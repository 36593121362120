/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCoworkInfo} from '../../cowork/AbstractCoworkInfo'
import {CoworkIdentifier} from '../../cowork/CoworkIdentifier'
import {CoworkInfo} from '../../cowork/CoworkInfo'
import {CoworkSimpleView} from '../../cowork/CoworkSimpleView'
import {CoworkStats} from '../../cowork/CoworkStats'
import {CoworkType} from '../../cowork/CoworkType'
import {EntityInfo} from '../../entity/EntityInfo'
import {GeoHash} from '../../geolocation/GeoHash'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {ReviewStatus} from './ReviewStatus'
import {ReviewType} from './ReviewType'
import {SelectTimes} from '../../cowork/SelectTimes'
import {Serializer} from '../../../serialize/Serializer'
import {ServiceTradableDesc} from '../../business/service/tradable/ServiceTradableDesc'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractReviewInfo extends AbstractCoworkInfo  {
    description: string;
    reviewScore: number;
    reviewType: ReviewType;
    employeeInfo: EntityInfo;
    productInfo: EntityInfo;
    refCoworkId: CoworkIdentifier;
    serviceDesc: ServiceTradableDesc;
    comments: number;
    localPoint: boolean;
    status: ReviewStatus;

    constructor() {
    	super();
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getReviewScore(): number {
    	return this.reviewScore;
    }

    public setReviewScore(reviewScore: number) {
    	this.reviewScore = reviewScore;
    }

    public getReviewType(): ReviewType {
    	return this.reviewType;
    }

    public setReviewType(reviewType: ReviewType) {
    	this.reviewType = reviewType;
    }

    public getEmployeeInfo(): EntityInfo {
    	return this.employeeInfo;
    }

    public setEmployeeInfo(employeeInfo: EntityInfo) {
    	this.employeeInfo = employeeInfo;
    }

    public getProductInfo(): EntityInfo {
    	return this.productInfo;
    }

    public setProductInfo(productInfo: EntityInfo) {
    	this.productInfo = productInfo;
    }

    public getRefCoworkId(): CoworkIdentifier {
    	return this.refCoworkId;
    }

    public setRefCoworkId(refCoworkId: CoworkIdentifier) {
    	this.refCoworkId = refCoworkId;
    }

    public getServiceDesc(): ServiceTradableDesc {
    	return this.serviceDesc;
    }

    public setServiceDesc(serviceDesc: ServiceTradableDesc) {
    	this.serviceDesc = serviceDesc;
    }

    public getComments(): number {
    	return this.comments;
    }

    public setComments(comments: number) {
    	this.comments = comments;
    }

    public isLocalPoint(): boolean {
    	return this.localPoint;
    }

    public setLocalPoint(localPoint: boolean) {
    	this.localPoint = localPoint;
    }

    public getStatus(): ReviewStatus {
    	return this.status;
    }

    public setStatus(status: ReviewStatus) {
    	this.status = status;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getKeywords(): Array<string>;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setKeywords(arg0: (Array<string> | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let reviewScore = json["reviewScore"];
        if (reviewScore != null) {
            this.setReviewScore(reviewScore);
        }
        let reviewType = json["reviewType"];
        if (reviewType != null) {
            let convertedReviewType0 = ReviewType[<string>reviewType];
            this.setReviewType(convertedReviewType0);
        }
        let employeeInfo = json["employeeInfo"];
        if (employeeInfo != null) {
            let convertedEmployeeInfo0 = Serializer.fillFromJsonObjectWithTypeTag(employeeInfo, "__type", factory);
            this.setEmployeeInfo(convertedEmployeeInfo0);
        }
        let productInfo = json["productInfo"];
        if (productInfo != null) {
            let convertedProductInfo0 = Serializer.fillFromJsonObjectWithTypeTag(productInfo, "__type", factory);
            this.setProductInfo(convertedProductInfo0);
        }
        let refCoworkId = json["refCoworkId"];
        if (refCoworkId != null) {
            let convertedRefCoworkId0 = new CoworkIdentifier();
            convertedRefCoworkId0.fillFromJson(refCoworkId, factory);
            this.setRefCoworkId(convertedRefCoworkId0);
        }
        let serviceDesc = json["serviceDesc"];
        if (serviceDesc != null) {
            let convertedServiceDesc0 = new ServiceTradableDesc();
            convertedServiceDesc0.fillFromJson(serviceDesc, factory);
            this.setServiceDesc(convertedServiceDesc0);
        }
        let comments = json["comments"];
        if (comments != null) {
            this.setComments(comments);
        }
        let localPoint = json["localPoint"];
        if (localPoint != null) {
            this.setLocalPoint(localPoint);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = ReviewStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let reviewScore = this.getReviewScore();
        if (reviewScore != null) {
            json["reviewScore"] = reviewScore;
        }
        let reviewType = this.getReviewType();
        if (reviewType != null) {
            let convertedReviewType0 = ReviewType[reviewType];
            json["reviewType"] = convertedReviewType0;
        }
        let employeeInfo = this.getEmployeeInfo();
        if (employeeInfo != null) {
            let convertedEmployeeInfo0 = employeeInfo.getJson(JsonTypeTagType.TYPE);
            json["employeeInfo"] = convertedEmployeeInfo0;
        }
        let productInfo = this.getProductInfo();
        if (productInfo != null) {
            let convertedProductInfo0 = productInfo.getJson(JsonTypeTagType.TYPE);
            json["productInfo"] = convertedProductInfo0;
        }
        let refCoworkId = this.getRefCoworkId();
        if (refCoworkId != null) {
            let convertedRefCoworkId0 = refCoworkId.getJson(JsonTypeTagType.NONE);
            json["refCoworkId"] = convertedRefCoworkId0;
        }
        let serviceDesc = this.getServiceDesc();
        if (serviceDesc != null) {
            let convertedServiceDesc0 = serviceDesc.getJson(JsonTypeTagType.NONE);
            json["serviceDesc"] = convertedServiceDesc0;
        }
        let comments = this.getComments();
        if (comments != null) {
            json["comments"] = comments;
        }
        let localPoint = this.isLocalPoint();
        if (localPoint != null) {
            json["localPoint"] = localPoint;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = ReviewStatus[status];
            json["status"] = convertedStatus0;
        }
    
        if (includeTypeTag) {
            json["__type"] = "ReviewInfo";
        }
    }


    public getTypeId(): string {
    	return "ReviewInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.ipsphere.review.ReviewInfo";
    }
}
