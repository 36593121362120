/**
 * 自动生成， 请不要手工修改！
 */


export enum ServiceErrorCode {
    UNCLASSIFIED_ERROR = 1,
    INVALID_ARGUMENT = 100,
    MISSING_ARGUMENT = 101,
    DATA_STORAGE_ERROR = 102,
    TECHNICAL_ERROR = 103,
    COMPLETE_COLLECTION_OPERATION_ERROR = 104,
    PARTIAL_COLLECTION_OPERATION_ERROR = 105,
    REQUEST_ALREADY_PROCESSED = 106,
    ASSOCIATION_ALREADY_EXISTS = 107,
    FAVOURITE_ALREADY_EXISTS = 108,
    ACTIVITY_ALREADY_JOINED = 109,
    APPROVAL_REJECTED = 110,
    INVALID_OPERATION = 111,
    MAX_TIME_ERROR = 200,
    USER_NAME_OR_PASSWORD_ERROR_MSG = 201,
    USER_NAME_ALREADY_REGISTERED = 202,
    UNCONFIRMED_MOBILE = 203,
    INVALID_MOBILE_NUMBER = 204,
    VERI_CODE_SERVICE_UNAVAILABLE = 205,
    INVALID_VERIFICATION_CODE = 206,
    VERIFICATION_CODE_TIMEOUT = 207,
    SEND_PHONE_MESSAGE_ERROR = 208,
    WECHAT_ERROR = 209,
    APPLE_ERROR = 210,
    UM_LOGIN_ERROR = 211,
    SMS_NOTIFICATION_SERVICE_UNAVAILABLE = 212,
    SMS_NOTIFICATION_SERVICE_FAILURE = 213,
    OLD_PASSWORD_ERROR_MSG = 214,
    INVALID_MINI_PROGRAM_CODE = 215,
    UNLOGGED_IN = 216,
    MOBILE_ALREADY_BOUND = 217,
    UNKNOWN_IDENTITY = 300,
    NOT_DATA_OWNER = 301,
    ENTITY_SUSPENDED = 302,
    INVALID_ENTITY = 303,
    DUPLICATE_NICK_NAME = 304,
    DUPLICATE_NAME = 305,
    EMPTY_USER_NAME = 306,
    TASK_ASSIGNEE_UNDEFINED = 400,
    TASK_NOT_FOUND = 401,
    TASK_HAVE_BEEAN_CLAIMED = 402,
    TASK_NOT_CORRESPONDING = 403,
    TASK_ASSIGNEE_DEFAULT = 404,
    NO_WORKFLOW_TASK_IDENTIFIER = 405,
    QA_OFFER_LESS_BID = 500,
    PRIZE_QUOTA_USED_UP = 501,
    VOTE_DUPLICATE = 600,
    INSUFFICIENT_FUND = 700,
    INSUFFICIENT_FUND_OR_CREIDT = 701,
    INSUFFICIENT_WITHDRAWAL_LIMITS = 702,
    INSUFFICIENT_CASH_BALANCE = 703,
    WITHDRAWAL_REQUEST_ALREADY = 704,
    INSUFFICIENT_QUOTA = 705,
    INVALID_APPROVER_PRIZE_SHARE = 706,
    APPROVER_ALREADY_PRIZED = 707,
    ANSWER_ABOVE_QUOTA = 708,
    WITHDRAWAL_REQUEST_CHECKED = 709,
    INSUFFICIENT_POINT_BALANCE = 710,
    INVALID_PAYMENT_AMOUNT = 711,
    TRANSFER_OUT_FAILURE = 712,
    WX_TRANSFER_OUT_FAILURE = 713,
    ALI_TRANSFER_OUT_FAILURE = 714,
    UNSUPPORTED_TRANSFER_OUT_METHOD = 715,
    INVALID_COUPON = 716,
    EXPIRED_COUPON = 717,
    INVALID_COUPON_USE = 718,
    COUPON_ALREADY_USED = 719,
    COUPON_INVALID_STATUS = 720,
    INVALID_COUPON_BY_SELF = 721,
    COUPON_TRADABLE_ID_VIOLATION = 722,
    INVALID_ACCOUNT_STATUS = 723,
    INVALID_SENDER_IDENTITY = 800,
    INVALID_CHANNEL = 801,
    INVALID_MEDIUM_ENTITY_ID = 802,
    USER_BLOCKED = 803,
    USER_IGNORED = 804,
    PRIVATE_CHAT_CLOSED_BY_USER = 805,
    PROFILE_PRIVACY_VIOLATION = 900,
    INSUFFICIENT_PRIVILEGE = 901,
    ILLEGAL_CONTENT = 902,
    NO_COMMENT = 903,
    NO_WRITE = 904,
    NO_TOPIC_WRITE = 905,
    CONTENT_DELETED = 906,
    CONTENT_IN_REVIEW = 907,
    INVALID_FE_SYMBOL = 908,
    GLOBAL_CHAT_CLOSED = 1000,
    LOCAL_CHAT_CLOSED = 1001,
    NO_REMOTE_CHAT = 1002,
    NO_CHAT_BY_USER = 1003,
    NO_CHAT_ON_LOCATION = 1004,
    MEMBER_ONLY = 1005,
    RED_ENVELOPE_NOT_EXISTS = 1006,
    RED_ENVELOPE_FINISHED = 1007,
    RED_ENVELOPE_PULLED = 1008,
    INSUFFICIENT_PLATFORM_FUND = 1009,
    NEW_USER_ONLY = 1010,
    REF_USER_ADDED = 1101,
    REF_LEADER_NOT_ACCEPTED = 1102,
    REF_EXISTS_MEMEBER = 1103,
    COLLEGE_EXIST = 1104,
    UNKNOWN_PRODUCT = 1201,
    HP_TOPIC_LIST_TOO_SHORT = 1301,
    HP_AD_CAT_LIST_TOO_SHORT = 1302,
    UNDER_STOCK = 1401,
    ENTER_GRID_EXIST = 1402,
    CREATE_ORDER_ERROR = 1501,
    CANCEL_SERVICE_ORDER = 1502,
    ORDER_DUPLICATE_PAY = 1503,
    PAY_AMOUNT_MISMATCH = 1504,
    SERVICE_REQUEST_CLOSED = 1505,
    SERVICE_REQUEST_ACCESS_DENIED = 1506,
    NO_SERVICE_QUOTA = 1507,
    NO_PACKAGE_AMOUNT = 1508,
    MISSING_PAYMENT_METHOD = 1509,
    QUIT_ACTIVITY_DISALLOWED = 1510,
    NO_OFFER_QUOTA = 1511,
    INVALID_TRADE_STATUS = 1512
}
