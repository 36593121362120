// @ts-nocheck
import {AbstractAlumniDetails} from './AbstractAlumniDetails'
import {ProductAttributes} from '../../product/ProductAttributes'
import {RangeType} from './RangeType'
import {TopicSpecificInfo} from './TopicSpecificInfo'


export class AlumniDetails extends AbstractAlumniDetails {
    constructor() {
        super();
    }

    insensitiveEquals(arg0: (TopicSpecificInfo | null)): boolean {
        // Manual Code Here
    }

    getRangeType(): RangeType {
        // Manual Code Here
    }

    copy(): TopicSpecificInfo {
        // Manual Code Here
    }

    copyInsensitive(): TopicSpecificInfo {
        // Manual Code Here
    }

    getProductAttributes(): ProductAttributes {
        // Manual Code Here
    }

    addSupplierId(arg0: (number | string | null)) {
        // Manual Code Here
    }

    copyTo(arg0: (TopicSpecificInfo | null)) {
        // Manual Code Here
    }

    setProductAttributes(arg0: (ProductAttributes | null)) {
        // Manual Code Here
    }

}
