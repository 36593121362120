import {EntityInfo} from "@/services/datatype/entity/EntityInfo";
import {RoleType} from "@/services/datatype/entity/RoleType";
import {RoleTypeName} from "@/services/datatype/entity/RoleTypeName";
import {NavigationTab} from "@/config/ui/NavigationTab";
import UserTools from "@/services/session/UserTools";

export class UserRole {
  enterpriseInfo: EntityInfo;
  private _roleType: RoleType;
  private _position: string;
  private _userWorkPage: boolean;

  constructor() {
  }

  public getEnterpriseInfo(): EntityInfo {
    return this.enterpriseInfo;
  }

  public setEnterpriseInfo(enterpriseInfo: EntityInfo) {
    this.enterpriseInfo = enterpriseInfo;
  }

  public getRoleType(): RoleType {
    return this._roleType;
  }

  get roleType(): RoleType {
    return this._roleType;
  }

  set roleType(value: RoleType) {
    this._roleType = value;
  }

  get position(): string {
    return this._position;
  }

  set position(position: string) {
    this._position = position;
  }

  public getActualPosition(): string | null {
    if (this._position) {
      return this._position;
    } else if (this._roleType) {
      let nameObject: String = RoleTypeName.getDisplayName(this._roleType);
      return (nameObject == null) ? null : nameObject.toString();
    } else {
      return null;
    }
  }

  public getDisplayName(): string | null {
    if (this.isToLogout()) {
      return "退出登录";
    } else {
      return this.getActualPosition();
    }
  }

  /**
   * 退出登录
   */
  public isToLogout(): boolean {
    return this._roleType == RoleType.VISITOR;
  }

  public getNavigationTabs(): Array<NavigationTab> {
    return [
      NavigationTab.HOME,
      NavigationTab.ORDER_MANAGE,
      NavigationTab.USER_INTERACT,
      NavigationTab.INFO_MAINTAIN,
      NavigationTab.PLATFORM_MESSAGE,
      NavigationTab.RECEIT_MANAGE,
      NavigationTab.FINANCE_SETTLE,
      NavigationTab.SERVICE_MARKET,
    ];
  }

  get userWorkPage(): boolean {
    return this._userWorkPage || !this.enterpriseInfo;
  }

  set userWorkPage(value: boolean) {
    this._userWorkPage = value;
  }

  public getEnterpriseId(): number | string {
    return this.enterpriseInfo ? this.enterpriseInfo.entityId : null;
  }

  public getServingEntityId(): number | string {
    return this._userWorkPage ? UserTools.loginId : this.getEnterpriseId();
  }
}
