/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {CoworkType} from './CoworkType'
import {EnterpriseServiceCategory} from '../business/service/EnterpriseServiceCategory'
import {HighlightType} from './HighlightType'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../serialize/TypeFactory'


export class CoworkHighlight extends AbstractSerializable  {
    id: (number | string);
    serviceCategory: EnterpriseServiceCategory;
    serviceCategoryName: string;
    serviceCategoryDisplayName: string;
    highlightType: HighlightType;
    regionId: (number | string);
    townId: (number | string);
    objectType: CoworkType;
    subType: string;
    objectId: (number | string);
    imgUrl: string;
    detailsLinks: Array<string>;
    video: boolean;
    tag: string;
    title: string;
    selectTime: (number | string);
    entityId: (number | string);
    startDay: number;
    endDay: number;

    constructor() {
    	super();
    }

    public getId(): (number | string) {
    	return this.id;
    }

    public setId(id: (number | string)) {
    	this.id = id;
    }

    public getServiceCategory(): EnterpriseServiceCategory {
    	return this.serviceCategory;
    }

    public setServiceCategory(serviceCategory: EnterpriseServiceCategory) {
    	this.serviceCategory = serviceCategory;
    }

    public getServiceCategoryName(): string {
    	return this.serviceCategoryName;
    }

    public setServiceCategoryName(serviceCategoryName: string) {
    	this.serviceCategoryName = serviceCategoryName;
    }

    public getServiceCategoryDisplayName(): string {
    	return this.serviceCategoryDisplayName;
    }

    public setServiceCategoryDisplayName(serviceCategoryDisplayName: string) {
    	this.serviceCategoryDisplayName = serviceCategoryDisplayName;
    }

    public getHighlightType(): HighlightType {
    	return this.highlightType;
    }

    public setHighlightType(highlightType: HighlightType) {
    	this.highlightType = highlightType;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getTownId(): (number | string) {
    	return this.townId;
    }

    public setTownId(townId: (number | string)) {
    	this.townId = townId;
    }

    public getObjectType(): CoworkType {
    	return this.objectType;
    }

    public setObjectType(objectType: CoworkType) {
    	this.objectType = objectType;
    }

    public getSubType(): string {
    	return this.subType;
    }

    public setSubType(subType: string) {
    	this.subType = subType;
    }

    public getObjectId(): (number | string) {
    	return this.objectId;
    }

    public setObjectId(objectId: (number | string)) {
    	this.objectId = objectId;
    }

    public getImgUrl(): string {
    	return this.imgUrl;
    }

    public setImgUrl(imgUrl: string) {
    	this.imgUrl = imgUrl;
    }

    public getDetailsLinks(): Array<string> {
    	return this.detailsLinks;
    }

    public setDetailsLinks(detailsLinks: Array<string>) {
    	this.detailsLinks = detailsLinks;
    }

    public isVideo(): boolean {
    	return this.video;
    }

    public setVideo(video: boolean) {
    	this.video = video;
    }

    public getTag(): string {
    	return this.tag;
    }

    public setTag(tag: string) {
    	this.tag = tag;
    }

    public getTitle(): string {
    	return this.title;
    }

    public setTitle(title: string) {
    	this.title = title;
    }

    public getSelectTime(): (number | string) {
    	return this.selectTime;
    }

    public setSelectTime(selectTime: (number | string)) {
    	this.selectTime = selectTime;
    }

    public getEntityId(): (number | string) {
    	return this.entityId;
    }

    public setEntityId(entityId: (number | string)) {
    	this.entityId = entityId;
    }

    public getStartDay(): number {
    	return this.startDay;
    }

    public setStartDay(startDay: number) {
    	this.startDay = startDay;
    }

    public getEndDay(): number {
    	return this.endDay;
    }

    public setEndDay(endDay: number) {
    	this.endDay = endDay;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let id = json["id"];
        if (id != null) {
            this.setId(id);
        }
        let serviceCategory = json["serviceCategory"];
        if (serviceCategory != null) {
            let convertedServiceCategory0 = EnterpriseServiceCategory[<string>serviceCategory];
            this.setServiceCategory(convertedServiceCategory0);
        }
        let serviceCategoryName = json["serviceCategoryName"];
        if (serviceCategoryName != null) {
            this.setServiceCategoryName(serviceCategoryName);
        }
        let serviceCategoryDisplayName = json["serviceCategoryDisplayName"];
        if (serviceCategoryDisplayName != null) {
            this.setServiceCategoryDisplayName(serviceCategoryDisplayName);
        }
        let highlightType = json["highlightType"];
        if (highlightType != null) {
            let convertedHighlightType0 = HighlightType[<string>highlightType];
            this.setHighlightType(convertedHighlightType0);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let townId = json["townId"];
        if (townId != null) {
            this.setTownId(townId);
        }
        let objectType = json["objectType"];
        if (objectType != null) {
            let convertedObjectType0 = CoworkType[<string>objectType];
            this.setObjectType(convertedObjectType0);
        }
        let subType = json["subType"];
        if (subType != null) {
            this.setSubType(subType);
        }
        let objectId = json["objectId"];
        if (objectId != null) {
            this.setObjectId(objectId);
        }
        let imgUrl = json["imgUrl"];
        if (imgUrl != null) {
            this.setImgUrl(imgUrl);
        }
        let detailsLinks = json["detailsLinks"];
        if (detailsLinks != null) {
            let convertedDetailsLinks0 = [];
            detailsLinks = detailsLinks[1];
            for (let i in detailsLinks) {
                let convertedDetailsLinks10 = detailsLinks[i];
                convertedDetailsLinks0.push(convertedDetailsLinks10);
            }
            this.setDetailsLinks(convertedDetailsLinks0);
        }
        let video = json["video"];
        if (video != null) {
            this.setVideo(video);
        }
        let tag = json["tag"];
        if (tag != null) {
            this.setTag(tag);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let selectTime = json["selectTime"];
        if (selectTime != null) {
            this.setSelectTime(selectTime);
        }
        let entityId = json["entityId"];
        if (entityId != null) {
            this.setEntityId(entityId);
        }
        let startDay = json["startDay"];
        if (startDay != null) {
            this.setStartDay(startDay);
        }
        let endDay = json["endDay"];
        if (endDay != null) {
            this.setEndDay(endDay);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let id = this.getId();
        if (id != null) {
            json["id"] = String(id);
        }
        let serviceCategory = this.getServiceCategory();
        if (serviceCategory != null) {
            let convertedServiceCategory0 = EnterpriseServiceCategory[serviceCategory];
            json["serviceCategory"] = convertedServiceCategory0;
        }
        let serviceCategoryName = this.getServiceCategoryName();
        if (serviceCategoryName != null) {
            json["serviceCategoryName"] = serviceCategoryName;
        }
        let serviceCategoryDisplayName = this.getServiceCategoryDisplayName();
        if (serviceCategoryDisplayName != null) {
            json["serviceCategoryDisplayName"] = serviceCategoryDisplayName;
        }
        let highlightType = this.getHighlightType();
        if (highlightType != null) {
            let convertedHighlightType0 = HighlightType[highlightType];
            json["highlightType"] = convertedHighlightType0;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let townId = this.getTownId();
        if (townId != null) {
            json["townId"] = String(townId);
        }
        let objectType = this.getObjectType();
        if (objectType != null) {
            let convertedObjectType0 = CoworkType[objectType];
            json["objectType"] = convertedObjectType0;
        }
        let subType = this.getSubType();
        if (subType != null) {
            json["subType"] = subType;
        }
        let objectId = this.getObjectId();
        if (objectId != null) {
            json["objectId"] = String(objectId);
        }
        let imgUrl = this.getImgUrl();
        if (imgUrl != null) {
            json["imgUrl"] = imgUrl;
        }
        let detailsLinks = this.getDetailsLinks();
        if (detailsLinks != null) {
            let convertedDetailsLinks0 = [];
            for (let i in detailsLinks) {
                let convertedDetailsLinks10 = detailsLinks[i];
                convertedDetailsLinks0.push(convertedDetailsLinks10);
            }
            let convertedDetailsLinksWithType1 = ["java.util.ArrayList", convertedDetailsLinks0];
            json["detailsLinks"] = convertedDetailsLinksWithType1;
        }
        let video = this.isVideo();
        if (video != null) {
            json["video"] = video;
        }
        let tag = this.getTag();
        if (tag != null) {
            json["tag"] = tag;
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let selectTime = this.getSelectTime();
        if (selectTime != null) {
            json["selectTime"] = String(selectTime);
        }
        let entityId = this.getEntityId();
        if (entityId != null) {
            json["entityId"] = String(entityId);
        }
        let startDay = this.getStartDay();
        if (startDay != null) {
            json["startDay"] = startDay;
        }
        let endDay = this.getEndDay();
        if (endDay != null) {
            json["endDay"] = endDay;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.cowork.CoworkHighlight";
    }
}
