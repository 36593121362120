/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { EnterpriseServiceCategory } from "./EnterpriseServiceCategory";
import { EnterpriseServiceDisplayType } from "./EnterpriseServiceDisplayType";
import { EnterpriseType } from "../../entity/enterprise/EnterpriseType";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class EnterpriseServiceCategorySpec extends AbstractSerializable {
  serviceCategory: EnterpriseServiceCategory;
  displayType: EnterpriseServiceDisplayType;
  name: string;
  imgUrl: string;
  description: string;
  regionId: number | string;
  local: boolean;
  enterpriseType: EnterpriseType;
  enterpriseId: number | string;

  constructor() {
    super();
  }

  public getServiceCategory(): EnterpriseServiceCategory {
    return this.serviceCategory;
  }

  public setServiceCategory(serviceCategory: EnterpriseServiceCategory) {
    this.serviceCategory = serviceCategory;
  }

  public getDisplayType(): EnterpriseServiceDisplayType {
    return this.displayType;
  }

  public setDisplayType(displayType: EnterpriseServiceDisplayType) {
    this.displayType = displayType;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string) {
    this.name = name;
  }

  public getImgUrl(): string {
    return this.imgUrl;
  }

  public setImgUrl(imgUrl: string) {
    this.imgUrl = imgUrl;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getRegionId(): number | string {
    return this.regionId;
  }

  public setRegionId(regionId: number | string) {
    this.regionId = regionId;
  }

  public getLocal(): boolean {
    return this.local;
  }

  public setLocal(local: boolean) {
    this.local = local;
  }

  public getEnterpriseType(): EnterpriseType {
    return this.enterpriseType;
  }

  public setEnterpriseType(enterpriseType: EnterpriseType) {
    this.enterpriseType = enterpriseType;
  }

  public getEnterpriseId(): number | string {
    return this.enterpriseId;
  }

  public setEnterpriseId(enterpriseId: number | string) {
    this.enterpriseId = enterpriseId;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const serviceCategory = json["serviceCategory"];
    if (serviceCategory != null) {
      const convertedServiceCategory0 =
        EnterpriseServiceCategory[<string>serviceCategory];
      this.setServiceCategory(convertedServiceCategory0);
    }
    const displayType = json["displayType"];
    if (displayType != null) {
      const convertedDisplayType0 =
        EnterpriseServiceDisplayType[<string>displayType];
      this.setDisplayType(convertedDisplayType0);
    }
    const name = json["name"];
    if (name != null) {
      this.setName(name);
    }
    const imgUrl = json["imgUrl"];
    if (imgUrl != null) {
      this.setImgUrl(imgUrl);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    const regionId = json["regionId"];
    if (regionId != null) {
      this.setRegionId(regionId);
    }
    const local = json["local"];
    if (local != null) {
      this.setLocal(local);
    }
    const enterpriseType = json["enterpriseType"];
    if (enterpriseType != null) {
      const convertedEnterpriseType0 = EnterpriseType[<string>enterpriseType];
      this.setEnterpriseType(convertedEnterpriseType0);
    }
    const enterpriseId = json["enterpriseId"];
    if (enterpriseId != null) {
      this.setEnterpriseId(enterpriseId);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const serviceCategory = this.getServiceCategory();
    if (serviceCategory != null) {
      const convertedServiceCategory0 =
        EnterpriseServiceCategory[serviceCategory];
      json["serviceCategory"] = convertedServiceCategory0;
    }
    const displayType = this.getDisplayType();
    if (displayType != null) {
      const convertedDisplayType0 = EnterpriseServiceDisplayType[displayType];
      json["displayType"] = convertedDisplayType0;
    }
    const name = this.getName();
    if (name != null) {
      json["name"] = name;
    }
    const imgUrl = this.getImgUrl();
    if (imgUrl != null) {
      json["imgUrl"] = imgUrl;
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const regionId = this.getRegionId();
    if (regionId != null) {
      json["regionId"] = String(regionId);
    }
    const local = this.getLocal();
    if (local != null) {
      json["local"] = local;
    }
    const enterpriseType = this.getEnterpriseType();
    if (enterpriseType != null) {
      const convertedEnterpriseType0 = EnterpriseType[enterpriseType];
      json["enterpriseType"] = convertedEnterpriseType0;
    }
    const enterpriseId = this.getEnterpriseId();
    if (enterpriseId != null) {
      json["enterpriseId"] = String(enterpriseId);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.EnterpriseServiceCategorySpec";
  }
}
