/**
 * 自动生成， 请不要手工修改！
 */

import {RoleType} from './RoleType'


export class RoleTypeName {
    public static getDisplayName(value: RoleType): string {
        switch (value) {
           case RoleType.GW_TEAM:
              return "平台管理员";
           case RoleType.FIN_ADMIN:
              return "财务经理";
           case RoleType.EDITOR:
              return "平台通讯员";
           case RoleType.SYSTEM:
              return "系统";
           case RoleType.VISITOR:
              return "游客";
           case RoleType.REGISTERED:
              return "已注册用户";
           case RoleType.QLL_MEMBER:
              return "企联联会员";
           case RoleType.QLL_VIP:
              return "企联联高级会员";
           case RoleType.BLACK_LISTED:
              return "黑名单";
           case RoleType.WATCHER:
              return "关注者";
           case RoleType.ADMIN:
              return "管理员";
           case RoleType.CREATOR:
              return "创建者";
           case RoleType.CHILD:
              return "从属";
           case RoleType.EMPLOYEE:
              return "业务员";
           case RoleType.CUSTOMER:
              return "顾客";
           case RoleType.ENTITY_OWNER:
              return "本人";
           case RoleType.OBJECT_OWNER:
              return "所有者";
           case RoleType.FRIEND:
              return "所有者";
           case RoleType.EXPERT:
              return "专家";
           case RoleType.OFFICIAL_REP:
              return "官方代表";
           case RoleType.E_DIRECTOR:
              return "主任";
           case RoleType.CEO:
              return "总裁";
           case RoleType.MEMBER:
              return "成员";
           case RoleType.MEMBER2:
              return "二级成员";
           case RoleType.HR:
              return "人事部主管";
           case RoleType.E_ASSOC_DIRECTOR:
              return "副主任";
           case RoleType.SENIOR_PARTNER:
              return "高级合伙人";
           case RoleType.PARTNER:
              return "合伙人";
           case RoleType.LAWYER:
              return "律师";
           case RoleType.P_LAWYER:
              return "见习律师";
           case RoleType.GRID_DIRECTOR:
              return "主任";
           case RoleType.GRID_MANAGER:
              return "网格长";
           case RoleType.GRID_OFFICER:
              return "管理员";
           case RoleType.GRID_ADMIN:
              return "网格员";
           case RoleType.GRID_INFOER:
              return "信息员";
           case RoleType.GRID_CITIZEN:
              return "网格居民";
           case RoleType.TOPIC_CHIEF_EDITOR:
              return "主编";
           case RoleType.TOPIC_EDITOR:
              return "编辑";
           case RoleType.TOPIC_EDITOR_IN_REVIEW:
              return "编辑";
           case RoleType.A_PRESIDENT:
              return "会长";
           case RoleType.A_STANDING_VP:
              return "常务副会长";
           case RoleType.A_VICE_PRESIDENT:
              return "副会长";
           case RoleType.A_SECRETARY_GEN:
              return "秘书长";
           case RoleType.A_SECRETARY:
              return "秘书";
           case RoleType.A_DIRECTOR:
              return "理事";
           case RoleType.A_VP_IN_REVIEW:
              return "副会长";
           case RoleType.A_MEMBER:
              return "会员";
           case RoleType.A_MEMBER_IN_REVIEW:
              return "会员";
           case RoleType.A_E_MEMBER:
              return "企业会员";
           case RoleType.A_MEMBER_E:
              return "会员企业成员";
           case RoleType.A_MEMBER_REP:
              return "会员企业代表";
           case RoleType.REGION_SALES:
              return "地区销售专员";
           case RoleType.REGION_MANAGER:
              return "地区运营经理";
           case RoleType.REGION_FIN_ADMIN:
              return "地区运营财务经理";
           case RoleType.REGION_ADMIN:
              return "地区运营专员";
           case RoleType.P_ADMIN:
              return "平台客户经理";
        };
        return null;
    }
}
