/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../serialize/TypeFactory'


export class QllCountrySpec extends AbstractSerializable  {
    name: string;
    code: number;

    constructor() {
    	super();
    }

    public getName(): string {
    	return this.name;
    }

    public setName(name: string) {
    	this.name = name;
    }

    public getCode(): number {
    	return this.code;
    }

    public setCode(code: number) {
    	this.code = code;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let name = json["name"];
        if (name != null) {
            this.setName(name);
        }
        let code = json["code"];
        if (code != null) {
            this.setCode(code);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let name = this.getName();
        if (name != null) {
            json["name"] = name;
        }
        let code = this.getCode();
        if (code != null) {
            json["code"] = code;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.international.QllCountrySpec";
    }
}
