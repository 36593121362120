// @ts-nocheck
import {AbstractGeoEntityDesc} from "./AbstractGeoEntityDesc";
import {MessageType} from "../../message/MessageContentType";

export class GeoEntityDesc extends AbstractGeoEntityDesc {
  constructor() {
    super();
  }

  getMessageType(): MessageContentType {
    // Manual Code Here
  }
}
