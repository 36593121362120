import { createStore } from "vuex";
import bread from "./modules/orderdetail.js";
import servicerequest from "./modules/detailchoose.js";
import talk from "./modules/talk.js";
import currentRole from "./modules/currentRole.js";

import message from "./modules/message.js";
import ws from "./modules/ws.js";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    bread,
    servicerequest,
    message,
    talk,
    currentRole,
    ws
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ["servicerequest", "bread"],
    }),
  ],
});
