/**
 * 自动生成， 请不要手工修改！
 */

import { CoworkSimpleView } from "../cowork/CoworkSimpleView";
import { CoworkStats } from "../cowork/CoworkStats";
import { GeneralCoworkStats } from "../cowork/GeneralCoworkStats";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractActivityStats extends GeneralCoworkStats {
  totalParticipants: number;

  constructor() {
    super();
  }

  public getTotalParticipants(): number {
    return this.totalParticipants;
  }

  public setTotalParticipants(totalParticipants: number) {
    this.totalParticipants = totalParticipants;
  }

  abstract addInfoToView(arg0: CoworkSimpleView | null);

  abstract updateCoworkStats(arg0: CoworkStats | null);

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const totalParticipants = json["totalParticipants"];
    if (totalParticipants != null) {
      this.setTotalParticipants(totalParticipants);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const totalParticipants = this.getTotalParticipants();
    if (totalParticipants != null) {
      json["totalParticipants"] = totalParticipants;
    }

    if (includeTypeTag) {
      json["__type"] = "ActivityStats";
    }
  }

  public getTypeId(): string {
    return "ActivityStats";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.activity.ActivityStats";
  }
}
