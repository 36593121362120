/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EnterpriseType} from '../enterprise/EnterpriseType'
import {EntityType} from '../EntityType'
import {InfoSectionType} from './InfoSectionType'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {TopicIconTag} from './TopicIconTag'
import {TopicType} from './TopicType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class InfoSectionDesc extends AbstractSerializable {
    rid: number;
    infoSectionType: InfoSectionType;
    entityId: (number | string);
    regionId: (number | string);
    entityType: EntityType;
    enterpriseType: EnterpriseType;
    topicType: TopicType;
    logoUrl: string;
    name: string;
    listTitle: string;
    iconTag: TopicIconTag;

    constructor() {
        super();
    }

    public getRid(): number {
        return this.rid;
    }

    public setRid(rid: number) {
        this.rid = rid;
    }

    public getInfoSectionType(): InfoSectionType {
        return this.infoSectionType;
    }

    public setInfoSectionType(infoSectionType: InfoSectionType) {
        this.infoSectionType = infoSectionType;
    }

    public getEntityId(): (number | string) {
        return this.entityId;
    }

    public setEntityId(entityId: (number | string)) {
        this.entityId = entityId;
    }

    public getRegionId(): (number | string) {
        return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
        this.regionId = regionId;
    }

    public getEntityType(): EntityType {
        return this.entityType;
    }

    public setEntityType(entityType: EntityType) {
        this.entityType = entityType;
    }

    public getEnterpriseType(): EnterpriseType {
        return this.enterpriseType;
    }

    public setEnterpriseType(enterpriseType: EnterpriseType) {
        this.enterpriseType = enterpriseType;
    }

    public getTopicType(): TopicType {
        return this.topicType;
    }

    public setTopicType(topicType: TopicType) {
        this.topicType = topicType;
    }

    public getLogoUrl(): string {
        return this.logoUrl;
    }

    public setLogoUrl(logoUrl: string) {
        this.logoUrl = logoUrl;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string) {
        this.name = name;
    }

    public getListTitle(): string {
        return this.listTitle;
    }

    public setListTitle(listTitle: string) {
        this.listTitle = listTitle;
    }

    public getIconTag(): TopicIconTag {
        return this.iconTag;
    }

    public setIconTag(iconTag: TopicIconTag) {
        this.iconTag = iconTag;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let rid = json["rid"];
        if (rid != null) {
            this.setRid(rid);
        }
        let infoSectionType = json["infoSectionType"];
        if (infoSectionType != null) {
            let convertedInfoSectionType0 = InfoSectionType[<string>infoSectionType];
            this.setInfoSectionType(convertedInfoSectionType0);
        }
        let entityId = json["entityId"];
        if (entityId != null) {
            this.setEntityId(entityId);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let entityType = json["entityType"];
        if (entityType != null) {
            let convertedEntityType0 = EntityType[<string>entityType];
            this.setEntityType(convertedEntityType0);
        }
        let enterpriseType = json["enterpriseType"];
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[<string>enterpriseType];
            this.setEnterpriseType(convertedEnterpriseType0);
        }
        let topicType = json["topicType"];
        if (topicType != null) {
            let convertedTopicType0 = TopicType[<string>topicType];
            this.setTopicType(convertedTopicType0);
        }
        let logoUrl = json["logoUrl"];
        if (logoUrl != null) {
            this.setLogoUrl(logoUrl);
        }
        let name = json["name"];
        if (name != null) {
            this.setName(name);
        }
        let listTitle = json["listTitle"];
        if (listTitle != null) {
            this.setListTitle(listTitle);
        }
        let iconTag = json["iconTag"];
        if (iconTag != null) {
            let convertedIconTag0 = TopicIconTag[<string>iconTag];
            this.setIconTag(convertedIconTag0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let rid = this.getRid();
        if (rid != null) {
            json["rid"] = rid;
        }
        let infoSectionType = this.getInfoSectionType();
        if (infoSectionType != null) {
            let convertedInfoSectionType0 = InfoSectionType[infoSectionType];
            json["infoSectionType"] = convertedInfoSectionType0;
        }
        let entityId = this.getEntityId();
        if (entityId != null) {
            json["entityId"] = String(entityId);
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let entityType = this.getEntityType();
        if (entityType != null) {
            let convertedEntityType0 = EntityType[entityType];
            json["entityType"] = convertedEntityType0;
        }
        let enterpriseType = this.getEnterpriseType();
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[enterpriseType];
            json["enterpriseType"] = convertedEnterpriseType0;
        }
        let topicType = this.getTopicType();
        if (topicType != null) {
            let convertedTopicType0 = TopicType[topicType];
            json["topicType"] = convertedTopicType0;
        }
        let logoUrl = this.getLogoUrl();
        if (logoUrl != null) {
            json["logoUrl"] = logoUrl;
        }
        let name = this.getName();
        if (name != null) {
            json["name"] = name;
        }
        let listTitle = this.getListTitle();
        if (listTitle != null) {
            json["listTitle"] = listTitle;
        }
        let iconTag = this.getIconTag();
        if (iconTag != null) {
            let convertedIconTag0 = TopicIconTag[iconTag];
            json["iconTag"] = convertedIconTag0;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.topic.InfoSectionDesc";
    }
}
