/**
 * 自动生成， 请不要手工修改！
 */

import {NavigationTab} from "./NavigationTab";

export class NavigationTabName {
  public static getDisplayName(value: NavigationTab): string {
    switch (value) {
      case NavigationTab.HOME:
        return "服务请求";
      case NavigationTab.ORDER_MANAGE:
        return "订单管理";
      case NavigationTab.USER_INTERACT:
        return "商企互动";
      case NavigationTab.INFO_MAINTAIN:
        return "信息维护";
      case NavigationTab.PLATFORM_MESSAGE:
        return "平台消息";
      case NavigationTab.RECEIT_MANAGE:
        return "发票管理";
      case NavigationTab.FINANCE_SETTLE:
        return "财务结算";
      case NavigationTab.SERVICE_MARKET:
        return "服务广场";
    }
    return null;
  }
}
