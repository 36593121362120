/**
 * 自动生成， 请不要手工修改！
 */

export enum EnterpriseType {
  LAW_FIRM,
  BANK,
  LABOUR_DISPATCH,
  SERVICE,
  TECH,
  PRODUCER,
  RETAILER,
  TECH_PARK,
  ESTATE_MGR,
  GOVERNMENT,
  CITY_LAW_OFFICE,
  DIST_LAW_OFFICE,
  STREET_OFFICE,
  GRID_RESIDENCE,
  GRID_VILLAGE,
  GRID_GOV,
  CHARITY_ORG,
  SCHOOL,
  STUDENT_UNION,
  OTHER,
  STUDENT_ASSOC,
  INTEREST_GROUP,
  WORKER_UNION,
  SECTOR_ASSOC,
  PLATFORM_COUNCIL,
  ELECTRONIC,
  COMMERCE,
  CONSUMER,
  AGRICULTURE,
  QLL_CUSTOMER_SERVICE,
}
