// @ts-nocheck
import { AbstractRequestAction } from "./AbstractRequestAction";
import { ActionType } from "./ActionType";
import { CoworkType } from "../cowork/CoworkType";
import { RequestType } from "./RequestType";

export class RequestAction extends AbstractRequestAction {
  constructor() {
    super();
  }

  /**
   * 必选数据： 请求ID
   */
  public withRequestId(requestId: number | string): RequestAction {
    this.requestId = requestId;
    return this;
  }

  /**
   * 必选数据： 请求类型
   */
  public withRequestType(requestType: RequestType): RequestAction {
    this.requestType = requestType;
    return this;
  }

  /**
   * 必选数据： 请求对象类型
   */
  public withTargetType(targetType: CoworkType): RequestAction {
    this.targetType = targetType;
    return this;
  }

  /**
   * 必选数据： 请求对象所属社区ID
   */
  public withTargetEntityId(targetEntityId: number | string): RequestAction {
    this.targetEntityId = targetEntityId;
    return this;
  }

  /**
   * 必选数据： 请求对象ID
   */
  public withTargetId(targetId: number | string): RequestAction {
    this.targetId = targetId;
    return this;
  }

  /**
   * 必选数据： 请求响应者ID
   */
  public withActorId(actorId: number | string): RequestAction {
    this.actorId = actorId;
    return this;
  }

  /**
   * 必选数据： 活动类型
   */
  public withActionType(actionType: ActionType): RequestAction {
    this.actionType = actionType;
    return this;
  }
}
