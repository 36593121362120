/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {AdditionalEntityAttributes} from '../AdditionalEntityAttributes'
import {EntityType} from '../EntityType'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {Serializer} from '../../../serialize/Serializer'
import {TopicSpecificInfo} from './TopicSpecificInfo'
import {TopicType} from './TopicType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractTopicDetails extends AbstractSerializable implements AdditionalEntityAttributes {
    topicType: TopicType;
    adImageUrls: Array<string>;
    byPlatform: boolean;
    sectorAssocId: (number | string);
    topicSpecificInfo: TopicSpecificInfo;

    constructor() {
        super();
    }

    public getTopicType(): TopicType {
        return this.topicType;
    }

    public setTopicType(topicType: TopicType) {
        this.topicType = topicType;
    }

    public getAdImageUrls(): Array<string> {
        return this.adImageUrls;
    }

    public setAdImageUrls(adImageUrls: Array<string>) {
        this.adImageUrls = adImageUrls;
    }

    public isByPlatform(): boolean {
        return this.byPlatform;
    }

    public setByPlatform(byPlatform: boolean) {
        this.byPlatform = byPlatform;
    }

    public getSectorAssocId(): (number | string) {
        return this.sectorAssocId;
    }

    public setSectorAssocId(sectorAssocId: (number | string)) {
        this.sectorAssocId = sectorAssocId;
    }

    public getTopicSpecificInfo(): TopicSpecificInfo {
        return this.topicSpecificInfo;
    }

    public setTopicSpecificInfo(topicSpecificInfo: TopicSpecificInfo) {
        this.topicSpecificInfo = topicSpecificInfo;
    }


    abstract copy(): AdditionalEntityAttributes;

    abstract getEntityType(): EntityType;

    abstract addAdditionalEntityAttributes(arg0: (AdditionalEntityAttributes | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let topicType = json["topicType"];
        if (topicType != null) {
            let convertedTopicType0 = TopicType[<string>topicType];
            this.setTopicType(convertedTopicType0);
        }
        let adImageUrls = json["adImageUrls"];
        if (adImageUrls != null) {
            let convertedAdImageUrls0 = [];
            adImageUrls = adImageUrls[1];
            for (let i in adImageUrls) {
                let convertedAdImageUrls10 = adImageUrls[i];
                convertedAdImageUrls0.push(convertedAdImageUrls10);
            }
            this.setAdImageUrls(convertedAdImageUrls0);
        }
        let byPlatform = json["byPlatform"];
        if (byPlatform != null) {
            this.setByPlatform(byPlatform);
        }
        let sectorAssocId = json["sectorAssocId"];
        if (sectorAssocId != null) {
            this.setSectorAssocId(sectorAssocId);
        }
        let topicSpecificInfo = json["topicSpecificInfo"];
        if (topicSpecificInfo != null) {
            let convertedTopicSpecificInfo0 = Serializer.fillFromJsonObjectWithTypeTag(topicSpecificInfo, "__type", factory);
            this.setTopicSpecificInfo(convertedTopicSpecificInfo0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let topicType = this.getTopicType();
        if (topicType != null) {
            let convertedTopicType0 = TopicType[topicType];
            json["topicType"] = convertedTopicType0;
        }
        let adImageUrls = this.getAdImageUrls();
        if (adImageUrls != null) {
            let convertedAdImageUrls0 = [];
            for (let i in adImageUrls) {
                let convertedAdImageUrls10 = adImageUrls[i];
                convertedAdImageUrls0.push(convertedAdImageUrls10);
            }
            let convertedAdImageUrlsWithType1 = ["java.util.ArrayList", convertedAdImageUrls0];
            json["adImageUrls"] = convertedAdImageUrlsWithType1;
        }
        let byPlatform = this.isByPlatform();
        if (byPlatform != null) {
            json["byPlatform"] = byPlatform;
        }
        let sectorAssocId = this.getSectorAssocId();
        if (sectorAssocId != null) {
            json["sectorAssocId"] = String(sectorAssocId);
        }
        let topicSpecificInfo = this.getTopicSpecificInfo();
        if (topicSpecificInfo != null) {
            let convertedTopicSpecificInfo0 = topicSpecificInfo.getJson(JsonTypeTagType.TYPE);
            json["topicSpecificInfo"] = convertedTopicSpecificInfo0;
        }

        if (includeTypeTag) {
            json["__type"] = "TopicDetails";
        }
    }


    public getTypeId(): string {
        return "TopicDetails";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.topic.TopicDetails";
    }
}
