/**
 * Created by Zhonghua on 15/11/2016.
 */

import { AbstractServiceRequest } from "./AbstractServiceRequest";
import { ClientInvocationService } from "./ClientInvocationService";
import { ClientInvocationServiceFactory } from "./ClientInvocationServiceFactory";
import { EmptyServiceResponseHandler } from "./EmptyServiceResponseHandler";
import { ServiceCancelRequest } from "./ServiceCancelRequest";
import { ServiceResponse } from "./ServiceResponse";
import { ServiceResponseHandler } from "./ServiceResponseHandler";

export abstract class AbstractClientServiceAdapter {
  private serviceName: string;
  private invocationService: ClientInvocationService;

  constructor(
    serviceName: string,
    invocationService: ClientInvocationService = ClientInvocationServiceFactory.getClientInvocationService()
  ) {
    this.serviceName = serviceName;
    this.invocationService = invocationService;
  }

  protected processMessageAsync(
    request: AbstractServiceRequest,
    handler: ServiceResponseHandler
  ) {
    this.invocationService.invokeServiceRequest(request, handler);
  }

  protected cancelServiceRequest(
    request: AbstractServiceRequest,
    handler: ServiceResponseHandler
  ) {
    if (request != null && request.isMultipleResponse()) {
      const cancelRequest = ServiceCancelRequest.createServiceCancelRequest(
        request.getId()
      );
      this.processMessageAsync(cancelRequest, handler);
    } else {
      if (handler) {
        handler.handleResponse(
          null,
          ServiceResponse.createServiceResponse(request.getId(), true)
        );
      }
    }
  }

  public etServiceName(): string {
    return this.serviceName;
  }
}
