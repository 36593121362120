/**
 * 自动生成， 请不要手工修改！
 */

export enum LocationType {
  GENERAL,
  NOT_IN_OP,
  RESIDENCE,
  COLLEGE,
  VILLAGE,
  TECH,
  INDUSTRIAL,
  SCHOOL,
}
