/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {ProductAttributes} from '../../product/ProductAttributes'
import {RangeType} from './RangeType'
import {TopicSpecificInfo} from './TopicSpecificInfo'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractAlumniDetails extends AbstractSerializable implements TopicSpecificInfo {
    schoolName: string;
    departmentName: string;
    teacherName: string;
    graduateYear: number;
    university: boolean;

    constructor() {
        super();
    }

    public getSchoolName(): string {
        return this.schoolName;
    }

    public setSchoolName(schoolName: string) {
        this.schoolName = schoolName;
    }

    public getDepartmentName(): string {
        return this.departmentName;
    }

    public setDepartmentName(departmentName: string) {
        this.departmentName = departmentName;
    }

    public getTeacherName(): string {
        return this.teacherName;
    }

    public setTeacherName(teacherName: string) {
        this.teacherName = teacherName;
    }

    public getGraduateYear(): number {
        return this.graduateYear;
    }

    public setGraduateYear(graduateYear: number) {
        this.graduateYear = graduateYear;
    }

    public isUniversity(): boolean {
        return this.university;
    }

    public setUniversity(university: boolean) {
        this.university = university;
    }


    abstract insensitiveEquals(arg0: (TopicSpecificInfo | null)): boolean;

    abstract getRangeType(): RangeType;

    abstract copy(): TopicSpecificInfo;

    abstract copyInsensitive(): TopicSpecificInfo;

    abstract getProductAttributes(): ProductAttributes;

    abstract addSupplierId(arg0: (number | string | null));

    abstract copyTo(arg0: (TopicSpecificInfo | null));

    abstract setProductAttributes(arg0: (ProductAttributes | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let schoolName = json["schoolName"];
        if (schoolName != null) {
            this.setSchoolName(schoolName);
        }
        let departmentName = json["departmentName"];
        if (departmentName != null) {
            this.setDepartmentName(departmentName);
        }
        let teacherName = json["teacherName"];
        if (teacherName != null) {
            this.setTeacherName(teacherName);
        }
        let graduateYear = json["graduateYear"];
        if (graduateYear != null) {
            this.setGraduateYear(graduateYear);
        }
        let university = json["university"];
        if (university != null) {
            this.setUniversity(university);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let schoolName = this.getSchoolName();
        if (schoolName != null) {
            json["schoolName"] = schoolName;
        }
        let departmentName = this.getDepartmentName();
        if (departmentName != null) {
            json["departmentName"] = departmentName;
        }
        let teacherName = this.getTeacherName();
        if (teacherName != null) {
            json["teacherName"] = teacherName;
        }
        let graduateYear = this.getGraduateYear();
        if (graduateYear != null) {
            json["graduateYear"] = graduateYear;
        }
        let university = this.isUniversity();
        if (university != null) {
            json["university"] = university;
        }

        if (includeTypeTag) {
            json["__type"] = "AlumniDetails";
        }
    }


    public getTypeId(): string {
        return "AlumniDetails";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.topic.AlumniDetails";
    }
}
