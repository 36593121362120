/**
 * 自动生成， 请不要手工修改！
 */

import { ActivityInfo } from "./ActivityInfo";
import { CompositeCoworkView } from "../cowork/CompositeCoworkView";
import { DistanceInfo } from "../geolocation/DistanceInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class ActivityView extends CompositeCoworkView<
  ActivityInfo,
  DistanceInfo
> {
  constructor() {
    super();
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);

    if (includeTypeTag) {
      json["__type"] = "ActivityView";
    }
  }

  public getTypeId(): string {
    return "ActivityView";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.activity.ActivityView";
  }
}
