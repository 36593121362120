/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCowork} from '../cowork/AbstractCowork'
import {ActivityHostType} from './ActivityHostType'
import {ActivityParticipantType} from './ActivityParticipantType'
import {ActivityStatus} from './ActivityStatus'
import {ActivityType} from './ActivityType'
import {EntityInfo} from '../entity/EntityInfo'
import {InfoSectionDesc} from '../entity/topic/InfoSectionDesc'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../keyword/KeywordEntity'
import {MessageType} from '../../message/MessageContentType'
import {QllCountry} from '../international/QllCountry'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class Activity extends AbstractCowork  {
    durationMins: number;
    initiatorMobile: string;
    contactName: string;
    startTime: (number | string);
    endTime: (number | string);
    activityType: ActivityType;
    maxParticipants: number;
    minParticipants: number;
    applicants: number;
    status: ActivityStatus;
    mobilePublic: boolean;
    cost: number;
    awardValue: number;
    publisherName: string;
    draftTime: (number | string);
    byPlatform: boolean;
    forEnterprise: boolean;
    maxPerEnterprise: number;
    participantType: ActivityParticipantType;
    tradableId: (number | string);
    infoLink: string;
    customerServiceSpec: InfoSectionDesc;
    customDisplay: boolean;
    activityHostType: ActivityHostType;

    constructor() {
    	super();
    }

    public getDurationMins(): number {
    	return this.durationMins;
    }

    public setDurationMins(durationMins: number) {
    	this.durationMins = durationMins;
    }

    public getInitiatorMobile(): string {
    	return this.initiatorMobile;
    }

    public setInitiatorMobile(initiatorMobile: string) {
    	this.initiatorMobile = initiatorMobile;
    }

    public getContactName(): string {
    	return this.contactName;
    }

    public setContactName(contactName: string) {
    	this.contactName = contactName;
    }

    public getStartTime(): (number | string) {
    	return this.startTime;
    }

    public setStartTime(startTime: (number | string)) {
    	this.startTime = startTime;
    }

    public getEndTime(): (number | string) {
    	return this.endTime;
    }

    public setEndTime(endTime: (number | string)) {
    	this.endTime = endTime;
    }

    public getActivityType(): ActivityType {
    	return this.activityType;
    }

    public setActivityType(activityType: ActivityType) {
    	this.activityType = activityType;
    }

    public getMaxParticipants(): number {
    	return this.maxParticipants;
    }

    public setMaxParticipants(maxParticipants: number) {
    	this.maxParticipants = maxParticipants;
    }

    public getMinParticipants(): number {
    	return this.minParticipants;
    }

    public setMinParticipants(minParticipants: number) {
    	this.minParticipants = minParticipants;
    }

    public getApplicants(): number {
    	return this.applicants;
    }

    public setApplicants(applicants: number) {
    	this.applicants = applicants;
    }

    public getStatus(): ActivityStatus {
    	return this.status;
    }

    public setStatus(status: ActivityStatus) {
    	this.status = status;
    }

    public isMobilePublic(): boolean {
    	return this.mobilePublic;
    }

    public setMobilePublic(mobilePublic: boolean) {
    	this.mobilePublic = mobilePublic;
    }

    public getCost(): number {
    	return this.cost;
    }

    public setCost(cost: number) {
    	this.cost = cost;
    }

    public getAwardValue(): number {
    	return this.awardValue;
    }

    public setAwardValue(awardValue: number) {
    	this.awardValue = awardValue;
    }

    public getPublisherName(): string {
    	return this.publisherName;
    }

    public setPublisherName(publisherName: string) {
    	this.publisherName = publisherName;
    }

    public getDraftTime(): (number | string) {
    	return this.draftTime;
    }

    public setDraftTime(draftTime: (number | string)) {
    	this.draftTime = draftTime;
    }

    public isByPlatform(): boolean {
    	return this.byPlatform;
    }

    public setByPlatform(byPlatform: boolean) {
    	this.byPlatform = byPlatform;
    }

    public isForEnterprise(): boolean {
    	return this.forEnterprise;
    }

    public setForEnterprise(forEnterprise: boolean) {
    	this.forEnterprise = forEnterprise;
    }

    public getMaxPerEnterprise(): number {
    	return this.maxPerEnterprise;
    }

    public setMaxPerEnterprise(maxPerEnterprise: number) {
    	this.maxPerEnterprise = maxPerEnterprise;
    }

    public getParticipantType(): ActivityParticipantType {
    	return this.participantType;
    }

    public setParticipantType(participantType: ActivityParticipantType) {
    	this.participantType = participantType;
    }

    public getTradableId(): (number | string) {
    	return this.tradableId;
    }

    public setTradableId(tradableId: (number | string)) {
    	this.tradableId = tradableId;
    }

    public getInfoLink(): string {
    	return this.infoLink;
    }

    public setInfoLink(infoLink: string) {
    	this.infoLink = infoLink;
    }

    public getCustomerServiceSpec(): InfoSectionDesc {
    	return this.customerServiceSpec;
    }

    public setCustomerServiceSpec(customerServiceSpec: InfoSectionDesc) {
    	this.customerServiceSpec = customerServiceSpec;
    }

    public isCustomDisplay(): boolean {
    	return this.customDisplay;
    }

    public setCustomDisplay(customDisplay: boolean) {
    	this.customDisplay = customDisplay;
    }

    public getActivityHostType(): ActivityHostType {
    	return this.activityHostType;
    }

    public setActivityHostType(activityHostType: ActivityHostType) {
    	this.activityHostType = activityHostType;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;

    abstract getTypeId(): string;

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let durationMins = json["durationMins"];
        if (durationMins != null) {
            this.setDurationMins(durationMins);
        }
        let initiatorMobile = json["initiatorMobile"];
        if (initiatorMobile != null) {
            this.setInitiatorMobile(initiatorMobile);
        }
        let contactName = json["contactName"];
        if (contactName != null) {
            this.setContactName(contactName);
        }
        let startTime = json["startTime"];
        if (startTime != null) {
            this.setStartTime(startTime);
        }
        let endTime = json["endTime"];
        if (endTime != null) {
            this.setEndTime(endTime);
        }
        let activityType = json["activityType"];
        if (activityType != null) {
            let convertedActivityType0 = ActivityType[<string>activityType];
            this.setActivityType(convertedActivityType0);
        }
        let maxParticipants = json["maxParticipants"];
        if (maxParticipants != null) {
            this.setMaxParticipants(maxParticipants);
        }
        let minParticipants = json["minParticipants"];
        if (minParticipants != null) {
            this.setMinParticipants(minParticipants);
        }
        let applicants = json["applicants"];
        if (applicants != null) {
            this.setApplicants(applicants);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = ActivityStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let mobilePublic = json["mobilePublic"];
        if (mobilePublic != null) {
            this.setMobilePublic(mobilePublic);
        }
        let cost = json["cost"];
        if (cost != null) {
            this.setCost(cost);
        }
        let awardValue = json["awardValue"];
        if (awardValue != null) {
            this.setAwardValue(awardValue);
        }
        let publisherName = json["publisherName"];
        if (publisherName != null) {
            this.setPublisherName(publisherName);
        }
        let draftTime = json["draftTime"];
        if (draftTime != null) {
            this.setDraftTime(draftTime);
        }
        let byPlatform = json["byPlatform"];
        if (byPlatform != null) {
            this.setByPlatform(byPlatform);
        }
        let forEnterprise = json["forEnterprise"];
        if (forEnterprise != null) {
            this.setForEnterprise(forEnterprise);
        }
        let maxPerEnterprise = json["maxPerEnterprise"];
        if (maxPerEnterprise != null) {
            this.setMaxPerEnterprise(maxPerEnterprise);
        }
        let participantType = json["participantType"];
        if (participantType != null) {
            let convertedParticipantType0 = ActivityParticipantType[<string>participantType];
            this.setParticipantType(convertedParticipantType0);
        }
        let tradableId = json["tradableId"];
        if (tradableId != null) {
            this.setTradableId(tradableId);
        }
        let infoLink = json["infoLink"];
        if (infoLink != null) {
            this.setInfoLink(infoLink);
        }
        let customerServiceSpec = json["customerServiceSpec"];
        if (customerServiceSpec != null) {
            let convertedCustomerServiceSpec0 = new InfoSectionDesc();
            convertedCustomerServiceSpec0.fillFromJson(customerServiceSpec, factory);
            this.setCustomerServiceSpec(convertedCustomerServiceSpec0);
        }
        let customDisplay = json["customDisplay"];
        if (customDisplay != null) {
            this.setCustomDisplay(customDisplay);
        }
        let activityHostType = json["activityHostType"];
        if (activityHostType != null) {
            let convertedActivityHostType0 = ActivityHostType[<string>activityHostType];
            this.setActivityHostType(convertedActivityHostType0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let durationMins = this.getDurationMins();
        if (durationMins != null) {
            json["durationMins"] = durationMins;
        }
        let initiatorMobile = this.getInitiatorMobile();
        if (initiatorMobile != null) {
            json["initiatorMobile"] = initiatorMobile;
        }
        let contactName = this.getContactName();
        if (contactName != null) {
            json["contactName"] = contactName;
        }
        let startTime = this.getStartTime();
        if (startTime != null) {
            json["startTime"] = String(startTime);
        }
        let endTime = this.getEndTime();
        if (endTime != null) {
            json["endTime"] = String(endTime);
        }
        let activityType = this.getActivityType();
        if (activityType != null) {
            let convertedActivityType0 = ActivityType[activityType];
            json["activityType"] = convertedActivityType0;
        }
        let maxParticipants = this.getMaxParticipants();
        if (maxParticipants != null) {
            json["maxParticipants"] = maxParticipants;
        }
        let minParticipants = this.getMinParticipants();
        if (minParticipants != null) {
            json["minParticipants"] = minParticipants;
        }
        let applicants = this.getApplicants();
        if (applicants != null) {
            json["applicants"] = applicants;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = ActivityStatus[status];
            json["status"] = convertedStatus0;
        }
        let mobilePublic = this.isMobilePublic();
        if (mobilePublic != null) {
            json["mobilePublic"] = mobilePublic;
        }
        let cost = this.getCost();
        if (cost != null) {
            json["cost"] = cost;
        }
        let awardValue = this.getAwardValue();
        if (awardValue != null) {
            json["awardValue"] = awardValue;
        }
        let publisherName = this.getPublisherName();
        if (publisherName != null) {
            json["publisherName"] = publisherName;
        }
        let draftTime = this.getDraftTime();
        if (draftTime != null) {
            json["draftTime"] = String(draftTime);
        }
        let byPlatform = this.isByPlatform();
        if (byPlatform != null) {
            json["byPlatform"] = byPlatform;
        }
        let forEnterprise = this.isForEnterprise();
        if (forEnterprise != null) {
            json["forEnterprise"] = forEnterprise;
        }
        let maxPerEnterprise = this.getMaxPerEnterprise();
        if (maxPerEnterprise != null) {
            json["maxPerEnterprise"] = maxPerEnterprise;
        }
        let participantType = this.getParticipantType();
        if (participantType != null) {
            let convertedParticipantType0 = ActivityParticipantType[participantType];
            json["participantType"] = convertedParticipantType0;
        }
        let tradableId = this.getTradableId();
        if (tradableId != null) {
            json["tradableId"] = String(tradableId);
        }
        let infoLink = this.getInfoLink();
        if (infoLink != null) {
            json["infoLink"] = infoLink;
        }
        let customerServiceSpec = this.getCustomerServiceSpec();
        if (customerServiceSpec != null) {
            let convertedCustomerServiceSpec0 = customerServiceSpec.getJson(JsonTypeTagType.NONE);
            json["customerServiceSpec"] = convertedCustomerServiceSpec0;
        }
        let customDisplay = this.isCustomDisplay();
        if (customDisplay != null) {
            json["customDisplay"] = customDisplay;
        }
        let activityHostType = this.getActivityHostType();
        if (activityHostType != null) {
            let convertedActivityHostType0 = ActivityHostType[activityHostType];
            json["activityHostType"] = convertedActivityHostType0;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.activity.Activity";
    }
}
