// 此处为跳转订单详情面包屑传递
export default {
  namespaced: true, // 为每个模块添加一个前缀名，保证模块命明不冲突
  state: () => {
    return {
      // 发送与收取id
      targetId: "",
      correlationId: "",
      // 聊天页面根据id的结束时间缓存
      serviceExpireTime: [],
    };
  },
  mutations: {
    updateTargetId(state, payload) {
      state.targetId = payload;
    },
    updateCorrelationId(state, payload) {
      state.correlationId = payload;
    },
    updateServiceExpireTime(state, payload) {
      state.serviceExpireTime[payload.id] = payload.time;
    },
  },
  actions: {},
};
