/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractEnterpriseTradable} from '../business/tradable/AbstractEnterpriseTradable'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../keyword/KeywordEntity'
import {MessageType} from '../../message/MessageContentType'
import {ProductAttributes} from './ProductAttributes'
import {ProductPriceType} from './ProductPriceType'
import {ProductStatus} from './ProductStatus'
import {QllCountry} from '../international/QllCountry'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractProduct extends AbstractEnterpriseTradable  {
    topicInfo: EntityInfo;
    contactInfo: EntityInfo;
    productImgUrl: string;
    productCode: string;
    brandName: string;
    prodStdNum: string;
    prodSpec: string;
    priceType: ProductPriceType;
    productAttributes: ProductAttributes;
    producerInfo: EntityInfo;
    producer: boolean;
    special: boolean;
    prodRelScore: number;
    telPhone: string;
    email: string;
    status: ProductStatus;
    lastUpdateUserInfo: EntityInfo;

    constructor() {
    	super();
    }

    public getTopicInfo(): EntityInfo {
    	return this.topicInfo;
    }

    public setTopicInfo(topicInfo: EntityInfo) {
    	this.topicInfo = topicInfo;
    }

    public getContactInfo(): EntityInfo {
    	return this.contactInfo;
    }

    public setContactInfo(contactInfo: EntityInfo) {
    	this.contactInfo = contactInfo;
    }

    public getProductImgUrl(): string {
    	return this.productImgUrl;
    }

    public setProductImgUrl(productImgUrl: string) {
    	this.productImgUrl = productImgUrl;
    }

    public getProductCode(): string {
    	return this.productCode;
    }

    public setProductCode(productCode: string) {
    	this.productCode = productCode;
    }

    public getBrandName(): string {
    	return this.brandName;
    }

    public setBrandName(brandName: string) {
    	this.brandName = brandName;
    }

    public getProdStdNum(): string {
    	return this.prodStdNum;
    }

    public setProdStdNum(prodStdNum: string) {
    	this.prodStdNum = prodStdNum;
    }

    public getProdSpec(): string {
    	return this.prodSpec;
    }

    public setProdSpec(prodSpec: string) {
    	this.prodSpec = prodSpec;
    }

    public getPriceType(): ProductPriceType {
    	return this.priceType;
    }

    public setPriceType(priceType: ProductPriceType) {
    	this.priceType = priceType;
    }

    public getProductAttributes(): ProductAttributes {
    	return this.productAttributes;
    }

    public setProductAttributes(productAttributes: ProductAttributes) {
    	this.productAttributes = productAttributes;
    }

    public getProducerInfo(): EntityInfo {
    	return this.producerInfo;
    }

    public setProducerInfo(producerInfo: EntityInfo) {
    	this.producerInfo = producerInfo;
    }

    public isProducer(): boolean {
    	return this.producer;
    }

    public setProducer(producer: boolean) {
    	this.producer = producer;
    }

    public isSpecial(): boolean {
    	return this.special;
    }

    public setSpecial(special: boolean) {
    	this.special = special;
    }

    public getProdRelScore(): number {
    	return this.prodRelScore;
    }

    public setProdRelScore(prodRelScore: number) {
    	this.prodRelScore = prodRelScore;
    }

    public getTelPhone(): string {
    	return this.telPhone;
    }

    public setTelPhone(telPhone: string) {
    	this.telPhone = telPhone;
    }

    public getEmail(): string {
    	return this.email;
    }

    public setEmail(email: string) {
    	this.email = email;
    }

    public getStatus(): ProductStatus {
    	return this.status;
    }

    public setStatus(status: ProductStatus) {
    	this.status = status;
    }

    public getLastUpdateUserInfo(): EntityInfo {
    	return this.lastUpdateUserInfo;
    }

    public setLastUpdateUserInfo(lastUpdateUserInfo: EntityInfo) {
    	this.lastUpdateUserInfo = lastUpdateUserInfo;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let topicInfo = json["topicInfo"];
        if (topicInfo != null) {
            let convertedTopicInfo0 = Serializer.fillFromJsonObjectWithTypeTag(topicInfo, "__type", factory);
            this.setTopicInfo(convertedTopicInfo0);
        }
        let contactInfo = json["contactInfo"];
        if (contactInfo != null) {
            let convertedContactInfo0 = Serializer.fillFromJsonObjectWithTypeTag(contactInfo, "__type", factory);
            this.setContactInfo(convertedContactInfo0);
        }
        let productImgUrl = json["productImgUrl"];
        if (productImgUrl != null) {
            this.setProductImgUrl(productImgUrl);
        }
        let productCode = json["productCode"];
        if (productCode != null) {
            this.setProductCode(productCode);
        }
        let brandName = json["brandName"];
        if (brandName != null) {
            this.setBrandName(brandName);
        }
        let prodStdNum = json["prodStdNum"];
        if (prodStdNum != null) {
            this.setProdStdNum(prodStdNum);
        }
        let prodSpec = json["prodSpec"];
        if (prodSpec != null) {
            this.setProdSpec(prodSpec);
        }
        let priceType = json["priceType"];
        if (priceType != null) {
            let convertedPriceType0 = ProductPriceType[<string>priceType];
            this.setPriceType(convertedPriceType0);
        }
        let productAttributes = json["productAttributes"];
        if (productAttributes != null) {
            let convertedProductAttributes0 = new ProductAttributes();
            convertedProductAttributes0.fillFromJson(productAttributes, factory);
            this.setProductAttributes(convertedProductAttributes0);
        }
        let producerInfo = json["producerInfo"];
        if (producerInfo != null) {
            let convertedProducerInfo0 = Serializer.fillFromJsonObjectWithTypeTag(producerInfo, "__type", factory);
            this.setProducerInfo(convertedProducerInfo0);
        }
        let producer = json["producer"];
        if (producer != null) {
            this.setProducer(producer);
        }
        let special = json["special"];
        if (special != null) {
            this.setSpecial(special);
        }
        let prodRelScore = json["prodRelScore"];
        if (prodRelScore != null) {
            this.setProdRelScore(prodRelScore);
        }
        let telPhone = json["telPhone"];
        if (telPhone != null) {
            this.setTelPhone(telPhone);
        }
        let email = json["email"];
        if (email != null) {
            this.setEmail(email);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = ProductStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let lastUpdateUserInfo = json["lastUpdateUserInfo"];
        if (lastUpdateUserInfo != null) {
            let convertedLastUpdateUserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(lastUpdateUserInfo, "__type", factory);
            this.setLastUpdateUserInfo(convertedLastUpdateUserInfo0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let topicInfo = this.getTopicInfo();
        if (topicInfo != null) {
            let convertedTopicInfo0 = topicInfo.getJson(JsonTypeTagType.TYPE);
            json["topicInfo"] = convertedTopicInfo0;
        }
        let contactInfo = this.getContactInfo();
        if (contactInfo != null) {
            let convertedContactInfo0 = contactInfo.getJson(JsonTypeTagType.TYPE);
            json["contactInfo"] = convertedContactInfo0;
        }
        let productImgUrl = this.getProductImgUrl();
        if (productImgUrl != null) {
            json["productImgUrl"] = productImgUrl;
        }
        let productCode = this.getProductCode();
        if (productCode != null) {
            json["productCode"] = productCode;
        }
        let brandName = this.getBrandName();
        if (brandName != null) {
            json["brandName"] = brandName;
        }
        let prodStdNum = this.getProdStdNum();
        if (prodStdNum != null) {
            json["prodStdNum"] = prodStdNum;
        }
        let prodSpec = this.getProdSpec();
        if (prodSpec != null) {
            json["prodSpec"] = prodSpec;
        }
        let priceType = this.getPriceType();
        if (priceType != null) {
            let convertedPriceType0 = ProductPriceType[priceType];
            json["priceType"] = convertedPriceType0;
        }
        let productAttributes = this.getProductAttributes();
        if (productAttributes != null) {
            let convertedProductAttributes0 = productAttributes.getJson(JsonTypeTagType.NONE);
            json["productAttributes"] = convertedProductAttributes0;
        }
        let producerInfo = this.getProducerInfo();
        if (producerInfo != null) {
            let convertedProducerInfo0 = producerInfo.getJson(JsonTypeTagType.TYPE);
            json["producerInfo"] = convertedProducerInfo0;
        }
        let producer = this.isProducer();
        if (producer != null) {
            json["producer"] = producer;
        }
        let special = this.isSpecial();
        if (special != null) {
            json["special"] = special;
        }
        let prodRelScore = this.getProdRelScore();
        if (prodRelScore != null) {
            json["prodRelScore"] = prodRelScore;
        }
        let telPhone = this.getTelPhone();
        if (telPhone != null) {
            json["telPhone"] = telPhone;
        }
        let email = this.getEmail();
        if (email != null) {
            json["email"] = email;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = ProductStatus[status];
            json["status"] = convertedStatus0;
        }
        let lastUpdateUserInfo = this.getLastUpdateUserInfo();
        if (lastUpdateUserInfo != null) {
            let convertedLastUpdateUserInfo0 = lastUpdateUserInfo.getJson(JsonTypeTagType.TYPE);
            json["lastUpdateUserInfo"] = convertedLastUpdateUserInfo0;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.product.Product";
    }
}
