/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCoworkInfo} from '../../../cowork/AbstractCoworkInfo'
import {CoworkInfo} from '../../../cowork/CoworkInfo'
import {CoworkSimpleView} from '../../../cowork/CoworkSimpleView'
import {CoworkStats} from '../../../cowork/CoworkStats'
import {CoworkType} from '../../../cowork/CoworkType'
import {EnterpriseServiceCategory} from '../EnterpriseServiceCategory'
import {EnterpriseServiceType} from '../EnterpriseServiceType'
import {EntityInfo} from '../../../entity/EntityInfo'
import {GeoHash} from '../../../geolocation/GeoHash'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {RequestStatusType} from '../../../request/RequestStatusType'
import {SelectTimes} from '../../../cowork/SelectTimes'
import {Serializer} from '../../../../serialize/Serializer'
import {ServiceDeliveryType} from '../ServiceDeliveryType'
import {ServiceUseType} from '../ServiceUseType'
import {SettlementBillSyncStatus} from '../settlement/SettlementBillSyncStatus'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export abstract class AbstractEnterpriseServiceRequestInfo extends AbstractCoworkInfo  {
    period: number;
    enterpriseServiceType: EnterpriseServiceType;
    assignable: boolean;
    serviceDeliveryType: ServiceDeliveryType;
    serviceUseType: ServiceUseType;
    serviceCategory: EnterpriseServiceCategory;
    serviceTypeName: string;
    serviceTypeDisplayName: string;
    serviceCategoryName: string;
    tradableId: (number | string);
    pkgTradableId: (number | string);
    tradeId: (number | string);
    tradeTitle: string;
    tradeDescription: string;
    orderId: (number | string);
    fromMiniService: boolean;
    orderingEnterpriseInfo: EntityInfo;
    personal: boolean;
    servingEnterpriseInfo: EntityInfo;
    servingProfessionalInfo: EntityInfo;
    assistantInfo: EntityInfo;
    customerServiceInfo: EntityInfo;
    customerServiceWorkerInfo: EntityInfo;
    status: RequestStatusType;
    delegateAccepted: boolean;
    completionTime: (number | string);
    scoreByUser: number;
    settleAmount: number;
    syncStatus: SettlementBillSyncStatus;
    customerService: boolean;
    preSale: boolean;
    serviceClassifier: string;
    unreadMessageCountByWorker: number;
    unreadMessageCountByRequestor: number;

    constructor() {
        super();
    }

    public getPeriod(): number {
        return this.period;
    }

    public setPeriod(period: number) {
        this.period = period;
    }

    public getEnterpriseServiceType(): EnterpriseServiceType {
        return this.enterpriseServiceType;
    }

    public setEnterpriseServiceType(enterpriseServiceType: EnterpriseServiceType) {
        this.enterpriseServiceType = enterpriseServiceType;
    }

    public isAssignable(): boolean {
        return this.assignable;
    }

    public setAssignable(assignable: boolean) {
        this.assignable = assignable;
    }

    public getServiceDeliveryType(): ServiceDeliveryType {
        return this.serviceDeliveryType;
    }

    public setServiceDeliveryType(serviceDeliveryType: ServiceDeliveryType) {
        this.serviceDeliveryType = serviceDeliveryType;
    }

    public getServiceUseType(): ServiceUseType {
        return this.serviceUseType;
    }

    public setServiceUseType(serviceUseType: ServiceUseType) {
        this.serviceUseType = serviceUseType;
    }

    public getServiceCategory(): EnterpriseServiceCategory {
        return this.serviceCategory;
    }

    public setServiceCategory(serviceCategory: EnterpriseServiceCategory) {
        this.serviceCategory = serviceCategory;
    }

    public getServiceTypeName(): string {
        return this.serviceTypeName;
    }

    public setServiceTypeName(serviceTypeName: string) {
        this.serviceTypeName = serviceTypeName;
    }

    public getServiceTypeDisplayName(): string {
        return this.serviceTypeDisplayName;
    }

    public setServiceTypeDisplayName(serviceTypeDisplayName: string) {
        this.serviceTypeDisplayName = serviceTypeDisplayName;
    }

    public getServiceCategoryName(): string {
        return this.serviceCategoryName;
    }

    public setServiceCategoryName(serviceCategoryName: string) {
        this.serviceCategoryName = serviceCategoryName;
    }

    public getTradableId(): (number | string) {
        return this.tradableId;
    }

    public setTradableId(tradableId: (number | string)) {
        this.tradableId = tradableId;
    }

    public getPkgTradableId(): (number | string) {
        return this.pkgTradableId;
    }

    public setPkgTradableId(pkgTradableId: (number | string)) {
        this.pkgTradableId = pkgTradableId;
    }

    public getTradeId(): (number | string) {
        return this.tradeId;
    }

    public setTradeId(tradeId: (number | string)) {
        this.tradeId = tradeId;
    }

    public getTradeTitle(): string {
        return this.tradeTitle;
    }

    public setTradeTitle(tradeTitle: string) {
        this.tradeTitle = tradeTitle;
    }

    public getTradeDescription(): string {
        return this.tradeDescription;
    }

    public setTradeDescription(tradeDescription: string) {
        this.tradeDescription = tradeDescription;
    }

    public getOrderId(): (number | string) {
        return this.orderId;
    }

    public setOrderId(orderId: (number | string)) {
        this.orderId = orderId;
    }

    public isFromMiniService(): boolean {
        return this.fromMiniService;
    }

    public setFromMiniService(fromMiniService: boolean) {
        this.fromMiniService = fromMiniService;
    }

    public getOrderingEnterpriseInfo(): EntityInfo {
        return this.orderingEnterpriseInfo;
    }

    public setOrderingEnterpriseInfo(orderingEnterpriseInfo: EntityInfo) {
        this.orderingEnterpriseInfo = orderingEnterpriseInfo;
    }

    public isPersonal(): boolean {
        return this.personal;
    }

    public setPersonal(personal: boolean) {
        this.personal = personal;
    }

    public getServingEnterpriseInfo(): EntityInfo {
        return this.servingEnterpriseInfo;
    }

    public setServingEnterpriseInfo(servingEnterpriseInfo: EntityInfo) {
        this.servingEnterpriseInfo = servingEnterpriseInfo;
    }

    public getServingProfessionalInfo(): EntityInfo {
        return this.servingProfessionalInfo;
    }

    public setServingProfessionalInfo(servingProfessionalInfo: EntityInfo) {
        this.servingProfessionalInfo = servingProfessionalInfo;
    }

    public getAssistantInfo(): EntityInfo {
        return this.assistantInfo;
    }

    public setAssistantInfo(assistantInfo: EntityInfo) {
        this.assistantInfo = assistantInfo;
    }

    public getCustomerServiceInfo(): EntityInfo {
        return this.customerServiceInfo;
    }

    public setCustomerServiceInfo(customerServiceInfo: EntityInfo) {
        this.customerServiceInfo = customerServiceInfo;
    }

    public getCustomerServiceWorkerInfo(): EntityInfo {
        return this.customerServiceWorkerInfo;
    }

    public setCustomerServiceWorkerInfo(customerServiceWorkerInfo: EntityInfo) {
        this.customerServiceWorkerInfo = customerServiceWorkerInfo;
    }

    public getStatus(): RequestStatusType {
        return this.status;
    }

    public setStatus(status: RequestStatusType) {
        this.status = status;
    }

    public isDelegateAccepted(): boolean {
        return this.delegateAccepted;
    }

    public setDelegateAccepted(delegateAccepted: boolean) {
        this.delegateAccepted = delegateAccepted;
    }

    public getCompletionTime(): (number | string) {
        return this.completionTime;
    }

    public setCompletionTime(completionTime: (number | string)) {
        this.completionTime = completionTime;
    }

    public getScoreByUser(): number {
        return this.scoreByUser;
    }

    public setScoreByUser(scoreByUser: number) {
        this.scoreByUser = scoreByUser;
    }

    public getSettleAmount(): number {
        return this.settleAmount;
    }

    public setSettleAmount(settleAmount: number) {
        this.settleAmount = settleAmount;
    }

    public getSyncStatus(): SettlementBillSyncStatus {
        return this.syncStatus;
    }

    public setSyncStatus(syncStatus: SettlementBillSyncStatus) {
        this.syncStatus = syncStatus;
    }

    public isCustomerService(): boolean {
        return this.customerService;
    }

    public setCustomerService(customerService: boolean) {
        this.customerService = customerService;
    }

    public isPreSale(): boolean {
        return this.preSale;
    }

    public setPreSale(preSale: boolean) {
        this.preSale = preSale;
    }

    public getServiceClassifier(): string {
        return this.serviceClassifier;
    }

    public setServiceClassifier(serviceClassifier: string) {
        this.serviceClassifier = serviceClassifier;
    }

    public getUnreadMessageCountByWorker(): number {
        return this.unreadMessageCountByWorker;
    }

    public setUnreadMessageCountByWorker(unreadMessageCountByWorker: number) {
        this.unreadMessageCountByWorker = unreadMessageCountByWorker;
    }

    public getUnreadMessageCountByRequestor(): number {
        return this.unreadMessageCountByRequestor;
    }

    public setUnreadMessageCountByRequestor(unreadMessageCountByRequestor: number) {
        this.unreadMessageCountByRequestor = unreadMessageCountByRequestor;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getKeywords(): Array<string>;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setKeywords(arg0: (Array<string> | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract getDescription(): string;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setDescription(arg0: (string | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let period = json["period"];
        if (period != null) {
            this.setPeriod(period);
        }
        let enterpriseServiceType = json["enterpriseServiceType"];
        if (enterpriseServiceType != null) {
            let convertedEnterpriseServiceType0 = EnterpriseServiceType[<string>enterpriseServiceType];
            this.setEnterpriseServiceType(convertedEnterpriseServiceType0);
        }
        let assignable = json["assignable"];
        if (assignable != null) {
            this.setAssignable(assignable);
        }
        let serviceDeliveryType = json["serviceDeliveryType"];
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[<string>serviceDeliveryType];
            this.setServiceDeliveryType(convertedServiceDeliveryType0);
        }
        let serviceUseType = json["serviceUseType"];
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[<string>serviceUseType];
            this.setServiceUseType(convertedServiceUseType0);
        }
        let serviceCategory = json["serviceCategory"];
        if (serviceCategory != null) {
            let convertedServiceCategory0 = EnterpriseServiceCategory[<string>serviceCategory];
            this.setServiceCategory(convertedServiceCategory0);
        }
        let serviceTypeName = json["serviceTypeName"];
        if (serviceTypeName != null) {
            this.setServiceTypeName(serviceTypeName);
        }
        let serviceTypeDisplayName = json["serviceTypeDisplayName"];
        if (serviceTypeDisplayName != null) {
            this.setServiceTypeDisplayName(serviceTypeDisplayName);
        }
        let serviceCategoryName = json["serviceCategoryName"];
        if (serviceCategoryName != null) {
            this.setServiceCategoryName(serviceCategoryName);
        }
        let tradableId = json["tradableId"];
        if (tradableId != null) {
            this.setTradableId(tradableId);
        }
        let pkgTradableId = json["pkgTradableId"];
        if (pkgTradableId != null) {
            this.setPkgTradableId(pkgTradableId);
        }
        let tradeId = json["tradeId"];
        if (tradeId != null) {
            this.setTradeId(tradeId);
        }
        let tradeTitle = json["tradeTitle"];
        if (tradeTitle != null) {
            this.setTradeTitle(tradeTitle);
        }
        let tradeDescription = json["tradeDescription"];
        if (tradeDescription != null) {
            this.setTradeDescription(tradeDescription);
        }
        let orderId = json["orderId"];
        if (orderId != null) {
            this.setOrderId(orderId);
        }
        let fromMiniService = json["fromMiniService"];
        if (fromMiniService != null) {
            this.setFromMiniService(fromMiniService);
        }
        let orderingEnterpriseInfo = json["orderingEnterpriseInfo"];
        if (orderingEnterpriseInfo != null) {
            let convertedOrderingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(orderingEnterpriseInfo, "__type", factory);
            this.setOrderingEnterpriseInfo(convertedOrderingEnterpriseInfo0);
        }
        let personal = json["personal"];
        if (personal != null) {
            this.setPersonal(personal);
        }
        let servingEnterpriseInfo = json["servingEnterpriseInfo"];
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(servingEnterpriseInfo, "__type", factory);
            this.setServingEnterpriseInfo(convertedServingEnterpriseInfo0);
        }
        let servingProfessionalInfo = json["servingProfessionalInfo"];
        if (servingProfessionalInfo != null) {
            let convertedServingProfessionalInfo0 = Serializer.fillFromJsonObjectWithTypeTag(servingProfessionalInfo, "__type", factory);
            this.setServingProfessionalInfo(convertedServingProfessionalInfo0);
        }
        let assistantInfo = json["assistantInfo"];
        if (assistantInfo != null) {
            let convertedAssistantInfo0 = Serializer.fillFromJsonObjectWithTypeTag(assistantInfo, "__type", factory);
            this.setAssistantInfo(convertedAssistantInfo0);
        }
        let customerServiceInfo = json["customerServiceInfo"];
        if (customerServiceInfo != null) {
            let convertedCustomerServiceInfo0 = Serializer.fillFromJsonObjectWithTypeTag(customerServiceInfo, "__type", factory);
            this.setCustomerServiceInfo(convertedCustomerServiceInfo0);
        }
        let customerServiceWorkerInfo = json["customerServiceWorkerInfo"];
        if (customerServiceWorkerInfo != null) {
            let convertedCustomerServiceWorkerInfo0 = Serializer.fillFromJsonObjectWithTypeTag(customerServiceWorkerInfo, "__type", factory);
            this.setCustomerServiceWorkerInfo(convertedCustomerServiceWorkerInfo0);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = RequestStatusType[<string>status];
            this.setStatus(convertedStatus0);
        }
        let delegateAccepted = json["delegateAccepted"];
        if (delegateAccepted != null) {
            this.setDelegateAccepted(delegateAccepted);
        }
        let completionTime = json["completionTime"];
        if (completionTime != null) {
            this.setCompletionTime(completionTime);
        }
        let scoreByUser = json["scoreByUser"];
        if (scoreByUser != null) {
            this.setScoreByUser(scoreByUser);
        }
        let settleAmount = json["settleAmount"];
        if (settleAmount != null) {
            this.setSettleAmount(settleAmount);
        }
        let syncStatus = json["syncStatus"];
        if (syncStatus != null) {
            let convertedSyncStatus0 = SettlementBillSyncStatus[<string>syncStatus];
            this.setSyncStatus(convertedSyncStatus0);
        }
        let customerService = json["customerService"];
        if (customerService != null) {
            this.setCustomerService(customerService);
        }
        let preSale = json["preSale"];
        if (preSale != null) {
            this.setPreSale(preSale);
        }
        let serviceClassifier = json["serviceClassifier"];
        if (serviceClassifier != null) {
            this.setServiceClassifier(serviceClassifier);
        }
        let unreadMessageCountByWorker = json["unreadMessageCountByWorker"];
        if (unreadMessageCountByWorker != null) {
            this.setUnreadMessageCountByWorker(unreadMessageCountByWorker);
        }
        let unreadMessageCountByRequestor = json["unreadMessageCountByRequestor"];
        if (unreadMessageCountByRequestor != null) {
            this.setUnreadMessageCountByRequestor(unreadMessageCountByRequestor);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let period = this.getPeriod();
        if (period != null) {
            json["period"] = period;
        }
        let enterpriseServiceType = this.getEnterpriseServiceType();
        if (enterpriseServiceType != null) {
            let convertedEnterpriseServiceType0 = EnterpriseServiceType[enterpriseServiceType];
            json["enterpriseServiceType"] = convertedEnterpriseServiceType0;
        }
        let assignable = this.isAssignable();
        if (assignable != null) {
            json["assignable"] = assignable;
        }
        let serviceDeliveryType = this.getServiceDeliveryType();
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[serviceDeliveryType];
            json["serviceDeliveryType"] = convertedServiceDeliveryType0;
        }
        let serviceUseType = this.getServiceUseType();
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[serviceUseType];
            json["serviceUseType"] = convertedServiceUseType0;
        }
        let serviceCategory = this.getServiceCategory();
        if (serviceCategory != null) {
            let convertedServiceCategory0 = EnterpriseServiceCategory[serviceCategory];
            json["serviceCategory"] = convertedServiceCategory0;
        }
        let serviceTypeName = this.getServiceTypeName();
        if (serviceTypeName != null) {
            json["serviceTypeName"] = serviceTypeName;
        }
        let serviceTypeDisplayName = this.getServiceTypeDisplayName();
        if (serviceTypeDisplayName != null) {
            json["serviceTypeDisplayName"] = serviceTypeDisplayName;
        }
        let serviceCategoryName = this.getServiceCategoryName();
        if (serviceCategoryName != null) {
            json["serviceCategoryName"] = serviceCategoryName;
        }
        let tradableId = this.getTradableId();
        if (tradableId != null) {
            json["tradableId"] = String(tradableId);
        }
        let pkgTradableId = this.getPkgTradableId();
        if (pkgTradableId != null) {
            json["pkgTradableId"] = String(pkgTradableId);
        }
        let tradeId = this.getTradeId();
        if (tradeId != null) {
            json["tradeId"] = String(tradeId);
        }
        let tradeTitle = this.getTradeTitle();
        if (tradeTitle != null) {
            json["tradeTitle"] = tradeTitle;
        }
        let tradeDescription = this.getTradeDescription();
        if (tradeDescription != null) {
            json["tradeDescription"] = tradeDescription;
        }
        let orderId = this.getOrderId();
        if (orderId != null) {
            json["orderId"] = String(orderId);
        }
        let fromMiniService = this.isFromMiniService();
        if (fromMiniService != null) {
            json["fromMiniService"] = fromMiniService;
        }
        let orderingEnterpriseInfo = this.getOrderingEnterpriseInfo();
        if (orderingEnterpriseInfo != null) {
            let convertedOrderingEnterpriseInfo0 = orderingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["orderingEnterpriseInfo"] = convertedOrderingEnterpriseInfo0;
        }
        let personal = this.isPersonal();
        if (personal != null) {
            json["personal"] = personal;
        }
        let servingEnterpriseInfo = this.getServingEnterpriseInfo();
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = servingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["servingEnterpriseInfo"] = convertedServingEnterpriseInfo0;
        }
        let servingProfessionalInfo = this.getServingProfessionalInfo();
        if (servingProfessionalInfo != null) {
            let convertedServingProfessionalInfo0 = servingProfessionalInfo.getJson(JsonTypeTagType.TYPE);
            json["servingProfessionalInfo"] = convertedServingProfessionalInfo0;
        }
        let assistantInfo = this.getAssistantInfo();
        if (assistantInfo != null) {
            let convertedAssistantInfo0 = assistantInfo.getJson(JsonTypeTagType.TYPE);
            json["assistantInfo"] = convertedAssistantInfo0;
        }
        let customerServiceInfo = this.getCustomerServiceInfo();
        if (customerServiceInfo != null) {
            let convertedCustomerServiceInfo0 = customerServiceInfo.getJson(JsonTypeTagType.TYPE);
            json["customerServiceInfo"] = convertedCustomerServiceInfo0;
        }
        let customerServiceWorkerInfo = this.getCustomerServiceWorkerInfo();
        if (customerServiceWorkerInfo != null) {
            let convertedCustomerServiceWorkerInfo0 = customerServiceWorkerInfo.getJson(JsonTypeTagType.TYPE);
            json["customerServiceWorkerInfo"] = convertedCustomerServiceWorkerInfo0;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = RequestStatusType[status];
            json["status"] = convertedStatus0;
        }
        let delegateAccepted = this.isDelegateAccepted();
        if (delegateAccepted != null) {
            json["delegateAccepted"] = delegateAccepted;
        }
        let completionTime = this.getCompletionTime();
        if (completionTime != null) {
            json["completionTime"] = String(completionTime);
        }
        let scoreByUser = this.getScoreByUser();
        if (scoreByUser != null) {
            json["scoreByUser"] = scoreByUser;
        }
        let settleAmount = this.getSettleAmount();
        if (settleAmount != null) {
            json["settleAmount"] = settleAmount;
        }
        let syncStatus = this.getSyncStatus();
        if (syncStatus != null) {
            let convertedSyncStatus0 = SettlementBillSyncStatus[syncStatus];
            json["syncStatus"] = convertedSyncStatus0;
        }
        let customerService = this.isCustomerService();
        if (customerService != null) {
            json["customerService"] = customerService;
        }
        let preSale = this.isPreSale();
        if (preSale != null) {
            json["preSale"] = preSale;
        }
        let serviceClassifier = this.getServiceClassifier();
        if (serviceClassifier != null) {
            json["serviceClassifier"] = serviceClassifier;
        }
        let unreadMessageCountByWorker = this.getUnreadMessageCountByWorker();
        if (unreadMessageCountByWorker != null) {
            json["unreadMessageCountByWorker"] = unreadMessageCountByWorker;
        }
        let unreadMessageCountByRequestor = this.getUnreadMessageCountByRequestor();
        if (unreadMessageCountByRequestor != null) {
            json["unreadMessageCountByRequestor"] = unreadMessageCountByRequestor;
        }
    }



    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.service.request.EnterpriseServiceRequestInfo";
    }
}
