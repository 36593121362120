// @ts-nocheck
import {AbstractContentUpdateRecord} from "./AbstractContentUpdateRecord";
import {MessageType} from "../../../message/MessageContentType";

export class ContentUpdateRecord extends AbstractContentUpdateRecord {
  constructor() {
    super();
  }

  getMessageType(): MessageContentType {
    // Manual Code Here
  }

  /**
   * 必选数据： 所需更新问题ID 或者 答案ID
   */
  public withStartMessageId(
    startMessageId: number | string
  ): ContentUpdateRecord {
    this.startMessageId = startMessageId;
    return this;
  }

  /**
   * 必选数据： 本次修改原因
   */
  public withModifyReason(modifyReason: string): ContentUpdateRecord {
    this.modifyReason = modifyReason;
    return this;
  }

  /**
   * 必选数据： 内容
   */
  public withDescription(description: string): ContentUpdateRecord {
    this.description = description;
    return this;
  }

  /**
   * 必选数据： 是否为问题
   */
  public withQuestion(question: boolean): ContentUpdateRecord {
    this.question = question;
    return this;
  }
}
