/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { PositionDescriptor } from "./PositionDescriptor";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class PageBlockAccessSpec extends AbstractSerializable {
  pageSize: number;
  maxPagesPerBatch: number;
  startPosition: number;
  positionAttributes: PositionDescriptor;
  fectchedSize: number;
  forceRefresh: boolean;
  forwardContinuous: boolean;

  constructor() {
    super();
  }

  public getPageSize(): number {
    return this.pageSize;
  }

  public setPageSize(pageSize: number) {
    this.pageSize = pageSize;
  }

  public getMaxPagesPerBatch(): number {
    return this.maxPagesPerBatch;
  }

  public setMaxPagesPerBatch(maxPagesPerBatch: number) {
    this.maxPagesPerBatch = maxPagesPerBatch;
  }

  public getStartPosition(): number {
    return this.startPosition;
  }

  public setStartPosition(startPosition: number) {
    this.startPosition = startPosition;
  }

  public getPositionAttributes(): PositionDescriptor {
    return this.positionAttributes;
  }

  public setPositionAttributes(positionAttributes: PositionDescriptor) {
    this.positionAttributes = positionAttributes;
  }

  public getFectchedSize(): number {
    return this.fectchedSize;
  }

  public setFectchedSize(fectchedSize: number) {
    this.fectchedSize = fectchedSize;
  }

  public isForceRefresh(): boolean {
    return this.forceRefresh;
  }

  public setForceRefresh(forceRefresh: boolean) {
    this.forceRefresh = forceRefresh;
  }

  public isForwardContinuous(): boolean {
    return this.forwardContinuous;
  }

  public setForwardContinuous(forwardContinuous: boolean) {
    this.forwardContinuous = forwardContinuous;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const pageSize = json["pageSize"];
    if (pageSize != null) {
      this.setPageSize(pageSize);
    }
    const maxPagesPerBatch = json["maxPagesPerBatch"];
    if (maxPagesPerBatch != null) {
      this.setMaxPagesPerBatch(maxPagesPerBatch);
    }
    const startPosition = json["startPosition"];
    if (startPosition != null) {
      this.setStartPosition(startPosition);
    }
    const positionAttributes = json["positionAttributes"];
    if (positionAttributes != null) {
      const convertedPositionAttributes0 =
        Serializer.fillFromJsonObjectWithTypeTag(
          positionAttributes,
          "__type",
          factory
        );
      this.setPositionAttributes(convertedPositionAttributes0);
    }
    const fectchedSize = json["fectchedSize"];
    if (fectchedSize != null) {
      this.setFectchedSize(fectchedSize);
    }
    const forceRefresh = json["forceRefresh"];
    if (forceRefresh != null) {
      this.setForceRefresh(forceRefresh);
    }
    const forwardContinuous = json["forwardContinuous"];
    if (forwardContinuous != null) {
      this.setForwardContinuous(forwardContinuous);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const pageSize = this.getPageSize();
    if (pageSize != null) {
      json["pageSize"] = pageSize;
    }
    const maxPagesPerBatch = this.getMaxPagesPerBatch();
    if (maxPagesPerBatch != null) {
      json["maxPagesPerBatch"] = maxPagesPerBatch;
    }
    const startPosition = this.getStartPosition();
    if (startPosition != null) {
      json["startPosition"] = startPosition;
    }
    const positionAttributes = this.getPositionAttributes();
    if (positionAttributes != null) {
      const convertedPositionAttributes0 = positionAttributes.getJson(
        JsonTypeTagType.TYPE
      );
      json["positionAttributes"] = convertedPositionAttributes0;
    }
    const fectchedSize = this.getFectchedSize();
    if (fectchedSize != null) {
      json["fectchedSize"] = fectchedSize;
    }
    const forceRefresh = this.isForceRefresh();
    if (forceRefresh != null) {
      json["forceRefresh"] = forceRefresh;
    }
    const forwardContinuous = this.isForwardContinuous();
    if (forwardContinuous != null) {
      json["forwardContinuous"] = forwardContinuous;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.paging.PageBlockAccessSpec";
  }
}
