/**
 * 自动生成， 请不要手工修改！
 */

export enum EntityOperationType {
  CREATE,
  UPDATE,
  SUSPEND,
  DELETE,
  RELEASE,
}
