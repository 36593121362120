/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class SettlementBillAdjustRequest extends AbstractSerializable {
    requestId: (number | string);
    billId: (number | string);
    period: number;
    enterpriseId: (number | string);
    workerId: (number | string);
    currentAmount: number;
    adjustAmount: number;
    reason: string;
    createTime: (number | string);

    constructor() {
        super();
    }

    public getRequestId(): (number | string) {
        return this.requestId;
    }

    public setRequestId(requestId: (number | string)) {
        this.requestId = requestId;
    }

    public getBillId(): (number | string) {
        return this.billId;
    }

    public setBillId(billId: (number | string)) {
        this.billId = billId;
    }

    public getPeriod(): number {
        return this.period;
    }

    public setPeriod(period: number) {
        this.period = period;
    }

    public getEnterpriseId(): (number | string) {
        return this.enterpriseId;
    }

    public setEnterpriseId(enterpriseId: (number | string)) {
        this.enterpriseId = enterpriseId;
    }

    public getWorkerId(): (number | string) {
        return this.workerId;
    }

    public setWorkerId(workerId: (number | string)) {
        this.workerId = workerId;
    }

    public getCurrentAmount(): number {
        return this.currentAmount;
    }

    public setCurrentAmount(currentAmount: number) {
        this.currentAmount = currentAmount;
    }

    public getAdjustAmount(): number {
        return this.adjustAmount;
    }

    public setAdjustAmount(adjustAmount: number) {
        this.adjustAmount = adjustAmount;
    }

    public getReason(): string {
        return this.reason;
    }

    public setReason(reason: string) {
        this.reason = reason;
    }

    public getCreateTime(): (number | string) {
        return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
        this.createTime = createTime;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let requestId = json["requestId"];
        if (requestId != null) {
            this.setRequestId(requestId);
        }
        let billId = json["billId"];
        if (billId != null) {
            this.setBillId(billId);
        }
        let period = json["period"];
        if (period != null) {
            this.setPeriod(period);
        }
        let enterpriseId = json["enterpriseId"];
        if (enterpriseId != null) {
            this.setEnterpriseId(enterpriseId);
        }
        let workerId = json["workerId"];
        if (workerId != null) {
            this.setWorkerId(workerId);
        }
        let currentAmount = json["currentAmount"];
        if (currentAmount != null) {
            this.setCurrentAmount(currentAmount);
        }
        let adjustAmount = json["adjustAmount"];
        if (adjustAmount != null) {
            this.setAdjustAmount(adjustAmount);
        }
        let reason = json["reason"];
        if (reason != null) {
            this.setReason(reason);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let requestId = this.getRequestId();
        if (requestId != null) {
            json["requestId"] = String(requestId);
        }
        let billId = this.getBillId();
        if (billId != null) {
            json["billId"] = String(billId);
        }
        let period = this.getPeriod();
        if (period != null) {
            json["period"] = period;
        }
        let enterpriseId = this.getEnterpriseId();
        if (enterpriseId != null) {
            json["enterpriseId"] = String(enterpriseId);
        }
        let workerId = this.getWorkerId();
        if (workerId != null) {
            json["workerId"] = String(workerId);
        }
        let currentAmount = this.getCurrentAmount();
        if (currentAmount != null) {
            json["currentAmount"] = currentAmount;
        }
        let adjustAmount = this.getAdjustAmount();
        if (adjustAmount != null) {
            json["adjustAmount"] = adjustAmount;
        }
        let reason = this.getReason();
        if (reason != null) {
            json["reason"] = reason;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
    }



    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.service.settlement.SettlementBillAdjustRequest";
    }
}
