/**
 * 自动生成， 请不要手工修改！
 */

export enum EnterpriseJoinStatus {
  NOT_JOINED,
  ON_TRIAL,
  IN_REVIEW,
  JOINED,
}
