/**
 * 自动生成， 请不要手工修改！
 */

import {CoworkSimpleView} from '../../../cowork/CoworkSimpleView'
import {CoworkStats} from '../../../cowork/CoworkStats'
import {GeneralCoworkStats} from '../../../cowork/GeneralCoworkStats'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export abstract class AbstractEnterpriseServiceTradableStats extends GeneralCoworkStats  {

    constructor() {
    	super();
    }


    abstract addInfoToView(arg0: (CoworkSimpleView | null));

    abstract updateCoworkStats(arg0: (CoworkStats | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceTradableStats";
    }
}
