// @ts-nocheck
import { AbstractCompositeAnnouncementInfo } from "./AbstractCompositeAnnouncementInfo";

export class CompositeAnnouncementInfo extends AbstractCompositeAnnouncementInfo {
  constructor() {
    super();
  }

  getVisits(): number {
    // Manual Code Here
  }
}
