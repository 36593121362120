/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {CoworkType} from '../cowork/CoworkType'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {MessageType} from '../../message/MessageContentType'
import {MessageContent} from '../../message/MessageContent'
import {RequestStatusType} from './RequestStatusType'
import {RoleType} from '../entity/RoleType'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractRequestStatus extends AbstractSerializable implements MessageContent  {
    requestId: (number | string);
    requestorId: (number | string);
    targetType: CoworkType;
    targetId: (number | string);
    roleType: RoleType;
    targetEntityInfo: EntityInfo;
    refEntityInfo: EntityInfo;
    status: RequestStatusType;
    resolvedTime: (number | string);
    title: string;
    resolveNotes: string;
    customStatus: any;
    systemTips: boolean;

    constructor() {
    	super();
    }

    public getRequestId(): (number | string) {
    	return this.requestId;
    }

    /**
     * 必选数据： 请求ID
     */
    public setRequestId(requestId: (number | string)) {
    	this.requestId = requestId;
    }

    public getRequestorId(): (number | string) {
    	return this.requestorId;
    }

    /**
     * 必选数据： 请求者ID
     */
    public setRequestorId(requestorId: (number | string)) {
    	this.requestorId = requestorId;
    }

    public getTargetType(): CoworkType {
    	return this.targetType;
    }

    /**
     * 必选数据： 请求对象类型
     */
    public setTargetType(targetType: CoworkType) {
    	this.targetType = targetType;
    }

    public getTargetId(): (number | string) {
    	return this.targetId;
    }

    /**
     * 必选数据： 请求对象ID
     */
    public setTargetId(targetId: (number | string)) {
    	this.targetId = targetId;
    }

    public getRoleType(): RoleType {
    	return this.roleType;
    }

    /**
     * 可选数据： 请求角色
     */
    public setRoleType(roleType: RoleType) {
    	this.roleType = roleType;
    }

    public getTargetEntityInfo(): EntityInfo {
    	return this.targetEntityInfo;
    }

    /**
     * 必选数据： 请求对象所属社区ID
     */
    public setTargetEntityInfo(targetEntityInfo: EntityInfo) {
    	this.targetEntityInfo = targetEntityInfo;
    }

    public getRefEntityInfo(): EntityInfo {
    	return this.refEntityInfo;
    }

    /**
     * 必选数据： 请求者所代表的社区ID
     */
    public setRefEntityInfo(refEntityInfo: EntityInfo) {
    	this.refEntityInfo = refEntityInfo;
    }

    public getStatus(): RequestStatusType {
    	return this.status;
    }

    /**
     * 必选数据： 请求状态
     */
    public setStatus(status: RequestStatusType) {
    	this.status = status;
    }

    public getResolvedTime(): (number | string) {
    	return this.resolvedTime;
    }

    /**
     * 必选数据： 请求解决时间
     */
    public setResolvedTime(resolvedTime: (number | string)) {
    	this.resolvedTime = resolvedTime;
    }

    public getTitle(): string {
    	return this.title;
    }

    /**
     * 可选数据： 请求状态标题
     */
    public setTitle(title: string) {
    	this.title = title;
    }

    public getResolveNotes(): string {
    	return this.resolveNotes;
    }

    /**
     * 可选数据： 请求解决说明
     */
    public setResolveNotes(resolveNotes: string) {
    	this.resolveNotes = resolveNotes;
    }

    public getCustomStatus(): any {
    	return this.customStatus;
    }

    public setCustomStatus(customStatus: any) {
    	this.customStatus = customStatus;
    }

    public isSystemTips(): boolean {
    	return this.systemTips;
    }

    public setSystemTips(systemTips: boolean) {
    	this.systemTips = systemTips;
    }


    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let requestId = json["requestId"];
        if (requestId != null) {
            this.setRequestId(requestId);
        }
        let requestorId = json["requestorId"];
        if (requestorId != null) {
            this.setRequestorId(requestorId);
        }
        let targetType = json["targetType"];
        if (targetType != null) {
            let convertedTargetType0 = CoworkType[<string>targetType];
            this.setTargetType(convertedTargetType0);
        }
        let targetId = json["targetId"];
        if (targetId != null) {
            this.setTargetId(targetId);
        }
        let roleType = json["roleType"];
        if (roleType != null) {
            let convertedRoleType0 = RoleType[<string>roleType];
            this.setRoleType(convertedRoleType0);
        }
        let targetEntityInfo = json["targetEntityInfo"];
        if (targetEntityInfo != null) {
            let convertedTargetEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(targetEntityInfo, "__type", factory);
            this.setTargetEntityInfo(convertedTargetEntityInfo0);
        }
        let refEntityInfo = json["refEntityInfo"];
        if (refEntityInfo != null) {
            let convertedRefEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(refEntityInfo, "__type", factory);
            this.setRefEntityInfo(convertedRefEntityInfo0);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = RequestStatusType[<string>status];
            this.setStatus(convertedStatus0);
        }
        let resolvedTime = json["resolvedTime"];
        if (resolvedTime != null) {
            this.setResolvedTime(resolvedTime);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let resolveNotes = json["resolveNotes"];
        if (resolveNotes != null) {
            this.setResolveNotes(resolveNotes);
        }
        let customStatus = json["customStatus"];
        if (customStatus != null) {
            let convertedCustomStatus0 = Serializer.fromJson(customStatus, factory);
            this.setCustomStatus(convertedCustomStatus0);
        }
        let systemTips = json["systemTips"];
        if (systemTips != null) {
            this.setSystemTips(systemTips);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let requestId = this.getRequestId();
        if (requestId != null) {
            json["requestId"] = String(requestId);
        }
        let requestorId = this.getRequestorId();
        if (requestorId != null) {
            json["requestorId"] = String(requestorId);
        }
        let targetType = this.getTargetType();
        if (targetType != null) {
            let convertedTargetType0 = CoworkType[targetType];
            json["targetType"] = convertedTargetType0;
        }
        let targetId = this.getTargetId();
        if (targetId != null) {
            json["targetId"] = String(targetId);
        }
        let roleType = this.getRoleType();
        if (roleType != null) {
            let convertedRoleType0 = RoleType[roleType];
            json["roleType"] = convertedRoleType0;
        }
        let targetEntityInfo = this.getTargetEntityInfo();
        if (targetEntityInfo != null) {
            let convertedTargetEntityInfo0 = targetEntityInfo.getJson(JsonTypeTagType.TYPE);
            json["targetEntityInfo"] = convertedTargetEntityInfo0;
        }
        let refEntityInfo = this.getRefEntityInfo();
        if (refEntityInfo != null) {
            let convertedRefEntityInfo0 = refEntityInfo.getJson(JsonTypeTagType.TYPE);
            json["refEntityInfo"] = convertedRefEntityInfo0;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = RequestStatusType[status];
            json["status"] = convertedStatus0;
        }
        let resolvedTime = this.getResolvedTime();
        if (resolvedTime != null) {
            json["resolvedTime"] = String(resolvedTime);
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let resolveNotes = this.getResolveNotes();
        if (resolveNotes != null) {
            json["resolveNotes"] = resolveNotes;
        }
        let customStatus = this.getCustomStatus();
        if (customStatus != null) {
            let convertedCustomStatus0 = Serializer.getJson(customStatus, JsonTypeTagType.CLASS);
            json["customStatus"] = convertedCustomStatus0;
        }
        let systemTips = this.isSystemTips();
        if (systemTips != null) {
            json["systemTips"] = systemTips;
        }

        if (includeTypeTag) {
            json["__type"] = "RequestStatus";
        }
    }


    public getTypeId(): string {
    	return "RequestStatus";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.request.RequestStatus";
    }
}
