/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { EntityInfo } from "../../entity/EntityInfo";
import {JsonTypeTagType} from "../../../serialize/JsonTypeTagType";
import {MessageType} from "../../../message/MessageContentType";
import {MessageContent} from "../../../message/MessageContent";
import { Serializer } from "../../../serialize/Serializer";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractContentUpdateRecord
  extends AbstractSerializable
  implements MessageContent
{
  id: number | string;
  targetEntityId: number | string;
  startMessageId: number | string;
  modifyReason: string;
  senderInfo: EntityInfo;
  title: string;
  description: string;
  picturesUrl: Array<string>;
  createTime: number | string;
  question: boolean;

  constructor() {
    super();
  }

  public getId(): number | string {
    return this.id;
  }

  public setId(id: number | string) {
    this.id = id;
  }

  public getTargetEntityId(): number | string {
    return this.targetEntityId;
  }

  public setTargetEntityId(targetEntityId: number | string) {
    this.targetEntityId = targetEntityId;
  }

  public getStartMessageId(): number | string {
    return this.startMessageId;
  }

  /**
   * 必选数据： 所需更新问题ID 或者 答案ID
   */
  public setStartMessageId(startMessageId: number | string) {
    this.startMessageId = startMessageId;
  }

  public getModifyReason(): string {
    return this.modifyReason;
  }

  /**
   * 必选数据： 本次修改原因
   */
  public setModifyReason(modifyReason: string) {
    this.modifyReason = modifyReason;
  }

  public getSenderInfo(): EntityInfo {
    return this.senderInfo;
  }

  /**
   * 可选数据： 提交者ID
   */
  public setSenderInfo(senderInfo: EntityInfo) {
    this.senderInfo = senderInfo;
  }

  public getTitle(): string {
    return this.title;
  }

  /**
   * 可选数据： 标题（问题必填）
   */
  public setTitle(title: string) {
    this.title = title;
  }

  public getDescription(): string {
    return this.description;
  }

  /**
   * 必选数据： 内容
   */
  public setDescription(description: string) {
    this.description = description;
  }

  public getPicturesUrl(): Array<string> {
    return this.picturesUrl;
  }

  /**
   * 可选数据： 图片（问题必填）
   */
  public setPicturesUrl(picturesUrl: Array<string>) {
    this.picturesUrl = picturesUrl;
  }

  public getCreateTime(): number | string {
    return this.createTime;
  }

  /**
   * 可选数据： 提交时间
   */
  public setCreateTime(createTime: number | string) {
    this.createTime = createTime;
  }

  public isQuestion(): boolean {
    return this.question;
  }

  /**
   * 必选数据： 是否为问题
   */
  public setQuestion(question: boolean) {
    this.question = question;
  }

  abstract getMessageType(): MessageType

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const id = json["id"];
    if (id != null) {
      this.setId(id);
    }
    const targetEntityId = json["targetEntityId"];
    if (targetEntityId != null) {
      this.setTargetEntityId(targetEntityId);
    }
    const startMessageId = json["startMessageId"];
    if (startMessageId != null) {
      this.setStartMessageId(startMessageId);
    }
    const modifyReason = json["modifyReason"];
    if (modifyReason != null) {
      this.setModifyReason(modifyReason);
    }
    const senderInfo = json["senderInfo"];
    if (senderInfo != null) {
      const convertedSenderInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        senderInfo,
        "__type",
        factory
      );
      this.setSenderInfo(convertedSenderInfo0);
    }
    const title = json["title"];
    if (title != null) {
      this.setTitle(title);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    let picturesUrl = json["picturesUrl"];
    if (picturesUrl != null) {
      const convertedPicturesUrl0 = [];
      picturesUrl = picturesUrl[1];
      for (const i in picturesUrl) {
        const convertedPicturesUrl10 = picturesUrl[i];
        convertedPicturesUrl0.push(convertedPicturesUrl10);
      }
      this.setPicturesUrl(convertedPicturesUrl0);
    }
    const createTime = json["createTime"];
    if (createTime != null) {
      this.setCreateTime(createTime);
    }
    const question = json["question"];
    if (question != null) {
      this.setQuestion(question);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const id = this.getId();
    if (id != null) {
      json["id"] = String(id);
    }
    const targetEntityId = this.getTargetEntityId();
    if (targetEntityId != null) {
      json["targetEntityId"] = String(targetEntityId);
    }
    const startMessageId = this.getStartMessageId();
    if (startMessageId != null) {
      json["startMessageId"] = String(startMessageId);
    }
    const modifyReason = this.getModifyReason();
    if (modifyReason != null) {
      json["modifyReason"] = modifyReason;
    }
    const senderInfo = this.getSenderInfo();
    if (senderInfo != null) {
      const convertedSenderInfo0 = senderInfo.getJson(JsonTypeTagType.TYPE);
      json["senderInfo"] = convertedSenderInfo0;
    }
    const title = this.getTitle();
    if (title != null) {
      json["title"] = title;
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const picturesUrl = this.getPicturesUrl();
    if (picturesUrl != null) {
      const convertedPicturesUrl0 = [];
      for (const i in picturesUrl) {
        const convertedPicturesUrl10 = picturesUrl[i];
        convertedPicturesUrl0.push(convertedPicturesUrl10);
      }
      const convertedPicturesUrlWithType1 = [
        "java.util.ArrayList",
        convertedPicturesUrl0,
      ];
      json["picturesUrl"] = convertedPicturesUrlWithType1;
    }
    const createTime = this.getCreateTime();
    if (createTime != null) {
      json["createTime"] = String(createTime);
    }
    const question = this.isQuestion();
    if (question != null) {
      json["question"] = question;
    }

    if (includeTypeTag) {
      json["__type"] = "ContentUpdateRecord";
    }
  }

  public getTypeId(): string {
    return "ContentUpdateRecord";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.qa.ContentUpdateRecord";
  }
}
