/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { EntityInfo } from "./EntityInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { RoleType } from "./RoleType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class AssociatedEntityInfo extends AbstractSerializable {
  entityInfo: EntityInfo;
  roleType: RoleType;
  officialRep: boolean;
  admin: boolean;

  constructor() {
    super();
  }

  public getEntityInfo(): EntityInfo {
    return this.entityInfo;
  }

  public setEntityInfo(entityInfo: EntityInfo) {
    this.entityInfo = entityInfo;
  }

  public getRoleType(): RoleType {
    return this.roleType;
  }

  public setRoleType(roleType: RoleType) {
    this.roleType = roleType;
  }

  public isOfficialRep(): boolean {
    return this.officialRep;
  }

  public setOfficialRep(officialRep: boolean) {
    this.officialRep = officialRep;
  }

  public isAdmin(): boolean {
    return this.admin;
  }

  public setAdmin(admin: boolean) {
    this.admin = admin;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const entityInfo = json["entityInfo"];
    if (entityInfo != null) {
      const convertedEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        entityInfo,
        "__type",
        factory
      );
      this.setEntityInfo(convertedEntityInfo0);
    }
    const roleType = json["roleType"];
    if (roleType != null) {
      const convertedRoleType0 = RoleType[<string>roleType];
      this.setRoleType(convertedRoleType0);
    }
    const officialRep = json["officialRep"];
    if (officialRep != null) {
      this.setOfficialRep(officialRep);
    }
    const admin = json["admin"];
    if (admin != null) {
      this.setAdmin(admin);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const entityInfo = this.getEntityInfo();
    if (entityInfo != null) {
      const convertedEntityInfo0 = entityInfo.getJson(JsonTypeTagType.TYPE);
      json["entityInfo"] = convertedEntityInfo0;
    }
    const roleType = this.getRoleType();
    if (roleType != null) {
      const convertedRoleType0 = RoleType[roleType];
      json["roleType"] = convertedRoleType0;
    }
    const officialRep = this.isOfficialRep();
    if (officialRep != null) {
      json["officialRep"] = officialRep;
    }
    const admin = this.isAdmin();
    if (admin != null) {
      json["admin"] = admin;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.AssociatedEntityInfo";
  }
}
