/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../../../common/AbstractClientServiceAdapter'
import {AbstractEnterpriseServiceTrade} from '../../../datatype/business/service/trade/AbstractEnterpriseServiceTrade'
import {BiConsumer} from '../../../util/BiConsumer'
import {CompositeEnterpriseServiceTradableInfo} from '../../../datatype/business/service/tradable/CompositeEnterpriseServiceTradableInfo'
import {EnterpriseServiceCategorySpec} from '../../../datatype/business/service/EnterpriseServiceCategorySpec'
import {EnterpriseServiceCategory} from '../../../datatype/business/service/EnterpriseServiceCategory'
import {EnterpriseServicePackageService} from '../../../api/business/service/EnterpriseServicePackageService'
import {EnterpriseServiceTradable} from '../../../datatype/business/service/tradable/EnterpriseServiceTradable'
import {EnterpriseServiceType} from '../../../datatype/business/service/EnterpriseServiceType'
import {EnterpriseServiceView} from '../../../datatype/business/service/tradable/EnterpriseServiceView'
import {GeoEntityDesc} from '../../../datatype/geolocation/GeoEntityDesc'
import {Location} from '../../../datatype/geolocation/Location'
import {PageableData} from '../../../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../../../datatype/paging/PageBlockAccessSpec'
import {PlatformHighlightedServiceInfos} from '../../../datatype/business/service/tradable/PlatformHighlightedServiceInfos'
import {PlatformMemberType} from '../../../datatype/business/service/PlatformMemberType'
import {ReviewOverview} from '../../../datatype/ipsphere/review/ReviewOverview'
import {SectorServiceSpec} from '../../../datatype/entity/sector/SectorServiceSpec'
import {ServiceConsumers} from '../../../util/ServiceConsumers'
import {ServiceHighlight} from '../../../datatype/business/service/ServiceHighlight'
import {ServiceRequest} from '../../../common/ServiceRequest'
import {ServiceTradableSeriesDisplaySpec} from '../../../datatype/business/service/tradable/ServiceTradableSeriesDisplaySpec'
import {SessionContext} from '../../../session/SessionContext'
import {UserServiceTradableInfo} from '../../../datatype/business/service/tradable/UserServiceTradableInfo'


export class EnterpriseServicePackageServiceProxy extends AbstractClientServiceAdapter implements EnterpriseServicePackageService {

    public constructor() {
        super("com.gong_wei.service.api.business.service.EnterpriseServicePackageService");
    }

    public addEnterpriseService(sessionContext: (SessionContext | null), enterpriseService: (EnterpriseServiceTradable | null), resultHandler: BiConsumer<(number | string), any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "addEnterpriseService", [sessionContext, enterpriseService]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public cancelEnterpriseService(sessionContext: (SessionContext | null), enterpriseServiceId: (number | string | null), reason: (string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "cancelEnterpriseService", [sessionContext, enterpriseServiceId, reason]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceById(sessionContext: (SessionContext | null), enterpriseServiceId: (number | string | null), resultHandler: BiConsumer<CompositeEnterpriseServiceTradableInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceById", [sessionContext, enterpriseServiceId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceByIdAndPkgId(sessionContext: (SessionContext | null), tradableId: (number | string | null), pkgTradableId: (number | string | null), resultHandler: BiConsumer<CompositeEnterpriseServiceTradableInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceByIdAndPkgId", [sessionContext, tradableId, pkgTradableId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceByMemberType(sessionContext: (SessionContext | null), memberType: (PlatformMemberType | null), resultHandler: BiConsumer<CompositeEnterpriseServiceTradableInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceByMemberType", [sessionContext, memberType]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceProfessionals(sessionContext: (SessionContext | null), enterpriseServiceId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceProfessionals", [sessionContext, enterpriseServiceId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceProfessionalsByRegionAndCategory(sessionContext: (SessionContext | null), regionId: (number | string | null), category: (EnterpriseServiceCategory | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceProfessionalsByRegionAndCategory", [sessionContext, regionId, category, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceProfessionalsByRegionAndServiceType(sessionContext: (SessionContext | null), regionId: (number | string | null), serviceType: (EnterpriseServiceType | null), resultHandler: BiConsumer<Array<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceProfessionalsByRegionAndServiceType", [sessionContext, regionId, serviceType]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceSuppliers(sessionContext: (SessionContext | null), enterpriseServiceId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceSuppliers", [sessionContext, enterpriseServiceId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceSuppliersByRegionAndCategory(sessionContext: (SessionContext | null), regionId: (number | string | null), category: (EnterpriseServiceCategory | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceSuppliersByRegionAndCategory", [sessionContext, regionId, category, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceSuppliersByRegionAndServiceType(sessionContext: (SessionContext | null), regionId: (number | string | null), serviceType: (EnterpriseServiceType | null), resultHandler: BiConsumer<Array<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceSuppliersByRegionAndServiceType", [sessionContext, regionId, serviceType]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceTradeByTradableId(sessionContext: (SessionContext | null), enterpriseServiceId: (number | string | null), resultHandler: BiConsumer<AbstractEnterpriseServiceTrade, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceTradeByTradableId", [sessionContext, enterpriseServiceId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceTradeByTradableIdInPkg(sessionContext: (SessionContext | null), tradableId: (number | string | null), pkgTradableId: (number | string | null), resultHandler: BiConsumer<AbstractEnterpriseServiceTrade, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServiceTradeByTradableIdInPkg", [sessionContext, tradableId, pkgTradableId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServicesByAssociationId(sessionContext: (SessionContext | null), associationId: (number | string | null), serviceCategory: (EnterpriseServiceCategory | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServicesByAssociationId", [sessionContext, associationId, serviceCategory, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServicesByEnterpriseId(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), serviceCategory: (EnterpriseServiceCategory | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServicesByEnterpriseId", [sessionContext, enterpriseId, serviceCategory, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServicesByRegion(sessionContext: (SessionContext | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServicesByRegion", [sessionContext, regionId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServicesByServiceTypeAndRegion(sessionContext: (SessionContext | null), serviceType: (EnterpriseServiceType | null), regionId: (number | string | null), resultHandler: BiConsumer<Array<EnterpriseServiceView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServicesByServiceTypeAndRegion", [sessionContext, serviceType, regionId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServicesInPackage(sessionContext: (SessionContext | null), enterpriseServicePackageId: (number | string | null), resultHandler: BiConsumer<Array<EnterpriseServiceView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServicesInPackage", [sessionContext, enterpriseServicePackageId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServicesInSeries(sessionContext: (SessionContext | null), seriesId: (number | string | null), resultHandler: BiConsumer<Array<CompositeEnterpriseServiceTradableInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findEnterpriseServicesInSeries", [sessionContext, seriesId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findRegionalServicesByAssociationId(sessionContext: (SessionContext | null), associationId: (number | string | null), regionId: (number | string | null), serviceCategory: (EnterpriseServiceCategory | null), isPackage: (boolean | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findRegionalServicesByAssociationId", [sessionContext, associationId, regionId, serviceCategory, isPackage, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findReviewsByServiceId(sessionContext: (SessionContext | null), serviceId: (number | string | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<ReviewOverview>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findReviewsByServiceId", [sessionContext, serviceId, regionId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findServiceIdByEnterpriseOnRegionAndCategory(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), regionId: (number | string | null), category: (EnterpriseServiceCategory | null), resultHandler: BiConsumer<(number | string), any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findServiceIdByEnterpriseOnRegionAndCategory", [sessionContext, enterpriseId, regionId, category]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findServiceIdByProfessionalOnRegionAndCategory(sessionContext: (SessionContext | null), professionalId: (number | string | null), regionId: (number | string | null), category: (EnterpriseServiceCategory | null), resultHandler: BiConsumer<(number | string), any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findServiceIdByProfessionalOnRegionAndCategory", [sessionContext, professionalId, regionId, category]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findUserServiceTradableInfoByTradableId(sessionContext: (SessionContext | null), tradableId: (number | string | null), resultHandler: BiConsumer<UserServiceTradableInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findUserServiceTradableInfoByTradableId", [sessionContext, tradableId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findUserServiceTradableInfoByTradableIdAndPkgTradeId(sessionContext: (SessionContext | null), tradableId: (number | string | null), pkgTradeId: (number | string | null), resultHandler: BiConsumer<UserServiceTradableInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findUserServiceTradableInfoByTradableIdAndPkgTradeId", [sessionContext, tradableId, pkgTradeId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findUserServiceTradableInfoByTradableIdInPkg(sessionContext: (SessionContext | null), tradableId: (number | string | null), pkgTradableId: (number | string | null), resultHandler: BiConsumer<UserServiceTradableInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "findUserServiceTradableInfoByTradableIdInPkg", [sessionContext, tradableId, pkgTradableId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getAllDisplayableServiceCategories(sessionContext: (SessionContext | null), resultHandler: BiConsumer<Array<EnterpriseServiceCategory>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "getAllDisplayableServiceCategories", [sessionContext]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getAllDisplayableServiceCategorySpecs(sessionContext: (SessionContext | null), regionId: (number | string | null), resultHandler: BiConsumer<Array<EnterpriseServiceCategorySpec>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "getAllDisplayableServiceCategorySpecs", [sessionContext, regionId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getGeneralServicesByRegionAndCategory(sessionContext: (SessionContext | null), regionId: (number | string | null), serviceCategory: (EnterpriseServiceCategory | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<SectorServiceSpec>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "getGeneralServicesByRegionAndCategory", [sessionContext, regionId, serviceCategory, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getPlatformHighlightedServiceInfosByCategoryForLocation(sessionContext: (SessionContext | null), category: (EnterpriseServiceCategory | null), location: (Location | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformHighlightedServiceInfos, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "getPlatformHighlightedServiceInfosByCategoryForLocation", [sessionContext, category, location, regionId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getPlatformHighlightedServiceInfosByMemberTypeForLocation(sessionContext: (SessionContext | null), memberType: (PlatformMemberType | null), location: (Location | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformHighlightedServiceInfos, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "getPlatformHighlightedServiceInfosByMemberTypeForLocation", [sessionContext, memberType, location, regionId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getPlatformHighlightedServiceInfosToUpgradeForLocation(sessionContext: (SessionContext | null), currentMemberType: (PlatformMemberType | null), location: (Location | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformHighlightedServiceInfos, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "getPlatformHighlightedServiceInfosToUpgradeForLocation", [sessionContext, currentMemberType, location, regionId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getPlatformServiceHighlightsByCategoryForLocation(sessionContext: (SessionContext | null), category: (EnterpriseServiceCategory | null), location: (Location | null), regionId: (number | string | null), resultHandler: BiConsumer<Array<ServiceHighlight>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "getPlatformServiceHighlightsByCategoryForLocation", [sessionContext, category, location, regionId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getPlatformServicesByCategoryForLocation(sessionContext: (SessionContext | null), category: (EnterpriseServiceCategory | null), location: (Location | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "getPlatformServicesByCategoryForLocation", [sessionContext, category, location, regionId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getServiceTradableSeriesDisplaySpec(sessionContext: (SessionContext | null), seriesId: (number | string | null), currentPkgId: (number | string | null), resultHandler: BiConsumer<ServiceTradableSeriesDisplaySpec, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "getServiceTradableSeriesDisplaySpec", [sessionContext, seriesId, currentPkgId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getServiceTradableSeriesDisplaySpecByMemberType(sessionContext: (SessionContext | null), memberType: (PlatformMemberType | null), resultHandler: BiConsumer<ServiceTradableSeriesDisplaySpec, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServicePackageService", "getServiceTradableSeriesDisplaySpecByMemberType", [sessionContext, memberType]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

}
