/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {AssociationType} from './AssociationType'
import {EnterpriseType} from '../enterprise/EnterpriseType'
import {EntityType} from '../EntityType'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {PlatformMemberType} from '../../business/service/PlatformMemberType'
import {RoleType} from '../RoleType'
import {SectorServiceSpec} from './SectorServiceSpec'
import {SectorTopicType} from './SectorTopicType'
import {TopicType} from '../topic/TopicType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class SectorTopicSpec extends AbstractSerializable {
    title: string;
    sectorTopicType: SectorTopicType;
    imgUrl: string;
    serviceSpec: SectorServiceSpec;
    entityId: (number | string);
    entityName: string;
    entityType: EntityType;
    enterpriseType: EnterpriseType;
    associationType: AssociationType;
    topicType: TopicType;
    memberType: PlatformMemberType;
    regionId: (number | string);
    roleType: RoleType;
    manager: boolean;
    showCombinedOnly: boolean;

    constructor() {
        super();
    }

    public getTitle(): string {
        return this.title;
    }

    public setTitle(title: string) {
        this.title = title;
    }

    public getSectorTopicType(): SectorTopicType {
        return this.sectorTopicType;
    }

    public setSectorTopicType(sectorTopicType: SectorTopicType) {
        this.sectorTopicType = sectorTopicType;
    }

    public getImgUrl(): string {
        return this.imgUrl;
    }

    public setImgUrl(imgUrl: string) {
        this.imgUrl = imgUrl;
    }

    public getServiceSpec(): SectorServiceSpec {
        return this.serviceSpec;
    }

    public setServiceSpec(serviceSpec: SectorServiceSpec) {
        this.serviceSpec = serviceSpec;
    }

    public getEntityId(): (number | string) {
        return this.entityId;
    }

    public setEntityId(entityId: (number | string)) {
        this.entityId = entityId;
    }

    public getEntityName(): string {
        return this.entityName;
    }

    public setEntityName(entityName: string) {
        this.entityName = entityName;
    }

    public getEntityType(): EntityType {
        return this.entityType;
    }

    public setEntityType(entityType: EntityType) {
        this.entityType = entityType;
    }

    public getEnterpriseType(): EnterpriseType {
        return this.enterpriseType;
    }

    public setEnterpriseType(enterpriseType: EnterpriseType) {
        this.enterpriseType = enterpriseType;
    }

    public getAssociationType(): AssociationType {
        return this.associationType;
    }

    public setAssociationType(associationType: AssociationType) {
        this.associationType = associationType;
    }

    public getTopicType(): TopicType {
        return this.topicType;
    }

    public setTopicType(topicType: TopicType) {
        this.topicType = topicType;
    }

    public getMemberType(): PlatformMemberType {
        return this.memberType;
    }

    public setMemberType(memberType: PlatformMemberType) {
        this.memberType = memberType;
    }

    public getRegionId(): (number | string) {
        return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
        this.regionId = regionId;
    }

    public getRoleType(): RoleType {
        return this.roleType;
    }

    public setRoleType(roleType: RoleType) {
        this.roleType = roleType;
    }

    public isManager(): boolean {
        return this.manager;
    }

    public setManager(manager: boolean) {
        this.manager = manager;
    }

    public isShowCombinedOnly(): boolean {
        return this.showCombinedOnly;
    }

    public setShowCombinedOnly(showCombinedOnly: boolean) {
        this.showCombinedOnly = showCombinedOnly;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let sectorTopicType = json["sectorTopicType"];
        if (sectorTopicType != null) {
            let convertedSectorTopicType0 = SectorTopicType[<string>sectorTopicType];
            this.setSectorTopicType(convertedSectorTopicType0);
        }
        let imgUrl = json["imgUrl"];
        if (imgUrl != null) {
            this.setImgUrl(imgUrl);
        }
        let serviceSpec = json["serviceSpec"];
        if (serviceSpec != null) {
            let convertedServiceSpec0 = new SectorServiceSpec();
            convertedServiceSpec0.fillFromJson(serviceSpec, factory);
            this.setServiceSpec(convertedServiceSpec0);
        }
        let entityId = json["entityId"];
        if (entityId != null) {
            this.setEntityId(entityId);
        }
        let entityName = json["entityName"];
        if (entityName != null) {
            this.setEntityName(entityName);
        }
        let entityType = json["entityType"];
        if (entityType != null) {
            let convertedEntityType0 = EntityType[<string>entityType];
            this.setEntityType(convertedEntityType0);
        }
        let enterpriseType = json["enterpriseType"];
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[<string>enterpriseType];
            this.setEnterpriseType(convertedEnterpriseType0);
        }
        let associationType = json["associationType"];
        if (associationType != null) {
            let convertedAssociationType0 = AssociationType[<string>associationType];
            this.setAssociationType(convertedAssociationType0);
        }
        let topicType = json["topicType"];
        if (topicType != null) {
            let convertedTopicType0 = TopicType[<string>topicType];
            this.setTopicType(convertedTopicType0);
        }
        let memberType = json["memberType"];
        if (memberType != null) {
            let convertedMemberType0 = PlatformMemberType[<string>memberType];
            this.setMemberType(convertedMemberType0);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let roleType = json["roleType"];
        if (roleType != null) {
            let convertedRoleType0 = RoleType[<string>roleType];
            this.setRoleType(convertedRoleType0);
        }
        let manager = json["manager"];
        if (manager != null) {
            this.setManager(manager);
        }
        let showCombinedOnly = json["showCombinedOnly"];
        if (showCombinedOnly != null) {
            this.setShowCombinedOnly(showCombinedOnly);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let sectorTopicType = this.getSectorTopicType();
        if (sectorTopicType != null) {
            let convertedSectorTopicType0 = SectorTopicType[sectorTopicType];
            json["sectorTopicType"] = convertedSectorTopicType0;
        }
        let imgUrl = this.getImgUrl();
        if (imgUrl != null) {
            json["imgUrl"] = imgUrl;
        }
        let serviceSpec = this.getServiceSpec();
        if (serviceSpec != null) {
            let convertedServiceSpec0 = serviceSpec.getJson(JsonTypeTagType.NONE);
            json["serviceSpec"] = convertedServiceSpec0;
        }
        let entityId = this.getEntityId();
        if (entityId != null) {
            json["entityId"] = String(entityId);
        }
        let entityName = this.getEntityName();
        if (entityName != null) {
            json["entityName"] = entityName;
        }
        let entityType = this.getEntityType();
        if (entityType != null) {
            let convertedEntityType0 = EntityType[entityType];
            json["entityType"] = convertedEntityType0;
        }
        let enterpriseType = this.getEnterpriseType();
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[enterpriseType];
            json["enterpriseType"] = convertedEnterpriseType0;
        }
        let associationType = this.getAssociationType();
        if (associationType != null) {
            let convertedAssociationType0 = AssociationType[associationType];
            json["associationType"] = convertedAssociationType0;
        }
        let topicType = this.getTopicType();
        if (topicType != null) {
            let convertedTopicType0 = TopicType[topicType];
            json["topicType"] = convertedTopicType0;
        }
        let memberType = this.getMemberType();
        if (memberType != null) {
            let convertedMemberType0 = PlatformMemberType[memberType];
            json["memberType"] = convertedMemberType0;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let roleType = this.getRoleType();
        if (roleType != null) {
            let convertedRoleType0 = RoleType[roleType];
            json["roleType"] = convertedRoleType0;
        }
        let manager = this.isManager();
        if (manager != null) {
            json["manager"] = manager;
        }
        let showCombinedOnly = this.isShowCombinedOnly();
        if (showCombinedOnly != null) {
            json["showCombinedOnly"] = showCombinedOnly;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.sector.SectorTopicSpec";
    }
}
