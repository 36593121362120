/**
 * 自动生成， 请不要手工修改！
 */

export enum CreditGrade {
  A,
  B,
  C,
  D,
  E,
  N,
  Z,
}
