/**
 * 自动生成， 请不要手工修改！
 */


export enum RequestType {
    REPORT_BAD,
    REPORT_FALSE,
    REPORT_ILLEGAL,
    REPORT_FRAUD,
    REP_VIOLATION,
    REP_POOR_AD,
    REP_BAD_ENT,
    REP_BAD_PROD,
    REP_GENERAL,
    SELF_CANCEL,
    REQ_NEW_ROLE,
    REQ_DEL_ROLE,
    REQ_ENT_REIVEW,
    BG_CHECK,
    REQ_ENT_GROUP_MEMBER,
    REQ_ENT_GROUP_MEMBER_REVIEW,
    SERVICE_REQ_ASSIGN,
    SERVICE_REQ_DELEGATE
}
