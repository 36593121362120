/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCowork} from '../../cowork/AbstractCowork'
import {CoworkIdentifier} from '../../cowork/CoworkIdentifier'
import {EntityInfo} from '../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../keyword/KeywordEntity'
import {MessageType} from '../../../message/MessageContentType'
import {QllCountry} from '../../international/QllCountry'
import {ReviewStatus} from './ReviewStatus'
import {ReviewType} from './ReviewType'
import {Serializer} from '../../../serialize/Serializer'
import {ServiceTradableDesc} from '../../business/service/tradable/ServiceTradableDesc'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractBasicReview extends AbstractCowork  {
    reviewScore: number;
    reviewType: ReviewType;
    status: ReviewStatus;
    employeeInfo: EntityInfo;
    productInfo: EntityInfo;
    advice: string;
    regionName: string;
    localPoint: boolean;
    forOneCard: boolean;
    refCoworkId: CoworkIdentifier;
    serviceDesc: ServiceTradableDesc;

    constructor() {
    	super();
    }

    public getReviewScore(): number {
    	return this.reviewScore;
    }

    /**
     * 必选数据： 点评得分
     */
    public setReviewScore(reviewScore: number) {
    	this.reviewScore = reviewScore;
    }

    public getReviewType(): ReviewType {
    	return this.reviewType;
    }

    /**
     * 必选数据： 点评类型-推荐 建议
     */
    public setReviewType(reviewType: ReviewType) {
    	this.reviewType = reviewType;
    }

    public getStatus(): ReviewStatus {
    	return this.status;
    }

    public setStatus(status: ReviewStatus) {
    	this.status = status;
    }

    public getEmployeeInfo(): EntityInfo {
    	return this.employeeInfo;
    }

    public setEmployeeInfo(employeeInfo: EntityInfo) {
    	this.employeeInfo = employeeInfo;
    }

    public getProductInfo(): EntityInfo {
    	return this.productInfo;
    }

    public setProductInfo(productInfo: EntityInfo) {
    	this.productInfo = productInfo;
    }

    public getAdvice(): string {
    	return this.advice;
    }

    public setAdvice(advice: string) {
    	this.advice = advice;
    }

    public getRegionName(): string {
    	return this.regionName;
    }

    public setRegionName(regionName: string) {
    	this.regionName = regionName;
    }

    public isLocalPoint(): boolean {
    	return this.localPoint;
    }

    public setLocalPoint(localPoint: boolean) {
    	this.localPoint = localPoint;
    }

    public isForOneCard(): boolean {
    	return this.forOneCard;
    }

    public setForOneCard(forOneCard: boolean) {
    	this.forOneCard = forOneCard;
    }

    public getRefCoworkId(): CoworkIdentifier {
    	return this.refCoworkId;
    }

    public setRefCoworkId(refCoworkId: CoworkIdentifier) {
    	this.refCoworkId = refCoworkId;
    }

    public getServiceDesc(): ServiceTradableDesc {
    	return this.serviceDesc;
    }

    public setServiceDesc(serviceDesc: ServiceTradableDesc) {
    	this.serviceDesc = serviceDesc;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let reviewScore = json["reviewScore"];
        if (reviewScore != null) {
            this.setReviewScore(reviewScore);
        }
        let reviewType = json["reviewType"];
        if (reviewType != null) {
            let convertedReviewType0 = ReviewType[<string>reviewType];
            this.setReviewType(convertedReviewType0);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = ReviewStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let employeeInfo = json["employeeInfo"];
        if (employeeInfo != null) {
            let convertedEmployeeInfo0 = Serializer.fillFromJsonObjectWithTypeTag(employeeInfo, "__type", factory);
            this.setEmployeeInfo(convertedEmployeeInfo0);
        }
        let productInfo = json["productInfo"];
        if (productInfo != null) {
            let convertedProductInfo0 = Serializer.fillFromJsonObjectWithTypeTag(productInfo, "__type", factory);
            this.setProductInfo(convertedProductInfo0);
        }
        let advice = json["advice"];
        if (advice != null) {
            this.setAdvice(advice);
        }
        let regionName = json["regionName"];
        if (regionName != null) {
            this.setRegionName(regionName);
        }
        let localPoint = json["localPoint"];
        if (localPoint != null) {
            this.setLocalPoint(localPoint);
        }
        let forOneCard = json["forOneCard"];
        if (forOneCard != null) {
            this.setForOneCard(forOneCard);
        }
        let refCoworkId = json["refCoworkId"];
        if (refCoworkId != null) {
            let convertedRefCoworkId0 = new CoworkIdentifier();
            convertedRefCoworkId0.fillFromJson(refCoworkId, factory);
            this.setRefCoworkId(convertedRefCoworkId0);
        }
        let serviceDesc = json["serviceDesc"];
        if (serviceDesc != null) {
            let convertedServiceDesc0 = new ServiceTradableDesc();
            convertedServiceDesc0.fillFromJson(serviceDesc, factory);
            this.setServiceDesc(convertedServiceDesc0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let reviewScore = this.getReviewScore();
        if (reviewScore != null) {
            json["reviewScore"] = reviewScore;
        }
        let reviewType = this.getReviewType();
        if (reviewType != null) {
            let convertedReviewType0 = ReviewType[reviewType];
            json["reviewType"] = convertedReviewType0;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = ReviewStatus[status];
            json["status"] = convertedStatus0;
        }
        let employeeInfo = this.getEmployeeInfo();
        if (employeeInfo != null) {
            let convertedEmployeeInfo0 = employeeInfo.getJson(JsonTypeTagType.TYPE);
            json["employeeInfo"] = convertedEmployeeInfo0;
        }
        let productInfo = this.getProductInfo();
        if (productInfo != null) {
            let convertedProductInfo0 = productInfo.getJson(JsonTypeTagType.TYPE);
            json["productInfo"] = convertedProductInfo0;
        }
        let advice = this.getAdvice();
        if (advice != null) {
            json["advice"] = advice;
        }
        let regionName = this.getRegionName();
        if (regionName != null) {
            json["regionName"] = regionName;
        }
        let localPoint = this.isLocalPoint();
        if (localPoint != null) {
            json["localPoint"] = localPoint;
        }
        let forOneCard = this.isForOneCard();
        if (forOneCard != null) {
            json["forOneCard"] = forOneCard;
        }
        let refCoworkId = this.getRefCoworkId();
        if (refCoworkId != null) {
            let convertedRefCoworkId0 = refCoworkId.getJson(JsonTypeTagType.NONE);
            json["refCoworkId"] = convertedRefCoworkId0;
        }
        let serviceDesc = this.getServiceDesc();
        if (serviceDesc != null) {
            let convertedServiceDesc0 = serviceDesc.getJson(JsonTypeTagType.NONE);
            json["serviceDesc"] = convertedServiceDesc0;
        }

        if (includeTypeTag) {
            json["__type"] = "BasicReview";
        }
    }


    public getTypeId(): string {
    	return "BasicReview";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.ipsphere.review.BasicReview";
    }
}
