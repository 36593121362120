/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../../serialize/JsonTypeTagType";
import { ServiceRequestClassifierItem } from "./ServiceRequestClassifierItem";
import { TypeFactory } from "../../../../serialize/TypeFactory";

export class ServiceRequestClassifier extends AbstractSerializable {
  classifierItems: Array<ServiceRequestClassifierItem>;

  constructor() {
    super();
  }

  public getClassifierItems(): Array<ServiceRequestClassifierItem> {
    return this.classifierItems;
  }

  public setClassifierItems(
    classifierItems: Array<ServiceRequestClassifierItem>
  ) {
    this.classifierItems = classifierItems;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    let classifierItems = json["classifierItems"];
    if (classifierItems != null) {
      const convertedClassifierItems0 = [];
      classifierItems = classifierItems[1];
      for (const i in classifierItems) {
        const convertedClassifierItems10 = classifierItems[i];
        const convertedClassifierItems1 = new ServiceRequestClassifierItem();
        convertedClassifierItems1.fillFromJson(
          convertedClassifierItems10,
          factory
        );
        convertedClassifierItems0.push(convertedClassifierItems1);
      }
      this.setClassifierItems(convertedClassifierItems0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const classifierItems = this.getClassifierItems();
    if (classifierItems != null) {
      const convertedClassifierItems0 = [];
      for (const i in classifierItems) {
        const convertedClassifierItems10 = classifierItems[i];
        const convertedClassifierItems1 = convertedClassifierItems10.getJson(
          JsonTypeTagType.NONE
        );
        convertedClassifierItems0.push(convertedClassifierItems1);
      }
      const convertedClassifierItemsWithType1 = [
        "java.util.ArrayList",
        convertedClassifierItems0,
      ];
      json["classifierItems"] = convertedClassifierItemsWithType1;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.request.ServiceRequestClassifier";
  }
}
