/**
 * 自动生成， 请不要手工修改！
 */


export enum ServiceType {
    ESTATE,
    POLICE,
    LAW,
    PSYCOLOGY,
    MEDICAL,
    VOLUNTEER,
    COMMERCE
}
