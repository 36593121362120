/**
 * 自动生成， 请不要手工修改！
 */

export enum FeedBackType {
  DYSFUNCTION,
  PROD_PROPOSAL,
  NEW_FUNCTION,
  OTHER_PROBLEMS,
}
