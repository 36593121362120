/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractPageableData} from './AbstractPageableData'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {PageBlockAccessSpec} from './PageBlockAccessSpec'
import {PageDataSpec} from './PageDataSpec'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractDefaultPageableData<T> extends AbstractPageableData<T> {

    constructor() {
        super();
    }


    abstract isRecordsRemaining(): boolean;

    abstract getForwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getForwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract getNextBackwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getNextBackwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract addPageDataSpec(arg0: (PageDataSpec | null), arg1: (boolean | null)): PageDataSpec;

    abstract getContinuousNoDataCount(): number;

    abstract getEstimatedTotalRecords(): number;

    abstract getFetchedSize(): number;

    abstract getNextStart(): number;

    abstract getStartPosition(): number;

    abstract setEstimatedTotalRecords(arg0: (number | null));

    abstract setRecordsRemaining(arg0: (boolean | null));

    abstract size(): number;

    abstract getPageDataPart(startPosition: (number | null), count: (number | null)): Array<T>;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);

        if (includeTypeTag) {
            json["__type"] = "DefaultPageableData";
        }
    }


    public getTypeId(): string {
        return "DefaultPageableData";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.paging.DefaultPageableData";
    }
}
