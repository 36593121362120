/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { ChannelSpec } from "./ChannelSpec";
import { EntityChannelType } from "./EntityChannelType";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractChannelSpec
  extends AbstractSerializable
  implements ChannelSpec
{
  constructor() {
    super();
  }

  abstract getChannelType(): EntityChannelType;

  abstract getRid(): number;

  abstract getDescription(): string;

  abstract getName(): string;

  abstract getTypeId(): string;

  public fillFromJson(json: Object, factory?: TypeFactory) {}

  public fillToJson(json: Object, includeTypeTag = true) {}

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.channel.AbstractChannelSpec";
  }
}
