import { SystemSuppliedEntityInfo } from "../datatype/entity/SystemSuppliedEntityInfo";
import { EntityInfo } from "../datatype/entity/EntityInfo";
import { SystemSuppliedEntityDesc } from "../datatype/entity/SystemSuppliedEntityDesc";
import { EntityType } from "../datatype/entity/EntityType";
import { Location } from "../datatype/geolocation/Location";
import {UserDetails} from "../datatype/entity/user/UserDetails";
import {TopicType} from "../datatype/entity/topic/TopicType";
import {RoleType} from "../datatype/entity/RoleType";
import { GeoEntityDesc } from "@/services/datatype/geolocation/GeoEntityDesc";
import { CoreGeoEntityDesc } from "@/services/datatype/geolocation/CoreGeoEntityDesc";

export default class EntityUtils {
  public static getEntityInfoFromSystemSuppliedEntityInfo(
    systemSuppliedEntityInfo: SystemSuppliedEntityInfo | null | undefined
  ): EntityInfo | null {
    if (!systemSuppliedEntityInfo) {
      return null;
    }
    const entityDesc: SystemSuppliedEntityDesc =
      systemSuppliedEntityInfo.entityDesc;
    if (!entityDesc) {
      return null;
    }

    let entityInfo: EntityInfo;
    const entityInfos: Array<EntityInfo> = entityDesc?.parentPath;
    if (entityInfos && entityInfos.length > 0) {
      entityInfo = entityInfos[0];
      if (entityInfo.entityId == entityDesc.nid) {
        return entityInfo;
      }
    }

    const geoEntityDescs: Array<GeoEntityDesc> = [];
    for (let i = 0; i < entityInfos.length; i++) {
      const geoEntityDesc: GeoEntityDesc = new GeoEntityDesc();
      const coreData: CoreGeoEntityDesc = new CoreGeoEntityDesc();
      geoEntityDesc.coreGeoEntityDesc = coreData;
      coreData.entityInfo = entityInfos[i];
      if (geoEntityDescs.length < 1) {
        geoEntityDescs[i] = geoEntityDesc;
      }
    }

    entityInfo = new EntityInfo();
    entityInfo.setEntityId(entityDesc.getNid());
    entityInfo.setEntityType(entityDesc.getEntityType());
    if (entityDesc.getName() && entityDesc.getNickName().indexOf("gw_") >= 0) {
      entityInfo.setEntityName(entityDesc.getName());
    } else {
      entityInfo.setEntityName(entityDesc.getNickName());
    }
    entityInfo.setLogoImgUrl(entityDesc.getImageUrl());

    if (entityDesc.getLat() && entityDesc.getLng()) {
      const location: Location = new Location();
      location.setLat(entityDesc.getLat());
      location.setLng(entityDesc.getLng());
      location.setName(entityDesc.getLocationName());
      entityInfo.setLocation(location);
    }

    return entityInfo;
  }

  public static existAssociateSchool(
    systemSuppliedEntityInfo: SystemSuppliedEntityInfo
  ): boolean {
    const userDetails: UserDetails = systemSuppliedEntityInfo.entityDesc
      .additionalAttributes as UserDetails;
    const adminInfo = userDetails.userEntityAdminInfo;
    const associatedEntityInfo = adminInfo?.roledEntityInfos;

    if (associatedEntityInfo) {
      for (const entityInfo of associatedEntityInfo) {
        if (
          entityInfo.roleType == RoleType.CREATOR &&
          entityInfo.entityInfo.entityType == EntityType.PRODUCT &&
            entityInfo.entityInfo.topicType == TopicType.SCHOOL
        ) {
          return true;
        }
      }
    }
    return false;
  }
}
