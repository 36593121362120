export enum EnterpriseServiceType {
    BANKING,
    LEGAL,
    COMMERCE,
    LABOUR,
    SOFTWARE,
    ACCOUNTANT,
    TAX,
    IP,
    CONSULT,
    TRAINING,
    MANAGEMENT,
    CB_EC,
    MARKETING,
    LICENSE,
    INSURANCE,
    REF_CHECK,
    CAPITAL,
    FP,
    CATERING,
    HOTEL,
    TEA,
    LEGAL_CONSULT,
    LEGAL_CONTRACT,
    LEGAL_LETTER,
    LEGAL_LAWSUIT,
    EQUITY,
    LOAN_SERVICE,
    EQUITY_LOAN_SERVICE,
    BOND_SERVICE,
    FIN_ENT_COOP_SERVICE,
    FIN_NET_COOP_SERVICE,
    CONSULT_FT,
    CONSULT_CAPITAL,
    CONSULT_MANAGEMENT,
    CONSULT_CB_EC,
    CONSULT_MARKETING,
    CONSULT_FP,
    TRAINING_FT,
    TRAINING_LEGAL,
    TRAINING_CAPITAL,
    TRAINING_MANAGEMENT,
    TRAINING_CB_EC,
    TRAINING_MARKETING,
    TRAINING_FP,
    FT_PLANNING,
    PUBLICITY_BASE,
    PUBLICITY_SUPER,
    CONNECTION,
    POLICY,
    POLICY_QUERY,
    COOP,
    COOP_QUERY,
    QLL_SERVICE_PKG,
    QLL_MEMBER_COOP,
    QLL_MEMBER,
    QLL_MEMBER_VIP,
    QLL_MEMBER_PARTNER,
    LEGAL_SERVICE_PKG,
    LEGAL_LIGHT,
    LEGAL_STANDARD,
    LEGAL_VIP,
    ASSOC_MEMBER,
    CHARITY,
    GENERAL,
    MINI_PROGRAM,
    WEIXIN_PUBLIC_ACCOUNT,
    MANUFACTURE_DATA_SERVICE,
    CLOUD,
    FORUM,
    COOP_BUS_CASE,
    QLL_BASIC_MEMBER,
    QLL_TEST_MEMBER,
    CONSULT_LABOUR_OUTSOURCE,
    CONSULT_RECRUITMENT,
    CONSULT_SOCIAL_SECURITY_AGENT,
    EMPLOYEE_HANDBOOK,
    CONSULT_EMPLOYEE_BENEFIT,
    CONSULT_EMPLOYEE_PE,
    CONSULT_AGENT_OPERATION,
    OFFICE_LOCATION,
    CONSULT_OFFICE_DECORATION,
    CONSULT_OFFICE_PLANT_LEASE,
    CONSULT_GIFT_CUSTOMIZE,
    ONLINE_DOMESTIC_MARKETING,
    ONLINE_INTERNATIONAL_MARKETING,
    EXCLUSIVE_CUSTOMER_MANAGER,
    BTL_AD,
    LEGAL_ADVANCED_CONSULT,
    LEGAL_ADVANCED_CONTRACT,
    LEGAL_ADVANCED_LETTER,
    ACCOUNT_AGENCY_GEN_TAX_PAYER,
    ACCOUNT_AGENCY_SMALL_SCALE,
    SORT_OUT_OLD_ACCOUNTS,
    ANNUAL_TAX_INSPECTION,
    ACCOUNT_AGENCY_GEN_TAX_PAYER_PKG,
    ACCOUNT_AGENCY_SMALL_SCALE_PKG,
    LEGAL_PLEADINGS,
    LEGAL_PLEADINGS_PKG,
    COMPANY_REGISTRATION,
    SELF_EMPLOYED_REGISTRATION,
    IC_CHANGES,
    COMPANY_DEREGISTRATION,
    MARKETING_CHANNELS,
    SHORT_VIDEO_OP,
    NEW_MEDIA_SHORT_VIDEO_AGENCY_OP,
    DOUYIN_ACCOUNT_AGENCY_OP,
    SHORT_VIDEO_PRODUCTIONS,
    PROMOTIONAL_SHORT_VIDEO_SHOOTING,
    PROMOTIONAL_SHORT_VIDEO_SHOOTING_3M,
    PROMOTIONAL_SHORT_VIDEO_SHOOTING_5M,
    PROMOTIONAL_SHORT_VIDEO_SHOOTING_8M,
    TEST_LEGAL_PLEADINGS_PKG,
  TEST_SHORT_VIDEO_PRODUCTIONS,
  TEST_LEGAL_VIP,
}
