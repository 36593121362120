/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class SettlementAccountInfo extends AbstractSerializable {
    internalId: (number | string);
    enterpriseId: (number | string);
    accountName: string;
    accountId: string;
    bankName: string;
    branchName: string;
    creatorId: (number | string);
    createTime: (number | string);
    lastUpdatorId: (number | string);
    lastUpdateTime: (number | string);

    constructor() {
        super();
    }

    public getInternalId(): (number | string) {
        return this.internalId;
    }

    public setInternalId(internalId: (number | string)) {
        this.internalId = internalId;
    }

    public getEnterpriseId(): (number | string) {
        return this.enterpriseId;
    }

    public setEnterpriseId(enterpriseId: (number | string)) {
        this.enterpriseId = enterpriseId;
    }

    public getAccountName(): string {
        return this.accountName;
    }

    public setAccountName(accountName: string) {
        this.accountName = accountName;
    }

    public getAccountId(): string {
        return this.accountId;
    }

    public setAccountId(accountId: string) {
        this.accountId = accountId;
    }

    public getBankName(): string {
        return this.bankName;
    }

    public setBankName(bankName: string) {
        this.bankName = bankName;
    }

    public getBranchName(): string {
        return this.branchName;
    }

    public setBranchName(branchName: string) {
        this.branchName = branchName;
    }

    public getCreatorId(): (number | string) {
        return this.creatorId;
    }

    public setCreatorId(creatorId: (number | string)) {
        this.creatorId = creatorId;
    }

    public getCreateTime(): (number | string) {
        return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
        this.createTime = createTime;
    }

    public getLastUpdatorId(): (number | string) {
        return this.lastUpdatorId;
    }

    public setLastUpdatorId(lastUpdatorId: (number | string)) {
        this.lastUpdatorId = lastUpdatorId;
    }

    public getLastUpdateTime(): (number | string) {
        return this.lastUpdateTime;
    }

    public setLastUpdateTime(lastUpdateTime: (number | string)) {
        this.lastUpdateTime = lastUpdateTime;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let internalId = json["internalId"];
        if (internalId != null) {
            this.setInternalId(internalId);
        }
        let enterpriseId = json["enterpriseId"];
        if (enterpriseId != null) {
            this.setEnterpriseId(enterpriseId);
        }
        let accountName = json["accountName"];
        if (accountName != null) {
            this.setAccountName(accountName);
        }
        let accountId = json["accountId"];
        if (accountId != null) {
            this.setAccountId(accountId);
        }
        let bankName = json["bankName"];
        if (bankName != null) {
            this.setBankName(bankName);
        }
        let branchName = json["branchName"];
        if (branchName != null) {
            this.setBranchName(branchName);
        }
        let creatorId = json["creatorId"];
        if (creatorId != null) {
            this.setCreatorId(creatorId);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let lastUpdatorId = json["lastUpdatorId"];
        if (lastUpdatorId != null) {
            this.setLastUpdatorId(lastUpdatorId);
        }
        let lastUpdateTime = json["lastUpdateTime"];
        if (lastUpdateTime != null) {
            this.setLastUpdateTime(lastUpdateTime);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let internalId = this.getInternalId();
        if (internalId != null) {
            json["internalId"] = String(internalId);
        }
        let enterpriseId = this.getEnterpriseId();
        if (enterpriseId != null) {
            json["enterpriseId"] = String(enterpriseId);
        }
        let accountName = this.getAccountName();
        if (accountName != null) {
            json["accountName"] = accountName;
        }
        let accountId = this.getAccountId();
        if (accountId != null) {
            json["accountId"] = accountId;
        }
        let bankName = this.getBankName();
        if (bankName != null) {
            json["bankName"] = bankName;
        }
        let branchName = this.getBranchName();
        if (branchName != null) {
            json["branchName"] = branchName;
        }
        let creatorId = this.getCreatorId();
        if (creatorId != null) {
            json["creatorId"] = String(creatorId);
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let lastUpdatorId = this.getLastUpdatorId();
        if (lastUpdatorId != null) {
            json["lastUpdatorId"] = String(lastUpdatorId);
        }
        let lastUpdateTime = this.getLastUpdateTime();
        if (lastUpdateTime != null) {
            json["lastUpdateTime"] = String(lastUpdateTime);
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.service.settlement.SettlementAccountInfo";
    }
}
