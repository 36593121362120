/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractUserSensitive} from '../datatype/query/AbstractUserSensitive'
import {ActivityFilter} from '../datatype/query/activity/ActivityFilter'
import {ActivityInfo} from '../datatype/activity/ActivityInfo'
import {ActivityStats} from '../datatype/activity/ActivityStats'
import {ActivityUserInfo} from '../datatype/activity/ActivityUserInfo'
import {ActivityView} from '../datatype/activity/ActivityView'
import {AdContent} from '../datatype/business/ad/AdContent'
import {AlumniDetails} from '../datatype/entity/topic/AlumniDetails'
import {AnnouncementFilter} from '../datatype/query/userad/AnnouncementFilter'
import {AnnouncementInfo} from '../datatype/userad/AnnouncementInfo'
import {AnnouncementView} from '../datatype/userad/AnnouncementView'
import {Announcement} from '../datatype/userad/Announcement'
import {AnswerContent} from '../datatype/ipsphere/qa/AnswerContent'
import {AppealInfoView} from '../datatype/appeal/AppealInfoView'
import {AppealInfo} from '../datatype/appeal/AppealInfo'
import {AssignableEnterpriseServiceRequest} from '../datatype/business/service/request/AssignableEnterpriseServiceRequest'
import {BasicReview} from '../datatype/ipsphere/review/BasicReview'
import {CommercialActivity} from '../datatype/activity/CommercialActivity'
import {CompositeActivityInfo} from '../datatype/activity/CompositeActivityInfo'
import {CompositeAnnouncementInfo} from '../datatype/userad/CompositeAnnouncementInfo'
import {CompositeCoworkView} from '../datatype/cowork/CompositeCoworkView'
import {CompositeInfoView} from '../datatype/cowork/CompositeInfoView'
import {CompositePageDataSpec} from '../datatype/paging/CompositePageDataSpec'
import {CompositePositionDescriptor} from '../datatype/paging/CompositePositionDescriptor'
import {CompositeProductInfo} from '../datatype/product/CompositeProductInfo'
import {CompositeQuestionInfo} from '../datatype/ipsphere/qa/CompositeQuestionInfo'
import {CompositeReviewInfo} from '../datatype/ipsphere/review/CompositeReviewInfo'
import {CompositeSharedInfo} from '../datatype/share/CompositeSharedInfo'
import {CompositeUserAdInfo} from '../datatype/userad/CompositeUserAdInfo'
import {ContentUpdateRecord} from '../datatype/ipsphere/qa/ContentUpdateRecord'
import {CoopUserAdInfo} from '../datatype/userad/coop/CoopUserAdInfo'
import {CoopUserAdView} from '../datatype/userad/coop/CoopUserAdView'
import {CoopUserInfo} from '../datatype/userad/coop/CoopUserInfo'
import {CoworkFilter} from '../datatype/query/cowork/CoworkFilter'
import {CoworkOperationMessage} from '../message/entity/cowork/CoworkOperationMessage'
import {CustomRequest} from '../datatype/request/CustomRequest'
import {DefaultPageableData} from '../datatype/paging/DefaultPageableData'
import {DefaultPageDataSpec} from '../datatype/paging/DefaultPageDataSpec'
import {DefaultPositionDescriptor} from '../datatype/paging/DefaultPositionDescriptor'
import {DistanceInfo} from '../datatype/geolocation/DistanceInfo'
import {EducationProfileAspect} from '../datatype/ipsphere/expertise/EducationProfileAspect'
import {EnterpriseServicePackageTradable} from '../datatype/business/service/tradable/EnterpriseServicePackageTradable'
import {EnterpriseServicePackageTrade} from '../datatype/business/service/trade/EnterpriseServicePackageTrade'
import {EnterpriseServiceRequest} from '../datatype/business/service/request/EnterpriseServiceRequest'
import {EnterpriseServiceTradableInfo} from '../datatype/business/service/tradable/EnterpriseServiceTradableInfo'
import {EnterpriseServiceTradable} from '../datatype/business/service/tradable/EnterpriseServiceTradable'
import {EnterpriseServiceTrade} from '../datatype/business/service/trade/EnterpriseServiceTrade'
import {EnterpriseServiceView} from '../datatype/business/service/tradable/EnterpriseServiceView'
import {EntityFilter} from '../datatype/query/entity/EntityFilter'
import {EntityInfo} from '../datatype/entity/EntityInfo'
import {EntityRoleRequest} from '../datatype/request/entity/EntityRoleRequest'
import {EntityRoleSpec} from '../datatype/request/entity/EntityRoleSpec'
import {EntityServiceView} from '../datatype/entity/EntityServiceView'
import {EntityUserInfo} from '../datatype/cowork/EntityUserInfo'
import {EquityFinanceServiceRequestInfo} from '../datatype/business/service/request/banking/EquityFinanceServiceRequestInfo'
import {EquityFinanceServiceRequest} from '../datatype/business/service/request/banking/EquityFinanceServiceRequest'
import {ExpertiseProfileAspect} from '../datatype/ipsphere/expertise/ExpertiseProfileAspect'
import {FinancingServiceRequest} from '../datatype/business/service/request/banking/FinancingServiceRequest'
import {GeneralActivity} from '../datatype/activity/GeneralActivity'
import {GeneralCoworkStats} from '../datatype/cowork/GeneralCoworkStats'
import {GeneralCoworkUserInfo} from '../datatype/cowork/GeneralCoworkUserInfo'
import {GeneralServiceRequestUserAdInfo} from '../datatype/userad/general/GeneralServiceRequestUserAdInfo'
import {GeneralServiceRequestUserAdView} from '../datatype/userad/general/GeneralServiceRequestUserAdView'
import {GeneralServiceRequestUserAd} from '../datatype/userad/general/GeneralServiceRequestUserAd'
import {GeneralServiceResponseHandle} from '../api/datatype/GeneralServiceResponseHandle'
import {GeneralUserAdInfo} from '../datatype/userad/general/GeneralUserAdInfo'
import {GeneralUserAd} from '../datatype/userad/general/GeneralUserAd'
import {GenericEntityMessage} from '../message/entity/api/GenericEntityMessage'
import {GeoEntityDesc} from '../datatype/geolocation/GeoEntityDesc'
import {GeoSelectPageableData} from '../datatype/paging/GeoSelectPageableData'
import {GeoSelectPageDataSpec} from '../datatype/paging/GeoSelectPageDataSpec'
import {GeoSelectPositions} from '../datatype/paging/GeoSelectPositions'
import {InboxMessageWrapper} from '../message/entity/api/InboxMessageWrapper'
import {KeywordFilter} from '../datatype/query/KeywordFilter'
import {LocalShared} from '../datatype/geolocation/LocalShared'
import {Map} from '../util/Map'
import {MessageFilter} from '../datatype/query/message/MessageFilter'
import {MessageQuerySpec} from '../datatype/query/message/MessageQuerySpec'
import {MessageRange} from '../datatype/paging/MessageRange'
import {MessageSearchFilter} from '../datatype/message/MessageSearchFilter'
import {MineFilter} from '../datatype/query/mine/MineFilter'
import {NetizenContribution} from '../datatype/entity/enterprise/NetizenContribution'
import {OfficialEndorsementProfileAspect} from '../datatype/entity/profile/OfficialEndorsementProfileAspect'
import {OfficialEndorsementReviewRequest} from '../datatype/request/entity/OfficialEndorsementReviewRequest'
import {OffsetPageDataSpec} from '../datatype/paging/OffsetPageDataSpec'
import {OffsetPositions} from '../datatype/paging/OffsetPositions'
import {OrderFilter} from '../datatype/business/order/OrderFilter'
import {OrderQuerySpec} from '../datatype/business/order/OrderQuerySpec'
import {PersonalProfileAspect} from '../datatype/entity/profile/PersonalProfileAspect'
import {PlatformActivity} from '../datatype/activity/PlatformActivity'
import {QAFilter} from '../datatype/query/ipsphere/qa/QAFilter'
import {QuerySpec} from '../datatype/query/QuerySpec'
import {QuestionInfo} from '../datatype/ipsphere/qa/QuestionInfo'
import {QuestionOverview} from '../datatype/ipsphere/qa/QuestionOverview'
import {QuestionStats} from '../datatype/ipsphere/qa/QuestionStats'
import {QuestionUserInfo} from '../datatype/ipsphere/qa/QuestionUserInfo'
import {Question} from '../datatype/ipsphere/qa/Question'
import {RegionSpec} from '../datatype/entity/region/RegionSpec'
import {ReleaseResponse} from '../datatype/cowork/ReleaseResponse'
import {RequestActionMessage} from '../message/entity/request/RequestActionMessage'
import {RequestAction} from '../datatype/request/RequestAction'
import {RequestFilter} from '../datatype/query/request/RequestFilter'
import {RequestMessage} from '../message/entity/request/RequestMessage'
import {RequestStatusMessage} from '../message/entity/request/RequestStatusMessage'
import {RequestStatus} from '../datatype/request/RequestStatus'
import {Request} from '../datatype/request/Request'
import {ReviewFilter} from '../datatype/query/review/ReviewFilter'
import {ReviewInfo} from '../datatype/ipsphere/review/ReviewInfo'
import {ReviewOverview} from '../datatype/ipsphere/review/ReviewOverview'
import {SecurityProfileAspect} from '../datatype/entity/profile/SecurityProfileAspect'
import {ServiceOpMessage} from '../message/business/service/ServiceOpMessage'
import {ServiceOpNotification} from '../datatype/notification/ServiceOpNotification'
import {ServiceRequestOpMessage} from '../message/business/service/ServiceRequestOpMessage'
import {ServiceRequestOp} from '../message/business/service/ServiceRequestOp'
import {SharedInfoFilter} from '../datatype/query/share/SharedInfoFilter'
import {SharedInfoView} from '../datatype/share/SharedInfoView'
import {SharedInfo} from '../datatype/share/SharedInfo'
import {SimpleChannelEntitlements} from '../datatype/entity/channel/SimpleChannelEntitlements'
import {SimpleChannelSpec} from '../datatype/entity/channel/SimpleChannelSpec'
import {SimpleEntityDesc} from '../datatype/entity/SimpleEntityDesc'
import {SimpleEntityEntitlements} from '../datatype/entity/channel/SimpleEntityEntitlements'
import {SimpleEntityMessageResponse} from '../message/entity/api/SimpleEntityMessageResponse'
import {SimpleEntityView} from '../datatype/entity/view/SimpleEntityView'
import {SimpleServiceResponseHandle} from '../api/datatype/SimpleServiceResponseHandle'
import {SimpleSharedInfo} from '../datatype/share/SimpleSharedInfo'
import {SupplierDetails} from '../datatype/entity/enterprise/SupplierDetails'
import {SystemSuppliedEntityDesc} from '../datatype/entity/SystemSuppliedEntityDesc'
import {TextMessage} from '../message/entity/TextMessage'
import {TopicDetails} from '../datatype/entity/topic/TopicDetails'
import {TopicFilter} from '../datatype/query/entity/topic/TopicFilter'
import {TopicView} from '../datatype/entity/topic/TopicView'
import {TradeFilter} from '../datatype/business/general/trade/TradeFilter'
import {URLMessage} from '../message/entity/URLMessage'
import {UserAdFilter} from '../datatype/query/userad/UserAdFilter'
import {UserAdQuerySpec} from '../datatype/query/userad/UserAdQuerySpec'
import {UserDetails} from '../datatype/entity/user/UserDetails'
import {UserFavorite} from '../datatype/entity/user/UserFavorite'
import {UserSuppliedEntityDesc} from '../datatype/entity/UserSuppliedEntityDesc'

export class GeneratedTypeFactory {
    public static createTypeMap(): Map<string, () => any> {
        let typeMap = new Map<string, () => any>();

        typeMap.set("AbstractUserSensitive", () => new AbstractUserSensitive());
        typeMap.set("ActivityFilter", () => new ActivityFilter());
        typeMap.set("ActivityInfo", () => new ActivityInfo());
        typeMap.set("ActivityStats", () => new ActivityStats());
        typeMap.set("ActivityUserInfo", () => new ActivityUserInfo());
        typeMap.set("ActivityView", () => new ActivityView());
        typeMap.set("AdContent", () => new AdContent());
        typeMap.set("AlumniDetails", () => new AlumniDetails());
        typeMap.set("Announcement", () => new Announcement());
        typeMap.set("AnnouncementFilter", () => new AnnouncementFilter());
        typeMap.set("AnnouncementInfo", () => new AnnouncementInfo());
        typeMap.set("AnnouncementView", () => new AnnouncementView());
        typeMap.set("AnswerContent", () => new AnswerContent());
        typeMap.set("AppealInfo", () => new AppealInfo());
        typeMap.set("AppealInfoView", () => new AppealInfoView());
        typeMap.set("AssignableEnterpriseServiceRequest", () => new AssignableEnterpriseServiceRequest());
        typeMap.set("BasicReview", () => new BasicReview());
        typeMap.set("CommercialActivity", () => new CommercialActivity());
        typeMap.set("ComplaintFilter", () => new RequestFilter());
        typeMap.set("CompositeActivityInfo", () => new CompositeActivityInfo());
        typeMap.set("CompositeAnnouncementInfo", () => new CompositeAnnouncementInfo());
        typeMap.set("CompositeCoworkView", () => new CompositeCoworkView());
        typeMap.set("CompositeInfoView", () => new CompositeInfoView());
        typeMap.set("CompositePageDataSpec", () => new CompositePageDataSpec());
        typeMap.set("CompositePosition", () => new CompositePositionDescriptor());
        typeMap.set("CompositeProductInfo", () => new CompositeProductInfo());
        typeMap.set("CompositeQuestionInfo", () => new CompositeQuestionInfo());
        typeMap.set("CompositeReviewInfo", () => new CompositeReviewInfo());
        typeMap.set("CompositeSharedInfo", () => new CompositeSharedInfo());
        typeMap.set("CompositeUserAdInfo", () => new CompositeUserAdInfo());
        typeMap.set("ContentUpdateRecord", () => new ContentUpdateRecord());
        typeMap.set("CoopUserAdInfo", () => new CoopUserAdInfo());
        typeMap.set("CoopUserAdView", () => new CoopUserAdView());
        typeMap.set("CoopUserInfo", () => new CoopUserInfo());
        typeMap.set("CoworkFilter", () => new CoworkFilter());
        typeMap.set("CoworkOperationMessage", () => new CoworkOperationMessage());
        typeMap.set("CustomRequest", () => new CustomRequest());
        typeMap.set("DefaultPageDataSpec", () => new DefaultPageDataSpec());
        typeMap.set("DefaultPageableData", () => new DefaultPageableData());
        typeMap.set("DefaultPosition", () => new DefaultPositionDescriptor());
        typeMap.set("DistanceInfo", () => new DistanceInfo());
        typeMap.set("EducationProfileAspect", () => new EducationProfileAspect());
        typeMap.set("EnterpriseServicePackageTradable", () => new EnterpriseServicePackageTradable());
        typeMap.set("EnterpriseServicePackageTrade", () => new EnterpriseServicePackageTrade());
        typeMap.set("EnterpriseServiceRequest", () => new EnterpriseServiceRequest());
        typeMap.set("EnterpriseServiceTradable", () => new EnterpriseServiceTradable());
        typeMap.set("EnterpriseServiceTradableInfo", () => new EnterpriseServiceTradableInfo());
        typeMap.set("EnterpriseServiceTrade", () => new EnterpriseServiceTrade());
        typeMap.set("EnterpriseServiceView", () => new EnterpriseServiceView());
        typeMap.set("EntityFilter", () => new EntityFilter());
        typeMap.set("EntityInfo", () => new EntityInfo());
        typeMap.set("EntityRoleRequest", () => new EntityRoleRequest());
        typeMap.set("EntityRoleSpec", () => new EntityRoleSpec());
        typeMap.set("EntityServiceView", () => new EntityServiceView());
        typeMap.set("EntityUserInfo", () => new EntityUserInfo());
        typeMap.set("EquityFinanceServiceRequest", () => new EquityFinanceServiceRequest());
        typeMap.set("EquityFinanceServiceRequestInfo", () => new EquityFinanceServiceRequestInfo());
        typeMap.set("ExpertiseProfileAspect", () => new ExpertiseProfileAspect());
        typeMap.set("FinancingServiceRequest", () => new FinancingServiceRequest());
        typeMap.set("GeneralActivity", () => new GeneralActivity());
        typeMap.set("GeneralCoworkStats", () => new GeneralCoworkStats());
        typeMap.set("GeneralCoworkUserInfo", () => new GeneralCoworkUserInfo());
        typeMap.set("GeneralServiceRequestUserAd", () => new GeneralServiceRequestUserAd());
        typeMap.set("GeneralServiceRequestUserAdInfo", () => new GeneralServiceRequestUserAdInfo());
        typeMap.set("GeneralServiceResponseHandle", () => new GeneralServiceResponseHandle());
        typeMap.set("GeneralUserAd", () => new GeneralUserAd());
        typeMap.set("GeneralUserAdInfo", () => new GeneralUserAdInfo());
        typeMap.set("GeneralUserAdView", () => new GeneralServiceRequestUserAdView());
        typeMap.set("GenericEntityMessage", () => new GenericEntityMessage());
        typeMap.set("GeoEntityDesc", () => new GeoEntityDesc());
        typeMap.set("GeoSelectPageDataSpec", () => new GeoSelectPageDataSpec());
        typeMap.set("GeoSelectPageableData", () => new GeoSelectPageableData());
        typeMap.set("GeoSelectPositions", () => new GeoSelectPositions());
        typeMap.set("InboxMessageWrapper", () => new InboxMessageWrapper());
        typeMap.set("KeywordFilter", () => new KeywordFilter());
        typeMap.set("LocalShared", () => new LocalShared());
        typeMap.set("MessageCorrelationFilter", () => new MessageFilter());
        typeMap.set("MessageQuerySpec", () => new MessageQuerySpec());
        typeMap.set("MessageRange", () => new MessageRange());
        typeMap.set("MessageSearchFilter", () => new MessageSearchFilter());
        typeMap.set("MineFilter", () => new MineFilter());
        typeMap.set("NetizenContribution", () => new NetizenContribution());
        typeMap.set("OfficialEndorsementProfileAspect", () => new OfficialEndorsementProfileAspect());
        typeMap.set("OfficialEndorsementReviewRequest", () => new OfficialEndorsementReviewRequest());
        typeMap.set("OffsetPageDataSpec", () => new OffsetPageDataSpec());
        typeMap.set("OffsetPositions", () => new OffsetPositions());
        typeMap.set("OrderFilter", () => new OrderFilter());
        typeMap.set("OrderQuerySpec", () => new OrderQuerySpec());
        typeMap.set("PlainPersonalProfileAspect", () => new PersonalProfileAspect());
        typeMap.set("PlainSecurityProfileAspect", () => new SecurityProfileAspect());
        typeMap.set("PlatformActivity", () => new PlatformActivity());
        typeMap.set("QAFilter", () => new QAFilter());
        typeMap.set("QuerySpec", () => new QuerySpec());
        typeMap.set("Question", () => new Question());
        typeMap.set("QuestionInfo", () => new QuestionInfo());
        typeMap.set("QuestionOverview", () => new QuestionOverview());
        typeMap.set("QuestionStats", () => new QuestionStats());
        typeMap.set("QuestionUserInfo", () => new QuestionUserInfo());
        typeMap.set("RegionSpec", () => new RegionSpec());
        typeMap.set("ReleaseResponse", () => new ReleaseResponse());
        typeMap.set("Request", () => new Request());
        typeMap.set("RequestAction", () => new RequestAction());
        typeMap.set("RequestActionMessage", () => new RequestActionMessage());
        typeMap.set("RequestMessage", () => new RequestMessage());
        typeMap.set("RequestStatus", () => new RequestStatus());
        typeMap.set("RequestStatusMessage", () => new RequestStatusMessage());
        typeMap.set("ReviewFilter", () => new ReviewFilter());
        typeMap.set("ReviewInfo", () => new ReviewInfo());
        typeMap.set("ReviewOverview", () => new ReviewOverview());
        typeMap.set("ServiceOpMessage", () => new ServiceOpMessage());
        typeMap.set("ServiceOpNotification", () => new ServiceOpNotification());
        typeMap.set("ServiceRequestOp", () => new ServiceRequestOp());
        typeMap.set("ServiceRequestOpMessage", () => new ServiceRequestOpMessage());
        typeMap.set("SharedInfo", () => new SharedInfo());
        typeMap.set("SharedInfoFilter", () => new SharedInfoFilter());
        typeMap.set("SharedInfoView", () => new SharedInfoView());
        typeMap.set("SimpleChannelEntitlements", () => new SimpleChannelEntitlements());
        typeMap.set("SimpleChannelSpec", () => new SimpleChannelSpec());
        typeMap.set("SimpleEntityDesc", () => new SimpleEntityDesc());
        typeMap.set("SimpleEntityEntitlements", () => new SimpleEntityEntitlements());
        typeMap.set("SimpleEntityMessageResponse", () => new SimpleEntityMessageResponse());
        typeMap.set("SimpleEntityView", () => new SimpleEntityView());
        typeMap.set("SimpleServiceResponseHandle", () => new SimpleServiceResponseHandle());
        typeMap.set("SimpleSharedInfo", () => new SimpleSharedInfo());
        typeMap.set("SupplierDetails", () => new SupplierDetails());
        typeMap.set("SystemSuppliedEntityDesc", () => new SystemSuppliedEntityDesc());
        typeMap.set("Text", () => new TextMessage());
        typeMap.set("TopicDetails", () => new TopicDetails());
        typeMap.set("TopicFilter", () => new TopicFilter());
        typeMap.set("TopicInfo", () => new TopicView());
        typeMap.set("TradeFilter", () => new TradeFilter());
        typeMap.set("Url", () => new URLMessage());
        typeMap.set("UserAdFilter", () => new UserAdFilter());
        typeMap.set("UserAdQuerySpec", () => new UserAdQuerySpec());
        typeMap.set("UserDetails", () => new UserDetails());
        typeMap.set("UserFavorite", () => new UserFavorite());
        typeMap.set("UserSuppliedEntityDesc", () => new UserSuppliedEntityDesc());

        return typeMap;
    }
}