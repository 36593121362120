/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { CoworkType } from "../../cowork/CoworkType";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Serializer } from "../../../serialize/Serializer";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class EnterpriseChat extends AbstractSerializable {
  msgId: number | string;
  senderId: number | string;
  enterpriseId: number | string;
  createTime: number | string;
  description: string;
  imageUrls: Array<string>;
  beSharedInfoId: number | string;
  coworkObjectType: CoworkType;
  concreteType: any;
  concreteTypeStr: string;
  anonymous: boolean;

  constructor() {
    super();
  }

  public getMsgId(): number | string {
    return this.msgId;
  }

  public setMsgId(msgId: number | string) {
    this.msgId = msgId;
  }

  public getSenderId(): number | string {
    return this.senderId;
  }

  public setSenderId(senderId: number | string) {
    this.senderId = senderId;
  }

  public getEnterpriseId(): number | string {
    return this.enterpriseId;
  }

  public setEnterpriseId(enterpriseId: number | string) {
    this.enterpriseId = enterpriseId;
  }

  public getCreateTime(): number | string {
    return this.createTime;
  }

  public setCreateTime(createTime: number | string) {
    this.createTime = createTime;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getImageUrls(): Array<string> {
    return this.imageUrls;
  }

  public setImageUrls(imageUrls: Array<string>) {
    this.imageUrls = imageUrls;
  }

  public getBeSharedInfoId(): number | string {
    return this.beSharedInfoId;
  }

  public setBeSharedInfoId(beSharedInfoId: number | string) {
    this.beSharedInfoId = beSharedInfoId;
  }

  public getCoworkObjectType(): CoworkType {
    return this.coworkObjectType;
  }

  public setCoworkObjectType(coworkObjectType: CoworkType) {
    this.coworkObjectType = coworkObjectType;
  }

  public getConcreteType(): any {
    return this.concreteType;
  }

  public setConcreteType(concreteType: any) {
    this.concreteType = concreteType;
  }

  public getConcreteTypeStr(): string {
    return this.concreteTypeStr;
  }

  public setConcreteTypeStr(concreteTypeStr: string) {
    this.concreteTypeStr = concreteTypeStr;
  }

  public isAnonymous(): boolean {
    return this.anonymous;
  }

  public setAnonymous(anonymous: boolean) {
    this.anonymous = anonymous;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const msgId = json["msgId"];
    if (msgId != null) {
      this.setMsgId(msgId);
    }
    const senderId = json["senderId"];
    if (senderId != null) {
      this.setSenderId(senderId);
    }
    const enterpriseId = json["enterpriseId"];
    if (enterpriseId != null) {
      this.setEnterpriseId(enterpriseId);
    }
    const createTime = json["createTime"];
    if (createTime != null) {
      this.setCreateTime(createTime);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    let imageUrls = json["imageUrls"];
    if (imageUrls != null) {
      const convertedImageUrls0 = [];
      imageUrls = imageUrls[1];
      for (const i in imageUrls) {
        const convertedImageUrls10 = imageUrls[i];
        convertedImageUrls0.push(convertedImageUrls10);
      }
      this.setImageUrls(convertedImageUrls0);
    }
    const beSharedInfoId = json["beSharedInfoId"];
    if (beSharedInfoId != null) {
      this.setBeSharedInfoId(beSharedInfoId);
    }
    const coworkObjectType = json["coworkObjectType"];
    if (coworkObjectType != null) {
      const convertedCoworkObjectType0 = CoworkType[<string>coworkObjectType];
      this.setCoworkObjectType(convertedCoworkObjectType0);
    }
    const concreteType = json["concreteType"];
    if (concreteType != null) {
      const convertedConcreteType0 = Serializer.fromJson(concreteType, factory);
      this.setConcreteType(convertedConcreteType0);
    }
    const concreteTypeStr = json["concreteTypeStr"];
    if (concreteTypeStr != null) {
      this.setConcreteTypeStr(concreteTypeStr);
    }
    const anonymous = json["anonymous"];
    if (anonymous != null) {
      this.setAnonymous(anonymous);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const msgId = this.getMsgId();
    if (msgId != null) {
      json["msgId"] = String(msgId);
    }
    const senderId = this.getSenderId();
    if (senderId != null) {
      json["senderId"] = String(senderId);
    }
    const enterpriseId = this.getEnterpriseId();
    if (enterpriseId != null) {
      json["enterpriseId"] = String(enterpriseId);
    }
    const createTime = this.getCreateTime();
    if (createTime != null) {
      json["createTime"] = String(createTime);
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const imageUrls = this.getImageUrls();
    if (imageUrls != null) {
      const convertedImageUrls0 = [];
      for (const i in imageUrls) {
        const convertedImageUrls10 = imageUrls[i];
        convertedImageUrls0.push(convertedImageUrls10);
      }
      const convertedImageUrlsWithType1 = [
        "java.util.ArrayList",
        convertedImageUrls0,
      ];
      json["imageUrls"] = convertedImageUrlsWithType1;
    }
    const beSharedInfoId = this.getBeSharedInfoId();
    if (beSharedInfoId != null) {
      json["beSharedInfoId"] = String(beSharedInfoId);
    }
    const coworkObjectType = this.getCoworkObjectType();
    if (coworkObjectType != null) {
      const convertedCoworkObjectType0 = CoworkType[coworkObjectType];
      json["coworkObjectType"] = convertedCoworkObjectType0;
    }
    const concreteType = this.getConcreteType();
    if (concreteType != null) {
      const convertedConcreteType0 = Serializer.getJson(
        concreteType,
        JsonTypeTagType.CLASS
      );
      json["concreteType"] = convertedConcreteType0;
    }
    const concreteTypeStr = this.getConcreteTypeStr();
    if (concreteTypeStr != null) {
      json["concreteTypeStr"] = concreteTypeStr;
    }
    const anonymous = this.isAnonymous();
    if (anonymous != null) {
      json["anonymous"] = anonymous;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.enterprise.EnterpriseChat";
  }
}
