/**
 * 下载！
 */
import { ElMessage } from "element-plus";
import Throttle from "./Throttle";
export default class CycleUtils {
  //周期中文到数字
  static cycletoTime(cycle: string): string {
    const year = cycle.slice(0, 4);
    const quarter = cycle.slice(5, 6);
    return year + "0" + quarter;
  }
  //周期数字到英文
  static timeToCycle(time: string): string {
    const year: string = time.slice(0, 4);
    const quarter: string = time.slice(5, 6);
    return `${year}年${quarter}季度`;
  }
  static getCurrentTime() {
    const date = new Date();
    const year = date.getFullYear(); // 2019
    //当前季度
    const month = Math.ceil((date.getMonth() + 1) / 3);
    return year.toString() + "0" + month.toString();
  }
  //获取当前时间到n年的周期
  static getCycle(num: number): string[] {
    const date = new Date(); // Sat Jul 06 2019 19:59:27 GMT+0800 (中国标准时间)
    //当前年
    let year = date.getFullYear(); // 2019
    //当前季度
    let month = Math.ceil((date.getMonth() + 1) / 3);
    const periodicInterval = [];
    for (let i = 0; i < num * 4; i++) {
      periodicInterval.push([
        CycleUtils.timeToCycle(year.toString() + "0" + month.toString()),
        year.toString() + "0" + month.toString(),
      ]);
      if (month - 1 === 0) {
        month = 4;
        year -= 1;
      } else {
        month = month - 1;
      }
    }
    return periodicInterval;
  }
}
