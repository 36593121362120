/**
 * 自动生成， 请不要手工修改！
 */


export enum CoworkType {
    GENERAL,
    ENTITY,
    QUESTION,
    ANSWER,
    ACTIVITY,
    ANNOUNCEMENT,
    AD,
    SHARED_INFO,
    REVIEW,
    MESSAGE,
    REPLY,
    GENERAL_REQUEST,
    ENTITY_REQUEST,
    INCOME,
    BUSINESS_AD,
    LOCATION,
    ENTERPRISE,
    INSTITUTION,
    TOPIC,
    PRODUCT,
    PROD_REL,
    SERVICE,
    PLATFORM,
    NETIZEN,
    RED_ENVELOPE,
    LOCAL_CHAT,
    APPEAL,
    TASK,
    BG_CHECK,
    GROUP_MEMBER,
    SUBGROUP,
    ENT_SERVICE_TRADABLE,
    ENT_SERVICE_PKG,
    ENT_LEGAL_SRV_PKG,
    ENT_SERVICE_REQUEST,
    ENT_SERVICE_ORDER
}
