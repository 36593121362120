/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {ProfileAspect} from './profile/ProfileAspect'
import {QllCountrySpec} from '../international/QllCountrySpec'
import {QllLanguageSpec} from '../international/QllLanguageSpec'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'
import {UserSuppliedEntityDesc} from './UserSuppliedEntityDesc'


export class UserSuppliedEntityInfo extends AbstractSerializable  {
    languageSpec: QllLanguageSpec;
    countrySpec: QllCountrySpec;
    originalId: (number | string);
    entityDesc: UserSuppliedEntityDesc;
    timestamp: (number | string);
    profileAspects: Array<ProfileAspect>;
    inviterCode: string;
    deviceNumber: string;
    sectorAssocId: (number | string);
    forFreeMemberShip: boolean;

    constructor() {
    	super();
    }

    public getLanguageSpec(): QllLanguageSpec {
    	return this.languageSpec;
    }

    public setLanguageSpec(languageSpec: QllLanguageSpec) {
    	this.languageSpec = languageSpec;
    }

    public getCountrySpec(): QllCountrySpec {
    	return this.countrySpec;
    }

    public setCountrySpec(countrySpec: QllCountrySpec) {
    	this.countrySpec = countrySpec;
    }

    public getOriginalId(): (number | string) {
    	return this.originalId;
    }

    public setOriginalId(originalId: (number | string)) {
    	this.originalId = originalId;
    }

    public getEntityDesc(): UserSuppliedEntityDesc {
    	return this.entityDesc;
    }

    public setEntityDesc(entityDesc: UserSuppliedEntityDesc) {
    	this.entityDesc = entityDesc;
    }

    public getTimestamp(): (number | string) {
    	return this.timestamp;
    }

    public setTimestamp(timestamp: (number | string)) {
    	this.timestamp = timestamp;
    }

    public getProfileAspects(): Array<ProfileAspect> {
    	return this.profileAspects;
    }

    public setProfileAspects(profileAspects: Array<ProfileAspect>) {
    	this.profileAspects = profileAspects;
    }

    public getInviterCode(): string {
    	return this.inviterCode;
    }

    public setInviterCode(inviterCode: string) {
    	this.inviterCode = inviterCode;
    }

    public getDeviceNumber(): string {
    	return this.deviceNumber;
    }

    public setDeviceNumber(deviceNumber: string) {
    	this.deviceNumber = deviceNumber;
    }

    public getSectorAssocId(): (number | string) {
    	return this.sectorAssocId;
    }

    public setSectorAssocId(sectorAssocId: (number | string)) {
    	this.sectorAssocId = sectorAssocId;
    }

    public isForFreeMemberShip(): boolean {
    	return this.forFreeMemberShip;
    }

    public setForFreeMemberShip(forFreeMemberShip: boolean) {
    	this.forFreeMemberShip = forFreeMemberShip;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let languageSpec = json["languageSpec"];
        if (languageSpec != null) {
            let convertedLanguageSpec0 = new QllLanguageSpec();
            convertedLanguageSpec0.fillFromJson(languageSpec, factory);
            this.setLanguageSpec(convertedLanguageSpec0);
        }
        let countrySpec = json["countrySpec"];
        if (countrySpec != null) {
            let convertedCountrySpec0 = new QllCountrySpec();
            convertedCountrySpec0.fillFromJson(countrySpec, factory);
            this.setCountrySpec(convertedCountrySpec0);
        }
        let originalId = json["originalId"];
        if (originalId != null) {
            this.setOriginalId(originalId);
        }
        let entityDesc = json["entityDesc"];
        if (entityDesc != null) {
            let convertedEntityDesc0 = Serializer.fillFromJsonObjectWithTypeTag(entityDesc, "__type", factory);
            this.setEntityDesc(convertedEntityDesc0);
        }
        let timestamp = json["timestamp"];
        if (timestamp != null) {
            this.setTimestamp(timestamp);
        }
        let profileAspects = json["profileAspects"];
        if (profileAspects != null) {
            let convertedProfileAspects0 = [];
            profileAspects = profileAspects[1];
            for (let i in profileAspects) {
                let convertedProfileAspects10 = profileAspects[i];
                let convertedProfileAspects1 = Serializer.fillFromJsonObjectWithTypeTag(convertedProfileAspects10, "__type", factory);
                convertedProfileAspects0.push(convertedProfileAspects1);
            }
            this.setProfileAspects(convertedProfileAspects0);
        }
        let inviterCode = json["inviterCode"];
        if (inviterCode != null) {
            this.setInviterCode(inviterCode);
        }
        let deviceNumber = json["deviceNumber"];
        if (deviceNumber != null) {
            this.setDeviceNumber(deviceNumber);
        }
        let sectorAssocId = json["sectorAssocId"];
        if (sectorAssocId != null) {
            this.setSectorAssocId(sectorAssocId);
        }
        let forFreeMemberShip = json["forFreeMemberShip"];
        if (forFreeMemberShip != null) {
            this.setForFreeMemberShip(forFreeMemberShip);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let languageSpec = this.getLanguageSpec();
        if (languageSpec != null) {
            let convertedLanguageSpec0 = languageSpec.getJson(JsonTypeTagType.NONE);
            json["languageSpec"] = convertedLanguageSpec0;
        }
        let countrySpec = this.getCountrySpec();
        if (countrySpec != null) {
            let convertedCountrySpec0 = countrySpec.getJson(JsonTypeTagType.NONE);
            json["countrySpec"] = convertedCountrySpec0;
        }
        let originalId = this.getOriginalId();
        if (originalId != null) {
            json["originalId"] = String(originalId);
        }
        let entityDesc = this.getEntityDesc();
        if (entityDesc != null) {
            let convertedEntityDesc0 = entityDesc.getJson(JsonTypeTagType.TYPE);
            json["entityDesc"] = convertedEntityDesc0;
        }
        let timestamp = this.getTimestamp();
        if (timestamp != null) {
            json["timestamp"] = String(timestamp);
        }
        let profileAspects = this.getProfileAspects();
        if (profileAspects != null) {
            let convertedProfileAspects0 = [];
            for (let i in profileAspects) {
                let convertedProfileAspects10 = profileAspects[i];
                let convertedProfileAspects1 = convertedProfileAspects10.getJson(JsonTypeTagType.TYPE);
                convertedProfileAspects0.push(convertedProfileAspects1);
            }
            let convertedProfileAspectsWithType1 = ["java.util.ArrayList", convertedProfileAspects0];
            json["profileAspects"] = convertedProfileAspectsWithType1;
        }
        let inviterCode = this.getInviterCode();
        if (inviterCode != null) {
            json["inviterCode"] = inviterCode;
        }
        let deviceNumber = this.getDeviceNumber();
        if (deviceNumber != null) {
            json["deviceNumber"] = deviceNumber;
        }
        let sectorAssocId = this.getSectorAssocId();
        if (sectorAssocId != null) {
            json["sectorAssocId"] = String(sectorAssocId);
        }
        let forFreeMemberShip = this.isForFreeMemberShip();
        if (forFreeMemberShip != null) {
            json["forFreeMemberShip"] = forFreeMemberShip;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.UserSuppliedEntityInfo";
    }
}
