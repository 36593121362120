/**
 * 自动生成， 请不要手工修改！
 */

export enum PriceType {
  CPM_GENERAL,
  CPM_LOCAL_CYCLE,
  CPC,
  PER_DAY,
  PUBLISH_ONE,
  PUBLISH_FIVE,
  PUBLISH_FIFTEEN,
  PUBLISH_THIRTY,
  PUBLISH_SIXTY,
}
