/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {EnterpriseServiceType} from '../EnterpriseServiceType'
import {FinancingType} from './FinancingType'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class FinancingServiceRequestClassifierItem extends AbstractSerializable  {
    financingType: FinancingType;
    serviceType: EnterpriseServiceType;
    imgUrl: string;
    additionalInfoRequired: boolean;
    displayCoopMatches: boolean;

    constructor() {
    	super();
    }

    public getFinancingType(): FinancingType {
    	return this.financingType;
    }

    public setFinancingType(financingType: FinancingType) {
    	this.financingType = financingType;
    }

    public getServiceType(): EnterpriseServiceType {
    	return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
    	this.serviceType = serviceType;
    }

    public getImgUrl(): string {
    	return this.imgUrl;
    }

    public setImgUrl(imgUrl: string) {
    	this.imgUrl = imgUrl;
    }

    public isAdditionalInfoRequired(): boolean {
    	return this.additionalInfoRequired;
    }

    public setAdditionalInfoRequired(additionalInfoRequired: boolean) {
    	this.additionalInfoRequired = additionalInfoRequired;
    }

    public isDisplayCoopMatches(): boolean {
    	return this.displayCoopMatches;
    }

    public setDisplayCoopMatches(displayCoopMatches: boolean) {
    	this.displayCoopMatches = displayCoopMatches;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let financingType = json["financingType"];
        if (financingType != null) {
            let convertedFinancingType0 = FinancingType[<string>financingType];
            this.setFinancingType(convertedFinancingType0);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let imgUrl = json["imgUrl"];
        if (imgUrl != null) {
            this.setImgUrl(imgUrl);
        }
        let additionalInfoRequired = json["additionalInfoRequired"];
        if (additionalInfoRequired != null) {
            this.setAdditionalInfoRequired(additionalInfoRequired);
        }
        let displayCoopMatches = json["displayCoopMatches"];
        if (displayCoopMatches != null) {
            this.setDisplayCoopMatches(displayCoopMatches);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let financingType = this.getFinancingType();
        if (financingType != null) {
            let convertedFinancingType0 = FinancingType[financingType];
            json["financingType"] = convertedFinancingType0;
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let imgUrl = this.getImgUrl();
        if (imgUrl != null) {
            json["imgUrl"] = imgUrl;
        }
        let additionalInfoRequired = this.isAdditionalInfoRequired();
        if (additionalInfoRequired != null) {
            json["additionalInfoRequired"] = additionalInfoRequired;
        }
        let displayCoopMatches = this.isDisplayCoopMatches();
        if (displayCoopMatches != null) {
            json["displayCoopMatches"] = displayCoopMatches;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.finance.FinancingServiceRequestClassifierItem";
    }
}
