/**
 * 自动生成， 请不要手工修改！
 */

export enum ReplyType {
  COMMENT,
  CALL,
  PRIVATE_MSG,
  PRAISE,
  HELP,
}
