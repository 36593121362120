// @ts-nocheck
import {AbstractEducationProfileAspect} from './AbstractEducationProfileAspect'
import {ProfileAspectType} from '../../entity/profile/ProfileAspectType'


export class EducationProfileAspect extends AbstractEducationProfileAspect  {
    constructor() {
    	super();
    }

    getProfileAspectType(): ProfileAspectType {
      // Manual Code Here
    }
    getEntityId(): (number | string) {
      // Manual Code Here
    }
    setEntityId(arg0: (number | string | null)) {
      // Manual Code Here
    }

}
