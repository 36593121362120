/**
 * 自动生成， 请不要手工修改！
 */

import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { QuerySpec } from "../QuerySpec";
import { TypeFactory } from "../../../serialize/TypeFactory";
import { UserAdFilter } from "./UserAdFilter";

export class UserAdQuerySpec extends QuerySpec<UserAdFilter> {
  constructor() {
    super();
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);

    if (includeTypeTag) {
      json["__type"] = "UserAdQuerySpec";
    }
  }

  public getTypeId(): string {
    return "UserAdQuerySpec";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.userad.UserAdQuerySpec";
  }
}
