/**
 * 自动生成， 请不要手工修改！
 */


export enum ProductPriceType {
    GE,
    JIAN,
    G100,
    ML100,
    JIN,
    KG
}
