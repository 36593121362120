/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractClientServiceAdapter } from "../common/AbstractClientServiceAdapter";
import { DefaultServiceFactory } from "../api/DefaultServiceFactory";
import { DefaultWebServiceFactory } from "../webapi/DefaultWebServiceFactory";
import { OSSTokenResponse } from "../response/OSSTokenResponse";
import { ServiceResponseHandle } from "../api/datatype/ServiceResponseHandle";
import { SystemService } from "../api/system/SystemService";
import { SystemSuppliedEntityInfo } from "../datatype/entity/SystemSuppliedEntityInfo";
import { WebSessionManager } from "../common/WebSessionManager";
import { WebSystemService } from "../webapi/WebSystemService";
import UserTools from "../session/UserTools";
import { qllStorageLocal } from "@/services/util/StorageUtils";
import {
  LOGIN_PASSWORD,
  LOGIN_REM_PASSWORD,
  LOGIN_USER,
} from "@/constants/LocalDataKeys";

export class WebSystemServiceImpl implements WebSystemService {
  private systemService: SystemService;

  public constructor() {
    this.systemService = DefaultServiceFactory.getInstance().getSystemService();
  }

  public logAppException(
    mobileName: string | null,
    mobileVersion: string | null,
    stackTrace: string | null
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.systemService.logAppException(
        WebSessionManager.getSessionContext(),
        mobileName,
        mobileVersion,
        stackTrace,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public login(
    mobile: string | null,
    password: string | null,
    rememberPassword: boolean,
    lastSyncTime: number | string | null
  ): Promise<SystemSuppliedEntityInfo> {
    const promise: Promise<SystemSuppliedEntityInfo> = new Promise(
      (resolve, reject) => {
        this.systemService.login(
          WebSessionManager.getSessionContext(),
          mobile,
          password,
          lastSyncTime,
          (t, u) => {
            if (u) {
              reject(u);
            } else {
              if (t) {
                UserTools.setLoginSystemSuppliedEntityInfo(t);
                if (rememberPassword) {
                  qllStorageLocal.setItem(LOGIN_USER, mobile);
                  qllStorageLocal.setItem(LOGIN_PASSWORD, password);
                }
              }
              resolve(t);
            }
          }
        );
      }
    );
    qllStorageLocal.setItem(LOGIN_REM_PASSWORD, rememberPassword);
    if (!rememberPassword) {
      qllStorageLocal.removeItem(LOGIN_USER);
      qllStorageLocal.removeItem(LOGIN_PASSWORD);
    }
    return promise;
  }

  public loginAsAdmin(
    mobile: string | null,
    password: string | null
  ): Promise<SystemSuppliedEntityInfo> {
    return new Promise((resolve, reject) => {
      this.systemService.loginAsAdmin(
        WebSessionManager.getSessionContext(),
        mobile,
        password,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            if (t) {
              UserTools.setLoginSystemSuppliedEntityInfo(t);
            }
            resolve(t);
          }
        }
      );
    });
  }

  public logout(): Promise<ServiceResponseHandle<boolean>> {
    return new Promise((resolve, reject) => {
      this.systemService.logout(
        WebSessionManager.getSessionContext(),

        (t) => {
          UserTools.setLoginSystemSuppliedEntityInfo(null);
          resolve(t);
          WebSessionManager.setSessionToken(null);
        }
      );
    });
  }

  public requestOSSToken(): Promise<OSSTokenResponse> {
    return new Promise((resolve, reject) => {
      this.systemService.requestOSSToken(
        WebSessionManager.getSessionContext(),
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public resetAccountPassword(newPassword: string | null): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.systemService.resetAccountPassword(
        WebSessionManager.getSessionContext(),
        newPassword,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }
}
