/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { GridStats } from "./GridStats";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class GridInfo extends AbstractSerializable {
  gridStats: GridStats;
  blockIds: Array<string>;

  constructor() {
    super();
  }

  public getGridStats(): GridStats {
    return this.gridStats;
  }

  public setGridStats(gridStats: GridStats) {
    this.gridStats = gridStats;
  }

  public getBlockIds(): Array<string> {
    return this.blockIds;
  }

  public setBlockIds(blockIds: Array<string>) {
    this.blockIds = blockIds;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const gridStats = json["gridStats"];
    if (gridStats != null) {
      const convertedGridStats0 = new GridStats();
      convertedGridStats0.fillFromJson(gridStats, factory);
      this.setGridStats(convertedGridStats0);
    }
    let blockIds = json["blockIds"];
    if (blockIds != null) {
      const convertedBlockIds0 = [];
      blockIds = blockIds[1];
      for (const i in blockIds) {
        const convertedBlockIds10 = blockIds[i];
        convertedBlockIds0.push(convertedBlockIds10);
      }
      this.setBlockIds(convertedBlockIds0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const gridStats = this.getGridStats();
    if (gridStats != null) {
      const convertedGridStats0 = gridStats.getJson(JsonTypeTagType.NONE);
      json["gridStats"] = convertedGridStats0;
    }
    const blockIds = this.getBlockIds();
    if (blockIds != null) {
      const convertedBlockIds0 = [];
      for (const i in blockIds) {
        const convertedBlockIds10 = blockIds[i];
        convertedBlockIds0.push(convertedBlockIds10);
      }
      const convertedBlockIdsWithType1 = [
        "java.util.ArrayList",
        convertedBlockIds0,
      ];
      json["blockIds"] = convertedBlockIdsWithType1;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.enterprise.GridInfo";
  }
}
