/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractEnterpriseServiceTrade } from "../trade/AbstractEnterpriseServiceTrade";
import { AbstractSerializable } from "../../../../serialize/AbstractJsonSerializable";
import { CompositeEnterpriseServiceTradableInfo } from "./CompositeEnterpriseServiceTradableInfo";
import { JsonTypeTagType } from "../../../../serialize/JsonTypeTagType";
import { Serializer } from "../../../../serialize/Serializer";
import { TypeFactory } from "../../../../serialize/TypeFactory";

export class UserServiceTradableInfo extends AbstractSerializable {
  tradableInfo: CompositeEnterpriseServiceTradableInfo;
  trade: AbstractEnterpriseServiceTrade;

  constructor() {
    super();
  }

  public getTradableInfo(): CompositeEnterpriseServiceTradableInfo {
    return this.tradableInfo;
  }

  public setTradableInfo(tradableInfo: CompositeEnterpriseServiceTradableInfo) {
    this.tradableInfo = tradableInfo;
  }

  public getTrade(): AbstractEnterpriseServiceTrade {
    return this.trade;
  }

  public setTrade(trade: AbstractEnterpriseServiceTrade) {
    this.trade = trade;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const tradableInfo = json["tradableInfo"];
    if (tradableInfo != null) {
      const convertedTradableInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        tradableInfo,
        "__type",
        factory
      );
      this.setTradableInfo(convertedTradableInfo0);
    }
    const trade = json["trade"];
    if (trade != null) {
      const convertedTrade0 = Serializer.fillFromJsonObjectWithTypeTag(
        trade,
        "__type",
        factory
      );
      this.setTrade(convertedTrade0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const tradableInfo = this.getTradableInfo();
    if (tradableInfo != null) {
      const convertedTradableInfo0 = tradableInfo.getJson(JsonTypeTagType.TYPE);
      json["tradableInfo"] = convertedTradableInfo0;
    }
    const trade = this.getTrade();
    if (trade != null) {
      const convertedTrade0 = trade.getJson(JsonTypeTagType.TYPE);
      json["trade"] = convertedTrade0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.tradable.UserServiceTradableInfo";
  }
}
