/**
 * 自动生成， 请不要手工修改！
 */

export enum OperationType {
  READ_DATA,
  CREATE_DATA,
  UPDATE_DATA,
  DELETE_DATA,
  APPROVE_DATA,
  PUBLISH_DATA,
  BID_WORK,
  CLAIM_TASK,
  RESOLVE_TASK,
  CREATE_ENTITY,
  UPDATE_STATUS,
  READ_ASSOCIATE,
  ADD_ASSOCIATE,
  DEL_ASSOCIATE,
  ADD_PRODUCT,
  DEL_PRODUCT,
  SUBSCRIBE,
  BG_CHECK,
}
