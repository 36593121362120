/**
 * 自动生成， 请不要手工修改！
 */


export enum SubNotificationType {
    SUBSCRIBED,
    USERAD_MATCH,
    COMPETE_REGION,
    ACTIVITY,
    OFFICAL_MSG,
    REPORT_MSG,
    GENERAL_MSG,
    COMMUNITY_DIVIDEND,
    ACTIVITY_REWARD,
    WITHDRAWAL,
    APPROVAL,
    REPLY,
    KUAKUA_TA,
    COMMENT,
    QUESTION,
    ANSWER,
    POINT,
    ROLE_APPROVED,
    ROLE_REJECTED,
    USER_EDIT_REJECTED,
    ENTERPRISE_EDIT_REJECTED,
    TOPIC_EIDT_REJECTED,
    PRODUCT_EIDT_REJECTED,
    SERVICE_EIDT_REJECTED,
    EIDT_REJECTED,
    PUBLISH_REJECTED,
    USER_EDIT_PASSED,
    ENTERPRISE_EDIT_PASSED,
    TOPIC_EIDT_PASSED,
    PRODUCT_EIDT_PASSED,
    SERVICE_EIDT_PASSED,
    EIDT_PASSED,
    BG_CHECK_COMPLETED,
    BG_CHECK_REJECTED,
    BG_CHECK_ACKED,
    BG_CHECK_FAILED,
    SR_ACCEPTED,
    SR_REPLIED,
    SR_USER_INTO_RECEIVED
}
