/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../../serialize/AbstractJsonSerializable";
import { EnterpriseServiceCategory } from "../EnterpriseServiceCategory";
import { EnterpriseServiceView } from "./EnterpriseServiceView";
import { JsonTypeTagType } from "../../../../serialize/JsonTypeTagType";
import { PageableData } from "../../../paging/PageableData";
import { Serializer } from "../../../../serialize/Serializer";
import { ServiceHighlight } from "../ServiceHighlight";
import { TypeFactory } from "../../../../serialize/TypeFactory";

export class PlatformHighlightedServiceInfos extends AbstractSerializable {
  serviceCategory: EnterpriseServiceCategory;
  categoryImgUrls: Array<string>;
  serviceHighlights: Array<ServiceHighlight>;
  serviceViews: PageableData<EnterpriseServiceView>;

  constructor() {
    super();
  }

  public getServiceCategory(): EnterpriseServiceCategory {
    return this.serviceCategory;
  }

  public setServiceCategory(serviceCategory: EnterpriseServiceCategory) {
    this.serviceCategory = serviceCategory;
  }

  public getCategoryImgUrls(): Array<string> {
    return this.categoryImgUrls;
  }

  public setCategoryImgUrls(categoryImgUrls: Array<string>) {
    this.categoryImgUrls = categoryImgUrls;
  }

  public getServiceHighlights(): Array<ServiceHighlight> {
    return this.serviceHighlights;
  }

  public setServiceHighlights(serviceHighlights: Array<ServiceHighlight>) {
    this.serviceHighlights = serviceHighlights;
  }

  public getServiceViews(): PageableData<EnterpriseServiceView> {
    return this.serviceViews;
  }

  public setServiceViews(serviceViews: PageableData<EnterpriseServiceView>) {
    this.serviceViews = serviceViews;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const serviceCategory = json["serviceCategory"];
    if (serviceCategory != null) {
      const convertedServiceCategory0 =
        EnterpriseServiceCategory[<string>serviceCategory];
      this.setServiceCategory(convertedServiceCategory0);
    }
    let categoryImgUrls = json["categoryImgUrls"];
    if (categoryImgUrls != null) {
      const convertedCategoryImgUrls0 = [];
      categoryImgUrls = categoryImgUrls[1];
      for (const i in categoryImgUrls) {
        const convertedCategoryImgUrls10 = categoryImgUrls[i];
        convertedCategoryImgUrls0.push(convertedCategoryImgUrls10);
      }
      this.setCategoryImgUrls(convertedCategoryImgUrls0);
    }
    let serviceHighlights = json["serviceHighlights"];
    if (serviceHighlights != null) {
      const convertedServiceHighlights0 = [];
      serviceHighlights = serviceHighlights[1];
      for (const i in serviceHighlights) {
        const convertedServiceHighlights10 = serviceHighlights[i];
        const convertedServiceHighlights1 = new ServiceHighlight();
        convertedServiceHighlights1.fillFromJson(
          convertedServiceHighlights10,
          factory
        );
        convertedServiceHighlights0.push(convertedServiceHighlights1);
      }
      this.setServiceHighlights(convertedServiceHighlights0);
    }
    const serviceViews = json["serviceViews"];
    if (serviceViews != null) {
      const convertedServiceViews0 = Serializer.fillFromJsonObjectWithTypeTag(
        serviceViews,
        "__type",
        factory
      );
      this.setServiceViews(convertedServiceViews0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const serviceCategory = this.getServiceCategory();
    if (serviceCategory != null) {
      const convertedServiceCategory0 =
        EnterpriseServiceCategory[serviceCategory];
      json["serviceCategory"] = convertedServiceCategory0;
    }
    const categoryImgUrls = this.getCategoryImgUrls();
    if (categoryImgUrls != null) {
      const convertedCategoryImgUrls0 = [];
      for (const i in categoryImgUrls) {
        const convertedCategoryImgUrls10 = categoryImgUrls[i];
        convertedCategoryImgUrls0.push(convertedCategoryImgUrls10);
      }
      const convertedCategoryImgUrlsWithType1 = [
        "java.util.ArrayList",
        convertedCategoryImgUrls0,
      ];
      json["categoryImgUrls"] = convertedCategoryImgUrlsWithType1;
    }
    const serviceHighlights = this.getServiceHighlights();
    if (serviceHighlights != null) {
      const convertedServiceHighlights0 = [];
      for (const i in serviceHighlights) {
        const convertedServiceHighlights10 = serviceHighlights[i];
        const convertedServiceHighlights1 =
          convertedServiceHighlights10.getJson(JsonTypeTagType.NONE);
        convertedServiceHighlights0.push(convertedServiceHighlights1);
      }
      const convertedServiceHighlightsWithType1 = [
        "java.util.ArrayList",
        convertedServiceHighlights0,
      ];
      json["serviceHighlights"] = convertedServiceHighlightsWithType1;
    }
    const serviceViews = this.getServiceViews();
    if (serviceViews != null) {
      const convertedServiceViews0 = serviceViews.getJson(JsonTypeTagType.TYPE);
      json["serviceViews"] = convertedServiceViews0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.tradable.PlatformHighlightedServiceInfos";
  }
}
