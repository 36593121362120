/**
 * 自动生成， 请不要手工修改！
 */

export enum InvoiceStatus {
  //已开票
  SUBMITTED,
  //处理中
  IN_PROCESS,
  //未开票
  COMPLETED,
}
