/**
 * 自动生成， 请不要手工修改！
 */


export enum Profession {
    LAWYER,
    DOMAIN_EXPERT,
    CUSTOMER_SERVICE
}
