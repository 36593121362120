// @ts-nocheck
import {AbstractServiceOpNotification} from './AbstractServiceOpNotification'
import {MessageContentType} from '../../message/MessageContentType'
import {TextType} from '../TextType'


export class ServiceOpNotification extends AbstractServiceOpNotification  {
    constructor() {
    	super();
    }

    getMessageType(): MessageContentType {
      // Manual Code Here
    }
    getTextType(): TextType {
      // Manual Code Here
    }
    getText(): string {
      // Manual Code Here
    }

}
