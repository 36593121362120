/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {ProductAttribute} from './ProductAttribute'
import {TypeFactory} from '../../serialize/TypeFactory'


export class ProductAttributes extends AbstractSerializable {
    title: string;
    attributes: Array<ProductAttribute>;

    constructor() {
        super();
    }

    public getTitle(): string {
        return this.title;
    }

    public setTitle(title: string) {
        this.title = title;
    }

    public getAttributes(): Array<ProductAttribute> {
        return this.attributes;
    }

    public setAttributes(attributes: Array<ProductAttribute>) {
        this.attributes = attributes;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let attributes = json["attributes"];
        if (attributes != null) {
            let convertedAttributes0 = [];
            attributes = attributes[1];
            for (let i in attributes) {
                let convertedAttributes10 = attributes[i];
                let convertedAttributes1 = new ProductAttribute();
                convertedAttributes1.fillFromJson(convertedAttributes10, factory);
                convertedAttributes0.push(convertedAttributes1);
            }
            this.setAttributes(convertedAttributes0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let attributes = this.getAttributes();
        if (attributes != null) {
            let convertedAttributes0 = [];
            for (let i in attributes) {
                let convertedAttributes10 = attributes[i];
                let convertedAttributes1 = convertedAttributes10.getJson(JsonTypeTagType.NONE);
                convertedAttributes0.push(convertedAttributes1);
            }
            let convertedAttributesWithType1 = ["java.util.ArrayList", convertedAttributes0];
            json["attributes"] = convertedAttributesWithType1;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.product.ProductAttributes";
    }
}
