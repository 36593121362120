/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {CouponType} from './CouponType'
import {CouponUseType} from './CouponUseType'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class QllCouponTemplate extends AbstractSerializable  {
    templateId: (number | string);
    couponType: CouponType;
    couponUseType: CouponUseType;
    packaged: boolean;
    pkgTemplateId: (number | string);
    imgUrl: string;
    regionId: (number | string);
    title: string;
    amount: number;
    minOrderAmount: number;
    creatorId: (number | string);
    tradableId: (number | string);
    tradableName: string;
    createTime: (number | string);
    endTime: (number | string);
    couponComponentTemplates: Array<QllCouponTemplate>;
    couponLimit: number;
    totalAcquired: number;
    totalUsed: number;

    constructor() {
    	super();
    }

    public getTemplateId(): (number | string) {
    	return this.templateId;
    }

    public setTemplateId(templateId: (number | string)) {
    	this.templateId = templateId;
    }

    public getCouponType(): CouponType {
    	return this.couponType;
    }

    public setCouponType(couponType: CouponType) {
    	this.couponType = couponType;
    }

    public getCouponUseType(): CouponUseType {
    	return this.couponUseType;
    }

    public setCouponUseType(couponUseType: CouponUseType) {
    	this.couponUseType = couponUseType;
    }

    public isPackaged(): boolean {
    	return this.packaged;
    }

    public setPackaged(packaged: boolean) {
    	this.packaged = packaged;
    }

    public getPkgTemplateId(): (number | string) {
    	return this.pkgTemplateId;
    }

    public setPkgTemplateId(pkgTemplateId: (number | string)) {
    	this.pkgTemplateId = pkgTemplateId;
    }

    public getImgUrl(): string {
    	return this.imgUrl;
    }

    public setImgUrl(imgUrl: string) {
    	this.imgUrl = imgUrl;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getTitle(): string {
    	return this.title;
    }

    public setTitle(title: string) {
    	this.title = title;
    }

    public getAmount(): number {
    	return this.amount;
    }

    public setAmount(amount: number) {
    	this.amount = amount;
    }

    public getMinOrderAmount(): number {
    	return this.minOrderAmount;
    }

    public setMinOrderAmount(minOrderAmount: number) {
    	this.minOrderAmount = minOrderAmount;
    }

    public getCreatorId(): (number | string) {
    	return this.creatorId;
    }

    public setCreatorId(creatorId: (number | string)) {
    	this.creatorId = creatorId;
    }

    public getTradableId(): (number | string) {
    	return this.tradableId;
    }

    public setTradableId(tradableId: (number | string)) {
    	this.tradableId = tradableId;
    }

    public getTradableName(): string {
    	return this.tradableName;
    }

    public setTradableName(tradableName: string) {
    	this.tradableName = tradableName;
    }

    public getCreateTime(): (number | string) {
    	return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
    	this.createTime = createTime;
    }

    public getEndTime(): (number | string) {
    	return this.endTime;
    }

    public setEndTime(endTime: (number | string)) {
    	this.endTime = endTime;
    }

    public getCouponComponentTemplates(): Array<QllCouponTemplate> {
    	return this.couponComponentTemplates;
    }

    public setCouponComponentTemplates(couponComponentTemplates: Array<QllCouponTemplate>) {
    	this.couponComponentTemplates = couponComponentTemplates;
    }

    public getCouponLimit(): number {
    	return this.couponLimit;
    }

    public setCouponLimit(couponLimit: number) {
    	this.couponLimit = couponLimit;
    }

    public getTotalAcquired(): number {
    	return this.totalAcquired;
    }

    public setTotalAcquired(totalAcquired: number) {
    	this.totalAcquired = totalAcquired;
    }

    public getTotalUsed(): number {
    	return this.totalUsed;
    }

    public setTotalUsed(totalUsed: number) {
    	this.totalUsed = totalUsed;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let templateId = json["templateId"];
        if (templateId != null) {
            this.setTemplateId(templateId);
        }
        let couponType = json["couponType"];
        if (couponType != null) {
            let convertedCouponType0 = CouponType[<string>couponType];
            this.setCouponType(convertedCouponType0);
        }
        let couponUseType = json["couponUseType"];
        if (couponUseType != null) {
            let convertedCouponUseType0 = CouponUseType[<string>couponUseType];
            this.setCouponUseType(convertedCouponUseType0);
        }
        let packaged = json["packaged"];
        if (packaged != null) {
            this.setPackaged(packaged);
        }
        let pkgTemplateId = json["pkgTemplateId"];
        if (pkgTemplateId != null) {
            this.setPkgTemplateId(pkgTemplateId);
        }
        let imgUrl = json["imgUrl"];
        if (imgUrl != null) {
            this.setImgUrl(imgUrl);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let amount = json["amount"];
        if (amount != null) {
            this.setAmount(amount);
        }
        let minOrderAmount = json["minOrderAmount"];
        if (minOrderAmount != null) {
            this.setMinOrderAmount(minOrderAmount);
        }
        let creatorId = json["creatorId"];
        if (creatorId != null) {
            this.setCreatorId(creatorId);
        }
        let tradableId = json["tradableId"];
        if (tradableId != null) {
            this.setTradableId(tradableId);
        }
        let tradableName = json["tradableName"];
        if (tradableName != null) {
            this.setTradableName(tradableName);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let endTime = json["endTime"];
        if (endTime != null) {
            this.setEndTime(endTime);
        }
        let couponComponentTemplates = json["couponComponentTemplates"];
        if (couponComponentTemplates != null) {
            let convertedCouponComponentTemplates0 = [];
            couponComponentTemplates = couponComponentTemplates[1];
            for (let i in couponComponentTemplates) {
                let convertedCouponComponentTemplates10 = couponComponentTemplates[i];
                let convertedCouponComponentTemplates1 = new QllCouponTemplate();
                convertedCouponComponentTemplates1.fillFromJson(convertedCouponComponentTemplates10, factory);
                convertedCouponComponentTemplates0.push(convertedCouponComponentTemplates1);
            }
            this.setCouponComponentTemplates(convertedCouponComponentTemplates0);
        }
        let couponLimit = json["couponLimit"];
        if (couponLimit != null) {
            this.setCouponLimit(couponLimit);
        }
        let totalAcquired = json["totalAcquired"];
        if (totalAcquired != null) {
            this.setTotalAcquired(totalAcquired);
        }
        let totalUsed = json["totalUsed"];
        if (totalUsed != null) {
            this.setTotalUsed(totalUsed);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let templateId = this.getTemplateId();
        if (templateId != null) {
            json["templateId"] = String(templateId);
        }
        let couponType = this.getCouponType();
        if (couponType != null) {
            let convertedCouponType0 = CouponType[couponType];
            json["couponType"] = convertedCouponType0;
        }
        let couponUseType = this.getCouponUseType();
        if (couponUseType != null) {
            let convertedCouponUseType0 = CouponUseType[couponUseType];
            json["couponUseType"] = convertedCouponUseType0;
        }
        let packaged = this.isPackaged();
        if (packaged != null) {
            json["packaged"] = packaged;
        }
        let pkgTemplateId = this.getPkgTemplateId();
        if (pkgTemplateId != null) {
            json["pkgTemplateId"] = String(pkgTemplateId);
        }
        let imgUrl = this.getImgUrl();
        if (imgUrl != null) {
            json["imgUrl"] = imgUrl;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let amount = this.getAmount();
        if (amount != null) {
            json["amount"] = amount;
        }
        let minOrderAmount = this.getMinOrderAmount();
        if (minOrderAmount != null) {
            json["minOrderAmount"] = minOrderAmount;
        }
        let creatorId = this.getCreatorId();
        if (creatorId != null) {
            json["creatorId"] = String(creatorId);
        }
        let tradableId = this.getTradableId();
        if (tradableId != null) {
            json["tradableId"] = String(tradableId);
        }
        let tradableName = this.getTradableName();
        if (tradableName != null) {
            json["tradableName"] = tradableName;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let endTime = this.getEndTime();
        if (endTime != null) {
            json["endTime"] = String(endTime);
        }
        let couponComponentTemplates = this.getCouponComponentTemplates();
        if (couponComponentTemplates != null) {
            let convertedCouponComponentTemplates0 = [];
            for (let i in couponComponentTemplates) {
                let convertedCouponComponentTemplates10 = couponComponentTemplates[i];
                let convertedCouponComponentTemplates1 = convertedCouponComponentTemplates10.getJson(JsonTypeTagType.NONE);
                convertedCouponComponentTemplates0.push(convertedCouponComponentTemplates1);
            }
            let convertedCouponComponentTemplatesWithType1 = ["java.util.ArrayList", convertedCouponComponentTemplates0];
            json["couponComponentTemplates"] = convertedCouponComponentTemplatesWithType1;
        }
        let couponLimit = this.getCouponLimit();
        if (couponLimit != null) {
            json["couponLimit"] = couponLimit;
        }
        let totalAcquired = this.getTotalAcquired();
        if (totalAcquired != null) {
            json["totalAcquired"] = totalAcquired;
        }
        let totalUsed = this.getTotalUsed();
        if (totalUsed != null) {
            json["totalUsed"] = totalUsed;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.coupon.QllCouponTemplate";
    }
}
