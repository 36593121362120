// @ts-nocheck
import {AbstractCompositePageDataSpec} from './AbstractCompositePageDataSpec'
import {PageBlockAccessSpec} from './PageBlockAccessSpec'
import {PageDataSpec} from './PageDataSpec'


export class CompositePageDataSpec extends AbstractCompositePageDataSpec {
    constructor() {
        super();
    }

    isRecordsRemaining(): boolean {
        // Manual Code Here
    }

    getForwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec {
        // Manual Code Here
    }

    getForwardPageSpecWithDefaultSize(): PageBlockAccessSpec {
        // Manual Code Here
    }

    getNextBackwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec {
        // Manual Code Here
    }

    getNextBackwardPageSpecWithDefaultSize(): PageBlockAccessSpec {
        // Manual Code Here
    }

    addPageDataSpec(arg0: (PageDataSpec | null), arg1: (boolean | null)): PageDataSpec {
        // Manual Code Here
    }

    getContinuousNoDataCount(): number {
        // Manual Code Here
    }

    getEstimatedTotalRecords(): number {
        // Manual Code Here
    }

    getFetchedSize(): number {
        // Manual Code Here
    }

    getNextStart(): number {
        // Manual Code Here
    }

    getStartPosition(): number {
        // Manual Code Here
    }

    setEstimatedTotalRecords(arg0: (number | null)) {
        // Manual Code Here
    }

    setRecordsRemaining(arg0: (boolean | null)) {
        // Manual Code Here
    }

}
