/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCowork} from '../cowork/AbstractCowork'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../keyword/KeywordEntity'
import {MessageType} from '../../message/MessageContentType'
import {QllCountry} from '../international/QllCountry'
import {TypeFactory} from '../../serialize/TypeFactory'
import {UserAdStatus} from './UserAdStatus'
import {UserAdType} from './UserAdType'


export abstract class UserAd extends AbstractCowork  {
    initiatorMobile: string;
    contactName: string;
    adType: UserAdType;
    shortName: string;
    status: UserAdStatus;
    mobilePublic: boolean;
    initiatorCredit: number;
    endTime: number;
    publisherName: string;

    constructor() {
    	super();
    }

    public getInitiatorMobile(): string {
    	return this.initiatorMobile;
    }

    public setInitiatorMobile(initiatorMobile: string) {
    	this.initiatorMobile = initiatorMobile;
    }

    public getContactName(): string {
    	return this.contactName;
    }

    public setContactName(contactName: string) {
    	this.contactName = contactName;
    }

    public getAdType(): UserAdType {
    	return this.adType;
    }

    public setAdType(adType: UserAdType) {
    	this.adType = adType;
    }

    public getShortName(): string {
    	return this.shortName;
    }

    public setShortName(shortName: string) {
    	this.shortName = shortName;
    }

    public getStatus(): UserAdStatus {
    	return this.status;
    }

    public setStatus(status: UserAdStatus) {
    	this.status = status;
    }

    public isMobilePublic(): boolean {
    	return this.mobilePublic;
    }

    public setMobilePublic(mobilePublic: boolean) {
    	this.mobilePublic = mobilePublic;
    }

    public getInitiatorCredit(): number {
    	return this.initiatorCredit;
    }

    public setInitiatorCredit(initiatorCredit: number) {
    	this.initiatorCredit = initiatorCredit;
    }

    public getEndTime(): number {
    	return this.endTime;
    }

    public setEndTime(endTime: number) {
    	this.endTime = endTime;
    }

    public getPublisherName(): string {
    	return this.publisherName;
    }

    public setPublisherName(publisherName: string) {
    	this.publisherName = publisherName;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;

    abstract getTypeId(): string;

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let initiatorMobile = json["initiatorMobile"];
        if (initiatorMobile != null) {
            this.setInitiatorMobile(initiatorMobile);
        }
        let contactName = json["contactName"];
        if (contactName != null) {
            this.setContactName(contactName);
        }
        let adType = json["adType"];
        if (adType != null) {
            let convertedAdType0 = UserAdType[<string>adType];
            this.setAdType(convertedAdType0);
        }
        let shortName = json["shortName"];
        if (shortName != null) {
            this.setShortName(shortName);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = UserAdStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let mobilePublic = json["mobilePublic"];
        if (mobilePublic != null) {
            this.setMobilePublic(mobilePublic);
        }
        let initiatorCredit = json["initiatorCredit"];
        if (initiatorCredit != null) {
            this.setInitiatorCredit(initiatorCredit);
        }
        let endTime = json["endTime"];
        if (endTime != null) {
            this.setEndTime(endTime);
        }
        let publisherName = json["publisherName"];
        if (publisherName != null) {
            this.setPublisherName(publisherName);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let initiatorMobile = this.getInitiatorMobile();
        if (initiatorMobile != null) {
            json["initiatorMobile"] = initiatorMobile;
        }
        let contactName = this.getContactName();
        if (contactName != null) {
            json["contactName"] = contactName;
        }
        let adType = this.getAdType();
        if (adType != null) {
            let convertedAdType0 = UserAdType[adType];
            json["adType"] = convertedAdType0;
        }
        let shortName = this.getShortName();
        if (shortName != null) {
            json["shortName"] = shortName;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = UserAdStatus[status];
            json["status"] = convertedStatus0;
        }
        let mobilePublic = this.isMobilePublic();
        if (mobilePublic != null) {
            json["mobilePublic"] = mobilePublic;
        }
        let initiatorCredit = this.getInitiatorCredit();
        if (initiatorCredit != null) {
            json["initiatorCredit"] = initiatorCredit;
        }
        let endTime = this.getEndTime();
        if (endTime != null) {
            json["endTime"] = endTime;
        }
        let publisherName = this.getPublisherName();
        if (publisherName != null) {
            json["publisherName"] = publisherName;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.userad.UserAd";
    }
}
