/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {CoworkType} from './CoworkType'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../serialize/TypeFactory'


export class CoworkIdentifier extends AbstractSerializable  {
    languageCode: number;
    objectType: CoworkType;
    id: (number | string);

    constructor() {
    	super();
    }

    public getLanguageCode(): number {
    	return this.languageCode;
    }

    public setLanguageCode(languageCode: number) {
    	this.languageCode = languageCode;
    }

    public getObjectType(): CoworkType {
    	return this.objectType;
    }

    public setObjectType(objectType: CoworkType) {
    	this.objectType = objectType;
    }

    public getId(): (number | string) {
    	return this.id;
    }

    public setId(id: (number | string)) {
    	this.id = id;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let languageCode = json["languageCode"];
        if (languageCode != null) {
            this.setLanguageCode(languageCode);
        }
        let objectType = json["objectType"];
        if (objectType != null) {
            let convertedObjectType0 = CoworkType[<string>objectType];
            this.setObjectType(convertedObjectType0);
        }
        let id = json["id"];
        if (id != null) {
            this.setId(id);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let languageCode = this.getLanguageCode();
        if (languageCode != null) {
            json["languageCode"] = languageCode;
        }
        let objectType = this.getObjectType();
        if (objectType != null) {
            let convertedObjectType0 = CoworkType[objectType];
            json["objectType"] = convertedObjectType0;
        }
        let id = this.getId();
        if (id != null) {
            json["id"] = String(id);
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.cowork.CoworkIdentifier";
    }
}
