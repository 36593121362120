/**
 * 自动生成， 请不要手工修改！
 */

import { CompositeInfoView } from "./CompositeInfoView";
import { CoworkSimpleView } from "./CoworkSimpleView";
import { CoworkUserInfo } from "./CoworkUserInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class CompositeCoworkView<
  S extends CoworkSimpleView,
  T extends CoworkUserInfo
> extends CompositeInfoView<S, T> {
  top: boolean;
  customSelectTime: number | string;

  constructor() {
    super();
  }

  public isTop(): boolean {
    return this.top;
  }

  public setTop(top: boolean) {
    this.top = top;
  }

  public getCustomSelectTime(): number | string {
    return this.customSelectTime;
  }

  public setCustomSelectTime(customSelectTime: number | string) {
    this.customSelectTime = customSelectTime;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const top = json["top"];
    if (top != null) {
      this.setTop(top);
    }
    const customSelectTime = json["customSelectTime"];
    if (customSelectTime != null) {
      this.setCustomSelectTime(customSelectTime);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const top = this.isTop();
    if (top != null) {
      json["top"] = top;
    }
    const customSelectTime = this.getCustomSelectTime();
    if (customSelectTime != null) {
      json["customSelectTime"] = String(customSelectTime);
    }

    if (includeTypeTag) {
      json["__type"] = "CompositeCoworkView";
    }
  }

  public getTypeId(): string {
    return "CompositeCoworkView";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.CompositeCoworkView";
  }
}
