/**
 * 自动生成， 请不要手工修改！
 */

import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {SimpleEntityView} from '../view/SimpleEntityView'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class TopicView extends SimpleEntityView {
    price: number;

    constructor() {
        super();
    }

    public getPrice(): number {
        return this.price;
    }

    public setPrice(price: number) {
        this.price = price;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let price = json["price"];
        if (price != null) {
            this.setPrice(price);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let price = this.getPrice();
        if (price != null) {
            json["price"] = price;
        }

        if (includeTypeTag) {
            json["__type"] = "TopicInfo";
        }
    }


    public getTypeId(): string {
        return "TopicInfo";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.topic.TopicView";
    }
}
