/**
 * 自动生成， 请不要手工修改！
 */

import {ActivityCooper} from './ActivityCooper'
import {Activity} from './Activity'
import {AwardType} from '../AwardType'
import {CoworkType} from '../cowork/CoworkType'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../keyword/KeywordEntity'
import {MessageType} from '../../message/MessageContentType'
import {QllCountry} from '../international/QllCountry'
import {Serializer} from '../../serialize/Serializer'
import {TextType} from '../TextType'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractPlatformActivity extends Activity  {
    enTitle: string;
    activityEntityId: (number | string);
    infoType: CoworkType;
    infoSubType: string;
    brief: string;
    textType: TextType;
    radius: number;
    awardType: AwardType;
    awardCount: number;
    sponsorship: number;
    enterpriseInfo: EntityInfo;
    coopers: Array<ActivityCooper>;
    geoWide: boolean;

    constructor() {
    	super();
    }

    public getEnTitle(): string {
    	return this.enTitle;
    }

    public setEnTitle(enTitle: string) {
    	this.enTitle = enTitle;
    }

    public getActivityEntityId(): (number | string) {
    	return this.activityEntityId;
    }

    public setActivityEntityId(activityEntityId: (number | string)) {
    	this.activityEntityId = activityEntityId;
    }

    public getInfoType(): CoworkType {
    	return this.infoType;
    }

    public setInfoType(infoType: CoworkType) {
    	this.infoType = infoType;
    }

    public getInfoSubType(): string {
    	return this.infoSubType;
    }

    public setInfoSubType(infoSubType: string) {
    	this.infoSubType = infoSubType;
    }

    public getBrief(): string {
    	return this.brief;
    }

    public setBrief(brief: string) {
    	this.brief = brief;
    }

    public getTextType(): TextType {
    	return this.textType;
    }

    public setTextType(textType: TextType) {
    	this.textType = textType;
    }

    public getRadius(): number {
    	return this.radius;
    }

    public setRadius(radius: number) {
    	this.radius = radius;
    }

    public getAwardType(): AwardType {
    	return this.awardType;
    }

    public setAwardType(awardType: AwardType) {
    	this.awardType = awardType;
    }

    public getAwardCount(): number {
    	return this.awardCount;
    }

    public setAwardCount(awardCount: number) {
    	this.awardCount = awardCount;
    }

    public getSponsorship(): number {
    	return this.sponsorship;
    }

    public setSponsorship(sponsorship: number) {
    	this.sponsorship = sponsorship;
    }

    public getEnterpriseInfo(): EntityInfo {
    	return this.enterpriseInfo;
    }

    public setEnterpriseInfo(enterpriseInfo: EntityInfo) {
    	this.enterpriseInfo = enterpriseInfo;
    }

    public getCoopers(): Array<ActivityCooper> {
    	return this.coopers;
    }

    public setCoopers(coopers: Array<ActivityCooper>) {
    	this.coopers = coopers;
    }

    public isGeoWide(): boolean {
    	return this.geoWide;
    }

    public setGeoWide(geoWide: boolean) {
    	this.geoWide = geoWide;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let enTitle = json["enTitle"];
        if (enTitle != null) {
            this.setEnTitle(enTitle);
        }
        let activityEntityId = json["activityEntityId"];
        if (activityEntityId != null) {
            this.setActivityEntityId(activityEntityId);
        }
        let infoType = json["infoType"];
        if (infoType != null) {
            let convertedInfoType0 = CoworkType[<string>infoType];
            this.setInfoType(convertedInfoType0);
        }
        let infoSubType = json["infoSubType"];
        if (infoSubType != null) {
            this.setInfoSubType(infoSubType);
        }
        let brief = json["brief"];
        if (brief != null) {
            this.setBrief(brief);
        }
        let textType = json["textType"];
        if (textType != null) {
            let convertedTextType0 = TextType[<string>textType];
            this.setTextType(convertedTextType0);
        }
        let radius = json["radius"];
        if (radius != null) {
            this.setRadius(radius);
        }
        let awardType = json["awardType"];
        if (awardType != null) {
            let convertedAwardType0 = AwardType[<string>awardType];
            this.setAwardType(convertedAwardType0);
        }
        let awardCount = json["awardCount"];
        if (awardCount != null) {
            this.setAwardCount(awardCount);
        }
        let sponsorship = json["sponsorship"];
        if (sponsorship != null) {
            this.setSponsorship(sponsorship);
        }
        let enterpriseInfo = json["enterpriseInfo"];
        if (enterpriseInfo != null) {
            let convertedEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(enterpriseInfo, "__type", factory);
            this.setEnterpriseInfo(convertedEnterpriseInfo0);
        }
        let coopers = json["coopers"];
        if (coopers != null) {
            let convertedCoopers0 = [];
            coopers = coopers[1];
            for (let i in coopers) {
                let convertedCoopers10 = coopers[i];
                let convertedCoopers1 = new ActivityCooper();
                convertedCoopers1.fillFromJson(convertedCoopers10, factory);
                convertedCoopers0.push(convertedCoopers1);
            }
            this.setCoopers(convertedCoopers0);
        }
        let geoWide = json["geoWide"];
        if (geoWide != null) {
            this.setGeoWide(geoWide);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let enTitle = this.getEnTitle();
        if (enTitle != null) {
            json["enTitle"] = enTitle;
        }
        let activityEntityId = this.getActivityEntityId();
        if (activityEntityId != null) {
            json["activityEntityId"] = String(activityEntityId);
        }
        let infoType = this.getInfoType();
        if (infoType != null) {
            let convertedInfoType0 = CoworkType[infoType];
            json["infoType"] = convertedInfoType0;
        }
        let infoSubType = this.getInfoSubType();
        if (infoSubType != null) {
            json["infoSubType"] = infoSubType;
        }
        let brief = this.getBrief();
        if (brief != null) {
            json["brief"] = brief;
        }
        let textType = this.getTextType();
        if (textType != null) {
            let convertedTextType0 = TextType[textType];
            json["textType"] = convertedTextType0;
        }
        let radius = this.getRadius();
        if (radius != null) {
            json["radius"] = radius;
        }
        let awardType = this.getAwardType();
        if (awardType != null) {
            let convertedAwardType0 = AwardType[awardType];
            json["awardType"] = convertedAwardType0;
        }
        let awardCount = this.getAwardCount();
        if (awardCount != null) {
            json["awardCount"] = awardCount;
        }
        let sponsorship = this.getSponsorship();
        if (sponsorship != null) {
            json["sponsorship"] = sponsorship;
        }
        let enterpriseInfo = this.getEnterpriseInfo();
        if (enterpriseInfo != null) {
            let convertedEnterpriseInfo0 = enterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["enterpriseInfo"] = convertedEnterpriseInfo0;
        }
        let coopers = this.getCoopers();
        if (coopers != null) {
            let convertedCoopers0 = [];
            for (let i in coopers) {
                let convertedCoopers10 = coopers[i];
                let convertedCoopers1 = convertedCoopers10.getJson(JsonTypeTagType.NONE);
                convertedCoopers0.push(convertedCoopers1);
            }
            let convertedCoopersWithType1 = ["java.util.ArrayList", convertedCoopers0];
            json["coopers"] = convertedCoopersWithType1;
        }
        let geoWide = this.isGeoWide();
        if (geoWide != null) {
            json["geoWide"] = geoWide;
        }

        if (includeTypeTag) {
            json["__type"] = "PlatformActivity";
        }
    }


    public getTypeId(): string {
    	return "PlatformActivity";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.activity.PlatformActivity";
    }
}
