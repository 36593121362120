/**
 * 自动生成， 请不要手工修改！
 */

export enum Importance {
  CRITICAL,
  HIGH,
  LOW,
  NONE,
}
