/**
 * 自动生成， 请不要手工修改！
 */

export enum AppealReplyStatus {
  VERY_USEFUL,
  USEFUL,
  NOT_TRUE,
  REPEAT,
  MISSING_INFO,
}
