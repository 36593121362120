/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {ActivityView} from '../datatype/activity/ActivityView'
import {AnswerContent} from '../datatype/ipsphere/qa/AnswerContent'
import {CompositeCoworkView} from '../datatype/cowork/CompositeCoworkView'
import {CoworkType} from '../datatype/cowork/CoworkType'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EliteInfo} from '../datatype/entity/user/EliteInfo'
import {EliteState} from '../datatype/entity/user/EliteState'
import {EnterpriseCategory} from '../datatype/entity/enterprise/EnterpriseCategory'
import {EnterpriseServiceOrder} from '../datatype/business/order/EnterpriseServiceOrder'
import {EnterpriseServiceTradeInfo} from '../datatype/business/service/trade/EnterpriseServiceTradeInfo'
import {EnterpriseType} from '../datatype/entity/enterprise/EnterpriseType'
import {EntityInfo} from '../datatype/entity/EntityInfo'
import {GeneralServiceRequestUserAdView} from '../datatype/userad/general/GeneralServiceRequestUserAdView'
import {GeoEntityDesc} from '../datatype/geolocation/GeoEntityDesc'
import {MineQuerySpec} from '../datatype/query/mine/MineQuerySpec'
import {MineService} from '../api/mine/MineService'
import {OrderQuerySpec} from '../datatype/business/order/OrderQuerySpec'
import {PageableData} from '../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {QuestionOverview} from '../datatype/ipsphere/qa/QuestionOverview'
import {ReviewOverview} from '../datatype/ipsphere/review/ReviewOverview'
import {SharedInfoView} from '../datatype/share/SharedInfoView'
import {SimpleEntityView} from '../datatype/entity/view/SimpleEntityView'
import {TradeQuerySpec} from '../datatype/business/general/trade/TradeQuerySpec'
import {WebMineService} from '../webapi/WebMineService'
import {WebSessionManager} from '../common/WebSessionManager'


export class WebMineServiceImpl implements WebMineService {

    private mineService: MineService;

    public constructor() {
        this.mineService = DefaultServiceFactory.getInstance().getMineService();
    }

    public applyForElite(eliteInfo: EliteInfo): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.mineService.applyForElite(WebSessionManager.getSessionContext(),
        	eliteInfo,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findActiveEnterpriseServicePackageTrades(userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceTradeInfo>> {
        return new Promise((resolve, reject) => {
        	this.mineService.findActiveEnterpriseServicePackageTrades(WebSessionManager.getSessionContext(),
        	userId, pageBlockSpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findActiveEnterpriseServiceTrades(userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceTradeInfo>> {
        return new Promise((resolve, reject) => {
        	this.mineService.findActiveEnterpriseServiceTrades(WebSessionManager.getSessionContext(),
        	userId, pageBlockSpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceOrders(userId: (number | string | null), orderQuerySpec: (OrderQuerySpec | null)): Promise<PageableData<EnterpriseServiceOrder>> {
        return new Promise((resolve, reject) => {
        	this.mineService.findEnterpriseServiceOrders(WebSessionManager.getSessionContext(),
        	userId, orderQuerySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServicePackageTrades(userId: (number | string | null), tradeQuerySpec: (TradeQuerySpec | null)): Promise<PageableData<EnterpriseServiceTradeInfo>> {
        return new Promise((resolve, reject) => {
        	this.mineService.findEnterpriseServicePackageTrades(WebSessionManager.getSessionContext(),
        	userId, tradeQuerySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceTrades(userId: (number | string | null), tradeQuerySpec: (TradeQuerySpec | null)): Promise<PageableData<EnterpriseServiceTradeInfo>> {
        return new Promise((resolve, reject) => {
        	this.mineService.findEnterpriseServiceTrades(WebSessionManager.getSessionContext(),
        	userId, tradeQuerySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getApprovedCoworks(userId: (number | string | null), coworkType: (CoworkType | null), pageBlockSpec: PageBlockAccessSpec): Promise<PageableData<CompositeCoworkView<any,any>>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getApprovedCoworks(WebSessionManager.getSessionContext(),
        	userId, coworkType, pageBlockSpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getCollectedAnswers(userId: (number | string | null), querySpec: MineQuerySpec): Promise<PageableData<AnswerContent>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getCollectedAnswers(WebSessionManager.getSessionContext(),
        	userId, querySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getCollectedReviews(userId: (number | string | null), querySpec: MineQuerySpec): Promise<PageableData<ReviewOverview>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getCollectedReviews(WebSessionManager.getSessionContext(),
        	userId, querySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getConcernedActivities(userId: (number | string | null), pageAccessSpec: PageBlockAccessSpec): Promise<PageableData<ActivityView>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getConcernedActivities(WebSessionManager.getSessionContext(),
        	userId, pageAccessSpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getConcernedCoops(userId: (number | string | null), pageAccessSpec: PageBlockAccessSpec): Promise<PageableData<GeneralServiceRequestUserAdView>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getConcernedCoops(WebSessionManager.getSessionContext(),
        	userId, pageAccessSpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getConcernedQuestion(userId: (number | string | null), querySpec: MineQuerySpec): Promise<PageableData<QuestionOverview>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getConcernedQuestion(WebSessionManager.getSessionContext(),
        	userId, querySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getEliteState(userId: (number | string | null)): Promise<EliteState> {
        return new Promise((resolve, reject) => {
        	this.mineService.getEliteState(WebSessionManager.getSessionContext(),
        	userId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getEnterprisesInMyScope(userId: (number | string | null), pageAccessSpec: PageBlockAccessSpec): Promise<PageableData<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getEnterprisesInMyScope(WebSessionManager.getSessionContext(),
        	userId, pageAccessSpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getManagedEnterprises(userId: (number | string | null), business: (boolean | null), enterpriseType: (EnterpriseType | null)): Promise<Array<SimpleEntityView>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getManagedEnterprises(WebSessionManager.getSessionContext(),
        	userId, business, enterpriseType,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getManagedOrCreatedEnterpriseDescs(enterpriseCategory: (EnterpriseCategory | null)): Promise<Array<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getManagedOrCreatedEnterpriseDescs(WebSessionManager.getSessionContext(),
        	enterpriseCategory,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getManagedOrCreatedEnterprises(enterpriseCategory: (EnterpriseCategory | null)): Promise<Array<EntityInfo>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getManagedOrCreatedEnterprises(WebSessionManager.getSessionContext(),
        	enterpriseCategory,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getMyCreationAnswers(userId: (number | string | null), querySpec: MineQuerySpec): Promise<PageableData<AnswerContent>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getMyCreationAnswers(WebSessionManager.getSessionContext(),
        	userId, querySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getMyCreationCoops(userId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PageableData<GeneralServiceRequestUserAdView>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getMyCreationCoops(WebSessionManager.getSessionContext(),
        	userId, pageBlockSpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getMyCreationQuestions(userId: (number | string | null), querySpec: MineQuerySpec): Promise<PageableData<QuestionOverview>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getMyCreationQuestions(WebSessionManager.getSessionContext(),
        	userId, querySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getMyCreationReviews(userId: (number | string | null), querySpec: MineQuerySpec): Promise<PageableData<ReviewOverview>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getMyCreationReviews(WebSessionManager.getSessionContext(),
        	userId, querySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getMyCreationSharedInfos(userId: (number | string | null), querySpec: MineQuerySpec): Promise<PageableData<SharedInfoView>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getMyCreationSharedInfos(WebSessionManager.getSessionContext(),
        	userId, querySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getMyCreations(userId: (number | string | null), querySpec: MineQuerySpec): Promise<PageableData<CompositeCoworkView<any,any>>> {
        return new Promise((resolve, reject) => {
        	this.mineService.getMyCreations(WebSessionManager.getSessionContext(),
        	userId, querySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public quitElite(userId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.mineService.quitElite(WebSessionManager.getSessionContext(),
        	userId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

}
