/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {PageableData} from '../../paging/PageableData'
import {ReviewOverview} from '../../ipsphere/review/ReviewOverview'
import {Serializer} from '../../../serialize/Serializer'
import {TopicView} from '../topic/TopicView'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class UserServices extends AbstractSerializable {
    products: Array<TopicView>;
    reviewOverviews: PageableData<ReviewOverview>;

    constructor() {
        super();
    }

    public getProducts(): Array<TopicView> {
        return this.products;
    }

    public setProducts(products: Array<TopicView>) {
        this.products = products;
    }

    public getReviewOverviews(): PageableData<ReviewOverview> {
        return this.reviewOverviews;
    }

    public setReviewOverviews(reviewOverviews: PageableData<ReviewOverview>) {
        this.reviewOverviews = reviewOverviews;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let products = json["products"];
        if (products != null) {
            let convertedProducts0 = [];
            products = products[1];
            for (let i in products) {
                let convertedProducts10 = products[i];
                let convertedProducts1 = Serializer.fillFromJsonObjectWithTypeTag(convertedProducts10, "__type", factory);
                convertedProducts0.push(convertedProducts1);
            }
            this.setProducts(convertedProducts0);
        }
        let reviewOverviews = json["reviewOverviews"];
        if (reviewOverviews != null) {
            let convertedReviewOverviews0 = Serializer.fillFromJsonObjectWithTypeTag(reviewOverviews, "__type", factory);
            this.setReviewOverviews(convertedReviewOverviews0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let products = this.getProducts();
        if (products != null) {
            let convertedProducts0 = [];
            for (let i in products) {
                let convertedProducts10 = products[i];
                let convertedProducts1 = convertedProducts10.getJson(JsonTypeTagType.TYPE);
                convertedProducts0.push(convertedProducts1);
            }
            let convertedProductsWithType1 = ["java.util.ArrayList", convertedProducts0];
            json["products"] = convertedProductsWithType1;
        }
        let reviewOverviews = this.getReviewOverviews();
        if (reviewOverviews != null) {
            let convertedReviewOverviews0 = reviewOverviews.getJson(JsonTypeTagType.TYPE);
            json["reviewOverviews"] = convertedReviewOverviews0;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.user.UserServices";
    }
}
