/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { EntityInfo } from "./EntityInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class FansInfo extends AbstractSerializable {
  entityInfo: EntityInfo;
  ownSubscribed: boolean;

  constructor() {
    super();
  }

  public getEntityInfo(): EntityInfo {
    return this.entityInfo;
  }

  public setEntityInfo(entityInfo: EntityInfo) {
    this.entityInfo = entityInfo;
  }

  public isOwnSubscribed(): boolean {
    return this.ownSubscribed;
  }

  public setOwnSubscribed(ownSubscribed: boolean) {
    this.ownSubscribed = ownSubscribed;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const entityInfo = json["entityInfo"];
    if (entityInfo != null) {
      const convertedEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        entityInfo,
        "__type",
        factory
      );
      this.setEntityInfo(convertedEntityInfo0);
    }
    const ownSubscribed = json["ownSubscribed"];
    if (ownSubscribed != null) {
      this.setOwnSubscribed(ownSubscribed);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const entityInfo = this.getEntityInfo();
    if (entityInfo != null) {
      const convertedEntityInfo0 = entityInfo.getJson(JsonTypeTagType.TYPE);
      json["entityInfo"] = convertedEntityInfo0;
    }
    const ownSubscribed = this.isOwnSubscribed();
    if (ownSubscribed != null) {
      json["ownSubscribed"] = ownSubscribed;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.FansInfo";
  }
}
