/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CreditGrade } from "../credit/CreditGrade";
import { EntityInfo } from "./EntityInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class EntityStats extends AbstractSerializable {
  totalSubscribers: number;
  totalApprovals: number;
  relationGrade: CreditGrade;
  relationScore: number;
  totalPrize: number;
  currentStartDay: number;
  currentPrize: number;
  netOfficialApprovals: number;
  endorserInfo: EntityInfo;

  constructor() {
    super();
  }

  public getTotalSubscribers(): number {
    return this.totalSubscribers;
  }

  public setTotalSubscribers(totalSubscribers: number) {
    this.totalSubscribers = totalSubscribers;
  }

  public getTotalApprovals(): number {
    return this.totalApprovals;
  }

  public setTotalApprovals(totalApprovals: number) {
    this.totalApprovals = totalApprovals;
  }

  public getRelationGrade(): CreditGrade {
    return this.relationGrade;
  }

  public setRelationGrade(relationGrade: CreditGrade) {
    this.relationGrade = relationGrade;
  }

  public getRelationScore(): number {
    return this.relationScore;
  }

  public setRelationScore(relationScore: number) {
    this.relationScore = relationScore;
  }

  public getTotalPrize(): number {
    return this.totalPrize;
  }

  public setTotalPrize(totalPrize: number) {
    this.totalPrize = totalPrize;
  }

  public getCurrentStartDay(): number {
    return this.currentStartDay;
  }

  public setCurrentStartDay(currentStartDay: number) {
    this.currentStartDay = currentStartDay;
  }

  public getCurrentPrize(): number {
    return this.currentPrize;
  }

  public setCurrentPrize(currentPrize: number) {
    this.currentPrize = currentPrize;
  }

  public getNetOfficialApprovals(): number {
    return this.netOfficialApprovals;
  }

  public setNetOfficialApprovals(netOfficialApprovals: number) {
    this.netOfficialApprovals = netOfficialApprovals;
  }

  public getEndorserInfo(): EntityInfo {
    return this.endorserInfo;
  }

  public setEndorserInfo(endorserInfo: EntityInfo) {
    this.endorserInfo = endorserInfo;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const totalSubscribers = json["totalSubscribers"];
    if (totalSubscribers != null) {
      this.setTotalSubscribers(totalSubscribers);
    }
    const totalApprovals = json["totalApprovals"];
    if (totalApprovals != null) {
      this.setTotalApprovals(totalApprovals);
    }
    const relationGrade = json["relationGrade"];
    if (relationGrade != null) {
      const convertedRelationGrade0 = CreditGrade[<string>relationGrade];
      this.setRelationGrade(convertedRelationGrade0);
    }
    const relationScore = json["relationScore"];
    if (relationScore != null) {
      this.setRelationScore(relationScore);
    }
    const totalPrize = json["totalPrize"];
    if (totalPrize != null) {
      this.setTotalPrize(totalPrize);
    }
    const currentStartDay = json["currentStartDay"];
    if (currentStartDay != null) {
      this.setCurrentStartDay(currentStartDay);
    }
    const currentPrize = json["currentPrize"];
    if (currentPrize != null) {
      this.setCurrentPrize(currentPrize);
    }
    const netOfficialApprovals = json["netOfficialApprovals"];
    if (netOfficialApprovals != null) {
      this.setNetOfficialApprovals(netOfficialApprovals);
    }
    const endorserInfo = json["endorserInfo"];
    if (endorserInfo != null) {
      const convertedEndorserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        endorserInfo,
        "__type",
        factory
      );
      this.setEndorserInfo(convertedEndorserInfo0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const totalSubscribers = this.getTotalSubscribers();
    if (totalSubscribers != null) {
      json["totalSubscribers"] = totalSubscribers;
    }
    const totalApprovals = this.getTotalApprovals();
    if (totalApprovals != null) {
      json["totalApprovals"] = totalApprovals;
    }
    const relationGrade = this.getRelationGrade();
    if (relationGrade != null) {
      const convertedRelationGrade0 = CreditGrade[relationGrade];
      json["relationGrade"] = convertedRelationGrade0;
    }
    const relationScore = this.getRelationScore();
    if (relationScore != null) {
      json["relationScore"] = relationScore;
    }
    const totalPrize = this.getTotalPrize();
    if (totalPrize != null) {
      json["totalPrize"] = totalPrize;
    }
    const currentStartDay = this.getCurrentStartDay();
    if (currentStartDay != null) {
      json["currentStartDay"] = currentStartDay;
    }
    const currentPrize = this.getCurrentPrize();
    if (currentPrize != null) {
      json["currentPrize"] = currentPrize;
    }
    const netOfficialApprovals = this.getNetOfficialApprovals();
    if (netOfficialApprovals != null) {
      json["netOfficialApprovals"] = netOfficialApprovals;
    }
    const endorserInfo = this.getEndorserInfo();
    if (endorserInfo != null) {
      const convertedEndorserInfo0 = endorserInfo.getJson(JsonTypeTagType.TYPE);
      json["endorserInfo"] = convertedEndorserInfo0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.EntityStats";
  }
}
