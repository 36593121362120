/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCoworkInfo} from '../cowork/AbstractCoworkInfo'
import {AnnouncementStatus} from './AnnouncementStatus'
import {AnnounceType} from './AnnounceType'
import {CoworkInfo} from '../cowork/CoworkInfo'
import {CoworkSimpleView} from '../cowork/CoworkSimpleView'
import {CoworkStats} from '../cowork/CoworkStats'
import {CoworkType} from '../cowork/CoworkType'
import {EntityInfo} from '../entity/EntityInfo'
import {GeoHash} from '../geolocation/GeoHash'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {PricedTag} from './PricedTag'
import {SelectTimes} from '../cowork/SelectTimes'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractAnnouncementInfo extends AbstractCoworkInfo  {
    announceType: AnnounceType;
    endTime: number;
    description: string;
    address: string;
    activity: boolean;
    keywords: Array<string>;
    price: number;
    pricedTag: PricedTag;
    customPriceName: string;
    status: AnnouncementStatus;
    reRid: (number | string);

    constructor() {
    	super();
    }

    public getAnnounceType(): AnnounceType {
    	return this.announceType;
    }

    public setAnnounceType(announceType: AnnounceType) {
    	this.announceType = announceType;
    }

    public getEndTime(): number {
    	return this.endTime;
    }

    public setEndTime(endTime: number) {
    	this.endTime = endTime;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getAddress(): string {
    	return this.address;
    }

    public setAddress(address: string) {
    	this.address = address;
    }

    public getActivity(): boolean {
    	return this.activity;
    }

    public setActivity(activity: boolean) {
    	this.activity = activity;
    }

    public getKeywords(): Array<string> {
    	return this.keywords;
    }

    public setKeywords(keywords: Array<string>) {
    	this.keywords = keywords;
    }

    public getPrice(): number {
    	return this.price;
    }

    public setPrice(price: number) {
    	this.price = price;
    }

    public getPricedTag(): PricedTag {
    	return this.pricedTag;
    }

    public setPricedTag(pricedTag: PricedTag) {
    	this.pricedTag = pricedTag;
    }

    public getCustomPriceName(): string {
    	return this.customPriceName;
    }

    public setCustomPriceName(customPriceName: string) {
    	this.customPriceName = customPriceName;
    }

    public getStatus(): AnnouncementStatus {
    	return this.status;
    }

    public setStatus(status: AnnouncementStatus) {
    	this.status = status;
    }

    public getReRid(): (number | string) {
    	return this.reRid;
    }

    public setReRid(reRid: (number | string)) {
    	this.reRid = reRid;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let announceType = json["announceType"];
        if (announceType != null) {
            let convertedAnnounceType0 = AnnounceType[<string>announceType];
            this.setAnnounceType(convertedAnnounceType0);
        }
        let endTime = json["endTime"];
        if (endTime != null) {
            this.setEndTime(endTime);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let address = json["address"];
        if (address != null) {
            this.setAddress(address);
        }
        let activity = json["activity"];
        if (activity != null) {
            this.setActivity(activity);
        }
        let keywords = json["keywords"];
        if (keywords != null) {
            let convertedKeywords0 = [];
            keywords = keywords[1];
            for (let i in keywords) {
                let convertedKeywords10 = keywords[i];
                convertedKeywords0.push(convertedKeywords10);
            }
            this.setKeywords(convertedKeywords0);
        }
        let price = json["price"];
        if (price != null) {
            this.setPrice(price);
        }
        let pricedTag = json["pricedTag"];
        if (pricedTag != null) {
            let convertedPricedTag0 = PricedTag[<string>pricedTag];
            this.setPricedTag(convertedPricedTag0);
        }
        let customPriceName = json["customPriceName"];
        if (customPriceName != null) {
            this.setCustomPriceName(customPriceName);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = AnnouncementStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let reRid = json["reRid"];
        if (reRid != null) {
            this.setReRid(reRid);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let announceType = this.getAnnounceType();
        if (announceType != null) {
            let convertedAnnounceType0 = AnnounceType[announceType];
            json["announceType"] = convertedAnnounceType0;
        }
        let endTime = this.getEndTime();
        if (endTime != null) {
            json["endTime"] = endTime;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let address = this.getAddress();
        if (address != null) {
            json["address"] = address;
        }
        let activity = this.getActivity();
        if (activity != null) {
            json["activity"] = activity;
        }
        let keywords = this.getKeywords();
        if (keywords != null) {
            let convertedKeywords0 = [];
            for (let i in keywords) {
                let convertedKeywords10 = keywords[i];
                convertedKeywords0.push(convertedKeywords10);
            }
            let convertedKeywordsWithType1 = ["java.util.ArrayList", convertedKeywords0];
            json["keywords"] = convertedKeywordsWithType1;
        }
        let price = this.getPrice();
        if (price != null) {
            json["price"] = price;
        }
        let pricedTag = this.getPricedTag();
        if (pricedTag != null) {
            let convertedPricedTag0 = PricedTag[pricedTag];
            json["pricedTag"] = convertedPricedTag0;
        }
        let customPriceName = this.getCustomPriceName();
        if (customPriceName != null) {
            json["customPriceName"] = customPriceName;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = AnnouncementStatus[status];
            json["status"] = convertedStatus0;
        }
        let reRid = this.getReRid();
        if (reRid != null) {
            json["reRid"] = String(reRid);
        }
    
        if (includeTypeTag) {
            json["__type"] = "AnnouncementInfo";
        }
    }


    public getTypeId(): string {
    	return "AnnouncementInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.userad.AnnouncementInfo";
    }
}
