/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { LocalChat } from "./LocalChat";
import { LocalShared } from "./LocalShared";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class ChatState extends AbstractSerializable {
  recentChat: LocalChat;
  recentRedEnvelope: LocalShared;
  timeStamp: number | string;

  constructor() {
    super();
  }

  public getRecentChat(): LocalChat {
    return this.recentChat;
  }

  public setRecentChat(recentChat: LocalChat) {
    this.recentChat = recentChat;
  }

  public getRecentRedEnvelope(): LocalShared {
    return this.recentRedEnvelope;
  }

  public setRecentRedEnvelope(recentRedEnvelope: LocalShared) {
    this.recentRedEnvelope = recentRedEnvelope;
  }

  public getTimeStamp(): number | string {
    return this.timeStamp;
  }

  public setTimeStamp(timeStamp: number | string) {
    this.timeStamp = timeStamp;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const recentChat = json["recentChat"];
    if (recentChat != null) {
      const convertedRecentChat0 = Serializer.fillFromJsonObjectWithTypeTag(
        recentChat,
        "__type",
        factory
      );
      this.setRecentChat(convertedRecentChat0);
    }
    const recentRedEnvelope = json["recentRedEnvelope"];
    if (recentRedEnvelope != null) {
      const convertedRecentRedEnvelope0 =
        Serializer.fillFromJsonObjectWithTypeTag(
          recentRedEnvelope,
          "__type",
          factory
        );
      this.setRecentRedEnvelope(convertedRecentRedEnvelope0);
    }
    const timeStamp = json["timeStamp"];
    if (timeStamp != null) {
      this.setTimeStamp(timeStamp);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const recentChat = this.getRecentChat();
    if (recentChat != null) {
      const convertedRecentChat0 = recentChat.getJson(JsonTypeTagType.TYPE);
      json["recentChat"] = convertedRecentChat0;
    }
    const recentRedEnvelope = this.getRecentRedEnvelope();
    if (recentRedEnvelope != null) {
      const convertedRecentRedEnvelope0 = recentRedEnvelope.getJson(
        JsonTypeTagType.TYPE
      );
      json["recentRedEnvelope"] = convertedRecentRedEnvelope0;
    }
    const timeStamp = this.getTimeStamp();
    if (timeStamp != null) {
      json["timeStamp"] = String(timeStamp);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.geolocation.ChatState";
  }
}
