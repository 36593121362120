/**
 * 自动生成， 请不要手工修改！
 */

export enum REPrizeStatus {
  OPEN,
  CLOSED,
  CUR_CLOSED,
  USER_TAKEN,
}
