/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkReplyInfo } from "./CoworkReplyInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class SelectTimes extends AbstractSerializable {
  obsolete: boolean;
  score: number;
  weighted: number;
  selectTime: number | string;
  mediumSelectTime: number | string;
  globalSelectTime: number | string;
  dynamicSelectTime: number | string;
  mediumDynamicSelectTime: number | string;
  globalDynamicSelectTime: number | string;
  entitySelectTime: number | string;
  entityDynamicSelectTime: number | string;
  serviceSelectTime: number | string;
  recentTime: number | string;
  latestSelectTime: number | string;
  latestReplyInfo: CoworkReplyInfo;

  constructor() {
    super();
  }

  public isObsolete(): boolean {
    return this.obsolete;
  }

  public setObsolete(obsolete: boolean) {
    this.obsolete = obsolete;
  }

  public getScore(): number {
    return this.score;
  }

  public setScore(score: number) {
    this.score = score;
  }

  public getWeighted(): number {
    return this.weighted;
  }

  public setWeighted(weighted: number) {
    this.weighted = weighted;
  }

  public getSelectTime(): number | string {
    return this.selectTime;
  }

  public setSelectTime(selectTime: number | string) {
    this.selectTime = selectTime;
  }

  public getMediumSelectTime(): number | string {
    return this.mediumSelectTime;
  }

  public setMediumSelectTime(mediumSelectTime: number | string) {
    this.mediumSelectTime = mediumSelectTime;
  }

  public getGlobalSelectTime(): number | string {
    return this.globalSelectTime;
  }

  public setGlobalSelectTime(globalSelectTime: number | string) {
    this.globalSelectTime = globalSelectTime;
  }

  public getDynamicSelectTime(): number | string {
    return this.dynamicSelectTime;
  }

  public setDynamicSelectTime(dynamicSelectTime: number | string) {
    this.dynamicSelectTime = dynamicSelectTime;
  }

  public getMediumDynamicSelectTime(): number | string {
    return this.mediumDynamicSelectTime;
  }

  public setMediumDynamicSelectTime(mediumDynamicSelectTime: number | string) {
    this.mediumDynamicSelectTime = mediumDynamicSelectTime;
  }

  public getGlobalDynamicSelectTime(): number | string {
    return this.globalDynamicSelectTime;
  }

  public setGlobalDynamicSelectTime(globalDynamicSelectTime: number | string) {
    this.globalDynamicSelectTime = globalDynamicSelectTime;
  }

  public getEntitySelectTime(): number | string {
    return this.entitySelectTime;
  }

  public setEntitySelectTime(entitySelectTime: number | string) {
    this.entitySelectTime = entitySelectTime;
  }

  public getEntityDynamicSelectTime(): number | string {
    return this.entityDynamicSelectTime;
  }

  public setEntityDynamicSelectTime(entityDynamicSelectTime: number | string) {
    this.entityDynamicSelectTime = entityDynamicSelectTime;
  }

  public getServiceSelectTime(): number | string {
    return this.serviceSelectTime;
  }

  public setServiceSelectTime(serviceSelectTime: number | string) {
    this.serviceSelectTime = serviceSelectTime;
  }

  public getRecentTime(): number | string {
    return this.recentTime;
  }

  public setRecentTime(recentTime: number | string) {
    this.recentTime = recentTime;
  }

  public getLatestSelectTime(): number | string {
    return this.latestSelectTime;
  }

  public setLatestSelectTime(latestSelectTime: number | string) {
    this.latestSelectTime = latestSelectTime;
  }

  public getLatestReplyInfo(): CoworkReplyInfo {
    return this.latestReplyInfo;
  }

  public setLatestReplyInfo(latestReplyInfo: CoworkReplyInfo) {
    this.latestReplyInfo = latestReplyInfo;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const obsolete = json["obsolete"];
    if (obsolete != null) {
      this.setObsolete(obsolete);
    }
    const score = json["score"];
    if (score != null) {
      this.setScore(score);
    }
    const weighted = json["weighted"];
    if (weighted != null) {
      this.setWeighted(weighted);
    }
    const selectTime = json["selectTime"];
    if (selectTime != null) {
      this.setSelectTime(selectTime);
    }
    const mediumSelectTime = json["mediumSelectTime"];
    if (mediumSelectTime != null) {
      this.setMediumSelectTime(mediumSelectTime);
    }
    const globalSelectTime = json["globalSelectTime"];
    if (globalSelectTime != null) {
      this.setGlobalSelectTime(globalSelectTime);
    }
    const dynamicSelectTime = json["dynamicSelectTime"];
    if (dynamicSelectTime != null) {
      this.setDynamicSelectTime(dynamicSelectTime);
    }
    const mediumDynamicSelectTime = json["mediumDynamicSelectTime"];
    if (mediumDynamicSelectTime != null) {
      this.setMediumDynamicSelectTime(mediumDynamicSelectTime);
    }
    const globalDynamicSelectTime = json["globalDynamicSelectTime"];
    if (globalDynamicSelectTime != null) {
      this.setGlobalDynamicSelectTime(globalDynamicSelectTime);
    }
    const entitySelectTime = json["entitySelectTime"];
    if (entitySelectTime != null) {
      this.setEntitySelectTime(entitySelectTime);
    }
    const entityDynamicSelectTime = json["entityDynamicSelectTime"];
    if (entityDynamicSelectTime != null) {
      this.setEntityDynamicSelectTime(entityDynamicSelectTime);
    }
    const serviceSelectTime = json["serviceSelectTime"];
    if (serviceSelectTime != null) {
      this.setServiceSelectTime(serviceSelectTime);
    }
    const recentTime = json["recentTime"];
    if (recentTime != null) {
      this.setRecentTime(recentTime);
    }
    const latestSelectTime = json["latestSelectTime"];
    if (latestSelectTime != null) {
      this.setLatestSelectTime(latestSelectTime);
    }
    const latestReplyInfo = json["latestReplyInfo"];
    if (latestReplyInfo != null) {
      const convertedLatestReplyInfo0 = new CoworkReplyInfo();
      convertedLatestReplyInfo0.fillFromJson(latestReplyInfo, factory);
      this.setLatestReplyInfo(convertedLatestReplyInfo0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const obsolete = this.isObsolete();
    if (obsolete != null) {
      json["obsolete"] = obsolete;
    }
    const score = this.getScore();
    if (score != null) {
      json["score"] = score;
    }
    const weighted = this.getWeighted();
    if (weighted != null) {
      json["weighted"] = weighted;
    }
    const selectTime = this.getSelectTime();
    if (selectTime != null) {
      json["selectTime"] = String(selectTime);
    }
    const mediumSelectTime = this.getMediumSelectTime();
    if (mediumSelectTime != null) {
      json["mediumSelectTime"] = String(mediumSelectTime);
    }
    const globalSelectTime = this.getGlobalSelectTime();
    if (globalSelectTime != null) {
      json["globalSelectTime"] = String(globalSelectTime);
    }
    const dynamicSelectTime = this.getDynamicSelectTime();
    if (dynamicSelectTime != null) {
      json["dynamicSelectTime"] = String(dynamicSelectTime);
    }
    const mediumDynamicSelectTime = this.getMediumDynamicSelectTime();
    if (mediumDynamicSelectTime != null) {
      json["mediumDynamicSelectTime"] = String(mediumDynamicSelectTime);
    }
    const globalDynamicSelectTime = this.getGlobalDynamicSelectTime();
    if (globalDynamicSelectTime != null) {
      json["globalDynamicSelectTime"] = String(globalDynamicSelectTime);
    }
    const entitySelectTime = this.getEntitySelectTime();
    if (entitySelectTime != null) {
      json["entitySelectTime"] = String(entitySelectTime);
    }
    const entityDynamicSelectTime = this.getEntityDynamicSelectTime();
    if (entityDynamicSelectTime != null) {
      json["entityDynamicSelectTime"] = String(entityDynamicSelectTime);
    }
    const serviceSelectTime = this.getServiceSelectTime();
    if (serviceSelectTime != null) {
      json["serviceSelectTime"] = String(serviceSelectTime);
    }
    const recentTime = this.getRecentTime();
    if (recentTime != null) {
      json["recentTime"] = String(recentTime);
    }
    const latestSelectTime = this.getLatestSelectTime();
    if (latestSelectTime != null) {
      json["latestSelectTime"] = String(latestSelectTime);
    }
    const latestReplyInfo = this.getLatestReplyInfo();
    if (latestReplyInfo != null) {
      const convertedLatestReplyInfo0 = latestReplyInfo.getJson(
        JsonTypeTagType.NONE
      );
      json["latestReplyInfo"] = convertedLatestReplyInfo0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.SelectTimes";
  }
}
