/**
 * 自动生成， 请不要手工修改！
 */

export enum ContributionType {
  GENERAL,
  ENT_CREATION,
  ENT_OP_REVIEW,
  SUGGEST,
  SUBMIT_QUESTION,
  ANSWER_QUESTION,
  FUND,
  SHARE_INFO,
  SUBMIT_REVIEW,
  SUBMIT_USER_AD,
  ANNOUNCE,
  SUBMIT_ACTIVITY,
  ADD_PROD_REL,
  ADD_COMMENT,
  DEL_REVIEW,
  DEL_QUESTION,
  DEL_ANSWER,
  DEL_SHARE_INFO,
  DEL_ACTIVITY,
  DEL_USER_AD,
  DEL_ANNOUNCE,
  DEL_PROD_REL,
  DEL_COMMENT,
  PRESENT_ENTITY,
  REC_LOCATION,
  REC_ENTERPRISE,
  REC_NETIZEN,
  REC_REGION,
  REC_PRODUCT,
  REP_OFFENSE,
  OFFENSE,
  VOTE,
  VOLUNTARY_WORK,
  CANCEL_VOTE,
  ADD_GRID_MEM,
  ADD_GRID_ENT,
  DEL_GRID_MEM,
  DEL_GRID_ENT,
  ADD_APPEAL,
  DEL_APPEAL,
  SOLVE_APPEAL,
}
