/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCoworkInfo} from '../cowork/AbstractCoworkInfo'
import {CoworkInfo} from '../cowork/CoworkInfo'
import {CoworkSimpleView} from '../cowork/CoworkSimpleView'
import {CoworkStats} from '../cowork/CoworkStats'
import {CoworkType} from '../cowork/CoworkType'
import {EntityInfo} from '../entity/EntityInfo'
import {GeoHash} from '../geolocation/GeoHash'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../keyword/KeywordEntity'
import {SelectTimes} from '../cowork/SelectTimes'
import {SharedInfoStatus} from './SharedInfoStatus'
import {SharedInfoType} from './SharedInfoType'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractSimpleSharedInfo extends AbstractCoworkInfo  {
    infoType: SharedInfoType;
    keywordEntities: Array<KeywordEntity>;
    description: string;
    urlsByEditor: boolean;
    local: boolean;
    declare regionId: number | string;
    comments: number;
    status: SharedInfoStatus;

    constructor() {
    	super();
    }

    public getInfoType(): SharedInfoType {
    	return this.infoType;
    }

    public setInfoType(infoType: SharedInfoType) {
    	this.infoType = infoType;
    }

    public getKeywordEntities(): Array<KeywordEntity> {
    	return this.keywordEntities;
    }

    public setKeywordEntities(keywordEntities: Array<KeywordEntity>) {
    	this.keywordEntities = keywordEntities;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public isUrlsByEditor(): boolean {
    	return this.urlsByEditor;
    }

    public setUrlsByEditor(urlsByEditor: boolean) {
    	this.urlsByEditor = urlsByEditor;
    }

    public getLocal(): boolean {
    	return this.local;
    }

    public setLocal(local: boolean) {
    	this.local = local;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getComments(): number {
    	return this.comments;
    }

    public setComments(comments: number) {
    	this.comments = comments;
    }

    public getStatus(): SharedInfoStatus {
    	return this.status;
    }

    public setStatus(status: SharedInfoStatus) {
    	this.status = status;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let infoType = json["infoType"];
        if (infoType != null) {
            let convertedInfoType0 = SharedInfoType[<string>infoType];
            this.setInfoType(convertedInfoType0);
        }
        let keywordEntities = json["keywordEntities"];
        if (keywordEntities != null) {
            let convertedKeywordEntities0 = [];
            keywordEntities = keywordEntities[1];
            for (let i in keywordEntities) {
                let convertedKeywordEntities10 = keywordEntities[i];
                let convertedKeywordEntities1 = new KeywordEntity();
                convertedKeywordEntities1.fillFromJson(convertedKeywordEntities10, factory);
                convertedKeywordEntities0.push(convertedKeywordEntities1);
            }
            this.setKeywordEntities(convertedKeywordEntities0);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let urlsByEditor = json["urlsByEditor"];
        if (urlsByEditor != null) {
            this.setUrlsByEditor(urlsByEditor);
        }
        let local = json["local"];
        if (local != null) {
            this.setLocal(local);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let comments = json["comments"];
        if (comments != null) {
            this.setComments(comments);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = SharedInfoStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let infoType = this.getInfoType();
        if (infoType != null) {
            let convertedInfoType0 = SharedInfoType[infoType];
            json["infoType"] = convertedInfoType0;
        }
        let keywordEntities = this.getKeywordEntities();
        if (keywordEntities != null) {
            let convertedKeywordEntities0 = [];
            for (let i in keywordEntities) {
                let convertedKeywordEntities10 = keywordEntities[i];
                let convertedKeywordEntities1 = convertedKeywordEntities10.getJson(JsonTypeTagType.NONE);
                convertedKeywordEntities0.push(convertedKeywordEntities1);
            }
            let convertedKeywordEntitiesWithType1 = ["java.util.ArrayList", convertedKeywordEntities0];
            json["keywordEntities"] = convertedKeywordEntitiesWithType1;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let urlsByEditor = this.isUrlsByEditor();
        if (urlsByEditor != null) {
            json["urlsByEditor"] = urlsByEditor;
        }
        let local = this.getLocal();
        if (local != null) {
            json["local"] = local;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let comments = this.getComments();
        if (comments != null) {
            json["comments"] = comments;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = SharedInfoStatus[status];
            json["status"] = convertedStatus0;
        }

        if (includeTypeTag) {
            json["__type"] = "SimpleSharedInfo";
        }
    }


    public getTypeId(): string {
    	return "SimpleSharedInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.share.SimpleSharedInfo";
    }
}
