/**
 * 自动生成， 请不要手工修改！
 */

import {EnterpriseServiceRequest} from '../EnterpriseServiceRequest'
import {EntityInfo} from '../../../../entity/EntityInfo'
import {FinancingType} from '../../finance/FinancingType'
import {JsonTypeTagType} from '../../../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../../../keyword/KeywordEntity'
import {MessageType} from '../../../../../message/MessageContentType'
import {QllCountry} from '../../../../international/QllCountry'
import {TextType} from '../../../../TextType'
import {TypeFactory} from '../../../../../serialize/TypeFactory'


export abstract class AbstractFinancingServiceRequest extends EnterpriseServiceRequest  {
    financingType: FinancingType;
    financingAmount: number;
    contactNo: string;

    constructor() {
    	super();
    }

    public getFinancingType(): FinancingType {
    	return this.financingType;
    }

    public setFinancingType(financingType: FinancingType) {
    	this.financingType = financingType;
    }

    public getFinancingAmount(): number {
    	return this.financingAmount;
    }

    public setFinancingAmount(financingAmount: number) {
    	this.financingAmount = financingAmount;
    }

    public getContactNo(): string {
    	return this.contactNo;
    }

    public setContactNo(contactNo: string) {
    	this.contactNo = contactNo;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;

    abstract getTextType(): TextType;

    abstract getText(): string;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let financingType = json["financingType"];
        if (financingType != null) {
            let convertedFinancingType0 = FinancingType[<string>financingType];
            this.setFinancingType(convertedFinancingType0);
        }
        let financingAmount = json["financingAmount"];
        if (financingAmount != null) {
            this.setFinancingAmount(financingAmount);
        }
        let contactNo = json["contactNo"];
        if (contactNo != null) {
            this.setContactNo(contactNo);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let financingType = this.getFinancingType();
        if (financingType != null) {
            let convertedFinancingType0 = FinancingType[financingType];
            json["financingType"] = convertedFinancingType0;
        }
        let financingAmount = this.getFinancingAmount();
        if (financingAmount != null) {
            json["financingAmount"] = financingAmount;
        }
        let contactNo = this.getContactNo();
        if (contactNo != null) {
            json["contactNo"] = contactNo;
        }

        if (includeTypeTag) {
            json["__type"] = "FinancingServiceRequest";
        }
    }


    public getTypeId(): string {
    	return "FinancingServiceRequest";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.request.banking.FinancingServiceRequest";
    }
}
