/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { PriceQuote } from "./PriceQuote";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class EntityPriceQuotes extends AbstractSerializable {
  entityId: number | string;
  priceQuotes: Array<PriceQuote>;
  availableCash: number;

  constructor() {
    super();
  }

  public getEntityId(): number | string {
    return this.entityId;
  }

  public setEntityId(entityId: number | string) {
    this.entityId = entityId;
  }

  public getPriceQuotes(): Array<PriceQuote> {
    return this.priceQuotes;
  }

  public setPriceQuotes(priceQuotes: Array<PriceQuote>) {
    this.priceQuotes = priceQuotes;
  }

  public getAvailableCash(): number {
    return this.availableCash;
  }

  public setAvailableCash(availableCash: number) {
    this.availableCash = availableCash;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const entityId = json["entityId"];
    if (entityId != null) {
      this.setEntityId(entityId);
    }
    let priceQuotes = json["priceQuotes"];
    if (priceQuotes != null) {
      const convertedPriceQuotes0 = [];
      priceQuotes = priceQuotes[1];
      for (const i in priceQuotes) {
        const convertedPriceQuotes10 = priceQuotes[i];
        const convertedPriceQuotes1 = new PriceQuote();
        convertedPriceQuotes1.fillFromJson(convertedPriceQuotes10, factory);
        convertedPriceQuotes0.push(convertedPriceQuotes1);
      }
      this.setPriceQuotes(convertedPriceQuotes0);
    }
    const availableCash = json["availableCash"];
    if (availableCash != null) {
      this.setAvailableCash(availableCash);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const entityId = this.getEntityId();
    if (entityId != null) {
      json["entityId"] = String(entityId);
    }
    const priceQuotes = this.getPriceQuotes();
    if (priceQuotes != null) {
      const convertedPriceQuotes0 = [];
      for (const i in priceQuotes) {
        const convertedPriceQuotes10 = priceQuotes[i];
        const convertedPriceQuotes1 = convertedPriceQuotes10.getJson(
          JsonTypeTagType.NONE
        );
        convertedPriceQuotes0.push(convertedPriceQuotes1);
      }
      const convertedPriceQuotesWithType1 = [
        "java.util.ArrayList",
        convertedPriceQuotes0,
      ];
      json["priceQuotes"] = convertedPriceQuotesWithType1;
    }
    const availableCash = this.getAvailableCash();
    if (availableCash != null) {
      json["availableCash"] = availableCash;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.price.EntityPriceQuotes";
  }
}
