/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { PositionDescriptor } from "./PositionDescriptor";
import { TypeFactory } from "../../serialize/TypeFactory";

export class DefaultPositionDescriptor
  extends AbstractSerializable
  implements PositionDescriptor
{
  constructor() {
    super();
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {}

  public fillToJson(json: Object, includeTypeTag = true) {
    if (includeTypeTag) {
      json["__type"] = "DefaultPosition";
    }
  }

  public getTypeId(): string {
    return "DefaultPosition";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.paging.DefaultPositionDescriptor";
  }
}
