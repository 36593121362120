/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractClientServiceAdapter } from "../common/AbstractClientServiceAdapter";
import { ChatService } from "../api/entity/region/ChatService";
import { ChatState } from "../datatype/geolocation/ChatState";
import { DefaultServiceFactory } from "../api/DefaultServiceFactory";
import { DefaultWebServiceFactory } from "../webapi/DefaultWebServiceFactory";
import { LocalChat } from "../datatype/geolocation/LocalChat";
import { Location } from "../datatype/geolocation/Location";
import { PageableData } from "../datatype/paging/PageableData";
import { PageBlockAccessSpec } from "../datatype/paging/PageBlockAccessSpec";
import { PlatformDataContainer } from "../datatype/platform/PlatformDataContainer";
import { WebChatService } from "../webapi/WebChatService";
import { WebSessionManager } from "../common/WebSessionManager";

export class WebChatServiceImpl implements WebChatService {
  private chatService: ChatService;

  public constructor() {
    this.chatService = DefaultServiceFactory.getInstance().getChatService();
  }

  public addLocalChat(
    localChat: LocalChat | null,
    geoLocation: Location
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.chatService.addLocalChat(
        WebSessionManager.getSessionContext(),
        localChat,
        geoLocation,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public addLocalChats(
    localChats: Array<LocalChat> | null,
    geoLocation: Location,
    chatLocation: Location
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.chatService.addLocalChats(
        WebSessionManager.getSessionContext(),
        localChats,
        geoLocation,
        chatLocation,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public addMoreAndGetLocalChats(
    localChats: Array<LocalChat> | null,
    geoLocation: Location,
    chatLocation: Location,
    radius: number | null,
    pageBlockSpec: PageBlockAccessSpec | null
  ): Promise<PlatformDataContainer<PageableData<LocalChat>>> {
    return new Promise((resolve, reject) => {
      this.chatService.addMoreAndGetLocalChats(
        WebSessionManager.getSessionContext(),
        localChats,
        geoLocation,
        chatLocation,
        radius,
        pageBlockSpec,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public addOneAndGetLocalChats(
    localChat: LocalChat | null,
    geoLocation: Location,
    radius: number | null,
    pageBlockSpec: PageBlockAccessSpec | null
  ): Promise<PlatformDataContainer<PageableData<LocalChat>>> {
    return new Promise((resolve, reject) => {
      this.chatService.addOneAndGetLocalChats(
        WebSessionManager.getSessionContext(),
        localChat,
        geoLocation,
        radius,
        pageBlockSpec,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public cancelLocalChat(
    chatId: number | string | null,
    chatLocation: Location
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.chatService.cancelLocalChat(
        WebSessionManager.getSessionContext(),
        chatId,
        chatLocation,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getActiveUserCount(
    chatLocation: Location,
    radius: number | null
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      this.chatService.getActiveUserCount(
        WebSessionManager.getSessionContext(),
        chatLocation,
        radius,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getLocalChats(
    geoLocation: Location | null,
    chatLocation: Location,
    radius: number | null,
    pageBlockSpec: PageBlockAccessSpec | null
  ): Promise<PlatformDataContainer<PageableData<LocalChat>>> {
    return new Promise((resolve, reject) => {
      this.chatService.getLocalChats(
        WebSessionManager.getSessionContext(),
        geoLocation,
        chatLocation,
        radius,
        pageBlockSpec,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getState(
    chatLocation: Location,
    radius: number | null
  ): Promise<ChatState> {
    return new Promise((resolve, reject) => {
      this.chatService.getState(
        WebSessionManager.getSessionContext(),
        chatLocation,
        radius,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public setChatStatus(
    userId: number | string | null,
    noChat: boolean | null
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.chatService.setChatStatus(
        WebSessionManager.getSessionContext(),
        userId,
        noChat,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }
}
