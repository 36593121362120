/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../../serialize/TypeFactory";

export class ServiceRequestClassifierItem extends AbstractSerializable {
  id: number | string;
  personal: boolean;
  position: number;
  imgUrl: string;
  keywords: Array<string>;

  constructor() {
    super();
  }

  public getId(): number | string {
    return this.id;
  }

  public setId(id: number | string) {
    this.id = id;
  }

  public isPersonal(): boolean {
    return this.personal;
  }

  public setPersonal(personal: boolean) {
    this.personal = personal;
  }

  public getPosition(): number {
    return this.position;
  }

  public setPosition(position: number) {
    this.position = position;
  }

  public getImgUrl(): string {
    return this.imgUrl;
  }

  public setImgUrl(imgUrl: string) {
    this.imgUrl = imgUrl;
  }

  public getKeywords(): Array<string> {
    return this.keywords;
  }

  public setKeywords(keywords: Array<string>) {
    this.keywords = keywords;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const id = json["id"];
    if (id != null) {
      this.setId(id);
    }
    const personal = json["personal"];
    if (personal != null) {
      this.setPersonal(personal);
    }
    const position = json["position"];
    if (position != null) {
      this.setPosition(position);
    }
    const imgUrl = json["imgUrl"];
    if (imgUrl != null) {
      this.setImgUrl(imgUrl);
    }
    let keywords = json["keywords"];
    if (keywords != null) {
      const convertedKeywords0 = [];
      keywords = keywords[1];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      this.setKeywords(convertedKeywords0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const id = this.getId();
    if (id != null) {
      json["id"] = String(id);
    }
    const personal = this.isPersonal();
    if (personal != null) {
      json["personal"] = personal;
    }
    const position = this.getPosition();
    if (position != null) {
      json["position"] = position;
    }
    const imgUrl = this.getImgUrl();
    if (imgUrl != null) {
      json["imgUrl"] = imgUrl;
    }
    const keywords = this.getKeywords();
    if (keywords != null) {
      const convertedKeywords0 = [];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      const convertedKeywordsWithType1 = [
        "java.util.ArrayList",
        convertedKeywords0,
      ];
      json["keywords"] = convertedKeywordsWithType1;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.request.ServiceRequestClassifierItem";
  }
}
