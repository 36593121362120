/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../../common/AbstractClientServiceAdapter'
import {BiConsumer} from '../../util/BiConsumer'
import {CompositeSharedInfo} from '../../datatype/share/CompositeSharedInfo'
import {EntityMessageResponse} from '../../message/entity/api/EntityMessageResponse'
import {PageableData} from '../../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../../datatype/paging/PageBlockAccessSpec'
import {PlatformDataContainer} from '../../datatype/platform/PlatformDataContainer'
import {RequestType} from '../../datatype/request/RequestType'
import {ServiceConsumers} from '../../util/ServiceConsumers'
import {ServiceRequest} from '../../common/ServiceRequest'
import {SessionContext} from '../../session/SessionContext'
import {SharedInfoService} from '../../api/share/SharedInfoService'
import {SharedInfoView} from '../../datatype/share/SharedInfoView'
import {SharedInfo} from '../../datatype/share/SharedInfo'


export class SharedInfoServiceProxy extends AbstractClientServiceAdapter implements SharedInfoService {

    public constructor() {
        super("com.gong_wei.service.api.share.SharedInfoService");
    }

    public addOrUpdateSharedInfo(sessionContext: (SessionContext | null), sharedInfo: (SharedInfo | null), resultHandler: BiConsumer<EntityMessageResponse, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.share.SharedInfoService", "addOrUpdateSharedInfo", [sessionContext, sharedInfo]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public cancelSharedInfo(sessionContext: (SessionContext | null), targetEntityId: (number | string | null), infoId: (number | string | null), regionId: (number | string | null), requestType: (RequestType | null), reason: (string | null), resultHandler: BiConsumer<EntityMessageResponse, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.share.SharedInfoService", "cancelSharedInfo", [sessionContext, targetEntityId, infoId, regionId, requestType, reason]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getNotesByTargetId(sessionContext: (SessionContext | null), targetId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<SharedInfoView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.share.SharedInfoService", "getNotesByTargetId", [sessionContext, targetId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getNotesByUserId(sessionContext: (SessionContext | null), targetId: (number | string | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<SharedInfoView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.share.SharedInfoService", "getNotesByUserId", [sessionContext, targetId, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getNotesBydCitzenId(sessionContext: (SessionContext | null), citzenId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<SharedInfoView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.share.SharedInfoService", "getNotesBydCitzenId", [sessionContext, citzenId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getSharedInfo(sessionContext: (SessionContext | null), sharedInfoId: (number | string | null), resultHandler: BiConsumer<PlatformDataContainer<CompositeSharedInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.share.SharedInfoService", "getSharedInfo", [sessionContext, sharedInfoId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

}
