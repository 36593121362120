/**
 * 自动生成， 请不要手工修改！
 */


export enum RegionType {
    COUNTRY,
    PROVINCE,
    MUNICIPALITY,
    CITY,
    PREFECTURE,
    DISTRICT,
    COUNTY,
    TOWN
}
