/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {RegionSpec} from './RegionSpec'
import {RoleType} from '../RoleType'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class RoledRegionSpec extends AbstractSerializable  {
    roleType: RoleType;
    regionSpec: RegionSpec;

    constructor() {
    	super();
    }

    public getRoleType(): RoleType {
    	return this.roleType;
    }

    public setRoleType(roleType: RoleType) {
    	this.roleType = roleType;
    }

    public getRegionSpec(): RegionSpec {
    	return this.regionSpec;
    }

    public setRegionSpec(regionSpec: RegionSpec) {
    	this.regionSpec = regionSpec;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let roleType = json["roleType"];
        if (roleType != null) {
            let convertedRoleType0 = RoleType[<string>roleType];
            this.setRoleType(convertedRoleType0);
        }
        let regionSpec = json["regionSpec"];
        if (regionSpec != null) {
            let convertedRegionSpec0 = Serializer.fillFromJsonObjectWithTypeTag(regionSpec, "__type", factory);
            this.setRegionSpec(convertedRegionSpec0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let roleType = this.getRoleType();
        if (roleType != null) {
            let convertedRoleType0 = RoleType[roleType];
            json["roleType"] = convertedRoleType0;
        }
        let regionSpec = this.getRegionSpec();
        if (regionSpec != null) {
            let convertedRegionSpec0 = regionSpec.getJson(JsonTypeTagType.TYPE);
            json["regionSpec"] = convertedRegionSpec0;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.region.RoledRegionSpec";
    }
}
