import { DefaultWebServiceFactory } from "@/services/webapi/DefaultWebServiceFactory";
import { PageBlockAccessSpec } from "@/services/datatype/paging/PageBlockAccessSpec";
import { UserInfoTabType } from "@/services/datatype/entity/user/UserInfoTabType";
import { BasicReview } from "@/services/datatype/ipsphere/review/BasicReview";
import { SharedInfo } from "@/services/datatype/share/SharedInfo";

const getPersonBriefData = (
  id: string | number,
  time: string | number,
  succinct: boolean
) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEntityService()
    .getEntityInfo(id, time, succinct);
const getPersonHonor = (
  id: number | string | null,
  pageSpec: PageBlockAccessSpec
) =>
  DefaultWebServiceFactory.getInstance()
    .getWebPersonalService()
    .findHonoursByEntityId(id, pageSpec);
const getPersonCase = (
  id: number | string | null,
  tabType: UserInfoTabType | null,
  pageSpec: PageBlockAccessSpec
) =>
  DefaultWebServiceFactory.getInstance()
    .getWebPersonalService()
    .findCoworkInfosByEntityId(id, tabType, pageSpec);
const getHomeHonor = (
  id: number | string | null,
  entityOnly: boolean | null,
  pageSpec: PageBlockAccessSpec
) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseHomeService()
    .findHonoursByEntityId(id, entityOnly, pageSpec);
const addBasicReview = (basicReview: BasicReview) =>
  DefaultWebServiceFactory.getInstance()
    .getWebGeneralReviewService()
    .addBasicReview(basicReview);
const addOrUpdateSharedInfo = (sharedInfo: SharedInfo) =>
  DefaultWebServiceFactory.getInstance()
    .getWebSharedInfoService()
    .addOrUpdateSharedInfo(sharedInfo);
const getEmployeeByEnterpriseId = (
  id: number | string | null,
  pageSpec: PageBlockAccessSpec
) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseHomeService()
    .getEmployeeByEnterpriseId(id, pageSpec);
export default {
  getPersonBriefData,
  getPersonHonor,
  getPersonCase,
  getHomeHonor,
  addBasicReview,
  addOrUpdateSharedInfo,
  getEmployeeByEnterpriseId,
};
