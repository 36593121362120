// @ts-nocheck
import {AbstractSystemSuppliedEntityDesc} from "./AbstractSystemSuppliedEntityDesc";
import {MessageType} from "../../message/MessageContentType";

export class SystemSuppliedEntityDesc extends AbstractSystemSuppliedEntityDesc {
  constructor() {
    super();
  }

    getMessageType(): MessageType {
        // Manual Code Here
    }
}
