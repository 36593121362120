// @ts-nocheck
import { AbstractCustomRequest } from "./AbstractCustomRequest";
import { CoworkType } from "../cowork/CoworkType";
import {EntityInfo} from "../entity/EntityInfo";
import {MessageType} from "../../message/MessageContentType";
import {RequestType} from "./RequestType";
import { TextSource } from "../../message/entity/api/TextSource";

export class CustomRequest<
  T extends TextSource
> extends AbstractCustomRequest<T> {
  constructor() {
    super();
  }

  /**
   * 必选数据： 请求对象类型
   */
  public withTargetType(targetType: CoworkType): CustomRequest<T> {
    this.targetType = targetType;
    return this;
  }

  /**
   * 必选数据： 请求对象ID
   */
  public withTargetId(targetId: number | string): CustomRequest<T> {
    this.targetId = targetId;
    return this;
  }

  /**
   * 必选数据： 请求对象所属社区D
   */
  public withTargetEntityInfo(targetEntityInfo: EntityInfo): CustomRequest<T> {
    this.targetEntityInfo = targetEntityInfo;
    return this;
  }

  /**
   * 必选数据： 请求者信息
   */
  public withRequestorInfo(requestorInfo: EntityInfo): CustomRequest<T> {
    this.requestorInfo = requestorInfo;
    return this;
  }

  /**
   * 必选数据： 请求类型
   */
  public withRequestType(requestType: RequestType): CustomRequest<T> {
    this.requestType = requestType;
    return this;
  }
}
