/**
 * 自动生成， 请不要手工修改！
 */

export enum AnnounceType {
  PUBLIC_NOTICE,
  ADMIN_NOTICE,
  CITIZEN_NOTICE,
  COMMERCIAL,
  PRICE,
  DOCUEMENT,
}
