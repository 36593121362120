/**
 * Created by Zhonghua on 27/11/2016.
 */
import { EventRequest } from "../common/EventRequest";
import { ServiceCancelRequest } from "../common/ServiceCancelRequest";
import { ServiceError } from "../common/ServiceError";
import { ServiceRequest } from "../common/ServiceRequest";
import { ServiceResponse } from "../common/ServiceResponse";
import { DefaultSessionContext } from "../session/DefaultSessionContext";
import { MockSessionContext } from "../session/MockSessionContext";
import { Map } from "../util/Map";
import { Pair } from "../util/Pair";

export class ManualClassFactory {
  public static createClassMap(): Map<string, () => any> {
    const classMap = new Map<string, () => any>();

    // 会话
    classMap.set(
      "com.gong_wei.common.session.MockSessionContext",
      () => new MockSessionContext()
    );
    classMap.set(
      "com.gong_wei.common.session.DefaultSessionContext",
      () => new DefaultSessionContext()
    );

    // 请求和反应
    classMap.set(
      "com.gong_wei.common.message.service.ServiceRequest",
      () => new ServiceRequest()
    );
    classMap.set(
      "com.gong_wei.common.message.service.ServiceResponse",
      () => new ServiceResponse()
    );
    classMap.set(
      "com.gong_wei.common.error.ServiceError",
      () => new ServiceError()
    );
    classMap.set(
      "com.gong_wei.common.message.service.EventRequest",
      () => new EventRequest()
    );
    classMap.set(
      "com.gong_wei.common.message.service.ServiceCancelRequest",
      () => new ServiceCancelRequest()
    );
    classMap.set("com.gong_wei.common.util.Pair", () => new Pair());
    return classMap;
  }
}
