/**
 * 自动生成， 请不要手工修改！
 */


export enum SectorTopicType {
    SUBGROUP,
    ENTERPRISE_MEMBER,
    USER_MEMBER,
    PROD_EXPO,
    POLICY,
    KNOWLEDGE,
    KNOWLEDGE_AND_REVIEW,
    JOBS,
    SERVICE,
    FORUM,
    COOPS,
    ENTITY,
    MY_ENTERPRISE,
    MY_ENTERPRISES,
    SUBSCRIBED_ENTS,
    ENT_RELATIONS,
    ENT_SERVICES,
    ENT_FORUM,
    ENT_POLICY,
    MY_ASSOCIATION,
    MY_ASSOCIATIONS,
    ASSOCIATIONS,
    ASSOC_BRANDS,
    ASSOC_ADVICES,
    SERVICE_ADVICES,
    SERVICE_STANDARDS,
    ASSOC_HONOURS,
    QLL_MEMBER,
    QLL_SERVICE,
    ENT_ADS
}
