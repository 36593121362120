export class OSSFederationToken {
  /**
   * Creates a new instance of OSSFederationToken
   *
   * @param tempAK        AccessKeyId returned from STS
   * @param tempSK        AccessKeySecret returned from STS
   * @param securityToken SecurityToken returned from STS
   * @param expiration    The expiration time in seconds from STS, in the Unix Epoch format.
   */
  constructor(
    public tempAK: string,
    public tempSK: string,
    public securityToken: string,
    public expiration: number,
    public pkgName: string
  ) {}

  public isValid(): boolean {
    const nowTime = Date.now();
    return this.expiration > nowTime / 1000 + 5 * 60;
  }

  public static getExpirationSecondsFromGMTFormat(
    expirationInGMTFormat: string
  ): number {
    const date = new Date(expirationInGMTFormat);
    const expiration = date.getTime() / 1000;
    return expiration;
  }
}
