/**
 * 自动生成， 请不要手工修改！
 */

export enum Ordering {
  NONE,
  TEMPORAL,
  TEMPORAL_ASC,
  POPULARITY,
  DISTANCE,
  SCORE,
  COST,
  SIZE,
}
