/**
 * 自动生成， 请不要手工修改！
 */


export enum TopicType {
    PRODUCT,
    GOODS,
    SERVICE,
    VOTE,
    TEMP_JOB,
    HELP,
    GLOBAL,
    PRICE,
    PRAISE,
    RECOMMEND,
    OPINION,
    LOCAL,
    APPEAL,
    SUGGESTION_PK,
    SUGGESTION_GENERAL,
    CHARITY,
    SCHOOL,
    COLLEGE,
    BRANCH_SCHOOL,
    TEACHER,
    SCHOOL_YEAR,
    ALUMNI_TOPIC,
    ALUMNI_DECADE,
    ALUMNI_SELECTION
}
