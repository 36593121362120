/**
 * 自动生成， 请不要手工修改！
 */


export enum RequestStatusType {
    TO_ACCEPT,
    MATCH_WORKER,
    ACCEPTED_WORKER,
    PEND_FOR_REVIEW,
    IN_REVIEW,
    REJECTED,
    ACCEPTED,
    PENDING_ORDER,
    ACKED,
    ACTIVITY_JOINED,
    CANCELLED,
    PROCESS_FAILED,
    ACK_COMPLETE,
    COMPLETED,
    DELEGATED,
    DELEGATE_REVOKED
}
