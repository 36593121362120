/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../serialize/TypeFactory'


export class ProductAttribute extends AbstractSerializable {
    id: (number | string);
    position: number;
    name: string;
    value: string;

    constructor() {
        super();
    }

    public getId(): (number | string) {
        return this.id;
    }

    public setId(id: (number | string)) {
        this.id = id;
    }

    public getPosition(): number {
        return this.position;
    }

    public setPosition(position: number) {
        this.position = position;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string) {
        this.name = name;
    }

    public getValue(): string {
        return this.value;
    }

    public setValue(value: string) {
        this.value = value;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let id = json["id"];
        if (id != null) {
            this.setId(id);
        }
        let position = json["position"];
        if (position != null) {
            this.setPosition(position);
        }
        let name = json["name"];
        if (name != null) {
            this.setName(name);
        }
        let value = json["value"];
        if (value != null) {
            this.setValue(value);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let id = this.getId();
        if (id != null) {
            json["id"] = String(id);
        }
        let position = this.getPosition();
        if (position != null) {
            json["position"] = position;
        }
        let name = this.getName();
        if (name != null) {
            json["name"] = name;
        }
        let value = this.getValue();
        if (value != null) {
            json["value"] = value;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.product.ProductAttribute";
    }
}
