/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { EntityChannelType } from "../../entity/channel/EntityChannelType";
import { Filter } from "../Filter";
import {JsonTypeTagType} from "../../../serialize/JsonTypeTagType";
import {MessageType} from "../../../message/MessageContentType";
import {MessageCorrelationType} from "./MessageCorrelationType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class MessageFilter extends AbstractSerializable implements Filter {
  correlatedMessageId: number | string;
  correlationType: MessageCorrelationType;
  contentType: MessageType
  channelType: EntityChannelType;
  referenceKey: string;
  senderId: number | string;
  mediumEntityId: number | string;
  serviceRequestId: number | string;
  startTime: number | string;
  endTime: number | string;

  constructor() {
    super();
  }

  public getCorrelatedMessageId(): number | string {
    return this.correlatedMessageId;
  }

  public setCorrelatedMessageId(correlatedMessageId: number | string) {
    this.correlatedMessageId = correlatedMessageId;
  }

  public getCorrelationType(): MessageCorrelationType {
    return this.correlationType;
  }

  public setCorrelationType(correlationType: MessageCorrelationType) {
    this.correlationType = correlationType;
  }

  public getContentType(): MessageType {
    return this.contentType;
  }

  public setContentType(contentType: MessageType) {
    this.contentType = contentType;
  }

  public getChannelType(): EntityChannelType {
    return this.channelType;
  }

  public setChannelType(channelType: EntityChannelType) {
    this.channelType = channelType;
  }

  public getReferenceKey(): string {
    return this.referenceKey;
  }

  public setReferenceKey(referenceKey: string) {
    this.referenceKey = referenceKey;
  }

  public getSenderId(): number | string {
    return this.senderId;
  }

  public setSenderId(senderId: number | string) {
    this.senderId = senderId;
  }

  public getMediumEntityId(): number | string {
    return this.mediumEntityId;
  }

  public setMediumEntityId(mediumEntityId: number | string) {
    this.mediumEntityId = mediumEntityId;
  }

  public getServiceRequestId(): number | string {
    return this.serviceRequestId;
  }

  public setServiceRequestId(serviceRequestId: number | string) {
    this.serviceRequestId = serviceRequestId;
  }

  public getStartTime(): number | string {
    return this.startTime;
  }

  public setStartTime(startTime: number | string) {
    this.startTime = startTime;
  }

  public getEndTime(): number | string {
    return this.endTime;
  }

  public setEndTime(endTime: number | string) {
    this.endTime = endTime;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const correlatedMessageId = json["correlatedMessageId"];
    if (correlatedMessageId != null) {
      this.setCorrelatedMessageId(correlatedMessageId);
    }
    const correlationType = json["correlationType"];
    if (correlationType != null) {
      const convertedCorrelationType0 =
        MessageCorrelationType[<string>correlationType];
      this.setCorrelationType(convertedCorrelationType0);
    }
    const contentType = json["contentType"];
    if (contentType != null) {
      const convertedContentType0 = MessageType[<string>contentType];
      this.setContentType(convertedContentType0);
    }
    const channelType = json["channelType"];
    if (channelType != null) {
      const convertedChannelType0 = EntityChannelType[<string>channelType];
      this.setChannelType(convertedChannelType0);
    }
    const referenceKey = json["referenceKey"];
    if (referenceKey != null) {
      this.setReferenceKey(referenceKey);
    }
    const senderId = json["senderId"];
    if (senderId != null) {
      this.setSenderId(senderId);
    }
    const mediumEntityId = json["mediumEntityId"];
    if (mediumEntityId != null) {
      this.setMediumEntityId(mediumEntityId);
    }
    const serviceRequestId = json["serviceRequestId"];
    if (serviceRequestId != null) {
      this.setServiceRequestId(serviceRequestId);
    }
    const startTime = json["startTime"];
    if (startTime != null) {
      this.setStartTime(startTime);
    }
    const endTime = json["endTime"];
    if (endTime != null) {
      this.setEndTime(endTime);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const correlatedMessageId = this.getCorrelatedMessageId();
    if (correlatedMessageId != null) {
      json["correlatedMessageId"] = String(correlatedMessageId);
    }
    const correlationType = this.getCorrelationType();
    if (correlationType != null) {
      const convertedCorrelationType0 = MessageCorrelationType[correlationType];
      json["correlationType"] = convertedCorrelationType0;
    }
    const contentType = this.getContentType();
    if (contentType != null) {
      const convertedContentType0 = MessageType[contentType];
      json["contentType"] = convertedContentType0;
    }
    const channelType = this.getChannelType();
    if (channelType != null) {
      const convertedChannelType0 = EntityChannelType[channelType];
      json["channelType"] = convertedChannelType0;
    }
    const referenceKey = this.getReferenceKey();
    if (referenceKey != null) {
      json["referenceKey"] = referenceKey;
    }
    const senderId = this.getSenderId();
    if (senderId != null) {
      json["senderId"] = String(senderId);
    }
    const mediumEntityId = this.getMediumEntityId();
    if (mediumEntityId != null) {
      json["mediumEntityId"] = String(mediumEntityId);
    }
    const serviceRequestId = this.getServiceRequestId();
    if (serviceRequestId != null) {
      json["serviceRequestId"] = String(serviceRequestId);
    }
    const startTime = this.getStartTime();
    if (startTime != null) {
      json["startTime"] = String(startTime);
    }
    const endTime = this.getEndTime();
    if (endTime != null) {
      json["endTime"] = String(endTime);
    }

    if (includeTypeTag) {
      json["__type"] = "MessageCorrelationFilter";
    }
  }

  public getTypeId(): string {
    return "MessageCorrelationFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.message.MessageFilter";
  }
}
