// @ts-nocheck
import { AbstractCompositeSharedInfo } from "./AbstractCompositeSharedInfo";

export class CompositeSharedInfo extends AbstractCompositeSharedInfo {
  constructor() {
    super();
  }

  getVisits(): number {
    // Manual Code Here
  }
}
