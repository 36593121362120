/**
 * 自动生成， 请不要手工修改！
 */


export enum ServiceUseType {
    CONSULT,
    ACTIVITY,
    PLATFORM_INTERACT,
    PRIVILEDGE,
    CUSTOM_OFFLINE,
    MISC
}
