/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class AppVersionInfo extends AbstractSerializable {
  appVersion: string;
  appSysType: string;
  clientType: string;
  appVersionCode: number;
  appSize: number | string;
  publishDescription: string;
  publishTime: number | string;
  appUrl: string;
  currentAppVersionCompatible: boolean;
  maxClientWebsocketIdleSeconds: number;
  appAgreementVersion: string;

  constructor() {
    super();
  }

  public getAppVersion(): string {
    return this.appVersion;
  }

  public setAppVersion(appVersion: string) {
    this.appVersion = appVersion;
  }

  public getAppSysType(): string {
    return this.appSysType;
  }

  public setAppSysType(appSysType: string) {
    this.appSysType = appSysType;
  }

  public getClientType(): string {
    return this.clientType;
  }

  public setClientType(clientType: string) {
    this.clientType = clientType;
  }

  public getAppVersionCode(): number {
    return this.appVersionCode;
  }

  public setAppVersionCode(appVersionCode: number) {
    this.appVersionCode = appVersionCode;
  }

  public getAppSize(): number | string {
    return this.appSize;
  }

  public setAppSize(appSize: number | string) {
    this.appSize = appSize;
  }

  public getPublishDescription(): string {
    return this.publishDescription;
  }

  public setPublishDescription(publishDescription: string) {
    this.publishDescription = publishDescription;
  }

  public getPublishTime(): number | string {
    return this.publishTime;
  }

  public setPublishTime(publishTime: number | string) {
    this.publishTime = publishTime;
  }

  public getAppUrl(): string {
    return this.appUrl;
  }

  public setAppUrl(appUrl: string) {
    this.appUrl = appUrl;
  }

  public isCurrentAppVersionCompatible(): boolean {
    return this.currentAppVersionCompatible;
  }

  public setCurrentAppVersionCompatible(currentAppVersionCompatible: boolean) {
    this.currentAppVersionCompatible = currentAppVersionCompatible;
  }

  public getMaxClientWebsocketIdleSeconds(): number {
    return this.maxClientWebsocketIdleSeconds;
  }

  public setMaxClientWebsocketIdleSeconds(
    maxClientWebsocketIdleSeconds: number
  ) {
    this.maxClientWebsocketIdleSeconds = maxClientWebsocketIdleSeconds;
  }

  public getAppAgreementVersion(): string {
    return this.appAgreementVersion;
  }

  public setAppAgreementVersion(appAgreementVersion: string) {
    this.appAgreementVersion = appAgreementVersion;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const appVersion = json["appVersion"];
    if (appVersion != null) {
      this.setAppVersion(appVersion);
    }
    const appSysType = json["appSysType"];
    if (appSysType != null) {
      this.setAppSysType(appSysType);
    }
    const clientType = json["clientType"];
    if (clientType != null) {
      this.setClientType(clientType);
    }
    const appVersionCode = json["appVersionCode"];
    if (appVersionCode != null) {
      this.setAppVersionCode(appVersionCode);
    }
    const appSize = json["appSize"];
    if (appSize != null) {
      this.setAppSize(appSize);
    }
    const publishDescription = json["publishDescription"];
    if (publishDescription != null) {
      this.setPublishDescription(publishDescription);
    }
    const publishTime = json["publishTime"];
    if (publishTime != null) {
      this.setPublishTime(publishTime);
    }
    const appUrl = json["appUrl"];
    if (appUrl != null) {
      this.setAppUrl(appUrl);
    }
    const currentAppVersionCompatible = json["currentAppVersionCompatible"];
    if (currentAppVersionCompatible != null) {
      this.setCurrentAppVersionCompatible(currentAppVersionCompatible);
    }
    const maxClientWebsocketIdleSeconds = json["maxClientWebsocketIdleSeconds"];
    if (maxClientWebsocketIdleSeconds != null) {
      this.setMaxClientWebsocketIdleSeconds(maxClientWebsocketIdleSeconds);
    }
    const appAgreementVersion = json["appAgreementVersion"];
    if (appAgreementVersion != null) {
      this.setAppAgreementVersion(appAgreementVersion);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const appVersion = this.getAppVersion();
    if (appVersion != null) {
      json["appVersion"] = appVersion;
    }
    const appSysType = this.getAppSysType();
    if (appSysType != null) {
      json["appSysType"] = appSysType;
    }
    const clientType = this.getClientType();
    if (clientType != null) {
      json["clientType"] = clientType;
    }
    const appVersionCode = this.getAppVersionCode();
    if (appVersionCode != null) {
      json["appVersionCode"] = appVersionCode;
    }
    const appSize = this.getAppSize();
    if (appSize != null) {
      json["appSize"] = String(appSize);
    }
    const publishDescription = this.getPublishDescription();
    if (publishDescription != null) {
      json["publishDescription"] = publishDescription;
    }
    const publishTime = this.getPublishTime();
    if (publishTime != null) {
      json["publishTime"] = String(publishTime);
    }
    const appUrl = this.getAppUrl();
    if (appUrl != null) {
      json["appUrl"] = appUrl;
    }
    const currentAppVersionCompatible = this.isCurrentAppVersionCompatible();
    if (currentAppVersionCompatible != null) {
      json["currentAppVersionCompatible"] = currentAppVersionCompatible;
    }
    const maxClientWebsocketIdleSeconds =
      this.getMaxClientWebsocketIdleSeconds();
    if (maxClientWebsocketIdleSeconds != null) {
      json["maxClientWebsocketIdleSeconds"] = maxClientWebsocketIdleSeconds;
    }
    const appAgreementVersion = this.getAppAgreementVersion();
    if (appAgreementVersion != null) {
      json["appAgreementVersion"] = appAgreementVersion;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.platform.AppVersionInfo";
  }
}
