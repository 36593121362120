/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { ActivityType } from "../../activity/ActivityType";
import { EntityType } from "../../entity/EntityType";
import { Filter } from "../Filter";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Location } from "../../geolocation/Location";
import { ReviewType } from "../../ipsphere/review/ReviewType";
import { TypeFactory } from "../../../serialize/TypeFactory";
import { UserAdType } from "../../userad/UserAdType";

export class CoworkFilter extends AbstractSerializable implements Filter {
  location: Location;
  distance: number;
  regionId: number | string;
  targetEntityId: number | string;
  entityType: EntityType;
  keywords: Array<string>;
  activityType: ActivityType;
  userAdType: UserAdType;
  reviewType: ReviewType;

  constructor() {
    super();
  }

  public getLocation(): Location {
    return this.location;
  }

  public setLocation(location: Location) {
    this.location = location;
  }

  public getDistance(): number {
    return this.distance;
  }

  public setDistance(distance: number) {
    this.distance = distance;
  }

  public getRegionId(): number | string {
    return this.regionId;
  }

  public setRegionId(regionId: number | string) {
    this.regionId = regionId;
  }

  public getTargetEntityId(): number | string {
    return this.targetEntityId;
  }

  public setTargetEntityId(targetEntityId: number | string) {
    this.targetEntityId = targetEntityId;
  }

  public getEntityType(): EntityType {
    return this.entityType;
  }

  public setEntityType(entityType: EntityType) {
    this.entityType = entityType;
  }

  public getKeywords(): Array<string> {
    return this.keywords;
  }

  public setKeywords(keywords: Array<string>) {
    this.keywords = keywords;
  }

  public getActivityType(): ActivityType {
    return this.activityType;
  }

  public setActivityType(activityType: ActivityType) {
    this.activityType = activityType;
  }

  public getUserAdType(): UserAdType {
    return this.userAdType;
  }

  public setUserAdType(userAdType: UserAdType) {
    this.userAdType = userAdType;
  }

  public getReviewType(): ReviewType {
    return this.reviewType;
  }

  public setReviewType(reviewType: ReviewType) {
    this.reviewType = reviewType;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const location = json["location"];
    if (location != null) {
      const convertedLocation0 = new Location();
      convertedLocation0.fillFromJson(location, factory);
      this.setLocation(convertedLocation0);
    }
    const distance = json["distance"];
    if (distance != null) {
      this.setDistance(distance);
    }
    const regionId = json["regionId"];
    if (regionId != null) {
      this.setRegionId(regionId);
    }
    const targetEntityId = json["targetEntityId"];
    if (targetEntityId != null) {
      this.setTargetEntityId(targetEntityId);
    }
    const entityType = json["entityType"];
    if (entityType != null) {
      const convertedEntityType0 = EntityType[<string>entityType];
      this.setEntityType(convertedEntityType0);
    }
    let keywords = json["keywords"];
    if (keywords != null) {
      const convertedKeywords0 = [];
      keywords = keywords[1];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      this.setKeywords(convertedKeywords0);
    }
    const activityType = json["activityType"];
    if (activityType != null) {
      const convertedActivityType0 = ActivityType[<string>activityType];
      this.setActivityType(convertedActivityType0);
    }
    const userAdType = json["userAdType"];
    if (userAdType != null) {
      const convertedUserAdType0 = UserAdType[<string>userAdType];
      this.setUserAdType(convertedUserAdType0);
    }
    const reviewType = json["reviewType"];
    if (reviewType != null) {
      const convertedReviewType0 = ReviewType[<string>reviewType];
      this.setReviewType(convertedReviewType0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const location = this.getLocation();
    if (location != null) {
      const convertedLocation0 = location.getJson(JsonTypeTagType.NONE);
      json["location"] = convertedLocation0;
    }
    const distance = this.getDistance();
    if (distance != null) {
      json["distance"] = distance;
    }
    const regionId = this.getRegionId();
    if (regionId != null) {
      json["regionId"] = String(regionId);
    }
    const targetEntityId = this.getTargetEntityId();
    if (targetEntityId != null) {
      json["targetEntityId"] = String(targetEntityId);
    }
    const entityType = this.getEntityType();
    if (entityType != null) {
      const convertedEntityType0 = EntityType[entityType];
      json["entityType"] = convertedEntityType0;
    }
    const keywords = this.getKeywords();
    if (keywords != null) {
      const convertedKeywords0 = [];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      const convertedKeywordsWithType1 = [
        "java.util.ArrayList",
        convertedKeywords0,
      ];
      json["keywords"] = convertedKeywordsWithType1;
    }
    const activityType = this.getActivityType();
    if (activityType != null) {
      const convertedActivityType0 = ActivityType[activityType];
      json["activityType"] = convertedActivityType0;
    }
    const userAdType = this.getUserAdType();
    if (userAdType != null) {
      const convertedUserAdType0 = UserAdType[userAdType];
      json["userAdType"] = convertedUserAdType0;
    }
    const reviewType = this.getReviewType();
    if (reviewType != null) {
      const convertedReviewType0 = ReviewType[reviewType];
      json["reviewType"] = convertedReviewType0;
    }

    if (includeTypeTag) {
      json["__type"] = "CoworkFilter";
    }
  }

  public getTypeId(): string {
    return "CoworkFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.cowork.CoworkFilter";
  }
}
