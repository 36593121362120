/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractClientServiceAdapter } from "../../../common/AbstractClientServiceAdapter";
import { BiConsumer } from "../../../util/BiConsumer";
import { ChatService } from "../../../api/entity/region/ChatService";
import { ChatState } from "../../../datatype/geolocation/ChatState";
import { LocalChat } from "../../../datatype/geolocation/LocalChat";
import { Location } from "../../../datatype/geolocation/Location";
import { PageableData } from "../../../datatype/paging/PageableData";
import { PageBlockAccessSpec } from "../../../datatype/paging/PageBlockAccessSpec";
import { PlatformDataContainer } from "../../../datatype/platform/PlatformDataContainer";
import { ServiceConsumers } from "../../../util/ServiceConsumers";
import { ServiceRequest } from "../../../common/ServiceRequest";
import { SessionContext } from "../../../session/SessionContext";

export class ChatServiceProxy
  extends AbstractClientServiceAdapter
  implements ChatService
{
  public constructor() {
    super("com.gong_wei.service.api.entity.region.ChatService");
  }

  public addLocalChat(
    sessionContext: SessionContext | null,
    localChat: LocalChat | null,
    geoLocation: Location | null,
    resultHandler: BiConsumer<boolean, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.region.ChatService",
      "addLocalChat",
      [sessionContext, localChat, geoLocation]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public addLocalChats(
    sessionContext: SessionContext | null,
    localChats: Array<LocalChat> | null,
    geoLocation: Location | null,
    chatLocation: Location | null,
    resultHandler: BiConsumer<boolean, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.region.ChatService",
      "addLocalChats",
      [sessionContext, localChats, geoLocation, chatLocation]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public addMoreAndGetLocalChats(
    sessionContext: SessionContext | null,
    localChats: Array<LocalChat> | null,
    geoLocation: Location | null,
    chatLocation: Location | null,
    radius: number | null,
    pageBlockSpec: PageBlockAccessSpec | null,
    resultHandler: BiConsumer<
      PlatformDataContainer<PageableData<LocalChat>>,
      any
    >
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.region.ChatService",
      "addMoreAndGetLocalChats",
      [
        sessionContext,
        localChats,
        geoLocation,
        chatLocation,
        radius,
        pageBlockSpec,
      ]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public addOneAndGetLocalChats(
    sessionContext: SessionContext | null,
    localChat: LocalChat | null,
    geoLocation: Location | null,
    radius: number | null,
    pageBlockSpec: PageBlockAccessSpec | null,
    resultHandler: BiConsumer<
      PlatformDataContainer<PageableData<LocalChat>>,
      any
    >
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.region.ChatService",
      "addOneAndGetLocalChats",
      [sessionContext, localChat, geoLocation, radius, pageBlockSpec]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public cancelLocalChat(
    sessionContext: SessionContext | null,
    chatId: number | string | null,
    chatLocation: Location | null,
    resultHandler: BiConsumer<boolean, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.region.ChatService",
      "cancelLocalChat",
      [sessionContext, chatId, chatLocation]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getActiveUserCount(
    sessionContext: SessionContext | null,
    chatLocation: Location | null,
    radius: number | null,
    resultHandler: BiConsumer<number, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.region.ChatService",
      "getActiveUserCount",
      [sessionContext, chatLocation, radius]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getLocalChats(
    sessionContext: SessionContext | null,
    geoLocation: Location | null,
    chatLocation: Location | null,
    radius: number | null,
    pageBlockSpec: PageBlockAccessSpec | null,
    resultHandler: BiConsumer<
      PlatformDataContainer<PageableData<LocalChat>>,
      any
    >
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.region.ChatService",
      "getLocalChats",
      [sessionContext, geoLocation, chatLocation, radius, pageBlockSpec]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getState(
    sessionContext: SessionContext | null,
    chatLocation: Location | null,
    radius: number | null,
    resultHandler: BiConsumer<ChatState, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.region.ChatService",
      "getState",
      [sessionContext, chatLocation, radius]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public setChatStatus(
    sessionContext: SessionContext | null,
    userId: number | string | null,
    noChat: boolean | null,
    resultHandler: BiConsumer<boolean, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.region.ChatService",
      "setChatStatus",
      [sessionContext, userId, noChat]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }
}
