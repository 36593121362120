/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {BasicReview} from '../datatype/ipsphere/review/BasicReview'
import {CompositeReviewInfo} from '../datatype/ipsphere/review/CompositeReviewInfo'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EntityMessageResponse} from '../message/entity/api/EntityMessageResponse'
import {GeneralReviewService} from '../api/review/GeneralReviewService'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {PlatformDataContainer} from '../datatype/platform/PlatformDataContainer'
import {RequestType} from '../datatype/request/RequestType'
import {WebGeneralReviewService} from '../webapi/WebGeneralReviewService'
import {WebSessionManager} from '../common/WebSessionManager'


export class WebGeneralReviewServiceImpl implements WebGeneralReviewService {

    private generalReviewService: GeneralReviewService;

    public constructor() {
        this.generalReviewService = DefaultServiceFactory.getInstance().getGeneralReviewService();
    }

    public addBasicReview(basicReview: BasicReview): Promise<EntityMessageResponse> {
        return new Promise((resolve, reject) => {
        	this.generalReviewService.addBasicReview(WebSessionManager.getSessionContext(),
        	basicReview, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public cancelReview(targetEntityId: (number | string | null), reviewId: (number | string | null), regionId: (number | string | null), requestType: (RequestType | null), reason: (string | null)): Promise<EntityMessageResponse> {
        return new Promise((resolve, reject) => {
        	this.generalReviewService.cancelReview(WebSessionManager.getSessionContext(),
        	targetEntityId, reviewId, regionId, requestType, reason, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getReviewDetails(pageBlockSpec: (PageBlockAccessSpec | null), reviewId: (number | string | null)): Promise<PlatformDataContainer<CompositeReviewInfo>> {
        return new Promise((resolve, reject) => {
        	this.generalReviewService.getReviewDetails(WebSessionManager.getSessionContext(),
        	pageBlockSpec, reviewId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

}
