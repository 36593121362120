/**
 * 自动生成， 请不要手工修改！
 */

export enum CoworkOp {
  CREATE,
  UPDATE,
  CANCEL,
  JOIN,
  QUIT,
  ACCEPT_WORKER,
  ACCEPT_REQUEST,
  REJECT_REQUEST,
  COMPLETE,
  RENEW,
  CONFIRM,
  TELEPHONE,
}
