/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkOp } from "./CoworkOp";
import { CoworkType } from "./CoworkType";
import { GeoHash } from "../geolocation/GeoHash";
import {JsonTypeTagType} from "../../serialize/JsonTypeTagType";
import {MessageType} from "../../message/MessageContentType";
import {MessageContent} from "../../message/MessageContent";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractCoworkOperation
  extends AbstractSerializable
  implements MessageContent
{
  coworkObjectType: CoworkType;
  coworkObjectId: number | string;
  geohash: GeoHash;
  coworkOperationType: CoworkOp;
  initiatorId: number | string;
  requestTime: number | string;
  description: string;
  targetEntityId: number | string;
  messageContentType: MessageType;

  constructor() {
    super();
  }

  public getCoworkObjectType(): CoworkType {
    return this.coworkObjectType;
  }

  /**
   * 必选数据
   */
  public setCoworkObjectType(coworkObjectType: CoworkType) {
    this.coworkObjectType = coworkObjectType;
  }

  public getCoworkObjectId(): number | string {
    return this.coworkObjectId;
  }

  public setCoworkObjectId(coworkObjectId: number | string) {
    this.coworkObjectId = coworkObjectId;
  }

  public getGeohash(): GeoHash {
    return this.geohash;
  }

  public setGeohash(geohash: GeoHash) {
    this.geohash = geohash;
  }

  public getCoworkOperationType(): CoworkOp {
    return this.coworkOperationType;
  }

  /**
   * 必选数据
   */
  public setCoworkOperationType(coworkOperationType: CoworkOp) {
    this.coworkOperationType = coworkOperationType;
  }

  public getInitiatorId(): number | string {
    return this.initiatorId;
  }

  public setInitiatorId(initiatorId: number | string) {
    this.initiatorId = initiatorId;
  }

  public getRequestTime(): number | string {
    return this.requestTime;
  }

  public setRequestTime(requestTime: number | string) {
    this.requestTime = requestTime;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getTargetEntityId(): number | string {
    return this.targetEntityId;
  }

  public setTargetEntityId(targetEntityId: number | string) {
    this.targetEntityId = targetEntityId;
  }

  public getMessageContentType(): MessageType {
    return this.messageContentType;
  }

  public setMessageContentType(messageType: MessageType) {
    this.messageContentType = messageType;
  }

  abstract getMessageType(): MessageType;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const coworkObjectType = json["coworkObjectType"];
    if (coworkObjectType != null) {
      const convertedCoworkObjectType0 = CoworkType[<string>coworkObjectType];
      this.setCoworkObjectType(convertedCoworkObjectType0);
    }
    const coworkObjectId = json["coworkObjectId"];
    if (coworkObjectId != null) {
      this.setCoworkObjectId(coworkObjectId);
    }
    const geohash = json["geohash"];
    if (geohash != null) {
      const convertedGeohash0 = new GeoHash();
      convertedGeohash0.fillFromJson(geohash, factory);
      this.setGeohash(convertedGeohash0);
    }
    const coworkOperationType = json["coworkOperationType"];
    if (coworkOperationType != null) {
      const convertedCoworkOperationType0 =
        CoworkOp[<string>coworkOperationType];
      this.setCoworkOperationType(convertedCoworkOperationType0);
    }
    const initiatorId = json["initiatorId"];
    if (initiatorId != null) {
      this.setInitiatorId(initiatorId);
    }
    const requestTime = json["requestTime"];
    if (requestTime != null) {
      this.setRequestTime(requestTime);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    const targetEntityId = json["targetEntityId"];
    if (targetEntityId != null) {
      this.setTargetEntityId(targetEntityId);
    }
    const messageContentType = json["messageContentType"];
    if (messageContentType != null) {
      const convertedMessageType0 =
          MessageType[<string>messageContentType];
      this.setMessageContentType(convertedMessageType0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const coworkObjectType = this.getCoworkObjectType();
    if (coworkObjectType != null) {
      const convertedCoworkObjectType0 = CoworkType[coworkObjectType];
      json["coworkObjectType"] = convertedCoworkObjectType0;
    }
    const coworkObjectId = this.getCoworkObjectId();
    if (coworkObjectId != null) {
      json["coworkObjectId"] = String(coworkObjectId);
    }
    const geohash = this.getGeohash();
    if (geohash != null) {
      const convertedGeohash0 = geohash.getJson(JsonTypeTagType.NONE);
      json["geohash"] = convertedGeohash0;
    }
    const coworkOperationType = this.getCoworkOperationType();
    if (coworkOperationType != null) {
      const convertedCoworkOperationType0 = CoworkOp[coworkOperationType];
      json["coworkOperationType"] = convertedCoworkOperationType0;
    }
    const initiatorId = this.getInitiatorId();
    if (initiatorId != null) {
      json["initiatorId"] = String(initiatorId);
    }
    const requestTime = this.getRequestTime();
    if (requestTime != null) {
      json["requestTime"] = String(requestTime);
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const targetEntityId = this.getTargetEntityId();
    if (targetEntityId != null) {
      json["targetEntityId"] = String(targetEntityId);
    }
    const messageContentType = this.getMessageType();
    if (messageContentType != null) {
      const convertedMessageType0 =
          MessageType[messageContentType];
      json["messageContentType"] = convertedMessageType0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.CoworkOperation";
  }
}
