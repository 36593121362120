// @ts-nocheck
import {AbstractBasicReview} from './AbstractBasicReview'
import {EntityInfo} from '../../entity/EntityInfo'
import {KeywordEntity} from '../../keyword/KeywordEntity'
import {MessageContentType} from '../../../message/MessageContentType'
import {QllCountry} from '../../international/QllCountry'
import {ReviewType} from './ReviewType'


export class BasicReview extends AbstractBasicReview  {
    constructor() {
    	super();
    }

    getTargetEntityInfo(): EntityInfo {
      // Manual Code Here
    }
    getCountry(): QllCountry {
      // Manual Code Here
    }
    getEntity(arg0: (string | null)): KeywordEntity {
      // Manual Code Here
    }
    getInitiatorId(): (number | string) {
      // Manual Code Here
    }
    getReferencedEntityId(): (number | string) {
      // Manual Code Here
    }
    getInitiatorImageUrl(): string {
      // Manual Code Here
    }
    getObjectMainImageUrl(): string {
      // Manual Code Here
    }
    getMessageType(): MessageContentType {
      // Manual Code Here
    }

    /**
     * 必选数据： 点评得分
     */
    public withReviewScore(reviewScore: number): BasicReview {
    	this.reviewScore = reviewScore;
    	return this;
    }

    /**
     * 必选数据： 点评类型-推荐 建议
     */
    public withReviewType(reviewType: ReviewType): BasicReview {
    	this.reviewType = reviewType;
    	return this;
    }

}
