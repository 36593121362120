/**
 * 自动生成， 请不要手工修改！
 */

import { CompositeCoworkView } from "../../cowork/CompositeCoworkView";
import { DistanceInfo } from "../../geolocation/DistanceInfo";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { QuestionInfo } from "./QuestionInfo";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class QuestionOverview extends CompositeCoworkView<
  QuestionInfo,
  DistanceInfo
> {
  constructor() {
    super();
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);

    if (includeTypeTag) {
      json["__type"] = "QuestionOverview";
    }
  }

  public getTypeId(): string {
    return "QuestionOverview";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.qa.QuestionOverview";
  }
}
