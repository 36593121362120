/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {AppealInfoView} from '../datatype/appeal/AppealInfoView'
import {CompositeCoworkView} from '../datatype/cowork/CompositeCoworkView'
import {CoworkHighlight} from '../datatype/cowork/CoworkHighlight'
import {CoworkType} from '../datatype/cowork/CoworkType'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EnterpriseChat} from '../datatype/entity/enterprise/EnterpriseChat'
import {EnterpriseHomePageInfo} from '../datatype/entity/enterprise/EnterpriseHomePageInfo'
import {EnterpriseHomeService} from '../api/entity/enterprise/EnterpriseHomeService'
import {EnterpriseInfoTabType} from '../datatype/entity/enterprise/EnterpriseInfoTabType'
import {EnterpriseServiceOrder} from '../datatype/business/order/EnterpriseServiceOrder'
import {EnterpriseServiceTradeInfo} from '../datatype/business/service/trade/EnterpriseServiceTradeInfo'
import {EntityOfficialRoleView} from '../datatype/entity/EntityOfficialRoleView'
import {GeoEntityDesc} from '../datatype/geolocation/GeoEntityDesc'
import {Location} from '../datatype/geolocation/Location'
import {OrderQuerySpec} from '../datatype/business/order/OrderQuerySpec'
import {PageableData} from '../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {PlatformDataContainer} from '../datatype/platform/PlatformDataContainer'
import {ProductView} from '../datatype/product/ProductView'
import {ReviewOverview} from '../datatype/ipsphere/review/ReviewOverview'
import {RoleType} from '../datatype/entity/RoleType'
import {ServiceType} from '../datatype/entity/topic/ServiceType'
import {SimpleEntityView} from '../datatype/entity/view/SimpleEntityView'
import {TradeQuerySpec} from '../datatype/business/general/trade/TradeQuerySpec'
import {WebEnterpriseHomeService} from '../webapi/WebEnterpriseHomeService'
import {WebSessionManager} from '../common/WebSessionManager'


export class WebEnterpriseHomeServiceImpl implements WebEnterpriseHomeService {

    private enterpriseHomeService: EnterpriseHomeService;

    public constructor() {
        this.enterpriseHomeService = DefaultServiceFactory.getInstance().getEnterpriseHomeService();
    }

    public addEmployeeByPhoneNumber(entityId: (number | string | null), phoneNumber: string): Promise<SimpleEntityView> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.addEmployeeByPhoneNumber(WebSessionManager.getSessionContext(),
        	entityId, phoneNumber, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public addHighlight(highlight: (CoworkHighlight | null)): Promise<(number | string)> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.addHighlight(WebSessionManager.getSessionContext(),
        	highlight, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public addMemeberByPhoneNumber(entityId: (number | string | null), role: (RoleType | null), phoneNumber: string, citizenName: (string | null), description: (string | null)): Promise<SimpleEntityView> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.addMemeberByPhoneNumber(WebSessionManager.getSessionContext(),
        	entityId, role, phoneNumber, citizenName, description, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public addOrCreateMemeberByPhoneNumber(entityId: (number | string | null), role: (RoleType | null), phoneNumber: string, citizenName: (string | null), location: (Location | null), description: (string | null)): Promise<SimpleEntityView> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.addOrCreateMemeberByPhoneNumber(WebSessionManager.getSessionContext(),
        	entityId, role, phoneNumber, citizenName, location, description, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public deleteServiceAssociation(communityId: (number | string | null), serviceType: (ServiceType | null), providerId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.deleteServiceAssociation(WebSessionManager.getSessionContext(),
        	communityId, serviceType, providerId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public detachCowork(entityId: (number | string | null), coworkType: (CoworkType | null), coworkRid: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.detachCowork(WebSessionManager.getSessionContext(),
        	entityId, coworkType, coworkRid, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findActiveEnterpriseServicePackageTradesByEnterpriseId(enterpriseId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceTradeInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findActiveEnterpriseServicePackageTradesByEnterpriseId(WebSessionManager.getSessionContext(),
        	enterpriseId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findActiveEnterpriseServiceTradesByEnterpriseId(enterpriseId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceTradeInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findActiveEnterpriseServiceTradesByEnterpriseId(WebSessionManager.getSessionContext(),
        	enterpriseId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findAdvicesAndReviewsByEntityId(entityId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findAdvicesAndReviewsByEntityId(WebSessionManager.getSessionContext(),
        	entityId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findCoworkInfosByEntityId(entityId: (number | string | null), tabType: (EnterpriseInfoTabType | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<CompositeCoworkView<any, any>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findCoworkInfosByEntityId(WebSessionManager.getSessionContext(),
        	entityId, tabType, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceOrdersByEnterpriseId(enterpriseId: (number | string | null), orderQuerySpec: (OrderQuerySpec | null)): Promise<PageableData<EnterpriseServiceOrder>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findEnterpriseServiceOrdersByEnterpriseId(WebSessionManager.getSessionContext(),
        	enterpriseId, orderQuerySpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServicePackageTradesByEnterpriseId(enterpriseId: (number | string | null), tradeQuerySpec: (TradeQuerySpec | null)): Promise<PageableData<EnterpriseServiceTradeInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findEnterpriseServicePackageTradesByEnterpriseId(WebSessionManager.getSessionContext(),
        	enterpriseId, tradeQuerySpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceTradesByEnterpriseId(enterpriseId: (number | string | null), tradeQuerySpec: (TradeQuerySpec | null)): Promise<PageableData<EnterpriseServiceTradeInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findEnterpriseServiceTradesByEnterpriseId(WebSessionManager.getSessionContext(),
        	enterpriseId, tradeQuerySpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEntityInfosByEntityId(entityId: (number | string | null), tabType: (EnterpriseInfoTabType | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findEntityInfosByEntityId(WebSessionManager.getSessionContext(),
        	entityId, tabType, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findHonoursByEntityId(entityId: (number | string | null), entityOnly: (boolean | null), pageBlockSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findHonoursByEntityId(WebSessionManager.getSessionContext(),
        	entityId, entityOnly, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findLawyersByLawFirmId(entityId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<GeoEntityDesc>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findLawyersByLawFirmId(WebSessionManager.getSessionContext(),
        	entityId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findRecentBusOpportuniesByEntityId(entityId: (number | string | null), location: (Location | null), pageBlockSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findRecentBusOpportuniesByEntityId(WebSessionManager.getSessionContext(),
        	entityId, location, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findRecommendedNegativeReviewsByEntityId(entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PlatformDataContainer<PageableData<ReviewOverview>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findRecommendedNegativeReviewsByEntityId(WebSessionManager.getSessionContext(),
        	entityId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findRecommendedPositiveReviewsByEntityId(entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PlatformDataContainer<PageableData<ReviewOverview>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findRecommendedPositiveReviewsByEntityId(WebSessionManager.getSessionContext(),
        	entityId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findRecommendedReviewsByEntityId(entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PlatformDataContainer<PageableData<ReviewOverview>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findRecommendedReviewsByEntityId(WebSessionManager.getSessionContext(),
        	entityId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findSelectedBusOpportuniesByEntityId(entityId: (number | string | null), location: (Location | null), pageBlockSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findSelectedBusOpportuniesByEntityId(WebSessionManager.getSessionContext(),
        	entityId, location, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findSelectedRecommendationsByEntityId(entityId: (number | string | null), location: (Location | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findSelectedRecommendationsByEntityId(WebSessionManager.getSessionContext(),
        	entityId, location, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findSuggestionsByEntityId(entityId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<AppealInfoView>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.findSuggestionsByEntityId(WebSessionManager.getSessionContext(),
        	entityId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getAdminByEnterpriseId(entityId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PageableData<SimpleEntityView>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.getAdminByEnterpriseId(WebSessionManager.getSessionContext(),
        	entityId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getEmployeeByEnterpriseId(entityId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<SimpleEntityView>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.getEmployeeByEnterpriseId(WebSessionManager.getSessionContext(),
        	entityId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getEnterpriseChats(entityId: (number | string | null), lastGetTime: (number | string | null), pageBlockAccessSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<EnterpriseChat>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.getEnterpriseChats(WebSessionManager.getSessionContext(),
        	entityId, lastGetTime, pageBlockAccessSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getEnterpriseHomePageInfo(entityId: (number | string | null)): Promise<PlatformDataContainer<EnterpriseHomePageInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.getEnterpriseHomePageInfo(WebSessionManager.getSessionContext(),
        	entityId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getEntityOfficialRoleView(entityId: (number | string | null)): Promise<EntityOfficialRoleView> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.getEntityOfficialRoleView(WebSessionManager.getSessionContext(),
        	entityId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getMemberedSectorAssociations(entityId: (number | string | null), pageAccessSpec: PageBlockAccessSpec): Promise<PageableData<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.getMemberedSectorAssociations(WebSessionManager.getSessionContext(),
        	entityId, pageAccessSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getMembersByIdAndRole(entityId: (number | string | null), roleType: (RoleType | null), descendant: (boolean | null), pageBlockSpec: PageBlockAccessSpec): Promise<PageableData<SimpleEntityView>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.getMembersByIdAndRole(WebSessionManager.getSessionContext(),
        	entityId, roleType, descendant, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getProductsByEnterpriseId(entityId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<ProductView>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.getProductsByEnterpriseId(WebSessionManager.getSessionContext(),
        	entityId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getRecentReviewsByEntityId(entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PlatformDataContainer<PageableData<ReviewOverview>>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.getRecentReviewsByEntityId(WebSessionManager.getSessionContext(),
        	entityId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public removeHighlight(entityId: (number | string | null), highlightId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.removeHighlight(WebSessionManager.getSessionContext(),
        	entityId, highlightId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public updateHighlights(highlights: (Array<CoworkHighlight> | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseHomeService.updateHighlights(WebSessionManager.getSessionContext(),
        	highlights, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

}
