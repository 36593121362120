/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { CustomMessageResponse } from "./CustomMessageResponse";
import { EntityMessageResponseCode } from "./EntityMessageResponseCode";
import { EntityMessageResponse } from "./EntityMessageResponse";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Serializer } from "../../../serialize/Serializer";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractMessageResponse
  extends AbstractSerializable
  implements EntityMessageResponse
{
  messageId: number | string;
  responseCode: EntityMessageResponseCode;
  responseText: string;
  customMessageResponse: CustomMessageResponse;
  subscriptionOn: boolean;
  inChat: boolean;

  constructor() {
    super();
  }

  public getMessageId(): number | string {
    return this.messageId;
  }

  public setMessageId(messageId: number | string) {
    this.messageId = messageId;
  }

  public getResponseCode(): EntityMessageResponseCode {
    return this.responseCode;
  }

  public setResponseCode(responseCode: EntityMessageResponseCode) {
    this.responseCode = responseCode;
  }

  public getResponseText(): string {
    return this.responseText;
  }

  public setResponseText(responseText: string) {
    this.responseText = responseText;
  }

  public getCustomMessageResponse(): CustomMessageResponse {
    return this.customMessageResponse;
  }

  public setCustomMessageResponse(
    customMessageResponse: CustomMessageResponse
  ) {
    this.customMessageResponse = customMessageResponse;
  }

  public isSubscriptionOn(): boolean {
    return this.subscriptionOn;
  }

  public setSubscriptionOn(subscriptionOn: boolean) {
    this.subscriptionOn = subscriptionOn;
  }

  public isInChat(): boolean {
    return this.inChat;
  }

  public setInChat(inChat: boolean) {
    this.inChat = inChat;
  }

  abstract isAccepted(): boolean;

  abstract getTypeId(): string;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const messageId = json["messageId"];
    if (messageId != null) {
      this.setMessageId(messageId);
    }
    const responseCode = json["responseCode"];
    if (responseCode != null) {
      const convertedResponseCode0 =
        EntityMessageResponseCode[<string>responseCode];
      this.setResponseCode(convertedResponseCode0);
    }
    const responseText = json["responseText"];
    if (responseText != null) {
      this.setResponseText(responseText);
    }
    const customMessageResponse = json["customMessageResponse"];
    if (customMessageResponse != null) {
      const convertedCustomMessageResponse0 = Serializer.fromJson(
        customMessageResponse,
        factory
      );
      this.setCustomMessageResponse(convertedCustomMessageResponse0);
    }
    const subscriptionOn = json["subscriptionOn"];
    if (subscriptionOn != null) {
      this.setSubscriptionOn(subscriptionOn);
    }
    const inChat = json["inChat"];
    if (inChat != null) {
      this.setInChat(inChat);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const messageId = this.getMessageId();
    if (messageId != null) {
      json["messageId"] = String(messageId);
    }
    const responseCode = this.getResponseCode();
    if (responseCode != null) {
      const convertedResponseCode0 = EntityMessageResponseCode[responseCode];
      json["responseCode"] = convertedResponseCode0;
    }
    const responseText = this.getResponseText();
    if (responseText != null) {
      json["responseText"] = responseText;
    }
    const customMessageResponse = this.getCustomMessageResponse();
    if (customMessageResponse != null) {
      const convertedCustomMessageResponse0 = Serializer.getJson(
        customMessageResponse,
        JsonTypeTagType.CLASS
      );
      json["customMessageResponse"] = convertedCustomMessageResponse0;
    }
    const subscriptionOn = this.isSubscriptionOn();
    if (subscriptionOn != null) {
      json["subscriptionOn"] = subscriptionOn;
    }
    const inChat = this.isInChat();
    if (inChat != null) {
      json["inChat"] = inChat;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.entity.api.AbstractMessageResponse";
  }
}
