import { MessageFilter } from "@/services/webapi/WebMessageService";
import { EntityMessage } from "@/services/message/entity/api/EntityMessage";
import { BiConsumer } from "@/services/util";

export class MessageListenerEntry {
  constructor(
    private messageFilter: MessageFilter,
    private messageListener: BiConsumer<Array<EntityMessage>, any>
  ) {}

  public getMessageFilter(): MessageFilter {
    return this.messageFilter;
  }

  public getMessageListener(): BiConsumer<Array<EntityMessage>, any> {
    return this.messageListener;
  }

  public match(message: EntityMessage): boolean {
    if (this.messageFilter) {
      return this.messageFilter(message);
    } else {
      return true;
    }
  }

  public onMessages(messages: Array<EntityMessage>): void {
    if (!messages || messages.length == 0) {
      return;
    }
    let matchedMessages: Array<EntityMessage> = null;
    for (const message of messages) {
      if (this.match(message)) {
        if (matchedMessages == null) {
          matchedMessages = new Array<EntityMessage>(messages.length);
        }
        matchedMessages.push(message);
      }
    }
    if (matchedMessages) {
      this.messageListener(matchedMessages, null);
    }
  }
}
