/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCowork} from '../../cowork/AbstractCowork'
import {EntityInfo} from '../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../keyword/KeywordEntity'
import {MessageType} from '../../../message/MessageContentType'
import {QllCountry} from '../../international/QllCountry'
import {Serializer} from '../../../serialize/Serializer'
import {ServiceHighlight} from '../service/ServiceHighlight'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractEnterpriseTradable extends AbstractCowork  {
    highlights: Array<ServiceHighlight>;
    originalPrice: number;
    price: number;
    settlePrice: number;
    firstPayPercentage: number;
    firstPayAmount: number;
    brief: string;
    serviceInfoLink: string;
    serviceStandardLink: string;
    userProtocolLink: string;
    memberDiscount: number;
    memberPrice: number;
    platformDiscount: number;
    mainSupplierInfo: EntityInfo;
    salesQuota: number;
    salesRemainingQuota: number;
    totalTrades: number;
    totalTradeVolume: number;
    test: boolean;
    preSale: boolean;

    constructor() {
    	super();
    }

    public getHighlights(): Array<ServiceHighlight> {
    	return this.highlights;
    }

    public setHighlights(highlights: Array<ServiceHighlight>) {
    	this.highlights = highlights;
    }

    public getOriginalPrice(): number {
    	return this.originalPrice;
    }

    public setOriginalPrice(originalPrice: number) {
    	this.originalPrice = originalPrice;
    }

    public getPrice(): number {
    	return this.price;
    }

    public setPrice(price: number) {
    	this.price = price;
    }

    public getSettlePrice(): number {
    	return this.settlePrice;
    }

    public setSettlePrice(settlePrice: number) {
    	this.settlePrice = settlePrice;
    }

    public getFirstPayPercentage(): number {
    	return this.firstPayPercentage;
    }

    public setFirstPayPercentage(firstPayPercentage: number) {
    	this.firstPayPercentage = firstPayPercentage;
    }

    public getFirstPayAmount(): number {
    	return this.firstPayAmount;
    }

    public setFirstPayAmount(firstPayAmount: number) {
    	this.firstPayAmount = firstPayAmount;
    }

    public getBrief(): string {
    	return this.brief;
    }

    public setBrief(brief: string) {
    	this.brief = brief;
    }

    public getServiceInfoLink(): string {
    	return this.serviceInfoLink;
    }

    public setServiceInfoLink(serviceInfoLink: string) {
    	this.serviceInfoLink = serviceInfoLink;
    }

    public getServiceStandardLink(): string {
    	return this.serviceStandardLink;
    }

    public setServiceStandardLink(serviceStandardLink: string) {
    	this.serviceStandardLink = serviceStandardLink;
    }

    public getUserProtocolLink(): string {
    	return this.userProtocolLink;
    }

    public setUserProtocolLink(userProtocolLink: string) {
    	this.userProtocolLink = userProtocolLink;
    }

    public getMemberDiscount(): number {
    	return this.memberDiscount;
    }

    public setMemberDiscount(memberDiscount: number) {
    	this.memberDiscount = memberDiscount;
    }

    public getMemberPrice(): number {
    	return this.memberPrice;
    }

    public setMemberPrice(memberPrice: number) {
    	this.memberPrice = memberPrice;
    }

    public getPlatformDiscount(): number {
    	return this.platformDiscount;
    }

    public setPlatformDiscount(platformDiscount: number) {
    	this.platformDiscount = platformDiscount;
    }

    public getMainSupplierInfo(): EntityInfo {
    	return this.mainSupplierInfo;
    }

    public setMainSupplierInfo(mainSupplierInfo: EntityInfo) {
    	this.mainSupplierInfo = mainSupplierInfo;
    }

    public getSalesQuota(): number {
    	return this.salesQuota;
    }

    public setSalesQuota(salesQuota: number) {
    	this.salesQuota = salesQuota;
    }

    public getSalesRemainingQuota(): number {
    	return this.salesRemainingQuota;
    }

    public setSalesRemainingQuota(salesRemainingQuota: number) {
    	this.salesRemainingQuota = salesRemainingQuota;
    }

    public getTotalTrades(): number {
    	return this.totalTrades;
    }

    public setTotalTrades(totalTrades: number) {
    	this.totalTrades = totalTrades;
    }

    public getTotalTradeVolume(): number {
    	return this.totalTradeVolume;
    }

    public setTotalTradeVolume(totalTradeVolume: number) {
    	this.totalTradeVolume = totalTradeVolume;
    }

    public isTest(): boolean {
    	return this.test;
    }

    public setTest(test: boolean) {
    	this.test = test;
    }

    public isPreSale(): boolean {
    	return this.preSale;
    }

    public setPreSale(preSale: boolean) {
    	this.preSale = preSale;
    }


    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;

    abstract getTypeId(): string;

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let highlights = json["highlights"];
        if (highlights != null) {
            let convertedHighlights0 = [];
            highlights = highlights[1];
            for (let i in highlights) {
                let convertedHighlights10 = highlights[i];
                let convertedHighlights1 = new ServiceHighlight();
                convertedHighlights1.fillFromJson(convertedHighlights10, factory);
                convertedHighlights0.push(convertedHighlights1);
            }
            this.setHighlights(convertedHighlights0);
        }
        let originalPrice = json["originalPrice"];
        if (originalPrice != null) {
            this.setOriginalPrice(originalPrice);
        }
        let price = json["price"];
        if (price != null) {
            this.setPrice(price);
        }
        let settlePrice = json["settlePrice"];
        if (settlePrice != null) {
            this.setSettlePrice(settlePrice);
        }
        let firstPayPercentage = json["firstPayPercentage"];
        if (firstPayPercentage != null) {
            this.setFirstPayPercentage(firstPayPercentage);
        }
        let firstPayAmount = json["firstPayAmount"];
        if (firstPayAmount != null) {
            this.setFirstPayAmount(firstPayAmount);
        }
        let brief = json["brief"];
        if (brief != null) {
            this.setBrief(brief);
        }
        let serviceInfoLink = json["serviceInfoLink"];
        if (serviceInfoLink != null) {
            this.setServiceInfoLink(serviceInfoLink);
        }
        let serviceStandardLink = json["serviceStandardLink"];
        if (serviceStandardLink != null) {
            this.setServiceStandardLink(serviceStandardLink);
        }
        let userProtocolLink = json["userProtocolLink"];
        if (userProtocolLink != null) {
            this.setUserProtocolLink(userProtocolLink);
        }
        let memberDiscount = json["memberDiscount"];
        if (memberDiscount != null) {
            this.setMemberDiscount(memberDiscount);
        }
        let memberPrice = json["memberPrice"];
        if (memberPrice != null) {
            this.setMemberPrice(memberPrice);
        }
        let platformDiscount = json["platformDiscount"];
        if (platformDiscount != null) {
            this.setPlatformDiscount(platformDiscount);
        }
        let mainSupplierInfo = json["mainSupplierInfo"];
        if (mainSupplierInfo != null) {
            let convertedMainSupplierInfo0 = Serializer.fillFromJsonObjectWithTypeTag(mainSupplierInfo, "__type", factory);
            this.setMainSupplierInfo(convertedMainSupplierInfo0);
        }
        let salesQuota = json["salesQuota"];
        if (salesQuota != null) {
            this.setSalesQuota(salesQuota);
        }
        let salesRemainingQuota = json["salesRemainingQuota"];
        if (salesRemainingQuota != null) {
            this.setSalesRemainingQuota(salesRemainingQuota);
        }
        let totalTrades = json["totalTrades"];
        if (totalTrades != null) {
            this.setTotalTrades(totalTrades);
        }
        let totalTradeVolume = json["totalTradeVolume"];
        if (totalTradeVolume != null) {
            this.setTotalTradeVolume(totalTradeVolume);
        }
        let test = json["test"];
        if (test != null) {
            this.setTest(test);
        }
        let preSale = json["preSale"];
        if (preSale != null) {
            this.setPreSale(preSale);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let highlights = this.getHighlights();
        if (highlights != null) {
            let convertedHighlights0 = [];
            for (let i in highlights) {
                let convertedHighlights10 = highlights[i];
                let convertedHighlights1 = convertedHighlights10.getJson(JsonTypeTagType.NONE);
                convertedHighlights0.push(convertedHighlights1);
            }
            let convertedHighlightsWithType1 = ["java.util.ArrayList", convertedHighlights0];
            json["highlights"] = convertedHighlightsWithType1;
        }
        let originalPrice = this.getOriginalPrice();
        if (originalPrice != null) {
            json["originalPrice"] = originalPrice;
        }
        let price = this.getPrice();
        if (price != null) {
            json["price"] = price;
        }
        let settlePrice = this.getSettlePrice();
        if (settlePrice != null) {
            json["settlePrice"] = settlePrice;
        }
        let firstPayPercentage = this.getFirstPayPercentage();
        if (firstPayPercentage != null) {
            json["firstPayPercentage"] = firstPayPercentage;
        }
        let firstPayAmount = this.getFirstPayAmount();
        if (firstPayAmount != null) {
            json["firstPayAmount"] = firstPayAmount;
        }
        let brief = this.getBrief();
        if (brief != null) {
            json["brief"] = brief;
        }
        let serviceInfoLink = this.getServiceInfoLink();
        if (serviceInfoLink != null) {
            json["serviceInfoLink"] = serviceInfoLink;
        }
        let serviceStandardLink = this.getServiceStandardLink();
        if (serviceStandardLink != null) {
            json["serviceStandardLink"] = serviceStandardLink;
        }
        let userProtocolLink = this.getUserProtocolLink();
        if (userProtocolLink != null) {
            json["userProtocolLink"] = userProtocolLink;
        }
        let memberDiscount = this.getMemberDiscount();
        if (memberDiscount != null) {
            json["memberDiscount"] = memberDiscount;
        }
        let memberPrice = this.getMemberPrice();
        if (memberPrice != null) {
            json["memberPrice"] = memberPrice;
        }
        let platformDiscount = this.getPlatformDiscount();
        if (platformDiscount != null) {
            json["platformDiscount"] = platformDiscount;
        }
        let mainSupplierInfo = this.getMainSupplierInfo();
        if (mainSupplierInfo != null) {
            let convertedMainSupplierInfo0 = mainSupplierInfo.getJson(JsonTypeTagType.TYPE);
            json["mainSupplierInfo"] = convertedMainSupplierInfo0;
        }
        let salesQuota = this.getSalesQuota();
        if (salesQuota != null) {
            json["salesQuota"] = salesQuota;
        }
        let salesRemainingQuota = this.getSalesRemainingQuota();
        if (salesRemainingQuota != null) {
            json["salesRemainingQuota"] = salesRemainingQuota;
        }
        let totalTrades = this.getTotalTrades();
        if (totalTrades != null) {
            json["totalTrades"] = totalTrades;
        }
        let totalTradeVolume = this.getTotalTradeVolume();
        if (totalTradeVolume != null) {
            json["totalTradeVolume"] = totalTradeVolume;
        }
        let test = this.isTest();
        if (test != null) {
            json["test"] = test;
        }
        let preSale = this.isPreSale();
        if (preSale != null) {
            json["preSale"] = preSale;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.tradable.AbstractEnterpriseTradable";
    }
}
