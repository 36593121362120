/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { AnswerStatus } from "../ipsphere/qa/AnswerStatus";
import { AppealReplyStatus } from "../appeal/AppealReplyStatus";
import { AppealStatus } from "../appeal/AppealStatus";
import { AwardOrder } from "./AwardOrder";
import { CoworkType } from "../cowork/CoworkType";
import { EntityInfo } from "../entity/EntityInfo";
import { HelpOffer } from "./HelpOffer";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { ReplyType } from "./ReplyType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class ReplyContent extends AbstractSerializable {
  id: number | string;
  rootId: number | string;
  rootType: CoworkType;
  replyType: ReplyType;
  correlationId: number | string;
  senderInfo: EntityInfo;
  beReplyerInfo: EntityInfo;
  enterpriseInfo: EntityInfo;
  createTime: number | string;
  description: string;
  approve: boolean;
  imageUrls: Array<string>;
  totalApprovals: number;
  layerNum: number;
  awardType: AwardOrder;
  activity: boolean;
  official: boolean;
  noPublic: boolean;
  status: AnswerStatus;
  appealStatus: AppealStatus;
  appealReplyStatus: AppealReplyStatus;
  helpOffer: HelpOffer;

  constructor() {
    super();
  }

  public getId(): number | string {
    return this.id;
  }

  public setId(id: number | string) {
    this.id = id;
  }

  public getRootId(): number | string {
    return this.rootId;
  }

  public setRootId(rootId: number | string) {
    this.rootId = rootId;
  }

  public getRootType(): CoworkType {
    return this.rootType;
  }

  public setRootType(rootType: CoworkType) {
    this.rootType = rootType;
  }

  public getReplyType(): ReplyType {
    return this.replyType;
  }

  public setReplyType(replyType: ReplyType) {
    this.replyType = replyType;
  }

  public getCorrelationId(): number | string {
    return this.correlationId;
  }

  public setCorrelationId(correlationId: number | string) {
    this.correlationId = correlationId;
  }

  public getSenderInfo(): EntityInfo {
    return this.senderInfo;
  }

  public setSenderInfo(senderInfo: EntityInfo) {
    this.senderInfo = senderInfo;
  }

  public getBeReplyerInfo(): EntityInfo {
    return this.beReplyerInfo;
  }

  public setBeReplyerInfo(beReplyerInfo: EntityInfo) {
    this.beReplyerInfo = beReplyerInfo;
  }

  public getEnterpriseInfo(): EntityInfo {
    return this.enterpriseInfo;
  }

  public setEnterpriseInfo(enterpriseInfo: EntityInfo) {
    this.enterpriseInfo = enterpriseInfo;
  }

  public getCreateTime(): number | string {
    return this.createTime;
  }

  public setCreateTime(createTime: number | string) {
    this.createTime = createTime;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getApprove(): boolean {
    return this.approve;
  }

  public setApprove(approve: boolean) {
    this.approve = approve;
  }

  public getImageUrls(): Array<string> {
    return this.imageUrls;
  }

  public setImageUrls(imageUrls: Array<string>) {
    this.imageUrls = imageUrls;
  }

  public getTotalApprovals(): number {
    return this.totalApprovals;
  }

  public setTotalApprovals(totalApprovals: number) {
    this.totalApprovals = totalApprovals;
  }

  public getLayerNum(): number {
    return this.layerNum;
  }

  public setLayerNum(layerNum: number) {
    this.layerNum = layerNum;
  }

  public getAwardType(): AwardOrder {
    return this.awardType;
  }

  public setAwardType(awardType: AwardOrder) {
    this.awardType = awardType;
  }

  public getActivity(): boolean {
    return this.activity;
  }

  public setActivity(activity: boolean) {
    this.activity = activity;
  }

  public isOfficial(): boolean {
    return this.official;
  }

  public setOfficial(official: boolean) {
    this.official = official;
  }

  public isNoPublic(): boolean {
    return this.noPublic;
  }

  public setNoPublic(noPublic: boolean) {
    this.noPublic = noPublic;
  }

  public getStatus(): AnswerStatus {
    return this.status;
  }

  public setStatus(status: AnswerStatus) {
    this.status = status;
  }

  public getAppealStatus(): AppealStatus {
    return this.appealStatus;
  }

  public setAppealStatus(appealStatus: AppealStatus) {
    this.appealStatus = appealStatus;
  }

  public getAppealReplyStatus(): AppealReplyStatus {
    return this.appealReplyStatus;
  }

  public setAppealReplyStatus(appealReplyStatus: AppealReplyStatus) {
    this.appealReplyStatus = appealReplyStatus;
  }

  public getHelpOffer(): HelpOffer {
    return this.helpOffer;
  }

  public setHelpOffer(helpOffer: HelpOffer) {
    this.helpOffer = helpOffer;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const id = json["id"];
    if (id != null) {
      this.setId(id);
    }
    const rootId = json["rootId"];
    if (rootId != null) {
      this.setRootId(rootId);
    }
    const rootType = json["rootType"];
    if (rootType != null) {
      const convertedRootType0 = CoworkType[<string>rootType];
      this.setRootType(convertedRootType0);
    }
    const replyType = json["replyType"];
    if (replyType != null) {
      const convertedReplyType0 = ReplyType[<string>replyType];
      this.setReplyType(convertedReplyType0);
    }
    const correlationId = json["correlationId"];
    if (correlationId != null) {
      this.setCorrelationId(correlationId);
    }
    const senderInfo = json["senderInfo"];
    if (senderInfo != null) {
      const convertedSenderInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        senderInfo,
        "__type",
        factory
      );
      this.setSenderInfo(convertedSenderInfo0);
    }
    const beReplyerInfo = json["beReplyerInfo"];
    if (beReplyerInfo != null) {
      const convertedBeReplyerInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        beReplyerInfo,
        "__type",
        factory
      );
      this.setBeReplyerInfo(convertedBeReplyerInfo0);
    }
    const enterpriseInfo = json["enterpriseInfo"];
    if (enterpriseInfo != null) {
      const convertedEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        enterpriseInfo,
        "__type",
        factory
      );
      this.setEnterpriseInfo(convertedEnterpriseInfo0);
    }
    const createTime = json["createTime"];
    if (createTime != null) {
      this.setCreateTime(createTime);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    const approve = json["approve"];
    if (approve != null) {
      this.setApprove(approve);
    }
    let imageUrls = json["imageUrls"];
    if (imageUrls != null) {
      const convertedImageUrls0 = [];
      imageUrls = imageUrls[1];
      for (const i in imageUrls) {
        const convertedImageUrls10 = imageUrls[i];
        convertedImageUrls0.push(convertedImageUrls10);
      }
      this.setImageUrls(convertedImageUrls0);
    }
    const totalApprovals = json["totalApprovals"];
    if (totalApprovals != null) {
      this.setTotalApprovals(totalApprovals);
    }
    const layerNum = json["layerNum"];
    if (layerNum != null) {
      this.setLayerNum(layerNum);
    }
    const awardType = json["awardType"];
    if (awardType != null) {
      const convertedAwardType0 = AwardOrder[<string>awardType];
      this.setAwardType(convertedAwardType0);
    }
    const activity = json["activity"];
    if (activity != null) {
      this.setActivity(activity);
    }
    const official = json["official"];
    if (official != null) {
      this.setOfficial(official);
    }
    const noPublic = json["noPublic"];
    if (noPublic != null) {
      this.setNoPublic(noPublic);
    }
    const status = json["status"];
    if (status != null) {
      const convertedStatus0 = AnswerStatus[<string>status];
      this.setStatus(convertedStatus0);
    }
    const appealStatus = json["appealStatus"];
    if (appealStatus != null) {
      const convertedAppealStatus0 = AppealStatus[<string>appealStatus];
      this.setAppealStatus(convertedAppealStatus0);
    }
    const appealReplyStatus = json["appealReplyStatus"];
    if (appealReplyStatus != null) {
      const convertedAppealReplyStatus0 =
        AppealReplyStatus[<string>appealReplyStatus];
      this.setAppealReplyStatus(convertedAppealReplyStatus0);
    }
    const helpOffer = json["helpOffer"];
    if (helpOffer != null) {
      const convertedHelpOffer0 = new HelpOffer();
      convertedHelpOffer0.fillFromJson(helpOffer, factory);
      this.setHelpOffer(convertedHelpOffer0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const id = this.getId();
    if (id != null) {
      json["id"] = String(id);
    }
    const rootId = this.getRootId();
    if (rootId != null) {
      json["rootId"] = String(rootId);
    }
    const rootType = this.getRootType();
    if (rootType != null) {
      const convertedRootType0 = CoworkType[rootType];
      json["rootType"] = convertedRootType0;
    }
    const replyType = this.getReplyType();
    if (replyType != null) {
      const convertedReplyType0 = ReplyType[replyType];
      json["replyType"] = convertedReplyType0;
    }
    const correlationId = this.getCorrelationId();
    if (correlationId != null) {
      json["correlationId"] = String(correlationId);
    }
    const senderInfo = this.getSenderInfo();
    if (senderInfo != null) {
      const convertedSenderInfo0 = senderInfo.getJson(JsonTypeTagType.TYPE);
      json["senderInfo"] = convertedSenderInfo0;
    }
    const beReplyerInfo = this.getBeReplyerInfo();
    if (beReplyerInfo != null) {
      const convertedBeReplyerInfo0 = beReplyerInfo.getJson(
        JsonTypeTagType.TYPE
      );
      json["beReplyerInfo"] = convertedBeReplyerInfo0;
    }
    const enterpriseInfo = this.getEnterpriseInfo();
    if (enterpriseInfo != null) {
      const convertedEnterpriseInfo0 = enterpriseInfo.getJson(
        JsonTypeTagType.TYPE
      );
      json["enterpriseInfo"] = convertedEnterpriseInfo0;
    }
    const createTime = this.getCreateTime();
    if (createTime != null) {
      json["createTime"] = String(createTime);
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const approve = this.getApprove();
    if (approve != null) {
      json["approve"] = approve;
    }
    const imageUrls = this.getImageUrls();
    if (imageUrls != null) {
      const convertedImageUrls0 = [];
      for (const i in imageUrls) {
        const convertedImageUrls10 = imageUrls[i];
        convertedImageUrls0.push(convertedImageUrls10);
      }
      const convertedImageUrlsWithType1 = [
        "java.util.ArrayList",
        convertedImageUrls0,
      ];
      json["imageUrls"] = convertedImageUrlsWithType1;
    }
    const totalApprovals = this.getTotalApprovals();
    if (totalApprovals != null) {
      json["totalApprovals"] = totalApprovals;
    }
    const layerNum = this.getLayerNum();
    if (layerNum != null) {
      json["layerNum"] = layerNum;
    }
    const awardType = this.getAwardType();
    if (awardType != null) {
      const convertedAwardType0 = AwardOrder[awardType];
      json["awardType"] = convertedAwardType0;
    }
    const activity = this.getActivity();
    if (activity != null) {
      json["activity"] = activity;
    }
    const official = this.isOfficial();
    if (official != null) {
      json["official"] = official;
    }
    const noPublic = this.isNoPublic();
    if (noPublic != null) {
      json["noPublic"] = noPublic;
    }
    const status = this.getStatus();
    if (status != null) {
      const convertedStatus0 = AnswerStatus[status];
      json["status"] = convertedStatus0;
    }
    const appealStatus = this.getAppealStatus();
    if (appealStatus != null) {
      const convertedAppealStatus0 = AppealStatus[appealStatus];
      json["appealStatus"] = convertedAppealStatus0;
    }
    const appealReplyStatus = this.getAppealReplyStatus();
    if (appealReplyStatus != null) {
      const convertedAppealReplyStatus0 = AppealReplyStatus[appealReplyStatus];
      json["appealReplyStatus"] = convertedAppealReplyStatus0;
    }
    const helpOffer = this.getHelpOffer();
    if (helpOffer != null) {
      const convertedHelpOffer0 = helpOffer.getJson(JsonTypeTagType.NONE);
      json["helpOffer"] = convertedHelpOffer0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.reply.ReplyContent";
  }
}
