/**
 * 自动生成， 请不要手工修改！
 */

import {EntityInfo} from '../../../../entity/EntityInfo'
import {FinancingServiceRequest} from './FinancingServiceRequest'
import {JsonTypeTagType} from '../../../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../../../keyword/KeywordEntity'
import {MessageType} from '../../../../../message/MessageContentType'
import {PeriodType} from '../../../../PeriodType'
import {QllCountry} from '../../../../international/QllCountry'
import {TextType} from '../../../../TextType'
import {TypeFactory} from '../../../../../serialize/TypeFactory'


export abstract class AbstractEquityFinanceServiceRequest extends FinancingServiceRequest  {
    stockPercentage: number;
    loanAmount: number;
    loanPeriod: number;
    loanPeriodType: PeriodType;

    constructor() {
    	super();
    }

    public getStockPercentage(): number {
    	return this.stockPercentage;
    }

    public setStockPercentage(stockPercentage: number) {
    	this.stockPercentage = stockPercentage;
    }

    public getLoanAmount(): number {
    	return this.loanAmount;
    }

    public setLoanAmount(loanAmount: number) {
    	this.loanAmount = loanAmount;
    }

    public getLoanPeriod(): number {
    	return this.loanPeriod;
    }

    public setLoanPeriod(loanPeriod: number) {
    	this.loanPeriod = loanPeriod;
    }

    public getLoanPeriodType(): PeriodType {
    	return this.loanPeriodType;
    }

    public setLoanPeriodType(loanPeriodType: PeriodType) {
    	this.loanPeriodType = loanPeriodType;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;

    abstract getTextType(): TextType;

    abstract getText(): string;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let stockPercentage = json["stockPercentage"];
        if (stockPercentage != null) {
            this.setStockPercentage(stockPercentage);
        }
        let loanAmount = json["loanAmount"];
        if (loanAmount != null) {
            this.setLoanAmount(loanAmount);
        }
        let loanPeriod = json["loanPeriod"];
        if (loanPeriod != null) {
            this.setLoanPeriod(loanPeriod);
        }
        let loanPeriodType = json["loanPeriodType"];
        if (loanPeriodType != null) {
            let convertedLoanPeriodType0 = PeriodType[<string>loanPeriodType];
            this.setLoanPeriodType(convertedLoanPeriodType0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let stockPercentage = this.getStockPercentage();
        if (stockPercentage != null) {
            json["stockPercentage"] = stockPercentage;
        }
        let loanAmount = this.getLoanAmount();
        if (loanAmount != null) {
            json["loanAmount"] = loanAmount;
        }
        let loanPeriod = this.getLoanPeriod();
        if (loanPeriod != null) {
            json["loanPeriod"] = loanPeriod;
        }
        let loanPeriodType = this.getLoanPeriodType();
        if (loanPeriodType != null) {
            let convertedLoanPeriodType0 = PeriodType[loanPeriodType];
            json["loanPeriodType"] = convertedLoanPeriodType0;
        }

        if (includeTypeTag) {
            json["__type"] = "EquityFinanceServiceRequest";
        }
    }


    public getTypeId(): string {
    	return "EquityFinanceServiceRequest";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.request.banking.EquityFinanceServiceRequest";
    }
}
