// @ts-nocheck
import { AbstractSupplierDetails } from "./AbstractSupplierDetails";
import { AdditionalEntityAttributes } from "../AdditionalEntityAttributes";
import { EntityType } from "../EntityType";

export class SupplierDetails extends AbstractSupplierDetails {
  constructor() {
    super();
  }

  copy(): AdditionalEntityAttributes {
    // Manual Code Here
  }
  getEntityType(): EntityType {
    // Manual Code Here
  }
  addAdditionalEntityAttributes(arg0: AdditionalEntityAttributes | null) {
    // Manual Code Here
  }
}
