<template>
  <div class="notFond">
    <div class="middle-box text-center">
      <h1>404</h1>
      <h3 class="font-bold">页面未找到！</h3>
      <div class="error-desc">抱歉，页面好像去火星了~</div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="less" scoped>
.notFond {
  width: 100vw;
  height: 100vh;
}

.text-center {
  text-align: center;
}

.middle-box {
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
}

h1,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: inherit;
  line-height: 1.1;
  color: inherit;
}

.middle-box h1 {
  font-size: 170px;
  font-weight: 100;
}

.middle-box h3 {
  font-size: 16px;
}

.font-bold {
  font-weight: 600;
}
</style>
