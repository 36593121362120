// @ts-nocheck
import { AbstractAssignableEnterpriseServiceRequest } from "./AbstractAssignableEnterpriseServiceRequest";
import { CoworkType } from "../../../cowork/CoworkType";
import {EntityInfo} from "../../../entity/EntityInfo";
import {MessageType} from "../../../../message/MessageContentType";
import {RequestType} from "../../../request/RequestType";

export class AssignableEnterpriseServiceRequest extends AbstractAssignableEnterpriseServiceRequest {
    constructor() {
        super();
    }

    getMessageType(): MessageType {
        return MessageType.ENT_SERVICE_REQUEST;
    }

    /**
     * 必选数据： 请求对象类型
     */
    public withTargetType(
        targetType: CoworkType
    ): AssignableEnterpriseServiceRequest {
        this.targetType = targetType;
        return this;
  }

  /**
   * 必选数据： 请求对象ID
   */
  public withTargetId(
    targetId: number | string
  ): AssignableEnterpriseServiceRequest {
    this.targetId = targetId;
    return this;
  }

  /**
   * 必选数据： 请求对象所属社区D
   */
  public withTargetEntityInfo(
    targetEntityInfo: EntityInfo
  ): AssignableEnterpriseServiceRequest {
    this.targetEntityInfo = targetEntityInfo;
    return this;
  }

  /**
   * 必选数据： 请求者信息
   */
  public withRequestorInfo(
    requestorInfo: EntityInfo
  ): AssignableEnterpriseServiceRequest {
    this.requestorInfo = requestorInfo;
    return this;
  }

  /**
   * 必选数据： 请求类型
   */
  public withRequestType(
    requestType: RequestType
  ): AssignableEnterpriseServiceRequest {
    this.requestType = requestType;
    return this;
  }
}
