/**
 * 自动生成， 请不要手工修改！
 */


export enum TradeStatus {
    ACTIVE,
    PENDING_TO_PAY,
    DISPATCHED,
    COMPLETED,
    CANCELLED
}
