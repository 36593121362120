/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { GroupInfoTabType } from "../group/GroupInfoTabType";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { SectorTopicSpec } from "./SectorTopicSpec";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class SectorTopicConfig extends AbstractSerializable {
  topicSpecs: Array<SectorTopicSpec>;
  tabTypes: Array<GroupInfoTabType>;
  tabNames: Array<string>;

  constructor() {
    super();
  }

  public getTopicSpecs(): Array<SectorTopicSpec> {
    return this.topicSpecs;
  }

  public setTopicSpecs(topicSpecs: Array<SectorTopicSpec>) {
    this.topicSpecs = topicSpecs;
  }

  public getTabTypes(): Array<GroupInfoTabType> {
    return this.tabTypes;
  }

  public setTabTypes(tabTypes: Array<GroupInfoTabType>) {
    this.tabTypes = tabTypes;
  }

  public getTabNames(): Array<string> {
    return this.tabNames;
  }

  public setTabNames(tabNames: Array<string>) {
    this.tabNames = tabNames;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    let topicSpecs = json["topicSpecs"];
    if (topicSpecs != null) {
      const convertedTopicSpecs0 = [];
      topicSpecs = topicSpecs[1];
      for (const i in topicSpecs) {
        const convertedTopicSpecs10 = topicSpecs[i];
        const convertedTopicSpecs1 = new SectorTopicSpec();
        convertedTopicSpecs1.fillFromJson(convertedTopicSpecs10, factory);
        convertedTopicSpecs0.push(convertedTopicSpecs1);
      }
      this.setTopicSpecs(convertedTopicSpecs0);
    }
    let tabTypes = json["tabTypes"];
    if (tabTypes != null) {
      const convertedTabTypes0 = [];
      tabTypes = tabTypes[1];
      for (const i in tabTypes) {
        const convertedTabTypes10 = tabTypes[i];
        const convertedTabTypes1 =
          GroupInfoTabType[<string>convertedTabTypes10];
        convertedTabTypes0.push(convertedTabTypes1);
      }
      this.setTabTypes(convertedTabTypes0);
    }
    let tabNames = json["tabNames"];
    if (tabNames != null) {
      const convertedTabNames0 = [];
      tabNames = tabNames[1];
      for (const i in tabNames) {
        const convertedTabNames10 = tabNames[i];
        convertedTabNames0.push(convertedTabNames10);
      }
      this.setTabNames(convertedTabNames0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const topicSpecs = this.getTopicSpecs();
    if (topicSpecs != null) {
      const convertedTopicSpecs0 = [];
      for (const i in topicSpecs) {
        const convertedTopicSpecs10 = topicSpecs[i];
        const convertedTopicSpecs1 = convertedTopicSpecs10.getJson(
          JsonTypeTagType.NONE
        );
        convertedTopicSpecs0.push(convertedTopicSpecs1);
      }
      const convertedTopicSpecsWithType1 = [
        "java.util.ArrayList",
        convertedTopicSpecs0,
      ];
      json["topicSpecs"] = convertedTopicSpecsWithType1;
    }
    const tabTypes = this.getTabTypes();
    if (tabTypes != null) {
      const convertedTabTypes0 = [];
      for (const i in tabTypes) {
        const convertedTabTypes10 = tabTypes[i];
        const convertedTabTypes1 = GroupInfoTabType[convertedTabTypes10];
        convertedTabTypes0.push(convertedTabTypes1);
      }
      const convertedTabTypesWithType1 = [
        "java.util.ArrayList",
        convertedTabTypes0,
      ];
      json["tabTypes"] = convertedTabTypesWithType1;
    }
    const tabNames = this.getTabNames();
    if (tabNames != null) {
      const convertedTabNames0 = [];
      for (const i in tabNames) {
        const convertedTabNames10 = tabNames[i];
        convertedTabNames0.push(convertedTabNames10);
      }
      const convertedTabNamesWithType1 = [
        "java.util.ArrayList",
        convertedTabNames0,
      ];
      json["tabNames"] = convertedTabNamesWithType1;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.sector.SectorTopicConfig";
  }
}
