import { nextTick } from "vue";

export default class PerchUtil {
  static async Perch(
    pageSize: number,
    length: number,
    child: string,
    father: string,
    index?: number
  ) {
    document
      .querySelectorAll(".occupying")
      .forEach((element) => element.remove());
    await nextTick(() => {
      if (
        length != pageSize &&
        ((index ? index : pageSize + 1) > pageSize || pageSize > length)
      ) {
        console.log("1111111111111111111");
        const count = pageSize - length;
        for (let i = 0; i < count; i++) {
          if (document.querySelector(child)) {
            const box = document.querySelector(child).cloneNode(true);
            document.querySelector(father).appendChild(box);
            const boxAll = document.querySelectorAll(child);
            boxAll[boxAll.length - 1].classList.add("occupying");
          }
        }
      }
    });
  }
}
