/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { Filter } from "./Filter";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class KeywordFilter extends AbstractSerializable implements Filter {
  keywords: string[];

  constructor() {
    super();
  }

  public getKeywords(): string[] {
    return this.keywords;
  }

  public setKeywords(keywords: string[]) {
    this.keywords = keywords;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const keywords = json["keywords"];
    if (keywords != null) {
      const convertedKeywords0 = [];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      this.setKeywords(convertedKeywords0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const keywords = this.getKeywords();
    if (keywords != null) {
      const convertedKeywords0 = [];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      json["keywords"] = convertedKeywords0;
    }

    if (includeTypeTag) {
      json["__type"] = "KeywordFilter";
    }
  }

  public getTypeId(): string {
    return "KeywordFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.KeywordFilter";
  }
}
