/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractCowork } from "./AbstractCowork";
import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkStatsAccessor } from "./CoworkStatsAccessor";
import { CoworkUserInfo } from "./CoworkUserInfo";
import { GeneralCoworkStats } from "./GeneralCoworkStats";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractCompositeCoworkInfo<
    T extends AbstractCowork,
    C extends GeneralCoworkStats,
    R extends CoworkUserInfo,
    S extends AbstractCompositeCoworkInfo<T, C, R, S>
  >
  extends AbstractSerializable
  implements CoworkStatsAccessor
{
  cowork: T;
  coworkStats: C;
  coworkUserInfo: R;

  constructor() {
    super();
  }

  public getCowork(): T {
    return this.cowork;
  }

  public setCowork(cowork: T) {
    this.cowork = cowork;
  }

  public getCoworkStats(): C {
    return this.coworkStats;
  }

  public setCoworkStats(coworkStats: C) {
    this.coworkStats = coworkStats;
  }

  public getCoworkUserInfo(): R {
    return this.coworkUserInfo;
  }

  public setCoworkUserInfo(coworkUserInfo: R) {
    this.coworkUserInfo = coworkUserInfo;
  }

  abstract getVisits(): number;

  abstract getTypeId(): string;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const cowork = json["cowork"];
    if (cowork != null) {
      const convertedCowork0 = Serializer.fillFromJsonObjectWithTypeTag(
        cowork,
        "__type",
        factory
      );
      this.setCowork(convertedCowork0);
    }
    const coworkStats = json["coworkStats"];
    if (coworkStats != null) {
      const convertedCoworkStats0 = Serializer.fillFromJsonObjectWithTypeTag(
        coworkStats,
        "__type",
        factory
      );
      this.setCoworkStats(convertedCoworkStats0);
    }
    const coworkUserInfo = json["coworkUserInfo"];
    if (coworkUserInfo != null) {
      const convertedCoworkUserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        coworkUserInfo,
        "__type",
        factory
      );
      this.setCoworkUserInfo(convertedCoworkUserInfo0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const cowork = this.getCowork();
    if (cowork != null) {
      const convertedCowork0 = cowork.getJson(JsonTypeTagType.TYPE);
      json["cowork"] = convertedCowork0;
    }
    const coworkStats = this.getCoworkStats();
    if (coworkStats != null) {
      const convertedCoworkStats0 = coworkStats.getJson(JsonTypeTagType.TYPE);
      json["coworkStats"] = convertedCoworkStats0;
    }
    const coworkUserInfo = this.getCoworkUserInfo();
    if (coworkUserInfo != null) {
      const convertedCoworkUserInfo0 = coworkUserInfo.getJson(
        JsonTypeTagType.TYPE
      );
      json["coworkUserInfo"] = convertedCoworkUserInfo0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.AbstractCompositeCoworkInfo";
  }
}
