import { DefaultWebServiceFactory } from "@/services/webapi/DefaultWebServiceFactory";
import { PageBlockAccessSpec } from "@/services/datatype/paging/PageBlockAccessSpec";
import UserTools from "@/services/session/UserTools";
//获取账号信息
const getIncome = () =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceSettlementService()
    .getEnterpriseServiceSettlementInfo(
      UserTools.userRoles.roles[localStorage.getItem("userRoleIndex")]
        .enterpriseInfo.entityId,
      UserTools.getUserId()
    );
//更新
const updateAccountInfo = (SettlementAccountInfo) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceSettlementService()
    .updateSettlementAccountInfo(SettlementAccountInfo);
//新增
const addAccountInfo = (SettlementAccountInfo) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceSettlementService()
    .addSettlementAccountInfo(SettlementAccountInfo);
const getBillAll = () => {
  const pageSpec = new PageBlockAccessSpec();
  pageSpec.pageSize = 3;
  return DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceSettlementService()
    .getSettlementBillInfos(
      UserTools.userRoles.roles[localStorage.getItem("userRoleIndex")]
        .enterpriseInfo.entityId,
      UserTools.getUserId(),
      pageSpec
    );
};
const getBILLId = (billId) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceSettlementService()
    .getSettlementBillInfoById(billId);
const getSeason = (Season) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceSettlementService()
    .getSettlementBillInfoBySeasonId(
      UserTools.userRoles.roles[localStorage.getItem("userRoleIndex")]
        .enterpriseInfo.entityId,
      UserTools.getUserId(),
      Season
    );
const requestToAdjustBill = (billAdjustRequest) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceSettlementService()
    .requestToAdjustBill(billAdjustRequest);
const confirmBillById = (seasonId: string | number, billId: string | number) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceSettlementService()
    .confirmBillById(
      UserTools.userRoles.roles[localStorage.getItem("userRoleIndex")]
        .enterpriseInfo.entityId,
      UserTools.getUserId(),
      seasonId,
      billId
    );
const getBillStatus = (syncStatus) => {
  const pageSpec = new PageBlockAccessSpec();
  pageSpec.pageSize = 3;
  return DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceSettlementService()
    .getSettlementBillInfosBySyncStatus(
      UserTools.userRoles.roles[localStorage.getItem("userRoleIndex")]
        .enterpriseInfo.entityId,
      UserTools.getUserId(),
      syncStatus,
      pageSpec
    );
};
const getDetails = (id: number) => {
  const pageSpec = new PageBlockAccessSpec();
  pageSpec.pageSize = 3;
  return DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceSettlementService()
    .findEnterpriseServiceRequestsToSettleByPeriod(
      id,
      UserTools.userRoles.roles[localStorage.getItem("userRoleIndex")]
        .enterpriseInfo.entityId,
      UserTools.getUserId(),
      pageSpec
    );
};
const getFinance = () =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceRequestService()
    .getServingEntityServiceStats(
      UserTools.userRoles.roles[
        localStorage.getItem("userRoleIndex")
      ].enterpriseInfo.entityId
    );
export default {
  getIncome,
  updateAccountInfo,
  addAccountInfo,
  getBillAll,
  getBILLId,
  getSeason,
  requestToAdjustBill,
  confirmBillById,
  getBillStatus,
  getDetails,
  getFinance,
};
