// @ts-nocheck
import { AbstractQuestionStats } from "./AbstractQuestionStats";
import { CoworkSimpleView } from "../../cowork/CoworkSimpleView";
import { CoworkStats } from "../../cowork/CoworkStats";

export class QuestionStats extends AbstractQuestionStats {
  constructor() {
    super();
  }

  addInfoToView(arg0: CoworkSimpleView | null) {
    // Manual Code Here
  }
  updateCoworkStats(arg0: CoworkStats | null) {
    // Manual Code Here
  }
}
