/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkSimpleView } from "./CoworkSimpleView";
import { CoworkStatsAccessor } from "./CoworkStatsAccessor";
import { CoworkStats } from "./CoworkStats";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractGeneralCoworkStats
  extends AbstractSerializable
  implements CoworkStats
{
  totalComments: number;
  sTotalComments: number;
  calls: number;
  totalVisits: number;
  totalApprovals: number;
  totalDisapprovals: number;
  coworkStatsAccessor: CoworkStatsAccessor;

  constructor() {
    super();
  }

  public getTotalComments(): number {
    return this.totalComments;
  }

  public setTotalComments(totalComments: number) {
    this.totalComments = totalComments;
  }

  public getSTotalComments(): number {
    return this.sTotalComments;
  }

  public setSTotalComments(sTotalComments: number) {
    this.sTotalComments = sTotalComments;
  }

  public getCalls(): number {
    return this.calls;
  }

  public setCalls(calls: number) {
    this.calls = calls;
  }

  public getTotalVisits(): number {
    return this.totalVisits;
  }

  public setTotalVisits(totalVisits: number) {
    this.totalVisits = totalVisits;
  }

  public getTotalApprovals(): number {
    return this.totalApprovals;
  }

  public setTotalApprovals(totalApprovals: number) {
    this.totalApprovals = totalApprovals;
  }

  public getTotalDisapprovals(): number {
    return this.totalDisapprovals;
  }

  public setTotalDisapprovals(totalDisapprovals: number) {
    this.totalDisapprovals = totalDisapprovals;
  }

  public getCoworkStatsAccessor(): CoworkStatsAccessor {
    return this.coworkStatsAccessor;
  }

  public setCoworkStatsAccessor(coworkStatsAccessor: CoworkStatsAccessor) {
    this.coworkStatsAccessor = coworkStatsAccessor;
  }

  abstract addInfoToView(arg0: CoworkSimpleView | null);

  abstract updateCoworkStats(arg0: CoworkStats | null);

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const totalComments = json["totalComments"];
    if (totalComments != null) {
      this.setTotalComments(totalComments);
    }
    const sTotalComments = json["sTotalComments"];
    if (sTotalComments != null) {
      this.setSTotalComments(sTotalComments);
    }
    const calls = json["calls"];
    if (calls != null) {
      this.setCalls(calls);
    }
    const totalVisits = json["totalVisits"];
    if (totalVisits != null) {
      this.setTotalVisits(totalVisits);
    }
    const totalApprovals = json["totalApprovals"];
    if (totalApprovals != null) {
      this.setTotalApprovals(totalApprovals);
    }
    const totalDisapprovals = json["totalDisapprovals"];
    if (totalDisapprovals != null) {
      this.setTotalDisapprovals(totalDisapprovals);
    }
    const coworkStatsAccessor = json["coworkStatsAccessor"];
    if (coworkStatsAccessor != null) {
      const convertedCoworkStatsAccessor0 = Serializer.fromJson(
        coworkStatsAccessor,
        factory
      );
      this.setCoworkStatsAccessor(convertedCoworkStatsAccessor0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const totalComments = this.getTotalComments();
    if (totalComments != null) {
      json["totalComments"] = totalComments;
    }
    const sTotalComments = this.getSTotalComments();
    if (sTotalComments != null) {
      json["sTotalComments"] = sTotalComments;
    }
    const calls = this.getCalls();
    if (calls != null) {
      json["calls"] = calls;
    }
    const totalVisits = this.getTotalVisits();
    if (totalVisits != null) {
      json["totalVisits"] = totalVisits;
    }
    const totalApprovals = this.getTotalApprovals();
    if (totalApprovals != null) {
      json["totalApprovals"] = totalApprovals;
    }
    const totalDisapprovals = this.getTotalDisapprovals();
    if (totalDisapprovals != null) {
      json["totalDisapprovals"] = totalDisapprovals;
    }
    const coworkStatsAccessor = this.getCoworkStatsAccessor();
    if (coworkStatsAccessor != null) {
      const convertedCoworkStatsAccessor0 = Serializer.getJson(
        coworkStatsAccessor,
        JsonTypeTagType.CLASS
      );
      json["coworkStatsAccessor"] = convertedCoworkStatsAccessor0;
    }

    if (includeTypeTag) {
      json["__type"] = "GeneralCoworkStats";
    }
  }

  public getTypeId(): string {
    return "GeneralCoworkStats";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.GeneralCoworkStats";
  }
}
