/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractEnterpriseTradableInfo} from '../../tradable/AbstractEnterpriseTradableInfo'
import {CoworkInfo} from '../../../cowork/CoworkInfo'
import {CoworkSimpleView} from '../../../cowork/CoworkSimpleView'
import {CoworkStats} from '../../../cowork/CoworkStats'
import {CoworkType} from '../../../cowork/CoworkType'
import {EnterpriseServiceStatus} from './EnterpriseServiceStatus'
import {EnterpriseServiceType} from '../EnterpriseServiceType'
import {EntityInfo} from '../../../entity/EntityInfo'
import {GeoHash} from '../../../geolocation/GeoHash'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {PeriodType} from '../../../PeriodType'
import {SelectTimes} from '../../../cowork/SelectTimes'
import {ServiceDeliveryType} from '../ServiceDeliveryType'
import {ServiceProviderType} from '../ServiceProviderType'
import {ServiceUseType} from '../ServiceUseType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export abstract class AbstractEnterpriseServiceTradableInfo extends AbstractEnterpriseTradableInfo  {
    serviceType: EnterpriseServiceType;
    global: boolean;
    servicePackage: boolean;
    seriesId: (number | string);
    packageOnly: boolean;
    serviceDeliveryType: ServiceDeliveryType;
    serviceUseType: ServiceUseType;
    serviceProviderType: ServiceProviderType;
    offered: boolean;
    otcPriced: boolean;
    startDay: number;
    endDay: number;
    periodType: PeriodType;
    servicePeriod: number;
    status: EnterpriseServiceStatus;
    declare test: boolean;

    constructor() {
    	super();
    }

    public getServiceType(): EnterpriseServiceType {
    	return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
    	this.serviceType = serviceType;
    }

    public isGlobal(): boolean {
    	return this.global;
    }

    public setGlobal(global: boolean) {
    	this.global = global;
    }

    public isServicePackage(): boolean {
    	return this.servicePackage;
    }

    public setServicePackage(servicePackage: boolean) {
    	this.servicePackage = servicePackage;
    }

    public getSeriesId(): (number | string) {
    	return this.seriesId;
    }

    public setSeriesId(seriesId: (number | string)) {
    	this.seriesId = seriesId;
    }

    public isPackageOnly(): boolean {
    	return this.packageOnly;
    }

    public setPackageOnly(packageOnly: boolean) {
    	this.packageOnly = packageOnly;
    }

    public getServiceDeliveryType(): ServiceDeliveryType {
    	return this.serviceDeliveryType;
    }

    public setServiceDeliveryType(serviceDeliveryType: ServiceDeliveryType) {
    	this.serviceDeliveryType = serviceDeliveryType;
    }

    public getServiceUseType(): ServiceUseType {
    	return this.serviceUseType;
    }

    public setServiceUseType(serviceUseType: ServiceUseType) {
    	this.serviceUseType = serviceUseType;
    }

    public getServiceProviderType(): ServiceProviderType {
    	return this.serviceProviderType;
    }

    public setServiceProviderType(serviceProviderType: ServiceProviderType) {
    	this.serviceProviderType = serviceProviderType;
    }

    public isOffered(): boolean {
    	return this.offered;
    }

    public setOffered(offered: boolean) {
    	this.offered = offered;
    }

    public isOtcPriced(): boolean {
    	return this.otcPriced;
    }

    public setOtcPriced(otcPriced: boolean) {
    	this.otcPriced = otcPriced;
    }

    public getStartDay(): number {
    	return this.startDay;
    }

    public setStartDay(startDay: number) {
    	this.startDay = startDay;
    }

    public getEndDay(): number {
    	return this.endDay;
    }

    public setEndDay(endDay: number) {
    	this.endDay = endDay;
    }

    public getPeriodType(): PeriodType {
    	return this.periodType;
    }

    public setPeriodType(periodType: PeriodType) {
    	this.periodType = periodType;
    }

    public getServicePeriod(): number {
    	return this.servicePeriod;
    }

    public setServicePeriod(servicePeriod: number) {
    	this.servicePeriod = servicePeriod;
    }

    public getStatus(): EnterpriseServiceStatus {
    	return this.status;
    }

    public setStatus(status: EnterpriseServiceStatus) {
    	this.status = status;
    }

    public isTest(): boolean {
    	return this.test;
    }

    public setTest(test: boolean) {
    	this.test = test;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let global = json["global"];
        if (global != null) {
            this.setGlobal(global);
        }
        let servicePackage = json["servicePackage"];
        if (servicePackage != null) {
            this.setServicePackage(servicePackage);
        }
        let seriesId = json["seriesId"];
        if (seriesId != null) {
            this.setSeriesId(seriesId);
        }
        let packageOnly = json["packageOnly"];
        if (packageOnly != null) {
            this.setPackageOnly(packageOnly);
        }
        let serviceDeliveryType = json["serviceDeliveryType"];
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[<string>serviceDeliveryType];
            this.setServiceDeliveryType(convertedServiceDeliveryType0);
        }
        let serviceUseType = json["serviceUseType"];
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[<string>serviceUseType];
            this.setServiceUseType(convertedServiceUseType0);
        }
        let serviceProviderType = json["serviceProviderType"];
        if (serviceProviderType != null) {
            let convertedServiceProviderType0 = ServiceProviderType[<string>serviceProviderType];
            this.setServiceProviderType(convertedServiceProviderType0);
        }
        let offered = json["offered"];
        if (offered != null) {
            this.setOffered(offered);
        }
        let otcPriced = json["otcPriced"];
        if (otcPriced != null) {
            this.setOtcPriced(otcPriced);
        }
        let startDay = json["startDay"];
        if (startDay != null) {
            this.setStartDay(startDay);
        }
        let endDay = json["endDay"];
        if (endDay != null) {
            this.setEndDay(endDay);
        }
        let periodType = json["periodType"];
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[<string>periodType];
            this.setPeriodType(convertedPeriodType0);
        }
        let servicePeriod = json["servicePeriod"];
        if (servicePeriod != null) {
            this.setServicePeriod(servicePeriod);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = EnterpriseServiceStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let test = json["test"];
        if (test != null) {
            this.setTest(test);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let global = this.isGlobal();
        if (global != null) {
            json["global"] = global;
        }
        let servicePackage = this.isServicePackage();
        if (servicePackage != null) {
            json["servicePackage"] = servicePackage;
        }
        let seriesId = this.getSeriesId();
        if (seriesId != null) {
            json["seriesId"] = String(seriesId);
        }
        let packageOnly = this.isPackageOnly();
        if (packageOnly != null) {
            json["packageOnly"] = packageOnly;
        }
        let serviceDeliveryType = this.getServiceDeliveryType();
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[serviceDeliveryType];
            json["serviceDeliveryType"] = convertedServiceDeliveryType0;
        }
        let serviceUseType = this.getServiceUseType();
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[serviceUseType];
            json["serviceUseType"] = convertedServiceUseType0;
        }
        let serviceProviderType = this.getServiceProviderType();
        if (serviceProviderType != null) {
            let convertedServiceProviderType0 = ServiceProviderType[serviceProviderType];
            json["serviceProviderType"] = convertedServiceProviderType0;
        }
        let offered = this.isOffered();
        if (offered != null) {
            json["offered"] = offered;
        }
        let otcPriced = this.isOtcPriced();
        if (otcPriced != null) {
            json["otcPriced"] = otcPriced;
        }
        let startDay = this.getStartDay();
        if (startDay != null) {
            json["startDay"] = startDay;
        }
        let endDay = this.getEndDay();
        if (endDay != null) {
            json["endDay"] = endDay;
        }
        let periodType = this.getPeriodType();
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[periodType];
            json["periodType"] = convertedPeriodType0;
        }
        let servicePeriod = this.getServicePeriod();
        if (servicePeriod != null) {
            json["servicePeriod"] = servicePeriod;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = EnterpriseServiceStatus[status];
            json["status"] = convertedStatus0;
        }
        let test = this.isTest();
        if (test != null) {
            json["test"] = test;
        }

        if (includeTypeTag) {
            json["__type"] = "EnterpriseServiceTradableInfo";
        }
    }


    public getTypeId(): string {
    	return "EnterpriseServiceTradableInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceTradableInfo";
    }
}
