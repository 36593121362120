// @ts-nocheck
import { AbstractOfficialEndorsementProfileAspect } from "./AbstractOfficialEndorsementProfileAspect";
import { ProfileAspectType } from "./ProfileAspectType";
import { TextType } from "../../TextType";

export class OfficialEndorsementProfileAspect extends AbstractOfficialEndorsementProfileAspect {
  constructor() {
    super();
  }

  getProfileAspectType(): ProfileAspectType {
    // Manual Code Here
  }
  getTextType(): TextType {
    // Manual Code Here
  }
  getText(): string {
    // Manual Code Here
  }
}
