/**
 * 自动生成， 请不要手工修改！
 */

import { CoworkType } from "../cowork/CoworkType";
import { EntityInfo } from "../entity/EntityInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { LocalChat } from "./LocalChat";
import { REPrizeStatus } from "../redenvelope/REPrizeStatus";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class LocalShared extends LocalChat {
  sharedId: number | string;
  sharedType: CoworkType;
  concreteType: string;
  sponsorInfo: EntityInfo;
  prizeStatus: REPrizeStatus;
  reNotified: boolean;

  constructor() {
    super();
  }

  public getSharedId(): number | string {
    return this.sharedId;
  }

  public setSharedId(sharedId: number | string) {
    this.sharedId = sharedId;
  }

  public getSharedType(): CoworkType {
    return this.sharedType;
  }

  public setSharedType(sharedType: CoworkType) {
    this.sharedType = sharedType;
  }

  public getConcreteType(): string {
    return this.concreteType;
  }

  public setConcreteType(concreteType: string) {
    this.concreteType = concreteType;
  }

  public getSponsorInfo(): EntityInfo {
    return this.sponsorInfo;
  }

  public setSponsorInfo(sponsorInfo: EntityInfo) {
    this.sponsorInfo = sponsorInfo;
  }

  public getPrizeStatus(): REPrizeStatus {
    return this.prizeStatus;
  }

  public setPrizeStatus(prizeStatus: REPrizeStatus) {
    this.prizeStatus = prizeStatus;
  }

  public isReNotified(): boolean {
    return this.reNotified;
  }

  public setReNotified(reNotified: boolean) {
    this.reNotified = reNotified;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const sharedId = json["sharedId"];
    if (sharedId != null) {
      this.setSharedId(sharedId);
    }
    const sharedType = json["sharedType"];
    if (sharedType != null) {
      const convertedSharedType0 = CoworkType[<string>sharedType];
      this.setSharedType(convertedSharedType0);
    }
    const concreteType = json["concreteType"];
    if (concreteType != null) {
      this.setConcreteType(concreteType);
    }
    const sponsorInfo = json["sponsorInfo"];
    if (sponsorInfo != null) {
      const convertedSponsorInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        sponsorInfo,
        "__type",
        factory
      );
      this.setSponsorInfo(convertedSponsorInfo0);
    }
    const prizeStatus = json["prizeStatus"];
    if (prizeStatus != null) {
      const convertedPrizeStatus0 = REPrizeStatus[<string>prizeStatus];
      this.setPrizeStatus(convertedPrizeStatus0);
    }
    const reNotified = json["reNotified"];
    if (reNotified != null) {
      this.setReNotified(reNotified);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const sharedId = this.getSharedId();
    if (sharedId != null) {
      json["sharedId"] = String(sharedId);
    }
    const sharedType = this.getSharedType();
    if (sharedType != null) {
      const convertedSharedType0 = CoworkType[sharedType];
      json["sharedType"] = convertedSharedType0;
    }
    const concreteType = this.getConcreteType();
    if (concreteType != null) {
      json["concreteType"] = concreteType;
    }
    const sponsorInfo = this.getSponsorInfo();
    if (sponsorInfo != null) {
      const convertedSponsorInfo0 = sponsorInfo.getJson(JsonTypeTagType.TYPE);
      json["sponsorInfo"] = convertedSponsorInfo0;
    }
    const prizeStatus = this.getPrizeStatus();
    if (prizeStatus != null) {
      const convertedPrizeStatus0 = REPrizeStatus[prizeStatus];
      json["prizeStatus"] = convertedPrizeStatus0;
    }
    const reNotified = this.isReNotified();
    if (reNotified != null) {
      json["reNotified"] = reNotified;
    }

    if (includeTypeTag) {
      json["__type"] = "LocalShared";
    }
  }

  public getTypeId(): string {
    return "LocalShared";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.geolocation.LocalShared";
  }
}
