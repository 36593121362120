/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractCompositeCoworkInfo } from "../cowork/AbstractCompositeCoworkInfo";
import { ActivityStats } from "./ActivityStats";
import { ActivityUserInfo } from "./ActivityUserInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { PlatformActivity } from "./PlatformActivity";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractCompositePlatformActivityInfo extends AbstractCompositeCoworkInfo<
  PlatformActivity,
  ActivityStats,
  ActivityUserInfo,
  AbstractCompositePlatformActivityInfo
> {
  constructor() {
    super();
  }

  abstract getVisits(): number;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.activity.CompositePlatformActivityInfo";
  }
}
