/**
 * 自动生成， 请不要手工修改！
 */


export enum RoleType {
    GW_TEAM,
    FIN_ADMIN,
    EDITOR,
    SYSTEM,
    VISITOR,
    REGISTERED,
    QLL_MEMBER,
    QLL_VIP,
    BLACK_LISTED,
    WATCHER,
    ADMIN,
    CREATOR,
    CHILD,
    EMPLOYEE,
    CUSTOMER,
    ENTITY_OWNER,
    OBJECT_OWNER,
    FRIEND,
    EXPERT,
    OFFICIAL_REP,
    E_DIRECTOR,
    CEO,
    MEMBER,
    MEMBER2,
    HR,
    E_ASSOC_DIRECTOR,
    SENIOR_PARTNER,
    PARTNER,
    LAWYER,
    P_LAWYER,
    GRID_DIRECTOR,
    GRID_MANAGER,
    GRID_OFFICER,
    GRID_ADMIN,
    GRID_INFOER,
    GRID_CITIZEN,
    TOPIC_CHIEF_EDITOR,
    TOPIC_EDITOR,
    TOPIC_EDITOR_IN_REVIEW,
    A_PRESIDENT,
    A_STANDING_VP,
    A_VICE_PRESIDENT,
    A_SECRETARY_GEN,
    A_SECRETARY,
    A_DIRECTOR,
    A_VP_IN_REVIEW,
    A_MEMBER,
    A_MEMBER_IN_REVIEW,
    A_E_MEMBER,
    A_MEMBER_E,
    A_MEMBER_REP,
    REGION_SALES,
    REGION_MANAGER,
    REGION_FIN_ADMIN,
    REGION_ADMIN,
    P_ADMIN
}
