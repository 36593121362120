import imgConstant from "../config/img";
export default class CheckType {
  static checkFileType(link: string): number {
    return /\w.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4)$/i.test(link)
      ? 3
      : /\w.(png|jpg|jpeg|svg|webp|gif|bmp)$/i.test(link)
      ? 4
      : /\w.(mp3)$/i.test(link)
      ? 2
      : link
      ? 1
      : 0;
  }
  static checkImgVideo(link) {
    return /\w.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4|png|jpg|jpeg|svg|webp|gif|bmp)$/.test(
      link
    )
      ? true
      : false;
  }
  static isImg(link: string): string {
    return link.includes("oss-cn-hangzhou.aliyuncs.com/")
      ? link
      : imgConstant + link;
  }
}
