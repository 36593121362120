/**
 * Created by Zhonghua on 15/11/2016.
 */

import {MessageType} from "../message/MessageContentType";
import {MessageResponseType} from "../message/MessageResponseType";
import { JsonTypeTagType } from "../serialize/JsonTypeTagType";
import { TypeFactory } from "../serialize/TypeFactory";
import { AbstractServiceRequest } from "./AbstractServiceRequest";
import { EventResponseStatus } from "@/services/common/EventResponseStatus";
import { ServiceResponse } from "@/services/common/ServiceResponse";

export class EventRequest extends AbstractServiceRequest {
  public static createEventRequest(
    serviceName: string,
    methodName: string,
    parameterValues: any[]
  ): EventRequest {
    const eventRequest = new EventRequest();
    eventRequest.setServiceName(serviceName);
    eventRequest.setMethodName(methodName);
    eventRequest.setParameterValues(parameterValues);
    eventRequest.fillId();
    return eventRequest;
  }

  public static isDataResponse(serviceResponse: ServiceResponse): boolean {
    if (serviceResponse) {
      const returnedValue = serviceResponse.getReturnValue();
      return (
        returnedValue &&
        !(returnedValue instanceof Number) &&
        typeof returnedValue != "number"
      );
    } else {
      return false;
    }
  }

  private _eventResponseStatus: EventResponseStatus;
  private cancelled: boolean;

  constructor() {
    super();
  }

  public isCancelled(): boolean {
    return this.cancelled;
  }

  public setCancelled(cancelled: boolean): void {
    this.cancelled = cancelled;
  }

    get eventResponseStatus(): EventResponseStatus {
        return this._eventResponseStatus;
    }

    set eventResponseStatus(value: EventResponseStatus) {
        this._eventResponseStatus = value;
    }

    public getContentType(): MessageType {
        return MessageType.EVENT_SUBSCRIBE;
    }

    public getResponseType(): MessageResponseType {
        return MessageResponseType.MULTIPLE;
    }

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag: boolean) {
    super.fillToJson(json, includeTypeTag);

    json["__type"] = "Event";
  }

  public getTypeId(): string {
    return "Event";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.service.spi.EventRequest";
  }
}
