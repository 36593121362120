/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../../common/AbstractClientServiceAdapter'
import {ActivityView} from '../../datatype/activity/ActivityView'
import {AnswerContent} from '../../datatype/ipsphere/qa/AnswerContent'
import {BiConsumer} from '../../util/BiConsumer'
import {CompositeCoworkView} from '../../datatype/cowork/CompositeCoworkView'
import {CoworkType} from '../../datatype/cowork/CoworkType'
import {EliteInfo} from '../../datatype/entity/user/EliteInfo'
import {EliteState} from '../../datatype/entity/user/EliteState'
import {EnterpriseCategory} from '../../datatype/entity/enterprise/EnterpriseCategory'
import {EnterpriseServiceOrder} from '../../datatype/business/order/EnterpriseServiceOrder'
import {EnterpriseServiceTradeInfo} from '../../datatype/business/service/trade/EnterpriseServiceTradeInfo'
import {EnterpriseType} from '../../datatype/entity/enterprise/EnterpriseType'
import {EntityInfo} from '../../datatype/entity/EntityInfo'
import {GeneralServiceRequestUserAdView} from '../../datatype/userad/general/GeneralServiceRequestUserAdView'
import {GeoEntityDesc} from '../../datatype/geolocation/GeoEntityDesc'
import {MineQuerySpec} from '../../datatype/query/mine/MineQuerySpec'
import {MineService} from '../../api/mine/MineService'
import {OrderQuerySpec} from '../../datatype/business/order/OrderQuerySpec'
import {PageableData} from '../../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../../datatype/paging/PageBlockAccessSpec'
import {QuestionOverview} from '../../datatype/ipsphere/qa/QuestionOverview'
import {ReviewOverview} from '../../datatype/ipsphere/review/ReviewOverview'
import {ServiceConsumers} from '../../util/ServiceConsumers'
import {ServiceRequest} from '../../common/ServiceRequest'
import {SessionContext} from '../../session/SessionContext'
import {SharedInfoView} from '../../datatype/share/SharedInfoView'
import {SimpleEntityView} from '../../datatype/entity/view/SimpleEntityView'
import {TradeQuerySpec} from '../../datatype/business/general/trade/TradeQuerySpec'


export class MineServiceProxy extends AbstractClientServiceAdapter implements MineService {

    public constructor() {
        super("com.gong_wei.service.api.mine.MineService");
    }

    public applyForElite(sessionContext: (SessionContext | null), eliteInfo: (EliteInfo | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "applyForElite", [sessionContext, eliteInfo]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findActiveEnterpriseServicePackageTrades(sessionContext: (SessionContext | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceTradeInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "findActiveEnterpriseServicePackageTrades", [sessionContext, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findActiveEnterpriseServiceTrades(sessionContext: (SessionContext | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceTradeInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "findActiveEnterpriseServiceTrades", [sessionContext, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceOrders(sessionContext: (SessionContext | null), userId: (number | string | null), orderQuerySpec: (OrderQuerySpec | null), resulter: BiConsumer<PageableData<EnterpriseServiceOrder>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "findEnterpriseServiceOrders", [sessionContext, userId, orderQuerySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resulter);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServicePackageTrades(sessionContext: (SessionContext | null), userId: (number | string | null), tradeQuerySpec: (TradeQuerySpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceTradeInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "findEnterpriseServicePackageTrades", [sessionContext, userId, tradeQuerySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceTrades(sessionContext: (SessionContext | null), userId: (number | string | null), tradeQuerySpec: (TradeQuerySpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceTradeInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "findEnterpriseServiceTrades", [sessionContext, userId, tradeQuerySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getApprovedCoworks(sessionContext: (SessionContext | null), userId: (number | string | null), coworkType: (CoworkType | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<CompositeCoworkView<any,any>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getApprovedCoworks", [sessionContext, userId, coworkType, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getCollectedAnswers(sessionContext: (SessionContext | null), userId: (number | string | null), querySpec: (MineQuerySpec | null), resultHandler: BiConsumer<PageableData<AnswerContent>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getCollectedAnswers", [sessionContext, userId, querySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getCollectedReviews(sessionContext: (SessionContext | null), userId: (number | string | null), querySpec: (MineQuerySpec | null), resultHandler: BiConsumer<PageableData<ReviewOverview>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getCollectedReviews", [sessionContext, userId, querySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getConcernedActivities(sessionContext: (SessionContext | null), userId: (number | string | null), pageAccessSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<ActivityView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getConcernedActivities", [sessionContext, userId, pageAccessSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getConcernedCoops(sessionContext: (SessionContext | null), userId: (number | string | null), pageAccessSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeneralServiceRequestUserAdView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getConcernedCoops", [sessionContext, userId, pageAccessSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getConcernedQuestion(sessionContext: (SessionContext | null), userId: (number | string | null), querySpec: (MineQuerySpec | null), resultHandler: BiConsumer<PageableData<QuestionOverview>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getConcernedQuestion", [sessionContext, userId, querySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEliteState(sessionContext: (SessionContext | null), userId: (number | string | null), resultHandler: BiConsumer<EliteState, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getEliteState", [sessionContext, userId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEnterprisesInMyScope(sessionContext: (SessionContext | null), userId: (number | string | null), pageAccessSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getEnterprisesInMyScope", [sessionContext, userId, pageAccessSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getManagedEnterprises(sessionContext: (SessionContext | null), userId: (number | string | null), business: (boolean | null), enterpriseType: (EnterpriseType | null), resultHandler: BiConsumer<Array<SimpleEntityView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getManagedEnterprises", [sessionContext, userId, business, enterpriseType]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getManagedOrCreatedEnterpriseDescs(sessionContext: (SessionContext | null), enterpriseCategory: (EnterpriseCategory | null), resultHandler: BiConsumer<Array<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getManagedOrCreatedEnterpriseDescs", [sessionContext, enterpriseCategory]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getManagedOrCreatedEnterprises(sessionContext: (SessionContext | null), enterpriseCategory: (EnterpriseCategory | null), resultHandler: BiConsumer<Array<EntityInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getManagedOrCreatedEnterprises", [sessionContext, enterpriseCategory]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getMyCreationAnswers(sessionContext: (SessionContext | null), userId: (number | string | null), querySpec: (MineQuerySpec | null), resultHandler: BiConsumer<PageableData<AnswerContent>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getMyCreationAnswers", [sessionContext, userId, querySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getMyCreationCoops(sessionContext: (SessionContext | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeneralServiceRequestUserAdView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getMyCreationCoops", [sessionContext, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getMyCreationQuestions(sessionContext: (SessionContext | null), userId: (number | string | null), querySpec: (MineQuerySpec | null), resultHandler: BiConsumer<PageableData<QuestionOverview>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getMyCreationQuestions", [sessionContext, userId, querySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getMyCreationReviews(sessionContext: (SessionContext | null), userId: (number | string | null), querySpec: (MineQuerySpec | null), resultHandler: BiConsumer<PageableData<ReviewOverview>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getMyCreationReviews", [sessionContext, userId, querySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getMyCreationSharedInfos(sessionContext: (SessionContext | null), userId: (number | string | null), querySpec: (MineQuerySpec | null), resultHandler: BiConsumer<PageableData<SharedInfoView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getMyCreationSharedInfos", [sessionContext, userId, querySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getMyCreations(sessionContext: (SessionContext | null), userId: (number | string | null), querySpec: (MineQuerySpec | null), resultHandler: BiConsumer<PageableData<CompositeCoworkView<any,any>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "getMyCreations", [sessionContext, userId, querySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public quitElite(sessionContext: (SessionContext | null), userId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.mine.MineService", "quitElite", [sessionContext, userId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

}
