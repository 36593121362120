/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class KeywordItem extends AbstractSerializable {
  keyword: string;
  totalApproval: number;
  approved: boolean;

  constructor() {
    super();
  }

  public getKeyword(): string {
    return this.keyword;
  }

  public setKeyword(keyword: string) {
    this.keyword = keyword;
  }

  public getTotalApproval(): number {
    return this.totalApproval;
  }

  public setTotalApproval(totalApproval: number) {
    this.totalApproval = totalApproval;
  }

  public getApproved(): boolean {
    return this.approved;
  }

  public setApproved(approved: boolean) {
    this.approved = approved;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const keyword = json["keyword"];
    if (keyword != null) {
      this.setKeyword(keyword);
    }
    const totalApproval = json["totalApproval"];
    if (totalApproval != null) {
      this.setTotalApproval(totalApproval);
    }
    const approved = json["approved"];
    if (approved != null) {
      this.setApproved(approved);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const keyword = this.getKeyword();
    if (keyword != null) {
      json["keyword"] = keyword;
    }
    const totalApproval = this.getTotalApproval();
    if (totalApproval != null) {
      json["totalApproval"] = totalApproval;
    }
    const approved = this.getApproved();
    if (approved != null) {
      json["approved"] = approved;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.expertise.KeywordItem";
  }
}
