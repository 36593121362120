/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {CouponType} from './CouponType'
import {CouponUseType} from './CouponUseType'
import {EntityInfo} from '../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class QllCoupon extends AbstractSerializable  {
    couponId: (number | string);
    templateId: (number | string);
    couponType: CouponType;
    couponUseType: CouponUseType;
    pkgCouponId: (number | string);
    packaged: boolean;
    imgUrl: string;
    title: string;
    tradableId: (number | string);
    tradableName: string;
    expireDay: number;
    ownerInfo: EntityInfo;
    refererInfo: EntityInfo;
    orderId: (number | string);
    useTime: (number | string);
    used: boolean;
    cancelled: boolean;
    refererAwarded: boolean;
    createTime: (number | string);
    userAmount: number;
    refererAmount: number;
    minOrderAmount: number;
    couponComponents: Array<QllCoupon>;

    constructor() {
    	super();
    }

    public getCouponId(): (number | string) {
    	return this.couponId;
    }

    public setCouponId(couponId: (number | string)) {
    	this.couponId = couponId;
    }

    public getTemplateId(): (number | string) {
    	return this.templateId;
    }

    public setTemplateId(templateId: (number | string)) {
    	this.templateId = templateId;
    }

    public getCouponType(): CouponType {
    	return this.couponType;
    }

    public setCouponType(couponType: CouponType) {
    	this.couponType = couponType;
    }

    public getCouponUseType(): CouponUseType {
    	return this.couponUseType;
    }

    public setCouponUseType(couponUseType: CouponUseType) {
    	this.couponUseType = couponUseType;
    }

    public getPkgCouponId(): (number | string) {
    	return this.pkgCouponId;
    }

    public setPkgCouponId(pkgCouponId: (number | string)) {
    	this.pkgCouponId = pkgCouponId;
    }

    public isPackaged(): boolean {
    	return this.packaged;
    }

    public setPackaged(packaged: boolean) {
    	this.packaged = packaged;
    }

    public getImgUrl(): string {
    	return this.imgUrl;
    }

    public setImgUrl(imgUrl: string) {
    	this.imgUrl = imgUrl;
    }

    public getTitle(): string {
    	return this.title;
    }

    public setTitle(title: string) {
    	this.title = title;
    }

    public getTradableId(): (number | string) {
    	return this.tradableId;
    }

    public setTradableId(tradableId: (number | string)) {
    	this.tradableId = tradableId;
    }

    public getTradableName(): string {
    	return this.tradableName;
    }

    public setTradableName(tradableName: string) {
    	this.tradableName = tradableName;
    }

    public getExpireDay(): number {
    	return this.expireDay;
    }

    public setExpireDay(expireDay: number) {
    	this.expireDay = expireDay;
    }

    public getOwnerInfo(): EntityInfo {
    	return this.ownerInfo;
    }

    public setOwnerInfo(ownerInfo: EntityInfo) {
    	this.ownerInfo = ownerInfo;
    }

    public getRefererInfo(): EntityInfo {
    	return this.refererInfo;
    }

    public setRefererInfo(refererInfo: EntityInfo) {
    	this.refererInfo = refererInfo;
    }

    public getOrderId(): (number | string) {
    	return this.orderId;
    }

    public setOrderId(orderId: (number | string)) {
    	this.orderId = orderId;
    }

    public getUseTime(): (number | string) {
    	return this.useTime;
    }

    public setUseTime(useTime: (number | string)) {
    	this.useTime = useTime;
    }

    public isUsed(): boolean {
    	return this.used;
    }

    public setUsed(used: boolean) {
    	this.used = used;
    }

    public isCancelled(): boolean {
    	return this.cancelled;
    }

    public setCancelled(cancelled: boolean) {
    	this.cancelled = cancelled;
    }

    public isRefererAwarded(): boolean {
    	return this.refererAwarded;
    }

    public setRefererAwarded(refererAwarded: boolean) {
    	this.refererAwarded = refererAwarded;
    }

    public getCreateTime(): (number | string) {
    	return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
    	this.createTime = createTime;
    }

    public getUserAmount(): number {
    	return this.userAmount;
    }

    public setUserAmount(userAmount: number) {
    	this.userAmount = userAmount;
    }

    public getRefererAmount(): number {
    	return this.refererAmount;
    }

    public setRefererAmount(refererAmount: number) {
    	this.refererAmount = refererAmount;
    }

    public getMinOrderAmount(): number {
    	return this.minOrderAmount;
    }

    public setMinOrderAmount(minOrderAmount: number) {
    	this.minOrderAmount = minOrderAmount;
    }

    public getCouponComponents(): Array<QllCoupon> {
    	return this.couponComponents;
    }

    public setCouponComponents(couponComponents: Array<QllCoupon>) {
    	this.couponComponents = couponComponents;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let couponId = json["couponId"];
        if (couponId != null) {
            this.setCouponId(couponId);
        }
        let templateId = json["templateId"];
        if (templateId != null) {
            this.setTemplateId(templateId);
        }
        let couponType = json["couponType"];
        if (couponType != null) {
            let convertedCouponType0 = CouponType[<string>couponType];
            this.setCouponType(convertedCouponType0);
        }
        let couponUseType = json["couponUseType"];
        if (couponUseType != null) {
            let convertedCouponUseType0 = CouponUseType[<string>couponUseType];
            this.setCouponUseType(convertedCouponUseType0);
        }
        let pkgCouponId = json["pkgCouponId"];
        if (pkgCouponId != null) {
            this.setPkgCouponId(pkgCouponId);
        }
        let packaged = json["packaged"];
        if (packaged != null) {
            this.setPackaged(packaged);
        }
        let imgUrl = json["imgUrl"];
        if (imgUrl != null) {
            this.setImgUrl(imgUrl);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let tradableId = json["tradableId"];
        if (tradableId != null) {
            this.setTradableId(tradableId);
        }
        let tradableName = json["tradableName"];
        if (tradableName != null) {
            this.setTradableName(tradableName);
        }
        let expireDay = json["expireDay"];
        if (expireDay != null) {
            this.setExpireDay(expireDay);
        }
        let ownerInfo = json["ownerInfo"];
        if (ownerInfo != null) {
            let convertedOwnerInfo0 = Serializer.fillFromJsonObjectWithTypeTag(ownerInfo, "__type", factory);
            this.setOwnerInfo(convertedOwnerInfo0);
        }
        let refererInfo = json["refererInfo"];
        if (refererInfo != null) {
            let convertedRefererInfo0 = Serializer.fillFromJsonObjectWithTypeTag(refererInfo, "__type", factory);
            this.setRefererInfo(convertedRefererInfo0);
        }
        let orderId = json["orderId"];
        if (orderId != null) {
            this.setOrderId(orderId);
        }
        let useTime = json["useTime"];
        if (useTime != null) {
            this.setUseTime(useTime);
        }
        let used = json["used"];
        if (used != null) {
            this.setUsed(used);
        }
        let cancelled = json["cancelled"];
        if (cancelled != null) {
            this.setCancelled(cancelled);
        }
        let refererAwarded = json["refererAwarded"];
        if (refererAwarded != null) {
            this.setRefererAwarded(refererAwarded);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let userAmount = json["userAmount"];
        if (userAmount != null) {
            this.setUserAmount(userAmount);
        }
        let refererAmount = json["refererAmount"];
        if (refererAmount != null) {
            this.setRefererAmount(refererAmount);
        }
        let minOrderAmount = json["minOrderAmount"];
        if (minOrderAmount != null) {
            this.setMinOrderAmount(minOrderAmount);
        }
        let couponComponents = json["couponComponents"];
        if (couponComponents != null) {
            let convertedCouponComponents0 = [];
            couponComponents = couponComponents[1];
            for (let i in couponComponents) {
                let convertedCouponComponents10 = couponComponents[i];
                let convertedCouponComponents1 = new QllCoupon();
                convertedCouponComponents1.fillFromJson(convertedCouponComponents10, factory);
                convertedCouponComponents0.push(convertedCouponComponents1);
            }
            this.setCouponComponents(convertedCouponComponents0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let couponId = this.getCouponId();
        if (couponId != null) {
            json["couponId"] = String(couponId);
        }
        let templateId = this.getTemplateId();
        if (templateId != null) {
            json["templateId"] = String(templateId);
        }
        let couponType = this.getCouponType();
        if (couponType != null) {
            let convertedCouponType0 = CouponType[couponType];
            json["couponType"] = convertedCouponType0;
        }
        let couponUseType = this.getCouponUseType();
        if (couponUseType != null) {
            let convertedCouponUseType0 = CouponUseType[couponUseType];
            json["couponUseType"] = convertedCouponUseType0;
        }
        let pkgCouponId = this.getPkgCouponId();
        if (pkgCouponId != null) {
            json["pkgCouponId"] = String(pkgCouponId);
        }
        let packaged = this.isPackaged();
        if (packaged != null) {
            json["packaged"] = packaged;
        }
        let imgUrl = this.getImgUrl();
        if (imgUrl != null) {
            json["imgUrl"] = imgUrl;
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let tradableId = this.getTradableId();
        if (tradableId != null) {
            json["tradableId"] = String(tradableId);
        }
        let tradableName = this.getTradableName();
        if (tradableName != null) {
            json["tradableName"] = tradableName;
        }
        let expireDay = this.getExpireDay();
        if (expireDay != null) {
            json["expireDay"] = expireDay;
        }
        let ownerInfo = this.getOwnerInfo();
        if (ownerInfo != null) {
            let convertedOwnerInfo0 = ownerInfo.getJson(JsonTypeTagType.TYPE);
            json["ownerInfo"] = convertedOwnerInfo0;
        }
        let refererInfo = this.getRefererInfo();
        if (refererInfo != null) {
            let convertedRefererInfo0 = refererInfo.getJson(JsonTypeTagType.TYPE);
            json["refererInfo"] = convertedRefererInfo0;
        }
        let orderId = this.getOrderId();
        if (orderId != null) {
            json["orderId"] = String(orderId);
        }
        let useTime = this.getUseTime();
        if (useTime != null) {
            json["useTime"] = String(useTime);
        }
        let used = this.isUsed();
        if (used != null) {
            json["used"] = used;
        }
        let cancelled = this.isCancelled();
        if (cancelled != null) {
            json["cancelled"] = cancelled;
        }
        let refererAwarded = this.isRefererAwarded();
        if (refererAwarded != null) {
            json["refererAwarded"] = refererAwarded;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let userAmount = this.getUserAmount();
        if (userAmount != null) {
            json["userAmount"] = userAmount;
        }
        let refererAmount = this.getRefererAmount();
        if (refererAmount != null) {
            json["refererAmount"] = refererAmount;
        }
        let minOrderAmount = this.getMinOrderAmount();
        if (minOrderAmount != null) {
            json["minOrderAmount"] = minOrderAmount;
        }
        let couponComponents = this.getCouponComponents();
        if (couponComponents != null) {
            let convertedCouponComponents0 = [];
            for (let i in couponComponents) {
                let convertedCouponComponents10 = couponComponents[i];
                let convertedCouponComponents1 = convertedCouponComponents10.getJson(JsonTypeTagType.NONE);
                convertedCouponComponents0.push(convertedCouponComponents1);
            }
            let convertedCouponComponentsWithType1 = ["java.util.ArrayList", convertedCouponComponents0];
            json["couponComponents"] = convertedCouponComponentsWithType1;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.coupon.QllCoupon";
    }
}
