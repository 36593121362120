/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {Filter} from '../../query/Filter'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {OrderStatus} from './OrderStatus'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class OrderFilter extends AbstractSerializable implements Filter  {
    status: OrderStatus;
    title: string;
    phone: string;

    constructor() {
    	super();
    }

    public getStatus(): OrderStatus {
    	return this.status;
    }

    public setStatus(status: OrderStatus) {
    	this.status = status;
    }

    public getTitle(): string {
    	return this.title;
    }

    public setTitle(title: string) {
    	this.title = title;
    }

    public getPhone(): string {
    	return this.phone;
    }

    public setPhone(phone: string) {
    	this.phone = phone;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = OrderStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let phone = json["phone"];
        if (phone != null) {
            this.setPhone(phone);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = OrderStatus[status];
            json["status"] = convertedStatus0;
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let phone = this.getPhone();
        if (phone != null) {
            json["phone"] = phone;
        }
    
        if (includeTypeTag) {
            json["__type"] = "OrderFilter";
        }
    }


    public getTypeId(): string {
    	return "OrderFilter";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.order.OrderFilter";
    }
}
