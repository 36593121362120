/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractClientServiceAdapter } from "../common/AbstractClientServiceAdapter";
import { DefaultServiceFactory } from "../api/DefaultServiceFactory";
import { DefaultWebServiceFactory } from "../webapi/DefaultWebServiceFactory";
import { EntityService } from "../api/entity/EntityService";
import { EntityVisitStats } from "../datatype/entity/EntityVisitStats";
import { GeoEntityDesc } from "../datatype/geolocation/GeoEntityDesc";
import { Location } from "../datatype/geolocation/Location";
import { PageableData } from "../datatype/paging/PageableData";
import { PageBlockAccessSpec } from "../datatype/paging/PageBlockAccessSpec";
import { PlatformDataContainer } from "../datatype/platform/PlatformDataContainer";
import { RoleType } from "../datatype/entity/RoleType";
import { SectorAssocInfo } from "../datatype/entity/sector/SectorAssocInfo";
import { ServiceResponseHandle } from "../api/datatype/ServiceResponseHandle";
import { SimpleEntityDesc } from "../datatype/entity/SimpleEntityDesc";
import { SystemSuppliedEntityInfo } from "../datatype/entity/SystemSuppliedEntityInfo";
import { UserSuppliedEntityInfo } from "../datatype/entity/UserSuppliedEntityInfo";
import { WebEntityService } from "../webapi/WebEntityService";
import { WebSessionManager } from "../common/WebSessionManager";

export class WebEntityServiceImpl implements WebEntityService {
  private entityService: EntityService;

  public constructor() {
    this.entityService = DefaultServiceFactory.getInstance().getEntityService();
  }

  public addAssociation(
    targetEntityId: number | string | null,
    associationRole: RoleType,
    entityId: number | string | null
  ): Promise<ServiceResponseHandle<boolean>> {
    return new Promise((resolve, reject) => {
      this.entityService.addAssociation(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        associationRole,
        entityId,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public addAssociationFromSingleToMore(
    targetEntityIds: Array<number | string> | null,
    associationRole: RoleType,
    entityId: number | string | null
  ): Promise<ServiceResponseHandle<boolean>> {
    return new Promise((resolve, reject) => {
      this.entityService.addAssociationFromSingleToMore(
        WebSessionManager.getSessionContext(),
        targetEntityIds,
        associationRole,
        entityId,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public addAssociations(
    targetEntityId: number | string | null,
    associationRole: RoleType,
    entityIds: Array<number | string> | null
  ): Promise<ServiceResponseHandle<boolean>> {
    return new Promise((resolve, reject) => {
      this.entityService.addAssociations(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        associationRole,
        entityIds,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public addCurrentUserAssociation(
    targetEntityId: number | string | null,
    associationRole: RoleType
  ): Promise<ServiceResponseHandle<boolean>> {
    return new Promise((resolve, reject) => {
      this.entityService.addCurrentUserAssociation(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        associationRole,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public checkAssociation(
    fromId: number | string | null,
    toId: number | string | null,
    roleType: RoleType
  ): Promise<ServiceResponseHandle<boolean>> {
    return new Promise((resolve, reject) => {
      this.entityService.checkAssociation(
        WebSessionManager.getSessionContext(),
        fromId,
        toId,
        roleType,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public deleteAssociation(
    targetEntityId: number | string | null,
    currentAssociationRole: RoleType,
    entityId: number | string | null
  ): Promise<ServiceResponseHandle<boolean>> {
    return new Promise((resolve, reject) => {
      this.entityService.deleteAssociation(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        currentAssociationRole,
        entityId,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public deleteAssociations(
    targetEntityId: number | string | null,
    currentAssociationRole: RoleType,
    entityIds: Array<number | string> | null
  ): Promise<ServiceResponseHandle<boolean>> {
    return new Promise((resolve, reject) => {
      this.entityService.deleteAssociations(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        currentAssociationRole,
        entityIds,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public deleteCurrentUserAssociation(
    targetEntityId: number | string | null,
    currentAssociationRole: RoleType
  ): Promise<ServiceResponseHandle<boolean>> {
    return new Promise((resolve, reject) => {
      this.entityService.deleteCurrentUserAssociation(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        currentAssociationRole,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public findAssociations(
    targetEntityId: number | string | null,
    currentAssociationRole: RoleType
  ): Promise<ServiceResponseHandle<Array<SimpleEntityDesc>>> {
    return new Promise((resolve, reject) => {
      this.entityService.findAssociations(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        currentAssociationRole,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public findChildEnterprises(
    parentId: number | string | null,
    pageBlockSpec: PageBlockAccessSpec | null
  ): Promise<PageableData<GeoEntityDesc>> {
    return new Promise((resolve, reject) => {
      this.entityService.findChildEnterprises(
        WebSessionManager.getSessionContext(),
        parentId,
        pageBlockSpec,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getBlackList(
    entityId: number | string | null,
    pageBlockSpec: PageBlockAccessSpec | null
  ): Promise<PageableData<GeoEntityDesc>> {
    return new Promise((resolve, reject) => {
      this.entityService.getBlackList(
        WebSessionManager.getSessionContext(),
        entityId,
        pageBlockSpec,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getEntityInfo(
    targetEntityId: number | string | null,
    lastSyncTime: number | string | null,
    succinct: boolean | null
  ): Promise<PlatformDataContainer<SystemSuppliedEntityInfo>> {
    return new Promise((resolve, reject) => {
      this.entityService.getEntityInfo(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        lastSyncTime,
        succinct,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getEntityInfoWithLocation(
    targetEntityId: number | string | null,
    location: Location | null,
    lastSyncTime: number | string | null,
    succinct: boolean | null
  ): Promise<PlatformDataContainer<SystemSuppliedEntityInfo>> {
    return new Promise((resolve, reject) => {
      this.entityService.getEntityInfoWithLocation(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        location,
        lastSyncTime,
        succinct,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getEntityVisitStats(
    entityId: number | string | null
  ): Promise<EntityVisitStats> {
    return new Promise((resolve, reject) => {
      this.entityService.getEntityVisitStats(
        WebSessionManager.getSessionContext(),
        entityId,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getSectorAssocInfo(
    targetEntityId: number | string | null
  ): Promise<SectorAssocInfo> {
    return new Promise((resolve, reject) => {
      this.entityService.getSectorAssocInfo(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public getSubscribers(
    entityId: number | string | null,
    pageBlockSpec: PageBlockAccessSpec | null
  ): Promise<PageableData<GeoEntityDesc>> {
    return new Promise((resolve, reject) => {
      this.entityService.getSubscribers(
        WebSessionManager.getSessionContext(),
        entityId,
        pageBlockSpec,
        (t, u) => {
          if (u) {
            reject(u);
          } else {
            resolve(t);
          }
        }
      );
    });
  }

  public updateAssociation(
    targetEntityId: number | string | null,
    entityId: number | string | null,
    roleToRemove: RoleType,
    roleToAdd: RoleType
  ): Promise<ServiceResponseHandle<boolean>> {
    return new Promise((resolve, reject) => {
      this.entityService.updateAssociation(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        entityId,
        roleToRemove,
        roleToAdd,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public updateEntity(
    entityInfo: UserSuppliedEntityInfo
  ): Promise<ServiceResponseHandle<SystemSuppliedEntityInfo>> {
    return new Promise((resolve, reject) => {
      this.entityService.updateEntity(
        WebSessionManager.getSessionContext(),
        entityInfo,
        (t) => {
          resolve(t);
        }
      );
    });
  }

  public updateSectorAssocInfo(
    targetEntityId: number | string | null,
    sectorAssocInfo: SectorAssocInfo
  ): Promise<ServiceResponseHandle<SystemSuppliedEntityInfo>> {
    return new Promise((resolve, reject) => {
      this.entityService.updateSectorAssocInfo(
        WebSessionManager.getSessionContext(),
        targetEntityId,
        sectorAssocInfo,
        (t) => {
          resolve(t);
        }
      );
    });
  }
}
