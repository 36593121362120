/**
 * 自动生成， 请不要手工修改！
 */

import { CreditGrade } from "../../credit/CreditGrade";
import { EntityInfo } from "../EntityInfo";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { RoleType } from "../RoleType";
import { Serializer } from "../../../serialize/Serializer";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class SimpleEntityView extends EntityInfo {
  initial: string;
  description: string;
  relationGrade: CreditGrade;
  relationScore: number;
  subscribers: number;
  votes: number;
  roleType: RoleType;
  createTime: number | string;

  constructor() {
    super();
  }

  public getKeywords(): Array<string> {
    return this.keywords;
  }

  public setKeywords(keywords: Array<string>) {
    this.keywords = keywords;
  }

  public getInitial(): string {
    return this.initial;
  }

  public setInitial(initial: string) {
    this.initial = initial;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getRelationGrade(): CreditGrade {
    return this.relationGrade;
  }

  public setRelationGrade(relationGrade: CreditGrade) {
    this.relationGrade = relationGrade;
  }

  public getRelationScore(): number {
    return this.relationScore;
  }

  public setRelationScore(relationScore: number) {
    this.relationScore = relationScore;
  }

  public getSubscribers(): number {
    return this.subscribers;
  }

  public setSubscribers(subscribers: number) {
    this.subscribers = subscribers;
  }

  public getVotes(): number {
    return this.votes;
  }

  public setVotes(votes: number) {
    this.votes = votes;
  }

  public getRoleType(): RoleType {
    return this.roleType;
  }

  public setRoleType(roleType: RoleType) {
    this.roleType = roleType;
  }

  public getCreateTime(): number | string {
    return this.createTime;
  }

  public setCreateTime(createTime: number | string) {
    this.createTime = createTime;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    let keywords = json["keywords"];
    if (keywords != null) {
      const convertedKeywords0 = [];
      keywords = keywords[1];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      this.setKeywords(convertedKeywords0);
    }
    const initial = json["initial"];
    if (initial != null) {
      const convertedInitial0 = Serializer.fromJson(initial, factory);
      this.setInitial(convertedInitial0);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    const relationGrade = json["relationGrade"];
    if (relationGrade != null) {
      const convertedRelationGrade0 = CreditGrade[<string>relationGrade];
      this.setRelationGrade(convertedRelationGrade0);
    }
    const relationScore = json["relationScore"];
    if (relationScore != null) {
      this.setRelationScore(relationScore);
    }
    const subscribers = json["subscribers"];
    if (subscribers != null) {
      this.setSubscribers(subscribers);
    }
    const votes = json["votes"];
    if (votes != null) {
      this.setVotes(votes);
    }
    const roleType = json["roleType"];
    if (roleType != null) {
      const convertedRoleType0 = RoleType[<string>roleType];
      this.setRoleType(convertedRoleType0);
    }
    const createTime = json["createTime"];
    if (createTime != null) {
      this.setCreateTime(createTime);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const keywords = this.getKeywords();
    if (keywords != null) {
      const convertedKeywords0 = [];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      const convertedKeywordsWithType1 = [
        "java.util.ArrayList",
        convertedKeywords0,
      ];
      json["keywords"] = convertedKeywordsWithType1;
    }
    const initial = this.getInitial();
    if (initial != null) {
      const convertedInitial0 = Serializer.getJson(
        initial,
        JsonTypeTagType.CLASS
      );
      json["initial"] = convertedInitial0;
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const relationGrade = this.getRelationGrade();
    if (relationGrade != null) {
      const convertedRelationGrade0 = CreditGrade[relationGrade];
      json["relationGrade"] = convertedRelationGrade0;
    }
    const relationScore = this.getRelationScore();
    if (relationScore != null) {
      json["relationScore"] = relationScore;
    }
    const subscribers = this.getSubscribers();
    if (subscribers != null) {
      json["subscribers"] = subscribers;
    }
    const votes = this.getVotes();
    if (votes != null) {
      json["votes"] = votes;
    }
    const roleType = this.getRoleType();
    if (roleType != null) {
      const convertedRoleType0 = RoleType[roleType];
      json["roleType"] = convertedRoleType0;
    }
    const createTime = this.getCreateTime();
    if (createTime != null) {
      json["createTime"] = String(createTime);
    }

    if (includeTypeTag) {
      json["__type"] = "SimpleEntityView";
    }
  }

  public getTypeId(): string {
    return "SimpleEntityView";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.view.SimpleEntityView";
  }
}
