/**
 * 自动生成， 请不要手工修改！
 */

export enum MessageRoleType {
  UNDISPLAYABLE_SERVICE_REQUEST,
  DISPLAYABLE_SERVICE_REQUEST,
  SERVICE_EVENT_SUBSCRIPTION,
  USER_VOTE,
  INTERMEDIATE_WORK_CONTENT,
  WORKFLOW_TASK,
  SERVICE_RESPONSE,
  STATUS_NOTICE_AFTER_REQUEST,
  SERVICE_EVENT_NOTIFICATION,
  SELECTED_CHANNEL_NOTICE,
  PUBLIC_CHANNEL_NOTICE,
  USER_VOTE_AND_COMMENT,
  USER_MESSAGE_ONLY,
  SYSTEM_MESSAGE,
  MESSAGE_REVOKE,
}
