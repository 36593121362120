/**
 * 自动生成， 请不要手工修改！
 */

export enum EntityStatus {
  WAIT_REVIEW,
  ACTIVE,
  SUSPENDED,
  HIDDEN,
  TERMINATED,
}
