/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractChannelSpec } from "./AbstractChannelSpec";
import { EntityChannelType } from "./EntityChannelType";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractSimpleChannelSpec extends AbstractChannelSpec {
  name: string;
  description: string;
  channelType: EntityChannelType;

  constructor() {
    super();
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string) {
    this.name = name;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getChannelType(): EntityChannelType {
    return this.channelType;
  }

  public setChannelType(channelType: EntityChannelType) {
    this.channelType = channelType;
  }

  abstract getRid(): number;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const name = json["name"];
    if (name != null) {
      this.setName(name);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    const channelType = json["channelType"];
    if (channelType != null) {
      const convertedChannelType0 = EntityChannelType[<string>channelType];
      this.setChannelType(convertedChannelType0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const name = this.getName();
    if (name != null) {
      json["name"] = name;
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const channelType = this.getChannelType();
    if (channelType != null) {
      const convertedChannelType0 = EntityChannelType[channelType];
      json["channelType"] = convertedChannelType0;
    }

    if (includeTypeTag) {
      json["__type"] = "SimpleChannelSpec";
    }
  }

  public getTypeId(): string {
    return "SimpleChannelSpec";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.channel.SimpleChannelSpec";
  }
}
