// @ts-nocheck
import { AbstractEnterpriseServiceTradableStats } from "./AbstractEnterpriseServiceTradableStats";
import { CoworkSimpleView } from "../../../cowork/CoworkSimpleView";
import { CoworkStats } from "../../../cowork/CoworkStats";

export class EnterpriseServiceTradableStats extends AbstractEnterpriseServiceTradableStats {
  constructor() {
    super();
  }

  addInfoToView(arg0: CoworkSimpleView | null) {
    // Manual Code Here
  }
  updateCoworkStats(arg0: CoworkStats | null) {
    // Manual Code Here
  }
}
