// @ts-nocheck
import { AbstractCompositeReviewInfo } from "./AbstractCompositeReviewInfo";

export class CompositeReviewInfo extends AbstractCompositeReviewInfo {
  constructor() {
    super();
  }

  getVisits(): number {
    // Manual Code Here
  }
}
