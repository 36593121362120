// @ts-nocheck
import {AbstractEnterpriseServiceRequest} from './AbstractEnterpriseServiceRequest'
import {EntityInfo} from '../../../entity/EntityInfo'
import {KeywordEntity} from '../../../keyword/KeywordEntity'
import {MessageContentType} from '../../../../message/MessageContentType'
import {QllCountry} from '../../../international/QllCountry'
import {TextType} from '../../../TextType'


export class EnterpriseServiceRequest extends AbstractEnterpriseServiceRequest  {
    constructor() {
    	super();
    }

    getTargetEntityInfo(): EntityInfo {
      // Manual Code Here
    }
    getCountry(): QllCountry {
      // Manual Code Here
    }
    getEntity(arg0: (string | null)): KeywordEntity {
      // Manual Code Here
    }
    getInitiatorId(): (number | string) {
      // Manual Code Here
    }
    getReferencedEntityId(): (number | string) {
      // Manual Code Here
    }
    getInitiatorImageUrl(): string {
      // Manual Code Here
    }
    getObjectMainImageUrl(): string {
      // Manual Code Here
    }
    getMessageType(): MessageContentType {
      // Manual Code Here
    }
    getTextType(): TextType {
      // Manual Code Here
    }
    getText(): string {
      // Manual Code Here
    }

}
