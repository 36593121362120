/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class GeoHash extends AbstractSerializable {
  bits: number | string;
  step: number;
  reqStep: number;

  constructor() {
    super();
  }

  public getBits(): number | string {
    return this.bits;
  }

  public setBits(bits: number | string) {
    this.bits = bits;
  }

  public getStep(): number {
    return this.step;
  }

  public setStep(step: number) {
    this.step = step;
  }

  public getReqStep(): number {
    return this.reqStep;
  }

  public setReqStep(reqStep: number) {
    this.reqStep = reqStep;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const bits = json["bits"];
    if (bits != null) {
      this.setBits(bits);
    }
    const step = json["step"];
    if (step != null) {
      this.setStep(step);
    }
    const reqStep = json["reqStep"];
    if (reqStep != null) {
      this.setReqStep(reqStep);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const bits = this.getBits();
    if (bits != null) {
      json["bits"] = String(bits);
    }
    const step = this.getStep();
    if (step != null) {
      json["step"] = step;
    }
    const reqStep = this.getReqStep();
    if (reqStep != null) {
      json["reqStep"] = reqStep;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.geolocation.GeoHash";
  }
}
