/**
 * Created by Zhonghua on 14/11/2016.
 */

import { JsonSerializable } from "./JsonSerializable";
import { JsonTypeTagType } from "./JsonTypeTagType";
import { TypeFactory } from "./TypeFactory";
import { TypeMapper } from "./TypeMapper";

export abstract class AbstractSerializable implements JsonSerializable {
  constructor() {}

  public fillFromJson(json: Object, factory?: TypeFactory) {}

  public fillToJson(json: Object, includeTypeTag = true) {}

  public getJson(tagType: JsonTypeTagType): Object {
    const json = {};
    switch (tagType) {
      case JsonTypeTagType.CLASS:
        this.fillToJson(json, false);
        return [this.getClassName(), json];
      case JsonTypeTagType.TYPE:
        this.fillToJson(json, true);
        return json;
      case JsonTypeTagType.NONE:
        this.fillToJson(json, false);
        return json;
    }
  }

  public abstract getClassName(): string;
}
