/**
 * 自动生成， 请不要手工修改！
 */


export enum InfoSectionType {
    TOPIC_ZONE,
    LOCAL_CIRCLE,
    LOCAL_ENTERPRISES,
    PERSONALITIES,
    AD,
    ACTIVITY,
    REVIEW,
    QUESTION,
    SERVICE,
    PRODUCT,
    COLUMN,
    PRICE,
    OPINION,
    PRAISE,
    RECOMMEND,
    SUGGEST,
    POINTS,
    APPEAL,
    GRID_GOV,
    RESIDENCE_LIST,
    VILLAGE_LIST,
    GRID_LIST,
    RESIDENCE,
    VILLAGE,
    BUS_CIRCLE,
    CHARITY,
    LIFE,
    GW,
    ALUMNI,
    SOCIETY,
    REGION_ALLIANCE,
    SECTOR_ALLIANCE
}
