/**
 * 自动生成， 请不要手工修改！
 */

export enum NavigationTab {
  HOME, // 首页
  ORDER_MANAGE, // 订单管理
  USER_INTERACT, // 商企互动
  INFO_MAINTAIN, // 信息维护
  PLATFORM_MESSAGE, // 信息维护
  RECEIT_MANAGE, // 发票管理
  FINANCE_SETTLE, // 财务结算
  SERVICE_MARKET, // 服务广场
}
