/**
 * 自动生成， 请不要手工修改！
 */


export enum SharedInfoType {
    NEWS,
    VIDEO,
    EXPERIENCE,
    POLICY,
    TECH,
    NOTES,
    OPINION,
    THINK,
    LOST,
    FOUND,
    HELP,
    INITIATIVE,
    DYNAMIC,
    SCHOOL_STORY,
    A_NEWS,
    E_NEWS,
    NOTICE,
    COMMERCE,
    BUS_CASE,
    CULTURE
}
