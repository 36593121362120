/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractClientServiceAdapter } from "../../common/AbstractClientServiceAdapter";
import { BiConsumer } from "../../util/BiConsumer";
import { Consumer } from "../../util/Consumer";
import { EntityService } from "../../api/entity/EntityService";
import { EntityVisitStats } from "../../datatype/entity/EntityVisitStats";
import { GeoEntityDesc } from "../../datatype/geolocation/GeoEntityDesc";
import { Location } from "../../datatype/geolocation/Location";
import { PageableData } from "../../datatype/paging/PageableData";
import { PageBlockAccessSpec } from "../../datatype/paging/PageBlockAccessSpec";
import { PlatformDataContainer } from "../../datatype/platform/PlatformDataContainer";
import { RoleType } from "../../datatype/entity/RoleType";
import { SectorAssocInfo } from "../../datatype/entity/sector/SectorAssocInfo";
import { ServiceConsumers } from "../../util/ServiceConsumers";
import { ServiceRequest } from "../../common/ServiceRequest";
import { ServiceResponseHandle } from "../../api/datatype/ServiceResponseHandle";
import { SessionContext } from "../../session/SessionContext";
import { SimpleEntityDesc } from "../../datatype/entity/SimpleEntityDesc";
import { SystemSuppliedEntityInfo } from "../../datatype/entity/SystemSuppliedEntityInfo";
import { UserSuppliedEntityInfo } from "../../datatype/entity/UserSuppliedEntityInfo";

export class EntityServiceProxy
  extends AbstractClientServiceAdapter
  implements EntityService
{
  public constructor() {
    super("com.gong_wei.service.api.entity.EntityService");
  }

  public addAssociation(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    associationRole: RoleType | null,
    entityId: number | string | null,
    resultHandler: Consumer<ServiceResponseHandle<boolean>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "addAssociation",
      [sessionContext, targetEntityId, associationRole, entityId]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public addAssociationFromSingleToMore(
    sessionContext: SessionContext | null,
    targetEntityIds: Array<number | string> | null,
    associationRole: RoleType | null,
    entityId: number | string | null,
    resultHandler: Consumer<ServiceResponseHandle<boolean>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "addAssociationFromSingleToMore",
      [sessionContext, targetEntityIds, associationRole, entityId]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public addAssociations(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    associationRole: RoleType | null,
    entityIds: Array<number | string> | null,
    resultHandler: Consumer<ServiceResponseHandle<boolean>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "addAssociations",
      [sessionContext, targetEntityId, associationRole, entityIds]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public addCurrentUserAssociation(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    associationRole: RoleType | null,
    resultHandler: Consumer<ServiceResponseHandle<boolean>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "addCurrentUserAssociation",
      [sessionContext, targetEntityId, associationRole]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public checkAssociation(
    sessionContext: SessionContext | null,
    fromId: number | string | null,
    toId: number | string | null,
    roleType: RoleType | null,
    resultHandler: Consumer<ServiceResponseHandle<boolean>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "checkAssociation",
      [sessionContext, fromId, toId, roleType]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public deleteAssociation(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    currentAssociationRole: RoleType | null,
    entityId: number | string | null,
    resultHandler: Consumer<ServiceResponseHandle<boolean>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "deleteAssociation",
      [sessionContext, targetEntityId, currentAssociationRole, entityId]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public deleteAssociations(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    currentAssociationRole: RoleType | null,
    entityIds: Array<number | string> | null,
    resultHandler: Consumer<ServiceResponseHandle<boolean>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "deleteAssociations",
      [sessionContext, targetEntityId, currentAssociationRole, entityIds]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public deleteCurrentUserAssociation(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    currentAssociationRole: RoleType | null,
    resultHandler: Consumer<ServiceResponseHandle<boolean>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "deleteCurrentUserAssociation",
      [sessionContext, targetEntityId, currentAssociationRole]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public findAssociations(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    currentAssociationRole: RoleType | null,
    resultHandler: Consumer<ServiceResponseHandle<Array<SimpleEntityDesc>>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "findAssociations",
      [sessionContext, targetEntityId, currentAssociationRole]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public findChildEnterprises(
    sessionContext: SessionContext | null,
    parentId: number | string | null,
    pageBlockSpec: PageBlockAccessSpec | null,
    resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "findChildEnterprises",
      [sessionContext, parentId, pageBlockSpec]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getBlackList(
    sessionContext: SessionContext | null,
    entityId: number | string | null,
    pageBlockSpec: PageBlockAccessSpec | null,
    resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "getBlackList",
      [sessionContext, entityId, pageBlockSpec]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getEntityInfo(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    lastSyncTime: number | string | null,
    succinct: boolean | null,
    resultHandler: BiConsumer<
      PlatformDataContainer<SystemSuppliedEntityInfo>,
      any
    >
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "getEntityInfo",
      [sessionContext, targetEntityId, lastSyncTime, succinct]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getEntityInfoWithLocation(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    location: Location | null,
    lastSyncTime: number | string | null,
    succinct: boolean | null,
    resultHandler: BiConsumer<
      PlatformDataContainer<SystemSuppliedEntityInfo>,
      any
    >
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "getEntityInfoWithLocation",
      [sessionContext, targetEntityId, location, lastSyncTime, succinct]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getEntityVisitStats(
    sessionContext: SessionContext | null,
    entityId: number | string | null,
    resultHandler: BiConsumer<EntityVisitStats, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "getEntityVisitStats",
      [sessionContext, entityId]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getSectorAssocInfo(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    resultHandler: BiConsumer<SectorAssocInfo, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "getSectorAssocInfo",
      [sessionContext, targetEntityId]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getSubscribers(
    sessionContext: SessionContext | null,
    entityId: number | string | null,
    pageBlockSpec: PageBlockAccessSpec | null,
    resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "getSubscribers",
      [sessionContext, entityId, pageBlockSpec]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public updateAssociation(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    entityId: number | string | null,
    roleToRemove: RoleType | null,
    roleToAdd: RoleType | null,
    resultHandler: Consumer<ServiceResponseHandle<boolean>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "updateAssociation",
      [sessionContext, targetEntityId, entityId, roleToRemove, roleToAdd]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public updateEntity(
    sessionContext: SessionContext | null,
    entityInfo: UserSuppliedEntityInfo | null,
    resultHandler: Consumer<ServiceResponseHandle<SystemSuppliedEntityInfo>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "updateEntity",
      [sessionContext, entityInfo]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public updateSectorAssocInfo(
    sessionContext: SessionContext | null,
    targetEntityId: number | string | null,
    sectorAssocInfo: SectorAssocInfo | null,
    resultHandler: Consumer<ServiceResponseHandle<SystemSuppliedEntityInfo>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.entity.EntityService",
      "updateSectorAssocInfo",
      [sessionContext, targetEntityId, sectorAssocInfo]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }
}
