/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class WeChatPayClientInfo extends AbstractSerializable {
  appid: string;
  mchId: string;
  prepayid: string;
  signMode: string;
  noncestr: string;
  sign: string;
  timeStamp: string;

  constructor() {
    super();
  }

  public getAppid(): string {
    return this.appid;
  }

  public setAppid(appid: string) {
    this.appid = appid;
  }

  public getMchId(): string {
    return this.mchId;
  }

  public setMchId(mchId: string) {
    this.mchId = mchId;
  }

  public getPrepayid(): string {
    return this.prepayid;
  }

  public setPrepayid(prepayid: string) {
    this.prepayid = prepayid;
  }

  public getSignMode(): string {
    return this.signMode;
  }

  public setSignMode(signMode: string) {
    this.signMode = signMode;
  }

  public getNoncestr(): string {
    return this.noncestr;
  }

  public setNoncestr(noncestr: string) {
    this.noncestr = noncestr;
  }

  public getSign(): string {
    return this.sign;
  }

  public setSign(sign: string) {
    this.sign = sign;
  }

  public getTimeStamp(): string {
    return this.timeStamp;
  }

  public setTimeStamp(timeStamp: string) {
    this.timeStamp = timeStamp;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const appid = json["appid"];
    if (appid != null) {
      this.setAppid(appid);
    }
    const mchId = json["mchId"];
    if (mchId != null) {
      this.setMchId(mchId);
    }
    const prepayid = json["prepayid"];
    if (prepayid != null) {
      this.setPrepayid(prepayid);
    }
    const signMode = json["signMode"];
    if (signMode != null) {
      this.setSignMode(signMode);
    }
    const noncestr = json["noncestr"];
    if (noncestr != null) {
      this.setNoncestr(noncestr);
    }
    const sign = json["sign"];
    if (sign != null) {
      this.setSign(sign);
    }
    const timeStamp = json["timeStamp"];
    if (timeStamp != null) {
      this.setTimeStamp(timeStamp);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const appid = this.getAppid();
    if (appid != null) {
      json["appid"] = appid;
    }
    const mchId = this.getMchId();
    if (mchId != null) {
      json["mchId"] = mchId;
    }
    const prepayid = this.getPrepayid();
    if (prepayid != null) {
      json["prepayid"] = prepayid;
    }
    const signMode = this.getSignMode();
    if (signMode != null) {
      json["signMode"] = signMode;
    }
    const noncestr = this.getNoncestr();
    if (noncestr != null) {
      json["noncestr"] = noncestr;
    }
    const sign = this.getSign();
    if (sign != null) {
      json["sign"] = sign;
    }
    const timeStamp = this.getTimeStamp();
    if (timeStamp != null) {
      json["timeStamp"] = timeStamp;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.payment.WeChatPayClientInfo";
  }
}
