/**
 * 自动生成， 请不要手工修改！
 */


export enum CoopType {
    AGENT,
    SERVICE,
    SUPPLY_CHAIN,
    PROJECT,
    PROPERTY_RIGHT,
    EQUITY,
    FUND,
    FRANCHISE
}
