/**
 * 自动生成， 请不要手工修改！
 */


export enum OrderUseStatus {
    PENDING_TO_PAY,
    USABLE,
    COMPLETED,
    ONLINE_CS,
    CANCELLED,
    EXPIRED
}
