/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { AcademicDegree } from "./AcademicDegree";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class EducationInfo extends AbstractSerializable {
  id: number | string;
  hostId: number | string;
  academicDegree: AcademicDegree;
  yearOfGraduation: string;
  academicSubject: string;
  certificateUrls: Array<string>;

  constructor() {
    super();
  }

  public getId(): number | string {
    return this.id;
  }

  public setId(id: number | string) {
    this.id = id;
  }

  public getHostId(): number | string {
    return this.hostId;
  }

  public setHostId(hostId: number | string) {
    this.hostId = hostId;
  }

  public getAcademicDegree(): AcademicDegree {
    return this.academicDegree;
  }

  public setAcademicDegree(academicDegree: AcademicDegree) {
    this.academicDegree = academicDegree;
  }

  public getYearOfGraduation(): string {
    return this.yearOfGraduation;
  }

  public setYearOfGraduation(yearOfGraduation: string) {
    this.yearOfGraduation = yearOfGraduation;
  }

  public getAcademicSubject(): string {
    return this.academicSubject;
  }

  public setAcademicSubject(academicSubject: string) {
    this.academicSubject = academicSubject;
  }

  public getCertificateUrls(): Array<string> {
    return this.certificateUrls;
  }

  public setCertificateUrls(certificateUrls: Array<string>) {
    this.certificateUrls = certificateUrls;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const id = json["id"];
    if (id != null) {
      this.setId(id);
    }
    const hostId = json["hostId"];
    if (hostId != null) {
      this.setHostId(hostId);
    }
    const academicDegree = json["academicDegree"];
    if (academicDegree != null) {
      const convertedAcademicDegree0 = AcademicDegree[<string>academicDegree];
      this.setAcademicDegree(convertedAcademicDegree0);
    }
    const yearOfGraduation = json["yearOfGraduation"];
    if (yearOfGraduation != null) {
      this.setYearOfGraduation(yearOfGraduation);
    }
    const academicSubject = json["academicSubject"];
    if (academicSubject != null) {
      this.setAcademicSubject(academicSubject);
    }
    const certificateUrls = json["certificateUrls"];
    if (certificateUrls != null) {
      const convertedCertificateUrls0 = [];
      for (const i in certificateUrls) {
        const convertedCertificateUrls10 = certificateUrls[i];
        convertedCertificateUrls0.push(convertedCertificateUrls10);
      }
      this.setCertificateUrls(convertedCertificateUrls0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const id = this.getId();
    if (id != null) {
      json["id"] = String(id);
    }
    const hostId = this.getHostId();
    if (hostId != null) {
      json["hostId"] = String(hostId);
    }
    const academicDegree = this.getAcademicDegree();
    if (academicDegree != null) {
      const convertedAcademicDegree0 = AcademicDegree[academicDegree];
      json["academicDegree"] = convertedAcademicDegree0;
    }
    const yearOfGraduation = this.getYearOfGraduation();
    if (yearOfGraduation != null) {
      json["yearOfGraduation"] = yearOfGraduation;
    }
    const academicSubject = this.getAcademicSubject();
    if (academicSubject != null) {
      json["academicSubject"] = academicSubject;
    }
    const certificateUrls = this.getCertificateUrls();
    if (certificateUrls != null) {
      const convertedCertificateUrls0 = [];
      for (const i in certificateUrls) {
        const convertedCertificateUrls10 = certificateUrls[i];
        convertedCertificateUrls0.push(convertedCertificateUrls10);
      }
      json["certificateUrls"] = convertedCertificateUrls0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.expert.EducationInfo";
  }
}
