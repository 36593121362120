/**
 * Created by Zhonghua on 18/11/2016.
 */

export class Helper {
  public static isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
  }

  public static isString(obj: any): boolean {
    return typeof obj === "string";
  }

  public static isArray(obj: any): boolean {
    return Array.isArray(obj);
  }

  public static isFunction(obj: any): boolean {
    return typeof obj === "function";
  }
}
