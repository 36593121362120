import { RequestStatusClassifier } from "@/services/datatype/request/RequestStatusClassifier";
import { RequestStatusClassifierName } from "@/services/datatype/request/RequestStatusClassifierName";
import { RequestStatusType } from "@/services/datatype/request/RequestStatusType";

export class RequestStatusClassifierUtils {
  public static getDisplayableRequestStatusClassifiers(): Array<RequestStatusClassifier> {
    return [
      RequestStatusClassifier.TO_PROCESS,
      RequestStatusClassifier.IN_PROCESS,
      RequestStatusClassifier.COMPLETED,
    ];
  }

  public static getDisplayableRequestStatusClassifierFullNames(): Array<string> {
    return ["待处理需求", "处理中需求", "已处理需求"];
  }

  public static getDisplayableRequestStatusClassifierNames(): Array<string> {
    return [
      RequestStatusClassifierName.getDisplayName(
        RequestStatusClassifier.TO_PROCESS
      ),
      RequestStatusClassifierName.getDisplayName(
        RequestStatusClassifier.IN_PROCESS
      ),
      RequestStatusClassifierName.getDisplayName(
        RequestStatusClassifier.COMPLETED
      ),
    ];
  }

  public static getStatusClassifierOfType(
    statusType: RequestStatusType
  ): RequestStatusClassifier {
    switch (statusType) {
      case RequestStatusType.TO_ACCEPT:
      case RequestStatusType.MATCH_WORKER:
      case RequestStatusType.ACCEPTED_WORKER:
      case RequestStatusType.PEND_FOR_REVIEW:
        return RequestStatusClassifier.TO_PROCESS;
      case RequestStatusType.IN_REVIEW:
        return RequestStatusClassifier.IN_PROCESS;
      case RequestStatusType.REJECTED:
        return RequestStatusClassifier.OBSOLETE;
      case RequestStatusType.ACCEPTED:
      case RequestStatusType.PENDING_ORDER:
      case RequestStatusType.ACKED:
        return RequestStatusClassifier.IN_PROCESS;
      case RequestStatusType.CANCELLED:
        return RequestStatusClassifier.OBSOLETE;
      case RequestStatusType.PROCESS_FAILED:
        return RequestStatusClassifier.IN_ERROR;
      case RequestStatusType.ACK_COMPLETE:
        return RequestStatusClassifier.IN_PROCESS;
      case RequestStatusType.COMPLETED:
        return RequestStatusClassifier.COMPLETED;
    }
  }
}
