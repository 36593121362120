/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EntityInfo} from '../EntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {Location} from '../../geolocation/Location'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class UserFavorite extends AbstractSerializable {
    userId: (number | string);
    location: Location;
    entityInfo: EntityInfo;
    createTime: (number | string);
    description: string;
    imageUrls: Array<string>;
    mutual: boolean;
    inRole: boolean;

    constructor() {
        super();
    }

    public getUserId(): (number | string) {
        return this.userId;
    }

    public setUserId(userId: (number | string)) {
        this.userId = userId;
    }

    public getLocation(): Location {
        return this.location;
    }

    public setLocation(location: Location) {
        this.location = location;
    }

    public getEntityInfo(): EntityInfo {
        return this.entityInfo;
    }

    public setEntityInfo(entityInfo: EntityInfo) {
        this.entityInfo = entityInfo;
    }

    public getCreateTime(): (number | string) {
        return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
        this.createTime = createTime;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string) {
        this.description = description;
    }

    public getImageUrls(): Array<string> {
        return this.imageUrls;
    }

    public setImageUrls(imageUrls: Array<string>) {
        this.imageUrls = imageUrls;
    }

    public isMutual(): boolean {
        return this.mutual;
    }

    public setMutual(mutual: boolean) {
        this.mutual = mutual;
    }

    public isInRole(): boolean {
        return this.inRole;
    }

    public setInRole(inRole: boolean) {
        this.inRole = inRole;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let userId = json["userId"];
        if (userId != null) {
            this.setUserId(userId);
        }
        let location = json["location"];
        if (location != null) {
            let convertedLocation0 = new Location();
            convertedLocation0.fillFromJson(location, factory);
            this.setLocation(convertedLocation0);
        }
        let entityInfo = json["entityInfo"];
        if (entityInfo != null) {
            let convertedEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(entityInfo, "__type", factory);
            this.setEntityInfo(convertedEntityInfo0);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let imageUrls = json["imageUrls"];
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            imageUrls = imageUrls[1];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            this.setImageUrls(convertedImageUrls0);
        }
        let mutual = json["mutual"];
        if (mutual != null) {
            this.setMutual(mutual);
        }
        let inRole = json["inRole"];
        if (inRole != null) {
            this.setInRole(inRole);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let userId = this.getUserId();
        if (userId != null) {
            json["userId"] = String(userId);
        }
        let location = this.getLocation();
        if (location != null) {
            let convertedLocation0 = location.getJson(JsonTypeTagType.NONE);
            json["location"] = convertedLocation0;
        }
        let entityInfo = this.getEntityInfo();
        if (entityInfo != null) {
            let convertedEntityInfo0 = entityInfo.getJson(JsonTypeTagType.TYPE);
            json["entityInfo"] = convertedEntityInfo0;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let imageUrls = this.getImageUrls();
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            let convertedImageUrlsWithType1 = ["java.util.ArrayList", convertedImageUrls0];
            json["imageUrls"] = convertedImageUrlsWithType1;
        }
        let mutual = this.isMutual();
        if (mutual != null) {
            json["mutual"] = mutual;
        }
        let inRole = this.isInRole();
        if (inRole != null) {
            json["inRole"] = inRole;
        }

        if (includeTypeTag) {
            json["__type"] = "UserFavorite";
        }
    }


    public getTypeId(): string {
        return "UserFavorite";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.user.UserFavorite";
    }
}
