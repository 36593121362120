/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../../../common/AbstractClientServiceAdapter'
import {BiConsumer} from '../../../util/BiConsumer'
import {EnterpriseServiceRequestInfo} from '../../../datatype/business/service/request/EnterpriseServiceRequestInfo'
import {EnterpriseServiceSettlementService} from '../../../api/business/service/EnterpriseServiceSettlementService'
import {PageableData} from '../../../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../../../datatype/paging/PageBlockAccessSpec'
import {ServiceConsumers} from '../../../util/ServiceConsumers'
import {ServiceRequest} from '../../../common/ServiceRequest'
import {SessionContext} from '../../../session/SessionContext'
import {SettlementAccountInfo} from '../../../datatype/business/service/settlement/SettlementAccountInfo'
import {SettlementBillAdjustRequest} from '../../../datatype/business/service/settlement/SettlementBillAdjustRequest'
import {SettlementBillInfo} from '../../../datatype/business/service/settlement/SettlementBillInfo'
import {SettlementBillSyncStatus} from '../../../datatype/business/service/settlement/SettlementBillSyncStatus'
import {SettlementInfo} from '../../../datatype/business/service/settlement/SettlementInfo'


export class EnterpriseServiceSettlementServiceProxy extends AbstractClientServiceAdapter implements EnterpriseServiceSettlementService {

    public constructor() {
        super("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService");
    }

    public addSettlementAccountInfo(sessionContext: (SessionContext | null), settlementAccountInfo: (SettlementAccountInfo | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "addSettlementAccountInfo", [sessionContext, settlementAccountInfo]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public addSettlementBillInfo(sessionContext: (SessionContext | null), settlementBillInfo: (SettlementBillInfo | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "addSettlementBillInfo", [sessionContext, settlementBillInfo]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public confirmBillById(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), workerId: (number | string | null), seasonId: (number | string | null), billId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "confirmBillById", [sessionContext, enterpriseId, workerId, seasonId, billId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsToSettleByPeriod(sessionContext: (SessionContext | null), period: (number | null), enterpriseId: (number | string | null), workerId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "findEnterpriseServiceRequestsToSettleByPeriod", [sessionContext, period, enterpriseId, workerId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findSettledEnterpriseServiceRequestsByPeriod(sessionContext: (SessionContext | null), period: (number | null), enterpriseId: (number | string | null), workerId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "findSettledEnterpriseServiceRequestsByPeriod", [sessionContext, period, enterpriseId, workerId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEnterpriseServiceSettlementInfo(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), workerId: (number | string | null), resultHandler: BiConsumer<SettlementInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "getEnterpriseServiceSettlementInfo", [sessionContext, enterpriseId, workerId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getSettlementBillInfoById(sessionContext: (SessionContext | null), billId: (number | string | null), resultHandler: BiConsumer<SettlementBillInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "getSettlementBillInfoById", [sessionContext, billId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getSettlementBillInfoBySeasonId(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), workerId: (number | string | null), seasonId: (number | string | null), resultHandler: BiConsumer<SettlementBillInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "getSettlementBillInfoBySeasonId", [sessionContext, enterpriseId, workerId, seasonId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getSettlementBillInfos(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), workerId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<SettlementBillInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "getSettlementBillInfos", [sessionContext, enterpriseId, workerId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getSettlementBillInfosBySyncStatus(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), workerId: (number | string | null), syncStatus: (SettlementBillSyncStatus | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<SettlementBillInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "getSettlementBillInfosBySyncStatus", [sessionContext, enterpriseId, workerId, syncStatus, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public requestToAdjustBill(sessionContext: (SessionContext | null), billAdjustRequest: (SettlementBillAdjustRequest | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "requestToAdjustBill", [sessionContext, billAdjustRequest]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public updateSettlementAccountInfo(sessionContext: (SessionContext | null), settlementAccountInfo: (SettlementAccountInfo | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceSettlementService", "updateSettlementAccountInfo", [sessionContext, settlementAccountInfo]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

}
