/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCowork} from '../../../cowork/AbstractCowork'
import {AbstractEnterpriseServiceTrade} from '../trade/AbstractEnterpriseServiceTrade'
import {EnterpriseServiceType} from '../EnterpriseServiceType'
import {EntityInfo} from '../../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../../keyword/KeywordEntity'
import {MessageType} from '../../../../message/MessageContentType'
import {PaymentSpec} from '../../payment/PaymentSpec'
import {QllCountry} from '../../../international/QllCountry'
import {RequestStatusType} from '../../../request/RequestStatusType'
import {Serializer} from '../../../../serialize/Serializer'
import {ServiceRequestSpecificInfo} from './ServiceRequestSpecificInfo'
import {SettlementBillSyncStatus} from '../settlement/SettlementBillSyncStatus'
import {TextSource} from '../../../../message/entity/api/TextSource'
import {TextType} from '../../../TextType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export abstract class AbstractEnterpriseServiceRequest extends AbstractCowork implements TextSource  {
    townId: (number | string);
    period: number;
    tradableId: (number | string);
    pkgTradableId: (number | string);
    tradePkgId: (number | string);
    orderId: (number | string);
    fromMiniService: boolean;
    enterpriseServiceType: EnterpriseServiceType;
    serviceTypeName: string;
    serviceTypeDisplayName: string;
    serviceCategoryName: string;
    tradeId: (number | string);
    trade: AbstractEnterpriseServiceTrade;
    orderingEnterpriseInfo: EntityInfo;
    servingEnterpriseInfo: EntityInfo;
    servingProfessionalInfo: EntityInfo;
    toDelegate: boolean;
    servingEntWeChatUrl: string;
    customerServiceWechatUrl: string;
    customerServicePhone: string;
    secretaryInfo: EntityInfo;
    assistEnterpriseInfo: EntityInfo;
    assistantInfo: EntityInfo;
    customerServiceInfo: EntityInfo;
    customerServiceWorkerInfo: EntityInfo;
    personal: boolean;
    assignerInfo: EntityInfo;
    specificInfo: ServiceRequestSpecificInfo;
    paymentSpec: PaymentSpec;
    status: RequestStatusType;
    sessionOpenTime: (number | string);
    serviceExpireTime: (number | string);
    completionTime: (number | string);
    scoreByUser: number;
    servingSide: boolean;
    reviewed: boolean;
    requestorMobile: string;
    workerMobile: string;
    otcPriced: boolean;
    entitledToAssign: boolean;
    delegateAccepted: boolean;
    serviceProviderSelectable: boolean;
    customerService: boolean;
    preSale: boolean;
    serviceClassifier: string;
    unreadMessageCountByWorker: number;
    unreadMessageCountByRequestor: number;
    syncStatus: SettlementBillSyncStatus;
    miniCode: string;
    exclusiveCustomServiceSessionSupported: boolean;

    constructor() {
        super();
    }

    public getTownId(): (number | string) {
        return this.townId;
    }

    public setTownId(townId: (number | string)) {
        this.townId = townId;
    }

    public getPeriod(): number {
        return this.period;
    }

    public setPeriod(period: number) {
        this.period = period;
    }

    public getTradableId(): (number | string) {
        return this.tradableId;
    }

    public setTradableId(tradableId: (number | string)) {
        this.tradableId = tradableId;
    }

    public getPkgTradableId(): (number | string) {
        return this.pkgTradableId;
    }

    public setPkgTradableId(pkgTradableId: (number | string)) {
        this.pkgTradableId = pkgTradableId;
    }

    public getTradePkgId(): (number | string) {
        return this.tradePkgId;
    }

    public setTradePkgId(tradePkgId: (number | string)) {
        this.tradePkgId = tradePkgId;
    }

    public getOrderId(): (number | string) {
        return this.orderId;
    }

    public setOrderId(orderId: (number | string)) {
        this.orderId = orderId;
    }

    public isFromMiniService(): boolean {
        return this.fromMiniService;
    }

    public setFromMiniService(fromMiniService: boolean) {
        this.fromMiniService = fromMiniService;
    }

    public getEnterpriseServiceType(): EnterpriseServiceType {
        return this.enterpriseServiceType;
    }

    public setEnterpriseServiceType(enterpriseServiceType: EnterpriseServiceType) {
        this.enterpriseServiceType = enterpriseServiceType;
    }

    public getServiceTypeName(): string {
        return this.serviceTypeName;
    }

    public setServiceTypeName(serviceTypeName: string) {
        this.serviceTypeName = serviceTypeName;
    }

    public getServiceTypeDisplayName(): string {
        return this.serviceTypeDisplayName;
    }

    public setServiceTypeDisplayName(serviceTypeDisplayName: string) {
        this.serviceTypeDisplayName = serviceTypeDisplayName;
    }

    public getServiceCategoryName(): string {
        return this.serviceCategoryName;
    }

    public setServiceCategoryName(serviceCategoryName: string) {
        this.serviceCategoryName = serviceCategoryName;
    }

    public getTradeId(): (number | string) {
        return this.tradeId;
    }

    public setTradeId(tradeId: (number | string)) {
        this.tradeId = tradeId;
    }

    public getTrade(): AbstractEnterpriseServiceTrade {
        return this.trade;
    }

    public setTrade(trade: AbstractEnterpriseServiceTrade) {
        this.trade = trade;
    }

    public getOrderingEnterpriseInfo(): EntityInfo {
        return this.orderingEnterpriseInfo;
    }

    public setOrderingEnterpriseInfo(orderingEnterpriseInfo: EntityInfo) {
        this.orderingEnterpriseInfo = orderingEnterpriseInfo;
    }

    public getServingEnterpriseInfo(): EntityInfo {
        return this.servingEnterpriseInfo;
    }

    public setServingEnterpriseInfo(servingEnterpriseInfo: EntityInfo) {
        this.servingEnterpriseInfo = servingEnterpriseInfo;
    }

    public getServingProfessionalInfo(): EntityInfo {
        return this.servingProfessionalInfo;
    }

    public setServingProfessionalInfo(servingProfessionalInfo: EntityInfo) {
        this.servingProfessionalInfo = servingProfessionalInfo;
    }

    public isToDelegate(): boolean {
        return this.toDelegate;
    }

    public setToDelegate(toDelegate: boolean) {
        this.toDelegate = toDelegate;
    }

    public getServingEntWeChatUrl(): string {
        return this.servingEntWeChatUrl;
    }

    public setServingEntWeChatUrl(servingEntWeChatUrl: string) {
        this.servingEntWeChatUrl = servingEntWeChatUrl;
    }

    public getCustomerServiceWechatUrl(): string {
        return this.customerServiceWechatUrl;
    }

    public setCustomerServiceWechatUrl(customerServiceWechatUrl: string) {
        this.customerServiceWechatUrl = customerServiceWechatUrl;
    }

    public getCustomerServicePhone(): string {
        return this.customerServicePhone;
    }

    public setCustomerServicePhone(customerServicePhone: string) {
        this.customerServicePhone = customerServicePhone;
    }

    public getSecretaryInfo(): EntityInfo {
        return this.secretaryInfo;
    }

    public setSecretaryInfo(secretaryInfo: EntityInfo) {
        this.secretaryInfo = secretaryInfo;
    }

    public getAssistEnterpriseInfo(): EntityInfo {
        return this.assistEnterpriseInfo;
    }

    public setAssistEnterpriseInfo(assistEnterpriseInfo: EntityInfo) {
        this.assistEnterpriseInfo = assistEnterpriseInfo;
    }

    public getAssistantInfo(): EntityInfo {
        return this.assistantInfo;
    }

    public setAssistantInfo(assistantInfo: EntityInfo) {
        this.assistantInfo = assistantInfo;
    }

    public getCustomerServiceInfo(): EntityInfo {
        return this.customerServiceInfo;
    }

    public setCustomerServiceInfo(customerServiceInfo: EntityInfo) {
        this.customerServiceInfo = customerServiceInfo;
    }

    public getCustomerServiceWorkerInfo(): EntityInfo {
        return this.customerServiceWorkerInfo;
    }

    public setCustomerServiceWorkerInfo(customerServiceWorkerInfo: EntityInfo) {
        this.customerServiceWorkerInfo = customerServiceWorkerInfo;
    }

    public isPersonal(): boolean {
        return this.personal;
    }

    public setPersonal(personal: boolean) {
        this.personal = personal;
    }

    public getAssignerInfo(): EntityInfo {
        return this.assignerInfo;
    }

    public setAssignerInfo(assignerInfo: EntityInfo) {
        this.assignerInfo = assignerInfo;
    }

    public getSpecificInfo(): ServiceRequestSpecificInfo {
        return this.specificInfo;
    }

    public setSpecificInfo(specificInfo: ServiceRequestSpecificInfo) {
        this.specificInfo = specificInfo;
    }

    public getPaymentSpec(): PaymentSpec {
        return this.paymentSpec;
    }

    public setPaymentSpec(paymentSpec: PaymentSpec) {
        this.paymentSpec = paymentSpec;
    }

    public getStatus(): RequestStatusType {
        return this.status;
    }

    public setStatus(status: RequestStatusType) {
        this.status = status;
    }

    public getSessionOpenTime(): (number | string) {
        return this.sessionOpenTime;
    }

    public setSessionOpenTime(sessionOpenTime: (number | string)) {
        this.sessionOpenTime = sessionOpenTime;
    }

    public getServiceExpireTime(): (number | string) {
        return this.serviceExpireTime;
    }

    public setServiceExpireTime(serviceExpireTime: (number | string)) {
        this.serviceExpireTime = serviceExpireTime;
    }

    public getCompletionTime(): (number | string) {
        return this.completionTime;
    }

    public setCompletionTime(completionTime: (number | string)) {
        this.completionTime = completionTime;
    }

    public getScoreByUser(): number {
        return this.scoreByUser;
    }

    public setScoreByUser(scoreByUser: number) {
        this.scoreByUser = scoreByUser;
    }

    public isServingSide(): boolean {
        return this.servingSide;
    }

    public setServingSide(servingSide: boolean) {
        this.servingSide = servingSide;
    }

    public isReviewed(): boolean {
        return this.reviewed;
    }

    public setReviewed(reviewed: boolean) {
        this.reviewed = reviewed;
    }

    public getRequestorMobile(): string {
        return this.requestorMobile;
    }

    public setRequestorMobile(requestorMobile: string) {
        this.requestorMobile = requestorMobile;
    }

    public getWorkerMobile(): string {
        return this.workerMobile;
    }

    public setWorkerMobile(workerMobile: string) {
        this.workerMobile = workerMobile;
    }

    public isOtcPriced(): boolean {
        return this.otcPriced;
    }

    public setOtcPriced(otcPriced: boolean) {
        this.otcPriced = otcPriced;
    }

    public isEntitledToAssign(): boolean {
        return this.entitledToAssign;
    }

    public setEntitledToAssign(entitledToAssign: boolean) {
        this.entitledToAssign = entitledToAssign;
    }

    public isDelegateAccepted(): boolean {
        return this.delegateAccepted;
    }

    public setDelegateAccepted(delegateAccepted: boolean) {
        this.delegateAccepted = delegateAccepted;
    }

    public isServiceProviderSelectable(): boolean {
        return this.serviceProviderSelectable;
    }

    public setServiceProviderSelectable(serviceProviderSelectable: boolean) {
        this.serviceProviderSelectable = serviceProviderSelectable;
    }

    public isCustomerService(): boolean {
        return this.customerService;
    }

    public setCustomerService(customerService: boolean) {
        this.customerService = customerService;
    }

    public isPreSale(): boolean {
        return this.preSale;
    }

    public setPreSale(preSale: boolean) {
        this.preSale = preSale;
    }

    public getServiceClassifier(): string {
        return this.serviceClassifier;
    }

    public setServiceClassifier(serviceClassifier: string) {
        this.serviceClassifier = serviceClassifier;
    }

    public getUnreadMessageCountByWorker(): number {
        return this.unreadMessageCountByWorker;
    }

    public setUnreadMessageCountByWorker(unreadMessageCountByWorker: number) {
        this.unreadMessageCountByWorker = unreadMessageCountByWorker;
    }

    public getUnreadMessageCountByRequestor(): number {
        return this.unreadMessageCountByRequestor;
    }

    public setUnreadMessageCountByRequestor(unreadMessageCountByRequestor: number) {
        this.unreadMessageCountByRequestor = unreadMessageCountByRequestor;
    }

    public getSyncStatus(): SettlementBillSyncStatus {
        return this.syncStatus;
    }

    public setSyncStatus(syncStatus: SettlementBillSyncStatus) {
        this.syncStatus = syncStatus;
    }

    public getMiniCode(): string {
        return this.miniCode;
    }

    public setMiniCode(miniCode: string) {
        this.miniCode = miniCode;
    }

    public getExclusiveCustomServiceSessionSupported(): boolean {
        return this.exclusiveCustomServiceSessionSupported;
    }

    public setExclusiveCustomServiceSessionSupported(exclusiveCustomServiceSessionSupported: boolean) {
        this.exclusiveCustomServiceSessionSupported = exclusiveCustomServiceSessionSupported;
    }


    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;

    abstract getTextType(): TextType;

    abstract getText(): string;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let townId = json["townId"];
        if (townId != null) {
            this.setTownId(townId);
        }
        let period = json["period"];
        if (period != null) {
            this.setPeriod(period);
        }
        let tradableId = json["tradableId"];
        if (tradableId != null) {
            this.setTradableId(tradableId);
        }
        let pkgTradableId = json["pkgTradableId"];
        if (pkgTradableId != null) {
            this.setPkgTradableId(pkgTradableId);
        }
        let tradePkgId = json["tradePkgId"];
        if (tradePkgId != null) {
            this.setTradePkgId(tradePkgId);
        }
        let orderId = json["orderId"];
        if (orderId != null) {
            this.setOrderId(orderId);
        }
        let fromMiniService = json["fromMiniService"];
        if (fromMiniService != null) {
            this.setFromMiniService(fromMiniService);
        }
        let enterpriseServiceType = json["enterpriseServiceType"];
        if (enterpriseServiceType != null) {
            let convertedEnterpriseServiceType0 = EnterpriseServiceType[<string>enterpriseServiceType];
            this.setEnterpriseServiceType(convertedEnterpriseServiceType0);
        }
        let serviceTypeName = json["serviceTypeName"];
        if (serviceTypeName != null) {
            this.setServiceTypeName(serviceTypeName);
        }
        let serviceTypeDisplayName = json["serviceTypeDisplayName"];
        if (serviceTypeDisplayName != null) {
            this.setServiceTypeDisplayName(serviceTypeDisplayName);
        }
        let serviceCategoryName = json["serviceCategoryName"];
        if (serviceCategoryName != null) {
            this.setServiceCategoryName(serviceCategoryName);
        }
        let tradeId = json["tradeId"];
        if (tradeId != null) {
            this.setTradeId(tradeId);
        }
        let trade = json["trade"];
        if (trade != null) {
            let convertedTrade0 = Serializer.fillFromJsonObjectWithTypeTag(trade, "__type", factory);
            this.setTrade(convertedTrade0);
        }
        let orderingEnterpriseInfo = json["orderingEnterpriseInfo"];
        if (orderingEnterpriseInfo != null) {
            let convertedOrderingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(orderingEnterpriseInfo, "__type", factory);
            this.setOrderingEnterpriseInfo(convertedOrderingEnterpriseInfo0);
        }
        let servingEnterpriseInfo = json["servingEnterpriseInfo"];
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(servingEnterpriseInfo, "__type", factory);
            this.setServingEnterpriseInfo(convertedServingEnterpriseInfo0);
        }
        let servingProfessionalInfo = json["servingProfessionalInfo"];
        if (servingProfessionalInfo != null) {
            let convertedServingProfessionalInfo0 = Serializer.fillFromJsonObjectWithTypeTag(servingProfessionalInfo, "__type", factory);
            this.setServingProfessionalInfo(convertedServingProfessionalInfo0);
        }
        let toDelegate = json["toDelegate"];
        if (toDelegate != null) {
            this.setToDelegate(toDelegate);
        }
        let servingEntWeChatUrl = json["servingEntWeChatUrl"];
        if (servingEntWeChatUrl != null) {
            this.setServingEntWeChatUrl(servingEntWeChatUrl);
        }
        let customerServiceWechatUrl = json["customerServiceWechatUrl"];
        if (customerServiceWechatUrl != null) {
            this.setCustomerServiceWechatUrl(customerServiceWechatUrl);
        }
        let customerServicePhone = json["customerServicePhone"];
        if (customerServicePhone != null) {
            this.setCustomerServicePhone(customerServicePhone);
        }
        let secretaryInfo = json["secretaryInfo"];
        if (secretaryInfo != null) {
            let convertedSecretaryInfo0 = Serializer.fillFromJsonObjectWithTypeTag(secretaryInfo, "__type", factory);
            this.setSecretaryInfo(convertedSecretaryInfo0);
        }
        let assistEnterpriseInfo = json["assistEnterpriseInfo"];
        if (assistEnterpriseInfo != null) {
            let convertedAssistEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(assistEnterpriseInfo, "__type", factory);
            this.setAssistEnterpriseInfo(convertedAssistEnterpriseInfo0);
        }
        let assistantInfo = json["assistantInfo"];
        if (assistantInfo != null) {
            let convertedAssistantInfo0 = Serializer.fillFromJsonObjectWithTypeTag(assistantInfo, "__type", factory);
            this.setAssistantInfo(convertedAssistantInfo0);
        }
        let customerServiceInfo = json["customerServiceInfo"];
        if (customerServiceInfo != null) {
            let convertedCustomerServiceInfo0 = Serializer.fillFromJsonObjectWithTypeTag(customerServiceInfo, "__type", factory);
            this.setCustomerServiceInfo(convertedCustomerServiceInfo0);
        }
        let customerServiceWorkerInfo = json["customerServiceWorkerInfo"];
        if (customerServiceWorkerInfo != null) {
            let convertedCustomerServiceWorkerInfo0 = Serializer.fillFromJsonObjectWithTypeTag(customerServiceWorkerInfo, "__type", factory);
            this.setCustomerServiceWorkerInfo(convertedCustomerServiceWorkerInfo0);
        }
        let personal = json["personal"];
        if (personal != null) {
            this.setPersonal(personal);
        }
        let assignerInfo = json["assignerInfo"];
        if (assignerInfo != null) {
            let convertedAssignerInfo0 = Serializer.fillFromJsonObjectWithTypeTag(assignerInfo, "__type", factory);
            this.setAssignerInfo(convertedAssignerInfo0);
        }
        let specificInfo = json["specificInfo"];
        if (specificInfo != null) {
            let convertedSpecificInfo0 = Serializer.fromJson(specificInfo, factory);
            this.setSpecificInfo(convertedSpecificInfo0);
        }
        let paymentSpec = json["paymentSpec"];
        if (paymentSpec != null) {
            let convertedPaymentSpec0 = new PaymentSpec();
            convertedPaymentSpec0.fillFromJson(paymentSpec, factory);
            this.setPaymentSpec(convertedPaymentSpec0);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = RequestStatusType[<string>status];
            this.setStatus(convertedStatus0);
        }
        let sessionOpenTime = json["sessionOpenTime"];
        if (sessionOpenTime != null) {
            this.setSessionOpenTime(sessionOpenTime);
        }
        let serviceExpireTime = json["serviceExpireTime"];
        if (serviceExpireTime != null) {
            this.setServiceExpireTime(serviceExpireTime);
        }
        let completionTime = json["completionTime"];
        if (completionTime != null) {
            this.setCompletionTime(completionTime);
        }
        let scoreByUser = json["scoreByUser"];
        if (scoreByUser != null) {
            this.setScoreByUser(scoreByUser);
        }
        let servingSide = json["servingSide"];
        if (servingSide != null) {
            this.setServingSide(servingSide);
        }
        let reviewed = json["reviewed"];
        if (reviewed != null) {
            this.setReviewed(reviewed);
        }
        let requestorMobile = json["requestorMobile"];
        if (requestorMobile != null) {
            this.setRequestorMobile(requestorMobile);
        }
        let workerMobile = json["workerMobile"];
        if (workerMobile != null) {
            this.setWorkerMobile(workerMobile);
        }
        let otcPriced = json["otcPriced"];
        if (otcPriced != null) {
            this.setOtcPriced(otcPriced);
        }
        let entitledToAssign = json["entitledToAssign"];
        if (entitledToAssign != null) {
            this.setEntitledToAssign(entitledToAssign);
        }
        let delegateAccepted = json["delegateAccepted"];
        if (delegateAccepted != null) {
            this.setDelegateAccepted(delegateAccepted);
        }
        let serviceProviderSelectable = json["serviceProviderSelectable"];
        if (serviceProviderSelectable != null) {
            this.setServiceProviderSelectable(serviceProviderSelectable);
        }
        let customerService = json["customerService"];
        if (customerService != null) {
            this.setCustomerService(customerService);
        }
        let preSale = json["preSale"];
        if (preSale != null) {
            this.setPreSale(preSale);
        }
        let serviceClassifier = json["serviceClassifier"];
        if (serviceClassifier != null) {
            this.setServiceClassifier(serviceClassifier);
        }
        let unreadMessageCountByWorker = json["unreadMessageCountByWorker"];
        if (unreadMessageCountByWorker != null) {
            this.setUnreadMessageCountByWorker(unreadMessageCountByWorker);
        }
        let unreadMessageCountByRequestor = json["unreadMessageCountByRequestor"];
        if (unreadMessageCountByRequestor != null) {
            this.setUnreadMessageCountByRequestor(unreadMessageCountByRequestor);
        }
        let syncStatus = json["syncStatus"];
        if (syncStatus != null) {
            let convertedSyncStatus0 = SettlementBillSyncStatus[<string>syncStatus];
            this.setSyncStatus(convertedSyncStatus0);
        }
        let miniCode = json["miniCode"];
        if (miniCode != null) {
            this.setMiniCode(miniCode);
        }
        let exclusiveCustomServiceSessionSupported = json["exclusiveCustomServiceSessionSupported"];
        if (exclusiveCustomServiceSessionSupported != null) {
            this.setExclusiveCustomServiceSessionSupported(exclusiveCustomServiceSessionSupported);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let townId = this.getTownId();
        if (townId != null) {
            json["townId"] = String(townId);
        }
        let period = this.getPeriod();
        if (period != null) {
            json["period"] = period;
        }
        let tradableId = this.getTradableId();
        if (tradableId != null) {
            json["tradableId"] = String(tradableId);
        }
        let pkgTradableId = this.getPkgTradableId();
        if (pkgTradableId != null) {
            json["pkgTradableId"] = String(pkgTradableId);
        }
        let tradePkgId = this.getTradePkgId();
        if (tradePkgId != null) {
            json["tradePkgId"] = String(tradePkgId);
        }
        let orderId = this.getOrderId();
        if (orderId != null) {
            json["orderId"] = String(orderId);
        }
        let fromMiniService = this.isFromMiniService();
        if (fromMiniService != null) {
            json["fromMiniService"] = fromMiniService;
        }
        let enterpriseServiceType = this.getEnterpriseServiceType();
        if (enterpriseServiceType != null) {
            let convertedEnterpriseServiceType0 = EnterpriseServiceType[enterpriseServiceType];
            json["enterpriseServiceType"] = convertedEnterpriseServiceType0;
        }
        let serviceTypeName = this.getServiceTypeName();
        if (serviceTypeName != null) {
            json["serviceTypeName"] = serviceTypeName;
        }
        let serviceTypeDisplayName = this.getServiceTypeDisplayName();
        if (serviceTypeDisplayName != null) {
            json["serviceTypeDisplayName"] = serviceTypeDisplayName;
        }
        let serviceCategoryName = this.getServiceCategoryName();
        if (serviceCategoryName != null) {
            json["serviceCategoryName"] = serviceCategoryName;
        }
        let tradeId = this.getTradeId();
        if (tradeId != null) {
            json["tradeId"] = String(tradeId);
        }
        let trade = this.getTrade();
        if (trade != null) {
            let convertedTrade0 = trade.getJson(JsonTypeTagType.TYPE);
            json["trade"] = convertedTrade0;
        }
        let orderingEnterpriseInfo = this.getOrderingEnterpriseInfo();
        if (orderingEnterpriseInfo != null) {
            let convertedOrderingEnterpriseInfo0 = orderingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["orderingEnterpriseInfo"] = convertedOrderingEnterpriseInfo0;
        }
        let servingEnterpriseInfo = this.getServingEnterpriseInfo();
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = servingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["servingEnterpriseInfo"] = convertedServingEnterpriseInfo0;
        }
        let servingProfessionalInfo = this.getServingProfessionalInfo();
        if (servingProfessionalInfo != null) {
            let convertedServingProfessionalInfo0 = servingProfessionalInfo.getJson(JsonTypeTagType.TYPE);
            json["servingProfessionalInfo"] = convertedServingProfessionalInfo0;
        }
        let toDelegate = this.isToDelegate();
        if (toDelegate != null) {
            json["toDelegate"] = toDelegate;
        }
        let servingEntWeChatUrl = this.getServingEntWeChatUrl();
        if (servingEntWeChatUrl != null) {
            json["servingEntWeChatUrl"] = servingEntWeChatUrl;
        }
        let customerServiceWechatUrl = this.getCustomerServiceWechatUrl();
        if (customerServiceWechatUrl != null) {
            json["customerServiceWechatUrl"] = customerServiceWechatUrl;
        }
        let customerServicePhone = this.getCustomerServicePhone();
        if (customerServicePhone != null) {
            json["customerServicePhone"] = customerServicePhone;
        }
        let secretaryInfo = this.getSecretaryInfo();
        if (secretaryInfo != null) {
            let convertedSecretaryInfo0 = secretaryInfo.getJson(JsonTypeTagType.TYPE);
            json["secretaryInfo"] = convertedSecretaryInfo0;
        }
        let assistEnterpriseInfo = this.getAssistEnterpriseInfo();
        if (assistEnterpriseInfo != null) {
            let convertedAssistEnterpriseInfo0 = assistEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["assistEnterpriseInfo"] = convertedAssistEnterpriseInfo0;
        }
        let assistantInfo = this.getAssistantInfo();
        if (assistantInfo != null) {
            let convertedAssistantInfo0 = assistantInfo.getJson(JsonTypeTagType.TYPE);
            json["assistantInfo"] = convertedAssistantInfo0;
        }
        let customerServiceInfo = this.getCustomerServiceInfo();
        if (customerServiceInfo != null) {
            let convertedCustomerServiceInfo0 = customerServiceInfo.getJson(JsonTypeTagType.TYPE);
            json["customerServiceInfo"] = convertedCustomerServiceInfo0;
        }
        let customerServiceWorkerInfo = this.getCustomerServiceWorkerInfo();
        if (customerServiceWorkerInfo != null) {
            let convertedCustomerServiceWorkerInfo0 = customerServiceWorkerInfo.getJson(JsonTypeTagType.TYPE);
            json["customerServiceWorkerInfo"] = convertedCustomerServiceWorkerInfo0;
        }
        let personal = this.isPersonal();
        if (personal != null) {
            json["personal"] = personal;
        }
        let assignerInfo = this.getAssignerInfo();
        if (assignerInfo != null) {
            let convertedAssignerInfo0 = assignerInfo.getJson(JsonTypeTagType.TYPE);
            json["assignerInfo"] = convertedAssignerInfo0;
        }
        let specificInfo = this.getSpecificInfo();
        if (specificInfo != null) {
            let convertedSpecificInfo0 = Serializer.getJson(specificInfo, JsonTypeTagType.CLASS);
            json["specificInfo"] = convertedSpecificInfo0;
        }
        let paymentSpec = this.getPaymentSpec();
        if (paymentSpec != null) {
            let convertedPaymentSpec0 = paymentSpec.getJson(JsonTypeTagType.NONE);
            json["paymentSpec"] = convertedPaymentSpec0;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = RequestStatusType[status];
            json["status"] = convertedStatus0;
        }
        let sessionOpenTime = this.getSessionOpenTime();
        if (sessionOpenTime != null) {
            json["sessionOpenTime"] = String(sessionOpenTime);
        }
        let serviceExpireTime = this.getServiceExpireTime();
        if (serviceExpireTime != null) {
            json["serviceExpireTime"] = String(serviceExpireTime);
        }
        let completionTime = this.getCompletionTime();
        if (completionTime != null) {
            json["completionTime"] = String(completionTime);
        }
        let scoreByUser = this.getScoreByUser();
        if (scoreByUser != null) {
            json["scoreByUser"] = scoreByUser;
        }
        let servingSide = this.isServingSide();
        if (servingSide != null) {
            json["servingSide"] = servingSide;
        }
        let reviewed = this.isReviewed();
        if (reviewed != null) {
            json["reviewed"] = reviewed;
        }
        let requestorMobile = this.getRequestorMobile();
        if (requestorMobile != null) {
            json["requestorMobile"] = requestorMobile;
        }
        let workerMobile = this.getWorkerMobile();
        if (workerMobile != null) {
            json["workerMobile"] = workerMobile;
        }
        let otcPriced = this.isOtcPriced();
        if (otcPriced != null) {
            json["otcPriced"] = otcPriced;
        }
        let entitledToAssign = this.isEntitledToAssign();
        if (entitledToAssign != null) {
            json["entitledToAssign"] = entitledToAssign;
        }
        let delegateAccepted = this.isDelegateAccepted();
        if (delegateAccepted != null) {
            json["delegateAccepted"] = delegateAccepted;
        }
        let serviceProviderSelectable = this.isServiceProviderSelectable();
        if (serviceProviderSelectable != null) {
            json["serviceProviderSelectable"] = serviceProviderSelectable;
        }
        let customerService = this.isCustomerService();
        if (customerService != null) {
            json["customerService"] = customerService;
        }
        let preSale = this.isPreSale();
        if (preSale != null) {
            json["preSale"] = preSale;
        }
        let serviceClassifier = this.getServiceClassifier();
        if (serviceClassifier != null) {
            json["serviceClassifier"] = serviceClassifier;
        }
        let unreadMessageCountByWorker = this.getUnreadMessageCountByWorker();
        if (unreadMessageCountByWorker != null) {
            json["unreadMessageCountByWorker"] = unreadMessageCountByWorker;
        }
        let unreadMessageCountByRequestor = this.getUnreadMessageCountByRequestor();
        if (unreadMessageCountByRequestor != null) {
            json["unreadMessageCountByRequestor"] = unreadMessageCountByRequestor;
        }
        let syncStatus = this.getSyncStatus();
        if (syncStatus != null) {
            let convertedSyncStatus0 = SettlementBillSyncStatus[syncStatus];
            json["syncStatus"] = convertedSyncStatus0;
        }
        let miniCode = this.getMiniCode();
        if (miniCode != null) {
            json["miniCode"] = miniCode;
        }
        let exclusiveCustomServiceSessionSupported = this.getExclusiveCustomServiceSessionSupported();
        if (exclusiveCustomServiceSessionSupported != null) {
            json["exclusiveCustomServiceSessionSupported"] = exclusiveCustomServiceSessionSupported;
        }

        if (includeTypeTag) {
            json["__type"] = "EnterpriseServiceRequest";
        }
    }


    public getTypeId(): string {
        return "EnterpriseServiceRequest";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.service.request.EnterpriseServiceRequest";
    }
}
