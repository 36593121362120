/**
 * 自动生成， 请不要手工修改！
 */

import {CompositeCoworkView} from '../../../cowork/CompositeCoworkView'
import {DistanceInfo} from '../../../geolocation/DistanceInfo'
import {EnterpriseServiceOrderInfo} from '../../order/EnterpriseServiceOrderInfo'
import {EnterpriseServiceTradableInfo} from './EnterpriseServiceTradableInfo'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {ServicePriceDiscountType} from '../../price/ServicePriceDiscountType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class EnterpriseServiceView extends CompositeCoworkView<EnterpriseServiceTradableInfo, DistanceInfo>  {
    orderInfo: EnterpriseServiceOrderInfo;
    priceDiscountType: ServicePriceDiscountType;
    priceOnOffer: number;
    discountOnOffer: number;

    constructor() {
    	super();
    }

    public getOrderInfo(): EnterpriseServiceOrderInfo {
    	return this.orderInfo;
    }

    public setOrderInfo(orderInfo: EnterpriseServiceOrderInfo) {
    	this.orderInfo = orderInfo;
    }

    public getPriceDiscountType(): ServicePriceDiscountType {
    	return this.priceDiscountType;
    }

    public setPriceDiscountType(priceDiscountType: ServicePriceDiscountType) {
    	this.priceDiscountType = priceDiscountType;
    }

    public getPriceOnOffer(): number {
    	return this.priceOnOffer;
    }

    public setPriceOnOffer(priceOnOffer: number) {
    	this.priceOnOffer = priceOnOffer;
    }

    public getDiscountOnOffer(): number {
    	return this.discountOnOffer;
    }

    public setDiscountOnOffer(discountOnOffer: number) {
    	this.discountOnOffer = discountOnOffer;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let orderInfo = json["orderInfo"];
        if (orderInfo != null) {
            let convertedOrderInfo0 = new EnterpriseServiceOrderInfo();
            convertedOrderInfo0.fillFromJson(orderInfo, factory);
            this.setOrderInfo(convertedOrderInfo0);
        }
        let priceDiscountType = json["priceDiscountType"];
        if (priceDiscountType != null) {
            let convertedPriceDiscountType0 = ServicePriceDiscountType[<string>priceDiscountType];
            this.setPriceDiscountType(convertedPriceDiscountType0);
        }
        let priceOnOffer = json["priceOnOffer"];
        if (priceOnOffer != null) {
            this.setPriceOnOffer(priceOnOffer);
        }
        let discountOnOffer = json["discountOnOffer"];
        if (discountOnOffer != null) {
            this.setDiscountOnOffer(discountOnOffer);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let orderInfo = this.getOrderInfo();
        if (orderInfo != null) {
            let convertedOrderInfo0 = orderInfo.getJson(JsonTypeTagType.NONE);
            json["orderInfo"] = convertedOrderInfo0;
        }
        let priceDiscountType = this.getPriceDiscountType();
        if (priceDiscountType != null) {
            let convertedPriceDiscountType0 = ServicePriceDiscountType[priceDiscountType];
            json["priceDiscountType"] = convertedPriceDiscountType0;
        }
        let priceOnOffer = this.getPriceOnOffer();
        if (priceOnOffer != null) {
            json["priceOnOffer"] = priceOnOffer;
        }
        let discountOnOffer = this.getDiscountOnOffer();
        if (discountOnOffer != null) {
            json["discountOnOffer"] = discountOnOffer;
        }
    
        if (includeTypeTag) {
            json["__type"] = "EnterpriseServiceView";
        }
    }


    public getTypeId(): string {
    	return "EnterpriseServiceView";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceView";
    }
}
