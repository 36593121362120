/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import {CoworkOp} from "../../../datatype/cowork/CoworkOp";
import {MessageType} from "../../MessageContentType";
import {MessageContent} from "../../MessageContent";
import { TextizableContent } from "../../entity/api/TextizableContent";
import { TextType } from "../../../datatype/TextType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractServiceRequestOp
  extends AbstractSerializable
  implements MessageContent, TextizableContent
{
  coworkOp: CoworkOp;
  notes: string;

  constructor() {
    super();
  }

  public getCoworkOp(): CoworkOp {
    return this.coworkOp;
  }

  public setCoworkOp(coworkOp: CoworkOp) {
    this.coworkOp = coworkOp;
  }

    public getNotes(): string {
        return this.notes;
    }

    public setNotes(notes: string) {
        this.notes = notes;
    }

    getMessageType(): MessageType {
        return MessageType.OP;
    }

    getTextType(): TextType {
        return TextType.PLAIN;
    }

    getText(): string {
        return this.notes;
    }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const coworkOp = json["coworkOp"];
    if (coworkOp != null) {
      const convertedCoworkOp0 = CoworkOp[<string>coworkOp];
      this.setCoworkOp(convertedCoworkOp0);
    }
    const notes = json["notes"];
    if (notes != null) {
      this.setNotes(notes);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const coworkOp = this.getCoworkOp();
    if (coworkOp != null) {
      const convertedCoworkOp0 = CoworkOp[coworkOp];
      json["coworkOp"] = convertedCoworkOp0;
    }
    const notes = this.getNotes();
    if (notes != null) {
      json["notes"] = notes;
    }

    if (includeTypeTag) {
      json["__type"] = "ServiceRequestOp";
    }
  }

  public getTypeId(): string {
    return "ServiceRequestOp";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.business.service.ServiceRequestOp";
  }
}
