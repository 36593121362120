/**
 * Created by Zhonghua on 02/12/2016.
 */

import {
  WebSocketConnectionManager,
  WebSocketConnectionManagerImpl,
} from "./WebSocketConnectionManager";

/**
 * Web Socket, 提供合适的链接管理接口。(扯淡的http这么用)
 */
export class ConnectionManagerFactory {
  private static connectionManager: WebSocketConnectionManager = null;
  public static getOrCreateWebSocketConnectionManager(
    defaultUrl?: string
  ): WebSocketConnectionManager {
    if (!ConnectionManagerFactory.connectionManager) {
      ConnectionManagerFactory.connectionManager =
        new WebSocketConnectionManagerImpl(defaultUrl);
    }
    return ConnectionManagerFactory.connectionManager;
  }
}
