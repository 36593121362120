/**
 * 自动生成， 请不要手工修改！
 */

import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { MessageFilter } from "./MessageFilter";
import { QuerySpec } from "../QuerySpec";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class MessageQuerySpec extends QuerySpec<MessageFilter> {
  constructor() {
    super();
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);

    if (includeTypeTag) {
      json["__type"] = "MessageQuerySpec";
    }
  }

  public getTypeId(): string {
    return "MessageQuerySpec";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.message.MessageQuerySpec";
  }
}
