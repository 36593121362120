// @ts-nocheck
import {AbstractDefaultPageDataSpec} from './AbstractDefaultPageDataSpec'
import {PageBlockAccessSpec} from './PageBlockAccessSpec'
import {PageDataSpec} from './PageDataSpec'


export class DefaultPageDataSpec extends AbstractDefaultPageDataSpec {
    constructor() {
        super();
    }

    getForwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec {
        // Manual Code Here
    }

    getForwardPageSpecWithDefaultSize(): PageBlockAccessSpec {
        // Manual Code Here
    }

    getNextBackwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec {
        // Manual Code Here
    }

    getNextBackwardPageSpecWithDefaultSize(): PageBlockAccessSpec {
        // Manual Code Here
    }

    addPageDataSpec(arg0: (PageDataSpec | null), arg1: (boolean | null)): PageDataSpec {
        // Manual Code Here
    }

    getContinuousNoDataCount(): number {
        // Manual Code Here
    }

}
