/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractEnterpriseServiceTrade} from '../service/trade/AbstractEnterpriseServiceTrade'
import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {BusinessContactInfo} from '../BusinessContactInfo'
import {EnterpriseServiceRequest} from '../service/request/EnterpriseServiceRequest'
import {EntityInfo} from '../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {OrderStatus} from './OrderStatus'
import {OrderUseStatus} from './OrderUseStatus'
import {PaymentSpec} from '../payment/PaymentSpec'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class EnterpriseServiceOrder extends AbstractSerializable  {
    orderId: (number | string);
    tradableId: (number | string);
    pkgTradableId: (number | string);
    servicePackage: boolean;
    refTradeId: (number | string);
    serviceRequestId: (number | string);
    trade: AbstractEnterpriseServiceTrade;
    serviceRequest: EnterpriseServiceRequest;
    title: string;
    serviceStandardLink: string;
    userProtocolLink: string;
    tradableImgUrls: Array<string>;
    orderEnterpriseInfo: EntityInfo;
    orderUserInfo: EntityInfo;
    regionId: (number | string);
    orderUnits: number;
    firstPaymentSpec: PaymentSpec;
    balancePaySchedule: any;
    multiLegsPayment: boolean;
    orderStatus: OrderStatus;
    orderUseStatus: OrderUseStatus;
    createTime: (number | string);
    lastUpdateTime: (number | string);
    expired: boolean;
    reviewed: boolean;
    otcPriced: boolean;
    inviteCode: string;
    userProtocolRead: boolean;
    contactInfo: BusinessContactInfo;
    exclusiveCustomServiceSessionSupported: boolean;

    constructor() {
    	super();
    }

    public getOrderId(): (number | string) {
    	return this.orderId;
    }

    public setOrderId(orderId: (number | string)) {
    	this.orderId = orderId;
    }

    public getTradableId(): (number | string) {
    	return this.tradableId;
    }

    public setTradableId(tradableId: (number | string)) {
    	this.tradableId = tradableId;
    }

    public getPkgTradableId(): (number | string) {
    	return this.pkgTradableId;
    }

    public setPkgTradableId(pkgTradableId: (number | string)) {
    	this.pkgTradableId = pkgTradableId;
    }

    public isServicePackage(): boolean {
    	return this.servicePackage;
    }

    public setServicePackage(servicePackage: boolean) {
    	this.servicePackage = servicePackage;
    }

    public getRefTradeId(): (number | string) {
    	return this.refTradeId;
    }

    public setRefTradeId(refTradeId: (number | string)) {
    	this.refTradeId = refTradeId;
    }

    public getServiceRequestId(): (number | string) {
    	return this.serviceRequestId;
    }

    public setServiceRequestId(serviceRequestId: (number | string)) {
    	this.serviceRequestId = serviceRequestId;
    }

    public getTrade(): AbstractEnterpriseServiceTrade {
    	return this.trade;
    }

    public setTrade(trade: AbstractEnterpriseServiceTrade) {
    	this.trade = trade;
    }

    public getServiceRequest(): EnterpriseServiceRequest {
    	return this.serviceRequest;
    }

    public setServiceRequest(serviceRequest: EnterpriseServiceRequest) {
    	this.serviceRequest = serviceRequest;
    }

    public getTitle(): string {
    	return this.title;
    }

    public setTitle(title: string) {
    	this.title = title;
    }

    public getServiceStandardLink(): string {
    	return this.serviceStandardLink;
    }

    public setServiceStandardLink(serviceStandardLink: string) {
    	this.serviceStandardLink = serviceStandardLink;
    }

    public getUserProtocolLink(): string {
    	return this.userProtocolLink;
    }

    public setUserProtocolLink(userProtocolLink: string) {
    	this.userProtocolLink = userProtocolLink;
    }

    public getTradableImgUrls(): Array<string> {
    	return this.tradableImgUrls;
    }

    public setTradableImgUrls(tradableImgUrls: Array<string>) {
    	this.tradableImgUrls = tradableImgUrls;
    }

    public getOrderEnterpriseInfo(): EntityInfo {
    	return this.orderEnterpriseInfo;
    }

    public setOrderEnterpriseInfo(orderEnterpriseInfo: EntityInfo) {
    	this.orderEnterpriseInfo = orderEnterpriseInfo;
    }

    public getOrderUserInfo(): EntityInfo {
    	return this.orderUserInfo;
    }

    public setOrderUserInfo(orderUserInfo: EntityInfo) {
    	this.orderUserInfo = orderUserInfo;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getOrderUnits(): number {
    	return this.orderUnits;
    }

    public setOrderUnits(orderUnits: number) {
    	this.orderUnits = orderUnits;
    }

    public getFirstPaymentSpec(): PaymentSpec {
    	return this.firstPaymentSpec;
    }

    public setFirstPaymentSpec(firstPaymentSpec: PaymentSpec) {
    	this.firstPaymentSpec = firstPaymentSpec;
    }

    public getBalancePaySchedule(): any {
    	return this.balancePaySchedule;
    }

    public setBalancePaySchedule(balancePaySchedule: any) {
    	this.balancePaySchedule = balancePaySchedule;
    }

    public isMultiLegsPayment(): boolean {
    	return this.multiLegsPayment;
    }

    public setMultiLegsPayment(multiLegsPayment: boolean) {
    	this.multiLegsPayment = multiLegsPayment;
    }

    public getOrderStatus(): OrderStatus {
    	return this.orderStatus;
    }

    public setOrderStatus(orderStatus: OrderStatus) {
    	this.orderStatus = orderStatus;
    }

    public getOrderUseStatus(): OrderUseStatus {
    	return this.orderUseStatus;
    }

    public setOrderUseStatus(orderUseStatus: OrderUseStatus) {
    	this.orderUseStatus = orderUseStatus;
    }

    public getCreateTime(): (number | string) {
    	return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
    	this.createTime = createTime;
    }

    public getLastUpdateTime(): (number | string) {
    	return this.lastUpdateTime;
    }

    public setLastUpdateTime(lastUpdateTime: (number | string)) {
    	this.lastUpdateTime = lastUpdateTime;
    }

    public isExpired(): boolean {
    	return this.expired;
    }

    public setExpired(expired: boolean) {
    	this.expired = expired;
    }

    public isReviewed(): boolean {
    	return this.reviewed;
    }

    public setReviewed(reviewed: boolean) {
    	this.reviewed = reviewed;
    }

    public isOtcPriced(): boolean {
    	return this.otcPriced;
    }

    public setOtcPriced(otcPriced: boolean) {
    	this.otcPriced = otcPriced;
    }

    public getInviteCode(): string {
    	return this.inviteCode;
    }

    public setInviteCode(inviteCode: string) {
    	this.inviteCode = inviteCode;
    }

    public isUserProtocolRead(): boolean {
    	return this.userProtocolRead;
    }

    public setUserProtocolRead(userProtocolRead: boolean) {
    	this.userProtocolRead = userProtocolRead;
    }

    public getContactInfo(): BusinessContactInfo {
    	return this.contactInfo;
    }

    public setContactInfo(contactInfo: BusinessContactInfo) {
    	this.contactInfo = contactInfo;
    }

    public getExclusiveCustomServiceSessionSupported(): boolean {
    	return this.exclusiveCustomServiceSessionSupported;
    }

    public setExclusiveCustomServiceSessionSupported(exclusiveCustomServiceSessionSupported: boolean) {
    	this.exclusiveCustomServiceSessionSupported = exclusiveCustomServiceSessionSupported;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let orderId = json["orderId"];
        if (orderId != null) {
            this.setOrderId(orderId);
        }
        let tradableId = json["tradableId"];
        if (tradableId != null) {
            this.setTradableId(tradableId);
        }
        let pkgTradableId = json["pkgTradableId"];
        if (pkgTradableId != null) {
            this.setPkgTradableId(pkgTradableId);
        }
        let servicePackage = json["servicePackage"];
        if (servicePackage != null) {
            this.setServicePackage(servicePackage);
        }
        let refTradeId = json["refTradeId"];
        if (refTradeId != null) {
            this.setRefTradeId(refTradeId);
        }
        let serviceRequestId = json["serviceRequestId"];
        if (serviceRequestId != null) {
            this.setServiceRequestId(serviceRequestId);
        }
        let trade = json["trade"];
        if (trade != null) {
            let convertedTrade0 = Serializer.fillFromJsonObjectWithTypeTag(trade, "__type", factory);
            this.setTrade(convertedTrade0);
        }
        let serviceRequest = json["serviceRequest"];
        if (serviceRequest != null) {
            let convertedServiceRequest0 = Serializer.fillFromJsonObjectWithTypeTag(serviceRequest, "__type", factory);
            this.setServiceRequest(convertedServiceRequest0);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let serviceStandardLink = json["serviceStandardLink"];
        if (serviceStandardLink != null) {
            this.setServiceStandardLink(serviceStandardLink);
        }
        let userProtocolLink = json["userProtocolLink"];
        if (userProtocolLink != null) {
            this.setUserProtocolLink(userProtocolLink);
        }
        let tradableImgUrls = json["tradableImgUrls"];
        if (tradableImgUrls != null) {
            let convertedTradableImgUrls0 = [];
            tradableImgUrls = tradableImgUrls[1];
            for (let i in tradableImgUrls) {
                let convertedTradableImgUrls10 = tradableImgUrls[i];
                convertedTradableImgUrls0.push(convertedTradableImgUrls10);
            }
            this.setTradableImgUrls(convertedTradableImgUrls0);
        }
        let orderEnterpriseInfo = json["orderEnterpriseInfo"];
        if (orderEnterpriseInfo != null) {
            let convertedOrderEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(orderEnterpriseInfo, "__type", factory);
            this.setOrderEnterpriseInfo(convertedOrderEnterpriseInfo0);
        }
        let orderUserInfo = json["orderUserInfo"];
        if (orderUserInfo != null) {
            let convertedOrderUserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(orderUserInfo, "__type", factory);
            this.setOrderUserInfo(convertedOrderUserInfo0);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let orderUnits = json["orderUnits"];
        if (orderUnits != null) {
            this.setOrderUnits(orderUnits);
        }
        let firstPaymentSpec = json["firstPaymentSpec"];
        if (firstPaymentSpec != null) {
            let convertedFirstPaymentSpec0 = new PaymentSpec();
            convertedFirstPaymentSpec0.fillFromJson(firstPaymentSpec, factory);
            this.setFirstPaymentSpec(convertedFirstPaymentSpec0);
        }
        let balancePaySchedule = json["balancePaySchedule"];
        if (balancePaySchedule != null) {
            // let convertedBalancePaySchedule0 = new PaySchedule();
            // convertedBalancePaySchedule0.fillFromJson(balancePaySchedule, factory);
            // this.setBalancePaySchedule(convertedBalancePaySchedule0);
        }
        let multiLegsPayment = json["multiLegsPayment"];
        if (multiLegsPayment != null) {
            this.setMultiLegsPayment(multiLegsPayment);
        }
        let orderStatus = json["orderStatus"];
        if (orderStatus != null) {
            let convertedOrderStatus0 = OrderStatus[<string>orderStatus];
            this.setOrderStatus(convertedOrderStatus0);
        }
        let orderUseStatus = json["orderUseStatus"];
        if (orderUseStatus != null) {
            let convertedOrderUseStatus0 = OrderUseStatus[<string>orderUseStatus];
            this.setOrderUseStatus(convertedOrderUseStatus0);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let lastUpdateTime = json["lastUpdateTime"];
        if (lastUpdateTime != null) {
            this.setLastUpdateTime(lastUpdateTime);
        }
        let expired = json["expired"];
        if (expired != null) {
            this.setExpired(expired);
        }
        let reviewed = json["reviewed"];
        if (reviewed != null) {
            this.setReviewed(reviewed);
        }
        let otcPriced = json["otcPriced"];
        if (otcPriced != null) {
            this.setOtcPriced(otcPriced);
        }
        let inviteCode = json["inviteCode"];
        if (inviteCode != null) {
            this.setInviteCode(inviteCode);
        }
        let userProtocolRead = json["userProtocolRead"];
        if (userProtocolRead != null) {
            this.setUserProtocolRead(userProtocolRead);
        }
        let contactInfo = json["contactInfo"];
        if (contactInfo != null) {
            let convertedContactInfo0 = new BusinessContactInfo();
            convertedContactInfo0.fillFromJson(contactInfo, factory);
            this.setContactInfo(convertedContactInfo0);
        }
        let exclusiveCustomServiceSessionSupported = json["exclusiveCustomServiceSessionSupported"];
        if (exclusiveCustomServiceSessionSupported != null) {
            this.setExclusiveCustomServiceSessionSupported(exclusiveCustomServiceSessionSupported);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let orderId = this.getOrderId();
        if (orderId != null) {
            json["orderId"] = String(orderId);
        }
        let tradableId = this.getTradableId();
        if (tradableId != null) {
            json["tradableId"] = String(tradableId);
        }
        let pkgTradableId = this.getPkgTradableId();
        if (pkgTradableId != null) {
            json["pkgTradableId"] = String(pkgTradableId);
        }
        let servicePackage = this.isServicePackage();
        if (servicePackage != null) {
            json["servicePackage"] = servicePackage;
        }
        let refTradeId = this.getRefTradeId();
        if (refTradeId != null) {
            json["refTradeId"] = String(refTradeId);
        }
        let serviceRequestId = this.getServiceRequestId();
        if (serviceRequestId != null) {
            json["serviceRequestId"] = String(serviceRequestId);
        }
        let trade = this.getTrade();
        if (trade != null) {
            let convertedTrade0 = trade.getJson(JsonTypeTagType.TYPE);
            json["trade"] = convertedTrade0;
        }
        let serviceRequest = this.getServiceRequest();
        if (serviceRequest != null) {
            let convertedServiceRequest0 = serviceRequest.getJson(JsonTypeTagType.TYPE);
            json["serviceRequest"] = convertedServiceRequest0;
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let serviceStandardLink = this.getServiceStandardLink();
        if (serviceStandardLink != null) {
            json["serviceStandardLink"] = serviceStandardLink;
        }
        let userProtocolLink = this.getUserProtocolLink();
        if (userProtocolLink != null) {
            json["userProtocolLink"] = userProtocolLink;
        }
        let tradableImgUrls = this.getTradableImgUrls();
        if (tradableImgUrls != null) {
            let convertedTradableImgUrls0 = [];
            for (let i in tradableImgUrls) {
                let convertedTradableImgUrls10 = tradableImgUrls[i];
                convertedTradableImgUrls0.push(convertedTradableImgUrls10);
            }
            let convertedTradableImgUrlsWithType1 = ["java.util.ArrayList", convertedTradableImgUrls0];
            json["tradableImgUrls"] = convertedTradableImgUrlsWithType1;
        }
        let orderEnterpriseInfo = this.getOrderEnterpriseInfo();
        if (orderEnterpriseInfo != null) {
            let convertedOrderEnterpriseInfo0 = orderEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["orderEnterpriseInfo"] = convertedOrderEnterpriseInfo0;
        }
        let orderUserInfo = this.getOrderUserInfo();
        if (orderUserInfo != null) {
            let convertedOrderUserInfo0 = orderUserInfo.getJson(JsonTypeTagType.TYPE);
            json["orderUserInfo"] = convertedOrderUserInfo0;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let orderUnits = this.getOrderUnits();
        if (orderUnits != null) {
            json["orderUnits"] = orderUnits;
        }
        let firstPaymentSpec = this.getFirstPaymentSpec();
        if (firstPaymentSpec != null) {
            let convertedFirstPaymentSpec0 = firstPaymentSpec.getJson(JsonTypeTagType.NONE);
            json["firstPaymentSpec"] = convertedFirstPaymentSpec0;
        }
        let balancePaySchedule = this.getBalancePaySchedule();
        if (balancePaySchedule != null) {
            let convertedBalancePaySchedule0 = balancePaySchedule.getJson(JsonTypeTagType.NONE);
            json["balancePaySchedule"] = convertedBalancePaySchedule0;
        }
        let multiLegsPayment = this.isMultiLegsPayment();
        if (multiLegsPayment != null) {
            json["multiLegsPayment"] = multiLegsPayment;
        }
        let orderStatus = this.getOrderStatus();
        if (orderStatus != null) {
            let convertedOrderStatus0 = OrderStatus[orderStatus];
            json["orderStatus"] = convertedOrderStatus0;
        }
        let orderUseStatus = this.getOrderUseStatus();
        if (orderUseStatus != null) {
            let convertedOrderUseStatus0 = OrderUseStatus[orderUseStatus];
            json["orderUseStatus"] = convertedOrderUseStatus0;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let lastUpdateTime = this.getLastUpdateTime();
        if (lastUpdateTime != null) {
            json["lastUpdateTime"] = String(lastUpdateTime);
        }
        let expired = this.isExpired();
        if (expired != null) {
            json["expired"] = expired;
        }
        let reviewed = this.isReviewed();
        if (reviewed != null) {
            json["reviewed"] = reviewed;
        }
        let otcPriced = this.isOtcPriced();
        if (otcPriced != null) {
            json["otcPriced"] = otcPriced;
        }
        let inviteCode = this.getInviteCode();
        if (inviteCode != null) {
            json["inviteCode"] = inviteCode;
        }
        let userProtocolRead = this.isUserProtocolRead();
        if (userProtocolRead != null) {
            json["userProtocolRead"] = userProtocolRead;
        }
        let contactInfo = this.getContactInfo();
        if (contactInfo != null) {
            let convertedContactInfo0 = contactInfo.getJson(JsonTypeTagType.NONE);
            json["contactInfo"] = convertedContactInfo0;
        }
        let exclusiveCustomServiceSessionSupported = this.getExclusiveCustomServiceSessionSupported();
        if (exclusiveCustomServiceSessionSupported != null) {
            json["exclusiveCustomServiceSessionSupported"] = exclusiveCustomServiceSessionSupported;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.order.EnterpriseServiceOrder";
    }
}
