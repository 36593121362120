/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EntityInfo} from '../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {OrderStatus} from './OrderStatus'
import {OrderUseStatus} from './OrderUseStatus'
import {PeriodType} from '../../PeriodType'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class EnterpriseServiceOrderInfo extends AbstractSerializable  {
    orderId: (number | string);
    orderStatus: OrderStatus;
    orderUseStatus: OrderUseStatus;
    tradeId: (number | string);
    tradingEnterpriseInfo: EntityInfo;
    periodType: PeriodType;
    servicePeriod: number;
    startDay: number;
    endDay: number;
    expired: boolean;

    constructor() {
    	super();
    }

    public getOrderId(): (number | string) {
    	return this.orderId;
    }

    public setOrderId(orderId: (number | string)) {
    	this.orderId = orderId;
    }

    public getOrderStatus(): OrderStatus {
    	return this.orderStatus;
    }

    public setOrderStatus(orderStatus: OrderStatus) {
    	this.orderStatus = orderStatus;
    }

    public getOrderUseStatus(): OrderUseStatus {
    	return this.orderUseStatus;
    }

    public setOrderUseStatus(orderUseStatus: OrderUseStatus) {
    	this.orderUseStatus = orderUseStatus;
    }

    public getTradeId(): (number | string) {
    	return this.tradeId;
    }

    public setTradeId(tradeId: (number | string)) {
    	this.tradeId = tradeId;
    }

    public getTradingEnterpriseInfo(): EntityInfo {
    	return this.tradingEnterpriseInfo;
    }

    public setTradingEnterpriseInfo(tradingEnterpriseInfo: EntityInfo) {
    	this.tradingEnterpriseInfo = tradingEnterpriseInfo;
    }

    public getPeriodType(): PeriodType {
    	return this.periodType;
    }

    public setPeriodType(periodType: PeriodType) {
    	this.periodType = periodType;
    }

    public getServicePeriod(): number {
    	return this.servicePeriod;
    }

    public setServicePeriod(servicePeriod: number) {
    	this.servicePeriod = servicePeriod;
    }

    public getStartDay(): number {
    	return this.startDay;
    }

    public setStartDay(startDay: number) {
    	this.startDay = startDay;
    }

    public getEndDay(): number {
    	return this.endDay;
    }

    public setEndDay(endDay: number) {
    	this.endDay = endDay;
    }

    public isExpired(): boolean {
    	return this.expired;
    }

    public setExpired(expired: boolean) {
    	this.expired = expired;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let orderId = json["orderId"];
        if (orderId != null) {
            this.setOrderId(orderId);
        }
        let orderStatus = json["orderStatus"];
        if (orderStatus != null) {
            let convertedOrderStatus0 = OrderStatus[<string>orderStatus];
            this.setOrderStatus(convertedOrderStatus0);
        }
        let orderUseStatus = json["orderUseStatus"];
        if (orderUseStatus != null) {
            let convertedOrderUseStatus0 = OrderUseStatus[<string>orderUseStatus];
            this.setOrderUseStatus(convertedOrderUseStatus0);
        }
        let tradeId = json["tradeId"];
        if (tradeId != null) {
            this.setTradeId(tradeId);
        }
        let tradingEnterpriseInfo = json["tradingEnterpriseInfo"];
        if (tradingEnterpriseInfo != null) {
            let convertedTradingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(tradingEnterpriseInfo, "__type", factory);
            this.setTradingEnterpriseInfo(convertedTradingEnterpriseInfo0);
        }
        let periodType = json["periodType"];
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[<string>periodType];
            this.setPeriodType(convertedPeriodType0);
        }
        let servicePeriod = json["servicePeriod"];
        if (servicePeriod != null) {
            this.setServicePeriod(servicePeriod);
        }
        let startDay = json["startDay"];
        if (startDay != null) {
            this.setStartDay(startDay);
        }
        let endDay = json["endDay"];
        if (endDay != null) {
            this.setEndDay(endDay);
        }
        let expired = json["expired"];
        if (expired != null) {
            this.setExpired(expired);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let orderId = this.getOrderId();
        if (orderId != null) {
            json["orderId"] = String(orderId);
        }
        let orderStatus = this.getOrderStatus();
        if (orderStatus != null) {
            let convertedOrderStatus0 = OrderStatus[orderStatus];
            json["orderStatus"] = convertedOrderStatus0;
        }
        let orderUseStatus = this.getOrderUseStatus();
        if (orderUseStatus != null) {
            let convertedOrderUseStatus0 = OrderUseStatus[orderUseStatus];
            json["orderUseStatus"] = convertedOrderUseStatus0;
        }
        let tradeId = this.getTradeId();
        if (tradeId != null) {
            json["tradeId"] = String(tradeId);
        }
        let tradingEnterpriseInfo = this.getTradingEnterpriseInfo();
        if (tradingEnterpriseInfo != null) {
            let convertedTradingEnterpriseInfo0 = tradingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["tradingEnterpriseInfo"] = convertedTradingEnterpriseInfo0;
        }
        let periodType = this.getPeriodType();
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[periodType];
            json["periodType"] = convertedPeriodType0;
        }
        let servicePeriod = this.getServicePeriod();
        if (servicePeriod != null) {
            json["servicePeriod"] = servicePeriod;
        }
        let startDay = this.getStartDay();
        if (startDay != null) {
            json["startDay"] = startDay;
        }
        let endDay = this.getEndDay();
        if (endDay != null) {
            json["endDay"] = endDay;
        }
        let expired = this.isExpired();
        if (expired != null) {
            json["expired"] = expired;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.order.EnterpriseServiceOrderInfo";
    }
}
