/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { ActivityStatus } from "../../activity/ActivityStatus";
import { ActivityType } from "../../activity/ActivityType";
import { Filter } from "../Filter";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Location } from "../../geolocation/Location";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class ActivityFilter extends AbstractSerializable implements Filter {
  location: Location;
  distance: number;
  activityType: ActivityType;
  keyword: string;
  targetEntityId: number | string;
  startTime: number | string;
  endTime: number | string;
  activityStatus: ActivityStatus;

  constructor() {
    super();
  }

  public getLocation(): Location {
    return this.location;
  }

  public setLocation(location: Location) {
    this.location = location;
  }

  public getDistance(): number {
    return this.distance;
  }

  public setDistance(distance: number) {
    this.distance = distance;
  }

  public getActivityType(): ActivityType {
    return this.activityType;
  }

  public setActivityType(activityType: ActivityType) {
    this.activityType = activityType;
  }

  public getKeyword(): string {
    return this.keyword;
  }

  public setKeyword(keyword: string) {
    this.keyword = keyword;
  }

  public getTargetEntityId(): number | string {
    return this.targetEntityId;
  }

  public setTargetEntityId(targetEntityId: number | string) {
    this.targetEntityId = targetEntityId;
  }

  public getStartTime(): number | string {
    return this.startTime;
  }

  public setStartTime(startTime: number | string) {
    this.startTime = startTime;
  }

  public getEndTime(): number | string {
    return this.endTime;
  }

  public setEndTime(endTime: number | string) {
    this.endTime = endTime;
  }

  public getActivityStatus(): ActivityStatus {
    return this.activityStatus;
  }

  public setActivityStatus(activityStatus: ActivityStatus) {
    this.activityStatus = activityStatus;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const location = json["location"];
    if (location != null) {
      const convertedLocation0 = new Location();
      convertedLocation0.fillFromJson(location, factory);
      this.setLocation(convertedLocation0);
    }
    const distance = json["distance"];
    if (distance != null) {
      this.setDistance(distance);
    }
    const activityType = json["activityType"];
    if (activityType != null) {
      const convertedActivityType0 = ActivityType[<string>activityType];
      this.setActivityType(convertedActivityType0);
    }
    const keyword = json["keyword"];
    if (keyword != null) {
      this.setKeyword(keyword);
    }
    const targetEntityId = json["targetEntityId"];
    if (targetEntityId != null) {
      this.setTargetEntityId(targetEntityId);
    }
    const startTime = json["startTime"];
    if (startTime != null) {
      this.setStartTime(startTime);
    }
    const endTime = json["endTime"];
    if (endTime != null) {
      this.setEndTime(endTime);
    }
    const activityStatus = json["activityStatus"];
    if (activityStatus != null) {
      const convertedActivityStatus0 = ActivityStatus[<string>activityStatus];
      this.setActivityStatus(convertedActivityStatus0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const location = this.getLocation();
    if (location != null) {
      const convertedLocation0 = location.getJson(JsonTypeTagType.NONE);
      json["location"] = convertedLocation0;
    }
    const distance = this.getDistance();
    if (distance != null) {
      json["distance"] = distance;
    }
    const activityType = this.getActivityType();
    if (activityType != null) {
      const convertedActivityType0 = ActivityType[activityType];
      json["activityType"] = convertedActivityType0;
    }
    const keyword = this.getKeyword();
    if (keyword != null) {
      json["keyword"] = keyword;
    }
    const targetEntityId = this.getTargetEntityId();
    if (targetEntityId != null) {
      json["targetEntityId"] = String(targetEntityId);
    }
    const startTime = this.getStartTime();
    if (startTime != null) {
      json["startTime"] = String(startTime);
    }
    const endTime = this.getEndTime();
    if (endTime != null) {
      json["endTime"] = String(endTime);
    }
    const activityStatus = this.getActivityStatus();
    if (activityStatus != null) {
      const convertedActivityStatus0 = ActivityStatus[activityStatus];
      json["activityStatus"] = convertedActivityStatus0;
    }

    if (includeTypeTag) {
      json["__type"] = "ActivityFilter";
    }
  }

  public getTypeId(): string {
    return "ActivityFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.activity.ActivityFilter";
  }
}
