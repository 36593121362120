/**
 * 自动生成， 请不要手工修改！
 */

import { AdditionalEntityAttributes } from "./AdditionalEntityAttributes";
import { EntityInfo } from "./EntityInfo";
import {JsonTypeTagType} from "../../serialize/JsonTypeTagType";
import {MessageType} from "../../message/MessageContentType";
import {Serializer} from "../../serialize/Serializer";
import { SimpleEntityDesc } from "./SimpleEntityDesc";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractSystemSuppliedEntityDesc extends SimpleEntityDesc {
  parentPath: Array<EntityInfo>;
  additionalAttributes: AdditionalEntityAttributes;

  constructor() {
    super();
  }

  public getParentPath(): Array<EntityInfo> {
    return this.parentPath;
  }

  public setParentPath(parentPath: Array<EntityInfo>) {
    this.parentPath = parentPath;
  }

  public getAdditionalAttributes(): AdditionalEntityAttributes {
    return this.additionalAttributes;
  }

  public setAdditionalAttributes(
    additionalAttributes: AdditionalEntityAttributes
  ) {
    this.additionalAttributes = additionalAttributes;
  }

    abstract getMessageType(): MessageType

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    let parentPath = json["parentPath"];
    if (parentPath != null) {
      const convertedParentPath0 = [];
      parentPath = parentPath[1];
      for (const i in parentPath) {
        const convertedParentPath10 = parentPath[i];
        const convertedParentPath1 = Serializer.fillFromJsonObjectWithTypeTag(
          convertedParentPath10,
          "__type",
          factory
        );
        convertedParentPath0.push(convertedParentPath1);
      }
      this.setParentPath(convertedParentPath0);
    }
    const additionalAttributes = json["additionalAttributes"];
    if (additionalAttributes != null) {
      const convertedAdditionalAttributes0 =
        Serializer.fillFromJsonObjectWithTypeTag(
          additionalAttributes,
          "__type",
          factory
        );
      this.setAdditionalAttributes(convertedAdditionalAttributes0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const parentPath = this.getParentPath();
    if (parentPath != null) {
      const convertedParentPath0 = [];
      for (const i in parentPath) {
        const convertedParentPath10 = parentPath[i];
        const convertedParentPath1 = convertedParentPath10.getJson(
          JsonTypeTagType.TYPE
        );
        convertedParentPath0.push(convertedParentPath1);
      }
      const convertedParentPathWithType1 = [
        "java.util.ArrayList",
        convertedParentPath0,
      ];
      json["parentPath"] = convertedParentPathWithType1;
    }
    const additionalAttributes = this.getAdditionalAttributes();
    if (additionalAttributes != null) {
      const convertedAdditionalAttributes0 = additionalAttributes.getJson(
        JsonTypeTagType.TYPE
      );
      json["additionalAttributes"] = convertedAdditionalAttributes0;
    }

    if (includeTypeTag) {
      json["__type"] = "SystemSuppliedEntityDesc";
    }
  }

  public getTypeId(): string {
    return "SystemSuppliedEntityDesc";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.SystemSuppliedEntityDesc";
  }
}
