/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CreditGrade } from "../credit/CreditGrade";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class UserCreditInfo extends AbstractSerializable {
  userId: number | string;
  nickName: string;
  creditGrade: CreditGrade;
  creditPoints: number;
  relationPoints: number;
  totalPlatformPositivePoints: number;
  totalPlatformNegativePoints: number;
  totalPoints: number;
  noPublish: boolean;
  noChat: boolean;
  createTime: number | string;

  constructor() {
    super();
  }

  public getUserId(): number | string {
    return this.userId;
  }

  public setUserId(userId: number | string) {
    this.userId = userId;
  }

  public getNickName(): string {
    return this.nickName;
  }

  public setNickName(nickName: string) {
    this.nickName = nickName;
  }

  public getCreditGrade(): CreditGrade {
    return this.creditGrade;
  }

  public setCreditGrade(creditGrade: CreditGrade) {
    this.creditGrade = creditGrade;
  }

  public getCreditPoints(): number {
    return this.creditPoints;
  }

  public setCreditPoints(creditPoints: number) {
    this.creditPoints = creditPoints;
  }

  public getRelationPoints(): number {
    return this.relationPoints;
  }

  public setRelationPoints(relationPoints: number) {
    this.relationPoints = relationPoints;
  }

  public getTotalPlatformPositivePoints(): number {
    return this.totalPlatformPositivePoints;
  }

  public setTotalPlatformPositivePoints(totalPlatformPositivePoints: number) {
    this.totalPlatformPositivePoints = totalPlatformPositivePoints;
  }

  public getTotalPlatformNegativePoints(): number {
    return this.totalPlatformNegativePoints;
  }

  public setTotalPlatformNegativePoints(totalPlatformNegativePoints: number) {
    this.totalPlatformNegativePoints = totalPlatformNegativePoints;
  }

  public getTotalPoints(): number {
    return this.totalPoints;
  }

  public setTotalPoints(totalPoints: number) {
    this.totalPoints = totalPoints;
  }

  public isNoPublish(): boolean {
    return this.noPublish;
  }

  public setNoPublish(noPublish: boolean) {
    this.noPublish = noPublish;
  }

  public isNoChat(): boolean {
    return this.noChat;
  }

  public setNoChat(noChat: boolean) {
    this.noChat = noChat;
  }

  public getCreateTime(): number | string {
    return this.createTime;
  }

  public setCreateTime(createTime: number | string) {
    this.createTime = createTime;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const userId = json["userId"];
    if (userId != null) {
      this.setUserId(userId);
    }
    const nickName = json["nickName"];
    if (nickName != null) {
      this.setNickName(nickName);
    }
    const creditGrade = json["creditGrade"];
    if (creditGrade != null) {
      const convertedCreditGrade0 = CreditGrade[<string>creditGrade];
      this.setCreditGrade(convertedCreditGrade0);
    }
    const creditPoints = json["creditPoints"];
    if (creditPoints != null) {
      this.setCreditPoints(creditPoints);
    }
    const relationPoints = json["relationPoints"];
    if (relationPoints != null) {
      this.setRelationPoints(relationPoints);
    }
    const totalPlatformPositivePoints = json["totalPlatformPositivePoints"];
    if (totalPlatformPositivePoints != null) {
      this.setTotalPlatformPositivePoints(totalPlatformPositivePoints);
    }
    const totalPlatformNegativePoints = json["totalPlatformNegativePoints"];
    if (totalPlatformNegativePoints != null) {
      this.setTotalPlatformNegativePoints(totalPlatformNegativePoints);
    }
    const totalPoints = json["totalPoints"];
    if (totalPoints != null) {
      this.setTotalPoints(totalPoints);
    }
    const noPublish = json["noPublish"];
    if (noPublish != null) {
      this.setNoPublish(noPublish);
    }
    const noChat = json["noChat"];
    if (noChat != null) {
      this.setNoChat(noChat);
    }
    const createTime = json["createTime"];
    if (createTime != null) {
      this.setCreateTime(createTime);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const userId = this.getUserId();
    if (userId != null) {
      json["userId"] = String(userId);
    }
    const nickName = this.getNickName();
    if (nickName != null) {
      json["nickName"] = nickName;
    }
    const creditGrade = this.getCreditGrade();
    if (creditGrade != null) {
      const convertedCreditGrade0 = CreditGrade[creditGrade];
      json["creditGrade"] = convertedCreditGrade0;
    }
    const creditPoints = this.getCreditPoints();
    if (creditPoints != null) {
      json["creditPoints"] = creditPoints;
    }
    const relationPoints = this.getRelationPoints();
    if (relationPoints != null) {
      json["relationPoints"] = relationPoints;
    }
    const totalPlatformPositivePoints = this.getTotalPlatformPositivePoints();
    if (totalPlatformPositivePoints != null) {
      json["totalPlatformPositivePoints"] = totalPlatformPositivePoints;
    }
    const totalPlatformNegativePoints = this.getTotalPlatformNegativePoints();
    if (totalPlatformNegativePoints != null) {
      json["totalPlatformNegativePoints"] = totalPlatformNegativePoints;
    }
    const totalPoints = this.getTotalPoints();
    if (totalPoints != null) {
      json["totalPoints"] = totalPoints;
    }
    const noPublish = this.isNoPublish();
    if (noPublish != null) {
      json["noPublish"] = noPublish;
    }
    const noChat = this.isNoChat();
    if (noChat != null) {
      json["noChat"] = noChat;
    }
    const createTime = this.getCreateTime();
    if (createTime != null) {
      json["createTime"] = String(createTime);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.UserCreditInfo";
  }
}
