/**
 * 自动生成， 请不要手工修改！
 */


export enum ServiceProviderType {
    PLATFORM,
    PLATFORM_INTEGRATED,
    PROVIDER
}
