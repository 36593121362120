/**
 * 自动生成， 请不要手工修改！
 */


export enum ServiceDeliveryType {
    QUOTA,
    PRIVILEDGE,
    DISCOUNT,
    AMOUNT,
    DISPLAY,
    FREE,
    CUSTOM_OFFLINE,
    MISC
}
