/**
 * 自动生成， 请不要手工修改！
 */

import {GenericEntityMessage} from "@/services/message/entity/api/GenericEntityMessage";
import {MessageType} from "../../MessageContentType";
import {RequestAction} from "@/services/datatype/request/RequestAction";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractRequestActionMessage extends GenericEntityMessage<RequestAction> {
    constructor() {
        super();
    }

    public getContentType(): MessageType {
        return MessageType.REQ_ACTION;
    }

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
    }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);

    if (includeTypeTag) {
      json["__type"] = "RequestActionMessage";
    }
  }

  public getTypeId(): string {
    return "RequestActionMessage";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.entity.request.RequestActionMessage";
  }
}
