/**
 * Created by Zhonghua on 15/11/2016.
 */

import {MessageType} from "../message/MessageContentType";
import {MessageResponseType} from "../message/MessageResponseType";
import { TypeFactory } from "../serialize/TypeFactory";
import { AbstractServiceRequest } from "./AbstractServiceRequest";

export class ServiceRequest extends AbstractServiceRequest {
  public static createServiceRequest(
    serviceName: string,
    methodName: string,
    parameterValues: any[]
  ): ServiceRequest {
    const serviceRequest = new ServiceRequest();
    serviceRequest.setServiceName(serviceName);
      serviceRequest.setMethodName(methodName);
      serviceRequest.setParameterValues(parameterValues);
      serviceRequest.fillId();
      return serviceRequest;
  }

    constructor() {
        super();
    }

    public getContentType(): MessageType {
        return MessageType.SERVICE_REQUEST;
    }

    public getResponseType(): MessageResponseType {
        return MessageResponseType.ONE;
    }

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag: boolean) {
    super.fillToJson(json, includeTypeTag);

    json["__type"] = "Service";
  }

  public getTypeId(): string {
    return "Service";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.service.spi.ServiceRequest";
  }
}
