/**
 * 自动生成， 请不要手工修改！
 */

export enum GWServiceType {
  PLATFORM_AD,
  COMMERCIAL_MSG,
  ENGAGE_USERS,
  NOTIFY_MATCHES,
}
