/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../serialize/JsonTypeTagType";
import { SessionContext } from "./SessionContext";
import { TypeFactory } from "../serialize/TypeFactory";

export class DefaultSessionContext
  extends AbstractSerializable
  implements SessionContext
{
  sessionToken: string;
  lastSyncPoint: number;
  equipmentId: string;
  ios: boolean;
  appId: string;
  appVersion: string;

  constructor() {
    super();
  }

  public getSessionToken(): string {
    return this.sessionToken;
  }

  public setSessionToken(sessionToken: string) {
    this.sessionToken = sessionToken;
  }

  public getLastSyncPoint(): number {
    return this.lastSyncPoint;
  }

  public setLastSyncPoint(lastSyncPoint: number) {
    this.lastSyncPoint = lastSyncPoint;
  }

  public getEquipmentId(): string {
    return this.equipmentId;
  }

  public setEquipmentId(equipmentId: string) {
    this.equipmentId = equipmentId;
  }

  public isIos(): boolean {
    return this.ios;
  }

  public setIos(ios: boolean) {
    this.ios = ios;
  }

  public getAppId(): string {
    return this.appId;
  }

  public setAppId(appId: string) {
    this.appId = appId;
  }

  public getAppVersion(): string {
    return this.appVersion;
  }

  public setAppVersion(appVersion: string) {
    this.appVersion = appVersion;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const sessionToken = json["sessionToken"];
    if (sessionToken != null) {
      this.setSessionToken(sessionToken);
    }
    const lastSyncPoint = json["lastSyncPoint"];
    if (lastSyncPoint != null) {
      this.setLastSyncPoint(lastSyncPoint);
    }
    const equipmentId = json["equipmentId"];
    if (equipmentId != null) {
      this.setEquipmentId(equipmentId);
    }
    const ios = json["ios"];
    if (ios != null) {
      this.setIos(ios);
    }
    const appId = json["appId"];
    if (appId != null) {
      this.setAppId(appId);
    }
    const appVersion = json["appVersion"];
    if (appVersion != null) {
      this.setAppVersion(appVersion);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const sessionToken = this.getSessionToken();
    if (sessionToken != null) {
      json["sessionToken"] = sessionToken;
    }
    const lastSyncPoint = this.getLastSyncPoint();
    if (lastSyncPoint != null) {
      json["lastSyncPoint"] = lastSyncPoint;
    }
    const equipmentId = this.getEquipmentId();
    if (equipmentId != null) {
      json["equipmentId"] = equipmentId;
    }
    const ios = this.isIos();
    if (ios != null) {
      json["ios"] = ios;
    }
    const appId = this.getAppId();
    if (appId != null) {
      json["appId"] = appId;
    }
    const appVersion = this.getAppVersion();
    if (appVersion != null) {
      json["appVersion"] = appVersion;
    }

    if (includeTypeTag) {
      json["__type"] = "DefaultSessionContext";
    }
  }

  public getTypeId(): string {
    return "DefaultSessionContext";
  }

  public getClassName(): string {
    return "com.gong_wei.common.session.DefaultSessionContext";
  }
}
