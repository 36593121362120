import { LinkType } from "@/services/message/entity/api/LinkType";
import { DefaultWebServiceFactory } from "@/services/webapi/DefaultWebServiceFactory";
//发送信息 对方id 已方id 房间id 文本内容
const sendMessage = (
  targetId: string,
  correlationId: string,
  rid: number,
  msg: string
) =>
  DefaultWebServiceFactory.getInstance()
    .getWebMessageService()
    .sendTextMessageOnServiceRequest(targetId, correlationId, rid, msg);
//消息监听器
const getMessage = (rid: number, messageListener: any) =>
  DefaultWebServiceFactory.getInstance()
    .getWebMessageService()
    .addMessageListenerOnServiceRequest(rid, messageListener);
//文件上传 //对方id 已方id 房间id  文件名字 文件路径 文件大小  文件类型
const uploadFiles = (
  targetId: string,
  correationId: string,
  serviceRequestId: number,
  fileName: string,
  fileUrl: string,
  fileSize: number,
  linkType: LinkType
) =>
  DefaultWebServiceFactory.getInstance()
    .getWebMessageService()
    .sendFileMessageOnServiceRequest(
      targetId,
      correationId,
      serviceRequestId,
      fileName,
      fileUrl,
      fileSize,
      linkType
    );
export default { sendMessage, getMessage, uploadFiles };
