/**
 * 自动生成， 请不要手工修改！
 */


export enum MessageType {
    SERVICE_REQUEST,
    SERVICE_RESP,
    EVENT_SUBSCRIBE,
    EVENT_NOTIFY,
    MESSAGE_REVOKE,
    TEXT,
    URL,
    COMMENT,
    ENTITY_DESC,
    COWORK,
    COWORK_OP,
    ENTITY_REVIEW,
    USER_ADS,
    ANNOUNCEMENT,
    PRODUCT,
    SHARED_INFO,
    ACTIVITY,
    ENT_SERVICE,
    ENT_SERVICE_REQUEST,
    REQUEST,
    REQ_STATUS,
    REQ_ACTION,
    APPEAL_INFO,
    TASK,
    OP,
    OP_NOTIFY,
    QA_REQUEST,
    QA_ANSWER,
    QA_UPDATE
}
