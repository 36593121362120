// @ts-nocheck
import { AbstractCoworkOperation } from "./AbstractCoworkOperation";
import { CoworkOp } from "./CoworkOp";
import {CoworkType} from "./CoworkType";
import {MessageType} from "../../message/MessageContentType";

export class CoworkOperation extends AbstractCoworkOperation {
  constructor() {
    super();
  }

    getMessageType(): MessageType {
        // Manual Code Here
    }

  /**
   * 必选数据
   */
  public withCoworkObjectType(coworkObjectType: CoworkType): CoworkOperation {
    this.coworkObjectType = coworkObjectType;
    return this;
  }

  /**
   * 必选数据
   */
  public withCoworkOperationType(
    coworkOperationType: CoworkOp
  ): CoworkOperation {
    this.coworkOperationType = coworkOperationType;
    return this;
  }
}
