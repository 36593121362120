/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { EntityChannelType } from "../entity/channel/EntityChannelType";
import { Filter } from "../query/Filter";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class MessageSearchFilter
  extends AbstractSerializable
  implements Filter
{
  channels: EntityChannelType[];
  query: string;

  constructor() {
    super();
  }

  public getChannels(): EntityChannelType[] {
    return this.channels;
  }

  public setChannels(channels: EntityChannelType[]) {
    this.channels = channels;
  }

  public getQuery(): string {
    return this.query;
  }

  public setQuery(query: string) {
    this.query = query;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const channels = json["channels"];
    if (channels != null) {
      const convertedChannels0 = [];
      for (const i in channels) {
        const convertedChannels10 = channels[i];
        const convertedChannels1 =
          EntityChannelType[<string>convertedChannels10];
        convertedChannels0.push(convertedChannels1);
      }
      this.setChannels(convertedChannels0);
    }
    const query = json["query"];
    if (query != null) {
      this.setQuery(query);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const channels = this.getChannels();
    if (channels != null) {
      const convertedChannels0 = [];
      for (const i in channels) {
        const convertedChannels10 = channels[i];
        const convertedChannels1 = EntityChannelType[convertedChannels10];
        convertedChannels0.push(convertedChannels1);
      }
      json["channels"] = convertedChannels0;
    }
    const query = this.getQuery();
    if (query != null) {
      json["query"] = query;
    }

    if (includeTypeTag) {
      json["__type"] = "MessageSearchFilter";
    }
  }

  public getTypeId(): string {
    return "MessageSearchFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.message.MessageSearchFilter";
  }
}
