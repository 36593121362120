/**
 * Created by Zhonghua on 16/11/2016.
 */

import { ConnectionManagerFactory } from "../connection/ConnectionManagerFactory";
import { AbstractClientInvocationService } from "./AbstractClientInvocationService";
import { WebSocketConnectionManager } from "../connection/WebSocketConnectionManager";

export class DefaultClientInvocationService extends AbstractClientInvocationService {
  constructor(
    connectionManager: WebSocketConnectionManager = ConnectionManagerFactory.getOrCreateWebSocketConnectionManager()
  ) {
    super(connectionManager);
  }
}
