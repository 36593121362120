/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractEntityMessage } from "./AbstractEntityMessage";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { MessageContent } from "../../MessageContent";
import { Serializer } from "../../../serialize/Serializer";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractGenericEntityMessage<
  T extends MessageContent
> extends AbstractEntityMessage {
  data: T;

  constructor() {
    super();
  }

  public getData(): T {
    return this.data;
  }

  public setData(data: T) {
    this.data = data;
  }

  public getMessageContent(): T {
    return this.data;
  }

  public getDataText(): string {
    return undefined;
  }

  public getTitle(): string {
    return undefined;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const data = json["data"];
    if (data != null) {
      const convertedData0 = Serializer.fillFromJsonObjectWithTypeTag(
        data,
        "__type",
        factory
      );
      this.setData(convertedData0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const data = this.getData();
    if (data != null) {
      const convertedData0 = data.getJson(JsonTypeTagType.TYPE);
      json["data"] = convertedData0;
    }

    if (includeTypeTag) {
      json["__type"] = "GenericEntityMessage";
    }
  }

  public getTypeId(): string {
    return "GenericEntityMessage";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.entity.api.GenericEntityMessage";
  }
}
