/**
 * 自动生成， 请不要手工修改！
 */


export enum FinancingType {
    LOAN,
    STOCK,
    STOCK_LOAN,
    DEBT,
    ENTERPRISE_COOP,
    NETIZEN_COOP
}
