/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { ReplyContent } from "./ReplyContent";
import { TypeFactory } from "../../serialize/TypeFactory";

export class CompositeReplyContent extends AbstractSerializable {
  id: number | string;
  rootReply: ReplyContent;
  childReplies: Array<ReplyContent>;
  approve: boolean;

  constructor() {
    super();
  }

  public getId(): number | string {
    return this.id;
  }

  public setId(id: number | string) {
    this.id = id;
  }

  public getRootReply(): ReplyContent {
    return this.rootReply;
  }

  public setRootReply(rootReply: ReplyContent) {
    this.rootReply = rootReply;
  }

  public getChildReplies(): Array<ReplyContent> {
    return this.childReplies;
  }

  public setChildReplies(childReplies: Array<ReplyContent>) {
    this.childReplies = childReplies;
  }

  public isApprove(): boolean {
    return this.approve;
  }

  public setApprove(approve: boolean) {
    this.approve = approve;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const id = json["id"];
    if (id != null) {
      this.setId(id);
    }
    const rootReply = json["rootReply"];
    if (rootReply != null) {
      const convertedRootReply0 = new ReplyContent();
      convertedRootReply0.fillFromJson(rootReply, factory);
      this.setRootReply(convertedRootReply0);
    }
    let childReplies = json["childReplies"];
    if (childReplies != null) {
      const convertedChildReplies0 = [];
      childReplies = childReplies[1];
      for (const i in childReplies) {
        const convertedChildReplies10 = childReplies[i];
        const convertedChildReplies1 = new ReplyContent();
        convertedChildReplies1.fillFromJson(convertedChildReplies10, factory);
        convertedChildReplies0.push(convertedChildReplies1);
      }
      this.setChildReplies(convertedChildReplies0);
    }
    const approve = json["approve"];
    if (approve != null) {
      this.setApprove(approve);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const id = this.getId();
    if (id != null) {
      json["id"] = String(id);
    }
    const rootReply = this.getRootReply();
    if (rootReply != null) {
      const convertedRootReply0 = rootReply.getJson(JsonTypeTagType.NONE);
      json["rootReply"] = convertedRootReply0;
    }
    const childReplies = this.getChildReplies();
    if (childReplies != null) {
      const convertedChildReplies0 = [];
      for (const i in childReplies) {
        const convertedChildReplies10 = childReplies[i];
        const convertedChildReplies1 = convertedChildReplies10.getJson(
          JsonTypeTagType.NONE
        );
        convertedChildReplies0.push(convertedChildReplies1);
      }
      const convertedChildRepliesWithType1 = [
        "java.util.ArrayList",
        convertedChildReplies0,
      ];
      json["childReplies"] = convertedChildRepliesWithType1;
    }
    const approve = this.isApprove();
    if (approve != null) {
      json["approve"] = approve;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.reply.CompositeReplyContent";
  }
}
