import EventHandle from "@/services/common/EventHandle";
import { EventRequest } from "@/services/common/EventRequest";

export type Canceller = () => void;

export class SimpleEventHandle implements EventHandle {
  private eventRequest: EventRequest;
  private canceller: Canceller;

  constructor(eventRequest: EventRequest, canceller: Canceller) {
    this.eventRequest = eventRequest;
    this.canceller = canceller;
  }

  public cancel(): void {
    if (this.canceller) {
      this.canceller();
    }
    if (this.eventRequest) {
      this.eventRequest.setCancelled(true);
    }
  }

  public isCancelled(): boolean {
    return this.eventRequest ? this.eventRequest.isCancelled() : false;
  }

  public getEventRequest(): EventRequest {
    return this.eventRequest;
  }

  public setCancelled(): void {
    if (this.eventRequest) {
      this.eventRequest.setCancelled(true);
    }
  }
}
