/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {EntityInfo} from '../../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {Serializer} from '../../../../serialize/Serializer'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class ServingEntityInfos extends AbstractSerializable  {
    servingEnterpriseInfo: EntityInfo;
    servingProfessionalInfo: EntityInfo;
    toDelegate: boolean;
    servingMobile: string;
    entWeChatUrl: string;

    constructor() {
    	super();
    }

    public getServingEnterpriseInfo(): EntityInfo {
    	return this.servingEnterpriseInfo;
    }

    public setServingEnterpriseInfo(servingEnterpriseInfo: EntityInfo) {
    	this.servingEnterpriseInfo = servingEnterpriseInfo;
    }

    public getServingProfessionalInfo(): EntityInfo {
    	return this.servingProfessionalInfo;
    }

    public setServingProfessionalInfo(servingProfessionalInfo: EntityInfo) {
    	this.servingProfessionalInfo = servingProfessionalInfo;
    }

    public isToDelegate(): boolean {
    	return this.toDelegate;
    }

    public setToDelegate(toDelegate: boolean) {
    	this.toDelegate = toDelegate;
    }

    public getServingMobile(): string {
    	return this.servingMobile;
    }

    public setServingMobile(servingMobile: string) {
    	this.servingMobile = servingMobile;
    }

    public getEntWeChatUrl(): string {
    	return this.entWeChatUrl;
    }

    public setEntWeChatUrl(entWeChatUrl: string) {
    	this.entWeChatUrl = entWeChatUrl;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let servingEnterpriseInfo = json["servingEnterpriseInfo"];
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(servingEnterpriseInfo, "__type", factory);
            this.setServingEnterpriseInfo(convertedServingEnterpriseInfo0);
        }
        let servingProfessionalInfo = json["servingProfessionalInfo"];
        if (servingProfessionalInfo != null) {
            let convertedServingProfessionalInfo0 = Serializer.fillFromJsonObjectWithTypeTag(servingProfessionalInfo, "__type", factory);
            this.setServingProfessionalInfo(convertedServingProfessionalInfo0);
        }
        let toDelegate = json["toDelegate"];
        if (toDelegate != null) {
            this.setToDelegate(toDelegate);
        }
        let servingMobile = json["servingMobile"];
        if (servingMobile != null) {
            this.setServingMobile(servingMobile);
        }
        let entWeChatUrl = json["entWeChatUrl"];
        if (entWeChatUrl != null) {
            this.setEntWeChatUrl(entWeChatUrl);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let servingEnterpriseInfo = this.getServingEnterpriseInfo();
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = servingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["servingEnterpriseInfo"] = convertedServingEnterpriseInfo0;
        }
        let servingProfessionalInfo = this.getServingProfessionalInfo();
        if (servingProfessionalInfo != null) {
            let convertedServingProfessionalInfo0 = servingProfessionalInfo.getJson(JsonTypeTagType.TYPE);
            json["servingProfessionalInfo"] = convertedServingProfessionalInfo0;
        }
        let toDelegate = this.isToDelegate();
        if (toDelegate != null) {
            json["toDelegate"] = toDelegate;
        }
        let servingMobile = this.getServingMobile();
        if (servingMobile != null) {
            json["servingMobile"] = servingMobile;
        }
        let entWeChatUrl = this.getEntWeChatUrl();
        if (entWeChatUrl != null) {
            json["entWeChatUrl"] = entWeChatUrl;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.request.ServingEntityInfos";
    }
}
