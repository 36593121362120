/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { PaymentMethod } from "./PaymentMethod";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class FinancialAccountInfo extends AbstractSerializable {
  id: number | string;
  paymentMethod: PaymentMethod;
  accountNumber: string;
  accountName: string;

  constructor() {
    super();
  }

  public getId(): number | string {
    return this.id;
  }

  public setId(id: number | string) {
    this.id = id;
  }

  public getPaymentMethod(): PaymentMethod {
    return this.paymentMethod;
  }

  public setPaymentMethod(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  public getAccountNumber(): string {
    return this.accountNumber;
  }

  public setAccountNumber(accountNumber: string) {
    this.accountNumber = accountNumber;
  }

  public getAccountName(): string {
    return this.accountName;
  }

  public setAccountName(accountName: string) {
    this.accountName = accountName;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const id = json["id"];
    if (id != null) {
      this.setId(id);
    }
    const paymentMethod = json["paymentMethod"];
    if (paymentMethod != null) {
      const convertedPaymentMethod0 = PaymentMethod[<string>paymentMethod];
      this.setPaymentMethod(convertedPaymentMethod0);
    }
    const accountNumber = json["accountNumber"];
    if (accountNumber != null) {
      this.setAccountNumber(accountNumber);
    }
    const accountName = json["accountName"];
    if (accountName != null) {
      this.setAccountName(accountName);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const id = this.getId();
    if (id != null) {
      json["id"] = String(id);
    }
    const paymentMethod = this.getPaymentMethod();
    if (paymentMethod != null) {
      const convertedPaymentMethod0 = PaymentMethod[paymentMethod];
      json["paymentMethod"] = convertedPaymentMethod0;
    }
    const accountNumber = this.getAccountNumber();
    if (accountNumber != null) {
      json["accountNumber"] = accountNumber;
    }
    const accountName = this.getAccountName();
    if (accountName != null) {
      json["accountName"] = accountName;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.payment.FinancialAccountInfo";
  }
}
