/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {AssociatedEntityInfo} from '../datatype/entity/AssociatedEntityInfo'
import {CoopUserAdView} from '../datatype/userad/coop/CoopUserAdView'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EnterpriseServiceOrder} from '../datatype/business/order/EnterpriseServiceOrder'
import {EnterpriseServiceRequestInfo} from '../datatype/business/service/request/EnterpriseServiceRequestInfo'
import {EnterpriseServiceRequestService} from '../api/business/service/EnterpriseServiceRequestService'
import {EnterpriseServiceRequest} from '../datatype/business/service/request/EnterpriseServiceRequest'
import {EnterpriseServiceType} from '../datatype/business/service/EnterpriseServiceType'
import {EntityMessageResponse} from '../message/entity/api/EntityMessageResponse'
import {EntityMessage} from '../message/entity/api/EntityMessage'
import {EntityServiceStats} from '../datatype/business/service/stats/EntityServiceStats'
import {FinancingServiceRequestClassifier} from '../datatype/business/service/finance/FinancingServiceRequestClassifier'
import {FinancingServiceRequest} from '../datatype/business/service/request/banking/FinancingServiceRequest'
import {GeoEntityDesc} from '../datatype/geolocation/GeoEntityDesc'
import {PageableData} from '../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {PaymentSpec} from '../datatype/business/payment/PaymentSpec'
import {RequestStatusClassifier} from '../datatype/request/RequestStatusClassifier'
import {RequestStatusType} from '../datatype/request/RequestStatusType'
import {ServiceRequestClassifier} from '../datatype/business/service/request/ServiceRequestClassifier'
import {ServingEntityInfos} from '../datatype/business/service/request/ServingEntityInfos'
import {WebEnterpriseServiceRequestService} from '../webapi/WebEnterpriseServiceRequestService'
import {WebSessionManager} from '../common/WebSessionManager'


export class WebEnterpriseServiceRequestServiceImpl implements WebEnterpriseServiceRequestService {

    private enterpriseServiceRequestService: EnterpriseServiceRequestService;

    public constructor() {
        this.enterpriseServiceRequestService = DefaultServiceFactory.getInstance().getEnterpriseServiceRequestService();
    }

    public acceptByServiceProvider(requestId: (number | string | null)): Promise<RequestStatusType> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.acceptByServiceProvider(WebSessionManager.getSessionContext(),
        	requestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public acceptByServiceProviderWithMiniCode(miniCode: (string | null)): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.acceptByServiceProviderWithMiniCode(WebSessionManager.getSessionContext(),
        	miniCode, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public acceptDelegateByServiceWorker(requestId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.acceptDelegateByServiceWorker(WebSessionManager.getSessionContext(),
        	requestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public acceptDelegateByServiceWorkerWithMiniCode(miniCode: (string | null)): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.acceptDelegateByServiceWorkerWithMiniCode(WebSessionManager.getSessionContext(),
        	miniCode, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public acceptServiceProvider(requestId: (number | string | null), servingProfessionalId: (number | string | null)): Promise<RequestStatusType> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.acceptServiceProvider(WebSessionManager.getSessionContext(),
        	requestId, servingProfessionalId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public addEnterpriseServiceRequest(serviceRequest: EnterpriseServiceRequest): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.addEnterpriseServiceRequest(WebSessionManager.getSessionContext(),
        	serviceRequest, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public addFinancingServiceRequestToMatchCoops(financingServiceRequest: (FinancingServiceRequest | null), pageAccessSpec: PageBlockAccessSpec): Promise<PageableData<CoopUserAdView>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.addFinancingServiceRequestToMatchCoops(WebSessionManager.getSessionContext(),
        	financingServiceRequest, pageAccessSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public assignToWorker(requestId: (number | string | null), enterpriseId: (number | string | null), workerId: (number | string | null)): Promise<ServingEntityInfos> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.assignToWorker(WebSessionManager.getSessionContext(),
        	requestId, enterpriseId, workerId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public cancelEnterpriseServiceRequestById(requestId: (number | string | null)): Promise<RequestStatusType> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.cancelEnterpriseServiceRequestById(WebSessionManager.getSessionContext(),
        	requestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public completeServiceRequest(requestId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.completeServiceRequest(WebSessionManager.getSessionContext(),
        	requestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public confirmServiceRequestCompleted(requestId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.confirmServiceRequestCompleted(WebSessionManager.getSessionContext(),
        	requestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public delegateToAssistant(requestId: (number | string | null), assistantId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.delegateToAssistant(WebSessionManager.getSessionContext(),
        	requestId, assistantId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public delegateToCustomerServiceAssistant(requestId: (number | string | null), assistEnterpriseId: (number | string | null), assistantId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.delegateToCustomerServiceAssistant(WebSessionManager.getSessionContext(),
        	requestId, assistEnterpriseId, assistantId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public enterMessageSessionOnServiceRequest(serviceRequestId: (number | string | null)): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.enterMessageSessionOnServiceRequest(WebSessionManager.getSessionContext(),
        	serviceRequestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findActiveCustomerServiceRequestByUserIdAndServiceType(userId: (number | string | null), serviceType: (EnterpriseServiceType | null)): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findActiveCustomerServiceRequestByUserIdAndServiceType(WebSessionManager.getSessionContext(),
        	userId, serviceType, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findActiveCustomerServiceRequestByUserIdAndServiceTypeName(userId: (number | string | null), serviceTypeName: (string | null)): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findActiveCustomerServiceRequestByUserIdAndServiceTypeName(WebSessionManager.getSessionContext(),
        	userId, serviceTypeName, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findAllCustomerServiceRequests(serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findAllCustomerServiceRequests(WebSessionManager.getSessionContext(),
        	serviceType, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findAllCustomerServiceRequestsByClassifier(serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findAllCustomerServiceRequestsByClassifier(WebSessionManager.getSessionContext(),
        	serviceClassifier, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findAllCustomerServiceRequestsByServiceTypeName(serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findAllCustomerServiceRequestsByServiceTypeName(WebSessionManager.getSessionContext(),
        	serviceTypeName, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findClassifiedCustomerServiceRequestsByCustomerServiceWorker(entityId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findClassifiedCustomerServiceRequestsByCustomerServiceWorker(WebSessionManager.getSessionContext(),
        	entityId, serviceClassifier, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findClassifiedEnterpriseServiceRequestsByCustomerService(entityId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findClassifiedEnterpriseServiceRequestsByCustomerService(WebSessionManager.getSessionContext(),
        	entityId, serviceClassifier, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findClassifiedEnterpriseServiceRequestsByServingEntity(entityId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findClassifiedEnterpriseServiceRequestsByServingEntity(WebSessionManager.getSessionContext(),
        	entityId, serviceClassifier, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findClassifiedEnterpriseServiceRequestsByServingManager(entityId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findClassifiedEnterpriseServiceRequestsByServingManager(WebSessionManager.getSessionContext(),
        	entityId, serviceClassifier, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findClassifiedEnterpriseServiceRequestsByServingWorker(entityId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findClassifiedEnterpriseServiceRequestsByServingWorker(WebSessionManager.getSessionContext(),
        	entityId, serviceClassifier, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findClassifiedEnterpriseServiceRequestsForRegion(regionId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findClassifiedEnterpriseServiceRequestsForRegion(WebSessionManager.getSessionContext(),
        	regionId, serviceClassifier, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findCustomerServiceAssistants(serviceRequestId: (number | string | null)): Promise<Array<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findCustomerServiceAssistants(WebSessionManager.getSessionContext(),
        	serviceRequestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findCustomerServiceRequestsByClientEntity(entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findCustomerServiceRequestsByClientEntity(WebSessionManager.getSessionContext(),
        	entityId, serviceType, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findCustomerServiceRequestsByClientEntityAndServiceTypeName(entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findCustomerServiceRequestsByClientEntityAndServiceTypeName(WebSessionManager.getSessionContext(),
        	entityId, serviceTypeName, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findCustomerServiceRequestsByCustomerServiceWorker(entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findCustomerServiceRequestsByCustomerServiceWorker(WebSessionManager.getSessionContext(),
        	entityId, serviceType, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findCustomerServiceRequestsByCustomerServiceWorkerAndServiceTypeName(entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findCustomerServiceRequestsByCustomerServiceWorkerAndServiceTypeName(WebSessionManager.getSessionContext(),
        	entityId, serviceTypeName, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceProfessionalsToReassign(serviceRequestId: (number | string | null)): Promise<Array<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceProfessionalsToReassign(WebSessionManager.getSessionContext(),
        	serviceRequestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByClientEntity(entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByClientEntity(WebSessionManager.getSessionContext(),
        	entityId, serviceType, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByClientEntityAndServiceTypeName(entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByClientEntityAndServiceTypeName(WebSessionManager.getSessionContext(),
        	entityId, serviceTypeName, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByCustomerService(entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByCustomerService(WebSessionManager.getSessionContext(),
        	entityId, serviceType, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByCustomerServiceAndServiceTypeName(entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByCustomerServiceAndServiceTypeName(WebSessionManager.getSessionContext(),
        	entityId, serviceTypeName, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByServiceTypeNameForRegion(regionId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByServiceTypeNameForRegion(WebSessionManager.getSessionContext(),
        	regionId, serviceTypeName, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByServingEntity(entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByServingEntity(WebSessionManager.getSessionContext(),
        	entityId, serviceType, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByServingEntityAndServiceTypeName(entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByServingEntityAndServiceTypeName(WebSessionManager.getSessionContext(),
        	entityId, serviceTypeName, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByServingManager(entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByServingManager(WebSessionManager.getSessionContext(),
        	entityId, serviceType, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByServingManagerAndServiceTypeName(entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByServingManagerAndServiceTypeName(WebSessionManager.getSessionContext(),
        	entityId, serviceTypeName, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByServingWorker(entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByServingWorker(WebSessionManager.getSessionContext(),
        	entityId, serviceType, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsByServingWorkerAndServiceTypeName(entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsByServingWorkerAndServiceTypeName(WebSessionManager.getSessionContext(),
        	entityId, serviceTypeName, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceRequestsForRegion(regionId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findEnterpriseServiceRequestsForRegion(WebSessionManager.getSessionContext(),
        	regionId, serviceType, statusClass, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findServiceEnterprisesOnServiceRequest(serviceRequestId: (number | string | null), tradableId: (number | string | null), tradeId: (number | string | null), pageBlockAccessSpec: PageBlockAccessSpec): Promise<PageableData<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findServiceEnterprisesOnServiceRequest(WebSessionManager.getSessionContext(),
        	serviceRequestId, tradableId, tradeId, pageBlockAccessSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findServiceProfessionalsOnServiceRequest(serviceRequestId: (number | string | null), tradableId: (number | string | null), tradeId: (number | string | null), enterpriseId: (number | string | null), pageBlockAccessSpec: PageBlockAccessSpec): Promise<PageableData<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.findServiceProfessionalsOnServiceRequest(WebSessionManager.getSessionContext(),
        	serviceRequestId, tradableId, tradeId, enterpriseId, pageBlockAccessSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getAndRequestToAssignEnterpriseServiceRequestById(requestId: (number | string | null)): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getAndRequestToAssignEnterpriseServiceRequestById(WebSessionManager.getSessionContext(),
        	requestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getCustomerServiceMonitorStats(entityId: (number | string | null)): Promise<EntityServiceStats> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getCustomerServiceMonitorStats(WebSessionManager.getSessionContext(),
        	entityId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getCustomerServiceStats(entityId: (number | string | null)): Promise<EntityServiceStats> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getCustomerServiceStats(WebSessionManager.getSessionContext(),
        	entityId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getEnterpriseServiceRequestById(requestId: (number | string | null)): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getEnterpriseServiceRequestById(WebSessionManager.getSessionContext(),
        	requestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getFinancingServiceRequestClassifier(): Promise<FinancingServiceRequestClassifier> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getFinancingServiceRequestClassifier(WebSessionManager.getSessionContext(),
        	
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getMessagesOnServiceRequest(serviceRequestId: (number | string | null), pageBlockAccessSpec: PageBlockAccessSpec): Promise<PageableData<EntityMessage>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getMessagesOnServiceRequest(WebSessionManager.getSessionContext(),
        	serviceRequestId, pageBlockAccessSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getOrCreateExclusiveCustomerServiceSessionByOrderId(orderId: (number | string | null)): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getOrCreateExclusiveCustomerServiceSessionByOrderId(WebSessionManager.getSessionContext(),
        	orderId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getOrCreateExclusiveCustomerServiceSessionByTradableId(tradableId: (number | string | null)): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getOrCreateExclusiveCustomerServiceSessionByTradableId(WebSessionManager.getSessionContext(),
        	tradableId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getOrCreateExclusiveCustomerServiceSessionByTradeId(tradeId: (number | string | null)): Promise<EnterpriseServiceRequest> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getOrCreateExclusiveCustomerServiceSessionByTradeId(WebSessionManager.getSessionContext(),
        	tradeId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getServiceProfessionalsByEnterpriseId(entityId: (number | string | null), serviceType: (EnterpriseServiceType | null)): Promise<Array<AssociatedEntityInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getServiceProfessionalsByEnterpriseId(WebSessionManager.getSessionContext(),
        	entityId, serviceType, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getServiceProfessionalsByEnterpriseIdAndServiceTypeName(entityId: (number | string | null), serviceTypeName: (string | null)): Promise<Array<AssociatedEntityInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getServiceProfessionalsByEnterpriseIdAndServiceTypeName(WebSessionManager.getSessionContext(),
        	entityId, serviceTypeName, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getServiceRequestClassifierByServiceType(serviceType: (EnterpriseServiceType | null), forEnterprise: (boolean | null)): Promise<ServiceRequestClassifier> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getServiceRequestClassifierByServiceType(WebSessionManager.getSessionContext(),
        	serviceType, forEnterprise, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getServiceRequestClassifierByServiceTypeName(serviceTypeName: (string | null), forEnterprise: (boolean | null)): Promise<ServiceRequestClassifier> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getServiceRequestClassifierByServiceTypeName(WebSessionManager.getSessionContext(),
        	serviceTypeName, forEnterprise, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getServingEntityServiceStats(entityId: (number | string | null)): Promise<EntityServiceStats> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.getServingEntityServiceStats(WebSessionManager.getSessionContext(),
        	entityId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public joinActivityOnServiceRequest(serviceRequest: (EnterpriseServiceRequest | null)): Promise<EntityMessageResponse> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.joinActivityOnServiceRequest(WebSessionManager.getSessionContext(),
        	serviceRequest, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public openMessageSessionOnServiceRequest(serviceRequestId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.openMessageSessionOnServiceRequest(WebSessionManager.getSessionContext(),
        	serviceRequestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public payEnterpriseServiceRequest(requestId: (number | string | null), paymentSpec: (PaymentSpec | null)): Promise<EnterpriseServiceOrder> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.payEnterpriseServiceRequest(WebSessionManager.getSessionContext(),
        	requestId, paymentSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public reassignToWorker(requestId: (number | string | null), enterpriseId: (number | string | null), workerId: (number | string | null)): Promise<ServingEntityInfos> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.reassignToWorker(WebSessionManager.getSessionContext(),
        	requestId, enterpriseId, workerId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public rejectByServiceProvider(requestId: (number | string | null)): Promise<RequestStatusType> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.rejectByServiceProvider(WebSessionManager.getSessionContext(),
        	requestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public rejectDelegateByServiceWorker(requestId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.rejectDelegateByServiceWorker(WebSessionManager.getSessionContext(),
        	requestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public requestToReassignServiceProvider(requestId: (number | string | null)): Promise<ServingEntityInfos> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.requestToReassignServiceProvider(WebSessionManager.getSessionContext(),
        	requestId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public selectServiceRequestWorker(requestId: (number | string | null), enterpriseId: (number | string | null), workerId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceRequestService.selectServiceRequestWorker(WebSessionManager.getSessionContext(),
        	requestId, enterpriseId, workerId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

}
