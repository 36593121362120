/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {MessageType} from '../MessageContentType'
import {MessageResponseType} from '../MessageResponseType'
import {Message} from './Message'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractMessage extends AbstractSerializable implements Message  {
    createTime: (number | string);

    constructor() {
    	super();
    }

    public getCreateTime(): (number | string) {
    	return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
    	this.createTime = createTime;
    }


    abstract isMultipleResponse(): boolean;

    abstract isResponseRequired(): boolean;

    abstract supportResend(): boolean;

    abstract getResponseType(): MessageResponseType;

    abstract getContentType(): MessageType;

    abstract getTypeId(): string;

    public fillFromJson(json: Object, factory?: TypeFactory) {
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.message.api.AbstractMessage";
    }
}
