/**
 * 自动生成， 请不要手工修改！
 */

export enum UserAdCategory {
  WISH,
  LOST_AND_FOUND,
  HELP,
  SELL_GOODS,
  TEMP_JOB,
  JOB,
  LET_HOUSE,
  SELL_HOUSE,
  GENERAL,
}
