/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { AdContent } from "../../business/ad/AdContent";
import { EntityOfficialRoleView } from "../EntityOfficialRoleView";
import { GeoEntityDesc } from "../../geolocation/GeoEntityDesc";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Serializer } from "../../../serialize/Serializer";
import { SystemSuppliedEntityInfo } from "../SystemSuppliedEntityInfo";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class EnterpriseHomePageInfo extends AbstractSerializable {
  enterpriseEntityInfo: SystemSuppliedEntityInfo;
  productCount: number;
  employeeCount: number;
  entityOfficialRoleView: EntityOfficialRoleView;
  sectorAssocCount: number;
  memberedSectorAssocs: Array<GeoEntityDesc>;
  adContent: AdContent;

  constructor() {
    super();
  }

  public getEnterpriseEntityInfo(): SystemSuppliedEntityInfo {
    return this.enterpriseEntityInfo;
  }

  public setEnterpriseEntityInfo(
    enterpriseEntityInfo: SystemSuppliedEntityInfo
  ) {
    this.enterpriseEntityInfo = enterpriseEntityInfo;
  }

  public getProductCount(): number {
    return this.productCount;
  }

  public setProductCount(productCount: number) {
    this.productCount = productCount;
  }

  public getEmployeeCount(): number {
    return this.employeeCount;
  }

  public setEmployeeCount(employeeCount: number) {
    this.employeeCount = employeeCount;
  }

  public getEntityOfficialRoleView(): EntityOfficialRoleView {
    return this.entityOfficialRoleView;
  }

  public setEntityOfficialRoleView(
    entityOfficialRoleView: EntityOfficialRoleView
  ) {
    this.entityOfficialRoleView = entityOfficialRoleView;
  }

  public getSectorAssocCount(): number {
    return this.sectorAssocCount;
  }

  public setSectorAssocCount(sectorAssocCount: number) {
    this.sectorAssocCount = sectorAssocCount;
  }

  public getMemberedSectorAssocs(): Array<GeoEntityDesc> {
    return this.memberedSectorAssocs;
  }

  public setMemberedSectorAssocs(memberedSectorAssocs: Array<GeoEntityDesc>) {
    this.memberedSectorAssocs = memberedSectorAssocs;
  }

  public getAdContent(): AdContent {
    return this.adContent;
  }

  public setAdContent(adContent: AdContent) {
    this.adContent = adContent;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const enterpriseEntityInfo = json["enterpriseEntityInfo"];
    if (enterpriseEntityInfo != null) {
      const convertedEnterpriseEntityInfo0 = new SystemSuppliedEntityInfo();
      convertedEnterpriseEntityInfo0.fillFromJson(
        enterpriseEntityInfo,
        factory
      );
      this.setEnterpriseEntityInfo(convertedEnterpriseEntityInfo0);
    }
    const productCount = json["productCount"];
    if (productCount != null) {
      this.setProductCount(productCount);
    }
    const employeeCount = json["employeeCount"];
    if (employeeCount != null) {
      this.setEmployeeCount(employeeCount);
    }
    const entityOfficialRoleView = json["entityOfficialRoleView"];
    if (entityOfficialRoleView != null) {
      const convertedEntityOfficialRoleView0 = new EntityOfficialRoleView();
      convertedEntityOfficialRoleView0.fillFromJson(
        entityOfficialRoleView,
        factory
      );
      this.setEntityOfficialRoleView(convertedEntityOfficialRoleView0);
    }
    const sectorAssocCount = json["sectorAssocCount"];
    if (sectorAssocCount != null) {
      this.setSectorAssocCount(sectorAssocCount);
    }
    let memberedSectorAssocs = json["memberedSectorAssocs"];
    if (memberedSectorAssocs != null) {
      const convertedMemberedSectorAssocs0 = [];
      memberedSectorAssocs = memberedSectorAssocs[1];
      for (const i in memberedSectorAssocs) {
        const convertedMemberedSectorAssocs10 = memberedSectorAssocs[i];
        const convertedMemberedSectorAssocs1 =
          Serializer.fillFromJsonObjectWithTypeTag(
            convertedMemberedSectorAssocs10,
            "__type",
            factory
          );
        convertedMemberedSectorAssocs0.push(convertedMemberedSectorAssocs1);
      }
      this.setMemberedSectorAssocs(convertedMemberedSectorAssocs0);
    }
    const adContent = json["adContent"];
    if (adContent != null) {
      const convertedAdContent0 = Serializer.fillFromJsonObjectWithTypeTag(
        adContent,
        "__type",
        factory
      );
      this.setAdContent(convertedAdContent0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const enterpriseEntityInfo = this.getEnterpriseEntityInfo();
    if (enterpriseEntityInfo != null) {
      const convertedEnterpriseEntityInfo0 = enterpriseEntityInfo.getJson(
        JsonTypeTagType.NONE
      );
      json["enterpriseEntityInfo"] = convertedEnterpriseEntityInfo0;
    }
    const productCount = this.getProductCount();
    if (productCount != null) {
      json["productCount"] = productCount;
    }
    const employeeCount = this.getEmployeeCount();
    if (employeeCount != null) {
      json["employeeCount"] = employeeCount;
    }
    const entityOfficialRoleView = this.getEntityOfficialRoleView();
    if (entityOfficialRoleView != null) {
      const convertedEntityOfficialRoleView0 = entityOfficialRoleView.getJson(
        JsonTypeTagType.NONE
      );
      json["entityOfficialRoleView"] = convertedEntityOfficialRoleView0;
    }
    const sectorAssocCount = this.getSectorAssocCount();
    if (sectorAssocCount != null) {
      json["sectorAssocCount"] = sectorAssocCount;
    }
    const memberedSectorAssocs = this.getMemberedSectorAssocs();
    if (memberedSectorAssocs != null) {
      const convertedMemberedSectorAssocs0 = [];
      for (const i in memberedSectorAssocs) {
        const convertedMemberedSectorAssocs10 = memberedSectorAssocs[i];
        const convertedMemberedSectorAssocs1 =
          convertedMemberedSectorAssocs10.getJson(JsonTypeTagType.TYPE);
        convertedMemberedSectorAssocs0.push(convertedMemberedSectorAssocs1);
      }
      const convertedMemberedSectorAssocsWithType1 = [
        "java.util.ArrayList",
        convertedMemberedSectorAssocs0,
      ];
      json["memberedSectorAssocs"] = convertedMemberedSectorAssocsWithType1;
    }
    const adContent = this.getAdContent();
    if (adContent != null) {
      const convertedAdContent0 = adContent.getJson(JsonTypeTagType.TYPE);
      json["adContent"] = convertedAdContent0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.enterprise.EnterpriseHomePageInfo";
  }
}
