/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { EntityType } from "./EntityType";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class EntityServiceView extends AbstractSerializable {
  entityName: string;
  logoImgUrl: string;
  entityId: number | string;
  entityType: EntityType;
  routeKey: number;
  latitude: number;
  longitude: number;
  simpleAddress: string;
  briefDescription: string;
  price: number;
  score: number;

  constructor() {
    super();
  }

  public getEntityName(): string {
    return this.entityName;
  }

  public setEntityName(entityName: string) {
    this.entityName = entityName;
  }

  public getLogoImgUrl(): string {
    return this.logoImgUrl;
  }

  public setLogoImgUrl(logoImgUrl: string) {
    this.logoImgUrl = logoImgUrl;
  }

  public getEntityId(): number | string {
    return this.entityId;
  }

  public setEntityId(entityId: number | string) {
    this.entityId = entityId;
  }

  public getEntityType(): EntityType {
    return this.entityType;
  }

  public setEntityType(entityType: EntityType) {
    this.entityType = entityType;
  }

  public getRouteKey(): number {
    return this.routeKey;
  }

  public setRouteKey(routeKey: number) {
    this.routeKey = routeKey;
  }

  public getLatitude(): number {
    return this.latitude;
  }

  public setLatitude(latitude: number) {
    this.latitude = latitude;
  }

  public getLongitude(): number {
    return this.longitude;
  }

  public setLongitude(longitude: number) {
    this.longitude = longitude;
  }

  public getSimpleAddress(): string {
    return this.simpleAddress;
  }

  public setSimpleAddress(simpleAddress: string) {
    this.simpleAddress = simpleAddress;
  }

  public getBriefDescription(): string {
    return this.briefDescription;
  }

  public setBriefDescription(briefDescription: string) {
    this.briefDescription = briefDescription;
  }

  public getPrice(): number {
    return this.price;
  }

  public setPrice(price: number) {
    this.price = price;
  }

  public getScore(): number {
    return this.score;
  }

  public setScore(score: number) {
    this.score = score;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const entityName = json["entityName"];
    if (entityName != null) {
      this.setEntityName(entityName);
    }
    const logoImgUrl = json["logoImgUrl"];
    if (logoImgUrl != null) {
      this.setLogoImgUrl(logoImgUrl);
    }
    const entityId = json["entityId"];
    if (entityId != null) {
      this.setEntityId(entityId);
    }
    const entityType = json["entityType"];
    if (entityType != null) {
      const convertedEntityType0 = EntityType[<string>entityType];
      this.setEntityType(convertedEntityType0);
    }
    const routeKey = json["routeKey"];
    if (routeKey != null) {
      this.setRouteKey(routeKey);
    }
    const latitude = json["latitude"];
    if (latitude != null) {
      this.setLatitude(latitude);
    }
    const longitude = json["longitude"];
    if (longitude != null) {
      this.setLongitude(longitude);
    }
    const simpleAddress = json["simpleAddress"];
    if (simpleAddress != null) {
      this.setSimpleAddress(simpleAddress);
    }
    const briefDescription = json["briefDescription"];
    if (briefDescription != null) {
      this.setBriefDescription(briefDescription);
    }
    const price = json["price"];
    if (price != null) {
      this.setPrice(price);
    }
    const score = json["score"];
    if (score != null) {
      this.setScore(score);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const entityName = this.getEntityName();
    if (entityName != null) {
      json["entityName"] = entityName;
    }
    const logoImgUrl = this.getLogoImgUrl();
    if (logoImgUrl != null) {
      json["logoImgUrl"] = logoImgUrl;
    }
    const entityId = this.getEntityId();
    if (entityId != null) {
      json["entityId"] = String(entityId);
    }
    const entityType = this.getEntityType();
    if (entityType != null) {
      const convertedEntityType0 = EntityType[entityType];
      json["entityType"] = convertedEntityType0;
    }
    const routeKey = this.getRouteKey();
    if (routeKey != null) {
      json["routeKey"] = routeKey;
    }
    const latitude = this.getLatitude();
    if (latitude != null) {
      json["latitude"] = latitude;
    }
    const longitude = this.getLongitude();
    if (longitude != null) {
      json["longitude"] = longitude;
    }
    const simpleAddress = this.getSimpleAddress();
    if (simpleAddress != null) {
      json["simpleAddress"] = simpleAddress;
    }
    const briefDescription = this.getBriefDescription();
    if (briefDescription != null) {
      json["briefDescription"] = briefDescription;
    }
    const price = this.getPrice();
    if (price != null) {
      json["price"] = price;
    }
    const score = this.getScore();
    if (score != null) {
      json["score"] = score;
    }

    if (includeTypeTag) {
      json["__type"] = "EntityServiceView";
    }
  }

  public getTypeId(): string {
    return "EntityServiceView";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.EntityServiceView";
  }
}
