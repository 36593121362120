// @ts-nocheck
import { AbstractSimpleEntityMessageResponse } from "./AbstractSimpleEntityMessageResponse";

export class SimpleEntityMessageResponse extends AbstractSimpleEntityMessageResponse {
  constructor() {
    super();
  }

  isAccepted(): boolean {
    // Manual Code Here
  }
}
