// @ts-nocheck
import {AbstractInboxMessageWrapper} from './AbstractInboxMessageWrapper'
import {EnterpriseServiceType} from '../../../datatype/business/service/EnterpriseServiceType'
import {EntityChannelType} from '../../../datatype/entity/channel/EntityChannelType'
import {EntityInfo} from '../../../datatype/entity/EntityInfo'
import {EntityMessageStatus} from './EntityMessageStatus'
import {EntityMessage} from './EntityMessage'
import {MessageContentType} from '../../MessageContentType'
import {MessageResponseType} from '../../MessageResponseType'
import {MessageRoleType} from '../../api/MessageRoleType'
import {RequestStatusType} from '../../../datatype/request/RequestStatusType'


export class InboxMessageWrapper extends AbstractInboxMessageWrapper  {
    constructor() {
    	super();
    }

    getCorrelationId(): T {
      // Manual Code Here
    }
    isMultipleResponse(): boolean {
      // Manual Code Here
    }
    isResponseRequired(): boolean {
      // Manual Code Here
    }
    supportResend(): boolean {
      // Manual Code Here
    }
    getResponseType(): MessageResponseType {
      // Manual Code Here
    }
    getContentType(): MessageContentType {
      // Manual Code Here
    }
    getCreateTime(): (number | string) {
      // Manual Code Here
    }
    setCreateTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    isDelegateAccepted(): boolean {
      // Manual Code Here
    }
    isDisplayable(): boolean {
      // Manual Code Here
    }
    getServiceType(): EnterpriseServiceType {
      // Manual Code Here
    }
    getServiceRequestStatus(): RequestStatusType {
      // Manual Code Here
    }
    getRoleType(): MessageRoleType {
      // Manual Code Here
    }
    getMessageCore(): EntityMessage {
      // Manual Code Here
    }
    getStatus(): EntityMessageStatus {
      // Manual Code Here
    }
    getAssistantId(): (number | string) {
      // Manual Code Here
    }
    getCorrelationId(): (number | string) {
      // Manual Code Here
    }
    getId(): (number | string) {
      // Manual Code Here
    }
    getServiceRequestId(): (number | string) {
      // Manual Code Here
    }
    getMessageContent(): T {
      // Manual Code Here
    }
    getDataText(): string {
      // Manual Code Here
    }
    getReferenceKey(): string {
      // Manual Code Here
    }
    getServiceClassifier(): string {
      // Manual Code Here
    }
    getServiceClassifierDisplayName(): string {
      // Manual Code Here
    }
    getServiceClassifierForPlatform(): string {
      // Manual Code Here
    }
    getServiceClassifierForProvider(): string {
      // Manual Code Here
    }
    validateMessage(): Set<string> {
      // Manual Code Here
    }
    setAssistantId(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setChannelReceiveTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setCorrelationId(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setDelegateAccepted(arg0: (boolean | null)) {
      // Manual Code Here
    }
    setId(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setReferenceKey(arg0: (string | null)) {
      // Manual Code Here
    }
    setServiceClassifier(arg0: (string | null)) {
      // Manual Code Here
    }
    setServiceClassifierDisplayName(arg0: (string | null)) {
      // Manual Code Here
    }
    setServiceClassifierForPlatform(arg0: (string | null)) {
      // Manual Code Here
    }
    setServiceClassifierForProvider(arg0: (string | null)) {
      // Manual Code Here
    }
    setServiceRequestId(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setServiceRequestStatus(arg0: (RequestStatusType | null)) {
      // Manual Code Here
    }
    setServiceType(arg0: (EnterpriseServiceType | null)) {
      // Manual Code Here
    }
    setStatus(arg0: (EntityMessageStatus | null)) {
      // Manual Code Here
    }
    getSenderEntityInfo(): EntityInfo {
      // Manual Code Here
    }
    getMediumChannelId(): EntityChannelType {
      // Manual Code Here
    }
    getContentType(): MessageContentType {
      // Manual Code Here
    }
    getId(): (number | string) {
      // Manual Code Here
    }
    getTitle(): string {
      // Manual Code Here
    }
    getChannelReceiveTime(): (number | string) {
      // Manual Code Here
    }
    getMediumEntityId(): (number | string) {
      // Manual Code Here
    }
    getSenderEntityId(): (number | string) {
      // Manual Code Here
    }
    setMediumEntityId(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setSenderEntityInfo(arg0: (EntityInfo | null)) {
      // Manual Code Here
    }
    getRootMessageId(): T {
      // Manual Code Here
    }

}
