/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../../common/AbstractClientServiceAdapter'
import {BasicReview} from '../../datatype/ipsphere/review/BasicReview'
import {BiConsumer} from '../../util/BiConsumer'
import {CompositeReviewInfo} from '../../datatype/ipsphere/review/CompositeReviewInfo'
import {EntityMessageResponse} from '../../message/entity/api/EntityMessageResponse'
import {GeneralReviewService} from '../../api/review/GeneralReviewService'
import {PageBlockAccessSpec} from '../../datatype/paging/PageBlockAccessSpec'
import {PlatformDataContainer} from '../../datatype/platform/PlatformDataContainer'
import {RequestType} from '../../datatype/request/RequestType'
import {ServiceConsumers} from '../../util/ServiceConsumers'
import {ServiceRequest} from '../../common/ServiceRequest'
import {SessionContext} from '../../session/SessionContext'


export class GeneralReviewServiceProxy extends AbstractClientServiceAdapter implements GeneralReviewService {

    public constructor() {
        super("com.gong_wei.service.api.review.GeneralReviewService");
    }

    public addBasicReview(sessionContext: (SessionContext | null), basicReview: (BasicReview | null), resultHandler: BiConsumer<EntityMessageResponse, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.review.GeneralReviewService", "addBasicReview", [sessionContext, basicReview]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public cancelReview(sessionContext: (SessionContext | null), targetEntityId: (number | string | null), reviewId: (number | string | null), regionId: (number | string | null), requestType: (RequestType | null), reason: (string | null), resultHandler: BiConsumer<EntityMessageResponse, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.review.GeneralReviewService", "cancelReview", [sessionContext, targetEntityId, reviewId, regionId, requestType, reason]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getReviewDetails(sessionContext: (SessionContext | null), pageBlockSpec: (PageBlockAccessSpec | null), reviewId: (number | string | null), resultHandler: BiConsumer<PlatformDataContainer<CompositeReviewInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.review.GeneralReviewService", "getReviewDetails", [sessionContext, pageBlockSpec, reviewId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

}
