/**
 * 节流！
 */
export default (fn: () => void, time: number) => {
  let timer;
  return () => {
    if (timer) return;
    fn();
    timer = setTimeout(() => (timer = null), time);
  };
};
