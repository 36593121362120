enum AppEnviroment {
  dev,
  prod,
}

export class ServerConfig {
  public env: AppEnviroment = AppEnviroment.dev;

  public ip = "";

  public wssPort = "8080";

  public protocol = "";

  public version = 0.04;

  public webSocketProtocol = "ws";

  public ossUploadUrl = "http://gong-wei.oss-cn-hangzhou.aliyuncs.com";

  constructor(
    env: AppEnviroment,
    webSocketProtocol: string,
    ip: string,
    wssPort: string,
    protocol: string
  ) {
    this.env = env;
    this.webSocketProtocol = webSocketProtocol;
    this.ip = ip;
    this.wssPort = wssPort;
    this.protocol = protocol;
  }

  public isDev(): boolean {
    return this.env === AppEnviroment.dev;
  }
}

//
const serverConfig = new ServerConfig(
  AppEnviroment.dev, //布署到安全测试换进
  "wss",
  "www.gong-wei.com/websocket_dev/",
  null,
  "https"
);
// const serverConfig = new ServerConfig(
//   AppEnviroment.prod,
//   "wss",
//   "service.gong-wei.com:8680/external/",
//   null,
//   "https"
// );
export default serverConfig;
