/**
 * 自动生成， 请不要手工修改！
 */

export enum RequestStatusClassifier {
  TO_PROCESS,
  IN_PROCESS,
  IN_ERROR,
  COMPLETED,
  OBSOLETE,
}
