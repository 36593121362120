// @ts-nocheck
import { AbstractOfficialEndorsementReviewRequest } from "./AbstractOfficialEndorsementReviewRequest";
import { CoworkType } from "../../cowork/CoworkType";
import { EntityInfo } from "../../entity/EntityInfo";
import { RequestType } from "../RequestType";

export class OfficialEndorsementReviewRequest extends AbstractOfficialEndorsementReviewRequest {
  constructor() {
    super();
  }

  /**
   * 必选数据： 请求对象类型
   */
  public withTargetType(
    targetType: CoworkType
  ): OfficialEndorsementReviewRequest {
    this.targetType = targetType;
    return this;
  }

  /**
   * 必选数据： 请求对象ID
   */
  public withTargetId(
    targetId: number | string
  ): OfficialEndorsementReviewRequest {
    this.targetId = targetId;
    return this;
  }

  /**
   * 必选数据： 请求对象所属社区D
   */
  public withTargetEntityInfo(
    targetEntityInfo: EntityInfo
  ): OfficialEndorsementReviewRequest {
    this.targetEntityInfo = targetEntityInfo;
    return this;
  }

  /**
   * 必选数据： 请求者信息
   */
  public withRequestorInfo(
    requestorInfo: EntityInfo
  ): OfficialEndorsementReviewRequest {
    this.requestorInfo = requestorInfo;
    return this;
  }

  /**
   * 必选数据： 请求类型
   */
  public withRequestType(
    requestType: RequestType
  ): OfficialEndorsementReviewRequest {
    this.requestType = requestType;
    return this;
  }
}
