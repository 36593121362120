/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class EliteState extends AbstractSerializable  {
    globalBusiness: boolean;
    regionBusiness: boolean;
    inReview: boolean;

    constructor() {
    	super();
    }

    public isGlobalBusiness(): boolean {
    	return this.globalBusiness;
    }

    public setGlobalBusiness(globalBusiness: boolean) {
    	this.globalBusiness = globalBusiness;
    }

    public isRegionBusiness(): boolean {
    	return this.regionBusiness;
    }

    public setRegionBusiness(regionBusiness: boolean) {
    	this.regionBusiness = regionBusiness;
    }

    public isInReview(): boolean {
    	return this.inReview;
    }

    public setInReview(inReview: boolean) {
    	this.inReview = inReview;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let globalBusiness = json["globalBusiness"];
        if (globalBusiness != null) {
            this.setGlobalBusiness(globalBusiness);
        }
        let regionBusiness = json["regionBusiness"];
        if (regionBusiness != null) {
            this.setRegionBusiness(regionBusiness);
        }
        let inReview = json["inReview"];
        if (inReview != null) {
            this.setInReview(inReview);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let globalBusiness = this.isGlobalBusiness();
        if (globalBusiness != null) {
            json["globalBusiness"] = globalBusiness;
        }
        let regionBusiness = this.isRegionBusiness();
        if (regionBusiness != null) {
            json["regionBusiness"] = regionBusiness;
        }
        let inReview = this.isInReview();
        if (inReview != null) {
            json["inReview"] = inReview;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.user.EliteState";
    }
}
