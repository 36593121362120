/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EnterpriseServiceCategory} from '../../business/service/EnterpriseServiceCategory'
import {EnterpriseServiceType} from '../../business/service/EnterpriseServiceType'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class EliteInfo extends AbstractSerializable  {
    applicationId: (number | string);
    userId: (number | string);
    regionId: (number | string);
    globalBusiness: boolean;
    regionBusiness: boolean;
    serviceCategory: EnterpriseServiceCategory;
    serviceType: EnterpriseServiceType;
    keywords: Array<string>;
    imageUrls: Array<string>;
    citizenId: string;
    applyTime: (number | string);
    reviewerId: (number | string);
    accepted: boolean;
    reviewTime: (number | string);
    reviewNote: string;

    constructor() {
    	super();
    }

    public getApplicationId(): (number | string) {
    	return this.applicationId;
    }

    public setApplicationId(applicationId: (number | string)) {
    	this.applicationId = applicationId;
    }

    public getUserId(): (number | string) {
    	return this.userId;
    }

    public setUserId(userId: (number | string)) {
    	this.userId = userId;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public isGlobalBusiness(): boolean {
    	return this.globalBusiness;
    }

    public setGlobalBusiness(globalBusiness: boolean) {
    	this.globalBusiness = globalBusiness;
    }

    public isRegionBusiness(): boolean {
    	return this.regionBusiness;
    }

    public setRegionBusiness(regionBusiness: boolean) {
    	this.regionBusiness = regionBusiness;
    }

    public getServiceCategory(): EnterpriseServiceCategory {
    	return this.serviceCategory;
    }

    public setServiceCategory(serviceCategory: EnterpriseServiceCategory) {
    	this.serviceCategory = serviceCategory;
    }

    public getServiceType(): EnterpriseServiceType {
    	return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
    	this.serviceType = serviceType;
    }

    public getKeywords(): Array<string> {
    	return this.keywords;
    }

    public setKeywords(keywords: Array<string>) {
    	this.keywords = keywords;
    }

    public getImageUrls(): Array<string> {
    	return this.imageUrls;
    }

    public setImageUrls(imageUrls: Array<string>) {
    	this.imageUrls = imageUrls;
    }

    public getCitizenId(): string {
    	return this.citizenId;
    }

    public setCitizenId(citizenId: string) {
    	this.citizenId = citizenId;
    }

    public getApplyTime(): (number | string) {
    	return this.applyTime;
    }

    public setApplyTime(applyTime: (number | string)) {
    	this.applyTime = applyTime;
    }

    public getReviewerId(): (number | string) {
    	return this.reviewerId;
    }

    public setReviewerId(reviewerId: (number | string)) {
    	this.reviewerId = reviewerId;
    }

    public getAccepted(): boolean {
    	return this.accepted;
    }

    public setAccepted(accepted: boolean) {
    	this.accepted = accepted;
    }

    public getReviewTime(): (number | string) {
    	return this.reviewTime;
    }

    public setReviewTime(reviewTime: (number | string)) {
    	this.reviewTime = reviewTime;
    }

    public getReviewNote(): string {
    	return this.reviewNote;
    }

    public setReviewNote(reviewNote: string) {
    	this.reviewNote = reviewNote;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let applicationId = json["applicationId"];
        if (applicationId != null) {
            this.setApplicationId(applicationId);
        }
        let userId = json["userId"];
        if (userId != null) {
            this.setUserId(userId);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let globalBusiness = json["globalBusiness"];
        if (globalBusiness != null) {
            this.setGlobalBusiness(globalBusiness);
        }
        let regionBusiness = json["regionBusiness"];
        if (regionBusiness != null) {
            this.setRegionBusiness(regionBusiness);
        }
        let serviceCategory = json["serviceCategory"];
        if (serviceCategory != null) {
            let convertedServiceCategory0 = EnterpriseServiceCategory[<string>serviceCategory];
            this.setServiceCategory(convertedServiceCategory0);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let keywords = json["keywords"];
        if (keywords != null) {
            let convertedKeywords0 = [];
            keywords = keywords[1];
            for (let i in keywords) {
                let convertedKeywords10 = keywords[i];
                convertedKeywords0.push(convertedKeywords10);
            }
            this.setKeywords(convertedKeywords0);
        }
        let imageUrls = json["imageUrls"];
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            imageUrls = imageUrls[1];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            this.setImageUrls(convertedImageUrls0);
        }
        let citizenId = json["citizenId"];
        if (citizenId != null) {
            this.setCitizenId(citizenId);
        }
        let applyTime = json["applyTime"];
        if (applyTime != null) {
            this.setApplyTime(applyTime);
        }
        let reviewerId = json["reviewerId"];
        if (reviewerId != null) {
            this.setReviewerId(reviewerId);
        }
        let accepted = json["accepted"];
        if (accepted != null) {
            this.setAccepted(accepted);
        }
        let reviewTime = json["reviewTime"];
        if (reviewTime != null) {
            this.setReviewTime(reviewTime);
        }
        let reviewNote = json["reviewNote"];
        if (reviewNote != null) {
            this.setReviewNote(reviewNote);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let applicationId = this.getApplicationId();
        if (applicationId != null) {
            json["applicationId"] = String(applicationId);
        }
        let userId = this.getUserId();
        if (userId != null) {
            json["userId"] = String(userId);
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let globalBusiness = this.isGlobalBusiness();
        if (globalBusiness != null) {
            json["globalBusiness"] = globalBusiness;
        }
        let regionBusiness = this.isRegionBusiness();
        if (regionBusiness != null) {
            json["regionBusiness"] = regionBusiness;
        }
        let serviceCategory = this.getServiceCategory();
        if (serviceCategory != null) {
            let convertedServiceCategory0 = EnterpriseServiceCategory[serviceCategory];
            json["serviceCategory"] = convertedServiceCategory0;
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let keywords = this.getKeywords();
        if (keywords != null) {
            let convertedKeywords0 = [];
            for (let i in keywords) {
                let convertedKeywords10 = keywords[i];
                convertedKeywords0.push(convertedKeywords10);
            }
            let convertedKeywordsWithType1 = ["java.util.ArrayList", convertedKeywords0];
            json["keywords"] = convertedKeywordsWithType1;
        }
        let imageUrls = this.getImageUrls();
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            let convertedImageUrlsWithType1 = ["java.util.ArrayList", convertedImageUrls0];
            json["imageUrls"] = convertedImageUrlsWithType1;
        }
        let citizenId = this.getCitizenId();
        if (citizenId != null) {
            json["citizenId"] = citizenId;
        }
        let applyTime = this.getApplyTime();
        if (applyTime != null) {
            json["applyTime"] = String(applyTime);
        }
        let reviewerId = this.getReviewerId();
        if (reviewerId != null) {
            json["reviewerId"] = String(reviewerId);
        }
        let accepted = this.getAccepted();
        if (accepted != null) {
            json["accepted"] = accepted;
        }
        let reviewTime = this.getReviewTime();
        if (reviewTime != null) {
            json["reviewTime"] = String(reviewTime);
        }
        let reviewNote = this.getReviewNote();
        if (reviewNote != null) {
            json["reviewNote"] = reviewNote;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.user.EliteInfo";
    }
}
