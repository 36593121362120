// @ts-nocheck
import { AbstractCompositePlatformActivityInfo } from "./AbstractCompositePlatformActivityInfo";

export class CompositePlatformActivityInfo extends AbstractCompositePlatformActivityInfo {
  constructor() {
    super();
  }

  getVisits(): number {
    // Manual Code Here
  }
}
