/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {OffsetItem} from './OffsetItem'
import {PositionDescriptor} from './PositionDescriptor'
import {TypeFactory} from '../../serialize/TypeFactory'


export class OffsetPositions extends AbstractSerializable implements PositionDescriptor {
    globalTopicOffset: OffsetItem;
    globalEnterpriseOffSet: OffsetItem;
    localTopicOffset: OffsetItem;
    localEnterpriseOffset: OffsetItem;

    constructor() {
        super();
    }

    public getGlobalTopicOffset(): OffsetItem {
        return this.globalTopicOffset;
    }

    public setGlobalTopicOffset(globalTopicOffset: OffsetItem) {
        this.globalTopicOffset = globalTopicOffset;
    }

    public getGlobalEnterpriseOffSet(): OffsetItem {
        return this.globalEnterpriseOffSet;
    }

    public setGlobalEnterpriseOffSet(globalEnterpriseOffSet: OffsetItem) {
        this.globalEnterpriseOffSet = globalEnterpriseOffSet;
    }

    public getLocalTopicOffset(): OffsetItem {
        return this.localTopicOffset;
    }

    public setLocalTopicOffset(localTopicOffset: OffsetItem) {
        this.localTopicOffset = localTopicOffset;
    }

    public getLocalEnterpriseOffset(): OffsetItem {
        return this.localEnterpriseOffset;
    }

    public setLocalEnterpriseOffset(localEnterpriseOffset: OffsetItem) {
        this.localEnterpriseOffset = localEnterpriseOffset;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let globalTopicOffset = json["globalTopicOffset"];
        if (globalTopicOffset != null) {
            let convertedGlobalTopicOffset0 = new OffsetItem();
            convertedGlobalTopicOffset0.fillFromJson(globalTopicOffset, factory);
            this.setGlobalTopicOffset(convertedGlobalTopicOffset0);
        }
        let globalEnterpriseOffSet = json["globalEnterpriseOffSet"];
        if (globalEnterpriseOffSet != null) {
            let convertedGlobalEnterpriseOffSet0 = new OffsetItem();
            convertedGlobalEnterpriseOffSet0.fillFromJson(globalEnterpriseOffSet, factory);
            this.setGlobalEnterpriseOffSet(convertedGlobalEnterpriseOffSet0);
        }
        let localTopicOffset = json["localTopicOffset"];
        if (localTopicOffset != null) {
            let convertedLocalTopicOffset0 = new OffsetItem();
            convertedLocalTopicOffset0.fillFromJson(localTopicOffset, factory);
            this.setLocalTopicOffset(convertedLocalTopicOffset0);
        }
        let localEnterpriseOffset = json["localEnterpriseOffset"];
        if (localEnterpriseOffset != null) {
            let convertedLocalEnterpriseOffset0 = new OffsetItem();
            convertedLocalEnterpriseOffset0.fillFromJson(localEnterpriseOffset, factory);
            this.setLocalEnterpriseOffset(convertedLocalEnterpriseOffset0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let globalTopicOffset = this.getGlobalTopicOffset();
        if (globalTopicOffset != null) {
            let convertedGlobalTopicOffset0 = globalTopicOffset.getJson(JsonTypeTagType.NONE);
            json["globalTopicOffset"] = convertedGlobalTopicOffset0;
        }
        let globalEnterpriseOffSet = this.getGlobalEnterpriseOffSet();
        if (globalEnterpriseOffSet != null) {
            let convertedGlobalEnterpriseOffSet0 = globalEnterpriseOffSet.getJson(JsonTypeTagType.NONE);
            json["globalEnterpriseOffSet"] = convertedGlobalEnterpriseOffSet0;
        }
        let localTopicOffset = this.getLocalTopicOffset();
        if (localTopicOffset != null) {
            let convertedLocalTopicOffset0 = localTopicOffset.getJson(JsonTypeTagType.NONE);
            json["localTopicOffset"] = convertedLocalTopicOffset0;
        }
        let localEnterpriseOffset = this.getLocalEnterpriseOffset();
        if (localEnterpriseOffset != null) {
            let convertedLocalEnterpriseOffset0 = localEnterpriseOffset.getJson(JsonTypeTagType.NONE);
            json["localEnterpriseOffset"] = convertedLocalEnterpriseOffset0;
        }

        if (includeTypeTag) {
            json["__type"] = "OffsetPositions";
        }
    }


    public getTypeId(): string {
        return "OffsetPositions";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.paging.OffsetPositions";
    }
}
