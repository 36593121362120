/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { RoleType } from "./RoleType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class EntityOfficialRoleView extends AbstractSerializable {
  topOfficerName: string;
  topOfficerId: number | string;
  topOfficerRole: RoleType;
  officerCount: number;

  constructor() {
    super();
  }

  public getTopOfficerName(): string {
    return this.topOfficerName;
  }

  public setTopOfficerName(topOfficerName: string) {
    this.topOfficerName = topOfficerName;
  }

  public getTopOfficerId(): number | string {
    return this.topOfficerId;
  }

  public setTopOfficerId(topOfficerId: number | string) {
    this.topOfficerId = topOfficerId;
  }

  public getTopOfficerRole(): RoleType {
    return this.topOfficerRole;
  }

  public setTopOfficerRole(topOfficerRole: RoleType) {
    this.topOfficerRole = topOfficerRole;
  }

  public getOfficerCount(): number {
    return this.officerCount;
  }

  public setOfficerCount(officerCount: number) {
    this.officerCount = officerCount;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const topOfficerName = json["topOfficerName"];
    if (topOfficerName != null) {
      this.setTopOfficerName(topOfficerName);
    }
    const topOfficerId = json["topOfficerId"];
    if (topOfficerId != null) {
      this.setTopOfficerId(topOfficerId);
    }
    const topOfficerRole = json["topOfficerRole"];
    if (topOfficerRole != null) {
      const convertedTopOfficerRole0 = RoleType[<string>topOfficerRole];
      this.setTopOfficerRole(convertedTopOfficerRole0);
    }
    const officerCount = json["officerCount"];
    if (officerCount != null) {
      this.setOfficerCount(officerCount);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const topOfficerName = this.getTopOfficerName();
    if (topOfficerName != null) {
      json["topOfficerName"] = topOfficerName;
    }
    const topOfficerId = this.getTopOfficerId();
    if (topOfficerId != null) {
      json["topOfficerId"] = String(topOfficerId);
    }
    const topOfficerRole = this.getTopOfficerRole();
    if (topOfficerRole != null) {
      const convertedTopOfficerRole0 = RoleType[topOfficerRole];
      json["topOfficerRole"] = convertedTopOfficerRole0;
    }
    const officerCount = this.getOfficerCount();
    if (officerCount != null) {
      json["officerCount"] = officerCount;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.EntityOfficialRoleView";
  }
}
