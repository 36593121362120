import { MessageHandle } from "@/services/webapi/MessageHandle";
import { MessageListenerEntry } from "@/services/webimpl/MessageListenerEntry";

export class DefaultMessageHandle implements MessageHandle {
  private messageListeners: Array<MessageListenerEntry>;
  private messageListenerEntry: MessageListenerEntry;
  private reason: string;
  private cancelled: boolean;

  constructor(
    messageListenerEntry: MessageListenerEntry,
    messageListeners: Array<MessageListenerEntry>
  ) {
    this.messageListenerEntry = messageListenerEntry;
    this.messageListeners = messageListeners;
  }

  public cancel(reason: string) {
    this.reason = reason;
    this.cancelled = true;
    if (this.messageListeners) {
      const index = this.messageListeners.indexOf(this.messageListenerEntry, 0);
      if (index > -1) {
        this.messageListeners.splice(index, 1);
      }
    }
  }

  public isCancelled(): boolean {
    return this.cancelled;
  }

  public getCancellationReason(): string {
    return this.reason;
  }
}
