/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {EnterpriseServiceCategory} from '../EnterpriseServiceCategory'
import {EnterpriseServiceType} from '../EnterpriseServiceType'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {PeriodType} from '../../../PeriodType'
import {ServiceDeliveryType} from '../ServiceDeliveryType'
import {ServiceProviderType} from '../ServiceProviderType'
import {ServiceUseType} from '../ServiceUseType'
import {TopicType} from '../../../entity/topic/TopicType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class EnterpriseServiceTradableKeyInfo extends AbstractSerializable  {
    position: number;
    serviceType: EnterpriseServiceType;
    serviceTag: EnterpriseServiceCategory;
    serviceTypeName: string;
    serviceTypeDisplayName: string;
    serviceTagName: string;
    exclusiveServiceProvider: boolean;
    enterpriseServiceId: (number | string);
    title: string;
    brief: string;
    imgUrls: Array<string>;
    description: string;
    productType: TopicType;
    productId: (number | string);
    serviceQuota: number;
    discount: number;
    serviceDeliveryType: ServiceDeliveryType;
    serviceUseType: ServiceUseType;
    serviceProviderType: ServiceProviderType;
    price: number;
    originalPrice: number;
    settlePrice: number;
    otcPriced: boolean;
    packageOnly: boolean;
    activityId: (number | string);
    offered: boolean;
    startDay: number;
    endDay: number;
    periodType: PeriodType;
    servicePeriod: number;
    test: boolean;
    preSale: boolean;

    constructor() {
    	super();
    }

    public getPosition(): number {
    	return this.position;
    }

    public setPosition(position: number) {
    	this.position = position;
    }

    public getServiceType(): EnterpriseServiceType {
    	return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
    	this.serviceType = serviceType;
    }

    public getServiceTag(): EnterpriseServiceCategory {
    	return this.serviceTag;
    }

    public setServiceTag(serviceTag: EnterpriseServiceCategory) {
    	this.serviceTag = serviceTag;
    }

    public getServiceTypeName(): string {
    	return this.serviceTypeName;
    }

    public setServiceTypeName(serviceTypeName: string) {
    	this.serviceTypeName = serviceTypeName;
    }

    public getServiceTypeDisplayName(): string {
    	return this.serviceTypeDisplayName;
    }

    public setServiceTypeDisplayName(serviceTypeDisplayName: string) {
    	this.serviceTypeDisplayName = serviceTypeDisplayName;
    }

    public getServiceTagName(): string {
    	return this.serviceTagName;
    }

    public setServiceTagName(serviceTagName: string) {
    	this.serviceTagName = serviceTagName;
    }

    public getExclusiveServiceProvider(): boolean {
    	return this.exclusiveServiceProvider;
    }

    public setExclusiveServiceProvider(exclusiveServiceProvider: boolean) {
    	this.exclusiveServiceProvider = exclusiveServiceProvider;
    }

    public getEnterpriseServiceId(): (number | string) {
    	return this.enterpriseServiceId;
    }

    public setEnterpriseServiceId(enterpriseServiceId: (number | string)) {
    	this.enterpriseServiceId = enterpriseServiceId;
    }

    public getTitle(): string {
    	return this.title;
    }

    public setTitle(title: string) {
    	this.title = title;
    }

    public getBrief(): string {
    	return this.brief;
    }

    public setBrief(brief: string) {
    	this.brief = brief;
    }

    public getImgUrls(): Array<string> {
    	return this.imgUrls;
    }

    public setImgUrls(imgUrls: Array<string>) {
    	this.imgUrls = imgUrls;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getProductType(): TopicType {
    	return this.productType;
    }

    public setProductType(productType: TopicType) {
    	this.productType = productType;
    }

    public getProductId(): (number | string) {
    	return this.productId;
    }

    public setProductId(productId: (number | string)) {
    	this.productId = productId;
    }

    public getServiceQuota(): number {
    	return this.serviceQuota;
    }

    public setServiceQuota(serviceQuota: number) {
    	this.serviceQuota = serviceQuota;
    }

    public getDiscount(): number {
    	return this.discount;
    }

    public setDiscount(discount: number) {
    	this.discount = discount;
    }

    public getServiceDeliveryType(): ServiceDeliveryType {
    	return this.serviceDeliveryType;
    }

    public setServiceDeliveryType(serviceDeliveryType: ServiceDeliveryType) {
    	this.serviceDeliveryType = serviceDeliveryType;
    }

    public getServiceUseType(): ServiceUseType {
    	return this.serviceUseType;
    }

    public setServiceUseType(serviceUseType: ServiceUseType) {
    	this.serviceUseType = serviceUseType;
    }

    public getServiceProviderType(): ServiceProviderType {
    	return this.serviceProviderType;
    }

    public setServiceProviderType(serviceProviderType: ServiceProviderType) {
    	this.serviceProviderType = serviceProviderType;
    }

    public getPrice(): number {
    	return this.price;
    }

    public setPrice(price: number) {
    	this.price = price;
    }

    public getOriginalPrice(): number {
    	return this.originalPrice;
    }

    public setOriginalPrice(originalPrice: number) {
    	this.originalPrice = originalPrice;
    }

    public getSettlePrice(): number {
    	return this.settlePrice;
    }

    public setSettlePrice(settlePrice: number) {
    	this.settlePrice = settlePrice;
    }

    public isOtcPriced(): boolean {
    	return this.otcPriced;
    }

    public setOtcPriced(otcPriced: boolean) {
    	this.otcPriced = otcPriced;
    }

    public isPackageOnly(): boolean {
    	return this.packageOnly;
    }

    public setPackageOnly(packageOnly: boolean) {
    	this.packageOnly = packageOnly;
    }

    public getActivityId(): (number | string) {
    	return this.activityId;
    }

    public setActivityId(activityId: (number | string)) {
    	this.activityId = activityId;
    }

    public isOffered(): boolean {
    	return this.offered;
    }

    public setOffered(offered: boolean) {
    	this.offered = offered;
    }

    public getStartDay(): number {
    	return this.startDay;
    }

    public setStartDay(startDay: number) {
    	this.startDay = startDay;
    }

    public getEndDay(): number {
    	return this.endDay;
    }

    public setEndDay(endDay: number) {
    	this.endDay = endDay;
    }

    public getPeriodType(): PeriodType {
    	return this.periodType;
    }

    public setPeriodType(periodType: PeriodType) {
    	this.periodType = periodType;
    }

    public getServicePeriod(): number {
    	return this.servicePeriod;
    }

    public setServicePeriod(servicePeriod: number) {
    	this.servicePeriod = servicePeriod;
    }

    public isTest(): boolean {
    	return this.test;
    }

    public setTest(test: boolean) {
    	this.test = test;
    }

    public isPreSale(): boolean {
    	return this.preSale;
    }

    public setPreSale(preSale: boolean) {
    	this.preSale = preSale;
    }




    public fillFromJson(json: Object, factory?: TypeFactory) {
        let position = json["position"];
        if (position != null) {
            this.setPosition(position);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let serviceTag = json["serviceTag"];
        if (serviceTag != null) {
            let convertedServiceTag0 = EnterpriseServiceCategory[<string>serviceTag];
            this.setServiceTag(convertedServiceTag0);
        }
        let serviceTypeName = json["serviceTypeName"];
        if (serviceTypeName != null) {
            this.setServiceTypeName(serviceTypeName);
        }
        let serviceTypeDisplayName = json["serviceTypeDisplayName"];
        if (serviceTypeDisplayName != null) {
            this.setServiceTypeDisplayName(serviceTypeDisplayName);
        }
        let serviceTagName = json["serviceTagName"];
        if (serviceTagName != null) {
            this.setServiceTagName(serviceTagName);
        }
        let exclusiveServiceProvider = json["exclusiveServiceProvider"];
        if (exclusiveServiceProvider != null) {
            this.setExclusiveServiceProvider(exclusiveServiceProvider);
        }
        let enterpriseServiceId = json["enterpriseServiceId"];
        if (enterpriseServiceId != null) {
            this.setEnterpriseServiceId(enterpriseServiceId);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let brief = json["brief"];
        if (brief != null) {
            this.setBrief(brief);
        }
        let imgUrls = json["imgUrls"];
        if (imgUrls != null) {
            let convertedImgUrls0 = [];
            imgUrls = imgUrls[1];
            for (let i in imgUrls) {
                let convertedImgUrls10 = imgUrls[i];
                convertedImgUrls0.push(convertedImgUrls10);
            }
            this.setImgUrls(convertedImgUrls0);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let productType = json["productType"];
        if (productType != null) {
            let convertedProductType0 = TopicType[<string>productType];
            this.setProductType(convertedProductType0);
        }
        let productId = json["productId"];
        if (productId != null) {
            this.setProductId(productId);
        }
        let serviceQuota = json["serviceQuota"];
        if (serviceQuota != null) {
            this.setServiceQuota(serviceQuota);
        }
        let discount = json["discount"];
        if (discount != null) {
            this.setDiscount(discount);
        }
        let serviceDeliveryType = json["serviceDeliveryType"];
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[<string>serviceDeliveryType];
            this.setServiceDeliveryType(convertedServiceDeliveryType0);
        }
        let serviceUseType = json["serviceUseType"];
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[<string>serviceUseType];
            this.setServiceUseType(convertedServiceUseType0);
        }
        let serviceProviderType = json["serviceProviderType"];
        if (serviceProviderType != null) {
            let convertedServiceProviderType0 = ServiceProviderType[<string>serviceProviderType];
            this.setServiceProviderType(convertedServiceProviderType0);
        }
        let price = json["price"];
        if (price != null) {
            this.setPrice(price);
        }
        let originalPrice = json["originalPrice"];
        if (originalPrice != null) {
            this.setOriginalPrice(originalPrice);
        }
        let settlePrice = json["settlePrice"];
        if (settlePrice != null) {
            this.setSettlePrice(settlePrice);
        }
        let otcPriced = json["otcPriced"];
        if (otcPriced != null) {
            this.setOtcPriced(otcPriced);
        }
        let packageOnly = json["packageOnly"];
        if (packageOnly != null) {
            this.setPackageOnly(packageOnly);
        }
        let activityId = json["activityId"];
        if (activityId != null) {
            this.setActivityId(activityId);
        }
        let offered = json["offered"];
        if (offered != null) {
            this.setOffered(offered);
        }
        let startDay = json["startDay"];
        if (startDay != null) {
            this.setStartDay(startDay);
        }
        let endDay = json["endDay"];
        if (endDay != null) {
            this.setEndDay(endDay);
        }
        let periodType = json["periodType"];
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[<string>periodType];
            this.setPeriodType(convertedPeriodType0);
        }
        let servicePeriod = json["servicePeriod"];
        if (servicePeriod != null) {
            this.setServicePeriod(servicePeriod);
        }
        let test = json["test"];
        if (test != null) {
            this.setTest(test);
        }
        let preSale = json["preSale"];
        if (preSale != null) {
            this.setPreSale(preSale);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let position = this.getPosition();
        if (position != null) {
            json["position"] = position;
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let serviceTag = this.getServiceTag();
        if (serviceTag != null) {
            let convertedServiceTag0 = EnterpriseServiceCategory[serviceTag];
            json["serviceTag"] = convertedServiceTag0;
        }
        let serviceTypeName = this.getServiceTypeName();
        if (serviceTypeName != null) {
            json["serviceTypeName"] = serviceTypeName;
        }
        let serviceTypeDisplayName = this.getServiceTypeDisplayName();
        if (serviceTypeDisplayName != null) {
            json["serviceTypeDisplayName"] = serviceTypeDisplayName;
        }
        let serviceTagName = this.getServiceTagName();
        if (serviceTagName != null) {
            json["serviceTagName"] = serviceTagName;
        }
        let exclusiveServiceProvider = this.getExclusiveServiceProvider();
        if (exclusiveServiceProvider != null) {
            json["exclusiveServiceProvider"] = exclusiveServiceProvider;
        }
        let enterpriseServiceId = this.getEnterpriseServiceId();
        if (enterpriseServiceId != null) {
            json["enterpriseServiceId"] = String(enterpriseServiceId);
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let brief = this.getBrief();
        if (brief != null) {
            json["brief"] = brief;
        }
        let imgUrls = this.getImgUrls();
        if (imgUrls != null) {
            let convertedImgUrls0 = [];
            for (let i in imgUrls) {
                let convertedImgUrls10 = imgUrls[i];
                convertedImgUrls0.push(convertedImgUrls10);
            }
            let convertedImgUrlsWithType1 = ["java.util.ArrayList", convertedImgUrls0];
            json["imgUrls"] = convertedImgUrlsWithType1;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let productType = this.getProductType();
        if (productType != null) {
            let convertedProductType0 = TopicType[productType];
            json["productType"] = convertedProductType0;
        }
        let productId = this.getProductId();
        if (productId != null) {
            json["productId"] = String(productId);
        }
        let serviceQuota = this.getServiceQuota();
        if (serviceQuota != null) {
            json["serviceQuota"] = serviceQuota;
        }
        let discount = this.getDiscount();
        if (discount != null) {
            json["discount"] = discount;
        }
        let serviceDeliveryType = this.getServiceDeliveryType();
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[serviceDeliveryType];
            json["serviceDeliveryType"] = convertedServiceDeliveryType0;
        }
        let serviceUseType = this.getServiceUseType();
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[serviceUseType];
            json["serviceUseType"] = convertedServiceUseType0;
        }
        let serviceProviderType = this.getServiceProviderType();
        if (serviceProviderType != null) {
            let convertedServiceProviderType0 = ServiceProviderType[serviceProviderType];
            json["serviceProviderType"] = convertedServiceProviderType0;
        }
        let price = this.getPrice();
        if (price != null) {
            json["price"] = price;
        }
        let originalPrice = this.getOriginalPrice();
        if (originalPrice != null) {
            json["originalPrice"] = originalPrice;
        }
        let settlePrice = this.getSettlePrice();
        if (settlePrice != null) {
            json["settlePrice"] = settlePrice;
        }
        let otcPriced = this.isOtcPriced();
        if (otcPriced != null) {
            json["otcPriced"] = otcPriced;
        }
        let packageOnly = this.isPackageOnly();
        if (packageOnly != null) {
            json["packageOnly"] = packageOnly;
        }
        let activityId = this.getActivityId();
        if (activityId != null) {
            json["activityId"] = String(activityId);
        }
        let offered = this.isOffered();
        if (offered != null) {
            json["offered"] = offered;
        }
        let startDay = this.getStartDay();
        if (startDay != null) {
            json["startDay"] = startDay;
        }
        let endDay = this.getEndDay();
        if (endDay != null) {
            json["endDay"] = endDay;
        }
        let periodType = this.getPeriodType();
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[periodType];
            json["periodType"] = convertedPeriodType0;
        }
        let servicePeriod = this.getServicePeriod();
        if (servicePeriod != null) {
            json["servicePeriod"] = servicePeriod;
        }
        let test = this.isTest();
        if (test != null) {
            json["test"] = test;
        }
        let preSale = this.isPreSale();
        if (preSale != null) {
            json["preSale"] = preSale;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceTradableKeyInfo";
    }
}
