/**
 * Created by Zhonghua on 16/11/2016.
 */

import { ClientInvocationService } from "./ClientInvocationService";
import { DefaultClientInvocationService } from "./DefaultClientInvocationService";

export class ClientInvocationServiceFactory {
  private static clientInvocationService: ClientInvocationService;

  public static getClientInvocationService(): ClientInvocationService {
    if (ClientInvocationServiceFactory.clientInvocationService == null) {
      ClientInvocationServiceFactory.clientInvocationService =
        new DefaultClientInvocationService();
    }
    return ClientInvocationServiceFactory.clientInvocationService;
  }

  public static reset(): void {
    ClientInvocationServiceFactory.clientInvocationService = null;
  }
}
