/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { Filter } from "../Filter";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Location } from "../../geolocation/Location";
import { TypeFactory } from "../../../serialize/TypeFactory";
import { UserAdStatus } from "../../userad/UserAdStatus";
import { UserAdType } from "../../userad/UserAdType";

export class UserAdFilter extends AbstractSerializable implements Filter {
  location: Location;
  distance: number;
  adType: UserAdType;
  keyword: string;
  shortName: string;
  targetEntityId: number | string;
  adStatus: UserAdStatus;

  constructor() {
    super();
  }

  public getLocation(): Location {
    return this.location;
  }

  public setLocation(location: Location) {
    this.location = location;
  }

  public getDistance(): number {
    return this.distance;
  }

  public setDistance(distance: number) {
    this.distance = distance;
  }

  public getAdType(): UserAdType {
    return this.adType;
  }

  public setAdType(adType: UserAdType) {
    this.adType = adType;
  }

  public getKeyword(): string {
    return this.keyword;
  }

  public setKeyword(keyword: string) {
    this.keyword = keyword;
  }

  public getShortName(): string {
    return this.shortName;
  }

  public setShortName(shortName: string) {
    this.shortName = shortName;
  }

  public getTargetEntityId(): number | string {
    return this.targetEntityId;
  }

  public setTargetEntityId(targetEntityId: number | string) {
    this.targetEntityId = targetEntityId;
  }

  public getAdStatus(): UserAdStatus {
    return this.adStatus;
  }

  public setAdStatus(adStatus: UserAdStatus) {
    this.adStatus = adStatus;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const location = json["location"];
    if (location != null) {
      const convertedLocation0 = new Location();
      convertedLocation0.fillFromJson(location, factory);
      this.setLocation(convertedLocation0);
    }
    const distance = json["distance"];
    if (distance != null) {
      this.setDistance(distance);
    }
    const adType = json["adType"];
    if (adType != null) {
      const convertedAdType0 = UserAdType[<string>adType];
      this.setAdType(convertedAdType0);
    }
    const keyword = json["keyword"];
    if (keyword != null) {
      this.setKeyword(keyword);
    }
    const shortName = json["shortName"];
    if (shortName != null) {
      this.setShortName(shortName);
    }
    const targetEntityId = json["targetEntityId"];
    if (targetEntityId != null) {
      this.setTargetEntityId(targetEntityId);
    }
    const adStatus = json["adStatus"];
    if (adStatus != null) {
      const convertedAdStatus0 = UserAdStatus[<string>adStatus];
      this.setAdStatus(convertedAdStatus0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const location = this.getLocation();
    if (location != null) {
      const convertedLocation0 = location.getJson(JsonTypeTagType.NONE);
      json["location"] = convertedLocation0;
    }
    const distance = this.getDistance();
    if (distance != null) {
      json["distance"] = distance;
    }
    const adType = this.getAdType();
    if (adType != null) {
      const convertedAdType0 = UserAdType[adType];
      json["adType"] = convertedAdType0;
    }
    const keyword = this.getKeyword();
    if (keyword != null) {
      json["keyword"] = keyword;
    }
    const shortName = this.getShortName();
    if (shortName != null) {
      json["shortName"] = shortName;
    }
    const targetEntityId = this.getTargetEntityId();
    if (targetEntityId != null) {
      json["targetEntityId"] = String(targetEntityId);
    }
    const adStatus = this.getAdStatus();
    if (adStatus != null) {
      const convertedAdStatus0 = UserAdStatus[adStatus];
      json["adStatus"] = convertedAdStatus0;
    }

    if (includeTypeTag) {
      json["__type"] = "UserAdFilter";
    }
  }

  public getTypeId(): string {
    return "UserAdFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.userad.UserAdFilter";
  }
}
