/**
 * 自动生成， 请不要手工修改！
 */

export enum ProfileAspectTypeTag {
  NONE,
  PRIVATE,
  ENTITY_OVERVIEW,
  SERVER_STATE,
}
