// @ts-nocheck
import { AbstractGeneralCoworkStats } from "./AbstractGeneralCoworkStats";
import { CoworkSimpleView } from "./CoworkSimpleView";
import { CoworkStats } from "./CoworkStats";

export class GeneralCoworkStats extends AbstractGeneralCoworkStats {
  constructor() {
    super();
  }

  addInfoToView(arg0: CoworkSimpleView | null) {
    // Manual Code Here
  }
  updateCoworkStats(arg0: CoworkStats | null) {
    // Manual Code Here
  }
}
