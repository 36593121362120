/**
 * 自动生成， 请不要手工修改！
 */

import {CoopType} from './CoopType'
import {CoworkInfo} from '../../cowork/CoworkInfo'
import {CoworkSimpleView} from '../../cowork/CoworkSimpleView'
import {CoworkStats} from '../../cowork/CoworkStats'
import {CoworkType} from '../../cowork/CoworkType'
import {EntityInfo} from '../../entity/EntityInfo'
import {GeneralServiceRequestUserAdInfo} from '../general/GeneralServiceRequestUserAdInfo'
import {GeoHash} from '../../geolocation/GeoHash'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {SelectTimes} from '../../cowork/SelectTimes'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractCoopUserAdInfo extends GeneralServiceRequestUserAdInfo  {
    coopType: CoopType;
    agentTypeName: string;
    serviceTypeName: string;
    external: boolean;
    query: boolean;
    totalReplies: number;
    totalMutualReplies: number;

    constructor() {
    	super();
    }

    public getCoopType(): CoopType {
    	return this.coopType;
    }

    public setCoopType(coopType: CoopType) {
    	this.coopType = coopType;
    }

    public getAgentTypeName(): string {
    	return this.agentTypeName;
    }

    public setAgentTypeName(agentTypeName: string) {
    	this.agentTypeName = agentTypeName;
    }

    public getServiceTypeName(): string {
    	return this.serviceTypeName;
    }

    public setServiceTypeName(serviceTypeName: string) {
    	this.serviceTypeName = serviceTypeName;
    }

    public isExternal(): boolean {
    	return this.external;
    }

    public setExternal(external: boolean) {
    	this.external = external;
    }

    public isQuery(): boolean {
    	return this.query;
    }

    public setQuery(query: boolean) {
    	this.query = query;
    }

    public getTotalReplies(): number {
    	return this.totalReplies;
    }

    public setTotalReplies(totalReplies: number) {
    	this.totalReplies = totalReplies;
    }

    public getTotalMutualReplies(): number {
    	return this.totalMutualReplies;
    }

    public setTotalMutualReplies(totalMutualReplies: number) {
    	this.totalMutualReplies = totalMutualReplies;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getKeywords(): Array<string>;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setKeywords(arg0: (Array<string> | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract getDescription(): string;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setDescription(arg0: (string | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));

    abstract isEnterprisePublish(): boolean;

    abstract getDisplayLineTextLeft(): string;

    abstract getDisplayLineTextRight(): string;

    abstract getUserAdId(): (number | string);


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let coopType = json["coopType"];
        if (coopType != null) {
            let convertedCoopType0 = CoopType[<string>coopType];
            this.setCoopType(convertedCoopType0);
        }
        let agentTypeName = json["agentTypeName"];
        if (agentTypeName != null) {
            this.setAgentTypeName(agentTypeName);
        }
        let serviceTypeName = json["serviceTypeName"];
        if (serviceTypeName != null) {
            this.setServiceTypeName(serviceTypeName);
        }
        let external = json["external"];
        if (external != null) {
            this.setExternal(external);
        }
        let query = json["query"];
        if (query != null) {
            this.setQuery(query);
        }
        let totalReplies = json["totalReplies"];
        if (totalReplies != null) {
            this.setTotalReplies(totalReplies);
        }
        let totalMutualReplies = json["totalMutualReplies"];
        if (totalMutualReplies != null) {
            this.setTotalMutualReplies(totalMutualReplies);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let coopType = this.getCoopType();
        if (coopType != null) {
            let convertedCoopType0 = CoopType[coopType];
            json["coopType"] = convertedCoopType0;
        }
        let agentTypeName = this.getAgentTypeName();
        if (agentTypeName != null) {
            json["agentTypeName"] = agentTypeName;
        }
        let serviceTypeName = this.getServiceTypeName();
        if (serviceTypeName != null) {
            json["serviceTypeName"] = serviceTypeName;
        }
        let external = this.isExternal();
        if (external != null) {
            json["external"] = external;
        }
        let query = this.isQuery();
        if (query != null) {
            json["query"] = query;
        }
        let totalReplies = this.getTotalReplies();
        if (totalReplies != null) {
            json["totalReplies"] = totalReplies;
        }
        let totalMutualReplies = this.getTotalMutualReplies();
        if (totalMutualReplies != null) {
            json["totalMutualReplies"] = totalMutualReplies;
        }
    
        if (includeTypeTag) {
            json["__type"] = "CoopUserAdInfo";
        }
    }


    public getTypeId(): string {
    	return "CoopUserAdInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.userad.coop.CoopUserAdInfo";
    }
}
