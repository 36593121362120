// @ts-nocheck
import { AbstractRequest } from "./AbstractRequest";
import { CoworkType } from "../cowork/CoworkType";
import {EntityInfo} from "../entity/EntityInfo";
import {MessageType} from "../../message/MessageContentType";
import {RequestType} from "./RequestType";

export class Request extends AbstractRequest {
  constructor() {
    super();
  }

  /**
   * 必选数据： 请求对象类型
   */
  public withTargetType(targetType: CoworkType): Request {
    this.targetType = targetType;
    return this;
  }

  /**
   * 必选数据： 请求对象ID
   */
  public withTargetId(targetId: number | string): Request {
    this.targetId = targetId;
    return this;
  }

  /**
   * 必选数据： 请求对象所属社区D
   */
  public withTargetEntityInfo(targetEntityInfo: EntityInfo): Request {
    this.targetEntityInfo = targetEntityInfo;
    return this;
  }

  /**
   * 必选数据： 请求者信息
   */
  public withRequestorInfo(requestorInfo: EntityInfo): Request {
    this.requestorInfo = requestorInfo;
    return this;
  }

  /**
   * 必选数据： 请求类型
   */
  public withRequestType(requestType: RequestType): Request {
    this.requestType = requestType;
    return this;
  }
}
