/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractCompositeCoworkInfo } from "../../cowork/AbstractCompositeCoworkInfo";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { QuestionStats } from "./QuestionStats";
import { QuestionUserInfo } from "./QuestionUserInfo";
import { Question } from "./Question";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractCompositeQuestionInfo extends AbstractCompositeCoworkInfo<
  Question,
  QuestionStats,
  QuestionUserInfo,
  AbstractCompositeQuestionInfo
> {
  constructor() {
    super();
  }

  abstract getVisits(): number;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);

    if (includeTypeTag) {
      json["__type"] = "CompositeQuestionInfo";
    }
  }

  public getTypeId(): string {
    return "CompositeQuestionInfo";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.qa.CompositeQuestionInfo";
  }
}
