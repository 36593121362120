/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class EntityVisitStats extends AbstractSerializable {
  entityId: number | string;
  totalInfos: number;
  totalVisits: number;
  totalInteractions: number;
  totalNewInfos: number;
  totalDeleted: number;
  totalBlackListed: number;
  lastReviewedTime: number | string;
  lastReviewedId: number | string;

  constructor() {
    super();
  }

  public getEntityId(): number | string {
    return this.entityId;
  }

  public setEntityId(entityId: number | string) {
    this.entityId = entityId;
  }

  public getTotalInfos(): number {
    return this.totalInfos;
  }

  public setTotalInfos(totalInfos: number) {
    this.totalInfos = totalInfos;
  }

  public getTotalVisits(): number {
    return this.totalVisits;
  }

  public setTotalVisits(totalVisits: number) {
    this.totalVisits = totalVisits;
  }

  public getTotalInteractions(): number {
    return this.totalInteractions;
  }

  public setTotalInteractions(totalInteractions: number) {
    this.totalInteractions = totalInteractions;
  }

  public getTotalNewInfos(): number {
    return this.totalNewInfos;
  }

  public setTotalNewInfos(totalNewInfos: number) {
    this.totalNewInfos = totalNewInfos;
  }

  public getTotalDeleted(): number {
    return this.totalDeleted;
  }

  public setTotalDeleted(totalDeleted: number) {
    this.totalDeleted = totalDeleted;
  }

  public getTotalBlackListed(): number {
    return this.totalBlackListed;
  }

  public setTotalBlackListed(totalBlackListed: number) {
    this.totalBlackListed = totalBlackListed;
  }

  public getLastReviewedTime(): number | string {
    return this.lastReviewedTime;
  }

  public setLastReviewedTime(lastReviewedTime: number | string) {
    this.lastReviewedTime = lastReviewedTime;
  }

  public getLastReviewedId(): number | string {
    return this.lastReviewedId;
  }

  public setLastReviewedId(lastReviewedId: number | string) {
    this.lastReviewedId = lastReviewedId;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const entityId = json["entityId"];
    if (entityId != null) {
      this.setEntityId(entityId);
    }
    const totalInfos = json["totalInfos"];
    if (totalInfos != null) {
      this.setTotalInfos(totalInfos);
    }
    const totalVisits = json["totalVisits"];
    if (totalVisits != null) {
      this.setTotalVisits(totalVisits);
    }
    const totalInteractions = json["totalInteractions"];
    if (totalInteractions != null) {
      this.setTotalInteractions(totalInteractions);
    }
    const totalNewInfos = json["totalNewInfos"];
    if (totalNewInfos != null) {
      this.setTotalNewInfos(totalNewInfos);
    }
    const totalDeleted = json["totalDeleted"];
    if (totalDeleted != null) {
      this.setTotalDeleted(totalDeleted);
    }
    const totalBlackListed = json["totalBlackListed"];
    if (totalBlackListed != null) {
      this.setTotalBlackListed(totalBlackListed);
    }
    const lastReviewedTime = json["lastReviewedTime"];
    if (lastReviewedTime != null) {
      this.setLastReviewedTime(lastReviewedTime);
    }
    const lastReviewedId = json["lastReviewedId"];
    if (lastReviewedId != null) {
      this.setLastReviewedId(lastReviewedId);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const entityId = this.getEntityId();
    if (entityId != null) {
      json["entityId"] = String(entityId);
    }
    const totalInfos = this.getTotalInfos();
    if (totalInfos != null) {
      json["totalInfos"] = totalInfos;
    }
    const totalVisits = this.getTotalVisits();
    if (totalVisits != null) {
      json["totalVisits"] = totalVisits;
    }
    const totalInteractions = this.getTotalInteractions();
    if (totalInteractions != null) {
      json["totalInteractions"] = totalInteractions;
    }
    const totalNewInfos = this.getTotalNewInfos();
    if (totalNewInfos != null) {
      json["totalNewInfos"] = totalNewInfos;
    }
    const totalDeleted = this.getTotalDeleted();
    if (totalDeleted != null) {
      json["totalDeleted"] = totalDeleted;
    }
    const totalBlackListed = this.getTotalBlackListed();
    if (totalBlackListed != null) {
      json["totalBlackListed"] = totalBlackListed;
    }
    const lastReviewedTime = this.getLastReviewedTime();
    if (lastReviewedTime != null) {
      json["lastReviewedTime"] = String(lastReviewedTime);
    }
    const lastReviewedId = this.getLastReviewedId();
    if (lastReviewedId != null) {
      json["lastReviewedId"] = String(lastReviewedId);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.EntityVisitStats";
  }
}
