/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCoworkInfo} from '../cowork/AbstractCoworkInfo'
import {AppealProcessType} from './AppealProcessType'
import {AppealStatus} from './AppealStatus'
import {AppealType} from './AppealType'
import {CoworkInfo} from '../cowork/CoworkInfo'
import {CoworkSimpleView} from '../cowork/CoworkSimpleView'
import {CoworkStats} from '../cowork/CoworkStats'
import {CoworkType} from '../cowork/CoworkType'
import {EntityInfo} from '../entity/EntityInfo'
import {GeoHash} from '../geolocation/GeoHash'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {LabelCount} from './LabelCount'
import {SelectTimes} from '../cowork/SelectTimes'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractAppealInfo extends AbstractCoworkInfo  {
    infoType: AppealType;
    desc: string;
    comments: number;
    status: AppealStatus;
    open: boolean;
    confirmOpen: boolean;
    hot: boolean;
    processType: AppealProcessType;
    parentAppealId: (number | string);
    labelCount: LabelCount;
    pkLabel: string;
    templateUrl: string;

    constructor() {
    	super();
    }

    public getInfoType(): AppealType {
    	return this.infoType;
    }

    public setInfoType(infoType: AppealType) {
    	this.infoType = infoType;
    }

    public getDesc(): string {
    	return this.desc;
    }

    public setDesc(desc: string) {
    	this.desc = desc;
    }

    public getComments(): number {
    	return this.comments;
    }

    public setComments(comments: number) {
    	this.comments = comments;
    }

    public getStatus(): AppealStatus {
    	return this.status;
    }

    public setStatus(status: AppealStatus) {
    	this.status = status;
    }

    public isOpen(): boolean {
    	return this.open;
    }

    public setOpen(open: boolean) {
    	this.open = open;
    }

    public isConfirmOpen(): boolean {
    	return this.confirmOpen;
    }

    public setConfirmOpen(confirmOpen: boolean) {
    	this.confirmOpen = confirmOpen;
    }

    public isHot(): boolean {
    	return this.hot;
    }

    public setHot(hot: boolean) {
    	this.hot = hot;
    }

    public getProcessType(): AppealProcessType {
    	return this.processType;
    }

    public setProcessType(processType: AppealProcessType) {
    	this.processType = processType;
    }

    public getParentAppealId(): (number | string) {
    	return this.parentAppealId;
    }

    public setParentAppealId(parentAppealId: (number | string)) {
    	this.parentAppealId = parentAppealId;
    }

    public getLabelCount(): LabelCount {
    	return this.labelCount;
    }

    public setLabelCount(labelCount: LabelCount) {
    	this.labelCount = labelCount;
    }

    public getPkLabel(): string {
    	return this.pkLabel;
    }

    public setPkLabel(pkLabel: string) {
    	this.pkLabel = pkLabel;
    }

    public getTemplateUrl(): string {
    	return this.templateUrl;
    }

    public setTemplateUrl(templateUrl: string) {
    	this.templateUrl = templateUrl;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getKeywords(): Array<string>;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setKeywords(arg0: (Array<string> | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract getDescription(): string;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setDescription(arg0: (string | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let infoType = json["infoType"];
        if (infoType != null) {
            let convertedInfoType0 = AppealType[<string>infoType];
            this.setInfoType(convertedInfoType0);
        }
        let desc = json["desc"];
        if (desc != null) {
            this.setDesc(desc);
        }
        let comments = json["comments"];
        if (comments != null) {
            this.setComments(comments);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = AppealStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let open = json["open"];
        if (open != null) {
            this.setOpen(open);
        }
        let confirmOpen = json["confirmOpen"];
        if (confirmOpen != null) {
            this.setConfirmOpen(confirmOpen);
        }
        let hot = json["hot"];
        if (hot != null) {
            this.setHot(hot);
        }
        let processType = json["processType"];
        if (processType != null) {
            let convertedProcessType0 = AppealProcessType[<string>processType];
            this.setProcessType(convertedProcessType0);
        }
        let parentAppealId = json["parentAppealId"];
        if (parentAppealId != null) {
            this.setParentAppealId(parentAppealId);
        }
        let labelCount = json["labelCount"];
        if (labelCount != null) {
            let convertedLabelCount0 = new LabelCount();
            convertedLabelCount0.fillFromJson(labelCount, factory);
            this.setLabelCount(convertedLabelCount0);
        }
        let pkLabel = json["pkLabel"];
        if (pkLabel != null) {
            this.setPkLabel(pkLabel);
        }
        let templateUrl = json["templateUrl"];
        if (templateUrl != null) {
            this.setTemplateUrl(templateUrl);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let infoType = this.getInfoType();
        if (infoType != null) {
            let convertedInfoType0 = AppealType[infoType];
            json["infoType"] = convertedInfoType0;
        }
        let desc = this.getDesc();
        if (desc != null) {
            json["desc"] = desc;
        }
        let comments = this.getComments();
        if (comments != null) {
            json["comments"] = comments;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = AppealStatus[status];
            json["status"] = convertedStatus0;
        }
        let open = this.isOpen();
        if (open != null) {
            json["open"] = open;
        }
        let confirmOpen = this.isConfirmOpen();
        if (confirmOpen != null) {
            json["confirmOpen"] = confirmOpen;
        }
        let hot = this.isHot();
        if (hot != null) {
            json["hot"] = hot;
        }
        let processType = this.getProcessType();
        if (processType != null) {
            let convertedProcessType0 = AppealProcessType[processType];
            json["processType"] = convertedProcessType0;
        }
        let parentAppealId = this.getParentAppealId();
        if (parentAppealId != null) {
            json["parentAppealId"] = String(parentAppealId);
        }
        let labelCount = this.getLabelCount();
        if (labelCount != null) {
            let convertedLabelCount0 = labelCount.getJson(JsonTypeTagType.NONE);
            json["labelCount"] = convertedLabelCount0;
        }
        let pkLabel = this.getPkLabel();
        if (pkLabel != null) {
            json["pkLabel"] = pkLabel;
        }
        let templateUrl = this.getTemplateUrl();
        if (templateUrl != null) {
            json["templateUrl"] = templateUrl;
        }
    
        if (includeTypeTag) {
            json["__type"] = "AppealInfo";
        }
    }


    public getTypeId(): string {
    	return "AppealInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.appeal.AppealInfo";
    }
}
