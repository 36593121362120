// @ts-nocheck
import {AbstractCoopUserInfo} from './AbstractCoopUserInfo'
import {CoworkUserInfo} from '../../cowork/CoworkUserInfo'


export class CoopUserInfo extends AbstractCoopUserInfo  {
    constructor() {
    	super();
    }

    isActive(): boolean {
      // Manual Code Here
    }
    isPulled(): boolean {
      // Manual Code Here
    }
    getPrizable(): boolean {
      // Manual Code Here
    }
    getApproverPrize(): number {
      // Manual Code Here
    }
    getCreatorPrize(): number {
      // Manual Code Here
    }
    getRelApproverPrize(): number {
      // Manual Code Here
    }
    getRelCreatorPrize(): number {
      // Manual Code Here
    }
    addApproverPrize(arg0: (number | null)) {
      // Manual Code Here
    }
    addCreatorPrize(arg0: (number | null)) {
      // Manual Code Here
    }
    setActive(active: (boolean | null)) {
      // Manual Code Here
    }
    setApproverPrize(approverPrize: (number | null)) {
      // Manual Code Here
    }
    setCreatorPrize(creatorPrize: (number | null)) {
      // Manual Code Here
    }
    setPrizable(prizable: (boolean | null)) {
      // Manual Code Here
    }
    setPulled() {
      // Manual Code Here
    }
    setRelCreatorPrize(relCreatorPrize: (number | null)) {
      // Manual Code Here
    }
    update(coworkUserInfo: (CoworkUserInfo | null)) {
      // Manual Code Here
    }

}
