import HomeView from "../pages/Layout/HomeView.vue";
import OrderView from "../pages/Layout/OrderView.vue";
import MessageView from "../pages/Layout/MessageView.vue";
import OrderDetail from "../pages/Order/OrderDetail.vue";
import DetailChoose from "../pages/Order/DetailChoose.vue";
import ActiveView from "../pages/Layout/ActiveView.vue";
import InvoiceView from "../pages/Layout/InvoiceView.vue";
import FinanceView from "../pages/Layout/FinanceView.vue";
import SquareView from "../pages/Layout/SquareView.vue";
import EntiyInfoView from "../pages/Layout/EntiyInfoView.vue";
import LawFirmView from "../pages/EntiyInfo/LawFirmView.vue";
import ViewDetails from "../pages/Layout/ViewDetails.vue";
import Talk from "../components/Talk.vue";
import { RouteRecordRaw } from "vue-router";
export const routeMenu: Array<any> = [
  {
    path: "/layout/home",
    name: "home",
    meta: {
      title: "企联联首页",
      // isLink: true,
    },
    component: HomeView,
  },
  {
    path: "/layout/order",
    meta: {
      title: "订单管理",
      // isLink: true
    },
    children: [
      {
        path: "/layout/order",
        name: "order",
        meta: {
          title: "订单管理",
          // isLink: true
        },
        component: OrderView,
      },
      {
        path: "/layout/order/:id",
        name: "orderdetail",
        meta: {
          title: "订单详情",
          // isLink: true
        },
        component: OrderDetail,
      },
      {
        path: "/layout/order/choose/:serviceType",
        name: "orderchoose",
        meta: {
          title: "选择领域",
        },
        component: DetailChoose,
      },
    ],
  },
  {
    path: "/layout/active",
    name: "active",
    meta: {
      title: "商企互动",
    },
    component: ActiveView,
  },
  {
    path: "/layout/entiyInfo",
    name: "entiyInfo",
    meta: {
      title: "信息维护",
    },
    children: [
      {
        path: "/layout/entiyInfo",
        name: "entiyInfo",
        meta: {
          title: "律师信息",
          // isLink: true
        },
        component: EntiyInfoView,
      },
      {
        path: "/layout/lawfirm",
        name: "lawfirm",
        meta: {
          title: "律所信息",
          // isLink: true
        },
        component: LawFirmView,
      },
    ],
  },
  {
    path: "/layout/message",
    name: "message",
    meta: {
      title: "平台消息",
    },
    component: MessageView,
  },
  {
    path: "/layout/invoice",
    name: "invoice",
    meta: {
      title: "发票管理",
    },
    component: InvoiceView,
  },
  {
    path: "/layout/finance",
    name: "finance",
    meta: {
      title: "财务结算",
    },
    component: FinanceView,
  },
  {
    path: "/layout/finance/:id",
    name: "viewdetails",
    meta: {
      title: "查看明细",
    },
    component: ViewDetails,
  },

  {
    path: "/layout/square",
    name: "square",
    meta: {
      title: "服务广场",
    },
    component: SquareView,
  },
  {
    path: "/layout/talk",
    name: "talk",
    meta: {
      title: "聊天室",
      isLink: true,
    },
    component: Talk,
  },
];
export function CheckIslink(linkList) {
  linkList.forEach((r) => {
    routeMenu[r].meta.isLink = true;
    if (routeMenu[r].children) {
      routeMenu[r].children.forEach((j) => {
        j.meta.isLink = true;
      });
    }
  });
  return routeMenu;
}

// function hasAuth(perMission, perMissions) {
//     //判断是否有访问该路由的权限 , 结果是 true/false
//     return perMissions.includes(perMission);
// }

// /*
//     功能: 拿到一个路由, 用户的权限列表, 判断这个路由是否在用户的权限列表中, 返回ture/false
//     返回值: true 表示用户有访问该路由的权限, false表示用户没有访问该路由的权限
//     参数一: route 路由 , 例如: {path: '',meta:{perMission:2},component: Register}
//     参数二: perMissions 用户的权限列表 , 例如:  [1, 11, 2]
// */
// function hasperMission(route, perMissions) {
//     //需要权限才能访问
//     if (route.meta && route.meta.perMission) {
//         //判断是否有访问该路由的权限 , 结果是 true/false
//         return perMissions.includes(route.meta.perMission);
//         //不需要权限就可以访问
//     } else {
//         return true;
//     }
// }

// export const addQllRoute = (menu) => {

//     menu.forEach((r) => {
//         router.addRoute(
//             "layout",
//             {
//                 path: r.path,
//                 name: r.component,
//                 meta: {
//                     title: r.title,
//                     isLink: r.isLink
//                 },
//                 component: () => import('../pages/Layout/' + r.component + 'View.vue')
//             })

//         r.children.forEach(n => {
//             n.islink = r.islink
//         })

//     })
// }
