/**
 * 自动生成， 请不要手工修改！
 */

export enum EntityMessageResponseCode {
  REPEAT_MESSAGE,
  REQ_NOT_FOUND,
  QUEUED,
  ACCEPTED,
  REJECTED,
  CANCELLED,
}
