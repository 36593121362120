/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkUserInfo } from "./CoworkUserInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { PrizableInfo } from "../asset/PrizableInfo";
import { REPrizeStatus } from "../redenvelope/REPrizeStatus";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractGeneralCoworkUserInfo
  extends AbstractSerializable
  implements CoworkUserInfo
{
  awayFromMe: number;
  approve: boolean;
  collected: boolean;
  prizableInfo: PrizableInfo;
  prizeStatus: REPrizeStatus;
  pulled: boolean;
  manager: boolean;

  constructor() {
    super();
  }

  public getAwayFromMe(): number {
    return this.awayFromMe;
  }

  public setAwayFromMe(awayFromMe: number) {
    this.awayFromMe = awayFromMe;
  }

  public getApprove(): boolean {
    return this.approve;
  }

  public setApprove(approve: boolean) {
    this.approve = approve;
  }

  public isCollected(): boolean {
    return this.collected;
  }

  public setCollected(collected: boolean) {
    this.collected = collected;
  }

  public getPrizableInfo(): PrizableInfo {
    return this.prizableInfo;
  }

  public setPrizableInfo(prizableInfo: PrizableInfo) {
    this.prizableInfo = prizableInfo;
  }

  public getPrizeStatus(): REPrizeStatus {
    return this.prizeStatus;
  }

  public setPrizeStatus(prizeStatus: REPrizeStatus) {
    this.prizeStatus = prizeStatus;
  }

  public isPulled(): boolean {
    return this.pulled;
  }

  public setPulled(pulled: boolean) {
    this.pulled = pulled;
  }

  public isManager(): boolean {
    return this.manager;
  }

  public setManager(manager: boolean) {
    this.manager = manager;
  }

  abstract isActive(): boolean;

  abstract getPrizable(): boolean;

  abstract getApproverPrize(): number;

  abstract getCreatorPrize(): number;

  abstract getRelApproverPrize(): number;

  abstract getRelCreatorPrize(): number;

  abstract addApproverPrize(arg0: number | null);

  abstract addCreatorPrize(arg0: number | null);

  abstract setActive(active: boolean | null);

  abstract setApproverPrize(approverPrize: number | null);

  abstract setCreatorPrize(creatorPrize: number | null);

  abstract setPrizable(prizable: boolean | null);

  abstract setRelCreatorPrize(relCreatorPrize: number | null);

  abstract update(coworkUserInfo: CoworkUserInfo | null);

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const awayFromMe = json["awayFromMe"];
    if (awayFromMe != null) {
      this.setAwayFromMe(awayFromMe);
    }
    const approve = json["approve"];
    if (approve != null) {
      this.setApprove(approve);
    }
    const collected = json["collected"];
    if (collected != null) {
      this.setCollected(collected);
    }
    const prizableInfo = json["prizableInfo"];
    if (prizableInfo != null) {
      const convertedPrizableInfo0 = new PrizableInfo();
      convertedPrizableInfo0.fillFromJson(prizableInfo, factory);
      this.setPrizableInfo(convertedPrizableInfo0);
    }
    const prizeStatus = json["prizeStatus"];
    if (prizeStatus != null) {
      const convertedPrizeStatus0 = REPrizeStatus[<string>prizeStatus];
      this.setPrizeStatus(convertedPrizeStatus0);
    }
    const pulled = json["pulled"];
    if (pulled != null) {
      this.setPulled(pulled);
    }
    const manager = json["manager"];
    if (manager != null) {
      this.setManager(manager);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const awayFromMe = this.getAwayFromMe();
    if (awayFromMe != null) {
      json["awayFromMe"] = awayFromMe;
    }
    const approve = this.getApprove();
    if (approve != null) {
      json["approve"] = approve;
    }
    const collected = this.isCollected();
    if (collected != null) {
      json["collected"] = collected;
    }
    const prizableInfo = this.getPrizableInfo();
    if (prizableInfo != null) {
      const convertedPrizableInfo0 = prizableInfo.getJson(JsonTypeTagType.NONE);
      json["prizableInfo"] = convertedPrizableInfo0;
    }
    const prizeStatus = this.getPrizeStatus();
    if (prizeStatus != null) {
      const convertedPrizeStatus0 = REPrizeStatus[prizeStatus];
      json["prizeStatus"] = convertedPrizeStatus0;
    }
    const pulled = this.isPulled();
    if (pulled != null) {
      json["pulled"] = pulled;
    }
    const manager = this.isManager();
    if (manager != null) {
      json["manager"] = manager;
    }

    if (includeTypeTag) {
      json["__type"] = "GeneralCoworkUserInfo";
    }
  }

  public getTypeId(): string {
    return "GeneralCoworkUserInfo";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.GeneralCoworkUserInfo";
  }
}
