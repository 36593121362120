/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {AwardOrder} from '../reply/AwardOrder'
import {CoworkOp} from './CoworkOp'
import {CoworkSimpleView} from './CoworkSimpleView'
import {CoworkViewable} from './CoworkViewable'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../keyword/KeywordEntity'
import {Location} from '../geolocation/Location'
import {MessageType} from '../../message/MessageContentType'
import {QllCountrySpec} from '../international/QllCountrySpec'
import {QllCountry} from '../international/QllCountry'
import {QllLanguageSpec} from '../international/QllLanguageSpec'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'
import { CoworkType } from './CoworkType'


export abstract class AbstractCowork extends AbstractSerializable implements CoworkViewable {
    rid: (number | string);
    coworkOp: CoworkOp;
    title: string;
    initiatorInfo: EntityInfo;
    createTime: (number | string);
    lastUpdateTime: (number | string);
    location: Location;
    locationDisplayName: string;
    description: string;
    targetEntityId: (number | string);
    regionId: (number | string);
    countrySpec: QllCountrySpec;
    languageSpec: QllLanguageSpec;
    targetEntityPath: Array<EntityInfo>;
    imageUrls: Array<string>;
    keywordEntities: Array<KeywordEntity>;
    refEntityInfo: EntityInfo;
    superTargetIds: (number | string)[];
    anonymous: boolean;
    coworkView: CoworkSimpleView;
    prize: number;
    activityId: (number | string);
    activityAwardType: AwardOrder;
    pendingUpdates: boolean;
    official: boolean;
    internal: boolean;
    originalId: (number | string);

    constructor() {
        super();
    }

    getCoworkType(): CoworkType {
        throw new Error('Method not implemented.')
    }
    getKeywords(): string[] {
        throw new Error('Method not implemented.')
    }

    public getRid(): (number | string) {
    	return this.rid;
    }

    public setRid(rid: (number | string)) {
    	this.rid = rid;
    }

    public getCoworkOp(): CoworkOp {
    	return this.coworkOp;
    }

    public setCoworkOp(coworkOp: CoworkOp) {
    	this.coworkOp = coworkOp;
    }

    public getTitle(): string {
    	return this.title;
    }

    public setTitle(title: string) {
    	this.title = title;
    }

    public getInitiatorInfo(): EntityInfo {
    	return this.initiatorInfo;
    }

    public setInitiatorInfo(initiatorInfo: EntityInfo) {
    	this.initiatorInfo = initiatorInfo;
    }

    public getCreateTime(): (number | string) {
    	return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
    	this.createTime = createTime;
    }

    public getLastUpdateTime(): (number | string) {
    	return this.lastUpdateTime;
    }

    public setLastUpdateTime(lastUpdateTime: (number | string)) {
    	this.lastUpdateTime = lastUpdateTime;
    }

    public getLocation(): Location {
    	return this.location;
    }

    public setLocation(location: Location) {
    	this.location = location;
    }

    public getLocationDisplayName(): string {
    	return this.locationDisplayName;
    }

    public setLocationDisplayName(locationDisplayName: string) {
    	this.locationDisplayName = locationDisplayName;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getTargetEntityId(): (number | string) {
    	return this.targetEntityId;
    }

    public setTargetEntityId(targetEntityId: (number | string)) {
    	this.targetEntityId = targetEntityId;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getCountrySpec(): QllCountrySpec {
    	return this.countrySpec;
    }

    public setCountrySpec(countrySpec: QllCountrySpec) {
    	this.countrySpec = countrySpec;
    }

    public getLanguageSpec(): QllLanguageSpec {
    	return this.languageSpec;
    }

    public setLanguageSpec(languageSpec: QllLanguageSpec) {
    	this.languageSpec = languageSpec;
    }

    public getTargetEntityPath(): Array<EntityInfo> {
    	return this.targetEntityPath;
    }

    public setTargetEntityPath(targetEntityPath: Array<EntityInfo>) {
    	this.targetEntityPath = targetEntityPath;
    }

    public getImageUrls(): Array<string> {
    	return this.imageUrls;
    }

    public setImageUrls(imageUrls: Array<string>) {
    	this.imageUrls = imageUrls;
    }

    public getKeywordEntities(): Array<KeywordEntity> {
    	return this.keywordEntities;
    }

    public setKeywordEntities(keywordEntities: Array<KeywordEntity>) {
    	this.keywordEntities = keywordEntities;
    }

    public getRefEntityInfo(): EntityInfo {
    	return this.refEntityInfo;
    }

    public setRefEntityInfo(refEntityInfo: EntityInfo) {
    	this.refEntityInfo = refEntityInfo;
    }

    public getSuperTargetIds(): (number | string)[] {
    	return this.superTargetIds;
    }

    public setSuperTargetIds(superTargetIds: (number | string)[]) {
    	this.superTargetIds = superTargetIds;
    }

    public isAnonymous(): boolean {
    	return this.anonymous;
    }

    public setAnonymous(anonymous: boolean) {
    	this.anonymous = anonymous;
    }

    public getCoworkView(): CoworkSimpleView {
    	return this.coworkView;
    }

    public setCoworkView(coworkView: CoworkSimpleView) {
    	this.coworkView = coworkView;
    }

    public getPrize(): number {
    	return this.prize;
    }

    public setPrize(prize: number) {
    	this.prize = prize;
    }

    public getActivityId(): (number | string) {
    	return this.activityId;
    }

    public setActivityId(activityId: (number | string)) {
    	this.activityId = activityId;
    }

    public getActivityAwardType(): AwardOrder {
    	return this.activityAwardType;
    }

    public setActivityAwardType(activityAwardType: AwardOrder) {
    	this.activityAwardType = activityAwardType;
    }

    public isPendingUpdates(): boolean {
    	return this.pendingUpdates;
    }

    public setPendingUpdates(pendingUpdates: boolean) {
    	this.pendingUpdates = pendingUpdates;
    }

    public isOfficial(): boolean {
    	return this.official;
    }

    public setOfficial(official: boolean) {
    	this.official = official;
    }

    public isInternal(): boolean {
    	return this.internal;
    }

    public setInternal(internal: boolean) {
    	this.internal = internal;
    }

    public getOriginalId(): (number | string) {
    	return this.originalId;
    }

    public setOriginalId(originalId: (number | string)) {
    	this.originalId = originalId;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;

    abstract getTypeId(): string;

    public fillFromJson(json: Object, factory?: TypeFactory) {
        let rid = json["rid"];
        if (rid != null) {
            this.setRid(rid);
        }
        let coworkOp = json["coworkOp"];
        if (coworkOp != null) {
            let convertedCoworkOp0 = CoworkOp[<string>coworkOp];
            this.setCoworkOp(convertedCoworkOp0);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let initiatorInfo = json["initiatorInfo"];
        if (initiatorInfo != null) {
            let convertedInitiatorInfo0 = Serializer.fillFromJsonObjectWithTypeTag(initiatorInfo, "__type", factory);
            this.setInitiatorInfo(convertedInitiatorInfo0);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let lastUpdateTime = json["lastUpdateTime"];
        if (lastUpdateTime != null) {
            this.setLastUpdateTime(lastUpdateTime);
        }
        let location = json["location"];
        if (location != null) {
            let convertedLocation0 = new Location();
            convertedLocation0.fillFromJson(location, factory);
            this.setLocation(convertedLocation0);
        }
        let locationDisplayName = json["locationDisplayName"];
        if (locationDisplayName != null) {
            this.setLocationDisplayName(locationDisplayName);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let targetEntityId = json["targetEntityId"];
        if (targetEntityId != null) {
            this.setTargetEntityId(targetEntityId);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let countrySpec = json["countrySpec"];
        if (countrySpec != null) {
            let convertedCountrySpec0 = new QllCountrySpec();
            convertedCountrySpec0.fillFromJson(countrySpec, factory);
            this.setCountrySpec(convertedCountrySpec0);
        }
        let languageSpec = json["languageSpec"];
        if (languageSpec != null) {
            let convertedLanguageSpec0 = new QllLanguageSpec();
            convertedLanguageSpec0.fillFromJson(languageSpec, factory);
            this.setLanguageSpec(convertedLanguageSpec0);
        }
        let targetEntityPath = json["targetEntityPath"];
        if (targetEntityPath != null) {
            let convertedTargetEntityPath0 = [];
            targetEntityPath = targetEntityPath[1];
            for (let i in targetEntityPath) {
                let convertedTargetEntityPath10 = targetEntityPath[i];
                let convertedTargetEntityPath1 = Serializer.fillFromJsonObjectWithTypeTag(convertedTargetEntityPath10, "__type", factory);
                convertedTargetEntityPath0.push(convertedTargetEntityPath1);
            }
            this.setTargetEntityPath(convertedTargetEntityPath0);
        }
        let imageUrls = json["imageUrls"];
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            imageUrls = imageUrls[1];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            this.setImageUrls(convertedImageUrls0);
        }
        let keywordEntities = json["keywordEntities"];
        if (keywordEntities != null) {
            let convertedKeywordEntities0 = [];
            keywordEntities = keywordEntities[1];
            for (let i in keywordEntities) {
                let convertedKeywordEntities10 = keywordEntities[i];
                let convertedKeywordEntities1 = new KeywordEntity();
                convertedKeywordEntities1.fillFromJson(convertedKeywordEntities10, factory);
                convertedKeywordEntities0.push(convertedKeywordEntities1);
            }
            this.setKeywordEntities(convertedKeywordEntities0);
        }
        let refEntityInfo = json["refEntityInfo"];
        if (refEntityInfo != null) {
            let convertedRefEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(refEntityInfo, "__type", factory);
            this.setRefEntityInfo(convertedRefEntityInfo0);
        }
        let superTargetIds = json["superTargetIds"];
        if (superTargetIds != null) {
            let convertedSuperTargetIds0 = [];
            for (let i in superTargetIds) {
                let convertedSuperTargetIds10 = superTargetIds[i];
                convertedSuperTargetIds0.push(convertedSuperTargetIds10);
            }
            this.setSuperTargetIds(convertedSuperTargetIds0);
        }
        let anonymous = json["anonymous"];
        if (anonymous != null) {
            this.setAnonymous(anonymous);
        }
        let coworkView = json["coworkView"];
        if (coworkView != null) {
            let convertedCoworkView0 = Serializer.fillFromJsonObjectWithTypeTag(coworkView, "__type", factory);
            this.setCoworkView(convertedCoworkView0);
        }
        let prize = json["prize"];
        if (prize != null) {
            this.setPrize(prize);
        }
        let activityId = json["activityId"];
        if (activityId != null) {
            this.setActivityId(activityId);
        }
        let activityAwardType = json["activityAwardType"];
        if (activityAwardType != null) {
            let convertedActivityAwardType0 = AwardOrder[<string>activityAwardType];
            this.setActivityAwardType(convertedActivityAwardType0);
        }
        let pendingUpdates = json["pendingUpdates"];
        if (pendingUpdates != null) {
            this.setPendingUpdates(pendingUpdates);
        }
        let official = json["official"];
        if (official != null) {
            this.setOfficial(official);
        }
        let internal = json["internal"];
        if (internal != null) {
            this.setInternal(internal);
        }
        let originalId = json["originalId"];
        if (originalId != null) {
            this.setOriginalId(originalId);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let rid = this.getRid();
        if (rid != null) {
            json["rid"] = String(rid);
        }
        let coworkOp = this.getCoworkOp();
        if (coworkOp != null) {
            let convertedCoworkOp0 = CoworkOp[coworkOp];
            json["coworkOp"] = convertedCoworkOp0;
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let initiatorInfo = this.getInitiatorInfo();
        if (initiatorInfo != null) {
            let convertedInitiatorInfo0 = initiatorInfo.getJson(JsonTypeTagType.TYPE);
            json["initiatorInfo"] = convertedInitiatorInfo0;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let lastUpdateTime = this.getLastUpdateTime();
        if (lastUpdateTime != null) {
            json["lastUpdateTime"] = String(lastUpdateTime);
        }
        let location = this.getLocation();
        if (location != null) {
            let convertedLocation0 = location.getJson(JsonTypeTagType.NONE);
            json["location"] = convertedLocation0;
        }
        let locationDisplayName = this.getLocationDisplayName();
        if (locationDisplayName != null) {
            json["locationDisplayName"] = locationDisplayName;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let targetEntityId = this.getTargetEntityId();
        if (targetEntityId != null) {
            json["targetEntityId"] = String(targetEntityId);
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let countrySpec = this.getCountrySpec();
        if (countrySpec != null) {
            let convertedCountrySpec0 = countrySpec.getJson(JsonTypeTagType.NONE);
            json["countrySpec"] = convertedCountrySpec0;
        }
        let languageSpec = this.getLanguageSpec();
        if (languageSpec != null) {
            let convertedLanguageSpec0 = languageSpec.getJson(JsonTypeTagType.NONE);
            json["languageSpec"] = convertedLanguageSpec0;
        }
        let targetEntityPath = this.getTargetEntityPath();
        if (targetEntityPath != null) {
            let convertedTargetEntityPath0 = [];
            for (let i in targetEntityPath) {
                let convertedTargetEntityPath10 = targetEntityPath[i];
                let convertedTargetEntityPath1 = convertedTargetEntityPath10.getJson(JsonTypeTagType.TYPE);
                convertedTargetEntityPath0.push(convertedTargetEntityPath1);
            }
            let convertedTargetEntityPathWithType1 = ["java.util.ArrayList", convertedTargetEntityPath0];
            json["targetEntityPath"] = convertedTargetEntityPathWithType1;
        }
        let imageUrls = this.getImageUrls();
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            let convertedImageUrlsWithType1 = ["java.util.ArrayList", convertedImageUrls0];
            json["imageUrls"] = convertedImageUrlsWithType1;
        }
        let keywordEntities = this.getKeywordEntities();
        if (keywordEntities != null) {
            let convertedKeywordEntities0 = [];
            for (let i in keywordEntities) {
                let convertedKeywordEntities10 = keywordEntities[i];
                let convertedKeywordEntities1 = convertedKeywordEntities10.getJson(JsonTypeTagType.NONE);
                convertedKeywordEntities0.push(convertedKeywordEntities1);
            }
            let convertedKeywordEntitiesWithType1 = ["java.util.ArrayList", convertedKeywordEntities0];
            json["keywordEntities"] = convertedKeywordEntitiesWithType1;
        }
        let refEntityInfo = this.getRefEntityInfo();
        if (refEntityInfo != null) {
            let convertedRefEntityInfo0 = refEntityInfo.getJson(JsonTypeTagType.TYPE);
            json["refEntityInfo"] = convertedRefEntityInfo0;
        }
        let superTargetIds = this.getSuperTargetIds();
        if (superTargetIds != null) {
            let convertedSuperTargetIds0 = [];
            for (let i in superTargetIds) {
                let convertedSuperTargetIds10 = superTargetIds[i];
                convertedSuperTargetIds0.push(String(convertedSuperTargetIds10));
            }
            json["superTargetIds"] = convertedSuperTargetIds0;
        }
        let anonymous = this.isAnonymous();
        if (anonymous != null) {
            json["anonymous"] = anonymous;
        }
        let coworkView = this.getCoworkView();
        if (coworkView != null) {
            let convertedCoworkView0 = coworkView.getJson(JsonTypeTagType.TYPE);
            json["coworkView"] = convertedCoworkView0;
        }
        let prize = this.getPrize();
        if (prize != null) {
            json["prize"] = prize;
        }
        let activityId = this.getActivityId();
        if (activityId != null) {
            json["activityId"] = String(activityId);
        }
        let activityAwardType = this.getActivityAwardType();
        if (activityAwardType != null) {
            let convertedActivityAwardType0 = AwardOrder[activityAwardType];
            json["activityAwardType"] = convertedActivityAwardType0;
        }
        let pendingUpdates = this.isPendingUpdates();
        if (pendingUpdates != null) {
            json["pendingUpdates"] = pendingUpdates;
        }
        let official = this.isOfficial();
        if (official != null) {
            json["official"] = official;
        }
        let internal = this.isInternal();
        if (internal != null) {
            json["internal"] = internal;
        }
        let originalId = this.getOriginalId();
        if (originalId != null) {
            json["originalId"] = String(originalId);
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.cowork.AbstractCowork";
    }
}
