/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractClientServiceAdapter } from "../../common/AbstractClientServiceAdapter";
import { BiConsumer } from "@/services/util";
import { Consumer } from "@/services/util";
import { EntityMessageResponse } from "../../message/entity/api/EntityMessageResponse";
import { EntityMessage } from "../../message/entity/api/EntityMessage";
//import {EventHandle} from '../../event/EventHandle'
import { LocalChat } from "../../datatype/geolocation/LocalChat";
import { Location } from "../../datatype/geolocation/Location";
import { MessageQuerySpec } from "../../datatype/query/message/MessageQuerySpec";
import { MessageService } from "../../api/message/MessageService";
import { MultiMessageResponse } from "../../datatype/message/MultiMessageResponse";
import { PageableData } from "../../datatype/paging/PageableData";
import { PageBlockAccessSpec } from "../../datatype/paging/PageBlockAccessSpec";
import { Pair } from "../../util/Pair";
import { PlatformDataContainer } from "../../datatype/platform/PlatformDataContainer";
import { ServiceConsumers } from "@/services/util";
import { ServiceRequest } from "@/services/common";
import { ServiceResponseHandle } from "../../api/datatype/ServiceResponseHandle";
import { SessionContext } from "@/services/session";
import { SyncResponse } from "../../datatype/platform/SyncResponse";
import EventHandle from "@/services/common/EventHandle";
import { EventRequest } from "@/services/common";
import { SimpleEventHandle } from "@/services/common/SimpleEventHandle";

export class MessageServiceProxy
  extends AbstractClientServiceAdapter
  implements MessageService
{
  public constructor() {
    super("com.gong_wei.service.api.message.MessageService");
  }

  public msgSubscribe(
    sessionContext: SessionContext | null,
    message: string | null,
    resultHandler: Consumer<ServiceResponseHandle<string>>
  ): EventHandle {
    const request = EventRequest.createEventRequest(
      "com.gong_wei.service.api.message.MessageService",
      "msgSubscribe",
      [sessionContext, message]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);

    const eventHandle = new SimpleEventHandle(request, () => {
      this.cancelServiceRequest(request, null);
    });
    return eventHandle;
  }

  // public subscribeLocalChats(sessionContext: (SessionContext | null), location: (Location | null), radius: (number | null), resultHandler: Consumer<ServiceResponseHandle<MultiMessageResponse>>): EventHandle<ServiceResponseHandle<MultiMessageResponse>> {
  //     let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.message.MessageService", "subscribeLocalChats", [sessionContext, location, radius]);
  //     let handler = ServiceConsumers.toServiceConsumer(resultHandler);
  //     super.processMessageAsync(request, handler);
  // }

  // @ts-ignore
  public subscribeMessages(
    sessionContext: SessionContext | null,
    location: Location | null,
    radius: number | null,
    pageBlockSpec: PageBlockAccessSpec | null,
    lastSyncPoint: number | string | null,
    resultHandler: Consumer<ServiceResponseHandle<MultiMessageResponse>>
  ): EventHandle {
    const request = EventRequest.createEventRequest(
      "com.gong_wei.service.api.message.MessageService",
      "subscribeMessages",
      [sessionContext, location, radius, pageBlockSpec, lastSyncPoint]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);

    const eventHandle = new SimpleEventHandle(request, () => {
      this.cancelServiceRequest(request, null);
    });
    return eventHandle;
  }

  public addLocalChat(
    sessionContext: SessionContext | null,
    localChat: LocalChat | null,
    location: Location | null,
    resultHandler: BiConsumer<boolean, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "addLocalChat",
      [sessionContext, localChat, location]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public changeSyncLocation(
    sessionContext: SessionContext | null,
    requestId: string | null,
    location: Location | null,
    radius: number | null,
    pageBlockSpec: PageBlockAccessSpec | null,
    resultHandler: BiConsumer<boolean, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "changeSyncLocation",
      [sessionContext, requestId, location, radius, pageBlockSpec]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public deleteMessages(
    sessionContext: SessionContext | null,
    messageIds: Array<number | string> | null,
    resultHandler: BiConsumer<number, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "deleteMessages",
      [sessionContext, messageIds]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getDescendantMessages(
    sessionContext: SessionContext | null,
    rootMessageId: number | string | null,
    querySpec: MessageQuerySpec | null,
    resultHandler: BiConsumer<PageableData<EntityMessage>, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "getDescendantMessages",
      [sessionContext, rootMessageId, querySpec]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public getPrivateChatMessages(
    sessionContext: SessionContext | null,
    rootMessageId: number | string | null,
    conversationParty1: number | string | null,
    conversationParty2: number | string | null,
    querySpec: MessageQuerySpec | null,
    resultHandler: BiConsumer<PageableData<EntityMessage>, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "getPrivateChatMessages",
      [
        sessionContext,
        rootMessageId,
        conversationParty1,
        conversationParty2,
        querySpec,
      ]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public sendMessage(
    sessionContext: SessionContext | null,
    message: EntityMessage | null,
    resultHandler: BiConsumer<PlatformDataContainer<EntityMessageResponse>, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "sendMessage",
      [sessionContext, message]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public sendMessages(
    sessionContext: SessionContext | null,
    messages: Array<EntityMessage> | null,
    resultHandler: BiConsumer<
      PlatformDataContainer<
        Pair<EntityMessageResponse[], Array<Pair<number, string>>>
      >,
      any
    >
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "sendMessages",
      [sessionContext, messages]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public sendPrivateChatMessage(
    sessionContext: SessionContext | null,
    message: EntityMessage | null,
    resultHandler: BiConsumer<PlatformDataContainer<EntityMessageResponse>, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "sendPrivateChatMessage",
      [sessionContext, message]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public setReadMessage(
    sessionContext: SessionContext | null,
    messageId: number | string | null,
    resultHandler: BiConsumer<boolean, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "setReadMessage",
      [sessionContext, messageId]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public setReadMessages(
    sessionContext: SessionContext | null,
    messageIds: Array<number | string> | null,
    resultHandler: BiConsumer<number, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "setReadMessages",
      [sessionContext, messageIds]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  // public subscribeLocalChats(sessionContext: (SessionContext | null), location: (Location | null), radius: (number | null), eventHandler: BiConsumer<EventHandle<ServiceResponseHandle<MultiMessageResponse>>, any>, resultHandler: Consumer<ServiceResponseHandle<MultiMessageResponse>>) {
  //     let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.message.MessageService", "subscribeLocalChats", [sessionContext, location, radius, eventHandler]);
  //     let handler = ServiceConsumers.toServiceConsumer(resultHandler);
  //     super.processMessageAsync(request, handler);
  // }

  public subscribePrivateMessagesWithEventHandler(
    sessionContext: SessionContext | null,
    lastSyncPoint: number | string | null,
    eventHandler: BiConsumer<EventHandle, any>,
    resultHandler: Consumer<ServiceResponseHandle<MultiMessageResponse>>
  ): void {
    const request = EventRequest.createEventRequest(
      "com.gong_wei.service.api.message.MessageService",
      "subscribePrivateMessages",
      [sessionContext, lastSyncPoint]
    );
    request.setWithCallback(true);
    const handler = ServiceConsumers.toBiServiceConsumerForEvent(
      eventHandler,
      resultHandler,
      () => {
        this.cancelServiceRequest(request, null);
      }
    );
    super.processMessageAsync(request, handler);
  }

  public sync(
    sessionContext: SessionContext | null,
    lastSyncPoint: number | string | null,
    resultHandler: BiConsumer<SyncResponse, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.message.MessageService",
      "sync",
      [sessionContext, lastSyncPoint]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }
}
