/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {KeywordItem} from './KeywordItem'
import {ProfileAspectType} from '../../entity/profile/ProfileAspectType'
import {ProfileAspect} from '../../entity/profile/ProfileAspect'
import {TopicType} from '../../entity/topic/TopicType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractExpertiseProfileAspect extends AbstractSerializable implements ProfileAspect {
    entityId: (number | string);
    expertAreas: Array<KeywordItem>;
    productType: TopicType;

    constructor() {
        super();
    }

    public getEntityId(): (number | string) {
        return this.entityId;
    }

    public setEntityId(entityId: (number | string)) {
        this.entityId = entityId;
    }

    public getExpertAreas(): Array<KeywordItem> {
        return this.expertAreas;
    }

    public setExpertAreas(expertAreas: Array<KeywordItem>) {
        this.expertAreas = expertAreas;
    }

    public getTopicType(): TopicType {
        return this.productType;
    }

    public setTopicType(productType: TopicType) {
        this.productType = productType;
    }


    abstract getProfileAspectType(): ProfileAspectType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let entityId = json["entityId"];
        if (entityId != null) {
            this.setEntityId(entityId);
        }
        let expertAreas = json["expertAreas"];
        if (expertAreas != null) {
            let convertedExpertAreas0 = [];
            expertAreas = expertAreas[1];
            for (let i in expertAreas) {
                let convertedExpertAreas10 = expertAreas[i];
                let convertedExpertAreas1 = new KeywordItem();
                convertedExpertAreas1.fillFromJson(convertedExpertAreas10, factory);
                convertedExpertAreas0.push(convertedExpertAreas1);
            }
            this.setExpertAreas(convertedExpertAreas0);
        }
        let productType = json["productType"];
        if (productType != null) {
            let convertedTopicType0 = TopicType[<string>productType];
            this.setTopicType(convertedTopicType0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let entityId = this.getEntityId();
        if (entityId != null) {
            json["entityId"] = String(entityId);
        }
        let expertAreas = this.getExpertAreas();
        if (expertAreas != null) {
            let convertedExpertAreas0 = [];
            for (let i in expertAreas) {
                let convertedExpertAreas10 = expertAreas[i];
                let convertedExpertAreas1 = convertedExpertAreas10.getJson(JsonTypeTagType.NONE);
                convertedExpertAreas0.push(convertedExpertAreas1);
            }
            let convertedExpertAreasWithType1 = ["java.util.ArrayList", convertedExpertAreas0];
            json["expertAreas"] = convertedExpertAreasWithType1;
        }
        let productType = this.getTopicType();
        if (productType != null) {
            let convertedTopicType0 = TopicType[productType];
            json["productType"] = convertedTopicType0;
        }

        if (includeTypeTag) {
            json["__type"] = "ExpertiseProfileAspect";
        }
    }


    public getTypeId(): string {
        return "ExpertiseProfileAspect";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.ipsphere.expertise.ExpertiseProfileAspect";
    }
}
