// @ts-nocheck
import { AbstractGenericEntityMessage } from "./AbstractGenericEntityMessage";
import { MessageContent } from "../../MessageContent";

export class GenericEntityMessage<
  T extends MessageContent
> extends AbstractGenericEntityMessage<T> {
  constructor() {
    super();
  }
}
