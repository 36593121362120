/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkType } from "../cowork/CoworkType";
import { EntityInfo } from "../entity/EntityInfo";
import { GeoHash } from "../geolocation/GeoHash";
import { Importance } from "./Importance";
import {JsonTypeTagType} from "../../serialize/JsonTypeTagType";
import {MessageType} from "../../message/MessageContentType";
import {MessageContent} from "../../message/MessageContent";
import { RequestStatusType } from "./RequestStatusType";
import { RequestType } from "./RequestType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractRequest
  extends AbstractSerializable
  implements MessageContent
{
  requestId: number | string;
  targetType: CoworkType;
  targetId: number | string;
  geohash: GeoHash;
  subTargetType: any;
  targetEntityInfo: EntityInfo;
  requestorInfo: EntityInfo;
  requestTime: number | string;
  title: string;
  requestType: RequestType;
  importance: Importance;
  desc: string;
  status: RequestStatusType;
  resolvedTime: number | string;
  resolveNotes: string;
  resolverInfo: EntityInfo;
  imageUrl: string;
  regionId: number | string;

  constructor() {
    super();
  }

  public getRequestId(): number | string {
    return this.requestId;
  }

  public setRequestId(requestId: number | string) {
    this.requestId = requestId;
  }

  public getTargetType(): CoworkType {
    return this.targetType;
  }

  /**
   * 必选数据： 请求对象类型
   */
  public setTargetType(targetType: CoworkType) {
    this.targetType = targetType;
  }

  public getTargetId(): number | string {
    return this.targetId;
  }

  /**
   * 必选数据： 请求对象ID
   */
  public setTargetId(targetId: number | string) {
    this.targetId = targetId;
  }

  public getGeohash(): GeoHash {
    return this.geohash;
  }

  public setGeohash(geohash: GeoHash) {
    this.geohash = geohash;
  }

  public getSubTargetType(): any {
    return this.subTargetType;
  }

  public setSubTargetType(subTargetType: any) {
    this.subTargetType = subTargetType;
  }

  public getTargetEntityInfo(): EntityInfo {
    return this.targetEntityInfo;
  }

  /**
   * 必选数据： 请求对象所属社区D
   */
  public setTargetEntityInfo(targetEntityInfo: EntityInfo) {
    this.targetEntityInfo = targetEntityInfo;
  }

  public getRequestorInfo(): EntityInfo {
    return this.requestorInfo;
  }

  /**
   * 必选数据： 请求者信息
   */
  public setRequestorInfo(requestorInfo: EntityInfo) {
    this.requestorInfo = requestorInfo;
  }

  public getRequestTime(): number | string {
    return this.requestTime;
  }

  /**
   * 可选数据： 请求时间
   */
  public setRequestTime(requestTime: number | string) {
    this.requestTime = requestTime;
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string) {
    this.title = title;
  }

  public getRequestType(): RequestType {
    return this.requestType;
  }

  /**
   * 必选数据： 请求类型
   */
  public setRequestType(requestType: RequestType) {
    this.requestType = requestType;
  }

  public getImportance(): Importance {
    return this.importance;
  }

  /**
   * 可选数据： 请求重要性
   */
  public setImportance(importance: Importance) {
    this.importance = importance;
  }

  public getDesc(): string {
    return this.desc;
  }

  /**
   * 可选数据： 请求说明
   */
  public setDesc(desc: string) {
    this.desc = desc;
  }

  public getStatus(): RequestStatusType {
    return this.status;
  }

  /**
   * 可选数据： 请求状态
   */
  public setStatus(status: RequestStatusType) {
    this.status = status;
  }

  public getResolvedTime(): number | string {
    return this.resolvedTime;
  }

  /**
   * 可选数据： 请求解决时间
   */
  public setResolvedTime(resolvedTime: number | string) {
    this.resolvedTime = resolvedTime;
  }

  public getResolveNotes(): string {
    return this.resolveNotes;
  }

  /**
   * 可选数据： 请求解决说明
   */
  public setResolveNotes(resolveNotes: string) {
    this.resolveNotes = resolveNotes;
  }

  public getResolverInfo(): EntityInfo {
    return this.resolverInfo;
  }

  /**
   * 可选数据： 请求解决者
   */
  public setResolverInfo(resolverInfo: EntityInfo) {
    this.resolverInfo = resolverInfo;
  }

  public getImageUrl(): string {
    return this.imageUrl;
  }

  /**
   * 可选数据： 图片
   */
  public setImageUrl(imageUrl: string) {
    this.imageUrl = imageUrl;
  }

    public getRegionId(): number | string {
        return this.regionId;
    }

    public setRegionId(regionId: number | string) {
        this.regionId = regionId;
    }

    public getMessageType(): MessageType {
        return MessageType.REQUEST;
    }

    public fillFromJson(json: Object, factory?: TypeFactory) {
        const requestId = json["requestId"];
        if (requestId != null) {
            this.setRequestId(requestId);
        }
        const targetType = json["targetType"];
        if (targetType != null) {
            const convertedTargetType0 = CoworkType[<string>targetType];
      this.setTargetType(convertedTargetType0);
    }
    const targetId = json["targetId"];
    if (targetId != null) {
      this.setTargetId(targetId);
    }
    const geohash = json["geohash"];
    if (geohash != null) {
      const convertedGeohash0 = new GeoHash();
      convertedGeohash0.fillFromJson(geohash, factory);
      this.setGeohash(convertedGeohash0);
    }
    const subTargetType = json["subTargetType"];
    if (subTargetType != null) {
      const convertedSubTargetType0 = Serializer.fromJson(
        subTargetType,
        factory
      );
      this.setSubTargetType(convertedSubTargetType0);
    }
    const targetEntityInfo = json["targetEntityInfo"];
    if (targetEntityInfo != null) {
      const convertedTargetEntityInfo0 =
        Serializer.fillFromJsonObjectWithTypeTag(
          targetEntityInfo,
          "__type",
          factory
        );
      this.setTargetEntityInfo(convertedTargetEntityInfo0);
    }
    const requestorInfo = json["requestorInfo"];
    if (requestorInfo != null) {
      const convertedRequestorInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        requestorInfo,
        "__type",
        factory
      );
      this.setRequestorInfo(convertedRequestorInfo0);
    }
    const requestTime = json["requestTime"];
    if (requestTime != null) {
      this.setRequestTime(requestTime);
    }
    const title = json["title"];
    if (title != null) {
      this.setTitle(title);
    }
    const requestType = json["requestType"];
    if (requestType != null) {
      const convertedRequestType0 = RequestType[<string>requestType];
      this.setRequestType(convertedRequestType0);
    }
    const importance = json["importance"];
    if (importance != null) {
      const convertedImportance0 = Importance[<string>importance];
      this.setImportance(convertedImportance0);
    }
    const desc = json["desc"];
    if (desc != null) {
      this.setDesc(desc);
    }
    const status = json["status"];
    if (status != null) {
      const convertedStatus0 = RequestStatusType[<string>status];
      this.setStatus(convertedStatus0);
    }
    const resolvedTime = json["resolvedTime"];
    if (resolvedTime != null) {
      this.setResolvedTime(resolvedTime);
    }
    const resolveNotes = json["resolveNotes"];
    if (resolveNotes != null) {
      this.setResolveNotes(resolveNotes);
    }
    const resolverInfo = json["resolverInfo"];
    if (resolverInfo != null) {
      const convertedResolverInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        resolverInfo,
        "__type",
        factory
      );
      this.setResolverInfo(convertedResolverInfo0);
    }
    const imageUrl = json["imageUrl"];
    if (imageUrl != null) {
      this.setImageUrl(imageUrl);
    }
    const regionId = json["regionId"];
    if (regionId != null) {
      this.setRegionId(regionId);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const requestId = this.getRequestId();
    if (requestId != null) {
      json["requestId"] = String(requestId);
    }
    const targetType = this.getTargetType();
    if (targetType != null) {
      const convertedTargetType0 = CoworkType[targetType];
      json["targetType"] = convertedTargetType0;
    }
    const targetId = this.getTargetId();
    if (targetId != null) {
      json["targetId"] = String(targetId);
    }
    const geohash = this.getGeohash();
    if (geohash != null) {
      const convertedGeohash0 = geohash.getJson(JsonTypeTagType.NONE);
      json["geohash"] = convertedGeohash0;
    }
    const subTargetType = this.getSubTargetType();
    if (subTargetType != null) {
      const convertedSubTargetType0 = Serializer.getJson(
        subTargetType,
        JsonTypeTagType.CLASS
      );
      json["subTargetType"] = convertedSubTargetType0;
    }
    const targetEntityInfo = this.getTargetEntityInfo();
    if (targetEntityInfo != null) {
      const convertedTargetEntityInfo0 = targetEntityInfo.getJson(
        JsonTypeTagType.TYPE
      );
      json["targetEntityInfo"] = convertedTargetEntityInfo0;
    }
    const requestorInfo = this.getRequestorInfo();
    if (requestorInfo != null) {
      const convertedRequestorInfo0 = requestorInfo.getJson(
        JsonTypeTagType.TYPE
      );
      json["requestorInfo"] = convertedRequestorInfo0;
    }
    const requestTime = this.getRequestTime();
    if (requestTime != null) {
      json["requestTime"] = String(requestTime);
    }
    const title = this.getTitle();
    if (title != null) {
      json["title"] = title;
    }
    const requestType = this.getRequestType();
    if (requestType != null) {
      const convertedRequestType0 = RequestType[requestType];
      json["requestType"] = convertedRequestType0;
    }
    const importance = this.getImportance();
    if (importance != null) {
      const convertedImportance0 = Importance[importance];
      json["importance"] = convertedImportance0;
    }
    const desc = this.getDesc();
    if (desc != null) {
      json["desc"] = desc;
    }
    const status = this.getStatus();
    if (status != null) {
      const convertedStatus0 = RequestStatusType[status];
      json["status"] = convertedStatus0;
    }
    const resolvedTime = this.getResolvedTime();
    if (resolvedTime != null) {
      json["resolvedTime"] = String(resolvedTime);
    }
    const resolveNotes = this.getResolveNotes();
    if (resolveNotes != null) {
      json["resolveNotes"] = resolveNotes;
    }
    const resolverInfo = this.getResolverInfo();
    if (resolverInfo != null) {
      const convertedResolverInfo0 = resolverInfo.getJson(JsonTypeTagType.TYPE);
      json["resolverInfo"] = convertedResolverInfo0;
    }
    const imageUrl = this.getImageUrl();
    if (imageUrl != null) {
      json["imageUrl"] = imageUrl;
    }
    const regionId = this.getRegionId();
    if (regionId != null) {
      json["regionId"] = String(regionId);
    }

    if (includeTypeTag) {
      json["__type"] = "Request";
    }
  }

  public getTypeId(): string {
    return "Request";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.request.Request";
  }
}
