/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractCompositeCoworkInfo } from "../../cowork/AbstractCompositeCoworkInfo";
import { BasicReview } from "./BasicReview";
import { GeneralCoworkStats } from "../../cowork/GeneralCoworkStats";
import { GeneralCoworkUserInfo } from "../../cowork/GeneralCoworkUserInfo";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractCompositeReviewInfo extends AbstractCompositeCoworkInfo<
  BasicReview,
  GeneralCoworkStats,
  GeneralCoworkUserInfo,
  AbstractCompositeReviewInfo
> {
  constructor() {
    super();
  }

  abstract getVisits(): number;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);

    if (includeTypeTag) {
      json["__type"] = "CompositeReviewInfo";
    }
  }

  public getTypeId(): string {
    return "CompositeReviewInfo";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.review.CompositeReviewInfo";
  }
}
