// 此处为跳转订单详情面包屑传递
export default {
  namespaced: true, // 为每个模块添加一个前缀名，保证模块命明不冲突
  state: () => {
    return {
      serviceRequest: {
        serviceType: "",
        tradeId: "",
        tradableId: "",
        keyWord: "",
      },
    };
  },
  mutations: {
    updateServiceType(state, payload) {
      state.serviceRequest.serviceType = payload;
    },
    updateTradeId(state, payload) {
      state.serviceRequest.tradeId = payload;
    },
    updateTradableId(state, payload) {
      state.serviceRequest.tradableId = payload;
    },
    updateKeyWord(state, payload) {
      state.serviceRequest.keyWord = payload;
    },
  },
  actions: {},
};
