/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {AdContent} from '../datatype/business/ad/AdContent'
import {CompositeCoworkView} from '../datatype/cowork/CompositeCoworkView'
import {CoworkIdentifier} from '../datatype/cowork/CoworkIdentifier'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EntityType} from '../datatype/entity/EntityType'
import {FansInfo} from '../datatype/entity/FansInfo'
import {FinancialAccountInfo} from '../datatype/business/payment/FinancialAccountInfo'
import {Location} from '../datatype/geolocation/Location'
import {PageableData} from '../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {PersonalService} from '../api/entity/user/PersonalService'
import {PlatformDataContainer} from '../datatype/platform/PlatformDataContainer'
import {ProductView} from '../datatype/product/ProductView'
import {ReviewOverview} from '../datatype/ipsphere/review/ReviewOverview'
import {UserFavorite} from '../datatype/entity/user/UserFavorite'
import {UserHomePageInfo} from '../datatype/entity/user/UserHomePageInfo'
import {UserInfoTabType} from '../datatype/entity/user/UserInfoTabType'
import {UserLocalCircle} from '../datatype/entity/user/UserLocalCircle'
import {UserServices} from '../datatype/entity/user/UserServices'
import {WebPersonalService} from '../webapi/WebPersonalService'
import {WebSessionManager} from '../common/WebSessionManager'


export class WebPersonalServiceImpl implements WebPersonalService {

    private personalService: PersonalService;

    public constructor() {
        this.personalService = DefaultServiceFactory.getInstance().getPersonalService();
    }

    public addFinancialAccount(financialAccountInfo: (FinancialAccountInfo | null)): Promise<(number | string)> {
        return new Promise((resolve, reject) => {
            this.personalService.addFinancialAccount(WebSessionManager.getSessionContext(),
                financialAccountInfo,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public addMyFavorite(userFavorite: UserFavorite): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.personalService.addMyFavorite(WebSessionManager.getSessionContext(),
                userFavorite,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public addRecommendEntity(adContent: AdContent): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.personalService.addRecommendEntity(WebSessionManager.getSessionContext(),
                adContent,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public deleteFinancialAccount(userId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.personalService.deleteFinancialAccount(WebSessionManager.getSessionContext(),
                userId,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public dislike(subjectId: CoworkIdentifier): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.personalService.dislike(WebSessionManager.getSessionContext(),
                subjectId,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public findCoworkInfosByEntityId(userId: (number | string | null), tabType: (UserInfoTabType | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<CompositeCoworkView<any, any>>> {
        return new Promise((resolve, reject) => {
            this.personalService.findCoworkInfosByEntityId(WebSessionManager.getSessionContext(),
                userId, tabType, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
					}
				});
		});
	}

	public findDynamicInfosByEntityId(entityId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>> {
		return new Promise((resolve, reject) => {
			this.personalService.findDynamicInfosByEntityId(WebSessionManager.getSessionContext(),
				entityId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public findHonoursByEntityId(entityId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PageableData<ReviewOverview>> {
        return new Promise((resolve, reject) => {
            this.personalService.findHonoursByEntityId(WebSessionManager.getSessionContext(),
                entityId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public findInfosToUserByOthers(userId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>> {
        return new Promise((resolve, reject) => {
            this.personalService.findInfosToUserByOthers(WebSessionManager.getSessionContext(),
                userId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
					}
				});
		});
	}

	public findRecentInfosByEntityId(userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<CompositeCoworkView<any, any>>> {
		return new Promise((resolve, reject) => {
			this.personalService.findRecentInfosByEntityId(WebSessionManager.getSessionContext(),
				userId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public findRecommendedReviewsByEntityId(userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PlatformDataContainer<PageableData<ReviewOverview>>> {
        return new Promise((resolve, reject) => {
            this.personalService.findRecommendedReviewsByEntityId(WebSessionManager.getSessionContext(),
                userId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public findSharedInfosByEntityId(userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<CompositeCoworkView<any, any>>> {
        return new Promise((resolve, reject) => {
            this.personalService.findSharedInfosByEntityId(WebSessionManager.getSessionContext(),
                userId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
					}
				});
		});
	}

	public getCollectedCoworks(userId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PageableData<CompositeCoworkView<any, any>>> {
		return new Promise((resolve, reject) => {
			this.personalService.getCollectedCoworks(WebSessionManager.getSessionContext(),
				userId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getFansInfo(userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<FansInfo>> {
        return new Promise((resolve, reject) => {
            this.personalService.getFansInfo(WebSessionManager.getSessionContext(),
                userId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getFinancialAccounts(userId: (number | string | null)): Promise<Array<FinancialAccountInfo>> {
        return new Promise((resolve, reject) => {
            this.personalService.getFinancialAccounts(WebSessionManager.getSessionContext(),
                userId,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getLocalCircle(userId: (number | string | null), size: number): Promise<UserLocalCircle> {
        return new Promise((resolve, reject) => {
            this.personalService.getLocalCircle(WebSessionManager.getSessionContext(),
                userId, size,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getLocalCycleAdContents(): Promise<Array<AdContent>> {
        return new Promise((resolve, reject) => {
            this.personalService.getLocalCycleAdContents(WebSessionManager.getSessionContext(),

                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getMyFansInfo(pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<FansInfo>> {
        return new Promise((resolve, reject) => {
            this.personalService.getMyFansInfo(WebSessionManager.getSessionContext(),
                pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getProducts(userId: (number | string | null), location: (Location | null), distance: (number | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PlatformDataContainer<PageableData<ProductView>>> {
        return new Promise((resolve, reject) => {
            this.personalService.getProducts(WebSessionManager.getSessionContext(),
                userId, location, distance, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getUserFavorites(userId: (number | string | null), entityType: (EntityType | null), pageBlockAccessSpec: (PageBlockAccessSpec | null)): Promise<PageableData<UserFavorite>> {
        return new Promise((resolve, reject) => {
            this.personalService.getUserFavorites(WebSessionManager.getSessionContext(),
                userId, entityType, pageBlockAccessSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getUserHomePageInfo(userId: (number | string | null)): Promise<UserHomePageInfo> {
        return new Promise((resolve, reject) => {
            this.personalService.getUserHomePageInfo(WebSessionManager.getSessionContext(),
                userId,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getUserServices(userId: (number | string | null)): Promise<UserServices> {
        return new Promise((resolve, reject) => {
            this.personalService.getUserServices(WebSessionManager.getSessionContext(),
                userId,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getUserWorks(userId: (number | string | null), pageBlockSpec: PageBlockAccessSpec): Promise<PageableData<CompositeCoworkView<any, any>>> {
        return new Promise((resolve, reject) => {
            this.personalService.getUserWorks(WebSessionManager.getSessionContext(),
                userId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public removeMyFavorite(userFavorite: UserFavorite): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.personalService.removeMyFavorite(WebSessionManager.getSessionContext(),
                userFavorite,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public removeRecommendEntity(adContent: AdContent): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.personalService.removeRecommendEntity(WebSessionManager.getSessionContext(),
                adContent,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public setNoActivePush(userId: (number | string | null), noActivePush: (boolean | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.personalService.setNoActivePush(WebSessionManager.getSessionContext(),
                userId, noActivePush,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public setPrivateChatEnabled(userId: (number | string | null), privateChatOn: (boolean | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.personalService.setPrivateChatEnabled(WebSessionManager.getSessionContext(),
                userId, privateChatOn,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public setPublishEnabled(userId: (number | string | null), publishOn: (boolean | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.personalService.setPublishEnabled(WebSessionManager.getSessionContext(),
                userId, publishOn,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

}
