/**
 * Created by Zhonghua on 16/11/2016.
 */
import config from "@/config/server-config";

const { ip, wssPort, webSocketProtocol } = config;

export class ConnectionConfig {
  public static get DEFAULT_WS_URL(): string {
    return `${webSocketProtocol}://${ip}${wssPort ? `:${wssPort}` : ""}`;
  }

  public static get DEFAULT_HTTP_URL(): string {
    return `http://${ip}:8181/api/`;
  }
}
