/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {PlatformOffer} from '../../datatype/platform/offer/PlatformOffer'
import {Serializer} from '../../serialize/Serializer'
import {ServiceError} from '../../common/ServiceError'
import {ServiceRequestStatusType} from './ServiceRequestStatusType'
import {ServiceResponseHandle} from './ServiceResponseHandle'
import {TypeFactory} from '../../serialize/TypeFactory'


export class GeneralServiceResponseHandle<T> extends AbstractSerializable implements ServiceResponseHandle<T> {
    statusType: ServiceRequestStatusType;
    result: T;
    error: ServiceError;
    workflowId: string;
    platformOffer: PlatformOffer;

    constructor() {
        super();
    }

    public getStatusType(): ServiceRequestStatusType {
        return this.statusType;
    }

    public setStatusType(statusType: ServiceRequestStatusType) {
        this.statusType = statusType;
    }

    public getResult(): T {
        return this.result;
    }

    public setResult(result: T) {
        this.result = result;
    }

    public getError(): ServiceError {
        return this.error;
    }

    public setError(error: ServiceError) {
        this.error = error;
    }

    public getWorkflowId(): string {
        return this.workflowId;
    }

    public setWorkflowId(workflowId: string) {
        this.workflowId = workflowId;
    }

    public getPlatformOffer(): PlatformOffer {
        return this.platformOffer;
    }

    public setPlatformOffer(platformOffer: PlatformOffer) {
        this.platformOffer = platformOffer;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let statusType = json["statusType"];
        if (statusType != null) {
            let convertedStatusType0 = ServiceRequestStatusType[<string>statusType];
            this.setStatusType(convertedStatusType0);
        }
        let result = json["result"];
        if (result != null) {
            let convertedResult0 = Serializer.fromJson(result, factory);
            this.setResult(convertedResult0);
        }
        let error = json["error"];
        if (error != null) {
            let convertedError0 = new ServiceError();
            convertedError0.fillFromJson(error, factory);
            this.setError(convertedError0);
        }
        let workflowId = json["workflowId"];
        if (workflowId != null) {
            this.setWorkflowId(workflowId);
        }
        let platformOffer = json["platformOffer"];
        if (platformOffer != null) {
            let convertedPlatformOffer0 = new PlatformOffer();
            convertedPlatformOffer0.fillFromJson(platformOffer, factory);
            this.setPlatformOffer(convertedPlatformOffer0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let statusType = this.getStatusType();
        if (statusType != null) {
            let convertedStatusType0 = ServiceRequestStatusType[statusType];
            json["statusType"] = convertedStatusType0;
        }
        let result = this.getResult();
        if (result != null) {
            let convertedResult0 = Serializer.getJson(result, JsonTypeTagType.CLASS);
            json["result"] = convertedResult0;
        }
        let error = this.getError();
        if (error != null) {
            let convertedError0 = error.getJson(JsonTypeTagType.NONE);
            json["error"] = convertedError0;
        }
        let workflowId = this.getWorkflowId();
        if (workflowId != null) {
            json["workflowId"] = workflowId;
        }
        let platformOffer = this.getPlatformOffer();
        if (platformOffer != null) {
            let convertedPlatformOffer0 = platformOffer.getJson(JsonTypeTagType.NONE);
            json["platformOffer"] = convertedPlatformOffer0;
        }

        if (includeTypeTag) {
            json["__type"] = "GeneralServiceResponseHandle";
        }
    }


    public getTypeId(): string {
        return "GeneralServiceResponseHandle";
    }

    public getClassName(): string {
        return "com.gong_wei.service.api.datatype.GeneralServiceResponseHandle";
    }
}
