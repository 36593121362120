// @ts-nocheck
import { AbstractSimpleEntityEntitlements } from "./AbstractSimpleEntityEntitlements";
import { RoleType } from "../RoleType";

export class SimpleEntityEntitlements extends AbstractSimpleEntityEntitlements {
  constructor() {
    super();
  }

  hasEnterpriseAssocRole(): boolean {
    return this.repInAssociation || this.indirectAssocMember;
  }

  isInAssocManagement(): boolean {
    return (
      this.assocPresident ||
      this.assocVicePresident ||
      this.assocStandingVP ||
      this.assocSecretaryGen ||
      this.assocSecretary ||
      this.officialRep
    );
  }

  isInAssociation(): boolean {
    return (
      this.isInAssocManagement() ||
      this.assocDirector ||
      this.assocMember ||
      this.indirectAssocMember
    );
  }

  isInGrid(): boolean {
    // Manual Code Here
  }

  isInGridManagementRole(): boolean {
    // Manual Code Here
  }

  isInRole(arg0: RoleType | null): boolean {
    // Manual Code Here
  }

  getRoles(): Array<RoleType> {
    // Manual Code Here
  }
}
