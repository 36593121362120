/**
 * 自动生成， 请不要手工修改！
 */


export enum PaymentStatus {
    PENDING_TO_PAY,
    PAYING,
    PAID,
    FAILED,
    OFFERED,
    CONFIRMED
}
