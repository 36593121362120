/**
 * 自动生成， 请不要手工修改！
 */

import { AdditionalEntityAttributes } from "./AdditionalEntityAttributes";
import {JsonTypeTagType} from "../../serialize/JsonTypeTagType";
import {MessageType} from "../../message/MessageContentType";
import {Serializer} from "../../serialize/Serializer";
import { SimpleEntityDesc } from "./SimpleEntityDesc";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractUserSuppliedEntityDesc extends SimpleEntityDesc {
  additionalAttributes: AdditionalEntityAttributes;

  constructor() {
    super();
  }

  public getCityName(): string {
    return this.cityName;
  }

  public setCityName(cityName: string) {
    this.cityName = cityName;
  }

  public getAdditionalAttributes(): AdditionalEntityAttributes {
    return this.additionalAttributes;
  }

  public setAdditionalAttributes(
    additionalAttributes: AdditionalEntityAttributes
  ) {
    this.additionalAttributes = additionalAttributes;
  }

    abstract getMessageType(): MessageType;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const cityName = json["cityName"];
    if (cityName != null) {
      this.setCityName(cityName);
    }
    const additionalAttributes = json["additionalAttributes"];
    if (additionalAttributes != null) {
      const convertedAdditionalAttributes0 =
        Serializer.fillFromJsonObjectWithTypeTag(
          additionalAttributes,
          "__type",
          factory
        );
      this.setAdditionalAttributes(convertedAdditionalAttributes0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const cityName = this.getCityName();
    if (cityName != null) {
      json["cityName"] = cityName;
    }
    const additionalAttributes = this.getAdditionalAttributes();
    if (additionalAttributes != null) {
      const convertedAdditionalAttributes0 = additionalAttributes.getJson(
        JsonTypeTagType.TYPE
      );
      json["additionalAttributes"] = convertedAdditionalAttributes0;
    }

    if (includeTypeTag) {
      json["__type"] = "UserSuppliedEntityDesc";
    }
  }

  public getTypeId(): string {
    return "UserSuppliedEntityDesc";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.UserSuppliedEntityDesc";
  }
}
