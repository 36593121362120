/**
 * 自动生成， 请不要手工修改！
 */

export enum AnswerStatus {
  IN_REVIEW,
  BE_SUMMARY,
  COMPLETED,
  TO_COMPLETE,
  REJECTED,
  CANCELLED,
  HIDDEN,
  ACTIVE,
}
