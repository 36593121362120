/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class GridStats extends AbstractSerializable {
  totalOfficers: number;
  totalCitizens: number;
  totalMigrants: number;
  totalHouses: number;
  totalRented: number;
  totalOccupied: number;
  totalAppeals: number;
  totalUnprocessedAppeals: number;
  totalReports: number;
  totalUnprocessedReports: number;

  constructor() {
    super();
  }

  public getTotalOfficers(): number {
    return this.totalOfficers;
  }

  public setTotalOfficers(totalOfficers: number) {
    this.totalOfficers = totalOfficers;
  }

  public getTotalCitizens(): number {
    return this.totalCitizens;
  }

  public setTotalCitizens(totalCitizens: number) {
    this.totalCitizens = totalCitizens;
  }

  public getTotalMigrants(): number {
    return this.totalMigrants;
  }

  public setTotalMigrants(totalMigrants: number) {
    this.totalMigrants = totalMigrants;
  }

  public getTotalHouses(): number {
    return this.totalHouses;
  }

  public setTotalHouses(totalHouses: number) {
    this.totalHouses = totalHouses;
  }

  public getTotalRented(): number {
    return this.totalRented;
  }

  public setTotalRented(totalRented: number) {
    this.totalRented = totalRented;
  }

  public getTotalOccupied(): number {
    return this.totalOccupied;
  }

  public setTotalOccupied(totalOccupied: number) {
    this.totalOccupied = totalOccupied;
  }

  public getTotalAppeals(): number {
    return this.totalAppeals;
  }

  public setTotalAppeals(totalAppeals: number) {
    this.totalAppeals = totalAppeals;
  }

  public getTotalUnprocessedAppeals(): number {
    return this.totalUnprocessedAppeals;
  }

  public setTotalUnprocessedAppeals(totalUnprocessedAppeals: number) {
    this.totalUnprocessedAppeals = totalUnprocessedAppeals;
  }

  public getTotalReports(): number {
    return this.totalReports;
  }

  public setTotalReports(totalReports: number) {
    this.totalReports = totalReports;
  }

  public getTotalUnprocessedReports(): number {
    return this.totalUnprocessedReports;
  }

  public setTotalUnprocessedReports(totalUnprocessedReports: number) {
    this.totalUnprocessedReports = totalUnprocessedReports;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const totalOfficers = json["totalOfficers"];
    if (totalOfficers != null) {
      this.setTotalOfficers(totalOfficers);
    }
    const totalCitizens = json["totalCitizens"];
    if (totalCitizens != null) {
      this.setTotalCitizens(totalCitizens);
    }
    const totalMigrants = json["totalMigrants"];
    if (totalMigrants != null) {
      this.setTotalMigrants(totalMigrants);
    }
    const totalHouses = json["totalHouses"];
    if (totalHouses != null) {
      this.setTotalHouses(totalHouses);
    }
    const totalRented = json["totalRented"];
    if (totalRented != null) {
      this.setTotalRented(totalRented);
    }
    const totalOccupied = json["totalOccupied"];
    if (totalOccupied != null) {
      this.setTotalOccupied(totalOccupied);
    }
    const totalAppeals = json["totalAppeals"];
    if (totalAppeals != null) {
      this.setTotalAppeals(totalAppeals);
    }
    const totalUnprocessedAppeals = json["totalUnprocessedAppeals"];
    if (totalUnprocessedAppeals != null) {
      this.setTotalUnprocessedAppeals(totalUnprocessedAppeals);
    }
    const totalReports = json["totalReports"];
    if (totalReports != null) {
      this.setTotalReports(totalReports);
    }
    const totalUnprocessedReports = json["totalUnprocessedReports"];
    if (totalUnprocessedReports != null) {
      this.setTotalUnprocessedReports(totalUnprocessedReports);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const totalOfficers = this.getTotalOfficers();
    if (totalOfficers != null) {
      json["totalOfficers"] = totalOfficers;
    }
    const totalCitizens = this.getTotalCitizens();
    if (totalCitizens != null) {
      json["totalCitizens"] = totalCitizens;
    }
    const totalMigrants = this.getTotalMigrants();
    if (totalMigrants != null) {
      json["totalMigrants"] = totalMigrants;
    }
    const totalHouses = this.getTotalHouses();
    if (totalHouses != null) {
      json["totalHouses"] = totalHouses;
    }
    const totalRented = this.getTotalRented();
    if (totalRented != null) {
      json["totalRented"] = totalRented;
    }
    const totalOccupied = this.getTotalOccupied();
    if (totalOccupied != null) {
      json["totalOccupied"] = totalOccupied;
    }
    const totalAppeals = this.getTotalAppeals();
    if (totalAppeals != null) {
      json["totalAppeals"] = totalAppeals;
    }
    const totalUnprocessedAppeals = this.getTotalUnprocessedAppeals();
    if (totalUnprocessedAppeals != null) {
      json["totalUnprocessedAppeals"] = totalUnprocessedAppeals;
    }
    const totalReports = this.getTotalReports();
    if (totalReports != null) {
      json["totalReports"] = totalReports;
    }
    const totalUnprocessedReports = this.getTotalUnprocessedReports();
    if (totalUnprocessedReports != null) {
      json["totalUnprocessedReports"] = totalUnprocessedReports;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.enterprise.GridStats";
  }
}
