/**
 * 上传！
 */
import {
  OSSCredentialProvider,
  OssTokenConsumer,
} from "@/services/util/oss/OSSCredentialProvider";
import { OSSFederationToken } from "@/services/util/oss/OSSFederationToken";
import OSS from "ali-oss";
import { nanoid } from "nanoid";
import CheckType from "./CheckType";
import talk from "@/api/talk";
import {ElMessage} from "element-plus";
export default class UploadUtils {
  static getFile(
    e: any,
    targetId: string,
    correlationId: string,
    serviceRequestId: number
  ): void {
    const fileTarget: any = e.target;
    const tokenConsumer: OssTokenConsumer = async (
      token: OSSFederationToken
    ): Promise<void> => {
      if (token) {
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: token.tempAK,
          accessKeySecret: token.tempSK,
          stsToken: token.securityToken,
          bucket: "gong-wei-private",
        });
        ElMessage({
          type: "success",
          message: "上传中,请耐心等待！",
        });
        for (const files of fileTarget.files) {
          const fullFileName: string =
            token.pkgName + "/" + nanoid(20) + "/" + files.name;
          const result = await client.put(fullFileName, files);
          await talk.uploadFiles(
            targetId,
            correlationId,
            serviceRequestId,
            files.name,
            result.url,
            files.size,
            CheckType.checkFileType(result.url)
          );
        }
        (document.getElementsByClassName("file")[0] as HTMLInputElement).value =
          null;
      }
    };
    OSSCredentialProvider.getInstance().getFederationToken(tokenConsumer);
  }
}
