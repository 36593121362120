/**
 * 自动生成， 请不要手工修改！
 */

import { CoworkUserInfo } from "../../cowork/CoworkUserInfo";
import { GeneralCoworkUserInfo } from "../../cowork/GeneralCoworkUserInfo";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractQuestionUserInfo extends GeneralCoworkUserInfo {
  subscribed: boolean;
  answered: boolean;
  sponsored: boolean;

  constructor() {
    super();
  }

  public isSubscribed(): boolean {
    return this.subscribed;
  }

  public setSubscribed(subscribed: boolean) {
    this.subscribed = subscribed;
  }

  public isAnswered(): boolean {
    return this.answered;
  }

  public setAnswered(answered: boolean) {
    this.answered = answered;
  }

  public isSponsored(): boolean {
    return this.sponsored;
  }

  public setSponsored(sponsored: boolean) {
    this.sponsored = sponsored;
  }

  abstract isActive(): boolean;

  abstract isPulled(): boolean;

  abstract getPrizable(): boolean;

  abstract getApproverPrize(): number;

  abstract getCreatorPrize(): number;

  abstract getRelApproverPrize(): number;

  abstract getRelCreatorPrize(): number;

  abstract addApproverPrize(arg0: number | null);

  abstract addCreatorPrize(arg0: number | null);

  abstract setActive(active: boolean | null);

  abstract setApproverPrize(approverPrize: number | null);

  abstract setCreatorPrize(creatorPrize: number | null);

  abstract setPrizable(prizable: boolean | null);

  abstract setPulled();

  abstract setRelCreatorPrize(relCreatorPrize: number | null);

  abstract update(coworkUserInfo: CoworkUserInfo | null);

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const subscribed = json["subscribed"];
    if (subscribed != null) {
      this.setSubscribed(subscribed);
    }
    const answered = json["answered"];
    if (answered != null) {
      this.setAnswered(answered);
    }
    const sponsored = json["sponsored"];
    if (sponsored != null) {
      this.setSponsored(sponsored);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const subscribed = this.isSubscribed();
    if (subscribed != null) {
      json["subscribed"] = subscribed;
    }
    const answered = this.isAnswered();
    if (answered != null) {
      json["answered"] = answered;
    }
    const sponsored = this.isSponsored();
    if (sponsored != null) {
      json["sponsored"] = sponsored;
    }

    if (includeTypeTag) {
      json["__type"] = "QuestionUserInfo";
    }
  }

  public getTypeId(): string {
    return "QuestionUserInfo";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.qa.QuestionUserInfo";
  }
}
