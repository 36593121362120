/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractClientServiceAdapter } from "../../common/AbstractClientServiceAdapter";
import { BiConsumer } from "../../util/BiConsumer";
import { Consumer } from "../../util/Consumer";
import { OSSTokenResponse } from "../../response/OSSTokenResponse";
import { ServiceConsumers } from "../../util/ServiceConsumers";
import { ServiceRequest } from "../../common/ServiceRequest";
import { ServiceResponseHandle } from "../../api/datatype/ServiceResponseHandle";
import { SessionContext } from "../../session/SessionContext";
import { SystemService } from "../../api/system/SystemService";
import { SystemSuppliedEntityInfo } from "../../datatype/entity/SystemSuppliedEntityInfo";

export class SystemServiceProxy
  extends AbstractClientServiceAdapter
  implements SystemService
{
  public constructor() {
    super("com.gong_wei.service.api.system.SystemService");
  }

  public logAppException(
    sessionContext: SessionContext | null,
    mobileName: string | null,
    mobileVersion: string | null,
    stackTrace: string | null,
    resultHandler: BiConsumer<boolean, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.system.SystemService",
      "logAppException",
      [sessionContext, mobileName, mobileVersion, stackTrace]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public login(
    sessionContext: SessionContext | null,
    mobile: string | null,
    password: string | null,
    lastSyncTime: number | string | null,
    resultHandler: BiConsumer<SystemSuppliedEntityInfo, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.system.SystemService",
      "login",
      [sessionContext, mobile, password, lastSyncTime]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public loginAsAdmin(
    sessionContext: SessionContext | null,
    mobile: string | null,
    password: string | null,
    resultHandler: BiConsumer<SystemSuppliedEntityInfo, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.system.SystemService",
      "loginAsAdmin",
      [sessionContext, mobile, password]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public logout(
    sessionContext: SessionContext | null,
    resultHandler: Consumer<ServiceResponseHandle<boolean>>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.system.SystemService",
      "logout",
      [sessionContext]
    );
    const handler = ServiceConsumers.toServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public requestOSSToken(
    sessionContext: SessionContext | null,
    resultHandler: BiConsumer<OSSTokenResponse, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.system.SystemService",
      "requestOSSToken",
      [sessionContext]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }

  public resetAccountPassword(
    sessionContext: SessionContext | null,
    newPassword: string | null,
    resultHandler: BiConsumer<boolean, any>
  ) {
    const request = ServiceRequest.createServiceRequest(
      "com.gong_wei.service.api.system.SystemService",
      "resetAccountPassword",
      [sessionContext, newPassword]
    );
    const handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
    super.processMessageAsync(request, handler);
  }
}
