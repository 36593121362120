/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../serialize/JsonTypeTagType";
import { TypeFactory } from "../serialize/TypeFactory";

export class OSSTokenResponse extends AbstractSerializable {
  accessKeyId: string;
  accessKeySecret: string;
  securityToken: string;
  expiration: string;
  packageName: string;

  constructor() {
    super();
  }

  public getAccessKeyId(): string {
    return this.accessKeyId;
  }

  public setAccessKeyId(accessKeyId: string) {
    this.accessKeyId = accessKeyId;
  }

  public getAccessKeySecret(): string {
    return this.accessKeySecret;
  }

  public setAccessKeySecret(accessKeySecret: string) {
    this.accessKeySecret = accessKeySecret;
  }

  public getSecurityToken(): string {
    return this.securityToken;
  }

  public setSecurityToken(securityToken: string) {
    this.securityToken = securityToken;
  }

  public getExpiration(): string {
    return this.expiration;
  }

  public setExpiration(expiration: string) {
    this.expiration = expiration;
  }

  public getPackageName(): string {
    return this.packageName;
  }

  public setPackageName(packageName: string) {
    this.packageName = packageName;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const accessKeyId = json["accessKeyId"];
    if (accessKeyId != null) {
      this.setAccessKeyId(accessKeyId);
    }
    const accessKeySecret = json["accessKeySecret"];
    if (accessKeySecret != null) {
      this.setAccessKeySecret(accessKeySecret);
    }
    const securityToken = json["securityToken"];
    if (securityToken != null) {
      this.setSecurityToken(securityToken);
    }
    const expiration = json["expiration"];
    if (expiration != null) {
      this.setExpiration(expiration);
    }
    const packageName = json["packageName"];
    if (packageName != null) {
      this.setPackageName(packageName);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const accessKeyId = this.getAccessKeyId();
    if (accessKeyId != null) {
      json["accessKeyId"] = accessKeyId;
    }
    const accessKeySecret = this.getAccessKeySecret();
    if (accessKeySecret != null) {
      json["accessKeySecret"] = accessKeySecret;
    }
    const securityToken = this.getSecurityToken();
    if (securityToken != null) {
      json["securityToken"] = securityToken;
    }
    const expiration = this.getExpiration();
    if (expiration != null) {
      json["expiration"] = expiration;
    }
    const packageName = this.getPackageName();
    if (packageName != null) {
      json["packageName"] = packageName;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.response.OSSTokenResponse";
  }
}
