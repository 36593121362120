/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkUserInfo } from "./CoworkUserInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class CompositeInfoView<
  S,
  T extends CoworkUserInfo
> extends AbstractSerializable {
  viewInfo: S;
  userInfo: T;

  constructor() {
    super();
  }

  public getViewInfo(): S {
    return this.viewInfo;
  }

  public setViewInfo(viewInfo: S) {
    this.viewInfo = viewInfo;
  }

  public getUserInfo(): T {
    return this.userInfo;
  }

  public setUserInfo(userInfo: T) {
    this.userInfo = userInfo;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const viewInfo = json["viewInfo"];
    if (viewInfo != null) {
      const convertedViewInfo0 = Serializer.fromJson(viewInfo, factory);
      this.setViewInfo(convertedViewInfo0);
    }
    const userInfo = json["userInfo"];
    if (userInfo != null) {
      const convertedUserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        userInfo,
        "__type",
        factory
      );
      this.setUserInfo(convertedUserInfo0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const viewInfo = this.getViewInfo();
    if (viewInfo != null) {
      const convertedViewInfo0 = Serializer.getJson(
        viewInfo,
        JsonTypeTagType.CLASS
      );
      json["viewInfo"] = convertedViewInfo0;
    }
    const userInfo = this.getUserInfo();
    if (userInfo != null) {
      const convertedUserInfo0 = userInfo.getJson(JsonTypeTagType.TYPE);
      json["userInfo"] = convertedUserInfo0;
    }

    if (includeTypeTag) {
      json["__type"] = "CompositeInfoView";
    }
  }

  public getTypeId(): string {
    return "CompositeInfoView";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.CompositeInfoView";
  }
}
