/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {ProfileAspectType} from './ProfileAspectType'
import {ProfileAspect} from './ProfileAspect'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractSecurityProfileAspect extends AbstractSerializable implements ProfileAspect  {
    oldPassword: string;
    password: string;
    mobile: string;
    nid: (number | string);
    openId: string;
    deviceNumber: string;
    appType: string;

    constructor() {
    	super();
    }

    public getOldPassword(): string {
    	return this.oldPassword;
    }

    public setOldPassword(oldPassword: string) {
    	this.oldPassword = oldPassword;
    }

    public getPassword(): string {
    	return this.password;
    }

    public setPassword(password: string) {
    	this.password = password;
    }

    public getMobile(): string {
    	return this.mobile;
    }

    public setMobile(mobile: string) {
    	this.mobile = mobile;
    }

    public getNid(): (number | string) {
    	return this.nid;
    }

    public setNid(nid: (number | string)) {
    	this.nid = nid;
    }

    public getOpenId(): string {
    	return this.openId;
    }

    public setOpenId(openId: string) {
    	this.openId = openId;
    }

    public getDeviceNumber(): string {
    	return this.deviceNumber;
    }

    public setDeviceNumber(deviceNumber: string) {
    	this.deviceNumber = deviceNumber;
    }

    public getAppType(): string {
    	return this.appType;
    }

    public setAppType(appType: string) {
    	this.appType = appType;
    }


    abstract getProfileAspectType(): ProfileAspectType;

    abstract getEntityId(): (number | string);

    abstract setEntityId(arg0: (number | string | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let oldPassword = json["oldPassword"];
        if (oldPassword != null) {
            this.setOldPassword(oldPassword);
        }
        let password = json["password"];
        if (password != null) {
            this.setPassword(password);
        }
        let mobile = json["mobile"];
        if (mobile != null) {
            this.setMobile(mobile);
        }
        let nid = json["nid"];
        if (nid != null) {
            this.setNid(nid);
        }
        let openId = json["openId"];
        if (openId != null) {
            this.setOpenId(openId);
        }
        let deviceNumber = json["deviceNumber"];
        if (deviceNumber != null) {
            this.setDeviceNumber(deviceNumber);
        }
        let appType = json["appType"];
        if (appType != null) {
            this.setAppType(appType);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let oldPassword = this.getOldPassword();
        if (oldPassword != null) {
            json["oldPassword"] = oldPassword;
        }
        let password = this.getPassword();
        if (password != null) {
            json["password"] = password;
        }
        let mobile = this.getMobile();
        if (mobile != null) {
            json["mobile"] = mobile;
        }
        let nid = this.getNid();
        if (nid != null) {
            json["nid"] = String(nid);
        }
        let openId = this.getOpenId();
        if (openId != null) {
            json["openId"] = openId;
        }
        let deviceNumber = this.getDeviceNumber();
        if (deviceNumber != null) {
            json["deviceNumber"] = deviceNumber;
        }
        let appType = this.getAppType();
        if (appType != null) {
            json["appType"] = appType;
        }
    
        if (includeTypeTag) {
            json["__type"] = "PlainSecurityProfileAspect";
        }
    }


    public getTypeId(): string {
    	return "PlainSecurityProfileAspect";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.profile.SecurityProfileAspect";
    }
}
