// @ts-nocheck
import {AbstractTopicDetails} from './AbstractTopicDetails'
import {AdditionalEntityAttributes} from '../AdditionalEntityAttributes'
import {EntityType} from '../EntityType'


export class TopicDetails extends AbstractTopicDetails {
    constructor() {
        super();
    }

    copy(): AdditionalEntityAttributes {
        // Manual Code Here
    }

    getEntityType(): EntityType {
        // Manual Code Here
    }

    addAdditionalEntityAttributes(arg0: (AdditionalEntityAttributes | null)) {
        // Manual Code Here
    }

}
