import { DefaultWebServiceFactory } from "@/services/webapi/DefaultWebServiceFactory";
import UserTools from "@/services/session/UserTools";
import {EnterpriseServiceType} from "@/services/datatype/business/service/EnterpriseServiceType";
import {AssociatedEntityInfo} from "@/services/datatype/entity/AssociatedEntityInfo";
//获取订单信息
const getOrder = (rid: string | number) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceRequestService()
    .getEnterpriseServiceRequestById(rid);
//结束订单
const endOrder = (rid: number) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceRequestService()
    .completeServiceRequest(rid);
//接受服务
const acceptOrder = (rid: number) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceRequestService()
    .acceptByServiceProvider(rid);
//拒绝服务
const refuseOrder = (rid: number) =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceRequestService()
    .rejectByServiceProvider(rid);
//获取律师列表
const getLawyer = (
  entityId: string | number,
  serviceType: EnterpriseServiceType
): Promise<AssociatedEntityInfo[]> =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceRequestService()
    .getServiceProfessionalsByEnterpriseId(entityId, serviceType);

//立即分配
const delegateToAssistant = (
  rid: string | number,
  entityId: number
): Promise<boolean> =>
  DefaultWebServiceFactory.getInstance()
    .getWebEnterpriseServiceRequestService()
    .delegateToAssistant(rid, entityId);

export default {
  getOrder,
  endOrder,
  acceptOrder,
  refuseOrder,
  getLawyer,
  delegateToAssistant,
};
