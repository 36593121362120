/**
 * Created by Zhonghua on 15/11/2016.
 */

import { WebSocketConnection } from "../connection/WebSocketConnection";
import ConnectionListener from "@/services/connection/ConnectionListener";
import { Message } from "../message/Message";
import { Serializer } from "../serialize/Serializer";
import { TypeMapper } from "../serialize/TypeMapper";
import { IKeyedCollection } from "../util/IKeyedCollection";
import { KeyedCollection } from "../util/KeyedCollection";
import { AbstractServiceRequest } from "./AbstractServiceRequest";
import { ClientInvocationService } from "./ClientInvocationService";
import { ServiceCancelRequest } from "./ServiceCancelRequest";
import { ServiceResponse } from "./ServiceResponse";
import { ServiceResponseHandler } from "./ServiceResponseHandler";
import { WebSessionManager } from "./WebSessionManager";
import { WebSocketConnectionManager } from "../connection/WebSocketConnectionManager";
import { EventResponseStatus } from "@/services/common/EventResponseStatus";
import { EventRequest } from "@/services/common/EventRequest";

class HandlerWrapper {
  public requestTime: number;

  constructor(
    public request: AbstractServiceRequest,
    public handler: ServiceResponseHandler
  ) {
    this.requestTime = new Date().getMilliseconds();
  }

  public hasMultipleResponses(): boolean {
    return this.request.isMultipleResponse();
  }

  public getEventResponseStatus(): EventResponseStatus {
    if (this.request instanceof EventRequest) {
      const eventRequest = this.request as EventRequest;
      return eventRequest.eventResponseStatus;
    } else {
      return EventResponseStatus.NO_RESPONSE;
    }
  }

  public isMultipleResponse(): boolean {
    return this.request && this.request.isMultipleResponse();
  }

  private setEventResponseStatus(responseStatus: EventResponseStatus) {
    if (this.request instanceof EventRequest) {
      const eventRequest = this.request as EventRequest;
      eventRequest.eventResponseStatus = responseStatus;
    }
  }

  public updateEventResponseStatusOnNewResponse(
    response: ServiceResponse
  ): void {
    if (this.request instanceof EventRequest) {
      const eventRequest = this.request as EventRequest;
      const currentResponseStatus = this.getEventResponseStatus();
      const isDataResponse: boolean = EventRequest.isDataResponse(response);
      switch (currentResponseStatus) {
        case EventResponseStatus.NO_RESPONSE:
          eventRequest.eventResponseStatus = isDataResponse
            ? EventResponseStatus.SUBSCRIBED
            : EventResponseStatus.FIRST_RESPONSE;
          break;
        case EventResponseStatus.FIRST_RESPONSE:
          if (isDataResponse) {
            eventRequest.eventResponseStatus = EventResponseStatus.SUBSCRIBED;
          }
          break;
        case EventResponseStatus.SUBSCRIBED:
        default:
      }
    }
  }

  public isNoResponseReceived(): boolean {
    return this.getEventResponseStatus() == EventResponseStatus.NO_RESPONSE;
  }
}

export class AbstractClientInvocationService
  implements ClientInvocationService, ConnectionListener
{
  protected static MAX_OUT_QUEUE_SIZE = 1000;
  protected static MAX_IN_QUEUE_SIZE = 1000;
  protected static CONNECTION_RETRY_WAIT_INTERVAL_IN_MILLISECONDS = 2;
  protected static MAX_MESSAGE_RESEND = 2;
  protected waitSendTasks: Map<string, AbstractServiceRequest> = new Map<
    string,
    AbstractServiceRequest
  >();

  private connectionManager: WebSocketConnectionManager;

  private responseHandlerRegistry: IKeyedCollection<HandlerWrapper>;

  constructor(connectionManager: WebSocketConnectionManager) {
    this.connectionManager = connectionManager;
    this.responseHandlerRegistry = new KeyedCollection<HandlerWrapper>();
  }

  public invokeServiceRequest(
    request: AbstractServiceRequest,
    handler: ServiceResponseHandler
  ) {
    this.start();
    const connection =
      this.connectionManager.getOrCreateDefaultConnection(this);
    this.doInvokeServiceRequestOnNonCallableConnection(
      connection,
      request,
      handler
    );
  }

  public start() {}

  public stop() {}

  onMessage(data: any) {
    let resp = data;
    if (typeof data === "string") {
      resp = Serializer.fromJsonString(data, TypeMapper.getTypeFactory());
    }
    this.onResponse(resp);
  }

  onError(error: any) {}

  onClose() {}

  onOpen() {
    const keyIterator: IterableIterator<string> = this.waitSendTasks.keys();
    let keyResult: IteratorResult<string>;
    while ((keyResult = keyIterator.next()) && !keyResult.done) {
      const request = this.waitSendTasks.get(keyResult.value);
      this.doSendMessage(
        this.connectionManager.getOrCreateDefaultConnection(this),
        request
      );
      this.waitSendTasks.delete(keyResult.value);
    }
  }

  protected doInvokeServiceRequestOnNonCallableConnection(
    connection: WebSocketConnection,
    request: AbstractServiceRequest,
    handler: ServiceResponseHandler
  ) {
    const handlerWrapper = new HandlerWrapper(request, handler);
    this.responseHandlerRegistry.add(request.getId(), handlerWrapper);
    const originalRequestId = this.getOriginalRequestIdIfCancelled(request);
    if (originalRequestId != null) {
      this.removeHandlerWrapper(originalRequestId);
    }
    try {
      switch (connection.getState()) {
        case WebSocket.OPEN:
          this.doSendMessage(connection, request);
          break;
        case WebSocket.CONNECTING:
          this.addWaitSendTask(request);
          break;
        case WebSocket.CLOSING:
        case WebSocket.CLOSED:
          this.addWaitSendTask(request);
          connection.reConnect();
          break;
      }
    } catch (e) {
      this.responseHandlerRegistry.remove(request.getId());
      handler.handleError(request, e);
    }
  }

  protected addWaitSendTask(request: AbstractServiceRequest): void {
    this.waitSendTasks.set(request.getId(), request);
  }

  /**
   * If the message is to cancel a previous request which was possibly to subscribe events,
   * returns the ID of the request to be cancelled.
   */
  protected getOriginalRequestIdIfCancelled(msg: Message): string {
    if (msg instanceof ServiceCancelRequest) {
      const cancellationRequest = msg as ServiceCancelRequest;
      return cancellationRequest.getCorrelationId() as string;
    } else {
      return null;
    }
  }

  protected getConnectionManager(): WebSocketConnectionManager {
    return this.connectionManager;
  }

  protected processUncorrelatedMessage(message: Message) {}

  private doSendMessage(
    connection: WebSocketConnection,
    request: AbstractServiceRequest
  ) {
    const jsonMessage = Serializer.toJsonString(request);
    connection.sendMessage(jsonMessage);
  }

  private getOrRemoveHandlerWrapper(correlationId: string): HandlerWrapper {
    const handlerWrapper = this.responseHandlerRegistry.item(correlationId);
    if (handlerWrapper != null && !handlerWrapper.hasMultipleResponses()) {
      this.responseHandlerRegistry.remove(correlationId);
    }
    return handlerWrapper;
  }

  private removeHandlerWrapper(correlationId: string): HandlerWrapper {
    return this.responseHandlerRegistry.remove(correlationId);
  }

  private processInMessage(msg: ServiceResponse) {
    const correlationId: string = msg.getCorrelationId() as string;
    let responseHandler: ServiceResponseHandler = null;
    let handlerWrapper: HandlerWrapper = null;
    if (correlationId != null) {
      handlerWrapper = this.getOrRemoveHandlerWrapper(correlationId);
      if (handlerWrapper != null) {
        responseHandler = handlerWrapper.handler;
      }
    }

    if (responseHandler != null) {
      const request = handlerWrapper.request;
      responseHandler.handleResponse(request, msg);
    } else {
      this.processUncorrelatedMessage(msg);
    }
  }

  private onResponse(msg: ServiceResponse) {
    const correlationId = msg.getCorrelationId();
    let handlerWrapper: HandlerWrapper = null;
    let responseHandler: ServiceResponseHandler = null;
    if (correlationId != null) {
      handlerWrapper = this.getOrRemoveHandlerWrapper(correlationId);
      if (handlerWrapper != null) {
        responseHandler = handlerWrapper.handler;
        if (handlerWrapper.isMultipleResponse()) {
          handlerWrapper.updateEventResponseStatusOnNewResponse(msg);
        }
      }
    }
    const sessionToken = msg.getSessionToken();
    if (sessionToken != null) {
      WebSessionManager.setSessionToken(sessionToken);
    }

    if (responseHandler != null) {
      const request = handlerWrapper.request;
      responseHandler.handleResponse(request, msg);
    } else {
      this.processUncorrelatedMessage(msg);
    }
  }
}
