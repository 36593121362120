/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {EntityEntitlements} from './channel/EntityEntitlements'
import {EntityInfo} from './EntityInfo'
import {EntityStats} from './EntityStats'
import {EntityUserInfo} from '../cowork/EntityUserInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {ProfileAspect} from './profile/ProfileAspect'
import {QllCountrySpec} from '../international/QllCountrySpec'
import {QllLanguageSpec} from '../international/QllLanguageSpec'
import {Serializer} from '../../serialize/Serializer'
import {SystemSuppliedEntityDesc} from './SystemSuppliedEntityDesc'
import {TypeFactory} from '../../serialize/TypeFactory'


export class SystemSuppliedEntityInfo extends AbstractSerializable  {
    languageSpec: QllLanguageSpec;
    countrySpec: QllCountrySpec;
    originalId: (number | string);
    entityDesc: SystemSuppliedEntityDesc;
    timestamp: (number | string);
    profileAspects: Array<ProfileAspect>;
    entityEntitlements: EntityEntitlements;
    entityStats: EntityStats;
    entityUserInfo: EntityUserInfo;
    forSector: boolean;
    assocEntityInfos: Array<EntityInfo>;

    constructor() {
    	super();
    }

    public getLanguageSpec(): QllLanguageSpec {
    	return this.languageSpec;
    }

    public setLanguageSpec(languageSpec: QllLanguageSpec) {
    	this.languageSpec = languageSpec;
    }

    public getCountrySpec(): QllCountrySpec {
    	return this.countrySpec;
    }

    public setCountrySpec(countrySpec: QllCountrySpec) {
    	this.countrySpec = countrySpec;
    }

    public getOriginalId(): (number | string) {
    	return this.originalId;
    }

    public setOriginalId(originalId: (number | string)) {
    	this.originalId = originalId;
    }

    public getEntityDesc(): SystemSuppliedEntityDesc {
    	return this.entityDesc;
    }

    public setEntityDesc(entityDesc: SystemSuppliedEntityDesc) {
    	this.entityDesc = entityDesc;
    }

    public getTimestamp(): (number | string) {
    	return this.timestamp;
    }

    public setTimestamp(timestamp: (number | string)) {
    	this.timestamp = timestamp;
    }

    public getProfileAspects(): Array<ProfileAspect> {
    	return this.profileAspects;
    }

    public setProfileAspects(profileAspects: Array<ProfileAspect>) {
    	this.profileAspects = profileAspects;
    }

    public getEntityEntitlements(): EntityEntitlements {
    	return this.entityEntitlements;
    }

    public setEntityEntitlements(entityEntitlements: EntityEntitlements) {
    	this.entityEntitlements = entityEntitlements;
    }

    public getEntityStats(): EntityStats {
    	return this.entityStats;
    }

    public setEntityStats(entityStats: EntityStats) {
    	this.entityStats = entityStats;
    }

    public getEntityUserInfo(): EntityUserInfo {
    	return this.entityUserInfo;
    }

    public setEntityUserInfo(entityUserInfo: EntityUserInfo) {
    	this.entityUserInfo = entityUserInfo;
    }

    public isForSector(): boolean {
    	return this.forSector;
    }

    public setForSector(forSector: boolean) {
    	this.forSector = forSector;
    }

    public getAssocEntityInfos(): Array<EntityInfo> {
    	return this.assocEntityInfos;
    }

    public setAssocEntityInfos(assocEntityInfos: Array<EntityInfo>) {
    	this.assocEntityInfos = assocEntityInfos;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let languageSpec = json["languageSpec"];
        if (languageSpec != null) {
            let convertedLanguageSpec0 = new QllLanguageSpec();
            convertedLanguageSpec0.fillFromJson(languageSpec, factory);
            this.setLanguageSpec(convertedLanguageSpec0);
        }
        let countrySpec = json["countrySpec"];
        if (countrySpec != null) {
            let convertedCountrySpec0 = new QllCountrySpec();
            convertedCountrySpec0.fillFromJson(countrySpec, factory);
            this.setCountrySpec(convertedCountrySpec0);
        }
        let originalId = json["originalId"];
        if (originalId != null) {
            this.setOriginalId(originalId);
        }
        let entityDesc = json["entityDesc"];
        if (entityDesc != null) {
            let convertedEntityDesc0 = Serializer.fillFromJsonObjectWithTypeTag(entityDesc, "__type", factory);
            this.setEntityDesc(convertedEntityDesc0);
        }
        let timestamp = json["timestamp"];
        if (timestamp != null) {
            this.setTimestamp(timestamp);
        }
        let profileAspects = json["profileAspects"];
        if (profileAspects != null) {
            let convertedProfileAspects0 = [];
            profileAspects = profileAspects[1];
            for (let i in profileAspects) {
                let convertedProfileAspects10 = profileAspects[i];
                let convertedProfileAspects1 = Serializer.fillFromJsonObjectWithTypeTag(convertedProfileAspects10, "__type", factory);
                convertedProfileAspects0.push(convertedProfileAspects1);
            }
            this.setProfileAspects(convertedProfileAspects0);
        }
        let entityEntitlements = json["entityEntitlements"];
        if (entityEntitlements != null) {
            let convertedEntityEntitlements0 = Serializer.fillFromJsonObjectWithTypeTag(entityEntitlements, "__type", factory);
            this.setEntityEntitlements(convertedEntityEntitlements0);
        }
        let entityStats = json["entityStats"];
        if (entityStats != null) {
            let convertedEntityStats0 = new EntityStats();
            convertedEntityStats0.fillFromJson(entityStats, factory);
            this.setEntityStats(convertedEntityStats0);
        }
        let entityUserInfo = json["entityUserInfo"];
        if (entityUserInfo != null) {
            let convertedEntityUserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(entityUserInfo, "__type", factory);
            this.setEntityUserInfo(convertedEntityUserInfo0);
        }
        let forSector = json["forSector"];
        if (forSector != null) {
            this.setForSector(forSector);
        }
        let assocEntityInfos = json["assocEntityInfos"];
        if (assocEntityInfos != null) {
            let convertedAssocEntityInfos0 = [];
            assocEntityInfos = assocEntityInfos[1];
            for (let i in assocEntityInfos) {
                let convertedAssocEntityInfos10 = assocEntityInfos[i];
                let convertedAssocEntityInfos1 = Serializer.fillFromJsonObjectWithTypeTag(convertedAssocEntityInfos10, "__type", factory);
                convertedAssocEntityInfos0.push(convertedAssocEntityInfos1);
            }
            this.setAssocEntityInfos(convertedAssocEntityInfos0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let languageSpec = this.getLanguageSpec();
        if (languageSpec != null) {
            let convertedLanguageSpec0 = languageSpec.getJson(JsonTypeTagType.NONE);
            json["languageSpec"] = convertedLanguageSpec0;
        }
        let countrySpec = this.getCountrySpec();
        if (countrySpec != null) {
            let convertedCountrySpec0 = countrySpec.getJson(JsonTypeTagType.NONE);
            json["countrySpec"] = convertedCountrySpec0;
        }
        let originalId = this.getOriginalId();
        if (originalId != null) {
            json["originalId"] = String(originalId);
        }
        let entityDesc = this.getEntityDesc();
        if (entityDesc != null) {
            let convertedEntityDesc0 = entityDesc.getJson(JsonTypeTagType.TYPE);
            json["entityDesc"] = convertedEntityDesc0;
        }
        let timestamp = this.getTimestamp();
        if (timestamp != null) {
            json["timestamp"] = String(timestamp);
        }
        let profileAspects = this.getProfileAspects();
        if (profileAspects != null) {
            let convertedProfileAspects0 = [];
            for (let i in profileAspects) {
                let convertedProfileAspects10 = profileAspects[i];
                let convertedProfileAspects1 = convertedProfileAspects10.getJson(JsonTypeTagType.TYPE);
                convertedProfileAspects0.push(convertedProfileAspects1);
            }
            let convertedProfileAspectsWithType1 = ["java.util.ArrayList", convertedProfileAspects0];
            json["profileAspects"] = convertedProfileAspectsWithType1;
        }
        let entityEntitlements = this.getEntityEntitlements();
        if (entityEntitlements != null) {
            let convertedEntityEntitlements0 = entityEntitlements.getJson(JsonTypeTagType.TYPE);
            json["entityEntitlements"] = convertedEntityEntitlements0;
        }
        let entityStats = this.getEntityStats();
        if (entityStats != null) {
            let convertedEntityStats0 = entityStats.getJson(JsonTypeTagType.NONE);
            json["entityStats"] = convertedEntityStats0;
        }
        let entityUserInfo = this.getEntityUserInfo();
        if (entityUserInfo != null) {
            let convertedEntityUserInfo0 = entityUserInfo.getJson(JsonTypeTagType.TYPE);
            json["entityUserInfo"] = convertedEntityUserInfo0;
        }
        let forSector = this.isForSector();
        if (forSector != null) {
            json["forSector"] = forSector;
        }
        let assocEntityInfos = this.getAssocEntityInfos();
        if (assocEntityInfos != null) {
            let convertedAssocEntityInfos0 = [];
            for (let i in assocEntityInfos) {
                let convertedAssocEntityInfos10 = assocEntityInfos[i];
                let convertedAssocEntityInfos1 = convertedAssocEntityInfos10.getJson(JsonTypeTagType.TYPE);
                convertedAssocEntityInfos0.push(convertedAssocEntityInfos1);
            }
            let convertedAssocEntityInfosWithType1 = ["java.util.ArrayList", convertedAssocEntityInfos0];
            json["assocEntityInfos"] = convertedAssocEntityInfosWithType1;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.SystemSuppliedEntityInfo";
    }
}
