/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EntityEntitlements} from './EntityEntitlements'
import {EntityInfo} from '../EntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {ProfileAspectType} from '../profile/ProfileAspectType'
import {RoleType} from '../RoleType'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractSimpleEntityEntitlements extends AbstractSerializable implements EntityEntitlements {
    owner: boolean;
    gwMember: boolean;
    gwFinAdmin: boolean;
    gwEditor: boolean;
    admin: boolean;
    finAdmin: boolean;
    pfAdmin: boolean;
    officialRep: boolean;
    employee: boolean;
    partialAdmin: boolean;
    gridInfoer: boolean;
    gridOfficer: boolean;
    gridAdmin: boolean;
    gridDirector: boolean;
    gridManager: boolean;
    gridCitizen: boolean;
    chiefEditor: boolean;
    editor: boolean;
    editorInReview: boolean;
    assocPresident: boolean;
    assocStandingVP: boolean;
    assocVicePresident: boolean;
    assocVPInReview: boolean;
    assocMember: boolean;
    assocMemberInReview: boolean;
    repInAssociation: boolean;
    indirectAssocMember: boolean;
    assocSecretaryGen: boolean;
    assocSecretary: boolean;
    assocDirector: boolean;
    ceo: boolean;
    enterpriseDirector: boolean;
    enterpriseAssocDirector: boolean;
    seniorPartner: boolean;
    partner: boolean;
    lawyer: boolean;
    probationLawyer: boolean;
    inManagerRole: boolean;
    inOfficialRole: boolean;
    noChat: boolean;
    editableAspects: Array<ProfileAspectType>;
    memberedEnterpriseInfos: Array<EntityInfo>;
    qllMember: boolean;
    highestRole: RoleType;

    constructor() {
        super();
    }

    public isOwner(): boolean {
        return this.owner;
    }

    public setOwner(owner: boolean) {
        this.owner = owner;
    }

    public isGwMember(): boolean {
        return this.gwMember;
    }

    public setGwMember(gwMember: boolean) {
        this.gwMember = gwMember;
    }

    public isGwFinAdmin(): boolean {
        return this.gwFinAdmin;
    }

    public setGwFinAdmin(gwFinAdmin: boolean) {
        this.gwFinAdmin = gwFinAdmin;
    }

    public isGwEditor(): boolean {
        return this.gwEditor;
    }

    public setGwEditor(gwEditor: boolean) {
        this.gwEditor = gwEditor;
    }

    public isAdmin(): boolean {
        return this.admin;
    }

    public setAdmin(admin: boolean) {
        this.admin = admin;
    }

    public isFinAdmin(): boolean {
        return this.finAdmin;
    }

    public setFinAdmin(finAdmin: boolean) {
        this.finAdmin = finAdmin;
    }

    public isPfAdmin(): boolean {
        return this.pfAdmin;
    }

    public setPfAdmin(pfAdmin: boolean) {
        this.pfAdmin = pfAdmin;
    }

    public isOfficialRep(): boolean {
        return this.officialRep;
    }

    public setOfficialRep(officialRep: boolean) {
        this.officialRep = officialRep;
    }

    public isEmployee(): boolean {
        return this.employee;
    }

    public setEmployee(employee: boolean) {
        this.employee = employee;
    }

    public isPartialAdmin(): boolean {
        return this.partialAdmin;
    }

    public setPartialAdmin(partialAdmin: boolean) {
        this.partialAdmin = partialAdmin;
    }

    public isGridInfoer(): boolean {
        return this.gridInfoer;
    }

    public setGridInfoer(gridInfoer: boolean) {
        this.gridInfoer = gridInfoer;
    }

    public isGridOfficer(): boolean {
        return this.gridOfficer;
    }

    public setGridOfficer(gridOfficer: boolean) {
        this.gridOfficer = gridOfficer;
    }

    public isGridAdmin(): boolean {
        return this.gridAdmin;
    }

    public setGridAdmin(gridAdmin: boolean) {
        this.gridAdmin = gridAdmin;
    }

    public isGridDirector(): boolean {
        return this.gridDirector;
    }

    public setGridDirector(gridDirector: boolean) {
        this.gridDirector = gridDirector;
    }

    public isGridManager(): boolean {
        return this.gridManager;
    }

    public setGridManager(gridManager: boolean) {
        this.gridManager = gridManager;
    }

    public isGridCitizen(): boolean {
        return this.gridCitizen;
    }

    public setGridCitizen(gridCitizen: boolean) {
        this.gridCitizen = gridCitizen;
    }

    public isChiefEditor(): boolean {
        return this.chiefEditor;
    }

    public setChiefEditor(chiefEditor: boolean) {
        this.chiefEditor = chiefEditor;
    }

    public isEditor(): boolean {
        return this.editor;
    }

    public setEditor(editor: boolean) {
        this.editor = editor;
    }

    public isEditorInReview(): boolean {
        return this.editorInReview;
    }

    public setEditorInReview(editorInReview: boolean) {
        this.editorInReview = editorInReview;
    }

    public isAssocPresident(): boolean {
        return this.assocPresident;
    }

    public setAssocPresident(assocPresident: boolean) {
        this.assocPresident = assocPresident;
    }

    public isAssocStandingVP(): boolean {
        return this.assocStandingVP;
    }

    public setAssocStandingVP(assocStandingVP: boolean) {
        this.assocStandingVP = assocStandingVP;
    }

    public isAssocVicePresident(): boolean {
        return this.assocVicePresident;
    }

    public setAssocVicePresident(assocVicePresident: boolean) {
        this.assocVicePresident = assocVicePresident;
    }

    public isAssocVPInReview(): boolean {
        return this.assocVPInReview;
    }

    public setAssocVPInReview(assocVPInReview: boolean) {
        this.assocVPInReview = assocVPInReview;
    }

    public isAssocMember(): boolean {
        return this.assocMember;
    }

    public setAssocMember(assocMember: boolean) {
        this.assocMember = assocMember;
    }

    public isAssocMemberInReview(): boolean {
        return this.assocMemberInReview;
    }

    public setAssocMemberInReview(assocMemberInReview: boolean) {
        this.assocMemberInReview = assocMemberInReview;
    }

    public isRepInAssociation(): boolean {
        return this.repInAssociation;
    }

    public setRepInAssociation(repInAssociation: boolean) {
        this.repInAssociation = repInAssociation;
    }

    public isIndirectAssocMember(): boolean {
        return this.indirectAssocMember;
    }

    public setIndirectAssocMember(indirectAssocMember: boolean) {
        this.indirectAssocMember = indirectAssocMember;
    }

    public isAssocSecretaryGen(): boolean {
        return this.assocSecretaryGen;
    }

    public setAssocSecretaryGen(assocSecretaryGen: boolean) {
        this.assocSecretaryGen = assocSecretaryGen;
    }

    public isAssocSecretary(): boolean {
        return this.assocSecretary;
    }

    public setAssocSecretary(assocSecretary: boolean) {
        this.assocSecretary = assocSecretary;
    }

    public isAssocDirector(): boolean {
        return this.assocDirector;
    }

    public setAssocDirector(assocDirector: boolean) {
        this.assocDirector = assocDirector;
    }

    public isCeo(): boolean {
        return this.ceo;
    }

    public setCeo(ceo: boolean) {
        this.ceo = ceo;
    }

    public isEnterpriseDirector(): boolean {
        return this.enterpriseDirector;
    }

    public setEnterpriseDirector(enterpriseDirector: boolean) {
        this.enterpriseDirector = enterpriseDirector;
    }

    public isEnterpriseAssocDirector(): boolean {
        return this.enterpriseAssocDirector;
    }

    public setEnterpriseAssocDirector(enterpriseAssocDirector: boolean) {
        this.enterpriseAssocDirector = enterpriseAssocDirector;
    }

    public isSeniorPartner(): boolean {
        return this.seniorPartner;
    }

    public setSeniorPartner(seniorPartner: boolean) {
        this.seniorPartner = seniorPartner;
    }

    public isPartner(): boolean {
        return this.partner;
    }

    public setPartner(partner: boolean) {
        this.partner = partner;
    }

    public isLawyer(): boolean {
        return this.lawyer;
    }

    public setLawyer(lawyer: boolean) {
        this.lawyer = lawyer;
    }

    public isProbationLawyer(): boolean {
        return this.probationLawyer;
    }

    public setProbationLawyer(probationLawyer: boolean) {
        this.probationLawyer = probationLawyer;
    }

    public isInManagerRole(): boolean {
        return this.inManagerRole;
    }

    public setInManagerRole(inManagerRole: boolean) {
        this.inManagerRole = inManagerRole;
    }

    public isInOfficialRole(): boolean {
        return this.inOfficialRole;
    }

    public setInOfficialRole(inOfficialRole: boolean) {
        this.inOfficialRole = inOfficialRole;
    }

    public isNoChat(): boolean {
        return this.noChat;
    }

    public setNoChat(noChat: boolean) {
        this.noChat = noChat;
    }

    public getEditableAspects(): Array<ProfileAspectType> {
        return this.editableAspects;
    }

    public setEditableAspects(editableAspects: Array<ProfileAspectType>) {
        this.editableAspects = editableAspects;
    }

    public getMemberedEnterpriseInfos(): Array<EntityInfo> {
        return this.memberedEnterpriseInfos;
    }

    public setMemberedEnterpriseInfos(memberedEnterpriseInfos: Array<EntityInfo>) {
        this.memberedEnterpriseInfos = memberedEnterpriseInfos;
    }

    public isQllMember(): boolean {
        return this.qllMember;
    }

    public setQllMember(qllMember: boolean) {
        this.qllMember = qllMember;
    }

    public getHighestRole(): RoleType {
        return this.highestRole;
    }

    public setHighestRole(highestRole: RoleType) {
        this.highestRole = highestRole;
    }


    abstract hasEnterpriseAssocRole(): boolean;

    abstract isInAssocManagement(): boolean;

    abstract isInAssociation(): boolean;

    abstract isInGrid(): boolean;

    abstract isInGridManagementRole(): boolean;

    abstract isInRole(arg0: (RoleType | null)): boolean;

    abstract getRoles(): Array<RoleType>;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let owner = json["owner"];
        if (owner != null) {
            this.setOwner(owner);
        }
        let gwMember = json["gwMember"];
        if (gwMember != null) {
            this.setGwMember(gwMember);
        }
        let gwFinAdmin = json["gwFinAdmin"];
        if (gwFinAdmin != null) {
            this.setGwFinAdmin(gwFinAdmin);
        }
        let gwEditor = json["gwEditor"];
        if (gwEditor != null) {
            this.setGwEditor(gwEditor);
        }
        let admin = json["admin"];
        if (admin != null) {
            this.setAdmin(admin);
        }
        let finAdmin = json["finAdmin"];
        if (finAdmin != null) {
            this.setFinAdmin(finAdmin);
        }
        let pfAdmin = json["pfAdmin"];
        if (pfAdmin != null) {
            this.setPfAdmin(pfAdmin);
        }
        let officialRep = json["officialRep"];
        if (officialRep != null) {
            this.setOfficialRep(officialRep);
        }
        let employee = json["employee"];
        if (employee != null) {
            this.setEmployee(employee);
        }
        let partialAdmin = json["partialAdmin"];
        if (partialAdmin != null) {
            this.setPartialAdmin(partialAdmin);
        }
        let gridInfoer = json["gridInfoer"];
        if (gridInfoer != null) {
            this.setGridInfoer(gridInfoer);
        }
        let gridOfficer = json["gridOfficer"];
        if (gridOfficer != null) {
            this.setGridOfficer(gridOfficer);
        }
        let gridAdmin = json["gridAdmin"];
        if (gridAdmin != null) {
            this.setGridAdmin(gridAdmin);
        }
        let gridDirector = json["gridDirector"];
        if (gridDirector != null) {
            this.setGridDirector(gridDirector);
        }
        let gridManager = json["gridManager"];
        if (gridManager != null) {
            this.setGridManager(gridManager);
        }
        let gridCitizen = json["gridCitizen"];
        if (gridCitizen != null) {
            this.setGridCitizen(gridCitizen);
        }
        let chiefEditor = json["chiefEditor"];
        if (chiefEditor != null) {
            this.setChiefEditor(chiefEditor);
        }
        let editor = json["editor"];
        if (editor != null) {
            this.setEditor(editor);
        }
        let editorInReview = json["editorInReview"];
        if (editorInReview != null) {
            this.setEditorInReview(editorInReview);
        }
        let assocPresident = json["assocPresident"];
        if (assocPresident != null) {
            this.setAssocPresident(assocPresident);
        }
        let assocStandingVP = json["assocStandingVP"];
        if (assocStandingVP != null) {
            this.setAssocStandingVP(assocStandingVP);
        }
        let assocVicePresident = json["assocVicePresident"];
        if (assocVicePresident != null) {
            this.setAssocVicePresident(assocVicePresident);
        }
        let assocVPInReview = json["assocVPInReview"];
        if (assocVPInReview != null) {
            this.setAssocVPInReview(assocVPInReview);
        }
        let assocMember = json["assocMember"];
        if (assocMember != null) {
            this.setAssocMember(assocMember);
        }
        let assocMemberInReview = json["assocMemberInReview"];
        if (assocMemberInReview != null) {
            this.setAssocMemberInReview(assocMemberInReview);
        }
        let repInAssociation = json["repInAssociation"];
        if (repInAssociation != null) {
            this.setRepInAssociation(repInAssociation);
        }
        let indirectAssocMember = json["indirectAssocMember"];
        if (indirectAssocMember != null) {
            this.setIndirectAssocMember(indirectAssocMember);
        }
        let assocSecretaryGen = json["assocSecretaryGen"];
        if (assocSecretaryGen != null) {
            this.setAssocSecretaryGen(assocSecretaryGen);
        }
        let assocSecretary = json["assocSecretary"];
        if (assocSecretary != null) {
            this.setAssocSecretary(assocSecretary);
        }
        let assocDirector = json["assocDirector"];
        if (assocDirector != null) {
            this.setAssocDirector(assocDirector);
        }
        let ceo = json["ceo"];
        if (ceo != null) {
            this.setCeo(ceo);
        }
        let enterpriseDirector = json["enterpriseDirector"];
        if (enterpriseDirector != null) {
            this.setEnterpriseDirector(enterpriseDirector);
        }
        let enterpriseAssocDirector = json["enterpriseAssocDirector"];
        if (enterpriseAssocDirector != null) {
            this.setEnterpriseAssocDirector(enterpriseAssocDirector);
        }
        let seniorPartner = json["seniorPartner"];
        if (seniorPartner != null) {
            this.setSeniorPartner(seniorPartner);
        }
        let partner = json["partner"];
        if (partner != null) {
            this.setPartner(partner);
        }
        let lawyer = json["lawyer"];
        if (lawyer != null) {
            this.setLawyer(lawyer);
        }
        let probationLawyer = json["probationLawyer"];
        if (probationLawyer != null) {
            this.setProbationLawyer(probationLawyer);
        }
        let inManagerRole = json["inManagerRole"];
        if (inManagerRole != null) {
            this.setInManagerRole(inManagerRole);
        }
        let inOfficialRole = json["inOfficialRole"];
        if (inOfficialRole != null) {
            this.setInOfficialRole(inOfficialRole);
        }
        let noChat = json["noChat"];
        if (noChat != null) {
            this.setNoChat(noChat);
        }
        let editableAspects = json["editableAspects"];
        if (editableAspects != null) {
            let convertedEditableAspects0 = [];
            editableAspects = editableAspects[1];
            for (let i in editableAspects) {
                let convertedEditableAspects10 = editableAspects[i];
                let convertedEditableAspects1 = ProfileAspectType[<string>convertedEditableAspects10];
                convertedEditableAspects0.push(convertedEditableAspects1);
            }
            this.setEditableAspects(convertedEditableAspects0);
        }
        let memberedEnterpriseInfos = json["memberedEnterpriseInfos"];
        if (memberedEnterpriseInfos != null) {
            let convertedMemberedEnterpriseInfos0 = [];
            memberedEnterpriseInfos = memberedEnterpriseInfos[1];
            for (let i in memberedEnterpriseInfos) {
                let convertedMemberedEnterpriseInfos10 = memberedEnterpriseInfos[i];
                let convertedMemberedEnterpriseInfos1 = Serializer.fillFromJsonObjectWithTypeTag(convertedMemberedEnterpriseInfos10, "__type", factory);
                convertedMemberedEnterpriseInfos0.push(convertedMemberedEnterpriseInfos1);
            }
            this.setMemberedEnterpriseInfos(convertedMemberedEnterpriseInfos0);
        }
        let qllMember = json["qllMember"];
        if (qllMember != null) {
            this.setQllMember(qllMember);
        }
        let highestRole = json["highestRole"];
        if (highestRole != null) {
            let convertedHighestRole0 = RoleType[<string>highestRole];
            this.setHighestRole(convertedHighestRole0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let owner = this.isOwner();
        if (owner != null) {
            json["owner"] = owner;
        }
        let gwMember = this.isGwMember();
        if (gwMember != null) {
            json["gwMember"] = gwMember;
        }
        let gwFinAdmin = this.isGwFinAdmin();
        if (gwFinAdmin != null) {
            json["gwFinAdmin"] = gwFinAdmin;
        }
        let gwEditor = this.isGwEditor();
        if (gwEditor != null) {
            json["gwEditor"] = gwEditor;
        }
        let admin = this.isAdmin();
        if (admin != null) {
            json["admin"] = admin;
        }
        let finAdmin = this.isFinAdmin();
        if (finAdmin != null) {
            json["finAdmin"] = finAdmin;
        }
        let pfAdmin = this.isPfAdmin();
        if (pfAdmin != null) {
            json["pfAdmin"] = pfAdmin;
        }
        let officialRep = this.isOfficialRep();
        if (officialRep != null) {
            json["officialRep"] = officialRep;
        }
        let employee = this.isEmployee();
        if (employee != null) {
            json["employee"] = employee;
        }
        let partialAdmin = this.isPartialAdmin();
        if (partialAdmin != null) {
            json["partialAdmin"] = partialAdmin;
        }
        let gridInfoer = this.isGridInfoer();
        if (gridInfoer != null) {
            json["gridInfoer"] = gridInfoer;
        }
        let gridOfficer = this.isGridOfficer();
        if (gridOfficer != null) {
            json["gridOfficer"] = gridOfficer;
        }
        let gridAdmin = this.isGridAdmin();
        if (gridAdmin != null) {
            json["gridAdmin"] = gridAdmin;
        }
        let gridDirector = this.isGridDirector();
        if (gridDirector != null) {
            json["gridDirector"] = gridDirector;
        }
        let gridManager = this.isGridManager();
        if (gridManager != null) {
            json["gridManager"] = gridManager;
        }
        let gridCitizen = this.isGridCitizen();
        if (gridCitizen != null) {
            json["gridCitizen"] = gridCitizen;
        }
        let chiefEditor = this.isChiefEditor();
        if (chiefEditor != null) {
            json["chiefEditor"] = chiefEditor;
        }
        let editor = this.isEditor();
        if (editor != null) {
            json["editor"] = editor;
        }
        let editorInReview = this.isEditorInReview();
        if (editorInReview != null) {
            json["editorInReview"] = editorInReview;
        }
        let assocPresident = this.isAssocPresident();
        if (assocPresident != null) {
            json["assocPresident"] = assocPresident;
        }
        let assocStandingVP = this.isAssocStandingVP();
        if (assocStandingVP != null) {
            json["assocStandingVP"] = assocStandingVP;
        }
        let assocVicePresident = this.isAssocVicePresident();
        if (assocVicePresident != null) {
            json["assocVicePresident"] = assocVicePresident;
        }
        let assocVPInReview = this.isAssocVPInReview();
        if (assocVPInReview != null) {
            json["assocVPInReview"] = assocVPInReview;
        }
        let assocMember = this.isAssocMember();
        if (assocMember != null) {
            json["assocMember"] = assocMember;
        }
        let assocMemberInReview = this.isAssocMemberInReview();
        if (assocMemberInReview != null) {
            json["assocMemberInReview"] = assocMemberInReview;
        }
        let repInAssociation = this.isRepInAssociation();
        if (repInAssociation != null) {
            json["repInAssociation"] = repInAssociation;
        }
        let indirectAssocMember = this.isIndirectAssocMember();
        if (indirectAssocMember != null) {
            json["indirectAssocMember"] = indirectAssocMember;
        }
        let assocSecretaryGen = this.isAssocSecretaryGen();
        if (assocSecretaryGen != null) {
            json["assocSecretaryGen"] = assocSecretaryGen;
        }
        let assocSecretary = this.isAssocSecretary();
        if (assocSecretary != null) {
            json["assocSecretary"] = assocSecretary;
        }
        let assocDirector = this.isAssocDirector();
        if (assocDirector != null) {
            json["assocDirector"] = assocDirector;
        }
        let ceo = this.isCeo();
        if (ceo != null) {
            json["ceo"] = ceo;
        }
        let enterpriseDirector = this.isEnterpriseDirector();
        if (enterpriseDirector != null) {
            json["enterpriseDirector"] = enterpriseDirector;
        }
        let enterpriseAssocDirector = this.isEnterpriseAssocDirector();
        if (enterpriseAssocDirector != null) {
            json["enterpriseAssocDirector"] = enterpriseAssocDirector;
        }
        let seniorPartner = this.isSeniorPartner();
        if (seniorPartner != null) {
            json["seniorPartner"] = seniorPartner;
        }
        let partner = this.isPartner();
        if (partner != null) {
            json["partner"] = partner;
        }
        let lawyer = this.isLawyer();
        if (lawyer != null) {
            json["lawyer"] = lawyer;
        }
        let probationLawyer = this.isProbationLawyer();
        if (probationLawyer != null) {
            json["probationLawyer"] = probationLawyer;
        }
        let inManagerRole = this.isInManagerRole();
        if (inManagerRole != null) {
            json["inManagerRole"] = inManagerRole;
        }
        let inOfficialRole = this.isInOfficialRole();
        if (inOfficialRole != null) {
            json["inOfficialRole"] = inOfficialRole;
        }
        let noChat = this.isNoChat();
        if (noChat != null) {
            json["noChat"] = noChat;
        }
        let editableAspects = this.getEditableAspects();
        if (editableAspects != null) {
            let convertedEditableAspects0 = [];
            for (let i in editableAspects) {
                let convertedEditableAspects10 = editableAspects[i];
                let convertedEditableAspects1 = ProfileAspectType[convertedEditableAspects10];
                convertedEditableAspects0.push(convertedEditableAspects1);
            }
            let convertedEditableAspectsWithType1 = ["java.util.ArrayList", convertedEditableAspects0];
            json["editableAspects"] = convertedEditableAspectsWithType1;
        }
        let memberedEnterpriseInfos = this.getMemberedEnterpriseInfos();
        if (memberedEnterpriseInfos != null) {
            let convertedMemberedEnterpriseInfos0 = [];
            for (let i in memberedEnterpriseInfos) {
                let convertedMemberedEnterpriseInfos10 = memberedEnterpriseInfos[i];
                let convertedMemberedEnterpriseInfos1 = convertedMemberedEnterpriseInfos10.getJson(JsonTypeTagType.TYPE);
                convertedMemberedEnterpriseInfos0.push(convertedMemberedEnterpriseInfos1);
            }
            let convertedMemberedEnterpriseInfosWithType1 = ["java.util.ArrayList", convertedMemberedEnterpriseInfos0];
            json["memberedEnterpriseInfos"] = convertedMemberedEnterpriseInfosWithType1;
        }
        let qllMember = this.isQllMember();
        if (qllMember != null) {
            json["qllMember"] = qllMember;
        }
        let highestRole = this.getHighestRole();
        if (highestRole != null) {
            let convertedHighestRole0 = RoleType[highestRole];
            json["highestRole"] = convertedHighestRole0;
        }

        if (includeTypeTag) {
            json["__type"] = "SimpleEntityEntitlements";
        }
    }


    public getTypeId(): string {
        return "SimpleEntityEntitlements";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.channel.SimpleEntityEntitlements";
    }
}
