/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../serialize/TypeFactory'


export class Location extends AbstractSerializable  {
    id: (number | string);
    regionId: (number | string);
    townId: (number | string);
    lat: number;
    lng: number;
    mapId: string;
    name: string;
    aliasName: string;
    districtName: string;
    cityName: string;

    constructor() {
    	super();
    }

    public getId(): (number | string) {
    	return this.id;
    }

    public setId(id: (number | string)) {
    	this.id = id;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getTownId(): (number | string) {
    	return this.townId;
    }

    public setTownId(townId: (number | string)) {
    	this.townId = townId;
    }

    public getLat(): number {
    	return this.lat;
    }

    public setLat(lat: number) {
    	this.lat = lat;
    }

    public getLng(): number {
    	return this.lng;
    }

    public setLng(lng: number) {
    	this.lng = lng;
    }

    public getMapId(): string {
    	return this.mapId;
    }

    public setMapId(mapId: string) {
    	this.mapId = mapId;
    }

    public getName(): string {
    	return this.name;
    }

    public setName(name: string) {
    	this.name = name;
    }

    public getAliasName(): string {
    	return this.aliasName;
    }

    public setAliasName(aliasName: string) {
    	this.aliasName = aliasName;
    }

    public getDistrictName(): string {
    	return this.districtName;
    }

    public setDistrictName(districtName: string) {
    	this.districtName = districtName;
    }

    public getCityName(): string {
    	return this.cityName;
    }

    public setCityName(cityName: string) {
    	this.cityName = cityName;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let id = json["id"];
        if (id != null) {
            this.setId(id);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let townId = json["townId"];
        if (townId != null) {
            this.setTownId(townId);
        }
        let lat = json["lat"];
        if (lat != null) {
            this.setLat(lat);
        }
        let lng = json["lng"];
        if (lng != null) {
            this.setLng(lng);
        }
        let mapId = json["mapId"];
        if (mapId != null) {
            this.setMapId(mapId);
        }
        let name = json["name"];
        if (name != null) {
            this.setName(name);
        }
        let aliasName = json["aliasName"];
        if (aliasName != null) {
            this.setAliasName(aliasName);
        }
        let districtName = json["districtName"];
        if (districtName != null) {
            this.setDistrictName(districtName);
        }
        let cityName = json["cityName"];
        if (cityName != null) {
            this.setCityName(cityName);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let id = this.getId();
        if (id != null) {
            json["id"] = String(id);
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let townId = this.getTownId();
        if (townId != null) {
            json["townId"] = String(townId);
        }
        let lat = this.getLat();
        if (lat != null) {
            json["lat"] = lat;
        }
        let lng = this.getLng();
        if (lng != null) {
            json["lng"] = lng;
        }
        let mapId = this.getMapId();
        if (mapId != null) {
            json["mapId"] = mapId;
        }
        let name = this.getName();
        if (name != null) {
            json["name"] = name;
        }
        let aliasName = this.getAliasName();
        if (aliasName != null) {
            json["aliasName"] = aliasName;
        }
        let districtName = this.getDistrictName();
        if (districtName != null) {
            json["districtName"] = districtName;
        }
        let cityName = this.getCityName();
        if (cityName != null) {
            json["cityName"] = cityName;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.geolocation.Location";
    }
}
