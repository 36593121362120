/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class SettlementRuleInfo extends AbstractSerializable {
    billCreateDayOfSeason: number;
    billCreateDayDesc: string;
    invoiceDelayAfterBill: number;
    invoiceDelayDesc: string;
    recordTips: string;

    constructor() {
        super();
    }

    public getBillCreateDayOfSeason(): number {
        return this.billCreateDayOfSeason;
    }

    public setBillCreateDayOfSeason(billCreateDayOfSeason: number) {
        this.billCreateDayOfSeason = billCreateDayOfSeason;
    }

    public getBillCreateDayDesc(): string {
        return this.billCreateDayDesc;
    }

    public setBillCreateDayDesc(billCreateDayDesc: string) {
        this.billCreateDayDesc = billCreateDayDesc;
    }

    public getInvoiceDelayAfterBill(): number {
        return this.invoiceDelayAfterBill;
    }

    public setInvoiceDelayAfterBill(invoiceDelayAfterBill: number) {
        this.invoiceDelayAfterBill = invoiceDelayAfterBill;
    }

    public getInvoiceDelayDesc(): string {
        return this.invoiceDelayDesc;
    }

    public setInvoiceDelayDesc(invoiceDelayDesc: string) {
        this.invoiceDelayDesc = invoiceDelayDesc;
    }

    public getRecordTips(): string {
        return this.recordTips;
    }

    public setRecordTips(recordTips: string) {
        this.recordTips = recordTips;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let billCreateDayOfSeason = json["billCreateDayOfSeason"];
        if (billCreateDayOfSeason != null) {
            this.setBillCreateDayOfSeason(billCreateDayOfSeason);
        }
        let billCreateDayDesc = json["billCreateDayDesc"];
        if (billCreateDayDesc != null) {
            this.setBillCreateDayDesc(billCreateDayDesc);
        }
        let invoiceDelayAfterBill = json["invoiceDelayAfterBill"];
        if (invoiceDelayAfterBill != null) {
            this.setInvoiceDelayAfterBill(invoiceDelayAfterBill);
        }
        let invoiceDelayDesc = json["invoiceDelayDesc"];
        if (invoiceDelayDesc != null) {
            this.setInvoiceDelayDesc(invoiceDelayDesc);
        }
        let recordTips = json["recordTips"];
        if (recordTips != null) {
            this.setRecordTips(recordTips);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let billCreateDayOfSeason = this.getBillCreateDayOfSeason();
        if (billCreateDayOfSeason != null) {
            json["billCreateDayOfSeason"] = billCreateDayOfSeason;
        }
        let billCreateDayDesc = this.getBillCreateDayDesc();
        if (billCreateDayDesc != null) {
            json["billCreateDayDesc"] = billCreateDayDesc;
        }
        let invoiceDelayAfterBill = this.getInvoiceDelayAfterBill();
        if (invoiceDelayAfterBill != null) {
            json["invoiceDelayAfterBill"] = invoiceDelayAfterBill;
        }
        let invoiceDelayDesc = this.getInvoiceDelayDesc();
        if (invoiceDelayDesc != null) {
            json["invoiceDelayDesc"] = invoiceDelayDesc;
        }
        let recordTips = this.getRecordTips();
        if (recordTips != null) {
            json["recordTips"] = recordTips;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.service.settlement.SettlementRuleInfo";
    }
}
