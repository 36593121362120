/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCowork} from '../../cowork/AbstractCowork'
import {AnswerStatus} from './AnswerStatus'
import {EntityInfo} from '../../entity/EntityInfo'
import {GeneralCoworkStats} from '../../cowork/GeneralCoworkStats'
import {GeneralCoworkUserInfo} from '../../cowork/GeneralCoworkUserInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../keyword/KeywordEntity'
import {MessageType} from '../../../message/MessageContentType'
import {QllCountry} from '../../international/QllCountry'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractAnswerContent extends AbstractCowork  {
    questionId: (number | string);
    answerStats: GeneralCoworkStats;
    answerUserInfo: GeneralCoworkUserInfo;
    status: AnswerStatus;

    constructor() {
    	super();
    }

    public getQuestionId(): (number | string) {
    	return this.questionId;
    }

    /**
     * 必选数据： 所对应的问题ID
     */
    public setQuestionId(questionId: (number | string)) {
    	this.questionId = questionId;
    }

    public getAnswerStats(): GeneralCoworkStats {
    	return this.answerStats;
    }

    public setAnswerStats(answerStats: GeneralCoworkStats) {
    	this.answerStats = answerStats;
    }

    public getAnswerUserInfo(): GeneralCoworkUserInfo {
    	return this.answerUserInfo;
    }

    public setAnswerUserInfo(answerUserInfo: GeneralCoworkUserInfo) {
    	this.answerUserInfo = answerUserInfo;
    }

    public getStatus(): AnswerStatus {
    	return this.status;
    }

    public setStatus(status: AnswerStatus) {
    	this.status = status;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let questionId = json["questionId"];
        if (questionId != null) {
            this.setQuestionId(questionId);
        }
        let answerStats = json["answerStats"];
        if (answerStats != null) {
            let convertedAnswerStats0 = Serializer.fillFromJsonObjectWithTypeTag(answerStats, "__type", factory);
            this.setAnswerStats(convertedAnswerStats0);
        }
        let answerUserInfo = json["answerUserInfo"];
        if (answerUserInfo != null) {
            let convertedAnswerUserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(answerUserInfo, "__type", factory);
            this.setAnswerUserInfo(convertedAnswerUserInfo0);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = AnswerStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let questionId = this.getQuestionId();
        if (questionId != null) {
            json["questionId"] = String(questionId);
        }
        let answerStats = this.getAnswerStats();
        if (answerStats != null) {
            let convertedAnswerStats0 = answerStats.getJson(JsonTypeTagType.TYPE);
            json["answerStats"] = convertedAnswerStats0;
        }
        let answerUserInfo = this.getAnswerUserInfo();
        if (answerUserInfo != null) {
            let convertedAnswerUserInfo0 = answerUserInfo.getJson(JsonTypeTagType.TYPE);
            json["answerUserInfo"] = convertedAnswerUserInfo0;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = AnswerStatus[status];
            json["status"] = convertedStatus0;
        }

        if (includeTypeTag) {
            json["__type"] = "AnswerContent";
        }
    }


    public getTypeId(): string {
    	return "AnswerContent";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.ipsphere.qa.AnswerContent";
    }
}
