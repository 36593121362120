// @ts-nocheck
import { AbstractCompositeActivityInfo } from "./AbstractCompositeActivityInfo";

export class CompositeActivityInfo extends AbstractCompositeActivityInfo {
  constructor() {
    super();
  }

  getVisits(): number {
    // Manual Code Here
  }
}
