/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkUserInfo } from "../cowork/CoworkUserInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { PrizableInfo } from "../asset/PrizableInfo";
import { REPrizeStatus } from "../redenvelope/REPrizeStatus";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractDistanceInfo
  extends AbstractSerializable
  implements CoworkUserInfo
{
  regionName: string;
  awayFromMe: number;
  approve: boolean;
  prizableInfo: PrizableInfo;
  prizeStatus: REPrizeStatus;
  pulled: boolean;
  templateUrl: string;

  constructor() {
    super();
  }

  public getRegionName(): string {
    return this.regionName;
  }

  public setRegionName(regionName: string) {
    this.regionName = regionName;
  }

  public getAwayFromMe(): number {
    return this.awayFromMe;
  }

  public setAwayFromMe(awayFromMe: number) {
    this.awayFromMe = awayFromMe;
  }

  public getApprove(): boolean {
    return this.approve;
  }

  public setApprove(approve: boolean) {
    this.approve = approve;
  }

  public getPrizableInfo(): PrizableInfo {
    return this.prizableInfo;
  }

  public setPrizableInfo(prizableInfo: PrizableInfo) {
    this.prizableInfo = prizableInfo;
  }

  public getPrizeStatus(): REPrizeStatus {
    return this.prizeStatus;
  }

  public setPrizeStatus(prizeStatus: REPrizeStatus) {
    this.prizeStatus = prizeStatus;
  }

  public isPulled(): boolean {
    return this.pulled;
  }

  public setPulled(pulled: boolean) {
    this.pulled = pulled;
  }

  public getTemplateUrl(): string {
    return this.templateUrl;
  }

  public setTemplateUrl(templateUrl: string) {
    this.templateUrl = templateUrl;
  }

  abstract isActive(): boolean;

  abstract isManager(): boolean;

  abstract getPrizable(): boolean;

  abstract getApproverPrize(): number;

  abstract getCreatorPrize(): number;

  abstract getRelApproverPrize(): number;

  abstract getRelCreatorPrize(): number;

  abstract addApproverPrize(arg0: number | null);

  abstract addCreatorPrize(arg0: number | null);

  abstract setActive(active: boolean | null);

  abstract setApproverPrize(approverPrize: number | null);

  abstract setCreatorPrize(creatorPrize: number | null);

  abstract setManager(arg0: boolean | null);

  abstract setPrizable(prizable: boolean | null);

  abstract setRelCreatorPrize(relCreatorPrize: number | null);

  abstract update(coworkUserInfo: CoworkUserInfo | null);

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const regionName = json["regionName"];
    if (regionName != null) {
      this.setRegionName(regionName);
    }
    const awayFromMe = json["awayFromMe"];
    if (awayFromMe != null) {
      this.setAwayFromMe(awayFromMe);
    }
    const approve = json["approve"];
    if (approve != null) {
      this.setApprove(approve);
    }
    const prizableInfo = json["prizableInfo"];
    if (prizableInfo != null) {
      const convertedPrizableInfo0 = new PrizableInfo();
      convertedPrizableInfo0.fillFromJson(prizableInfo, factory);
      this.setPrizableInfo(convertedPrizableInfo0);
    }
    const prizeStatus = json["prizeStatus"];
    if (prizeStatus != null) {
      const convertedPrizeStatus0 = REPrizeStatus[<string>prizeStatus];
      this.setPrizeStatus(convertedPrizeStatus0);
    }
    const pulled = json["pulled"];
    if (pulled != null) {
      this.setPulled(pulled);
    }
    const templateUrl = json["templateUrl"];
    if (templateUrl != null) {
      this.setTemplateUrl(templateUrl);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const regionName = this.getRegionName();
    if (regionName != null) {
      json["regionName"] = regionName;
    }
    const awayFromMe = this.getAwayFromMe();
    if (awayFromMe != null) {
      json["awayFromMe"] = awayFromMe;
    }
    const approve = this.getApprove();
    if (approve != null) {
      json["approve"] = approve;
    }
    const prizableInfo = this.getPrizableInfo();
    if (prizableInfo != null) {
      const convertedPrizableInfo0 = prizableInfo.getJson(JsonTypeTagType.NONE);
      json["prizableInfo"] = convertedPrizableInfo0;
    }
    const prizeStatus = this.getPrizeStatus();
    if (prizeStatus != null) {
      const convertedPrizeStatus0 = REPrizeStatus[prizeStatus];
      json["prizeStatus"] = convertedPrizeStatus0;
    }
    const pulled = this.isPulled();
    if (pulled != null) {
      json["pulled"] = pulled;
    }
    const templateUrl = this.getTemplateUrl();
    if (templateUrl != null) {
      json["templateUrl"] = templateUrl;
    }

    if (includeTypeTag) {
      json["__type"] = "DistanceInfo";
    }
  }

  public getTypeId(): string {
    return "DistanceInfo";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.geolocation.DistanceInfo";
  }
}
