/**
 * 自动生成， 请不要手工修改！
 */


export enum QuestionStatus {
    IN_REVIEW,
    CHECK_FAIL,
    CALL_FOR_EXPERT,
    ANS_ACCEPTED,
    ACTIVE,
    CANCELLED,
    HIDDEN,
    DISLIKED
}
