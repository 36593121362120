/**
 * 自动生成， 请不要手工修改！
 */

import {GenericEntityMessage} from "@/services/message/entity/api/GenericEntityMessage";
import {MessageType} from "../../MessageContentType";
import {TypeFactory} from "../../../serialize/TypeFactory";
import { ServiceOpNotification } from "@/services/datatype/ipsphere/ServiceOpNotification";

export abstract class AbstractServiceOpMessage extends GenericEntityMessage<ServiceOpNotification> {
    constructor() {
        super();
    }

    public getContentType(): MessageType {
        return MessageType.OP_NOTIFY;
    }

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
    }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);

    if (includeTypeTag) {
      json["__type"] = "ServiceOpMessage";
    }
  }

  public getTypeId(): string {
    return "ServiceOpMessage";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.business.service.ServiceOpMessage";
  }
}
