/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { ChannelEntitlements } from "./ChannelEntitlements";
import { ChannelSpec } from "./ChannelSpec";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Serializer } from "../../../serialize/Serializer";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class ChannelInfo extends AbstractSerializable {
  channelSpec: ChannelSpec;
  entitlements: ChannelEntitlements;

  constructor() {
    super();
  }

  public getChannelSpec(): ChannelSpec {
    return this.channelSpec;
  }

  public setChannelSpec(channelSpec: ChannelSpec) {
    this.channelSpec = channelSpec;
  }

  public getEntitlements(): ChannelEntitlements {
    return this.entitlements;
  }

  public setEntitlements(entitlements: ChannelEntitlements) {
    this.entitlements = entitlements;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const channelSpec = json["channelSpec"];
    if (channelSpec != null) {
      const convertedChannelSpec0 = Serializer.fillFromJsonObjectWithTypeTag(
        channelSpec,
        "__type",
        factory
      );
      this.setChannelSpec(convertedChannelSpec0);
    }
    const entitlements = json["entitlements"];
    if (entitlements != null) {
      const convertedEntitlements0 = Serializer.fillFromJsonObjectWithTypeTag(
        entitlements,
        "__type",
        factory
      );
      this.setEntitlements(convertedEntitlements0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const channelSpec = this.getChannelSpec();
    if (channelSpec != null) {
      const convertedChannelSpec0 = channelSpec.getJson(JsonTypeTagType.TYPE);
      json["channelSpec"] = convertedChannelSpec0;
    }
    const entitlements = this.getEntitlements();
    if (entitlements != null) {
      const convertedEntitlements0 = entitlements.getJson(JsonTypeTagType.TYPE);
      json["entitlements"] = convertedEntitlements0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.channel.ChannelInfo";
  }
}
