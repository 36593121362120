/**
 * 自动生成， 请不要手工修改！
 */

import {CoworkUserInfo} from '../cowork/CoworkUserInfo'
import {EnterpriseServiceOrder} from '../business/order/EnterpriseServiceOrder'
import {GeneralCoworkUserInfo} from '../cowork/GeneralCoworkUserInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractActivityUserInfo extends GeneralCoworkUserInfo {
    participated: boolean;
    targetAssociationMember: boolean;
    enterpriseId: (number | string);
    serviceOrder: EnterpriseServiceOrder;

    constructor() {
        super();
    }

    public isParticipated(): boolean {
        return this.participated;
    }

    public setParticipated(participated: boolean) {
        this.participated = participated;
    }

    public isTargetAssociationMember(): boolean {
        return this.targetAssociationMember;
    }

    public setTargetAssociationMember(targetAssociationMember: boolean) {
        this.targetAssociationMember = targetAssociationMember;
    }

    public getEnterpriseId(): (number | string) {
        return this.enterpriseId;
    }

    public setEnterpriseId(enterpriseId: (number | string)) {
        this.enterpriseId = enterpriseId;
    }

    public getServiceOrder(): EnterpriseServiceOrder {
        return this.serviceOrder;
    }

    public setServiceOrder(serviceOrder: EnterpriseServiceOrder) {
        this.serviceOrder = serviceOrder;
    }


    abstract isActive(): boolean;

    abstract isPulled(): boolean;

    abstract getPrizable(): boolean;

    abstract getApproverPrize(): number;

    abstract getCreatorPrize(): number;

    abstract getRelApproverPrize(): number;

    abstract getRelCreatorPrize(): number;

    abstract addApproverPrize(arg0: (number | null));

    abstract addCreatorPrize(arg0: (number | null));

    abstract setActive(active: (boolean | null));

    abstract setApproverPrize(approverPrize: (number | null));

    abstract setCreatorPrize(creatorPrize: (number | null));

    abstract setPrizable(prizable: (boolean | null));

    abstract setPulled();

    abstract setRelCreatorPrize(relCreatorPrize: (number | null));

    abstract update(coworkUserInfo: (CoworkUserInfo | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let participated = json["participated"];
        if (participated != null) {
            this.setParticipated(participated);
        }
        let targetAssociationMember = json["targetAssociationMember"];
        if (targetAssociationMember != null) {
            this.setTargetAssociationMember(targetAssociationMember);
        }
        let enterpriseId = json["enterpriseId"];
        if (enterpriseId != null) {
            this.setEnterpriseId(enterpriseId);
        }
        let serviceOrder = json["serviceOrder"];
        if (serviceOrder != null) {
            let convertedServiceOrder0 = new EnterpriseServiceOrder();
            convertedServiceOrder0.fillFromJson(serviceOrder, factory);
            this.setServiceOrder(convertedServiceOrder0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let participated = this.isParticipated();
        if (participated != null) {
            json["participated"] = participated;
        }
        let targetAssociationMember = this.isTargetAssociationMember();
        if (targetAssociationMember != null) {
            json["targetAssociationMember"] = targetAssociationMember;
        }
        let enterpriseId = this.getEnterpriseId();
        if (enterpriseId != null) {
            json["enterpriseId"] = String(enterpriseId);
        }
        let serviceOrder = this.getServiceOrder();
        if (serviceOrder != null) {
            let convertedServiceOrder0 = serviceOrder.getJson(JsonTypeTagType.NONE);
            json["serviceOrder"] = convertedServiceOrder0;
        }

        if (includeTypeTag) {
            json["__type"] = "ActivityUserInfo";
        }
    }


    public getTypeId(): string {
        return "ActivityUserInfo";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.activity.ActivityUserInfo";
    }
}
