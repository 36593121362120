/**
 * 自动生成， 请不要手工修改！
 */

import {EntityInfo} from '../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../keyword/KeywordEntity'
import {MessageType} from '../../../message/MessageContentType'
import {QllCountry} from '../../international/QllCountry'
import {TypeFactory} from '../../../serialize/TypeFactory'
import {UserAdDirection} from '../UserAdDirection'
import {UserAd} from '../UserAd'


export abstract class AbstractGeneralServiceRequestUserAd extends UserAd  {
    expectedTime: (number | string);
    adDirection: UserAdDirection;

    constructor() {
    	super();
    }

    public getExpectedTime(): (number | string) {
    	return this.expectedTime;
    }

    public setExpectedTime(expectedTime: (number | string)) {
    	this.expectedTime = expectedTime;
    }

    public getAdDirection(): UserAdDirection {
    	return this.adDirection;
    }

    public setAdDirection(adDirection: UserAdDirection) {
    	this.adDirection = adDirection;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let expectedTime = json["expectedTime"];
        if (expectedTime != null) {
            this.setExpectedTime(expectedTime);
        }
        let adDirection = json["adDirection"];
        if (adDirection != null) {
            let convertedAdDirection0 = UserAdDirection[<string>adDirection];
            this.setAdDirection(convertedAdDirection0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let expectedTime = this.getExpectedTime();
        if (expectedTime != null) {
            json["expectedTime"] = String(expectedTime);
        }
        let adDirection = this.getAdDirection();
        if (adDirection != null) {
            let convertedAdDirection0 = UserAdDirection[adDirection];
            json["adDirection"] = convertedAdDirection0;
        }

        if (includeTypeTag) {
            json["__type"] = "GeneralServiceRequestUserAd";
        }
    }


    public getTypeId(): string {
    	return "GeneralServiceRequestUserAd";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.userad.general.GeneralServiceRequestUserAd";
    }
}
