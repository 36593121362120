/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../../serialize/AbstractJsonSerializable";
import { Filter } from "../../Filter";
import { JsonTypeTagType } from "../../../../serialize/JsonTypeTagType";
import { Location } from "../../../geolocation/Location";
import { TypeFactory } from "../../../../serialize/TypeFactory";

export class QAFilter extends AbstractSerializable implements Filter {
  questionId: number | string;
  location: Location;
  radius: number;
  mediumEntityId: number | string;
  keywords: Array<string>;
  unanswered: boolean;
  answerId: number | string;
  regionId: number | string;

  constructor() {
    super();
  }

  public getQuestionId(): number | string {
    return this.questionId;
  }

  public setQuestionId(questionId: number | string) {
    this.questionId = questionId;
  }

  public getLocation(): Location {
    return this.location;
  }

  public setLocation(location: Location) {
    this.location = location;
  }

  public getRadius(): number {
    return this.radius;
  }

  public setRadius(radius: number) {
    this.radius = radius;
  }

  public getMediumEntityId(): number | string {
    return this.mediumEntityId;
  }

  public setMediumEntityId(mediumEntityId: number | string) {
    this.mediumEntityId = mediumEntityId;
  }

  public getKeywords(): Array<string> {
    return this.keywords;
  }

  public setKeywords(keywords: Array<string>) {
    this.keywords = keywords;
  }

  public getUnanswered(): boolean {
    return this.unanswered;
  }

  public setUnanswered(unanswered: boolean) {
    this.unanswered = unanswered;
  }

  public getAnswerId(): number | string {
    return this.answerId;
  }

  public setAnswerId(answerId: number | string) {
    this.answerId = answerId;
  }

  public getRegionId(): number | string {
    return this.regionId;
  }

  public setRegionId(regionId: number | string) {
    this.regionId = regionId;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const questionId = json["questionId"];
    if (questionId != null) {
      this.setQuestionId(questionId);
    }
    const location = json["location"];
    if (location != null) {
      const convertedLocation0 = new Location();
      convertedLocation0.fillFromJson(location, factory);
      this.setLocation(convertedLocation0);
    }
    const radius = json["radius"];
    if (radius != null) {
      this.setRadius(radius);
    }
    const mediumEntityId = json["mediumEntityId"];
    if (mediumEntityId != null) {
      this.setMediumEntityId(mediumEntityId);
    }
    let keywords = json["keywords"];
    if (keywords != null) {
      const convertedKeywords0 = [];
      keywords = keywords[1];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      this.setKeywords(convertedKeywords0);
    }
    const unanswered = json["unanswered"];
    if (unanswered != null) {
      this.setUnanswered(unanswered);
    }
    const answerId = json["answerId"];
    if (answerId != null) {
      this.setAnswerId(answerId);
    }
    const regionId = json["regionId"];
    if (regionId != null) {
      this.setRegionId(regionId);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const questionId = this.getQuestionId();
    if (questionId != null) {
      json["questionId"] = String(questionId);
    }
    const location = this.getLocation();
    if (location != null) {
      const convertedLocation0 = location.getJson(JsonTypeTagType.NONE);
      json["location"] = convertedLocation0;
    }
    const radius = this.getRadius();
    if (radius != null) {
      json["radius"] = radius;
    }
    const mediumEntityId = this.getMediumEntityId();
    if (mediumEntityId != null) {
      json["mediumEntityId"] = String(mediumEntityId);
    }
    const keywords = this.getKeywords();
    if (keywords != null) {
      const convertedKeywords0 = [];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      const convertedKeywordsWithType1 = [
        "java.util.ArrayList",
        convertedKeywords0,
      ];
      json["keywords"] = convertedKeywordsWithType1;
    }
    const unanswered = this.getUnanswered();
    if (unanswered != null) {
      json["unanswered"] = unanswered;
    }
    const answerId = this.getAnswerId();
    if (answerId != null) {
      json["answerId"] = String(answerId);
    }
    const regionId = this.getRegionId();
    if (regionId != null) {
      json["regionId"] = String(regionId);
    }

    if (includeTypeTag) {
      json["__type"] = "QAFilter";
    }
  }

  public getTypeId(): string {
    return "QAFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.ipsphere.qa.QAFilter";
  }
}
