/**
 * 自动生成， 请不要手工修改！
 */

export enum AppealType {
  INTEREST,
  CONSULT,
  MEDIATE,
  ADVISE,
  FOR_ADVISE,
  RE_ADVISE,
  WISH,
  REPORT,
  STANDARD,
}
