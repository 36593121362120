/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkType } from "./CoworkType";
import { EntityInfo } from "../entity/EntityInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class CoworkReplyInfo extends AbstractSerializable {
  reply: string;
  replyType: CoworkType;
  imageUrls: Array<string>;
  replierInfo: EntityInfo;
  replyTime: number | string;

  constructor() {
    super();
  }

  public getReply(): string {
    return this.reply;
  }

  public setReply(reply: string) {
    this.reply = reply;
  }

  public getReplyType(): CoworkType {
    return this.replyType;
  }

  public setReplyType(replyType: CoworkType) {
    this.replyType = replyType;
  }

  public getImageUrls(): Array<string> {
    return this.imageUrls;
  }

  public setImageUrls(imageUrls: Array<string>) {
    this.imageUrls = imageUrls;
  }

  public getReplierInfo(): EntityInfo {
    return this.replierInfo;
  }

  public setReplierInfo(replierInfo: EntityInfo) {
    this.replierInfo = replierInfo;
  }

  public getReplyTime(): number | string {
    return this.replyTime;
  }

  public setReplyTime(replyTime: number | string) {
    this.replyTime = replyTime;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const reply = json["reply"];
    if (reply != null) {
      this.setReply(reply);
    }
    const replyType = json["replyType"];
    if (replyType != null) {
      const convertedReplyType0 = CoworkType[<string>replyType];
      this.setReplyType(convertedReplyType0);
    }
    let imageUrls = json["imageUrls"];
    if (imageUrls != null) {
      const convertedImageUrls0 = [];
      imageUrls = imageUrls[1];
      for (const i in imageUrls) {
        const convertedImageUrls10 = imageUrls[i];
        convertedImageUrls0.push(convertedImageUrls10);
      }
      this.setImageUrls(convertedImageUrls0);
    }
    const replierInfo = json["replierInfo"];
    if (replierInfo != null) {
      const convertedReplierInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        replierInfo,
        "__type",
        factory
      );
      this.setReplierInfo(convertedReplierInfo0);
    }
    const replyTime = json["replyTime"];
    if (replyTime != null) {
      this.setReplyTime(replyTime);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const reply = this.getReply();
    if (reply != null) {
      json["reply"] = reply;
    }
    const replyType = this.getReplyType();
    if (replyType != null) {
      const convertedReplyType0 = CoworkType[replyType];
      json["replyType"] = convertedReplyType0;
    }
    const imageUrls = this.getImageUrls();
    if (imageUrls != null) {
      const convertedImageUrls0 = [];
      for (const i in imageUrls) {
        const convertedImageUrls10 = imageUrls[i];
        convertedImageUrls0.push(convertedImageUrls10);
      }
      const convertedImageUrlsWithType1 = [
        "java.util.ArrayList",
        convertedImageUrls0,
      ];
      json["imageUrls"] = convertedImageUrlsWithType1;
    }
    const replierInfo = this.getReplierInfo();
    if (replierInfo != null) {
      const convertedReplierInfo0 = replierInfo.getJson(JsonTypeTagType.TYPE);
      json["replierInfo"] = convertedReplierInfo0;
    }
    const replyTime = this.getReplyTime();
    if (replyTime != null) {
      json["replyTime"] = String(replyTime);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.CoworkReplyInfo";
  }
}
