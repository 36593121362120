/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractEntityMessage} from "./api/AbstractEntityMessage";
import {MessageType} from "../MessageContentType";
import {TypeFactory} from "../../serialize/TypeFactory";
import { LinkType } from "@/services/message/entity/api/LinkType";

export abstract class AbstractURLMessage extends AbstractEntityMessage {
    dataContentType: MessageType;
    linkType: LinkType;
  link: string;
  fileName: string;
  fileSize: number | string;

  constructor() {
    super();
  }

    public getDataContentType(): MessageType {
        return this.dataContentType;
    }

    public setDataContentType(dataContentType: MessageType) {
        this.dataContentType = dataContentType;
    }

  public getLinkType(): LinkType {
    return this.linkType;
  }

  public setLinkType(linkType: LinkType) {
    this.linkType = linkType;
  }

  public getLink(): string {
    return this.link;
  }

  public setLink(link: string) {
    this.link = link;
  }

  public getFileName(): string {
    return this.fileName;
  }

  public setFileName(fileName: string) {
    this.fileName = fileName;
  }

  public getFileSize(): number | string {
    return this.fileSize;
  }

  public setFileSize(fileSize: number | string) {
    this.fileSize = fileSize;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const dataContentType = json["dataContentType"];
    if (dataContentType != null) {
        const convertedDataContentType0 =
          MessageType[<string>dataContentType];
      this.setDataContentType(convertedDataContentType0);
    }
    const linkType = json["linkType"];
    if (linkType != null) {
      const convertedLinkType0 = LinkType[<string>linkType];
      this.setLinkType(convertedLinkType0);
    }
    const link = json["link"];
    if (link != null) {
      this.setLink(link);
    }
    const fileName = json["fileName"];
    if (fileName != null) {
      this.setFileName(fileName);
    }
    const fileSize = json["fileSize"];
    if (fileSize != null) {
      this.setFileSize(fileSize);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const dataContentType = this.getDataContentType();
    if (dataContentType != null) {
        const convertedDataContentType0 = MessageType[dataContentType];
      json["dataContentType"] = convertedDataContentType0;
    }
    const linkType = this.getLinkType();
    if (linkType != null) {
      const convertedLinkType0 = LinkType[linkType];
      json["linkType"] = convertedLinkType0;
    }
    const link = this.getLink();
    if (link != null) {
      json["link"] = link;
    }
    const fileName = this.getFileName();
    if (fileName != null) {
      json["fileName"] = fileName;
    }
    const fileSize = this.getFileSize();
    if (fileSize != null) {
      json["fileSize"] = String(fileSize);
    }

    if (includeTypeTag) {
      json["__type"] = "Url";
    }
  }

  public getTypeId(): string {
    return "Url";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.entity.URLMessage";
  }
}
