/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {OffsetItem} from './OffsetItem'
import {PageBlockAccessSpec} from './PageBlockAccessSpec'
import {PageDataSpec} from './PageDataSpec'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractOffsetPageDataSpec extends AbstractSerializable implements PageDataSpec {
    globalTopicOffset: OffsetItem;
    globalEnterpriseOffSet: OffsetItem;
    localTopicOffset: OffsetItem;
    localEnterpriseOffset: OffsetItem;

    constructor() {
        super();
    }

    public getGlobalTopicOffset(): OffsetItem {
        return this.globalTopicOffset;
    }

    public setGlobalTopicOffset(globalTopicOffset: OffsetItem) {
        this.globalTopicOffset = globalTopicOffset;
    }

    public getGlobalEnterpriseOffSet(): OffsetItem {
        return this.globalEnterpriseOffSet;
    }

    public setGlobalEnterpriseOffSet(globalEnterpriseOffSet: OffsetItem) {
        this.globalEnterpriseOffSet = globalEnterpriseOffSet;
    }

    public getLocalTopicOffset(): OffsetItem {
        return this.localTopicOffset;
    }

    public setLocalTopicOffset(localTopicOffset: OffsetItem) {
        this.localTopicOffset = localTopicOffset;
    }

    public getLocalEnterpriseOffset(): OffsetItem {
        return this.localEnterpriseOffset;
    }

    public setLocalEnterpriseOffset(localEnterpriseOffset: OffsetItem) {
        this.localEnterpriseOffset = localEnterpriseOffset;
    }


    abstract isRecordsRemaining(): boolean;

    abstract getForwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getForwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract getNextBackwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getNextBackwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract addPageDataSpec(arg0: (PageDataSpec | null), arg1: (boolean | null)): PageDataSpec;

    abstract getContinuousNoDataCount(): number;

    abstract getEstimatedTotalRecords(): number;

    abstract getFetchedSize(): number;

    abstract getNextStart(): number;

    abstract getStartPosition(): number;

    abstract setEstimatedTotalRecords(arg0: (number | null));

    abstract setRecordsRemaining(arg0: (boolean | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let globalTopicOffset = json["globalTopicOffset"];
        if (globalTopicOffset != null) {
            let convertedGlobalTopicOffset0 = new OffsetItem();
            convertedGlobalTopicOffset0.fillFromJson(globalTopicOffset, factory);
            this.setGlobalTopicOffset(convertedGlobalTopicOffset0);
        }
        let globalEnterpriseOffSet = json["globalEnterpriseOffSet"];
        if (globalEnterpriseOffSet != null) {
            let convertedGlobalEnterpriseOffSet0 = new OffsetItem();
            convertedGlobalEnterpriseOffSet0.fillFromJson(globalEnterpriseOffSet, factory);
            this.setGlobalEnterpriseOffSet(convertedGlobalEnterpriseOffSet0);
        }
        let localTopicOffset = json["localTopicOffset"];
        if (localTopicOffset != null) {
            let convertedLocalTopicOffset0 = new OffsetItem();
            convertedLocalTopicOffset0.fillFromJson(localTopicOffset, factory);
            this.setLocalTopicOffset(convertedLocalTopicOffset0);
        }
        let localEnterpriseOffset = json["localEnterpriseOffset"];
        if (localEnterpriseOffset != null) {
            let convertedLocalEnterpriseOffset0 = new OffsetItem();
            convertedLocalEnterpriseOffset0.fillFromJson(localEnterpriseOffset, factory);
            this.setLocalEnterpriseOffset(convertedLocalEnterpriseOffset0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let globalTopicOffset = this.getGlobalTopicOffset();
        if (globalTopicOffset != null) {
            let convertedGlobalTopicOffset0 = globalTopicOffset.getJson(JsonTypeTagType.NONE);
            json["globalTopicOffset"] = convertedGlobalTopicOffset0;
        }
        let globalEnterpriseOffSet = this.getGlobalEnterpriseOffSet();
        if (globalEnterpriseOffSet != null) {
            let convertedGlobalEnterpriseOffSet0 = globalEnterpriseOffSet.getJson(JsonTypeTagType.NONE);
            json["globalEnterpriseOffSet"] = convertedGlobalEnterpriseOffSet0;
        }
        let localTopicOffset = this.getLocalTopicOffset();
        if (localTopicOffset != null) {
            let convertedLocalTopicOffset0 = localTopicOffset.getJson(JsonTypeTagType.NONE);
            json["localTopicOffset"] = convertedLocalTopicOffset0;
        }
        let localEnterpriseOffset = this.getLocalEnterpriseOffset();
        if (localEnterpriseOffset != null) {
            let convertedLocalEnterpriseOffset0 = localEnterpriseOffset.getJson(JsonTypeTagType.NONE);
            json["localEnterpriseOffset"] = convertedLocalEnterpriseOffset0;
        }

        if (includeTypeTag) {
            json["__type"] = "OffsetPageDataSpec";
        }
    }


    public getTypeId(): string {
        return "OffsetPageDataSpec";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.paging.OffsetPageDataSpec";
    }
}
