/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {CompositeEnterpriseServiceTradableInfo} from './CompositeEnterpriseServiceTradableInfo'
import {InfoSectionDesc} from '../../../entity/topic/InfoSectionDesc'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {Serializer} from '../../../../serialize/Serializer'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class ServiceTradableSeriesDisplaySpec extends AbstractSerializable {
    seriesId: (number | string);
    currentPkgId: (number | string);
    currentPkgIndex: number;
    servicePackageInSeries: Array<CompositeEnterpriseServiceTradableInfo>;
    customerServiceSpec: InfoSectionDesc;
    showServiceSuppliers: boolean;

    constructor() {
        super();
    }

    public getSeriesId(): (number | string) {
        return this.seriesId;
    }

    public setSeriesId(seriesId: (number | string)) {
        this.seriesId = seriesId;
    }

    public getCurrentPkgId(): (number | string) {
        return this.currentPkgId;
    }

    public setCurrentPkgId(currentPkgId: (number | string)) {
        this.currentPkgId = currentPkgId;
    }

    public getCurrentPkgIndex(): number {
        return this.currentPkgIndex;
    }

    public setCurrentPkgIndex(currentPkgIndex: number) {
        this.currentPkgIndex = currentPkgIndex;
    }

    public getServicePackageInSeries(): Array<CompositeEnterpriseServiceTradableInfo> {
        return this.servicePackageInSeries;
    }

    public setServicePackageInSeries(servicePackageInSeries: Array<CompositeEnterpriseServiceTradableInfo>) {
        this.servicePackageInSeries = servicePackageInSeries;
    }

    public getCustomerServiceSpec(): InfoSectionDesc {
        return this.customerServiceSpec;
    }

    public setCustomerServiceSpec(customerServiceSpec: InfoSectionDesc) {
        this.customerServiceSpec = customerServiceSpec;
    }

    public isShowServiceSuppliers(): boolean {
        return this.showServiceSuppliers;
    }

    public setShowServiceSuppliers(showServiceSuppliers: boolean) {
        this.showServiceSuppliers = showServiceSuppliers;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let seriesId = json["seriesId"];
        if (seriesId != null) {
            this.setSeriesId(seriesId);
        }
        let currentPkgId = json["currentPkgId"];
        if (currentPkgId != null) {
            this.setCurrentPkgId(currentPkgId);
        }
        let currentPkgIndex = json["currentPkgIndex"];
        if (currentPkgIndex != null) {
            this.setCurrentPkgIndex(currentPkgIndex);
        }
        let servicePackageInSeries = json["servicePackageInSeries"];
        if (servicePackageInSeries != null) {
            let convertedServicePackageInSeries0 = [];
            servicePackageInSeries = servicePackageInSeries[1];
            for (let i in servicePackageInSeries) {
                let convertedServicePackageInSeries10 = servicePackageInSeries[i];
                let convertedServicePackageInSeries1 = Serializer.fillFromJsonObjectWithTypeTag(convertedServicePackageInSeries10, "__type", factory);
                convertedServicePackageInSeries0.push(convertedServicePackageInSeries1);
            }
            this.setServicePackageInSeries(convertedServicePackageInSeries0);
        }
        let customerServiceSpec = json["customerServiceSpec"];
        if (customerServiceSpec != null) {
            let convertedCustomerServiceSpec0 = new InfoSectionDesc();
            convertedCustomerServiceSpec0.fillFromJson(customerServiceSpec, factory);
            this.setCustomerServiceSpec(convertedCustomerServiceSpec0);
        }
        let showServiceSuppliers = json["showServiceSuppliers"];
        if (showServiceSuppliers != null) {
            this.setShowServiceSuppliers(showServiceSuppliers);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let seriesId = this.getSeriesId();
        if (seriesId != null) {
            json["seriesId"] = String(seriesId);
        }
        let currentPkgId = this.getCurrentPkgId();
        if (currentPkgId != null) {
            json["currentPkgId"] = String(currentPkgId);
        }
        let currentPkgIndex = this.getCurrentPkgIndex();
        if (currentPkgIndex != null) {
            json["currentPkgIndex"] = currentPkgIndex;
        }
        let servicePackageInSeries = this.getServicePackageInSeries();
        if (servicePackageInSeries != null) {
            let convertedServicePackageInSeries0 = [];
            for (let i in servicePackageInSeries) {
                let convertedServicePackageInSeries10 = servicePackageInSeries[i];
                let convertedServicePackageInSeries1 = convertedServicePackageInSeries10.getJson(JsonTypeTagType.TYPE);
                convertedServicePackageInSeries0.push(convertedServicePackageInSeries1);
            }
            let convertedServicePackageInSeriesWithType1 = ["java.util.ArrayList", convertedServicePackageInSeries0];
            json["servicePackageInSeries"] = convertedServicePackageInSeriesWithType1;
        }
        let customerServiceSpec = this.getCustomerServiceSpec();
        if (customerServiceSpec != null) {
            let convertedCustomerServiceSpec0 = customerServiceSpec.getJson(JsonTypeTagType.NONE);
            json["customerServiceSpec"] = convertedCustomerServiceSpec0;
        }
        let showServiceSuppliers = this.isShowServiceSuppliers();
        if (showServiceSuppliers != null) {
            json["showServiceSuppliers"] = showServiceSuppliers;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.service.tradable.ServiceTradableSeriesDisplaySpec";
    }
}
