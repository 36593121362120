/**
 * Created by Zhonghua on 14/11/2016.
 */

import { JsonTypeTagType } from "./JsonTypeTagType";
import { TypeFactory } from "./TypeFactory";

export const TYPE_ID_GETTER = "getTypeId";
export const TYPE_TAG = "__type";

export const JAVA_LONG = "java.lang.Long";
export const JAVA_STRING = "java.lang.string";
export const JAVA_BOOLEAN = "java.lang.Boolean";
export const JAVA_ARRAY_LIST = "java.util.ArrayList";
export const JAVA_HASH_MAP = "java.util.HashMap";
export const JAVA_OBJECT_ARRAY = "[Ljava.lang.Object;";
export const JAVA_OBJECT = "java.lang.Object";

export interface JsonSerializable {
  fillFromJson(json: any, factory?: TypeFactory);
  fillToJson(json: Object, includeTypeTag: boolean);
  getJson(tagType: JsonTypeTagType);
}
