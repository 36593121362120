/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {PageBlockAccessSpec} from './PageBlockAccessSpec'
import {PageDataSpec} from './PageDataSpec'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractCompositePageDataSpec extends AbstractSerializable implements PageDataSpec {
    leftPageDataSpec: PageDataSpec;
    rightPageDataSpec: PageDataSpec;

    constructor() {
        super();
    }

    public getLeftPageDataSpec(): PageDataSpec {
        return this.leftPageDataSpec;
    }

    public setLeftPageDataSpec(leftPageDataSpec: PageDataSpec) {
        this.leftPageDataSpec = leftPageDataSpec;
    }

    public getRightPageDataSpec(): PageDataSpec {
        return this.rightPageDataSpec;
    }

    public setRightPageDataSpec(rightPageDataSpec: PageDataSpec) {
        this.rightPageDataSpec = rightPageDataSpec;
    }


    abstract isRecordsRemaining(): boolean;

    abstract getForwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getForwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract getNextBackwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getNextBackwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract addPageDataSpec(arg0: (PageDataSpec | null), arg1: (boolean | null)): PageDataSpec;

    abstract getContinuousNoDataCount(): number;

    abstract getEstimatedTotalRecords(): number;

    abstract getFetchedSize(): number;

    abstract getNextStart(): number;

    abstract getStartPosition(): number;

    abstract setEstimatedTotalRecords(arg0: (number | null));

    abstract setRecordsRemaining(arg0: (boolean | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let leftPageDataSpec = json["leftPageDataSpec"];
        if (leftPageDataSpec != null) {
            let convertedLeftPageDataSpec0 = Serializer.fillFromJsonObjectWithTypeTag(leftPageDataSpec, "__type", factory);
            this.setLeftPageDataSpec(convertedLeftPageDataSpec0);
        }
        let rightPageDataSpec = json["rightPageDataSpec"];
        if (rightPageDataSpec != null) {
            let convertedRightPageDataSpec0 = Serializer.fillFromJsonObjectWithTypeTag(rightPageDataSpec, "__type", factory);
            this.setRightPageDataSpec(convertedRightPageDataSpec0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let leftPageDataSpec = this.getLeftPageDataSpec();
        if (leftPageDataSpec != null) {
            let convertedLeftPageDataSpec0 = leftPageDataSpec.getJson(JsonTypeTagType.TYPE);
            json["leftPageDataSpec"] = convertedLeftPageDataSpec0;
        }
        let rightPageDataSpec = this.getRightPageDataSpec();
        if (rightPageDataSpec != null) {
            let convertedRightPageDataSpec0 = rightPageDataSpec.getJson(JsonTypeTagType.TYPE);
            json["rightPageDataSpec"] = convertedRightPageDataSpec0;
        }

        if (includeTypeTag) {
            json["__type"] = "CompositePageDataSpec";
        }
    }


    public getTypeId(): string {
        return "CompositePageDataSpec";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.paging.CompositePageDataSpec";
    }
}
