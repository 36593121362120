// @ts-nocheck
import { AbstractActivityStats } from "./AbstractActivityStats";
import { CoworkSimpleView } from "../cowork/CoworkSimpleView";
import { CoworkStats } from "../cowork/CoworkStats";

export class ActivityStats extends AbstractActivityStats {
  constructor() {
    super();
  }

  addInfoToView(arg0: CoworkSimpleView | null) {
    // Manual Code Here
  }
  updateCoworkStats(arg0: CoworkStats | null) {
    // Manual Code Here
  }
}
