/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {EntityInfo} from './EntityInfo'
import {EntityStatus} from './EntityStatus'
import {EntityType} from './EntityType'
import {GeoHash} from '../geolocation/GeoHash'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {MessageType} from '../../message/MessageContentType'
import {MessageContent} from '../../message/MessageContent'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractSimpleEntityDesc extends AbstractSerializable implements MessageContent  {
    nid: (number | string);
    entityType: EntityType;
    sid: string;
    createTime: (number | string);
    lat: number;
    lng: number;
    locationName: string;
    cityName: string;
    geohash: GeoHash;
    regionId: (number | string);
    parentId: (number | string);
    parentName: string;
    superTargetIds: (number | string)[];
    children: Array<EntityInfo>;
    imageUrl: string;
    showImageUrls: Array<string>;
    name: string;
    nickName: string;
    description: string;
    keywords: Array<string>;
    entityStatus: EntityStatus;

    constructor() {
    	super();
    }

    public getNid(): (number | string) {
    	return this.nid;
    }

    public setNid(nid: (number | string)) {
    	this.nid = nid;
    }

    public getEntityType(): EntityType {
    	return this.entityType;
    }

    public setEntityType(entityType: EntityType) {
    	this.entityType = entityType;
    }

    public getSid(): string {
    	return this.sid;
    }

    public setSid(sid: string) {
    	this.sid = sid;
    }

    public getCreateTime(): (number | string) {
    	return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
    	this.createTime = createTime;
    }

    public getLat(): number {
    	return this.lat;
    }

    public setLat(lat: number) {
    	this.lat = lat;
    }

    public getLng(): number {
    	return this.lng;
    }

    public setLng(lng: number) {
    	this.lng = lng;
    }

    public getLocationName(): string {
    	return this.locationName;
    }

    public setLocationName(locationName: string) {
    	this.locationName = locationName;
    }

    public getCityName(): string {
    	return this.cityName;
    }

    public setCityName(cityName: string) {
    	this.cityName = cityName;
    }

    public getGeohash(): GeoHash {
    	return this.geohash;
    }

    public setGeohash(geohash: GeoHash) {
    	this.geohash = geohash;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getParentId(): (number | string) {
    	return this.parentId;
    }

    public setParentId(parentId: (number | string)) {
    	this.parentId = parentId;
    }

    public getParentName(): string {
    	return this.parentName;
    }

    public setParentName(parentName: string) {
    	this.parentName = parentName;
    }

    public getSuperTargetIds(): (number | string)[] {
    	return this.superTargetIds;
    }

    public setSuperTargetIds(superTargetIds: (number | string)[]) {
    	this.superTargetIds = superTargetIds;
    }

    public getChildren(): Array<EntityInfo> {
    	return this.children;
    }

    public setChildren(children: Array<EntityInfo>) {
    	this.children = children;
    }

    public getImageUrl(): string {
    	return this.imageUrl;
    }

    public setImageUrl(imageUrl: string) {
    	this.imageUrl = imageUrl;
    }

    public getShowImageUrls(): Array<string> {
    	return this.showImageUrls;
    }

    public setShowImageUrls(showImageUrls: Array<string>) {
    	this.showImageUrls = showImageUrls;
    }

    public getName(): string {
    	return this.name;
    }

    public setName(name: string) {
    	this.name = name;
    }

    public getNickName(): string {
    	return this.nickName;
    }

    public setNickName(nickName: string) {
    	this.nickName = nickName;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getKeywords(): Array<string> {
    	return this.keywords;
    }

    public setKeywords(keywords: Array<string>) {
    	this.keywords = keywords;
    }

    public getEntityStatus(): EntityStatus {
    	return this.entityStatus;
    }

    public setEntityStatus(entityStatus: EntityStatus) {
    	this.entityStatus = entityStatus;
    }


    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let nid = json["nid"];
        if (nid != null) {
            this.setNid(nid);
        }
        let entityType = json["entityType"];
        if (entityType != null) {
            let convertedEntityType0 = EntityType[<string>entityType];
            this.setEntityType(convertedEntityType0);
        }
        let sid = json["sid"];
        if (sid != null) {
            this.setSid(sid);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let lat = json["lat"];
        if (lat != null) {
            this.setLat(lat);
        }
        let lng = json["lng"];
        if (lng != null) {
            this.setLng(lng);
        }
        let locationName = json["locationName"];
        if (locationName != null) {
            this.setLocationName(locationName);
        }
        let cityName = json["cityName"];
        if (cityName != null) {
            this.setCityName(cityName);
        }
        let geohash = json["geohash"];
        if (geohash != null) {
            let convertedGeohash0 = new GeoHash();
            convertedGeohash0.fillFromJson(geohash, factory);
            this.setGeohash(convertedGeohash0);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let parentId = json["parentId"];
        if (parentId != null) {
            this.setParentId(parentId);
        }
        let parentName = json["parentName"];
        if (parentName != null) {
            this.setParentName(parentName);
        }
        let superTargetIds = json["superTargetIds"];
        if (superTargetIds != null) {
            let convertedSuperTargetIds0 = [];
            for (let i in superTargetIds) {
                let convertedSuperTargetIds10 = superTargetIds[i];
                convertedSuperTargetIds0.push(convertedSuperTargetIds10);
            }
            this.setSuperTargetIds(convertedSuperTargetIds0);
        }
        let children = json["children"];
        if (children != null) {
            let convertedChildren0 = [];
            children = children[1];
            for (let i in children) {
                let convertedChildren10 = children[i];
                let convertedChildren1 = Serializer.fillFromJsonObjectWithTypeTag(convertedChildren10, "__type", factory);
                convertedChildren0.push(convertedChildren1);
            }
            this.setChildren(convertedChildren0);
        }
        let imageUrl = json["imageUrl"];
        if (imageUrl != null) {
            this.setImageUrl(imageUrl);
        }
        let showImageUrls = json["showImageUrls"];
        if (showImageUrls != null) {
            let convertedShowImageUrls0 = [];
            showImageUrls = showImageUrls[1];
            for (let i in showImageUrls) {
                let convertedShowImageUrls10 = showImageUrls[i];
                convertedShowImageUrls0.push(convertedShowImageUrls10);
            }
            this.setShowImageUrls(convertedShowImageUrls0);
        }
        let name = json["name"];
        if (name != null) {
            this.setName(name);
        }
        let nickName = json["nickName"];
        if (nickName != null) {
            this.setNickName(nickName);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let keywords = json["keywords"];
        if (keywords != null) {
            let convertedKeywords0 = [];
            keywords = keywords[1];
            for (let i in keywords) {
                let convertedKeywords10 = keywords[i];
                convertedKeywords0.push(convertedKeywords10);
            }
            this.setKeywords(convertedKeywords0);
        }
        let entityStatus = json["entityStatus"];
        if (entityStatus != null) {
            let convertedEntityStatus0 = EntityStatus[<string>entityStatus];
            this.setEntityStatus(convertedEntityStatus0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let nid = this.getNid();
        if (nid != null) {
            json["nid"] = String(nid);
        }
        let entityType = this.getEntityType();
        if (entityType != null) {
            let convertedEntityType0 = EntityType[entityType];
            json["entityType"] = convertedEntityType0;
        }
        let sid = this.getSid();
        if (sid != null) {
            json["sid"] = sid;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let lat = this.getLat();
        if (lat != null) {
            json["lat"] = lat;
        }
        let lng = this.getLng();
        if (lng != null) {
            json["lng"] = lng;
        }
        let locationName = this.getLocationName();
        if (locationName != null) {
            json["locationName"] = locationName;
        }
        let cityName = this.getCityName();
        if (cityName != null) {
            json["cityName"] = cityName;
        }
        let geohash = this.getGeohash();
        if (geohash != null) {
            let convertedGeohash0 = geohash.getJson(JsonTypeTagType.NONE);
            json["geohash"] = convertedGeohash0;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let parentId = this.getParentId();
        if (parentId != null) {
            json["parentId"] = String(parentId);
        }
        let parentName = this.getParentName();
        if (parentName != null) {
            json["parentName"] = parentName;
        }
        let superTargetIds = this.getSuperTargetIds();
        if (superTargetIds != null) {
            let convertedSuperTargetIds0 = [];
            for (let i in superTargetIds) {
                let convertedSuperTargetIds10 = superTargetIds[i];
                convertedSuperTargetIds0.push(String(convertedSuperTargetIds10));
            }
            json["superTargetIds"] = convertedSuperTargetIds0;
        }
        let children = this.getChildren();
        if (children != null) {
            let convertedChildren0 = [];
            for (let i in children) {
                let convertedChildren10 = children[i];
                let convertedChildren1 = convertedChildren10.getJson(JsonTypeTagType.TYPE);
                convertedChildren0.push(convertedChildren1);
            }
            let convertedChildrenWithType1 = ["java.util.ArrayList", convertedChildren0];
            json["children"] = convertedChildrenWithType1;
        }
        let imageUrl = this.getImageUrl();
        if (imageUrl != null) {
            json["imageUrl"] = imageUrl;
        }
        let showImageUrls = this.getShowImageUrls();
        if (showImageUrls != null) {
            let convertedShowImageUrls0 = [];
            for (let i in showImageUrls) {
                let convertedShowImageUrls10 = showImageUrls[i];
                convertedShowImageUrls0.push(convertedShowImageUrls10);
            }
            let convertedShowImageUrlsWithType1 = ["java.util.ArrayList", convertedShowImageUrls0];
            json["showImageUrls"] = convertedShowImageUrlsWithType1;
        }
        let name = this.getName();
        if (name != null) {
            json["name"] = name;
        }
        let nickName = this.getNickName();
        if (nickName != null) {
            json["nickName"] = nickName;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let keywords = this.getKeywords();
        if (keywords != null) {
            let convertedKeywords0 = [];
            for (let i in keywords) {
                let convertedKeywords10 = keywords[i];
                convertedKeywords0.push(convertedKeywords10);
            }
            let convertedKeywordsWithType1 = ["java.util.ArrayList", convertedKeywords0];
            json["keywords"] = convertedKeywordsWithType1;
        }
        let entityStatus = this.getEntityStatus();
        if (entityStatus != null) {
            let convertedEntityStatus0 = EntityStatus[entityStatus];
            json["entityStatus"] = convertedEntityStatus0;
        }

        if (includeTypeTag) {
            json["__type"] = "SimpleEntityDesc";
        }
    }


    public getTypeId(): string {
    	return "SimpleEntityDesc";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.SimpleEntityDesc";
    }
}
