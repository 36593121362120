/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractEnterpriseServiceTradable} from './AbstractEnterpriseServiceTradable'
import {EnterpriseServiceTradableKeyInfo} from './EnterpriseServiceTradableKeyInfo'
import {EntityInfo} from '../../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../../keyword/KeywordEntity'
import {MessageType} from '../../../../message/MessageContentType'
import {PlatformMemberType} from '../PlatformMemberType'
import {QllCountry} from '../../../international/QllCountry'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export abstract class AbstractEnterpriseServicePackageTradable extends AbstractEnterpriseServiceTradable  {
    memberType: PlatformMemberType;
    briefName: string;
    relativeName: string;
    packageTag: string;
    declare seriesLeader: boolean;
    declare test: boolean;
    serviceInfos: Array<EnterpriseServiceTradableKeyInfo>;
    offeredServiceInfos: Array<EnterpriseServiceTradableKeyInfo>;

    constructor() {
    	super();
    }

    public getMemberType(): PlatformMemberType {
    	return this.memberType;
    }

    public setMemberType(memberType: PlatformMemberType) {
    	this.memberType = memberType;
    }

    public getBriefName(): string {
    	return this.briefName;
    }

    public setBriefName(briefName: string) {
    	this.briefName = briefName;
    }

    public getRelativeName(): string {
    	return this.relativeName;
    }

    public setRelativeName(relativeName: string) {
    	this.relativeName = relativeName;
    }

    public getPackageTag(): string {
    	return this.packageTag;
    }

    public setPackageTag(packageTag: string) {
    	this.packageTag = packageTag;
    }

    public isSeriesLeader(): boolean {
    	return this.seriesLeader;
    }

    public setSeriesLeader(seriesLeader: boolean) {
    	this.seriesLeader = seriesLeader;
    }

    public isTest(): boolean {
    	return this.test;
    }

    public setTest(test: boolean) {
    	this.test = test;
    }

    public getServiceInfos(): Array<EnterpriseServiceTradableKeyInfo> {
    	return this.serviceInfos;
    }

    public setServiceInfos(serviceInfos: Array<EnterpriseServiceTradableKeyInfo>) {
    	this.serviceInfos = serviceInfos;
    }

    public getOfferedServiceInfos(): Array<EnterpriseServiceTradableKeyInfo> {
    	return this.offeredServiceInfos;
    }

    public setOfferedServiceInfos(offeredServiceInfos: Array<EnterpriseServiceTradableKeyInfo>) {
    	this.offeredServiceInfos = offeredServiceInfos;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let memberType = json["memberType"];
        if (memberType != null) {
            let convertedMemberType0 = PlatformMemberType[<string>memberType];
            this.setMemberType(convertedMemberType0);
        }
        let briefName = json["briefName"];
        if (briefName != null) {
            this.setBriefName(briefName);
        }
        let relativeName = json["relativeName"];
        if (relativeName != null) {
            this.setRelativeName(relativeName);
        }
        let packageTag = json["packageTag"];
        if (packageTag != null) {
            this.setPackageTag(packageTag);
        }
        let seriesLeader = json["seriesLeader"];
        if (seriesLeader != null) {
            this.setSeriesLeader(seriesLeader);
        }
        let test = json["test"];
        if (test != null) {
            this.setTest(test);
        }
        let serviceInfos = json["serviceInfos"];
        if (serviceInfos != null) {
            let convertedServiceInfos0 = [];
            serviceInfos = serviceInfos[1];
            for (let i in serviceInfos) {
                let convertedServiceInfos10 = serviceInfos[i];
                let convertedServiceInfos1 = new EnterpriseServiceTradableKeyInfo();
                convertedServiceInfos1.fillFromJson(convertedServiceInfos10, factory);
                convertedServiceInfos0.push(convertedServiceInfos1);
            }
            this.setServiceInfos(convertedServiceInfos0);
        }
        let offeredServiceInfos = json["offeredServiceInfos"];
        if (offeredServiceInfos != null) {
            let convertedOfferedServiceInfos0 = [];
            offeredServiceInfos = offeredServiceInfos[1];
            for (let i in offeredServiceInfos) {
                let convertedOfferedServiceInfos10 = offeredServiceInfos[i];
                let convertedOfferedServiceInfos1 = new EnterpriseServiceTradableKeyInfo();
                convertedOfferedServiceInfos1.fillFromJson(convertedOfferedServiceInfos10, factory);
                convertedOfferedServiceInfos0.push(convertedOfferedServiceInfos1);
            }
            this.setOfferedServiceInfos(convertedOfferedServiceInfos0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let memberType = this.getMemberType();
        if (memberType != null) {
            let convertedMemberType0 = PlatformMemberType[memberType];
            json["memberType"] = convertedMemberType0;
        }
        let briefName = this.getBriefName();
        if (briefName != null) {
            json["briefName"] = briefName;
        }
        let relativeName = this.getRelativeName();
        if (relativeName != null) {
            json["relativeName"] = relativeName;
        }
        let packageTag = this.getPackageTag();
        if (packageTag != null) {
            json["packageTag"] = packageTag;
        }
        let seriesLeader = this.isSeriesLeader();
        if (seriesLeader != null) {
            json["seriesLeader"] = seriesLeader;
        }
        let test = this.isTest();
        if (test != null) {
            json["test"] = test;
        }
        let serviceInfos = this.getServiceInfos();
        if (serviceInfos != null) {
            let convertedServiceInfos0 = [];
            for (let i in serviceInfos) {
                let convertedServiceInfos10 = serviceInfos[i];
                let convertedServiceInfos1 = convertedServiceInfos10.getJson(JsonTypeTagType.NONE);
                convertedServiceInfos0.push(convertedServiceInfos1);
            }
            let convertedServiceInfosWithType1 = ["java.util.ArrayList", convertedServiceInfos0];
            json["serviceInfos"] = convertedServiceInfosWithType1;
        }
        let offeredServiceInfos = this.getOfferedServiceInfos();
        if (offeredServiceInfos != null) {
            let convertedOfferedServiceInfos0 = [];
            for (let i in offeredServiceInfos) {
                let convertedOfferedServiceInfos10 = offeredServiceInfos[i];
                let convertedOfferedServiceInfos1 = convertedOfferedServiceInfos10.getJson(JsonTypeTagType.NONE);
                convertedOfferedServiceInfos0.push(convertedOfferedServiceInfos1);
            }
            let convertedOfferedServiceInfosWithType1 = ["java.util.ArrayList", convertedOfferedServiceInfos0];
            json["offeredServiceInfos"] = convertedOfferedServiceInfosWithType1;
        }

        if (includeTypeTag) {
            json["__type"] = "EnterpriseServicePackageTradable";
        }
    }


    public getTypeId(): string {
    	return "EnterpriseServicePackageTradable";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.tradable.EnterpriseServicePackageTradable";
    }
}
