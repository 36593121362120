/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCompositeCoworkInfo} from '../../../cowork/AbstractCompositeCoworkInfo'
import {AbstractEnterpriseServiceTradable} from './AbstractEnterpriseServiceTradable'
import {CompositeActivityInfo} from '../../../activity/CompositeActivityInfo'
import {EnterpriseServiceOrderInfo} from '../../order/EnterpriseServiceOrderInfo'
import {EnterpriseServiceTradableStats} from './EnterpriseServiceTradableStats'
import {EnterpriseServiceTradableUserInfo} from './EnterpriseServiceTradableUserInfo'
import {EntityInfo} from '../../../entity/EntityInfo'
import {InfoSectionDesc} from '../../../entity/topic/InfoSectionDesc'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {Serializer} from '../../../../serialize/Serializer'
import {ServicePriceDiscountType} from '../../price/ServicePriceDiscountType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export abstract class AbstractCompositeEnterpriseServiceTradableInfo extends AbstractCompositeCoworkInfo<AbstractEnterpriseServiceTradable, EnterpriseServiceTradableStats, EnterpriseServiceTradableUserInfo, AbstractCompositeEnterpriseServiceTradableInfo>  {
    orderInfo: EnterpriseServiceOrderInfo;
    activityInfo: CompositeActivityInfo;
    priceDiscountType: ServicePriceDiscountType;
    priceOnOffer: number;
    discountOnOffer: number;
    firstPayAmountOnOffer: number;
    customerServiceSpec: InfoSectionDesc;
    servingEnterpriseInfo: EntityInfo;

    constructor() {
    	super();
    }

    public getOrderInfo(): EnterpriseServiceOrderInfo {
    	return this.orderInfo;
    }

    public setOrderInfo(orderInfo: EnterpriseServiceOrderInfo) {
    	this.orderInfo = orderInfo;
    }

    public getActivityInfo(): CompositeActivityInfo {
    	return this.activityInfo;
    }

    public setActivityInfo(activityInfo: CompositeActivityInfo) {
    	this.activityInfo = activityInfo;
    }

    public getPriceDiscountType(): ServicePriceDiscountType {
    	return this.priceDiscountType;
    }

    public setPriceDiscountType(priceDiscountType: ServicePriceDiscountType) {
    	this.priceDiscountType = priceDiscountType;
    }

    public getPriceOnOffer(): number {
    	return this.priceOnOffer;
    }

    public setPriceOnOffer(priceOnOffer: number) {
    	this.priceOnOffer = priceOnOffer;
    }

    public getDiscountOnOffer(): number {
    	return this.discountOnOffer;
    }

    public setDiscountOnOffer(discountOnOffer: number) {
    	this.discountOnOffer = discountOnOffer;
    }

    public getFirstPayAmountOnOffer(): number {
    	return this.firstPayAmountOnOffer;
    }

    public setFirstPayAmountOnOffer(firstPayAmountOnOffer: number) {
    	this.firstPayAmountOnOffer = firstPayAmountOnOffer;
    }



    public getCustomerServiceSpec(): InfoSectionDesc {
    	return this.customerServiceSpec;
    }

    public setCustomerServiceSpec(customerServiceSpec: InfoSectionDesc) {
    	this.customerServiceSpec = customerServiceSpec;
    }

    public getServingEnterpriseInfo(): EntityInfo {
    	return this.servingEnterpriseInfo;
    }

    public setServingEnterpriseInfo(servingEnterpriseInfo: EntityInfo) {
    	this.servingEnterpriseInfo = servingEnterpriseInfo;
    }


    abstract getVisits(): number;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let orderInfo = json["orderInfo"];
        if (orderInfo != null) {
            let convertedOrderInfo0 = new EnterpriseServiceOrderInfo();
            convertedOrderInfo0.fillFromJson(orderInfo, factory);
            this.setOrderInfo(convertedOrderInfo0);
        }
        let activityInfo = json["activityInfo"];
        if (activityInfo != null) {
            let convertedActivityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(activityInfo, "__type", factory);
            this.setActivityInfo(convertedActivityInfo0);
        }
        let priceDiscountType = json["priceDiscountType"];
        if (priceDiscountType != null) {
            let convertedPriceDiscountType0 = ServicePriceDiscountType[<string>priceDiscountType];
            this.setPriceDiscountType(convertedPriceDiscountType0);
        }
        let priceOnOffer = json["priceOnOffer"];
        if (priceOnOffer != null) {
            this.setPriceOnOffer(priceOnOffer);
        }
        let discountOnOffer = json["discountOnOffer"];
        if (discountOnOffer != null) {
            this.setDiscountOnOffer(discountOnOffer);
        }
        let firstPayAmountOnOffer = json["firstPayAmountOnOffer"];
        if (firstPayAmountOnOffer != null) {
            this.setFirstPayAmountOnOffer(firstPayAmountOnOffer);
        }
        let customerServiceSpec = json["customerServiceSpec"];
        if (customerServiceSpec != null) {
            let convertedCustomerServiceSpec0 = new InfoSectionDesc();
            convertedCustomerServiceSpec0.fillFromJson(customerServiceSpec, factory);
            this.setCustomerServiceSpec(convertedCustomerServiceSpec0);
        }
        let servingEnterpriseInfo = json["servingEnterpriseInfo"];
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(servingEnterpriseInfo, "__type", factory);
            this.setServingEnterpriseInfo(convertedServingEnterpriseInfo0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let orderInfo = this.getOrderInfo();
        if (orderInfo != null) {
            let convertedOrderInfo0 = orderInfo.getJson(JsonTypeTagType.NONE);
            json["orderInfo"] = convertedOrderInfo0;
        }
        let activityInfo = this.getActivityInfo();
        if (activityInfo != null) {
            let convertedActivityInfo0 = activityInfo.getJson(JsonTypeTagType.TYPE);
            json["activityInfo"] = convertedActivityInfo0;
        }
        let priceDiscountType = this.getPriceDiscountType();
        if (priceDiscountType != null) {
            let convertedPriceDiscountType0 = ServicePriceDiscountType[priceDiscountType];
            json["priceDiscountType"] = convertedPriceDiscountType0;
        }
        let priceOnOffer = this.getPriceOnOffer();
        if (priceOnOffer != null) {
            json["priceOnOffer"] = priceOnOffer;
        }
        let discountOnOffer = this.getDiscountOnOffer();
        if (discountOnOffer != null) {
            json["discountOnOffer"] = discountOnOffer;
        }
        let firstPayAmountOnOffer = this.getFirstPayAmountOnOffer();
        if (firstPayAmountOnOffer != null) {
            json["firstPayAmountOnOffer"] = firstPayAmountOnOffer;
        }
        let customerServiceSpec = this.getCustomerServiceSpec();
        if (customerServiceSpec != null) {
            let convertedCustomerServiceSpec0 = customerServiceSpec.getJson(JsonTypeTagType.NONE);
            json["customerServiceSpec"] = convertedCustomerServiceSpec0;
        }
        let servingEnterpriseInfo = this.getServingEnterpriseInfo();
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = servingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["servingEnterpriseInfo"] = convertedServingEnterpriseInfo0;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.tradable.CompositeEnterpriseServiceTradableInfo";
    }
}
