/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {AdditionalEntityAttributes} from '../AdditionalEntityAttributes'
import {CoworkHighlight} from '../../cowork/CoworkHighlight'
import {EnterpriseJoinStatus} from './EnterpriseJoinStatus'
import {EnterpriseType} from './EnterpriseType'
import {EntityType} from '../EntityType'
import {GridInfo} from './GridInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {PlatformMemberType} from '../../business/service/PlatformMemberType'
import {SectorAssocInfo} from '../sector/SectorAssocInfo'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractSupplierDetails extends AbstractSerializable implements AdditionalEntityAttributes {
    enterpriseType: EnterpriseType;
    joinStatus: EnterpriseJoinStatus;
    joinTime: (number | string);
    telPhone: string;
    adImageUrl: string;
    business: boolean;
    openTimes: string;
    webLink: string;
    highlights: Array<CoworkHighlight>;
    honourId: (number | string);
    honourTitle: string;
    honourCount: number;
    gridInfo: GridInfo;
    byPlatform: boolean;
    sectorAssocInfo: SectorAssocInfo;
    memberCount: number;
    subCount: number;
    subscribers: number;
    status: string;
    statusTime: (number | string);
    chatOpen: boolean;
    openToVP: boolean;
    memberType: PlatformMemberType;
    qllMemberStartDay: number;
    qllMemberEndDay: number;

    constructor() {
        super();
    }

    public getEnterpriseType(): EnterpriseType {
        return this.enterpriseType;
    }

    public setEnterpriseType(enterpriseType: EnterpriseType) {
        this.enterpriseType = enterpriseType;
    }

    public getJoinStatus(): EnterpriseJoinStatus {
        return this.joinStatus;
    }

    public setJoinStatus(joinStatus: EnterpriseJoinStatus) {
        this.joinStatus = joinStatus;
    }

    public getJoinTime(): (number | string) {
        return this.joinTime;
    }

    public setJoinTime(joinTime: (number | string)) {
        this.joinTime = joinTime;
    }

    public getTelPhone(): string {
        return this.telPhone;
    }

    public setTelPhone(telPhone: string) {
        this.telPhone = telPhone;
    }

    public getAdImageUrl(): string {
        return this.adImageUrl;
    }

    public setAdImageUrl(adImageUrl: string) {
        this.adImageUrl = adImageUrl;
    }

    public isBusiness(): boolean {
        return this.business;
    }

    public setBusiness(business: boolean) {
        this.business = business;
    }

    public getOpenTimes(): string {
        return this.openTimes;
    }

    public setOpenTimes(openTimes: string) {
        this.openTimes = openTimes;
    }

    public getWebLink(): string {
        return this.webLink;
    }

    public setWebLink(webLink: string) {
        this.webLink = webLink;
    }

    public getHighlights(): Array<CoworkHighlight> {
        return this.highlights;
    }

    public setHighlights(highlights: Array<CoworkHighlight>) {
        this.highlights = highlights;
    }

    public getHonourId(): (number | string) {
        return this.honourId;
    }

    public setHonourId(honourId: (number | string)) {
        this.honourId = honourId;
    }

    public getHonourTitle(): string {
        return this.honourTitle;
    }

    public setHonourTitle(honourTitle: string) {
        this.honourTitle = honourTitle;
    }

    public getHonourCount(): number {
        return this.honourCount;
    }

    public setHonourCount(honourCount: number) {
        this.honourCount = honourCount;
    }

    public getGridInfo(): GridInfo {
        return this.gridInfo;
    }

    public setGridInfo(gridInfo: GridInfo) {
        this.gridInfo = gridInfo;
    }

    public isByPlatform(): boolean {
        return this.byPlatform;
    }

    public setByPlatform(byPlatform: boolean) {
        this.byPlatform = byPlatform;
    }

    public getSectorAssocInfo(): SectorAssocInfo {
        return this.sectorAssocInfo;
    }

    public setSectorAssocInfo(sectorAssocInfo: SectorAssocInfo) {
        this.sectorAssocInfo = sectorAssocInfo;
    }

    public getMemberCount(): number {
        return this.memberCount;
    }

    public setMemberCount(memberCount: number) {
        this.memberCount = memberCount;
    }

    public getSubCount(): number {
        return this.subCount;
    }

    public setSubCount(subCount: number) {
        this.subCount = subCount;
    }

    public getSubscribers(): number {
        return this.subscribers;
    }

    public setSubscribers(subscribers: number) {
        this.subscribers = subscribers;
    }

    public getStatus(): string {
        return this.status;
    }

    public setStatus(status: string) {
        this.status = status;
    }

    public getStatusTime(): (number | string) {
        return this.statusTime;
    }

    public setStatusTime(statusTime: (number | string)) {
        this.statusTime = statusTime;
    }

    public isChatOpen(): boolean {
        return this.chatOpen;
    }

    public setChatOpen(chatOpen: boolean) {
        this.chatOpen = chatOpen;
    }

    public isOpenToVP(): boolean {
        return this.openToVP;
    }

    public setOpenToVP(openToVP: boolean) {
        this.openToVP = openToVP;
    }

    public getMemberType(): PlatformMemberType {
        return this.memberType;
    }

    public setMemberType(memberType: PlatformMemberType) {
        this.memberType = memberType;
    }

    public getQllMemberStartDay(): number {
        return this.qllMemberStartDay;
    }

    public setQllMemberStartDay(qllMemberStartDay: number) {
        this.qllMemberStartDay = qllMemberStartDay;
    }

    public getQllMemberEndDay(): number {
        return this.qllMemberEndDay;
    }

    public setQllMemberEndDay(qllMemberEndDay: number) {
        this.qllMemberEndDay = qllMemberEndDay;
    }


    abstract copy(): AdditionalEntityAttributes;

    abstract getEntityType(): EntityType;

    abstract addAdditionalEntityAttributes(arg0: (AdditionalEntityAttributes | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let enterpriseType = json["enterpriseType"];
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[<string>enterpriseType];
            this.setEnterpriseType(convertedEnterpriseType0);
        }
        let joinStatus = json["joinStatus"];
        if (joinStatus != null) {
            let convertedJoinStatus0 = EnterpriseJoinStatus[<string>joinStatus];
            this.setJoinStatus(convertedJoinStatus0);
        }
        let joinTime = json["joinTime"];
        if (joinTime != null) {
            this.setJoinTime(joinTime);
        }
        let telPhone = json["telPhone"];
        if (telPhone != null) {
            this.setTelPhone(telPhone);
        }
        let adImageUrl = json["adImageUrl"];
        if (adImageUrl != null) {
            this.setAdImageUrl(adImageUrl);
        }
        let business = json["business"];
        if (business != null) {
            this.setBusiness(business);
        }
        let openTimes = json["openTimes"];
        if (openTimes != null) {
            this.setOpenTimes(openTimes);
        }
        let webLink = json["webLink"];
        if (webLink != null) {
            this.setWebLink(webLink);
        }
        let highlights = json["highlights"];
        if (highlights != null) {
            let convertedHighlights0 = [];
            highlights = highlights[1];
            for (let i in highlights) {
                let convertedHighlights10 = highlights[i];
                let convertedHighlights1 = new CoworkHighlight();
                convertedHighlights1.fillFromJson(convertedHighlights10, factory);
                convertedHighlights0.push(convertedHighlights1);
            }
            this.setHighlights(convertedHighlights0);
        }
        let honourId = json["honourId"];
        if (honourId != null) {
            this.setHonourId(honourId);
        }
        let honourTitle = json["honourTitle"];
        if (honourTitle != null) {
            this.setHonourTitle(honourTitle);
        }
        let honourCount = json["honourCount"];
        if (honourCount != null) {
            this.setHonourCount(honourCount);
        }
        let gridInfo = json["gridInfo"];
        if (gridInfo != null) {
            let convertedGridInfo0 = new GridInfo();
            convertedGridInfo0.fillFromJson(gridInfo, factory);
            this.setGridInfo(convertedGridInfo0);
        }
        let byPlatform = json["byPlatform"];
        if (byPlatform != null) {
            this.setByPlatform(byPlatform);
        }
        let sectorAssocInfo = json["sectorAssocInfo"];
        if (sectorAssocInfo != null) {
            let convertedSectorAssocInfo0 = new SectorAssocInfo();
            convertedSectorAssocInfo0.fillFromJson(sectorAssocInfo, factory);
            this.setSectorAssocInfo(convertedSectorAssocInfo0);
        }
        let memberCount = json["memberCount"];
        if (memberCount != null) {
            this.setMemberCount(memberCount);
        }
        let subCount = json["subCount"];
        if (subCount != null) {
            this.setSubCount(subCount);
        }
        let subscribers = json["subscribers"];
        if (subscribers != null) {
            this.setSubscribers(subscribers);
        }
        let status = json["status"];
        if (status != null) {
            this.setStatus(status);
        }
        let statusTime = json["statusTime"];
        if (statusTime != null) {
            this.setStatusTime(statusTime);
        }
        let chatOpen = json["chatOpen"];
        if (chatOpen != null) {
            this.setChatOpen(chatOpen);
        }
        let openToVP = json["openToVP"];
        if (openToVP != null) {
            this.setOpenToVP(openToVP);
        }
        let memberType = json["memberType"];
        if (memberType != null) {
            let convertedMemberType0 = PlatformMemberType[<string>memberType];
            this.setMemberType(convertedMemberType0);
        }
        let qllMemberStartDay = json["qllMemberStartDay"];
        if (qllMemberStartDay != null) {
            this.setQllMemberStartDay(qllMemberStartDay);
        }
        let qllMemberEndDay = json["qllMemberEndDay"];
        if (qllMemberEndDay != null) {
            this.setQllMemberEndDay(qllMemberEndDay);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let enterpriseType = this.getEnterpriseType();
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[enterpriseType];
            json["enterpriseType"] = convertedEnterpriseType0;
        }
        let joinStatus = this.getJoinStatus();
        if (joinStatus != null) {
            let convertedJoinStatus0 = EnterpriseJoinStatus[joinStatus];
            json["joinStatus"] = convertedJoinStatus0;
        }
        let joinTime = this.getJoinTime();
        if (joinTime != null) {
            json["joinTime"] = String(joinTime);
        }
        let telPhone = this.getTelPhone();
        if (telPhone != null) {
            json["telPhone"] = telPhone;
        }
        let adImageUrl = this.getAdImageUrl();
        if (adImageUrl != null) {
            json["adImageUrl"] = adImageUrl;
        }
        let business = this.isBusiness();
        if (business != null) {
            json["business"] = business;
        }
        let openTimes = this.getOpenTimes();
        if (openTimes != null) {
            json["openTimes"] = openTimes;
        }
        let webLink = this.getWebLink();
        if (webLink != null) {
            json["webLink"] = webLink;
        }
        let highlights = this.getHighlights();
        if (highlights != null) {
            let convertedHighlights0 = [];
            for (let i in highlights) {
                let convertedHighlights10 = highlights[i];
                let convertedHighlights1 = convertedHighlights10.getJson(JsonTypeTagType.NONE);
                convertedHighlights0.push(convertedHighlights1);
            }
            let convertedHighlightsWithType1 = ["java.util.ArrayList", convertedHighlights0];
            json["highlights"] = convertedHighlightsWithType1;
        }
        let honourId = this.getHonourId();
        if (honourId != null) {
            json["honourId"] = String(honourId);
        }
        let honourTitle = this.getHonourTitle();
        if (honourTitle != null) {
            json["honourTitle"] = honourTitle;
        }
        let honourCount = this.getHonourCount();
        if (honourCount != null) {
            json["honourCount"] = honourCount;
        }
        let gridInfo = this.getGridInfo();
        if (gridInfo != null) {
            let convertedGridInfo0 = gridInfo.getJson(JsonTypeTagType.NONE);
            json["gridInfo"] = convertedGridInfo0;
        }
        let byPlatform = this.isByPlatform();
        if (byPlatform != null) {
            json["byPlatform"] = byPlatform;
        }
        let sectorAssocInfo = this.getSectorAssocInfo();
        if (sectorAssocInfo != null) {
            let convertedSectorAssocInfo0 = sectorAssocInfo.getJson(JsonTypeTagType.NONE);
            json["sectorAssocInfo"] = convertedSectorAssocInfo0;
        }
        let memberCount = this.getMemberCount();
        if (memberCount != null) {
            json["memberCount"] = memberCount;
        }
        let subCount = this.getSubCount();
        if (subCount != null) {
            json["subCount"] = subCount;
        }
        let subscribers = this.getSubscribers();
        if (subscribers != null) {
            json["subscribers"] = subscribers;
        }
        let status = this.getStatus();
        if (status != null) {
            json["status"] = status;
        }
        let statusTime = this.getStatusTime();
        if (statusTime != null) {
            json["statusTime"] = String(statusTime);
        }
        let chatOpen = this.isChatOpen();
        if (chatOpen != null) {
            json["chatOpen"] = chatOpen;
        }
        let openToVP = this.isOpenToVP();
        if (openToVP != null) {
            json["openToVP"] = openToVP;
        }
        let memberType = this.getMemberType();
        if (memberType != null) {
            let convertedMemberType0 = PlatformMemberType[memberType];
            json["memberType"] = convertedMemberType0;
        }
        let qllMemberStartDay = this.getQllMemberStartDay();
        if (qllMemberStartDay != null) {
            json["qllMemberStartDay"] = qllMemberStartDay;
        }
        let qllMemberEndDay = this.getQllMemberEndDay();
        if (qllMemberEndDay != null) {
            json["qllMemberEndDay"] = qllMemberEndDay;
        }

        if (includeTypeTag) {
            json["__type"] = "SupplierDetails";
        }
    }


    public getTypeId(): string {
        return "SupplierDetails";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.enterprise.SupplierDetails";
    }
}
