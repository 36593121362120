/**
 * 自动生成， 请不要手工修改！
 */


export enum PlatformMemberType {
    QLL_FRIEND,
    QLL_BASIC,
    QLL_GENERAL,
    QLL_VIP,
    QLL_PARTNER,
    LEGAL_LIGHT,
    LEGAL_STANDARD,
    LEGAL_VIP,
    QLL_USER,
    QLL_TEST
}
