/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { CoworkType } from "../../cowork/CoworkType";
import { Filter } from "../Filter";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { RequestStatusType } from "../../request/RequestStatusType";
import { RequestType } from "../../request/RequestType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class RequestFilter extends AbstractSerializable implements Filter {
  entityId: number | string;
  targetType: CoworkType;
  requestSuperType: RequestType;
  requestType: RequestType;
  status: RequestStatusType;
  userId: number | string;

  constructor() {
    super();
  }

  public getEntityId(): number | string {
    return this.entityId;
  }

  public setEntityId(entityId: number | string) {
    this.entityId = entityId;
  }

  public getTargetType(): CoworkType {
    return this.targetType;
  }

  public setTargetType(targetType: CoworkType) {
    this.targetType = targetType;
  }

  public getRequestSuperType(): RequestType {
    return this.requestSuperType;
  }

  public setRequestSuperType(requestSuperType: RequestType) {
    this.requestSuperType = requestSuperType;
  }

  public getRequestType(): RequestType {
    return this.requestType;
  }

  public setRequestType(requestType: RequestType) {
    this.requestType = requestType;
  }

  public getStatus(): RequestStatusType {
    return this.status;
  }

  public setStatus(status: RequestStatusType) {
    this.status = status;
  }

  public getUserId(): number | string {
    return this.userId;
  }

  public setUserId(userId: number | string) {
    this.userId = userId;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const entityId = json["entityId"];
    if (entityId != null) {
      this.setEntityId(entityId);
    }
    const targetType = json["targetType"];
    if (targetType != null) {
      const convertedTargetType0 = CoworkType[<string>targetType];
      this.setTargetType(convertedTargetType0);
    }
    const requestSuperType = json["requestSuperType"];
    if (requestSuperType != null) {
      const convertedRequestSuperType0 = RequestType[<string>requestSuperType];
      this.setRequestSuperType(convertedRequestSuperType0);
    }
    const requestType = json["requestType"];
    if (requestType != null) {
      const convertedRequestType0 = RequestType[<string>requestType];
      this.setRequestType(convertedRequestType0);
    }
    const status = json["status"];
    if (status != null) {
      const convertedStatus0 = RequestStatusType[<string>status];
      this.setStatus(convertedStatus0);
    }
    const userId = json["userId"];
    if (userId != null) {
      this.setUserId(userId);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const entityId = this.getEntityId();
    if (entityId != null) {
      json["entityId"] = String(entityId);
    }
    const targetType = this.getTargetType();
    if (targetType != null) {
      const convertedTargetType0 = CoworkType[targetType];
      json["targetType"] = convertedTargetType0;
    }
    const requestSuperType = this.getRequestSuperType();
    if (requestSuperType != null) {
      const convertedRequestSuperType0 = RequestType[requestSuperType];
      json["requestSuperType"] = convertedRequestSuperType0;
    }
    const requestType = this.getRequestType();
    if (requestType != null) {
      const convertedRequestType0 = RequestType[requestType];
      json["requestType"] = convertedRequestType0;
    }
    const status = this.getStatus();
    if (status != null) {
      const convertedStatus0 = RequestStatusType[status];
      json["status"] = convertedStatus0;
    }
    const userId = this.getUserId();
    if (userId != null) {
      json["userId"] = String(userId);
    }

    if (includeTypeTag) {
      json["__type"] = "ComplaintFilter";
    }
  }

  public getTypeId(): string {
    return "ComplaintFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.request.RequestFilter";
  }
}
