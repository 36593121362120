/**
 * 自动生成， 请不要手工修改！
 */


export enum QllCountry {
    CHINA,
    US,
    UK,
    CANADA,
    GERMANY,
    KOREA,
    JAPAN
}
