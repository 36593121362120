// @ts-nocheck
import { AbstractCompositeEnterpriseServiceTradableInfo } from "./AbstractCompositeEnterpriseServiceTradableInfo";

export class CompositeEnterpriseServiceTradableInfo extends AbstractCompositeEnterpriseServiceTradableInfo {
  constructor() {
    super();
  }

  getVisits(): number {
    // Manual Code Here
  }
}
