/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { Filter } from "./Filter";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";
import { UserSensitive } from "./UserSensitive";

export class AbstractUserSensitive
  extends AbstractSerializable
  implements UserSensitive, Filter
{
  userId: number | string;

  constructor() {
    super();
  }

  public getUserId(): number | string {
    return this.userId;
  }

  public setUserId(userId: number | string) {
    this.userId = userId;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const userId = json["userId"];
    if (userId != null) {
      this.setUserId(userId);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const userId = this.getUserId();
    if (userId != null) {
      json["userId"] = String(userId);
    }

    if (includeTypeTag) {
      json["__type"] = "AbstractUserSensitive";
    }
  }

  public getTypeId(): string {
    return "AbstractUserSensitive";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.AbstractUserSensitive";
  }
}
