/**
 * 自动生成， 请不要手工修改！
 */


export enum SharedInfoStatus {
    IN_REVIEW,
    ACTIVE,
    CANCELLED,
    TO_EXPOSE,
    HIDDEN,
    DISLIKED
}
