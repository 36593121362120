/**
 * 自动生成， 请不要手工修改！
 */

import {OrderStatus} from './OrderStatus'


export class OrderStatusName {
    public static getDisplayName(value: OrderStatus): string {
        switch (value) {
            case OrderStatus.ACCEPT_NEW:
                return "新订单";
            case OrderStatus.PENDING_TO_PAY:
                return "待支付";
            case OrderStatus.CANCELLED:
                return "已取消";
            case OrderStatus.COMPLETED:
                return "已完成";
            case OrderStatus.PENDING_REVIEW:
                return "待审核";
        }
        ;
        return null;
    }
}
