/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { AdContent } from "../business/ad/AdContent";
import { AppVersionInfo } from "./AppVersionInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Serializer } from "../../serialize/Serializer";
import { ServiceDistributionSpec } from "../../service/ServiceDistributionSpec";
import { SyncResponse } from "./SyncResponse";
import { TypeFactory } from "../../serialize/TypeFactory";

export class PlatformDataContainer<T> extends AbstractSerializable {
  data: T;
  syncResponse: SyncResponse;
  ads: Array<AdContent>;
  serviceDistributionSpec: ServiceDistributionSpec;
  latestAppVersionInfo: AppVersionInfo;

  constructor() {
    super();
  }

  public getData(): T {
    return this.data;
  }

  public setData(data: T) {
    this.data = data;
  }

  public getSyncResponse(): SyncResponse {
    return this.syncResponse;
  }

  public setSyncResponse(syncResponse: SyncResponse) {
    this.syncResponse = syncResponse;
  }

  public getAds(): Array<AdContent> {
    return this.ads;
  }

  public setAds(ads: Array<AdContent>) {
    this.ads = ads;
  }

  public getServiceDistributionSpec(): ServiceDistributionSpec {
    return this.serviceDistributionSpec;
  }

  public setServiceDistributionSpec(
    serviceDistributionSpec: ServiceDistributionSpec
  ) {
    this.serviceDistributionSpec = serviceDistributionSpec;
  }

  public getLatestAppVersionInfo(): AppVersionInfo {
    return this.latestAppVersionInfo;
  }

  public setLatestAppVersionInfo(latestAppVersionInfo: AppVersionInfo) {
    this.latestAppVersionInfo = latestAppVersionInfo;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const data = json["data"];
    if (data != null) {
      const convertedData0 = Serializer.fromJson(data, factory);
      this.setData(convertedData0);
    }
    const syncResponse = json["syncResponse"];
    if (syncResponse != null) {
      const convertedSyncResponse0 = new SyncResponse();
      convertedSyncResponse0.fillFromJson(syncResponse, factory);
      this.setSyncResponse(convertedSyncResponse0);
    }
    let ads = json["ads"];
    if (ads != null) {
      const convertedAds0 = [];
      ads = ads[1];
      for (const i in ads) {
        const convertedAds10 = ads[i];
        const convertedAds1 = Serializer.fillFromJsonObjectWithTypeTag(
          convertedAds10,
          "__type",
          factory
        );
        convertedAds0.push(convertedAds1);
      }
      this.setAds(convertedAds0);
    }
    const serviceDistributionSpec = json["serviceDistributionSpec"];
    if (serviceDistributionSpec != null) {
      const convertedServiceDistributionSpec0 = new ServiceDistributionSpec();
      convertedServiceDistributionSpec0.fillFromJson(
        serviceDistributionSpec,
        factory
      );
      this.setServiceDistributionSpec(convertedServiceDistributionSpec0);
    }
    const latestAppVersionInfo = json["latestAppVersionInfo"];
    if (latestAppVersionInfo != null) {
      const convertedLatestAppVersionInfo0 = new AppVersionInfo();
      convertedLatestAppVersionInfo0.fillFromJson(
        latestAppVersionInfo,
        factory
      );
      this.setLatestAppVersionInfo(convertedLatestAppVersionInfo0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const data = this.getData();
    if (data != null) {
      const convertedData0 = Serializer.getJson(data, JsonTypeTagType.CLASS);
      json["data"] = convertedData0;
    }
    const syncResponse = this.getSyncResponse();
    if (syncResponse != null) {
      const convertedSyncResponse0 = syncResponse.getJson(JsonTypeTagType.NONE);
      json["syncResponse"] = convertedSyncResponse0;
    }
    const ads = this.getAds();
    if (ads != null) {
      const convertedAds0 = [];
      for (const i in ads) {
        const convertedAds10 = ads[i];
        const convertedAds1 = convertedAds10.getJson(JsonTypeTagType.TYPE);
        convertedAds0.push(convertedAds1);
      }
      const convertedAdsWithType1 = ["java.util.ArrayList", convertedAds0];
      json["ads"] = convertedAdsWithType1;
    }
    const serviceDistributionSpec = this.getServiceDistributionSpec();
    if (serviceDistributionSpec != null) {
      const convertedServiceDistributionSpec0 = serviceDistributionSpec.getJson(
        JsonTypeTagType.NONE
      );
      json["serviceDistributionSpec"] = convertedServiceDistributionSpec0;
    }
    const latestAppVersionInfo = this.getLatestAppVersionInfo();
    if (latestAppVersionInfo != null) {
      const convertedLatestAppVersionInfo0 = latestAppVersionInfo.getJson(
        JsonTypeTagType.NONE
      );
      json["latestAppVersionInfo"] = convertedLatestAppVersionInfo0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.platform.PlatformDataContainer";
  }
}
