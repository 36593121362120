// @ts-nocheck
import { AbstractUserDetails } from "./AbstractUserDetails";
import { AdditionalEntityAttributes } from "../AdditionalEntityAttributes";
import { EntityType } from "../EntityType";

export class UserDetails extends AbstractUserDetails {
  constructor() {
    super();
  }

  copy(): AdditionalEntityAttributes {
    // Manual Code Here
  }
  getEntityType(): EntityType {
    // Manual Code Here
  }
  addAdditionalEntityAttributes(arg0: AdditionalEntityAttributes | null) {
    // Manual Code Here
  }
}
