/**
 * 自动生成， 请不要手工修改！
 */


export enum EnterpriseCategory {
    ENTERPRISE,
    GROUP,
    GRID,
    INSTITUTION,
    QLL
}
