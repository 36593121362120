/**
 * 自动生成， 请不要手工修改！
 */


export enum ActionType {
    ADD,
    IGNORE,
    CANCEL,
    ACCEPT,
    DEAL,
    ACK,
    REJECT,
    ASSIGN,
    REASSIGN,
    COMPLETE,
    ASK_COMPLETE,
    AUTO_COMPLETE,
    DELEGATE,
    REVOKE_DELEGATE,
    ACCEPT_DELEGATE,
    REJECT_DELEGATE
}
