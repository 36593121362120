/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {ActivityCoopType} from './ActivityCoopType'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export class ActivityCooper extends AbstractSerializable {
    activityId: (number | string);
    entityInfo: EntityInfo;
    coopType: ActivityCoopType;
    score: number;

    constructor() {
        super();
    }

    public getActivityId(): (number | string) {
        return this.activityId;
    }

    public setActivityId(activityId: (number | string)) {
        this.activityId = activityId;
    }

    public getEntityInfo(): EntityInfo {
        return this.entityInfo;
    }

    public setEntityInfo(entityInfo: EntityInfo) {
        this.entityInfo = entityInfo;
    }

    public getCoopType(): ActivityCoopType {
        return this.coopType;
    }

    public setCoopType(coopType: ActivityCoopType) {
        this.coopType = coopType;
    }

    public getScore(): number {
        return this.score;
    }

    public setScore(score: number) {
        this.score = score;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let activityId = json["activityId"];
        if (activityId != null) {
            this.setActivityId(activityId);
        }
        let entityInfo = json["entityInfo"];
        if (entityInfo != null) {
            let convertedEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(entityInfo, "__type", factory);
            this.setEntityInfo(convertedEntityInfo0);
        }
        let coopType = json["coopType"];
        if (coopType != null) {
            let convertedCoopType0 = ActivityCoopType[<string>coopType];
            this.setCoopType(convertedCoopType0);
        }
        let score = json["score"];
        if (score != null) {
            this.setScore(score);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let activityId = this.getActivityId();
        if (activityId != null) {
            json["activityId"] = String(activityId);
        }
        let entityInfo = this.getEntityInfo();
        if (entityInfo != null) {
            let convertedEntityInfo0 = entityInfo.getJson(JsonTypeTagType.TYPE);
            json["entityInfo"] = convertedEntityInfo0;
        }
        let coopType = this.getCoopType();
        if (coopType != null) {
            let convertedCoopType0 = ActivityCoopType[coopType];
            json["coopType"] = convertedCoopType0;
        }
        let score = this.getScore();
        if (score != null) {
            json["score"] = score;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.activity.ActivityCooper";
    }
}
