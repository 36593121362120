/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { CoworkType } from "../../cowork/CoworkType";
import { CoworkVisitType } from "../../cowork/CoworkVisitType";
import { EntityType } from "../../entity/EntityType";
import { Filter } from "../Filter";
import {JsonTypeTagType} from "../../../serialize/JsonTypeTagType";
import {TopicType} from "../../entity/topic/TopicType";
import {TypeFactory} from "../../../serialize/TypeFactory";

export class MineFilter extends AbstractSerializable implements Filter {
  searchStr: string;
  coworkObjectType: CoworkType;
  entityType: EntityType;
  ipVisitType: CoworkVisitType;
  noNetizen: boolean;
  noEnterprise: boolean;
  noProduct: boolean;
  productTypes: Array<TopicType>;

  constructor() {
    super();
  }

  public getSearchStr(): string {
    return this.searchStr;
  }

  public setSearchStr(searchStr: string) {
    this.searchStr = searchStr;
  }

  public getCoworkObjectType(): CoworkType {
    return this.coworkObjectType;
  }

  public setCoworkObjectType(coworkObjectType: CoworkType) {
    this.coworkObjectType = coworkObjectType;
  }

  public getEntityType(): EntityType {
    return this.entityType;
  }

  public setEntityType(entityType: EntityType) {
    this.entityType = entityType;
  }

  public getIpVisitType(): CoworkVisitType {
    return this.ipVisitType;
  }

  public setIpVisitType(ipVisitType: CoworkVisitType) {
    this.ipVisitType = ipVisitType;
  }

  public isNoNetizen(): boolean {
    return this.noNetizen;
  }

  public setNoNetizen(noNetizen: boolean) {
    this.noNetizen = noNetizen;
  }

  public isNoEnterprise(): boolean {
    return this.noEnterprise;
  }

  public setNoEnterprise(noEnterprise: boolean) {
    this.noEnterprise = noEnterprise;
  }

  public isNoProduct(): boolean {
    return this.noProduct;
  }

  public setNoProduct(noProduct: boolean) {
    this.noProduct = noProduct;
  }

  public getTopicTypes(): Array<TopicType> {
    return this.productTypes;
  }

  public setTopicTypes(productTypes: Array<TopicType>) {
    this.productTypes = productTypes;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const searchStr = json["searchStr"];
    if (searchStr != null) {
      this.setSearchStr(searchStr);
    }
    const coworkObjectType = json["coworkObjectType"];
    if (coworkObjectType != null) {
      const convertedCoworkObjectType0 = CoworkType[<string>coworkObjectType];
      this.setCoworkObjectType(convertedCoworkObjectType0);
    }
    const entityType = json["entityType"];
    if (entityType != null) {
      const convertedEntityType0 = EntityType[<string>entityType];
      this.setEntityType(convertedEntityType0);
    }
    const ipVisitType = json["ipVisitType"];
    if (ipVisitType != null) {
      const convertedIpVisitType0 = CoworkVisitType[<string>ipVisitType];
      this.setIpVisitType(convertedIpVisitType0);
    }
    const noNetizen = json["noNetizen"];
    if (noNetizen != null) {
      this.setNoNetizen(noNetizen);
    }
    const noEnterprise = json["noEnterprise"];
    if (noEnterprise != null) {
      this.setNoEnterprise(noEnterprise);
    }
    const noProduct = json["noProduct"];
    if (noProduct != null) {
      this.setNoProduct(noProduct);
    }
    let productTypes = json["productTypes"];
    if (productTypes != null) {
      const convertedTopicTypes0 = [];
      productTypes = productTypes[1];
      for (const i in productTypes) {
        const convertedTopicTypes10 = productTypes[i];
        const convertedTopicTypes1 =
            productTypes[<string>convertedTopicTypes10];
        convertedTopicTypes0.push(convertedTopicTypes1);
      }
      this.setTopicTypes(convertedTopicTypes0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const searchStr = this.getSearchStr();
    if (searchStr != null) {
      json["searchStr"] = searchStr;
    }
    const coworkObjectType = this.getCoworkObjectType();
    if (coworkObjectType != null) {
      const convertedCoworkObjectType0 = CoworkType[coworkObjectType];
      json["coworkObjectType"] = convertedCoworkObjectType0;
    }
    const entityType = this.getEntityType();
    if (entityType != null) {
      const convertedEntityType0 = EntityType[entityType];
      json["entityType"] = convertedEntityType0;
    }
    const ipVisitType = this.getIpVisitType();
    if (ipVisitType != null) {
      const convertedIpVisitType0 = CoworkVisitType[ipVisitType];
      json["ipVisitType"] = convertedIpVisitType0;
    }
    const noNetizen = this.isNoNetizen();
    if (noNetizen != null) {
      json["noNetizen"] = noNetizen;
    }
    const noEnterprise = this.isNoEnterprise();
    if (noEnterprise != null) {
      json["noEnterprise"] = noEnterprise;
    }
    const noProduct = this.isNoProduct();
    if (noProduct != null) {
      json["noProduct"] = noProduct;
    }
    const productTypes = this.getTopicTypes();
    if (productTypes != null) {
      const convertedTopicTypes0 = [];
      for (const i in productTypes) {
        const convertedTopicTypes10 = productTypes[i];
        const convertedTopicTypes1 = TopicType[convertedTopicTypes10];
        convertedTopicTypes0.push(convertedTopicTypes1);
      }
      const convertedTopicTypesWithType1 = [
        "java.util.ArrayList",
        convertedTopicTypes0,
      ];
      json["productTypes"] = convertedTopicTypesWithType1;
    }

    if (includeTypeTag) {
      json["__type"] = "MineFilter";
    }
  }

  public getTypeId(): string {
    return "MineFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.mine.MineFilter";
  }
}
