/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../serialize/JsonTypeTagType";
import { Serializer } from "../serialize/Serializer";
import { TypeFactory } from "../serialize/TypeFactory";

export class Pair<S, T> extends AbstractSerializable {
  left: S;
  right: T;

  constructor() {
    super();
  }

  public getLeft(): S {
    return this.left;
  }

  public setLeft(left: S) {
    this.left = left;
  }

  public getRight(): T {
    return this.right;
  }

  public setRight(right: T) {
    this.right = right;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const left = json["left"];
    if (left != null) {
      const convertedLeft0 = Serializer.fromJson(left, factory);
      this.setLeft(convertedLeft0);
    }
    const right = json["right"];
    if (right != null) {
      const convertedRight0 = Serializer.fromJson(right, factory);
      this.setRight(convertedRight0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const left = this.getLeft();
    if (left != null) {
      const convertedLeft0 = Serializer.getJson(left, JsonTypeTagType.CLASS);
      json["left"] = convertedLeft0;
    }
    const right = this.getRight();
    if (right != null) {
      const convertedRight0 = Serializer.getJson(right, JsonTypeTagType.CLASS);
      json["right"] = convertedRight0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.util.Pair";
  }
}
