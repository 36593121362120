/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../serialize/JsonTypeTagType'
import {Map} from '../util/Map'
import {Serializer} from '../serialize/Serializer'
import {TypeFactory} from '../serialize/TypeFactory'


export class ServiceDistributionSpec extends AbstractSerializable  {
    resourceServerUrls: Array<string>;
    searchServerUrls: Array<string>;
    netizenUrls: Array<string>;
    internationalServerUrl: string;
    defaultServerUrl: string;
    specificServers: Map<(number | string), string>;
    generalServers: Array<string>;
    maxTryConn: number;
    version: number;

    constructor() {
    	super();
    }

    public getResourceServerUrls(): Array<string> {
    	return this.resourceServerUrls;
    }

    public setResourceServerUrls(resourceServerUrls: Array<string>) {
    	this.resourceServerUrls = resourceServerUrls;
    }

    public getSearchServerUrls(): Array<string> {
    	return this.searchServerUrls;
    }

    public setSearchServerUrls(searchServerUrls: Array<string>) {
    	this.searchServerUrls = searchServerUrls;
    }

    public getNetizenUrls(): Array<string> {
    	return this.netizenUrls;
    }

    public setNetizenUrls(netizenUrls: Array<string>) {
    	this.netizenUrls = netizenUrls;
    }

    public getInternationalServerUrl(): string {
    	return this.internationalServerUrl;
    }

    public setInternationalServerUrl(internationalServerUrl: string) {
    	this.internationalServerUrl = internationalServerUrl;
    }

    public getDefaultServerUrl(): string {
    	return this.defaultServerUrl;
    }

    public setDefaultServerUrl(defaultServerUrl: string) {
    	this.defaultServerUrl = defaultServerUrl;
    }

    public getSpecificServers(): Map<(number | string), string> {
    	return this.specificServers;
    }

    public setSpecificServers(specificServers: Map<(number | string), string>) {
    	this.specificServers = specificServers;
    }

    public getGeneralServers(): Array<string> {
    	return this.generalServers;
    }

    public setGeneralServers(generalServers: Array<string>) {
    	this.generalServers = generalServers;
    }


    public getMaxTryConn(): number {
    	return this.maxTryConn;
    }

    public setMaxTryConn(maxTryConn: number) {
    	this.maxTryConn = maxTryConn;
    }

    public getVersion(): number {
    	return this.version;
    }

    public setVersion(version: number) {
    	this.version = version;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let resourceServerUrls = json["resourceServerUrls"];
        if (resourceServerUrls != null) {
            let convertedResourceServerUrls0 = [];
            resourceServerUrls = resourceServerUrls[1];
            for (let i in resourceServerUrls) {
                let convertedResourceServerUrls10 = resourceServerUrls[i];
                convertedResourceServerUrls0.push(convertedResourceServerUrls10);
            }
            this.setResourceServerUrls(convertedResourceServerUrls0);
        }
        let searchServerUrls = json["searchServerUrls"];
        if (searchServerUrls != null) {
            let convertedSearchServerUrls0 = [];
            searchServerUrls = searchServerUrls[1];
            for (let i in searchServerUrls) {
                let convertedSearchServerUrls10 = searchServerUrls[i];
                convertedSearchServerUrls0.push(convertedSearchServerUrls10);
            }
            this.setSearchServerUrls(convertedSearchServerUrls0);
        }
        let netizenUrls = json["netizenUrls"];
        if (netizenUrls != null) {
            let convertedNetizenUrls0 = [];
            netizenUrls = netizenUrls[1];
            for (let i in netizenUrls) {
                let convertedNetizenUrls10 = netizenUrls[i];
                convertedNetizenUrls0.push(convertedNetizenUrls10);
            }
            this.setNetizenUrls(convertedNetizenUrls0);
        }
        let internationalServerUrl = json["internationalServerUrl"];
        if (internationalServerUrl != null) {
            this.setInternationalServerUrl(internationalServerUrl);
        }
        let defaultServerUrl = json["defaultServerUrl"];
        if (defaultServerUrl != null) {
            this.setDefaultServerUrl(defaultServerUrl);
        }
        let specificServers = json["specificServers"];
        if (specificServers != null) {
            let convertedSpecificServers0 = new Map<(number | string), string>();
            specificServers = specificServers[1];
            for (let k1 in specificServers) {
                let ck1 = Serializer.fromJson(k1, factory);
                let v1 = specificServers[k1];
                convertedSpecificServers0.set(ck1, v1);
            }
            this.setSpecificServers(convertedSpecificServers0);
        }
        let generalServers = json["generalServers"];
        if (generalServers != null) {
            let convertedGeneralServers0 = [];
            generalServers = generalServers[1];
            for (let i in generalServers) {
                let convertedGeneralServers10 = generalServers[i];
                convertedGeneralServers0.push(convertedGeneralServers10);
            }
            this.setGeneralServers(convertedGeneralServers0);
        }
        let maxTryConn = json["maxTryConn"];
        if (maxTryConn != null) {
            this.setMaxTryConn(maxTryConn);
        }
        let version = json["version"];
        if (version != null) {
            this.setVersion(version);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let resourceServerUrls = this.getResourceServerUrls();
        if (resourceServerUrls != null) {
            let convertedResourceServerUrls0 = [];
            for (let i in resourceServerUrls) {
                let convertedResourceServerUrls10 = resourceServerUrls[i];
                convertedResourceServerUrls0.push(convertedResourceServerUrls10);
            }
            let convertedResourceServerUrlsWithType1 = ["java.util.ArrayList", convertedResourceServerUrls0];
            json["resourceServerUrls"] = convertedResourceServerUrlsWithType1;
        }
        let searchServerUrls = this.getSearchServerUrls();
        if (searchServerUrls != null) {
            let convertedSearchServerUrls0 = [];
            for (let i in searchServerUrls) {
                let convertedSearchServerUrls10 = searchServerUrls[i];
                convertedSearchServerUrls0.push(convertedSearchServerUrls10);
            }
            let convertedSearchServerUrlsWithType1 = ["java.util.ArrayList", convertedSearchServerUrls0];
            json["searchServerUrls"] = convertedSearchServerUrlsWithType1;
        }
        let netizenUrls = this.getNetizenUrls();
        if (netizenUrls != null) {
            let convertedNetizenUrls0 = [];
            for (let i in netizenUrls) {
                let convertedNetizenUrls10 = netizenUrls[i];
                convertedNetizenUrls0.push(convertedNetizenUrls10);
            }
            let convertedNetizenUrlsWithType1 = ["java.util.ArrayList", convertedNetizenUrls0];
            json["netizenUrls"] = convertedNetizenUrlsWithType1;
        }
        let internationalServerUrl = this.getInternationalServerUrl();
        if (internationalServerUrl != null) {
            json["internationalServerUrl"] = internationalServerUrl;
        }
        let defaultServerUrl = this.getDefaultServerUrl();
        if (defaultServerUrl != null) {
            json["defaultServerUrl"] = defaultServerUrl;
        }
        let specificServers = this.getSpecificServers();
        if (specificServers != null) {
            let convertedSpecificServers0 = {};
            specificServers.forEach((v1, k1, m1) => {
                let ck1 = Serializer.getJson(k1, JsonTypeTagType.NONE);
                convertedSpecificServers0[ck1] = v1;
            });
            let convertedSpecificServersWithType1 = ["java.util.HashMap", convertedSpecificServers0];
            json["specificServers"] = convertedSpecificServersWithType1;
        }
        let generalServers = this.getGeneralServers();
        if (generalServers != null) {
            let convertedGeneralServers0 = [];
            for (let i in generalServers) {
                let convertedGeneralServers10 = generalServers[i];
                convertedGeneralServers0.push(convertedGeneralServers10);
            }
            let convertedGeneralServersWithType1 = ["java.util.ArrayList", convertedGeneralServers0];
            json["generalServers"] = convertedGeneralServersWithType1;
        }
        let maxTryConn = this.getMaxTryConn();
        if (maxTryConn != null) {
            json["maxTryConn"] = maxTryConn;
        }
        let version = this.getVersion();
        if (version != null) {
            json["version"] = version;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.service.ServiceDistributionSpec";
    }
}
