/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { MessageRange } from "./MessageRange";
import { PositionDescriptor } from "./PositionDescriptor";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class GeoSelectPositions
  extends AbstractSerializable
  implements PositionDescriptor
{
  localRadiusFiltered: boolean;
  localRange: MessageRange;
  mediumRange: MessageRange;
  globalRange: MessageRange;
  attentionRange: MessageRange;

  constructor() {
    super();
  }

  public isLocalRadiusFiltered(): boolean {
    return this.localRadiusFiltered;
  }

  public setLocalRadiusFiltered(localRadiusFiltered: boolean) {
    this.localRadiusFiltered = localRadiusFiltered;
  }

  public getLocalRange(): MessageRange {
    return this.localRange;
  }

  public setLocalRange(localRange: MessageRange) {
    this.localRange = localRange;
  }

  public getMediumRange(): MessageRange {
    return this.mediumRange;
  }

  public setMediumRange(mediumRange: MessageRange) {
    this.mediumRange = mediumRange;
  }

  public getGlobalRange(): MessageRange {
    return this.globalRange;
  }

  public setGlobalRange(globalRange: MessageRange) {
    this.globalRange = globalRange;
  }

  public getAttentionRange(): MessageRange {
    return this.attentionRange;
  }

  public setAttentionRange(attentionRange: MessageRange) {
    this.attentionRange = attentionRange;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const localRadiusFiltered = json["localRadiusFiltered"];
    if (localRadiusFiltered != null) {
      this.setLocalRadiusFiltered(localRadiusFiltered);
    }
    const localRange = json["localRange"];
    if (localRange != null) {
      const convertedLocalRange0 = Serializer.fillFromJsonObjectWithTypeTag(
        localRange,
        "__type",
        factory
      );
      this.setLocalRange(convertedLocalRange0);
    }
    const mediumRange = json["mediumRange"];
    if (mediumRange != null) {
      const convertedMediumRange0 = Serializer.fillFromJsonObjectWithTypeTag(
        mediumRange,
        "__type",
        factory
      );
      this.setMediumRange(convertedMediumRange0);
    }
    const globalRange = json["globalRange"];
    if (globalRange != null) {
      const convertedGlobalRange0 = Serializer.fillFromJsonObjectWithTypeTag(
        globalRange,
        "__type",
        factory
      );
      this.setGlobalRange(convertedGlobalRange0);
    }
    const attentionRange = json["attentionRange"];
    if (attentionRange != null) {
      const convertedAttentionRange0 = Serializer.fillFromJsonObjectWithTypeTag(
        attentionRange,
        "__type",
        factory
      );
      this.setAttentionRange(convertedAttentionRange0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const localRadiusFiltered = this.isLocalRadiusFiltered();
    if (localRadiusFiltered != null) {
      json["localRadiusFiltered"] = localRadiusFiltered;
    }
    const localRange = this.getLocalRange();
    if (localRange != null) {
      const convertedLocalRange0 = localRange.getJson(JsonTypeTagType.TYPE);
      json["localRange"] = convertedLocalRange0;
    }
    const mediumRange = this.getMediumRange();
    if (mediumRange != null) {
      const convertedMediumRange0 = mediumRange.getJson(JsonTypeTagType.TYPE);
      json["mediumRange"] = convertedMediumRange0;
    }
    const globalRange = this.getGlobalRange();
    if (globalRange != null) {
      const convertedGlobalRange0 = globalRange.getJson(JsonTypeTagType.TYPE);
      json["globalRange"] = convertedGlobalRange0;
    }
    const attentionRange = this.getAttentionRange();
    if (attentionRange != null) {
      const convertedAttentionRange0 = attentionRange.getJson(
        JsonTypeTagType.TYPE
      );
      json["attentionRange"] = convertedAttentionRange0;
    }

    if (includeTypeTag) {
      json["__type"] = "GeoSelectPositions";
    }
  }

  public getTypeId(): string {
    return "GeoSelectPositions";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.paging.GeoSelectPositions";
  }
}
