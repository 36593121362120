/**
 * Created by Zhonghua on 15/11/2016.
 */

import { Map } from "../util/Map";
import {
  JAVA_ARRAY_LIST,
  JAVA_BOOLEAN,
  JAVA_HASH_MAP,
  JAVA_LONG,
  JAVA_OBJECT,
  JAVA_OBJECT_ARRAY,
  JAVA_STRING,
  JsonSerializable,
  TYPE_ID_GETTER,
  TYPE_TAG,
} from "./JsonSerializable";
import { JsonTypeTagType } from "./JsonTypeTagType";
import { TypeFactory } from "./TypeFactory";
import { DefaultSessionContext } from "@/services/session";
//import {TypeMapper} from "@/services/serialize/TypeMapper";

const USE_MAP = true;
const MAP_TYPE_NAME = "Map";
const GET_JSON_FUNCTION = "getJson";

export class Serializer {
  public static isJsonSerializable(obj): boolean {
    return obj && typeof obj === "object" && obj.fillFromJson && obj.fillToJson;
  }

  public static getType(obj: Object): string {
    const typeIdGetter = obj[TYPE_ID_GETTER];
    if (typeIdGetter && typeof typeIdGetter === "function") {
      return typeIdGetter.apply(obj, []);
    } else {
      return null;
    }
  }

  public static isMap(obj: any): boolean {
    return Serializer.getType(obj) === MAP_TYPE_NAME;
  }

  public static isJavaLongName(json: any): boolean {
    return json === JAVA_LONG;
  }
  public static isJavaStringName(json: any): boolean {
    return json === JAVA_STRING;
  }
  public static isJavaBooleanName(json: any): boolean {
    return json === JAVA_BOOLEAN;
  }

  public static isJavaListName(json: any): boolean {
    return json === JAVA_ARRAY_LIST;
  }

  public static isJavaMapName(json: any): boolean {
    return json === JAVA_HASH_MAP;
  }

  public static isJavaObjectArrayName(json: any): boolean {
    return json === JAVA_OBJECT_ARRAY;
  }

  public static isJavaObjectName(json: any): boolean {
    return json === JAVA_OBJECT;
  }

  public static fillFromJsonObjectWithClassInfo(
    json: any,
    factory: TypeFactory
  ): any {
    if (Array.isArray(json)) {
      // is it an enum with interface on the Java side?
      const jsonArray = json as any[];
      if (jsonArray.length === 2 && typeof jsonArray[0] === "string") {
        const typeCandidate = jsonArray[0] as string;
        const valueCandidate = jsonArray[1];
        const obj = factory.createInstanceByClassId(typeCandidate);
        if (obj) {
          obj.fillFromJson(valueCandidate, factory);
          return obj;
        } else {
          console.warn(
            "Failed to crete onject for " +
              typeCandidate +
              ", and fall back to the default deserialization."
          );
        }
      } else {
        console.warn(
          "Expect class information in Json while found none" +
            ", and fall back to the default deserialization."
        );
      }
    } else {
      console.warn(
        "Expect class information in Json while found none" +
          ", and fall back to the default deserialization."
      );
    }
    return Serializer.fromJson(json, factory);
  }

  public static fillFromJsonObjectWithTypeTag(
    json: any,
    typeTag: string,
    factory: TypeFactory
  ): any {
    if (json == null) {
      return null;
    } else if (Array.isArray(json)) {
      return Serializer.fillFromJsonObjectWithClassInfo(json, factory);
    } else if (json.hasOwnProperty(typeTag)) {
      const typeId = json[TYPE_TAG];
      const obj = factory.createInstanceByTypeId(typeId);
      if (obj) {
        obj.fillFromJson(json, factory);
        return obj;
      }
      return obj;
    } else {
      console.warn(
        "Failed to find type ID under tag: " +
          typeTag +
          ", and fall back to the default deserialization."
      );
      return Serializer.fromJson(json, factory);
    }
  }

  public static fillFromJsonObject(
    obj: Object,
    jsonObj: Object,
    factory: TypeFactory
  ) {
    if (Serializer.isJsonSerializable(obj)) {
      const serializableObj = obj as JsonSerializable;
      serializableObj.fillFromJson(jsonObj, factory);
    } else {
      Serializer.doFillFromJsonObject(obj, jsonObj, factory);
    }
  }

  public static fillFromJson(value: any, json: any, factory: TypeFactory): any {
    if (Array.isArray(json)) {
      Serializer.fromJsonToArray(value as any[], json as any[], factory);
      return value;
    } else if (typeof json === "object") {
      if (Serializer.isMap(value)) {
        return Serializer.fillFromJsonToMap(
          value as Map<any, any>,
          json as Object,
          factory
        );
      } else {
        return Serializer.fillFromJsonObject(value as Object, json, factory);
      }
    } else {
      return json;
    }
  }

  public static fromJson(json: any, factory: TypeFactory): any {
    let obj;
    if (json == null) {
      return null;
    } else if (Array.isArray(json)) {
      // is it an enum with interface on the Java side?
      const jsonArray = json as any[];
      if (jsonArray.length === 2 && typeof jsonArray[0] === "string") {
        const typeCandidate = jsonArray[0] as string;
        const valueCandidate = jsonArray[1];
        if (factory.isEnum(typeCandidate)) {
          return factory.getEnumValue(typeCandidate, valueCandidate);
        } else if (
          Serializer.isJavaObjectArrayName(typeCandidate) ||
          Serializer.isJavaListName(typeCandidate)
        ) {
          obj = [];
          Serializer.fromJsonToArray(obj, valueCandidate, factory);
          return obj;
        } else if (Serializer.isJavaMapName(typeCandidate)) {
          return Serializer.fromJsonToMap(valueCandidate as Object, factory);
        } else if (
          Serializer.isJavaLongName(typeCandidate) ||
          Serializer.isJavaStringName(typeCandidate)
        ) {
          return valueCandidate as string;
        } else if (Serializer.isJavaBooleanName(typeCandidate)) {
          return valueCandidate === "true";
        } else {
          obj = factory.createInstanceByClassId(typeCandidate);
          if (obj) {
            obj.fillFromJson(valueCandidate, factory);
            return obj;
          }
        }
      }
      const arrayVal = [];
      Serializer.fromJsonToArray(arrayVal, jsonArray, factory);
      return arrayVal;
    } else if (typeof json === "object") {
      if (json.hasOwnProperty(TYPE_TAG)) {
        const typeId = json[TYPE_TAG];
        obj = factory.createInstanceByTypeId(typeId);
        obj.fillFromJson(json, factory);
        return obj;
      } else {
        return Serializer.fromJsonToMap(json as Object, factory);
      }
    } else {
      return json;
    }
  }

  public static fromJsonString(json: any, factory: TypeFactory): any {
    try {
      const jsonObj = JSON.parse(json);
      return Serializer.fromJson(jsonObj, factory);
    } catch (ex) {
      console.error(
        "Failed to deserialize message: \n" +
          json +
          "\n with error:\n" +
          ex.toString()
      );
      throw ex;
    }
  }

  /**
   * 这里的方法不应被直接调用。对象的序列化应通过它本身的getJson方法，以达到与Java端的兼容。
   */
  public static getJson(
    val: any,
    tagType: JsonTypeTagType = JsonTypeTagType.TYPE
  ): any {
    if (val == null) {
      return val;
    } else if (Array.isArray(val)) {
      const jsonArr = [];
      Serializer.fillJsonFromArray(val as any[], jsonArr, tagType);
      if (tagType === JsonTypeTagType.CLASS) {
        return [JAVA_OBJECT_ARRAY, jsonArr];
      } else {
        return jsonArr;
      }
    } else if (typeof val === "object") {
      const getJsonFunction = val[GET_JSON_FUNCTION];
      if (getJsonFunction && typeof getJsonFunction === "function") {
        return getJsonFunction.apply(val, [tagType]);
      } else {
        const jsonObj = {};
        const obj = val as Object;
        if (Serializer.isMap(obj)) {
          const map = obj as Map<any, any>;
          Serializer.fillJsonFromMap(map, jsonObj, tagType);
          if (tagType === JsonTypeTagType.CLASS) {
            return [JAVA_HASH_MAP, jsonObj];
          } else {
            return jsonObj;
          }
        } else {
          Serializer.fillJsonFromObject(obj, jsonObj);
          return jsonObj;
        }
      }
    } else {
      return val;
    }
  }

  public static toJsonString(obj: Object): string {
    const jsonObj = Serializer.getJson(obj);
    return JSON.stringify(jsonObj);
  }

  /**
   * ----------------------------------------------------------------------------------------------
   * ----------------------------------  from json to object  -------------------------------------
   * ----------------------------------------------------------------------------------------------
   */

  private static doFillFromJsonObject(
    obj: Object,
    jsonObj: Object,
    factory: TypeFactory
  ) {
    for (const propName in jsonObj) {
      if (TYPE_TAG !== propName) {
        obj[propName] = Serializer.fromJson(jsonObj[propName], factory);
      }
    }
  }

  private static fromJsonToArray(
    value: any[],
    jsonArray: any[],
    factory: TypeFactory
  ) {
    for (let i = 0; i < jsonArray.length; i++) {
      value[i] = Serializer.fromJson(jsonArray[i], factory);
    }
  }

  private static fromJsonToMap(jsonMap: Object, factory: TypeFactory): Object {
    if (USE_MAP) {
      const map = new Map<any, any>();
      Serializer.fillFromJsonToMap(map, jsonMap, factory);
      return map;
    } else {
      for (const propName in jsonMap) {
        jsonMap[propName] = Serializer.fromJson(jsonMap[propName], factory);
      }
      return jsonMap;
    }
  }

  private static fillFromJsonToMap(
    objMap: Map<any, any>,
    jsonMap: Object,
    factory
  ) {
    for (const propName in jsonMap) {
      objMap.set(propName, Serializer.fromJson(jsonMap[propName], factory));
    }
  }

  /**
   * ----------------------------------------------------------------------------------------------
   * ----------------------------------  from object to json  -------------------------------------
   * ----------------------------------------------------------------------------------------------
   */

  private static doFillJsonFromObject(obj: Object, jsonObj: Object) {
    for (const p in obj) {
      if (typeof obj[p] !== "function") {
        jsonObj[p] = Serializer.getJson(obj[p]);
      }
    }
    const typeIdGetter = obj[TYPE_ID_GETTER];
    if (typeIdGetter && typeof typeIdGetter === "function") {
      const typeId = typeIdGetter.apply(obj, []);
      if (typeId) {
        jsonObj[TYPE_TAG] = typeId;
      }
    }
  }

  private static fillJsonFromObject(obj: Object, jsonObj: Object) {
    if (obj) {
      if (Serializer.isJsonSerializable(obj)) {
        (obj as JsonSerializable).fillToJson(jsonObj, true);
      } else {
        Serializer.doFillJsonFromObject(obj, jsonObj);
      }
    }
  }

  private static fillJsonFromMap(
    map: Map<any, any>,
    json: Object,
    tagType: JsonTypeTagType
  ) {
    map.forEach((v, k, m, thisArg?) => {
      json[k as string] = Serializer.getJson(v, tagType);
    });
  }

  private static fillJsonFromArray(
    arr: any[],
    jsonArr: any[],
    tagType: JsonTypeTagType
  ) {
    console.log(
      "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"
    );
    for (const v of arr) {
      jsonArr.push(Serializer.getJson(v, tagType));
    }
  }
}
