// @ts-nocheck
import { AbstractRequestStatus } from "./AbstractRequestStatus";
import { CoworkType } from "../cowork/CoworkType";
import {EntityInfo} from "../entity/EntityInfo";
import {MessageType} from "../../message/MessageContentType";
import {RequestStatusType} from "./RequestStatusType";

export class RequestStatus extends AbstractRequestStatus {
  constructor() {
    super();
  }

  /**
   * 必选数据： 请求ID
   */
  public withRequestId(requestId: number | string): RequestStatus {
    this.requestId = requestId;
    return this;
  }

  /**
   * 必选数据： 请求者ID
   */
  public withRequestorId(requestorId: number | string): RequestStatus {
    this.requestorId = requestorId;
    return this;
  }

  /**
   * 必选数据： 请求对象类型
   */
  public withTargetType(targetType: CoworkType): RequestStatus {
    this.targetType = targetType;
    return this;
  }

  /**
   * 必选数据： 请求对象ID
   */
  public withTargetId(targetId: number | string): RequestStatus {
    this.targetId = targetId;
    return this;
  }

  /**
   * 必选数据： 请求对象所属社区ID
   */
  public withTargetEntityInfo(targetEntityInfo: EntityInfo): RequestStatus {
    this.targetEntityInfo = targetEntityInfo;
    return this;
  }

  /**
   * 必选数据： 请求者所代表的社区ID
   */
  public withRefEntityInfo(refEntityInfo: EntityInfo): RequestStatus {
    this.refEntityInfo = refEntityInfo;
    return this;
  }

  /**
   * 必选数据： 请求状态
   */
  public withStatus(status: RequestStatusType): RequestStatus {
    this.status = status;
    return this;
  }

  /**
   * 必选数据： 请求解决时间
   */
  public withResolvedTime(resolvedTime: number | string): RequestStatus {
    this.resolvedTime = resolvedTime;
    return this;
  }
}
