/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {AbstractEnterpriseServiceTrade} from '../datatype/business/service/trade/AbstractEnterpriseServiceTrade'
import {BusinessContactInfo} from '../datatype/business/BusinessContactInfo'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EnterpriseServiceOrderService} from '../api/business/service/EnterpriseServiceOrderService'
import {EnterpriseServiceOrder} from '../datatype/business/order/EnterpriseServiceOrder'
import {EnterpriseServicePackageTrade} from '../datatype/business/service/trade/EnterpriseServicePackageTrade'
import {EnterpriseServiceTradeInfo} from '../datatype/business/service/trade/EnterpriseServiceTradeInfo'
import {InvoiceRequest} from '../datatype/business/order/InvoiceRequest'
//import {InvoiceTemplate} from '../datatype/business/order/InvoiceTemplate'
import {OrderQuerySpec} from '../datatype/business/order/OrderQuerySpec'
import {OrderStatus} from '../datatype/business/order/OrderStatus'
import {PageableData} from '../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {PaymentSpec} from '../datatype/business/payment/PaymentSpec'
import {ThirdPartyPayClientInfo} from '../datatype/business/payment/ThirdPartyPayClientInfo'
import {WebEnterpriseServiceOrderService} from '../webapi/WebEnterpriseServiceOrderService'
import {WebSessionManager} from '../common/WebSessionManager'


export class WebEnterpriseServiceOrderServiceImpl implements WebEnterpriseServiceOrderService {

    private enterpriseServiceOrderService: EnterpriseServiceOrderService;

    public constructor() {
        this.enterpriseServiceOrderService = DefaultServiceFactory.getInstance().getEnterpriseServiceOrderService();
    }

    public addContactInfo(orderId: (number | string | null), enterpriseId: (number | string | null), contactInfo: BusinessContactInfo): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.addContactInfo(WebSessionManager.getSessionContext(),
        	orderId, enterpriseId, contactInfo,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public addEnterpriseServiceOrder(enterpriseServiceOrder: EnterpriseServiceOrder): Promise<AbstractEnterpriseServiceTrade> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.addEnterpriseServiceOrder(WebSessionManager.getSessionContext(),
        	enterpriseServiceOrder,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    // public addInvoiceTemplate(invoiceTemplate: (InvoiceTemplate | null)): Promise<(number | string)> {
    //     return new Promise((resolve, reject) => {
    //     	this.enterpriseServiceOrderService.addInvoiceTemplate(WebSessionManager.getSessionContext(),
    //     	invoiceTemplate,
    //     	(t, u) => {
    //     		if (u) { reject(u); }
    //     		else { resolve(t); }
    //     	});
    //     });
    // }

    public attachEnterpirseToTrade(tradeId: (number | string | null), enterpriseId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.attachEnterpirseToTrade(WebSessionManager.getSessionContext(),
        	tradeId, enterpriseId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public cancelEnterpriseServiceOrder(enterpriseServiceOrderId: (number | string | null), reason: (string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.cancelEnterpriseServiceOrder(WebSessionManager.getSessionContext(),
        	enterpriseServiceOrderId, reason,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public createEnterpriseServiceOrderWithRefTradeId(refTradeId: (number | string), enterpriseId: (number | string | null)): Promise<EnterpriseServiceOrder> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.createEnterpriseServiceOrderWithRefTradeId(WebSessionManager.getSessionContext(),
        	refTradeId, enterpriseId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public createEnterpriseServiceOrderWithTradableId(tradableId: (number | string), enterpriseId: (number | string | null)): Promise<EnterpriseServiceOrder> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.createEnterpriseServiceOrderWithTradableId(WebSessionManager.getSessionContext(),
        	tradableId, enterpriseId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public deleteInvoiceTemplateByTempId(tempId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.deleteInvoiceTemplateByTempId(WebSessionManager.getSessionContext(),
        	tempId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findContactInfosByUser(userId: (number | string | null)): Promise<Array<BusinessContactInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.findContactInfosByUser(WebSessionManager.getSessionContext(),
        	userId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    // public findDefaultInvoiceTemplateByUserId(): Promise<InvoiceTemplate> {
    //     return new Promise((resolve, reject) => {
    //     	this.enterpriseServiceOrderService.findDefaultInvoiceTemplateByUserId(WebSessionManager.getSessionContext(),
		//
    //     	(t, u) => {
    //     		if (u) { reject(u); }
    //     		else { resolve(t); }
    //     	});
    //     });
    // }

    public findEnterpriseServiceOrdersByRegionId(regionId: (number | string | null), orderQuerySpec: (OrderQuerySpec | null)): Promise<PageableData<EnterpriseServiceOrder>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.findEnterpriseServiceOrdersByRegionId(WebSessionManager.getSessionContext(),
        	regionId, orderQuerySpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServicePackageTradeByOrderId(enterpriseServiceOrderId: (number | string | null)): Promise<EnterpriseServicePackageTrade> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.findEnterpriseServicePackageTradeByOrderId(WebSessionManager.getSessionContext(),
        	enterpriseServiceOrderId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceTradeById(tradeId: (number | string | null)): Promise<AbstractEnterpriseServiceTrade> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.findEnterpriseServiceTradeById(WebSessionManager.getSessionContext(),
        	tradeId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceTradesByOrderId(enterpriseServiceOrderId: (number | string | null)): Promise<Array<EnterpriseServiceTradeInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.findEnterpriseServiceTradesByOrderId(WebSessionManager.getSessionContext(),
        	enterpriseServiceOrderId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findInvoiceRequestsByOrderId(orderId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<InvoiceRequest>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.findInvoiceRequestsByOrderId(WebSessionManager.getSessionContext(),
        	orderId, pageBlockSpec,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    // public findInvoiceTemplateByTempId(tempId: (number | string | null)): Promise<InvoiceTemplate> {
    //     return new Promise((resolve, reject) => {
    //     	this.enterpriseServiceOrderService.findInvoiceTemplateByTempId(WebSessionManager.getSessionContext(),
    //     	tempId,
    //     	(t, u) => {
    //     		if (u) { reject(u); }
    //     		else { resolve(t); }
    //     	});
    //     });
    // }

    // public findInvoiceTemplateByUserId(): Promise<Array<InvoiceTemplate>> {
    //     return new Promise((resolve, reject) => {
    //     	this.enterpriseServiceOrderService.findInvoiceTemplateByUserId(WebSessionManager.getSessionContext(),
		//
    //     	(t, u) => {
    //     		if (u) { reject(u); }
    //     		else { resolve(t); }
    //     	});
    //     });
    // }

    public findMainEnterpriseServiceTradeByOrderId(enterpriseServiceOrderId: (number | string | null)): Promise<AbstractEnterpriseServiceTrade> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.findMainEnterpriseServiceTradeByOrderId(WebSessionManager.getSessionContext(),
        	enterpriseServiceOrderId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getEnterpriseServiceOrderByOrderId(enterpriseServiceOrderId: (number | string | null)): Promise<EnterpriseServiceOrder> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.getEnterpriseServiceOrderByOrderId(WebSessionManager.getSessionContext(),
        	enterpriseServiceOrderId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getEnterpriseServiceOrderByOrderIdAfterPaySuccess(enterpriseServiceOrderId: (number | string | null)): Promise<EnterpriseServiceOrder> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.getEnterpriseServiceOrderByOrderIdAfterPaySuccess(WebSessionManager.getSessionContext(),
        	enterpriseServiceOrderId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getEnterpriseServiceOrderStatusByOrderId(enterpriseServiceOrderId: (number | string | null)): Promise<OrderStatus> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.getEnterpriseServiceOrderStatusByOrderId(WebSessionManager.getSessionContext(),
        	enterpriseServiceOrderId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getPayClientInfoForServiceOrder(payment: PaymentSpec): Promise<ThirdPartyPayClientInfo> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.getPayClientInfoForServiceOrder(WebSessionManager.getSessionContext(),
        	payment,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public renewServiceOrder(currentOrderId: (number | string | null)): Promise<AbstractEnterpriseServiceTrade> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.renewServiceOrder(WebSessionManager.getSessionContext(),
        	currentOrderId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public renewServiceOrderByCurrentTradeId(currentTradeId: (number | string | null)): Promise<AbstractEnterpriseServiceTrade> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.renewServiceOrderByCurrentTradeId(WebSessionManager.getSessionContext(),
        	currentTradeId,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public submitInvoiceRequest(invoiceRequest: (InvoiceRequest | null)): Promise<(number | string)> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServiceOrderService.submitInvoiceRequest(WebSessionManager.getSessionContext(),
        	invoiceRequest,
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

	addInvoiceTemplate(invoiceTemplate: | null): Promise<number | string> {
		return Promise.resolve (undefined);
	}

	findDefaultInvoiceTemplateByUserId(): Promise<any> {
		return Promise.resolve (undefined);
	}

	findInvoiceTemplateByTempId(tempId: number | string | null): Promise<any> {
		return Promise.resolve (undefined);
	}

	findInvoiceTemplateByUserId(): Promise<Array<any>> {
		return Promise.resolve (undefined);
	}

	updateInvoiceTemplateByTempId(invoiceTemplate: | null): Promise<boolean> {
		return Promise.resolve (false);
	}

    // public updateInvoiceTemplateByTempId(invoiceTemplate: (InvoiceTemplate | null)): Promise<boolean> {
    //     return new Promise((resolve, reject) => {
    //     	this.enterpriseServiceOrderService.updateInvoiceTemplateByTempId(WebSessionManager.getSessionContext(),
    //     	invoiceTemplate,
    //     	(t, u) => {
    //     		if (u) { reject(u); }
    //     		else { resolve(t); }
    //     	});
    //     });
    // }

}
