/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {AdministrativeUnit} from './AdministrativeUnit'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {RegionType} from './RegionType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractRegionSpec extends AbstractSerializable implements AdministrativeUnit  {
    nid: (number | string);
    name: string;
    pyName: string;
    regionType: RegionType;
    latitude: number;
    longitude: number;
    mapId: string;
    locationName: string;
    parentEntityId: (number | string);
    provinceName: string;
    provincePyName: string;
    topLevelCity: boolean;

    constructor() {
    	super();
    }

    public getNid(): (number | string) {
    	return this.nid;
    }

    public setNid(nid: (number | string)) {
    	this.nid = nid;
    }

    public getName(): string {
    	return this.name;
    }

    public setName(name: string) {
    	this.name = name;
    }

    public getPyName(): string {
    	return this.pyName;
    }

    public setPyName(pyName: string) {
    	this.pyName = pyName;
    }

    public getRegionType(): RegionType {
    	return this.regionType;
    }

    public setRegionType(regionType: RegionType) {
    	this.regionType = regionType;
    }

    public getLatitude(): number {
    	return this.latitude;
    }

    public setLatitude(latitude: number) {
    	this.latitude = latitude;
    }

    public getLongitude(): number {
    	return this.longitude;
    }

    public setLongitude(longitude: number) {
    	this.longitude = longitude;
    }

    public getMapId(): string {
    	return this.mapId;
    }

    public setMapId(mapId: string) {
    	this.mapId = mapId;
    }

    public getLocationName(): string {
    	return this.locationName;
    }

    public setLocationName(locationName: string) {
    	this.locationName = locationName;
    }

    public getParentEntityId(): (number | string) {
    	return this.parentEntityId;
    }

    public setParentEntityId(parentEntityId: (number | string)) {
    	this.parentEntityId = parentEntityId;
    }

    public getProvinceName(): string {
    	return this.provinceName;
    }

    public setProvinceName(provinceName: string) {
    	this.provinceName = provinceName;
    }

    public getProvincePyName(): string {
    	return this.provincePyName;
    }

    public setProvincePyName(provincePyName: string) {
    	this.provincePyName = provincePyName;
    }

    public isTopLevelCity(): boolean {
    	return this.topLevelCity;
    }

    public setTopLevelCity(topLevelCity: boolean) {
    	this.topLevelCity = topLevelCity;
    }


    abstract getLat(): number;

    abstract getLng(): number;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let nid = json["nid"];
        if (nid != null) {
            this.setNid(nid);
        }
        let name = json["name"];
        if (name != null) {
            this.setName(name);
        }
        let pyName = json["pyName"];
        if (pyName != null) {
            this.setPyName(pyName);
        }
        let regionType = json["regionType"];
        if (regionType != null) {
            let convertedRegionType0 = RegionType[<string>regionType];
            this.setRegionType(convertedRegionType0);
        }
        let latitude = json["latitude"];
        if (latitude != null) {
            this.setLatitude(latitude);
        }
        let longitude = json["longitude"];
        if (longitude != null) {
            this.setLongitude(longitude);
        }
        let mapId = json["mapId"];
        if (mapId != null) {
            this.setMapId(mapId);
        }
        let locationName = json["locationName"];
        if (locationName != null) {
            this.setLocationName(locationName);
        }
        let parentEntityId = json["parentEntityId"];
        if (parentEntityId != null) {
            this.setParentEntityId(parentEntityId);
        }
        let provinceName = json["provinceName"];
        if (provinceName != null) {
            this.setProvinceName(provinceName);
        }
        let provincePyName = json["provincePyName"];
        if (provincePyName != null) {
            this.setProvincePyName(provincePyName);
        }
        let topLevelCity = json["topLevelCity"];
        if (topLevelCity != null) {
            this.setTopLevelCity(topLevelCity);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let nid = this.getNid();
        if (nid != null) {
            json["nid"] = String(nid);
        }
        let name = this.getName();
        if (name != null) {
            json["name"] = name;
        }
        let pyName = this.getPyName();
        if (pyName != null) {
            json["pyName"] = pyName;
        }
        let regionType = this.getRegionType();
        if (regionType != null) {
            let convertedRegionType0 = RegionType[regionType];
            json["regionType"] = convertedRegionType0;
        }
        let latitude = this.getLatitude();
        if (latitude != null) {
            json["latitude"] = latitude;
        }
        let longitude = this.getLongitude();
        if (longitude != null) {
            json["longitude"] = longitude;
        }
        let mapId = this.getMapId();
        if (mapId != null) {
            json["mapId"] = mapId;
        }
        let locationName = this.getLocationName();
        if (locationName != null) {
            json["locationName"] = locationName;
        }
        let parentEntityId = this.getParentEntityId();
        if (parentEntityId != null) {
            json["parentEntityId"] = String(parentEntityId);
        }
        let provinceName = this.getProvinceName();
        if (provinceName != null) {
            json["provinceName"] = provinceName;
        }
        let provincePyName = this.getProvincePyName();
        if (provincePyName != null) {
            json["provincePyName"] = provincePyName;
        }
        let topLevelCity = this.isTopLevelCity();
        if (topLevelCity != null) {
            json["topLevelCity"] = topLevelCity;
        }
    
        if (includeTypeTag) {
            json["__type"] = "RegionSpec";
        }
    }


    public getTypeId(): string {
    	return "RegionSpec";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.region.RegionSpec";
    }
}
