/**
 * 自动生成， 请不要手工修改！
 */

export enum ProfileAspectType {
  DETAILS,
  GENERAL_INFO,
  ENDORSEMENT,
  MEMBERSHIP,
  COMMUNICATION,
  BANK_ACCOUNTS,
  EXPERTISE,
  CREDIT,
  PERSONAL,
  SECURITY,
  ASSET_ACCOUNT,
  EDUCATION,
  PROD_EXPERTISE,
  PROD_COVERAGE,
  GROUP_CHARTER,
}
