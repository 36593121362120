/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkType } from "./CoworkType";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class CoworkSubIdentifier extends AbstractSerializable {
  objectType: CoworkType;
  id: number | string;
  subType: string;
  creatorId: number | string;

  constructor() {
    super();
  }

  public getObjectType(): CoworkType {
    return this.objectType;
  }

  public setObjectType(objectType: CoworkType) {
    this.objectType = objectType;
  }

  public getId(): number | string {
    return this.id;
  }

  public setId(id: number | string) {
    this.id = id;
  }

  public getSubType(): string {
    return this.subType;
  }

  public setSubType(subType: string) {
    this.subType = subType;
  }

  public getCreatorId(): number | string {
    return this.creatorId;
  }

  public setCreatorId(creatorId: number | string) {
    this.creatorId = creatorId;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const objectType = json["objectType"];
    if (objectType != null) {
      const convertedObjectType0 = CoworkType[<string>objectType];
      this.setObjectType(convertedObjectType0);
    }
    const id = json["id"];
    if (id != null) {
      this.setId(id);
    }
    const subType = json["subType"];
    if (subType != null) {
      this.setSubType(subType);
    }
    const creatorId = json["creatorId"];
    if (creatorId != null) {
      this.setCreatorId(creatorId);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const objectType = this.getObjectType();
    if (objectType != null) {
      const convertedObjectType0 = CoworkType[objectType];
      json["objectType"] = convertedObjectType0;
    }
    const id = this.getId();
    if (id != null) {
      json["id"] = String(id);
    }
    const subType = this.getSubType();
    if (subType != null) {
      json["subType"] = subType;
    }
    const creatorId = this.getCreatorId();
    if (creatorId != null) {
      json["creatorId"] = String(creatorId);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.CoworkSubIdentifier";
  }
}
