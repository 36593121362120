/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../../serialize/AbstractJsonSerializable";
import { EnterpriseServiceAwardType } from "./EnterpriseServiceAwardType";
import { JsonTypeTagType } from "../../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../../serialize/TypeFactory";

export class ServicePurchaseAward extends AbstractSerializable {
  minTradeAmount: number;
  awardType: EnterpriseServiceAwardType;
  awardAmount: number;
  serviceId: number | string;

  constructor() {
    super();
  }

  public getMinTradeAmount(): number {
    return this.minTradeAmount;
  }

  public setMinTradeAmount(minTradeAmount: number) {
    this.minTradeAmount = minTradeAmount;
  }

  public getAwardType(): EnterpriseServiceAwardType {
    return this.awardType;
  }

  public setAwardType(awardType: EnterpriseServiceAwardType) {
    this.awardType = awardType;
  }

  public getAwardAmount(): number {
    return this.awardAmount;
  }

  public setAwardAmount(awardAmount: number) {
    this.awardAmount = awardAmount;
  }

  public getServiceId(): number | string {
    return this.serviceId;
  }

  public setServiceId(serviceId: number | string) {
    this.serviceId = serviceId;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const minTradeAmount = json["minTradeAmount"];
    if (minTradeAmount != null) {
      this.setMinTradeAmount(minTradeAmount);
    }
    const awardType = json["awardType"];
    if (awardType != null) {
      const convertedAwardType0 = EnterpriseServiceAwardType[<string>awardType];
      this.setAwardType(convertedAwardType0);
    }
    const awardAmount = json["awardAmount"];
    if (awardAmount != null) {
      this.setAwardAmount(awardAmount);
    }
    const serviceId = json["serviceId"];
    if (serviceId != null) {
      this.setServiceId(serviceId);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const minTradeAmount = this.getMinTradeAmount();
    if (minTradeAmount != null) {
      json["minTradeAmount"] = minTradeAmount;
    }
    const awardType = this.getAwardType();
    if (awardType != null) {
      const convertedAwardType0 = EnterpriseServiceAwardType[awardType];
      json["awardType"] = convertedAwardType0;
    }
    const awardAmount = this.getAwardAmount();
    if (awardAmount != null) {
      json["awardAmount"] = awardAmount;
    }
    const serviceId = this.getServiceId();
    if (serviceId != null) {
      json["serviceId"] = String(serviceId);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.tradable.ServicePurchaseAward";
  }
}
