// @ts-nocheck
import {AbstractRegionSpec} from './AbstractRegionSpec'


export class RegionSpec extends AbstractRegionSpec  {
    constructor() {
    	super();
    }

    getLat(): number {
      // Manual Code Here
    }
    getLng(): number {
      // Manual Code Here
    }

}
