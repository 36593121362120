/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { ApprovalValue } from "./ApprovalValue";
import { EntityInfo } from "../EntityInfo";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Serializer } from "../../../serialize/Serializer";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class NetizenContribution extends AbstractSerializable {
  userInfo: EntityInfo;
  contributionVal: number;
  approvalValue: ApprovalValue;

  constructor() {
    super();
  }

  public getUserInfo(): EntityInfo {
    return this.userInfo;
  }

  public setUserInfo(userInfo: EntityInfo) {
    this.userInfo = userInfo;
  }

  public getContributionVal(): number {
    return this.contributionVal;
  }

  public setContributionVal(contributionVal: number) {
    this.contributionVal = contributionVal;
  }

  public getApprovalValue(): ApprovalValue {
    return this.approvalValue;
  }

  public setApprovalValue(approvalValue: ApprovalValue) {
    this.approvalValue = approvalValue;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const userInfo = json["userInfo"];
    if (userInfo != null) {
      const convertedUserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        userInfo,
        "__type",
        factory
      );
      this.setUserInfo(convertedUserInfo0);
    }
    const contributionVal = json["contributionVal"];
    if (contributionVal != null) {
      this.setContributionVal(contributionVal);
    }
    const approvalValue = json["approvalValue"];
    if (approvalValue != null) {
      const convertedApprovalValue0 = ApprovalValue[<string>approvalValue];
      this.setApprovalValue(convertedApprovalValue0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const userInfo = this.getUserInfo();
    if (userInfo != null) {
      const convertedUserInfo0 = userInfo.getJson(JsonTypeTagType.TYPE);
      json["userInfo"] = convertedUserInfo0;
    }
    const contributionVal = this.getContributionVal();
    if (contributionVal != null) {
      json["contributionVal"] = contributionVal;
    }
    const approvalValue = this.getApprovalValue();
    if (approvalValue != null) {
      const convertedApprovalValue0 = ApprovalValue[approvalValue];
      json["approvalValue"] = convertedApprovalValue0;
    }

    if (includeTypeTag) {
      json["__type"] = "NetizenContribution";
    }
  }

  public getTypeId(): string {
    return "NetizenContribution";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.enterprise.NetizenContribution";
  }
}
