/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { EntityInfo } from "../../entity/EntityInfo";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Serializer } from "../../../serialize/Serializer";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class UserSponsorInfo extends AbstractSerializable {
  userInfo: EntityInfo;
  sponsorshipAmount: number;
  description: string;

  constructor() {
    super();
  }

  public getUserInfo(): EntityInfo {
    return this.userInfo;
  }

  public setUserInfo(userInfo: EntityInfo) {
    this.userInfo = userInfo;
  }

  public getSponsorshipAmount(): number {
    return this.sponsorshipAmount;
  }

  public setSponsorshipAmount(sponsorshipAmount: number) {
    this.sponsorshipAmount = sponsorshipAmount;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const userInfo = json["userInfo"];
    if (userInfo != null) {
      const convertedUserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        userInfo,
        "__type",
        factory
      );
      this.setUserInfo(convertedUserInfo0);
    }
    const sponsorshipAmount = json["sponsorshipAmount"];
    if (sponsorshipAmount != null) {
      this.setSponsorshipAmount(sponsorshipAmount);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const userInfo = this.getUserInfo();
    if (userInfo != null) {
      const convertedUserInfo0 = userInfo.getJson(JsonTypeTagType.TYPE);
      json["userInfo"] = convertedUserInfo0;
    }
    const sponsorshipAmount = this.getSponsorshipAmount();
    if (sponsorshipAmount != null) {
      json["sponsorshipAmount"] = sponsorshipAmount;
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.qa.UserSponsorInfo";
  }
}
