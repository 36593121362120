// @ts-nocheck
import {AbstractGeneralUserAdInfo} from './AbstractGeneralUserAdInfo'
import {CoworkInfo} from '../../cowork/CoworkInfo'
import {CoworkSimpleView} from '../../cowork/CoworkSimpleView'
import {CoworkStats} from '../../cowork/CoworkStats'
import {CoworkType} from '../../cowork/CoworkType'
import {EntityInfo} from '../../entity/EntityInfo'
import {GeoHash} from '../../geolocation/GeoHash'
import {SelectTimes} from '../../cowork/SelectTimes'
import {UserAdType} from '../UserAdType'


export class GeneralUserAdInfo extends AbstractGeneralUserAdInfo  {
    constructor() {
    	super();
    }

    hasImageUrls(): boolean {
      // Manual Code Here
    }
    isByPlatform(): boolean {
      // Manual Code Here
    }
    isObsolete(): boolean {
      // Manual Code Here
    }
    isObsoleteInDays(arg0: (number | null)): boolean {
      // Manual Code Here
    }
    isPlatformActivity(): boolean {
      // Manual Code Here
    }
    getActualInitiatorInfo(): EntityInfo {
      // Manual Code Here
    }
    getGeohash(): GeoHash {
      // Manual Code Here
    }
    getCVisits(): number {
      // Manual Code Here
    }
    getProperVisits(): number {
      // Manual Code Here
    }
    getScore(): number {
      // Manual Code Here
    }
    getWeighted(): number {
      // Manual Code Here
    }
    getVisitOffset(): number {
      // Manual Code Here
    }
    getRefEntityId(): (number | string) {
      // Manual Code Here
    }
    getTargetEntityId(): (number | string) {
      // Manual Code Here
    }
    getCityName(): string {
      // Manual Code Here
    }
    getCommonDescription(): string {
      // Manual Code Here
    }
    getDisplayTitle(arg0: (boolean | null)): string {
      // Manual Code Here
    }
    getLocationName(): string {
      // Manual Code Here
    }
    getMainImageUrl(): string {
      // Manual Code Here
    }
    getSuggestionPkLabel(): string {
      // Manual Code Here
    }
    getTypeDisplayName(): string {
      // Manual Code Here
    }
    getKeywords(): Array<string> {
      // Manual Code Here
    }
    getEntityDynamicTime(): (number | string) {
      // Manual Code Here
    }
    getEntitySelectTime(): (number | string) {
      // Manual Code Here
    }
    getGlobalDynamicSelectTime(): (number | string) {
      // Manual Code Here
    }
    getGlobalSelectTime(): (number | string) {
      // Manual Code Here
    }
    getLastUpdateTime(): (number | string) {
      // Manual Code Here
    }
    getMediumDynamicSelectTime(): (number | string) {
      // Manual Code Here
    }
    getMediumSelectTime(): (number | string) {
      // Manual Code Here
    }
    getRecentTime(): (number | string) {
      // Manual Code Here
    }
    getServiceSelectTime(): (number | string) {
      // Manual Code Here
    }
    addApprovals(arg0: (number | null)) {
      // Manual Code Here
    }
    addDisapprovals(arg0: (number | null)) {
      // Manual Code Here
    }
    addPrize(arg0: (number | null)) {
      // Manual Code Here
    }
    addVisits(arg0: (number | null)) {
      // Manual Code Here
    }
    addWeighted(arg0: (number | null)) {
      // Manual Code Here
    }
    copyFrom(arg0: (CoworkInfo | null)) {
      // Manual Code Here
    }
    mergeFrom(arg0: (CoworkInfo | null)) {
      // Manual Code Here
    }
    setByPlatform(arg0: (boolean | null)) {
      // Manual Code Here
    }
    setCityName(arg0: (string | null)) {
      // Manual Code Here
    }
    setEntityDynamicTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setEntitySelectTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setGeohash(arg0: (GeoHash | null)) {
      // Manual Code Here
    }
    setGlobalDynamicSelectTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setGlobalSelectTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setInitiatorId(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setKeywords(arg0: (Array<string> | null)) {
      // Manual Code Here
    }
    setLastUpdateTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setLat(arg0: (number | null)) {
      // Manual Code Here
    }
    setLng(arg0: (number | null)) {
      // Manual Code Here
    }
    setLocationName(arg0: (string | null)) {
      // Manual Code Here
    }
    setMediumDynamicSelectTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setMediumSelectTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setObsolete(arg0: (boolean | null)) {
      // Manual Code Here
    }
    setRecentTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setRefEntityId(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setScore(arg0: (number | null)) {
      // Manual Code Here
    }
    setServiceSelectTime(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setSuggestionPkLabel(arg0: (string | null)) {
      // Manual Code Here
    }
    setTargetEntityId(arg0: (number | string | null)) {
      // Manual Code Here
    }
    setWeighted(arg0: (number | null)) {
      // Manual Code Here
    }
    isActive(): boolean {
      // Manual Code Here
    }
    copy(arg0: (boolean | null)): CoworkSimpleView {
      // Manual Code Here
    }
    getCoworkType(): CoworkType {
      // Manual Code Here
    }
    getSelectTimes(): SelectTimes {
      // Manual Code Here
    }
    getCountryCode(): number {
      // Manual Code Here
    }
    getLanguageCode(): number {
      // Manual Code Here
    }
    getInitiatorId(): (number | string) {
      // Manual Code Here
    }
    getCoworkStatus(): any {
      // Manual Code Here
    }
    getSubType(): any {
      // Manual Code Here
    }
    getDescription(): string {
      // Manual Code Here
    }
    getMainImageUrl(): string {
      // Manual Code Here
    }
    accept(arg0: (CoworkStats | null)) {
      // Manual Code Here
    }
    addSelectTimes(arg0: (SelectTimes | null)) {
      // Manual Code Here
    }
    setCoworkStatus(arg0: (any | null)) {
      // Manual Code Here
    }
    setDescription(arg0: (string | null)) {
      // Manual Code Here
    }
    setSubType(arg0: (any | null)) {
      // Manual Code Here
    }
    update(arg0: (CoworkSimpleView | null)) {
      // Manual Code Here
    }
    isEnterprisePublish(): boolean {
      // Manual Code Here
    }
    getUserAdType(): UserAdType {
      // Manual Code Here
    }
    getScore(): number {
      // Manual Code Here
    }
    getWeighted(): number {
      // Manual Code Here
    }
    getDisplayLineTextLeft(): string {
      // Manual Code Here
    }
    getDisplayLineTextRight(): string {
      // Manual Code Here
    }
    getUserAdId(): (number | string) {
      // Manual Code Here
    }

}
