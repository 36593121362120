/**
 * 自动生成， 请不要手工修改！
 */

export enum EntityChannelType {
  MANAGEMENT,
  INFORMATION,
  Q_AND_A,
  REVIEW,
  ACTIVITY,
  ADS,
  ANNOUNCEMENT,
  PRIVATE_CHAT,
  INBOX_MESSAGE,
  DIGEST,
  FORUM,
  PRIVATE_FORUM,
  ADMIN_SUPPORT,
}
