/**
 * 自动生成， 请不要手工修改！
 */

export enum UserAdType {
  BUY_HOUSE,
  SELL_HOUSE,
  RENT_HOUSE,
  LET_HOUSE,
  RENT_OFFICE,
  LET_OFFICE,
  HOME_KEEPER,
  HOME_TUTOR,
  HOME_REPAIR,
  HOME_DECORATION,
  PROVIDE_RIDE,
  REQUEST_RIDE,
  PROVIDE_JOB,
  SEEK_JOB,
  PROV_TEMP_JOB,
  SEEK_TEMP_JOB,
  NOTICE_LOST,
  NOTICE_FOUND,
  BUY_GOODS,
  SELL_GOODS,
  MAKE_FRIEND,
  SEEK_HELP,
  GENERAL_SERVICE,
  WISH,
  INITIATIVE,
  DO_TOGETHER,
  FOR_COOP,
  LABOUR,
}
