/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractEntityMessage} from "./api/AbstractEntityMessage";
import {MessageType} from "../MessageContentType";
import {TextType} from "../../datatype/TextType";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractTextMessage extends AbstractEntityMessage {
    dataContentType: MessageType;
    textType: TextType;
  data: string;

  constructor() {
    super();
  }

    public getDataContentType(): MessageType {
        return this.dataContentType;
    }

    public setDataContentType(dataContentType: MessageType) {
        this.dataContentType = dataContentType;
    }

  public getTextType(): TextType {
    return this.textType;
  }

  public setTextType(textType: TextType) {
    this.textType = textType;
  }

  public getData(): string {
    return this.data;
  }

  public setData(data: string) {
    this.data = data;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const dataContentType = json["dataContentType"];
    if (dataContentType != null) {
        const convertedDataContentType0 =
          MessageType[<string>dataContentType];
      this.setDataContentType(convertedDataContentType0);
    }
    const textType = json["textType"];
    if (textType != null) {
      const convertedTextType0 = TextType[<string>textType];
      this.setTextType(convertedTextType0);
    }
    const data = json["data"];
    if (data != null) {
      this.setData(data);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const dataContentType = this.getDataContentType();
    if (dataContentType != null) {
        const convertedDataContentType0 = MessageType[dataContentType];
      json["dataContentType"] = convertedDataContentType0;
    }
    const textType = this.getTextType();
    if (textType != null) {
      const convertedTextType0 = TextType[textType];
      json["textType"] = convertedTextType0;
    }
    const data = this.getData();
    if (data != null) {
      json["data"] = data;
    }

    if (includeTypeTag) {
      json["__type"] = "Text";
    }
  }

  public getTypeId(): string {
    return "Text";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.entity.TextMessage";
  }
}
