/**
 * 自动生成， 请不要手工修改！
 */


export enum ActivityParticipantType {
    QLL_MEMBER,
    ASSOCIATION_MEMBER,
    GENERAL
}
