/**
 * 自动生成， 请不要手工修改！
 */


export enum SettlementBillSyncStatus {
    TO_CONFIRM,
    CONFIRMED,
    IN_ADJUST
}
