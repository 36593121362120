/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { ChannelEntitlements } from "./ChannelEntitlements";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import {Map} from "../../../util/Map";
import {MessageType} from "../../../message/MessageContentType";
import {OperationType} from "../../security/OperationType";
import { Serializer } from "../../../serialize/Serializer";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractSimpleChannelEntitlements
  extends AbstractSerializable
  implements ChannelEntitlements
{
  supportedOperations: Map<MessageType, Array<OperationType>>;

  constructor() {
    super();
  }

  public getSupportedOperations(): Map<MessageType,
      Array<OperationType>> {
    return this.supportedOperations;
  }

  public setSupportedOperations(
      supportedOperations: Map<MessageType, Array<OperationType>>
  ) {
    this.supportedOperations = supportedOperations;
  }

  abstract getContentTypes(): Array<MessageType>;

  abstract getEntitledOperations(
      arg0: MessageType | null
  ): Array<OperationType>;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    let supportedOperations = json["supportedOperations"];
    if (supportedOperations != null) {
      const convertedSupportedOperations0 = new Map<MessageType,
          Array<OperationType>>();
      supportedOperations = supportedOperations[1];
      for (const k1 in supportedOperations) {
        const ck1 = MessageType[k1];
        let v1 = supportedOperations[k1];
        const convertedSupportedOperations1 = [];
        v1 = v1[1];
        for (const i in v1) {
          const convertedSupportedOperations20 = v1[i];
          const convertedSupportedOperations2 =
            OperationType[<string>convertedSupportedOperations20];
          convertedSupportedOperations1.push(convertedSupportedOperations2);
        }
        convertedSupportedOperations0.set(ck1, convertedSupportedOperations1);
      }
      this.setSupportedOperations(convertedSupportedOperations0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const supportedOperations = this.getSupportedOperations();
    if (supportedOperations != null) {
      const convertedSupportedOperations0 = {};
      supportedOperations.forEach((v1, k1, m1) => {
        const ck1 = MessageType[k1];
        const convertedSupportedOperations1 = [];
        for (const i in v1) {
          const convertedSupportedOperations20 = v1[i];
          const convertedSupportedOperations2 =
            OperationType[convertedSupportedOperations20];
          convertedSupportedOperations1.push(convertedSupportedOperations2);
        }
        const convertedSupportedOperationsWithType2 = [
          "java.util.ArrayList",
          convertedSupportedOperations1,
        ];
        convertedSupportedOperations0[ck1] =
          convertedSupportedOperationsWithType2;
      });
      const convertedSupportedOperationsWithType1 = [
        "java.util.HashMap",
        convertedSupportedOperations0,
      ];
      json["supportedOperations"] = convertedSupportedOperationsWithType1;
    }

    if (includeTypeTag) {
      json["__type"] = "SimpleChannelEntitlements";
    }
  }

  public getTypeId(): string {
    return "SimpleChannelEntitlements";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.channel.SimpleChannelEntitlements";
  }
}
