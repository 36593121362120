/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCowork} from '../cowork/AbstractCowork'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../keyword/KeywordEntity'
import {MessageType} from '../../message/MessageContentType'
import {QllCountry} from '../international/QllCountry'
import {RegionSpec} from '../entity/region/RegionSpec'
import {Serializer} from '../../serialize/Serializer'
import {SharedInfoStatus} from './SharedInfoStatus'
import {SharedInfoType} from './SharedInfoType'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractSharedInfo extends AbstractCowork  {
    infoType: SharedInfoType;
    status: SharedInfoStatus;
    urlsByEditor: boolean;
    local: boolean;
    declare regionId: number | string;
    policyRegion: RegionSpec;
    referencedEntitiesInDesc: Array<KeywordEntity>;

    constructor() {
    	super();
    }

    public getInfoType(): SharedInfoType {
    	return this.infoType;
    }

    public setInfoType(infoType: SharedInfoType) {
    	this.infoType = infoType;
    }

    public getStatus(): SharedInfoStatus {
    	return this.status;
    }

    public setStatus(status: SharedInfoStatus) {
    	this.status = status;
    }

    public isUrlsByEditor(): boolean {
    	return this.urlsByEditor;
    }

    public setUrlsByEditor(urlsByEditor: boolean) {
    	this.urlsByEditor = urlsByEditor;
    }

    public getLocal(): boolean {
    	return this.local;
    }

    public setLocal(local: boolean) {
    	this.local = local;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getPolicyRegion(): RegionSpec {
    	return this.policyRegion;
    }

    public setPolicyRegion(policyRegion: RegionSpec) {
    	this.policyRegion = policyRegion;
    }

    public getReferencedEntitiesInDesc(): Array<KeywordEntity> {
    	return this.referencedEntitiesInDesc;
    }

    public setReferencedEntitiesInDesc(referencedEntitiesInDesc: Array<KeywordEntity>) {
    	this.referencedEntitiesInDesc = referencedEntitiesInDesc;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let infoType = json["infoType"];
        if (infoType != null) {
            let convertedInfoType0 = SharedInfoType[<string>infoType];
            this.setInfoType(convertedInfoType0);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = SharedInfoStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let urlsByEditor = json["urlsByEditor"];
        if (urlsByEditor != null) {
            this.setUrlsByEditor(urlsByEditor);
        }
        let local = json["local"];
        if (local != null) {
            this.setLocal(local);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let policyRegion = json["policyRegion"];
        if (policyRegion != null) {
            let convertedPolicyRegion0 = Serializer.fillFromJsonObjectWithTypeTag(policyRegion, "__type", factory);
            this.setPolicyRegion(convertedPolicyRegion0);
        }
        let referencedEntitiesInDesc = json["referencedEntitiesInDesc"];
        if (referencedEntitiesInDesc != null) {
            let convertedReferencedEntitiesInDesc0 = [];
            referencedEntitiesInDesc = referencedEntitiesInDesc[1];
            for (let i in referencedEntitiesInDesc) {
                let convertedReferencedEntitiesInDesc10 = referencedEntitiesInDesc[i];
                let convertedReferencedEntitiesInDesc1 = new KeywordEntity();
                convertedReferencedEntitiesInDesc1.fillFromJson(convertedReferencedEntitiesInDesc10, factory);
                convertedReferencedEntitiesInDesc0.push(convertedReferencedEntitiesInDesc1);
            }
            this.setReferencedEntitiesInDesc(convertedReferencedEntitiesInDesc0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let infoType = this.getInfoType();
        if (infoType != null) {
            let convertedInfoType0 = SharedInfoType[infoType];
            json["infoType"] = convertedInfoType0;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = SharedInfoStatus[status];
            json["status"] = convertedStatus0;
        }
        let urlsByEditor = this.isUrlsByEditor();
        if (urlsByEditor != null) {
            json["urlsByEditor"] = urlsByEditor;
        }
        let local = this.getLocal();
        if (local != null) {
            json["local"] = local;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let policyRegion = this.getPolicyRegion();
        if (policyRegion != null) {
            let convertedPolicyRegion0 = policyRegion.getJson(JsonTypeTagType.TYPE);
            json["policyRegion"] = convertedPolicyRegion0;
        }
        let referencedEntitiesInDesc = this.getReferencedEntitiesInDesc();
        if (referencedEntitiesInDesc != null) {
            let convertedReferencedEntitiesInDesc0 = [];
            for (let i in referencedEntitiesInDesc) {
                let convertedReferencedEntitiesInDesc10 = referencedEntitiesInDesc[i];
                let convertedReferencedEntitiesInDesc1 = convertedReferencedEntitiesInDesc10.getJson(JsonTypeTagType.NONE);
                convertedReferencedEntitiesInDesc0.push(convertedReferencedEntitiesInDesc1);
            }
            let convertedReferencedEntitiesInDescWithType1 = ["java.util.ArrayList", convertedReferencedEntitiesInDesc0];
            json["referencedEntitiesInDesc"] = convertedReferencedEntitiesInDescWithType1;
        }

        if (includeTypeTag) {
            json["__type"] = "SharedInfo";
        }
    }


    public getTypeId(): string {
    	return "SharedInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.share.SharedInfo";
    }
}
