/**
 * 自动生成， 请不要手工修改！
 */


export enum UserAdStatus {
    IN_REVIEW,
    DUE_TO_PAY,
    ACTIVE,
    CANCELLED,
    HIDDEN,
    OBSOLETE,
    FINISHED,
    DISLIKED
}
