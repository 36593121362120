/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../../../common/AbstractClientServiceAdapter'
import {AdContent} from '../../../datatype/business/ad/AdContent'
import {BiConsumer} from '../../../util/BiConsumer'
import {CompositeCoworkView} from '../../../datatype/cowork/CompositeCoworkView'
import {CoworkIdentifier} from '../../../datatype/cowork/CoworkIdentifier'
import {EntityType} from '../../../datatype/entity/EntityType'
import {FansInfo} from '../../../datatype/entity/FansInfo'
import {FinancialAccountInfo} from '../../../datatype/business/payment/FinancialAccountInfo'
import {Location} from '../../../datatype/geolocation/Location'
import {PageableData} from '../../../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../../../datatype/paging/PageBlockAccessSpec'
import {PersonalService} from '../../../api/entity/user/PersonalService'
import {PlatformDataContainer} from '../../../datatype/platform/PlatformDataContainer'
import {ProductView} from '../../../datatype/product/ProductView'
import {ReviewOverview} from '../../../datatype/ipsphere/review/ReviewOverview'
import {ServiceConsumers} from '../../../util/ServiceConsumers'
import {ServiceRequest} from '../../../common/ServiceRequest'
import {SessionContext} from '../../../session/SessionContext'
import {UserFavorite} from '../../../datatype/entity/user/UserFavorite'
import {UserHomePageInfo} from '../../../datatype/entity/user/UserHomePageInfo'
import {UserInfoTabType} from '../../../datatype/entity/user/UserInfoTabType'
import {UserLocalCircle} from '../../../datatype/entity/user/UserLocalCircle'
import {UserServices} from '../../../datatype/entity/user/UserServices'


export class PersonalServiceProxy extends AbstractClientServiceAdapter implements PersonalService {

    public constructor() {
        super("com.gong_wei.service.api.entity.user.PersonalService");
    }

    public addFinancialAccount(sessionContext: (SessionContext | null), financialAccountInfo: (FinancialAccountInfo | null), resultHandler: BiConsumer<(number | string), any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "addFinancialAccount", [sessionContext, financialAccountInfo]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public addMyFavorite(sessionContext: (SessionContext | null), userFavorite: (UserFavorite | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "addMyFavorite", [sessionContext, userFavorite]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public addRecommendEntity(sessionContext: (SessionContext | null), adContent: (AdContent | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "addRecommendEntity", [sessionContext, adContent]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public deleteFinancialAccount(sessionContext: (SessionContext | null), userId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "deleteFinancialAccount", [sessionContext, userId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public dislike(sessionContext: (SessionContext | null), subjectId: (CoworkIdentifier | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "dislike", [sessionContext, subjectId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findCoworkInfosByEntityId(sessionContext: (SessionContext | null), userId: (number | string | null), tabType: (UserInfoTabType | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<CompositeCoworkView<any, any>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "findCoworkInfosByEntityId", [sessionContext, userId, tabType, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findDynamicInfosByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "findDynamicInfosByEntityId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findHonoursByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<ReviewOverview>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "findHonoursByEntityId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findInfosToUserByOthers(sessionContext: (SessionContext | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "findInfosToUserByOthers", [sessionContext, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findRecentInfosByEntityId(sessionContext: (SessionContext | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<CompositeCoworkView<any, any>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "findRecentInfosByEntityId", [sessionContext, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findRecommendedReviewsByEntityId(sessionContext: (SessionContext | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<ReviewOverview>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "findRecommendedReviewsByEntityId", [sessionContext, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findSharedInfosByEntityId(sessionContext: (SessionContext | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<CompositeCoworkView<any, any>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "findSharedInfosByEntityId", [sessionContext, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getCollectedCoworks(sessionContext: (SessionContext | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<CompositeCoworkView<any, any>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getCollectedCoworks", [sessionContext, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getFansInfo(sessionContext: (SessionContext | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<FansInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getFansInfo", [sessionContext, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getFinancialAccounts(sessionContext: (SessionContext | null), userId: (number | string | null), resultHandler: BiConsumer<Array<FinancialAccountInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getFinancialAccounts", [sessionContext, userId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getLocalCircle(sessionContext: (SessionContext | null), userId: (number | string | null), size: (number | null), resultHandler: BiConsumer<UserLocalCircle, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getLocalCircle", [sessionContext, userId, size]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getLocalCycleAdContents(sessionContext: (SessionContext | null), resultHandler: BiConsumer<Array<AdContent>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getLocalCycleAdContents", [sessionContext]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getMyFansInfo(sessionContext: (SessionContext | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<FansInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getMyFansInfo", [sessionContext, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getProducts(sessionContext: (SessionContext | null), userId: (number | string | null), location: (Location | null), distance: (number | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<ProductView>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getProducts", [sessionContext, userId, location, distance, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getUserFavorites(sessionContext: (SessionContext | null), userId: (number | string | null), entityType: (EntityType | null), pageBlockAccessSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<UserFavorite>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getUserFavorites", [sessionContext, userId, entityType, pageBlockAccessSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getUserHomePageInfo(sessionContext: (SessionContext | null), userId: (number | string | null), resultHandler: BiConsumer<UserHomePageInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getUserHomePageInfo", [sessionContext, userId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getUserServices(sessionContext: (SessionContext | null), userId: (number | string | null), resultHandler: BiConsumer<UserServices, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getUserServices", [sessionContext, userId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getUserWorks(sessionContext: (SessionContext | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<CompositeCoworkView<any, any>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "getUserWorks", [sessionContext, userId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public removeMyFavorite(sessionContext: (SessionContext | null), userFavorite: (UserFavorite | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "removeMyFavorite", [sessionContext, userFavorite]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public removeRecommendEntity(sessionContext: (SessionContext | null), adContent: (AdContent | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "removeRecommendEntity", [sessionContext, adContent]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public setNoActivePush(sessionContext: (SessionContext | null), userId: (number | string | null), noActivePush: (boolean | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "setNoActivePush", [sessionContext, userId, noActivePush]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public setPrivateChatEnabled(sessionContext: (SessionContext | null), userId: (number | string | null), privateChatOn: (boolean | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "setPrivateChatEnabled", [sessionContext, userId, privateChatOn]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public setPublishEnabled(sessionContext: (SessionContext | null), userId: (number | string | null), publishOn: (boolean | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.user.PersonalService", "setPublishEnabled", [sessionContext, userId, publishOn]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

}
