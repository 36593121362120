// 此处为跳转订单详情面包屑传递
export default {
  namespaced: true, // 为每个模块添加一个前缀名，保证模块命明不冲突
  state: () => {
    return {
      currentRole: {},
      roleIndex: 0,
    };
  },
  mutations: {
    updateCurrentRole(state, payload) {
      state.currentRole = payload;
    },
    updateRoleIndex(state, payload) {
      state.roleIndex = payload;
    },
  },
  actions: {},
};
