/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {ProfileAspectType} from './ProfileAspectType'
import {ProfileAspect} from './ProfileAspect'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractPersonalProfileAspect extends AbstractSerializable implements ProfileAspect  {
    sex: string;
    city: string;
    personalDesc: string;
    birthDate: (number | string);

    constructor() {
    	super();
    }

    public getSex(): string {
    	return this.sex;
    }

    public setSex(sex: string) {
    	this.sex = sex;
    }

    public getCity(): string {
    	return this.city;
    }

    public setCity(city: string) {
    	this.city = city;
    }

    public getPersonalDesc(): string {
    	return this.personalDesc;
    }

    public setPersonalDesc(personalDesc: string) {
    	this.personalDesc = personalDesc;
    }

    public getBirthDate(): (number | string) {
    	return this.birthDate;
    }

    public setBirthDate(birthDate: (number | string)) {
    	this.birthDate = birthDate;
    }


    abstract getProfileAspectType(): ProfileAspectType;

    abstract getEntityId(): (number | string);

    abstract setEntityId(arg0: (number | string | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let sex = json["sex"];
        if (sex != null) {
            this.setSex(sex);
        }
        let city = json["city"];
        if (city != null) {
            this.setCity(city);
        }
        let personalDesc = json["personalDesc"];
        if (personalDesc != null) {
            this.setPersonalDesc(personalDesc);
        }
        let birthDate = json["birthDate"];
        if (birthDate != null) {
            this.setBirthDate(birthDate);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let sex = this.getSex();
        if (sex != null) {
            json["sex"] = sex;
        }
        let city = this.getCity();
        if (city != null) {
            json["city"] = city;
        }
        let personalDesc = this.getPersonalDesc();
        if (personalDesc != null) {
            json["personalDesc"] = personalDesc;
        }
        let birthDate = this.getBirthDate();
        if (birthDate != null) {
            json["birthDate"] = String(birthDate);
        }
    
        if (includeTypeTag) {
            json["__type"] = "PlainPersonalProfileAspect";
        }
    }


    public getTypeId(): string {
    	return "PlainPersonalProfileAspect";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.profile.PersonalProfileAspect";
    }
}
