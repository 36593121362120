/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { EntityOperationType } from "../../../message/entity/api/EntityOperationType";
import { RoleType } from "../../entity/RoleType";
import { TextSource } from "../../../message/entity/api/TextSource";
import { TextType } from "../../TextType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractEntityRoleSpec
  extends AbstractSerializable
  implements TextSource
{
  requestEntityId: number | string;
  targetEntityId: number | string;
  operationType: EntityOperationType;
  roleType: RoleType;

  constructor() {
    super();
  }

  public getRequestEntityId(): number | string {
    return this.requestEntityId;
  }

  public setRequestEntityId(requestEntityId: number | string) {
    this.requestEntityId = requestEntityId;
  }

  public getTargetEntityId(): number | string {
    return this.targetEntityId;
  }

  public setTargetEntityId(targetEntityId: number | string) {
    this.targetEntityId = targetEntityId;
  }

  public getOperationType(): EntityOperationType {
    return this.operationType;
  }

  public setOperationType(operationType: EntityOperationType) {
    this.operationType = operationType;
  }

  public getRoleType(): RoleType {
    return this.roleType;
  }

  public setRoleType(roleType: RoleType) {
    this.roleType = roleType;
  }

  abstract getTextType(): TextType;

  abstract getText(): string;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const requestEntityId = json["requestEntityId"];
    if (requestEntityId != null) {
      this.setRequestEntityId(requestEntityId);
    }
    const targetEntityId = json["targetEntityId"];
    if (targetEntityId != null) {
      this.setTargetEntityId(targetEntityId);
    }
    const operationType = json["operationType"];
    if (operationType != null) {
      const convertedOperationType0 =
        EntityOperationType[<string>operationType];
      this.setOperationType(convertedOperationType0);
    }
    const roleType = json["roleType"];
    if (roleType != null) {
      const convertedRoleType0 = RoleType[<string>roleType];
      this.setRoleType(convertedRoleType0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const requestEntityId = this.getRequestEntityId();
    if (requestEntityId != null) {
      json["requestEntityId"] = String(requestEntityId);
    }
    const targetEntityId = this.getTargetEntityId();
    if (targetEntityId != null) {
      json["targetEntityId"] = String(targetEntityId);
    }
    const operationType = this.getOperationType();
    if (operationType != null) {
      const convertedOperationType0 = EntityOperationType[operationType];
      json["operationType"] = convertedOperationType0;
    }
    const roleType = this.getRoleType();
    if (roleType != null) {
      const convertedRoleType0 = RoleType[roleType];
      json["roleType"] = convertedRoleType0;
    }

    if (includeTypeTag) {
      json["__type"] = "EntityRoleSpec";
    }
  }

  public getTypeId(): string {
    return "EntityRoleSpec";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.request.entity.EntityRoleSpec";
  }
}
