/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { EntityType } from "../../entity/EntityType";
import { Filter } from "../Filter";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Pair } from "../../../util/Pair";
import { RoleType } from "../../entity/RoleType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class EntityFilter extends AbstractSerializable implements Filter {
  sourceEntityId: number | string;
  keywords: string[];
  entityRoleType: RoleType;
  targetEntityType: EntityType;
  whereMap: Pair<string[], any[]>;
  whereOperator: string[];

  constructor() {
    super();
  }

  public getSourceEntityId(): number | string {
    return this.sourceEntityId;
  }

  public setSourceEntityId(sourceEntityId: number | string) {
    this.sourceEntityId = sourceEntityId;
  }

  public getKeywords(): string[] {
    return this.keywords;
  }

  public setKeywords(keywords: string[]) {
    this.keywords = keywords;
  }

  public getEntityRoleType(): RoleType {
    return this.entityRoleType;
  }

  public setEntityRoleType(entityRoleType: RoleType) {
    this.entityRoleType = entityRoleType;
  }

  public getTargetEntityType(): EntityType {
    return this.targetEntityType;
  }

  public setTargetEntityType(targetEntityType: EntityType) {
    this.targetEntityType = targetEntityType;
  }

  public getWhereMap(): Pair<string[], any[]> {
    return this.whereMap;
  }

  public setWhereMap(whereMap: Pair<string[], any[]>) {
    this.whereMap = whereMap;
  }

  public getWhereOperator(): string[] {
    return this.whereOperator;
  }

  public setWhereOperator(whereOperator: string[]) {
    this.whereOperator = whereOperator;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const sourceEntityId = json["sourceEntityId"];
    if (sourceEntityId != null) {
      this.setSourceEntityId(sourceEntityId);
    }
    const keywords = json["keywords"];
    if (keywords != null) {
      const convertedKeywords0 = [];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      this.setKeywords(convertedKeywords0);
    }
    const entityRoleType = json["entityRoleType"];
    if (entityRoleType != null) {
      const convertedEntityRoleType0 = RoleType[<string>entityRoleType];
      this.setEntityRoleType(convertedEntityRoleType0);
    }
    const targetEntityType = json["targetEntityType"];
    if (targetEntityType != null) {
      const convertedTargetEntityType0 = EntityType[<string>targetEntityType];
      this.setTargetEntityType(convertedTargetEntityType0);
    }
    const whereMap = json["whereMap"];
    if (whereMap != null) {
      const convertedWhereMap0 = new Pair<string[], any[]>();
      convertedWhereMap0.fillFromJson(whereMap, factory);
      this.setWhereMap(convertedWhereMap0);
    }
    const whereOperator = json["whereOperator"];
    if (whereOperator != null) {
      const convertedWhereOperator0 = [];
      for (const i in whereOperator) {
        const convertedWhereOperator10 = whereOperator[i];
        convertedWhereOperator0.push(convertedWhereOperator10);
      }
      this.setWhereOperator(convertedWhereOperator0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const sourceEntityId = this.getSourceEntityId();
    if (sourceEntityId != null) {
      json["sourceEntityId"] = String(sourceEntityId);
    }
    const keywords = this.getKeywords();
    if (keywords != null) {
      const convertedKeywords0 = [];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      json["keywords"] = convertedKeywords0;
    }
    const entityRoleType = this.getEntityRoleType();
    if (entityRoleType != null) {
      const convertedEntityRoleType0 = RoleType[entityRoleType];
      json["entityRoleType"] = convertedEntityRoleType0;
    }
    const targetEntityType = this.getTargetEntityType();
    if (targetEntityType != null) {
      const convertedTargetEntityType0 = EntityType[targetEntityType];
      json["targetEntityType"] = convertedTargetEntityType0;
    }
    const whereMap = this.getWhereMap();
    if (whereMap != null) {
      const convertedWhereMap0 = whereMap.getJson(JsonTypeTagType.NONE);
      json["whereMap"] = convertedWhereMap0;
    }
    const whereOperator = this.getWhereOperator();
    if (whereOperator != null) {
      const convertedWhereOperator0 = [];
      for (const i in whereOperator) {
        const convertedWhereOperator10 = whereOperator[i];
        convertedWhereOperator0.push(convertedWhereOperator10);
      }
      json["whereOperator"] = convertedWhereOperator0;
    }

    if (includeTypeTag) {
      json["__type"] = "EntityFilter";
    }
  }

  public getTypeId(): string {
    return "EntityFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.entity.EntityFilter";
  }
}
