/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../../../common/AbstractClientServiceAdapter'
import {AppealInfoView} from '../../../datatype/appeal/AppealInfoView'
import {BiConsumer} from '../../../util/BiConsumer'
import {CompositeCoworkView} from '../../../datatype/cowork/CompositeCoworkView'
import {CoworkHighlight} from '../../../datatype/cowork/CoworkHighlight'
import {CoworkType} from '../../../datatype/cowork/CoworkType'
import {EnterpriseChat} from '../../../datatype/entity/enterprise/EnterpriseChat'
import {EnterpriseHomePageInfo} from '../../../datatype/entity/enterprise/EnterpriseHomePageInfo'
import {EnterpriseHomeService} from '../../../api/entity/enterprise/EnterpriseHomeService'
import {EnterpriseInfoTabType} from '../../../datatype/entity/enterprise/EnterpriseInfoTabType'
import {EnterpriseServiceOrder} from '../../../datatype/business/order/EnterpriseServiceOrder'
import {EnterpriseServiceTradeInfo} from '../../../datatype/business/service/trade/EnterpriseServiceTradeInfo'
import {EntityOfficialRoleView} from '../../../datatype/entity/EntityOfficialRoleView'
import {GeoEntityDesc} from '../../../datatype/geolocation/GeoEntityDesc'
import {Location} from '../../../datatype/geolocation/Location'
import {OrderQuerySpec} from '../../../datatype/business/order/OrderQuerySpec'
import {PageableData} from '../../../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../../../datatype/paging/PageBlockAccessSpec'
import {PlatformDataContainer} from '../../../datatype/platform/PlatformDataContainer'
import {ProductView} from '../../../datatype/product/ProductView'
import {ReviewOverview} from '../../../datatype/ipsphere/review/ReviewOverview'
import {RoleType} from '../../../datatype/entity/RoleType'
import {ServiceConsumers} from '../../../util/ServiceConsumers'
import {ServiceRequest} from '../../../common/ServiceRequest'
import {ServiceType} from '../../../datatype/entity/topic/ServiceType'
import {SessionContext} from '../../../session/SessionContext'
import {SimpleEntityView} from '../../../datatype/entity/view/SimpleEntityView'
import {TradeQuerySpec} from '../../../datatype/business/general/trade/TradeQuerySpec'


export class EnterpriseHomeServiceProxy extends AbstractClientServiceAdapter implements EnterpriseHomeService {

    public constructor() {
        super("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService");
    }

    public addEmployeeByPhoneNumber(sessionContext: (SessionContext | null), entityId: (number | string | null), phoneNumber: (string | null), resultHandler: BiConsumer<SimpleEntityView, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "addEmployeeByPhoneNumber", [sessionContext, entityId, phoneNumber]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public addHighlight(sessionContext: (SessionContext | null), highlight: (CoworkHighlight | null), resultHandler: BiConsumer<(number | string), any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "addHighlight", [sessionContext, highlight]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public addMemeberByPhoneNumber(sessionContext: (SessionContext | null), entityId: (number | string | null), role: (RoleType | null), phoneNumber: (string | null), citizenName: (string | null), description: (string | null), resultHandler: BiConsumer<SimpleEntityView, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "addMemeberByPhoneNumber", [sessionContext, entityId, role, phoneNumber, citizenName, description]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public addOrCreateMemeberByPhoneNumber(sessionContext: (SessionContext | null), entityId: (number | string | null), role: (RoleType | null), phoneNumber: (string | null), citizenName: (string | null), location: (Location | null), description: (string | null), resultHandler: BiConsumer<SimpleEntityView, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "addOrCreateMemeberByPhoneNumber", [sessionContext, entityId, role, phoneNumber, citizenName, location, description]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public deleteServiceAssociation(sessionContext: (SessionContext | null), communityId: (number | string | null), serviceType: (ServiceType | null), providerId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "deleteServiceAssociation", [sessionContext, communityId, serviceType, providerId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public detachCowork(sessionContext: (SessionContext | null), entityId: (number | string | null), coworkType: (CoworkType | null), coworkRid: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "detachCowork", [sessionContext, entityId, coworkType, coworkRid]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findActiveEnterpriseServicePackageTradesByEnterpriseId(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceTradeInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findActiveEnterpriseServicePackageTradesByEnterpriseId", [sessionContext, enterpriseId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findActiveEnterpriseServiceTradesByEnterpriseId(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceTradeInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findActiveEnterpriseServiceTradesByEnterpriseId", [sessionContext, enterpriseId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findAdvicesAndReviewsByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findAdvicesAndReviewsByEntityId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findCoworkInfosByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), tabType: (EnterpriseInfoTabType | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<CompositeCoworkView<any, any>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findCoworkInfosByEntityId", [sessionContext, entityId, tabType, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceOrdersByEnterpriseId(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), orderQuerySpec: (OrderQuerySpec | null), resulter: BiConsumer<PageableData<EnterpriseServiceOrder>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findEnterpriseServiceOrdersByEnterpriseId", [sessionContext, enterpriseId, orderQuerySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resulter);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServicePackageTradesByEnterpriseId(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), tradeQuerySpec: (TradeQuerySpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceTradeInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findEnterpriseServicePackageTradesByEnterpriseId", [sessionContext, enterpriseId, tradeQuerySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceTradesByEnterpriseId(sessionContext: (SessionContext | null), enterpriseId: (number | string | null), tradeQuerySpec: (TradeQuerySpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceTradeInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findEnterpriseServiceTradesByEnterpriseId", [sessionContext, enterpriseId, tradeQuerySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEntityInfosByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), tabType: (EnterpriseInfoTabType | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findEntityInfosByEntityId", [sessionContext, entityId, tabType, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findHonoursByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), entityOnly: (boolean | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findHonoursByEntityId", [sessionContext, entityId, entityOnly, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findLawyersByLawFirmId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<GeoEntityDesc>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findLawyersByLawFirmId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findRecentBusOpportuniesByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), location: (Location | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findRecentBusOpportuniesByEntityId", [sessionContext, entityId, location, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findRecommendedNegativeReviewsByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<ReviewOverview>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findRecommendedNegativeReviewsByEntityId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findRecommendedPositiveReviewsByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<ReviewOverview>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findRecommendedPositiveReviewsByEntityId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findRecommendedReviewsByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<ReviewOverview>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findRecommendedReviewsByEntityId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findSelectedBusOpportuniesByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), location: (Location | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findSelectedBusOpportuniesByEntityId", [sessionContext, entityId, location, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findSelectedRecommendationsByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), location: (Location | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<CompositeCoworkView<any, any>>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findSelectedRecommendationsByEntityId", [sessionContext, entityId, location, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findSuggestionsByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<AppealInfoView>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "findSuggestionsByEntityId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getAdminByEnterpriseId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<SimpleEntityView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "getAdminByEnterpriseId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEmployeeByEnterpriseId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<SimpleEntityView>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "getEmployeeByEnterpriseId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEnterpriseChats(sessionContext: (SessionContext | null), entityId: (number | string | null), lastGetTime: (number | string | null), pageBlockAccessSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<EnterpriseChat>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "getEnterpriseChats", [sessionContext, entityId, lastGetTime, pageBlockAccessSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEnterpriseHomePageInfo(sessionContext: (SessionContext | null), entityId: (number | string | null), resultHandler: BiConsumer<PlatformDataContainer<EnterpriseHomePageInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "getEnterpriseHomePageInfo", [sessionContext, entityId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEntityOfficialRoleView(sessionContext: (SessionContext | null), entityId: (number | string | null), resultHandler: BiConsumer<EntityOfficialRoleView, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "getEntityOfficialRoleView", [sessionContext, entityId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getMemberedSectorAssociations(sessionContext: (SessionContext | null), entityId: (number | string | null), pageAccessSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "getMemberedSectorAssociations", [sessionContext, entityId, pageAccessSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getMembersByIdAndRole(sessionContext: (SessionContext | null), entityId: (number | string | null), roleType: (RoleType | null), descendant: (boolean | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<SimpleEntityView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "getMembersByIdAndRole", [sessionContext, entityId, roleType, descendant, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getProductsByEnterpriseId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<ProductView>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "getProductsByEnterpriseId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getRecentReviewsByEntityId(sessionContext: (SessionContext | null), entityId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PlatformDataContainer<PageableData<ReviewOverview>>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "getRecentReviewsByEntityId", [sessionContext, entityId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public removeHighlight(sessionContext: (SessionContext | null), entityId: (number | string | null), highlightId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "removeHighlight", [sessionContext, entityId, highlightId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public updateHighlights(sessionContext: (SessionContext | null), highlights: (Array<CoworkHighlight> | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.entity.enterprise.EnterpriseHomeService", "updateHighlights", [sessionContext, highlights]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

}
