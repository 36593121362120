/**
 * 自动生成， 请不要手工修改！
 */


export enum ServicePriceDiscountType {
    PLATFORM,
    QLL_MEMBER,
    PACKAGE,
    OFFER
}
