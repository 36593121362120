/**
 * Created by Zhonghua on 18/11/2016.
 */

export class KeyValuePair<K, V> {
  public key: K;
  public value: V;
  constructor(key: K, value: V) {
    this.key = key;
    this.value = value;
  }
}
export class Map<K, V> {
  // class MapDDD<K,V> implements Map
  // -------------- Fields -----------------------
  private keyAndValues: Array<KeyValuePair<K, V>>;
  // ---------------------------------------------
  constructor() {
    this.keyAndValues = [];
  }
  // --- Public Methods ---
  public getKeysOfValue(value: V) {
    const keysToReturn: K[] = [];
    const valueToFind = value;
    this.keyAndValues.forEach(function (
      value1: KeyValuePair<K, V>,
      index: number,
      array: Array<KeyValuePair<K, V>>
    ): void {
      if (value1.value === valueToFind) {
        keysToReturn.push(value1.key);
      }
    });
    return keysToReturn;
  }

  // Standard:
  public clear(): void {
    this.keyAndValues = [];
  }
  public delete(key: K): boolean {
    let found = false;
    this.keyAndValues.forEach(function (
      value: KeyValuePair<K, V>,
      index: number,
      array: Array<KeyValuePair<K, V>>
    ): void {
      if (found) {
        return;
      }
      if (key === value.key) {
        array = array.slice(0, index).concat(array.slice(index + 1));
        found = true;
      }
    });
    return found;
  }
  public forEach(
    callbackfn: (value: V, key: K, map: Map<K, V>) => void,
    thisArg?: any
  ): void {
    this.keyAndValues.forEach(function (
      value: KeyValuePair<K, V>,
      index: number,
      array: Array<KeyValuePair<K, V>>
    ): void {
      callbackfn.apply(thisArg, [value.value, value.key, this]);
    },
    this);
  }
  public getKeyAndValues(): Array<KeyValuePair<K, V>> {
    return this.keyAndValues;
  }
  public get(key: K): V {
    let valueToReturn: V;
    this.keyAndValues.forEach(function (
      value: KeyValuePair<K, V>,
      index: number,
      array: Array<KeyValuePair<K, V>>
    ): void {
      if (valueToReturn !== undefined) {
        return;
      }
      if (key === value.key) {
        valueToReturn = value.value;
      }
    });
    return valueToReturn;
  }
  public has(key: K): boolean {
    let found = false;
    this.keyAndValues.forEach(function (
      value: KeyValuePair<K, V>,
      index: number,
      array: Array<KeyValuePair<K, V>>
    ): void {
      if (found) {
        return;
      }
      if (key === value.key) {
        found = true;
      }
    });
    return found;
  }
  public set(key: K, value: V): Map<K, V> {
    let found = false;
    const valueToSet = value;
    this.keyAndValues.forEach(function (
      value1: KeyValuePair<K, V>,
      index: number,
      array: Array<KeyValuePair<K, V>>
    ): void {
      if (found) {
        return;
      }
      if (key === value1.key) {
        found = true;
        value1.value = valueToSet;
      }
    });
    if (!found) {
      this.keyAndValues.push(new KeyValuePair<K, V>(key, valueToSet));
    }
    return this;
  }
  // ----------------------

  // Getters:
  // Standard:
  get size() {
    return this.keyAndValues.length;
  }

  public getTypeId(): string {
    return "Map";
  }
}
