/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EnterpriseServiceType} from '../../business/service/EnterpriseServiceType'
import {EnterpriseType} from '../enterprise/EnterpriseType'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class SectorServiceSpec extends AbstractSerializable {
    sectorServiceType: EnterpriseServiceType;
    serviceTypeName: string;
    serviceTypeId: (number | string);
    tradableId: (number | string);
    pkgTradableId: (number | string);
    name: string;
    imgUrl: string;
    description: string;
    regionId: (number | string);
    local: boolean;
    enterpriseType: EnterpriseType;
    enterpriseId: (number | string);
    pendingToOpen: boolean;

    constructor() {
        super();
    }

    public getSectorServiceType(): EnterpriseServiceType {
        return this.sectorServiceType;
    }

    public setSectorServiceType(sectorServiceType: EnterpriseServiceType) {
        this.sectorServiceType = sectorServiceType;
    }

    public getServiceTypeName(): string {
        return this.serviceTypeName;
    }

    public setServiceTypeName(serviceTypeName: string) {
        this.serviceTypeName = serviceTypeName;
    }

    public getServiceTypeId(): (number | string) {
        return this.serviceTypeId;
    }

    public setServiceTypeId(serviceTypeId: (number | string)) {
        this.serviceTypeId = serviceTypeId;
    }

    public getTradableId(): (number | string) {
        return this.tradableId;
    }

    public setTradableId(tradableId: (number | string)) {
        this.tradableId = tradableId;
    }

    public getPkgTradableId(): (number | string) {
        return this.pkgTradableId;
    }

    public setPkgTradableId(pkgTradableId: (number | string)) {
        this.pkgTradableId = pkgTradableId;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string) {
        this.name = name;
    }

    public getImgUrl(): string {
        return this.imgUrl;
    }

    public setImgUrl(imgUrl: string) {
        this.imgUrl = imgUrl;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string) {
        this.description = description;
    }

    public getRegionId(): (number | string) {
        return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
        this.regionId = regionId;
    }

    public getLocal(): boolean {
        return this.local;
    }

    public setLocal(local: boolean) {
        this.local = local;
    }

    public getEnterpriseType(): EnterpriseType {
        return this.enterpriseType;
    }

    public setEnterpriseType(enterpriseType: EnterpriseType) {
        this.enterpriseType = enterpriseType;
    }

    public getEnterpriseId(): (number | string) {
        return this.enterpriseId;
    }

    public setEnterpriseId(enterpriseId: (number | string)) {
        this.enterpriseId = enterpriseId;
    }

    public isPendingToOpen(): boolean {
        return this.pendingToOpen;
    }

    public setPendingToOpen(pendingToOpen: boolean) {
        this.pendingToOpen = pendingToOpen;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let sectorServiceType = json["sectorServiceType"];
        if (sectorServiceType != null) {
            let convertedSectorServiceType0 = EnterpriseServiceType[<string>sectorServiceType];
            this.setSectorServiceType(convertedSectorServiceType0);
        }
        let serviceTypeName = json["serviceTypeName"];
        if (serviceTypeName != null) {
            this.setServiceTypeName(serviceTypeName);
        }
        let serviceTypeId = json["serviceTypeId"];
        if (serviceTypeId != null) {
            this.setServiceTypeId(serviceTypeId);
        }
        let tradableId = json["tradableId"];
        if (tradableId != null) {
            this.setTradableId(tradableId);
        }
        let pkgTradableId = json["pkgTradableId"];
        if (pkgTradableId != null) {
            this.setPkgTradableId(pkgTradableId);
        }
        let name = json["name"];
        if (name != null) {
            this.setName(name);
        }
        let imgUrl = json["imgUrl"];
        if (imgUrl != null) {
            this.setImgUrl(imgUrl);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let local = json["local"];
        if (local != null) {
            this.setLocal(local);
        }
        let enterpriseType = json["enterpriseType"];
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[<string>enterpriseType];
            this.setEnterpriseType(convertedEnterpriseType0);
        }
        let enterpriseId = json["enterpriseId"];
        if (enterpriseId != null) {
            this.setEnterpriseId(enterpriseId);
        }
        let pendingToOpen = json["pendingToOpen"];
        if (pendingToOpen != null) {
            this.setPendingToOpen(pendingToOpen);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let sectorServiceType = this.getSectorServiceType();
        if (sectorServiceType != null) {
            let convertedSectorServiceType0 = EnterpriseServiceType[sectorServiceType];
            json["sectorServiceType"] = convertedSectorServiceType0;
        }
        let serviceTypeName = this.getServiceTypeName();
        if (serviceTypeName != null) {
            json["serviceTypeName"] = serviceTypeName;
        }
        let serviceTypeId = this.getServiceTypeId();
        if (serviceTypeId != null) {
            json["serviceTypeId"] = String(serviceTypeId);
        }
        let tradableId = this.getTradableId();
        if (tradableId != null) {
            json["tradableId"] = String(tradableId);
        }
        let pkgTradableId = this.getPkgTradableId();
        if (pkgTradableId != null) {
            json["pkgTradableId"] = String(pkgTradableId);
        }
        let name = this.getName();
        if (name != null) {
            json["name"] = name;
        }
        let imgUrl = this.getImgUrl();
        if (imgUrl != null) {
            json["imgUrl"] = imgUrl;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let local = this.getLocal();
        if (local != null) {
            json["local"] = local;
        }
        let enterpriseType = this.getEnterpriseType();
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[enterpriseType];
            json["enterpriseType"] = convertedEnterpriseType0;
        }
        let enterpriseId = this.getEnterpriseId();
        if (enterpriseId != null) {
            json["enterpriseId"] = String(enterpriseId);
        }
        let pendingToOpen = this.isPendingToOpen();
        if (pendingToOpen != null) {
            json["pendingToOpen"] = pendingToOpen;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.sector.SectorServiceSpec";
    }
}
