/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCoworkInfo} from '../cowork/AbstractCoworkInfo'
import {CoworkInfo} from '../cowork/CoworkInfo'
import {CoworkSimpleView} from '../cowork/CoworkSimpleView'
import {CoworkStats} from '../cowork/CoworkStats'
import {CoworkType} from '../cowork/CoworkType'
import {EntityInfo} from '../entity/EntityInfo'
import {GeoHash} from '../geolocation/GeoHash'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {SelectTimes} from '../cowork/SelectTimes'
import {TypeFactory} from '../../serialize/TypeFactory'
import {UserAdInfo} from './UserAdInfo'
import {UserAdStatus} from './UserAdStatus'
import {UserAdType} from './UserAdType'


export abstract class AbstractUserAdInfo extends AbstractCoworkInfo implements UserAdInfo  {
    address: string;
    brief: string;
    adTime: (number | string);
    publisherName: string;
    comments: number;
    calls: number;
    status: UserAdStatus;

    constructor() {
    	super();
    }

    public getAddress(): string {
    	return this.address;
    }

    public setAddress(address: string) {
    	this.address = address;
    }

    public getBrief(): string {
    	return this.brief;
    }

    public setBrief(brief: string) {
    	this.brief = brief;
    }

    public getAdTime(): (number | string) {
    	return this.adTime;
    }

    public setAdTime(adTime: (number | string)) {
    	this.adTime = adTime;
    }

    public getPublisherName(): string {
    	return this.publisherName;
    }

    public setPublisherName(publisherName: string) {
    	this.publisherName = publisherName;
    }

    public getComments(): number {
    	return this.comments;
    }

    public setComments(comments: number) {
    	this.comments = comments;
    }

    public getCalls(): number {
    	return this.calls;
    }

    public setCalls(calls: number) {
    	this.calls = calls;
    }

    public getStatus(): UserAdStatus {
    	return this.status;
    }

    public setStatus(status: UserAdStatus) {
    	this.status = status;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getKeywords(): Array<string>;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setKeywords(arg0: (Array<string> | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract getDescription(): string;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setDescription(arg0: (string | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));

    abstract isEnterprisePublish(): boolean;

    abstract getUserAdType(): UserAdType;

    abstract getDisplayLineTextLeft(): string;

    abstract getDisplayLineTextRight(): string;

    abstract getUserAdId(): (number | string);

    abstract getTypeId(): string;

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let address = json["address"];
        if (address != null) {
            this.setAddress(address);
        }
        let brief = json["brief"];
        if (brief != null) {
            this.setBrief(brief);
        }
        let adTime = json["adTime"];
        if (adTime != null) {
            this.setAdTime(adTime);
        }
        let publisherName = json["publisherName"];
        if (publisherName != null) {
            this.setPublisherName(publisherName);
        }
        let comments = json["comments"];
        if (comments != null) {
            this.setComments(comments);
        }
        let calls = json["calls"];
        if (calls != null) {
            this.setCalls(calls);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = UserAdStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let address = this.getAddress();
        if (address != null) {
            json["address"] = address;
        }
        let brief = this.getBrief();
        if (brief != null) {
            json["brief"] = brief;
        }
        let adTime = this.getAdTime();
        if (adTime != null) {
            json["adTime"] = String(adTime);
        }
        let publisherName = this.getPublisherName();
        if (publisherName != null) {
            json["publisherName"] = publisherName;
        }
        let comments = this.getComments();
        if (comments != null) {
            json["comments"] = comments;
        }
        let calls = this.getCalls();
        if (calls != null) {
            json["calls"] = calls;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = UserAdStatus[status];
            json["status"] = convertedStatus0;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.userad.AbstractUserAdInfo";
    }
}
