/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {PaymentMethod} from './PaymentMethod'
import {PaymentStatus} from './PaymentStatus'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class PaymentSpec extends AbstractSerializable {
    orderId: (number | string);
    orderReference: string;
    paymentReference: string;
    price: number;
    discount: number;
    amountFromPackage: number;
    totalAmount: number;
    amount: number;
    settleAmount: number;
    paymentMethod: PaymentMethod;
    paymentStatus: PaymentStatus;
    payTime: (number | string);

    constructor() {
        super();
    }

    public getOrderId(): (number | string) {
        return this.orderId;
    }

    public setOrderId(orderId: (number | string)) {
        this.orderId = orderId;
    }

    public getOrderReference(): string {
        return this.orderReference;
    }

    public setOrderReference(orderReference: string) {
        this.orderReference = orderReference;
    }

    public getPaymentReference(): string {
        return this.paymentReference;
    }

    public setPaymentReference(paymentReference: string) {
        this.paymentReference = paymentReference;
    }

    public getPrice(): number {
        return this.price;
    }

    public setPrice(price: number) {
        this.price = price;
    }

    public getDiscount(): number {
        return this.discount;
    }

    public setDiscount(discount: number) {
        this.discount = discount;
    }

    public getAmountFromPackage(): number {
        return this.amountFromPackage;
    }

    public setAmountFromPackage(amountFromPackage: number) {
        this.amountFromPackage = amountFromPackage;
    }

    public getTotalAmount(): number {
        return this.totalAmount;
    }

    public setTotalAmount(totalAmount: number) {
        this.totalAmount = totalAmount;
    }

    public getAmount(): number {
        return this.amount;
    }

    public setAmount(amount: number) {
        this.amount = amount;
    }

    public getSettleAmount(): number {
        return this.settleAmount;
    }

    public setSettleAmount(settleAmount: number) {
        this.settleAmount = settleAmount;
    }

    public getPaymentMethod(): PaymentMethod {
        return this.paymentMethod;
    }

    public setPaymentMethod(paymentMethod: PaymentMethod) {
        this.paymentMethod = paymentMethod;
    }

    public getPaymentStatus(): PaymentStatus {
        return this.paymentStatus;
    }

    public setPaymentStatus(paymentStatus: PaymentStatus) {
        this.paymentStatus = paymentStatus;
    }

    public getPayTime(): (number | string) {
        return this.payTime;
    }

    public setPayTime(payTime: (number | string)) {
        this.payTime = payTime;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let orderId = json["orderId"];
        if (orderId != null) {
            this.setOrderId(orderId);
        }
        let orderReference = json["orderReference"];
        if (orderReference != null) {
            this.setOrderReference(orderReference);
        }
        let paymentReference = json["paymentReference"];
        if (paymentReference != null) {
            this.setPaymentReference(paymentReference);
        }
        let price = json["price"];
        if (price != null) {
            this.setPrice(price);
        }
        let discount = json["discount"];
        if (discount != null) {
            this.setDiscount(discount);
        }
        let amountFromPackage = json["amountFromPackage"];
        if (amountFromPackage != null) {
            this.setAmountFromPackage(amountFromPackage);
        }
        let totalAmount = json["totalAmount"];
        if (totalAmount != null) {
            this.setTotalAmount(totalAmount);
        }
        let amount = json["amount"];
        if (amount != null) {
            this.setAmount(amount);
        }
        let settleAmount = json["settleAmount"];
        if (settleAmount != null) {
            this.setSettleAmount(settleAmount);
        }
        let paymentMethod = json["paymentMethod"];
        if (paymentMethod != null) {
            let convertedPaymentMethod0 = PaymentMethod[<string>paymentMethod];
            this.setPaymentMethod(convertedPaymentMethod0);
        }
        let paymentStatus = json["paymentStatus"];
        if (paymentStatus != null) {
            let convertedPaymentStatus0 = PaymentStatus[<string>paymentStatus];
            this.setPaymentStatus(convertedPaymentStatus0);
        }
        let payTime = json["payTime"];
        if (payTime != null) {
            this.setPayTime(payTime);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let orderId = this.getOrderId();
        if (orderId != null) {
            json["orderId"] = String(orderId);
        }
        let orderReference = this.getOrderReference();
        if (orderReference != null) {
            json["orderReference"] = orderReference;
        }
        let paymentReference = this.getPaymentReference();
        if (paymentReference != null) {
            json["paymentReference"] = paymentReference;
        }
        let price = this.getPrice();
        if (price != null) {
            json["price"] = price;
        }
        let discount = this.getDiscount();
        if (discount != null) {
            json["discount"] = discount;
        }
        let amountFromPackage = this.getAmountFromPackage();
        if (amountFromPackage != null) {
            json["amountFromPackage"] = amountFromPackage;
        }
        let totalAmount = this.getTotalAmount();
        if (totalAmount != null) {
            json["totalAmount"] = totalAmount;
        }
        let amount = this.getAmount();
        if (amount != null) {
            json["amount"] = amount;
        }
        let settleAmount = this.getSettleAmount();
        if (settleAmount != null) {
            json["settleAmount"] = settleAmount;
        }
        let paymentMethod = this.getPaymentMethod();
        if (paymentMethod != null) {
            let convertedPaymentMethod0 = PaymentMethod[paymentMethod];
            json["paymentMethod"] = convertedPaymentMethod0;
        }
        let paymentStatus = this.getPaymentStatus();
        if (paymentStatus != null) {
            let convertedPaymentStatus0 = PaymentStatus[paymentStatus];
            json["paymentStatus"] = convertedPaymentStatus0;
        }
        let payTime = this.getPayTime();
        if (payTime != null) {
            json["payTime"] = String(payTime);
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.payment.PaymentSpec";
    }
}
