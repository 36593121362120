/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {FinancingServiceRequestClassifierItem} from './FinancingServiceRequestClassifierItem'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class FinancingServiceRequestClassifier extends AbstractSerializable  {
    imgUrl: string;
    classifierItems: Array<FinancingServiceRequestClassifierItem>;

    constructor() {
    	super();
    }

    public getImgUrl(): string {
    	return this.imgUrl;
    }

    public setImgUrl(imgUrl: string) {
    	this.imgUrl = imgUrl;
    }

    public getClassifierItems(): Array<FinancingServiceRequestClassifierItem> {
    	return this.classifierItems;
    }

    public setClassifierItems(classifierItems: Array<FinancingServiceRequestClassifierItem>) {
    	this.classifierItems = classifierItems;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let imgUrl = json["imgUrl"];
        if (imgUrl != null) {
            this.setImgUrl(imgUrl);
        }
        let classifierItems = json["classifierItems"];
        if (classifierItems != null) {
            let convertedClassifierItems0 = [];
            classifierItems = classifierItems[1];
            for (let i in classifierItems) {
                let convertedClassifierItems10 = classifierItems[i];
                let convertedClassifierItems1 = new FinancingServiceRequestClassifierItem();
                convertedClassifierItems1.fillFromJson(convertedClassifierItems10, factory);
                convertedClassifierItems0.push(convertedClassifierItems1);
            }
            this.setClassifierItems(convertedClassifierItems0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let imgUrl = this.getImgUrl();
        if (imgUrl != null) {
            json["imgUrl"] = imgUrl;
        }
        let classifierItems = this.getClassifierItems();
        if (classifierItems != null) {
            let convertedClassifierItems0 = [];
            for (let i in classifierItems) {
                let convertedClassifierItems10 = classifierItems[i];
                let convertedClassifierItems1 = convertedClassifierItems10.getJson(JsonTypeTagType.NONE);
                convertedClassifierItems0.push(convertedClassifierItems1);
            }
            let convertedClassifierItemsWithType1 = ["java.util.ArrayList", convertedClassifierItems0];
            json["classifierItems"] = convertedClassifierItemsWithType1;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.finance.FinancingServiceRequestClassifier";
    }
}
