/**
 * 自动生成， 请不要手工修改！
 */

import {GenericEntityMessage} from "../../entity/api/GenericEntityMessage";
import {MessageType} from "../../MessageContentType";
import {ServiceRequestOp} from "./ServiceRequestOp";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractServiceRequestOpMessage extends GenericEntityMessage<ServiceRequestOp> {
    constructor() {
        super();
    }

    public getContentType(): MessageType {
        return MessageType.OP;
    }

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
    }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);

    if (includeTypeTag) {
      json["__type"] = "ServiceRequestOpMessage";
    }
  }

  public getTypeId(): string {
    return "ServiceRequestOpMessage";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.business.service.ServiceRequestOpMessage";
  }
}
