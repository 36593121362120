/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";
import { WeChatPayClientInfo } from "./WeChatPayClientInfo";

export class ThirdPartyPayClientInfo extends AbstractSerializable {
  payReference: string;
  weChatPayInfo: WeChatPayClientInfo;

  constructor() {
    super();
  }

  public getPayReference(): string {
    return this.payReference;
  }

  public setPayReference(payReference: string) {
    this.payReference = payReference;
  }

  public getWeChatPayInfo(): WeChatPayClientInfo {
    return this.weChatPayInfo;
  }

  public setWeChatPayInfo(weChatPayInfo: WeChatPayClientInfo) {
    this.weChatPayInfo = weChatPayInfo;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const payReference = json["payReference"];
    if (payReference != null) {
      this.setPayReference(payReference);
    }
    const weChatPayInfo = json["weChatPayInfo"];
    if (weChatPayInfo != null) {
      const convertedWeChatPayInfo0 = new WeChatPayClientInfo();
      convertedWeChatPayInfo0.fillFromJson(weChatPayInfo, factory);
      this.setWeChatPayInfo(convertedWeChatPayInfo0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const payReference = this.getPayReference();
    if (payReference != null) {
      json["payReference"] = payReference;
    }
    const weChatPayInfo = this.getWeChatPayInfo();
    if (weChatPayInfo != null) {
      const convertedWeChatPayInfo0 = weChatPayInfo.getJson(
        JsonTypeTagType.NONE
      );
      json["weChatPayInfo"] = convertedWeChatPayInfo0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.payment.ThirdPartyPayClientInfo";
  }
}
