/**
 * Created by Zhonghua on 14/11/2016.
 */

import {AbstractMessage} from "../message/AbstractMessage";
import {MessageType} from "../message/MessageContentType";
import {MessageResponseType} from "../message/MessageResponseType";
import { JsonTypeTagType } from "../serialize/JsonTypeTagType";
import { Serializer } from "../serialize/Serializer";
import { TypeFactory } from "../serialize/TypeFactory";
import { ServiceError } from "./ServiceError";

export class ServiceResponse extends AbstractMessage {
  constructor() {
    super();
  }
  private requestId: string;
  private sessionToken: string;
  private succeeded: boolean;
  private returnValue: any;
  private serviceError: ServiceError;
  public static createServiceResponse(
    requestId: string,
    returnValue: any
  ): ServiceResponse {
    const response = new ServiceResponse();
    response.setCorrelationId(requestId);
    response.setReturnValue(returnValue);
    return response;
  }

  public getCorrelationId(): string {
    return this.getRequestId();
  }

  public setCorrelationId(correlationId: string) {
    this.setRequestId(correlationId);
  }

  public getSessionToken(): string {
    return this.sessionToken;
  }

  public setSessionToken(sessionToken: string) {
    this.sessionToken = sessionToken;
  }

  public getReturnValue(): any {
    return this.returnValue;
  }

    public setReturnValue(returnValue: any) {
        this.returnValue = returnValue;
        this.succeeded = true;
    }

    public isSucceeded(): boolean {
        return this.succeeded;
    }

    public getContentType(): MessageType {
        return MessageType.SERVICE_RESP;
    }

    public getResponseType(): MessageResponseType {
        return MessageResponseType.ZERO;
    }

    public getRequestId(): string {
        return this.requestId;
    }

  public setRequestId(requestId: string) {
    this.requestId = requestId;
  }

  public getServiceError(): ServiceError {
    return this.serviceError;
  }

  public setServiceError(error: ServiceError) {
    this.serviceError = error;
    this.succeeded = false;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    super.fillFromJson(json, factory);
    const requestId = json["requestId"];
    if (requestId != null) {
      this.setRequestId(requestId);
    }
    const returnValue = json["returnValue"];
    if (returnValue != null) {
      this.setReturnValue(Serializer.fromJson(returnValue, factory));
    }
    const serviceError = json["serviceError"];
    if (serviceError != null) {
      const convertedServiceError = new ServiceError();
      Serializer.fillFromJson(convertedServiceError, serviceError, factory);
      this.setServiceError(convertedServiceError);
    }
    const sessionToken = json["sessionToken"];
    if (sessionToken != null) {
      this.setSessionToken(sessionToken);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const id = this.getId();
    if (id != null) {
      json["id"] = id;
    }
    const requestId = this.getRequestId();
    if (requestId != null) {
      json["requestId"] = requestId;
    }
    const sessionToken = this.getSessionToken();
    if (sessionToken != null) {
      json["sessionToken"] = sessionToken;
    }
    const succeeded = this.isSucceeded();
    if (succeeded != null) {
      json["succeeded"] = succeeded;
    }
    const returnValue = this.getReturnValue();
    if (returnValue != null) {
      const convertedReturnValue0 = Serializer.getJson(
        returnValue,
        JsonTypeTagType.CLASS
      );
      json["returnValue"] = convertedReturnValue0;
    }
    const serviceError = this.getServiceError();
    if (serviceError != null) {
      const convertedServiceError0 = serviceError.getJson(JsonTypeTagType.NONE);
      json["serviceError"] = convertedServiceError0;
    }
    const serviceException = this.getServiceError();
    if (serviceException != null) {
      const convertedServiceException0 = serviceException.getJson(
        JsonTypeTagType.NONE
      );
      json["serviceException"] = convertedServiceException0;
    }

    if (includeTypeTag) {
      json["__type"] = "ServiceResponse";
    }
  }

  public getTypeId(): string {
    return "ServiceResponse";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.service.spi.ServiceResponse";
  }
}
