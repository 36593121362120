/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractEnterpriseServiceTrade} from './AbstractEnterpriseServiceTrade'
import {EnterpriseServiceTradeKeyInfo} from './EnterpriseServiceTradeKeyInfo'
import {EntityInfo} from '../../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {PlatformMemberType} from '../PlatformMemberType'
import {Serializer} from '../../../../serialize/Serializer'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class EnterpriseServicePackageTrade extends AbstractEnterpriseServiceTrade {
    memberType: PlatformMemberType;
    serviceTradeInfos: Array<EnterpriseServiceTradeKeyInfo>;
    offeredServiceTradeInfos: Array<EnterpriseServiceTradeKeyInfo>;
    candidateServingEnterpriseInfo: EntityInfo;
    candidateServingWorkerInfo: EntityInfo;
    mainServingEnterpriseInfo: EntityInfo;
    mainServingWorkerInfo: EntityInfo;
    addedAmount: number;
    remainingAmount: number;
    reservedAmount: number;
    renewed: boolean;
    remindingReview: boolean;

    constructor() {
        super();
    }

    public getMemberType(): PlatformMemberType {
        return this.memberType;
    }

    public setMemberType(memberType: PlatformMemberType) {
        this.memberType = memberType;
    }

    public getServiceTradeInfos(): Array<EnterpriseServiceTradeKeyInfo> {
        return this.serviceTradeInfos;
    }

    public setServiceTradeInfos(serviceTradeInfos: Array<EnterpriseServiceTradeKeyInfo>) {
        this.serviceTradeInfos = serviceTradeInfos;
    }

    public getOfferedServiceTradeInfos(): Array<EnterpriseServiceTradeKeyInfo> {
        return this.offeredServiceTradeInfos;
    }

    public setOfferedServiceTradeInfos(offeredServiceTradeInfos: Array<EnterpriseServiceTradeKeyInfo>) {
        this.offeredServiceTradeInfos = offeredServiceTradeInfos;
    }

    public getCandidateServingEnterpriseInfo(): EntityInfo {
        return this.candidateServingEnterpriseInfo;
    }

    public setCandidateServingEnterpriseInfo(candidateServingEnterpriseInfo: EntityInfo) {
        this.candidateServingEnterpriseInfo = candidateServingEnterpriseInfo;
    }

    public getCandidateServingWorkerInfo(): EntityInfo {
        return this.candidateServingWorkerInfo;
    }

    public setCandidateServingWorkerInfo(candidateServingWorkerInfo: EntityInfo) {
        this.candidateServingWorkerInfo = candidateServingWorkerInfo;
    }

    public getMainServingEnterpriseInfo(): EntityInfo {
        return this.mainServingEnterpriseInfo;
    }

    public setMainServingEnterpriseInfo(mainServingEnterpriseInfo: EntityInfo) {
        this.mainServingEnterpriseInfo = mainServingEnterpriseInfo;
    }

    public getMainServingWorkerInfo(): EntityInfo {
        return this.mainServingWorkerInfo;
    }

    public setMainServingWorkerInfo(mainServingWorkerInfo: EntityInfo) {
        this.mainServingWorkerInfo = mainServingWorkerInfo;
    }

    public getAddedAmount(): number {
        return this.addedAmount;
    }

    public setAddedAmount(addedAmount: number) {
        this.addedAmount = addedAmount;
    }

    public getRemainingAmount(): number {
        return this.remainingAmount;
    }

    public setRemainingAmount(remainingAmount: number) {
        this.remainingAmount = remainingAmount;
    }

    public getReservedAmount(): number {
        return this.reservedAmount;
    }

    public setReservedAmount(reservedAmount: number) {
        this.reservedAmount = reservedAmount;
    }

    public isRenewed(): boolean {
        return this.renewed;
    }

    public setRenewed(renewed: boolean) {
        this.renewed = renewed;
    }

    public isRemindingReview(): boolean {
        return this.remindingReview;
    }

    public setRemindingReview(remindingReview: boolean) {
        this.remindingReview = remindingReview;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let memberType = json["memberType"];
        if (memberType != null) {
            let convertedMemberType0 = PlatformMemberType[<string>memberType];
            this.setMemberType(convertedMemberType0);
        }
        let serviceTradeInfos = json["serviceTradeInfos"];
        if (serviceTradeInfos != null) {
            let convertedServiceTradeInfos0 = [];
            serviceTradeInfos = serviceTradeInfos[1];
            for (let i in serviceTradeInfos) {
                let convertedServiceTradeInfos10 = serviceTradeInfos[i];
                let convertedServiceTradeInfos1 = new EnterpriseServiceTradeKeyInfo();
                convertedServiceTradeInfos1.fillFromJson(convertedServiceTradeInfos10, factory);
                convertedServiceTradeInfos0.push(convertedServiceTradeInfos1);
            }
            this.setServiceTradeInfos(convertedServiceTradeInfos0);
        }
        let offeredServiceTradeInfos = json["offeredServiceTradeInfos"];
        if (offeredServiceTradeInfos != null) {
            let convertedOfferedServiceTradeInfos0 = [];
            offeredServiceTradeInfos = offeredServiceTradeInfos[1];
            for (let i in offeredServiceTradeInfos) {
                let convertedOfferedServiceTradeInfos10 = offeredServiceTradeInfos[i];
                let convertedOfferedServiceTradeInfos1 = new EnterpriseServiceTradeKeyInfo();
                convertedOfferedServiceTradeInfos1.fillFromJson(convertedOfferedServiceTradeInfos10, factory);
                convertedOfferedServiceTradeInfos0.push(convertedOfferedServiceTradeInfos1);
            }
            this.setOfferedServiceTradeInfos(convertedOfferedServiceTradeInfos0);
        }
        let candidateServingEnterpriseInfo = json["candidateServingEnterpriseInfo"];
        if (candidateServingEnterpriseInfo != null) {
            let convertedCandidateServingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(candidateServingEnterpriseInfo, "__type", factory);
            this.setCandidateServingEnterpriseInfo(convertedCandidateServingEnterpriseInfo0);
        }
        let candidateServingWorkerInfo = json["candidateServingWorkerInfo"];
        if (candidateServingWorkerInfo != null) {
            let convertedCandidateServingWorkerInfo0 = Serializer.fillFromJsonObjectWithTypeTag(candidateServingWorkerInfo, "__type", factory);
            this.setCandidateServingWorkerInfo(convertedCandidateServingWorkerInfo0);
        }
        let mainServingEnterpriseInfo = json["mainServingEnterpriseInfo"];
        if (mainServingEnterpriseInfo != null) {
            let convertedMainServingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(mainServingEnterpriseInfo, "__type", factory);
            this.setMainServingEnterpriseInfo(convertedMainServingEnterpriseInfo0);
        }
        let mainServingWorkerInfo = json["mainServingWorkerInfo"];
        if (mainServingWorkerInfo != null) {
            let convertedMainServingWorkerInfo0 = Serializer.fillFromJsonObjectWithTypeTag(mainServingWorkerInfo, "__type", factory);
            this.setMainServingWorkerInfo(convertedMainServingWorkerInfo0);
        }
        let addedAmount = json["addedAmount"];
        if (addedAmount != null) {
            this.setAddedAmount(addedAmount);
        }
        let remainingAmount = json["remainingAmount"];
        if (remainingAmount != null) {
            this.setRemainingAmount(remainingAmount);
        }
        let reservedAmount = json["reservedAmount"];
        if (reservedAmount != null) {
            this.setReservedAmount(reservedAmount);
        }
        let renewed = json["renewed"];
        if (renewed != null) {
            this.setRenewed(renewed);
        }
        let remindingReview = json["remindingReview"];
        if (remindingReview != null) {
            this.setRemindingReview(remindingReview);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let memberType = this.getMemberType();
        if (memberType != null) {
            let convertedMemberType0 = PlatformMemberType[memberType];
            json["memberType"] = convertedMemberType0;
        }
        let serviceTradeInfos = this.getServiceTradeInfos();
        if (serviceTradeInfos != null) {
            let convertedServiceTradeInfos0 = [];
            for (let i in serviceTradeInfos) {
                let convertedServiceTradeInfos10 = serviceTradeInfos[i];
                let convertedServiceTradeInfos1 = convertedServiceTradeInfos10.getJson(JsonTypeTagType.NONE);
                convertedServiceTradeInfos0.push(convertedServiceTradeInfos1);
            }
            let convertedServiceTradeInfosWithType1 = ["java.util.ArrayList", convertedServiceTradeInfos0];
            json["serviceTradeInfos"] = convertedServiceTradeInfosWithType1;
        }
        let offeredServiceTradeInfos = this.getOfferedServiceTradeInfos();
        if (offeredServiceTradeInfos != null) {
            let convertedOfferedServiceTradeInfos0 = [];
            for (let i in offeredServiceTradeInfos) {
                let convertedOfferedServiceTradeInfos10 = offeredServiceTradeInfos[i];
                let convertedOfferedServiceTradeInfos1 = convertedOfferedServiceTradeInfos10.getJson(JsonTypeTagType.NONE);
                convertedOfferedServiceTradeInfos0.push(convertedOfferedServiceTradeInfos1);
            }
            let convertedOfferedServiceTradeInfosWithType1 = ["java.util.ArrayList", convertedOfferedServiceTradeInfos0];
            json["offeredServiceTradeInfos"] = convertedOfferedServiceTradeInfosWithType1;
        }
        let candidateServingEnterpriseInfo = this.getCandidateServingEnterpriseInfo();
        if (candidateServingEnterpriseInfo != null) {
            let convertedCandidateServingEnterpriseInfo0 = candidateServingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["candidateServingEnterpriseInfo"] = convertedCandidateServingEnterpriseInfo0;
        }
        let candidateServingWorkerInfo = this.getCandidateServingWorkerInfo();
        if (candidateServingWorkerInfo != null) {
            let convertedCandidateServingWorkerInfo0 = candidateServingWorkerInfo.getJson(JsonTypeTagType.TYPE);
            json["candidateServingWorkerInfo"] = convertedCandidateServingWorkerInfo0;
        }
        let mainServingEnterpriseInfo = this.getMainServingEnterpriseInfo();
        if (mainServingEnterpriseInfo != null) {
            let convertedMainServingEnterpriseInfo0 = mainServingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["mainServingEnterpriseInfo"] = convertedMainServingEnterpriseInfo0;
        }
        let mainServingWorkerInfo = this.getMainServingWorkerInfo();
        if (mainServingWorkerInfo != null) {
            let convertedMainServingWorkerInfo0 = mainServingWorkerInfo.getJson(JsonTypeTagType.TYPE);
            json["mainServingWorkerInfo"] = convertedMainServingWorkerInfo0;
        }
        let addedAmount = this.getAddedAmount();
        if (addedAmount != null) {
            json["addedAmount"] = addedAmount;
        }
        let remainingAmount = this.getRemainingAmount();
        if (remainingAmount != null) {
            json["remainingAmount"] = remainingAmount;
        }
        let reservedAmount = this.getReservedAmount();
        if (reservedAmount != null) {
            json["reservedAmount"] = reservedAmount;
        }
        let renewed = this.isRenewed();
        if (renewed != null) {
            json["renewed"] = renewed;
        }
        let remindingReview = this.isRemindingReview();
        if (remindingReview != null) {
            json["remindingReview"] = remindingReview;
        }

        if (includeTypeTag) {
            json["__type"] = "EnterpriseServicePackageTrade";
        }
    }


    public getTypeId(): string {
        return "EnterpriseServicePackageTrade";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.service.trade.EnterpriseServicePackageTrade";
    }
}
