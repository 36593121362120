/**
 * 下载！
 */
import { ElMessage } from "element-plus";
import Throttle from "./Throttle";
export default class DownloadUtils {
  static DownloadUtils(link: string, fileName: string) {
    return Throttle((): void => {
      ElMessage({
        type: "success",
        message: "下载中,请耐心等待！",
      });
      fetch(link)
        .then((res) => res.blob())
        .then((blob) => {
          const a: HTMLAnchorElement = document.createElement("a");
          const objectUrl = window.URL.createObjectURL(blob);
          a.download = `${fileName}.${
            link.split(".")[link.split(".").length - 1]
          }`;
          a.href = objectUrl;
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
        });
    }, 10000);
  }
}
