/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CustomMessageResponseType } from "../../message/entity/api/CustomMessageResponseType";
import { CustomMessageResponse } from "../../message/entity/api/CustomMessageResponse";
import { EntityPriceQuotes } from "../business/price/EntityPriceQuotes";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { ReleaseStatus } from "./ReleaseStatus";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class ReleaseResponse<T>
  extends AbstractSerializable
  implements CustomMessageResponse
{
  releaseId: number | string;
  releaseStatus: ReleaseStatus;
  inChat: boolean;
  priceQuotes: EntityPriceQuotes;
  notes: string;
  dataFromRelease: T;
  customMessageResponseType: CustomMessageResponseType;

  constructor() {
    super();
  }

  public getReleaseId(): number | string {
    return this.releaseId;
  }

  public setReleaseId(releaseId: number | string) {
    this.releaseId = releaseId;
  }

  public getReleaseStatus(): ReleaseStatus {
    return this.releaseStatus;
  }

  public setReleaseStatus(releaseStatus: ReleaseStatus) {
    this.releaseStatus = releaseStatus;
  }

  public isInChat(): boolean {
    return this.inChat;
  }

  public setInChat(inChat: boolean) {
    this.inChat = inChat;
  }

  public getPriceQuotes(): EntityPriceQuotes {
    return this.priceQuotes;
  }

  public setPriceQuotes(priceQuotes: EntityPriceQuotes) {
    this.priceQuotes = priceQuotes;
  }

  public getNotes(): string {
    return this.notes;
  }

  public setNotes(notes: string) {
    this.notes = notes;
  }

  public getDataFromRelease(): T {
    return this.dataFromRelease;
  }

  public setDataFromRelease(dataFromRelease: T) {
    this.dataFromRelease = dataFromRelease;
  }

  public getCustomMessageResponseType(): CustomMessageResponseType {
    return this.customMessageResponseType;
  }

  public setCustomMessageResponseType(
    customMessageResponseType: CustomMessageResponseType
  ) {
    this.customMessageResponseType = customMessageResponseType;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const releaseId = json["releaseId"];
    if (releaseId != null) {
      this.setReleaseId(releaseId);
    }
    const releaseStatus = json["releaseStatus"];
    if (releaseStatus != null) {
      const convertedReleaseStatus0 = ReleaseStatus[<string>releaseStatus];
      this.setReleaseStatus(convertedReleaseStatus0);
    }
    const inChat = json["inChat"];
    if (inChat != null) {
      this.setInChat(inChat);
    }
    const priceQuotes = json["priceQuotes"];
    if (priceQuotes != null) {
      const convertedPriceQuotes0 = new EntityPriceQuotes();
      convertedPriceQuotes0.fillFromJson(priceQuotes, factory);
      this.setPriceQuotes(convertedPriceQuotes0);
    }
    const notes = json["notes"];
    if (notes != null) {
      this.setNotes(notes);
    }
    const dataFromRelease = json["dataFromRelease"];
    if (dataFromRelease != null) {
      const convertedDataFromRelease0 = Serializer.fromJson(
        dataFromRelease,
        factory
      );
      this.setDataFromRelease(convertedDataFromRelease0);
    }
    const customMessageResponseType = json["customMessageResponseType"];
    if (customMessageResponseType != null) {
      const convertedCustomMessageResponseType0 =
        CustomMessageResponseType[<string>customMessageResponseType];
      this.setCustomMessageResponseType(convertedCustomMessageResponseType0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const releaseId = this.getReleaseId();
    if (releaseId != null) {
      json["releaseId"] = String(releaseId);
    }
    const releaseStatus = this.getReleaseStatus();
    if (releaseStatus != null) {
      const convertedReleaseStatus0 = ReleaseStatus[releaseStatus];
      json["releaseStatus"] = convertedReleaseStatus0;
    }
    const inChat = this.isInChat();
    if (inChat != null) {
      json["inChat"] = inChat;
    }
    const priceQuotes = this.getPriceQuotes();
    if (priceQuotes != null) {
      const convertedPriceQuotes0 = priceQuotes.getJson(JsonTypeTagType.NONE);
      json["priceQuotes"] = convertedPriceQuotes0;
    }
    const notes = this.getNotes();
    if (notes != null) {
      json["notes"] = notes;
    }
    const dataFromRelease = this.getDataFromRelease();
    if (dataFromRelease != null) {
      const convertedDataFromRelease0 = Serializer.getJson(
        dataFromRelease,
        JsonTypeTagType.CLASS
      );
      json["dataFromRelease"] = convertedDataFromRelease0;
    }
    const customMessageResponseType = this.getCustomMessageResponseType();
    if (customMessageResponseType != null) {
      const convertedCustomMessageResponseType0 =
        CustomMessageResponseType[customMessageResponseType];
      json["customMessageResponseType"] = convertedCustomMessageResponseType0;
    }
  }

  public getTypeId(): string {
    return "ReleaseResponse";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.ReleaseResponse";
  }
}
