/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { PositionDescriptor } from "./PositionDescriptor";
import { TypeFactory } from "../../serialize/TypeFactory";

export class MessageRange
  extends AbstractSerializable
  implements PositionDescriptor
{
  nextStart: number;
  startTime: number | string;
  endTime: number | string;
  startFetchId: number | string;
  endFetchId: number | string;

  constructor() {
    super();
  }

  public getNextStart(): number {
    return this.nextStart;
  }

  public setNextStart(nextStart: number) {
    this.nextStart = nextStart;
  }

  public getStartTime(): number | string {
    return this.startTime;
  }

  public setStartTime(startTime: number | string) {
    this.startTime = startTime;
  }

  public getEndTime(): number | string {
    return this.endTime;
  }

  public setEndTime(endTime: number | string) {
    this.endTime = endTime;
  }

  public getStartFetchId(): number | string {
    return this.startFetchId;
  }

  public setStartFetchId(startFetchId: number | string) {
    this.startFetchId = startFetchId;
  }

  public getEndFetchId(): number | string {
    return this.endFetchId;
  }

  public setEndFetchId(endFetchId: number | string) {
    this.endFetchId = endFetchId;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const nextStart = json["nextStart"];
    if (nextStart != null) {
      this.setNextStart(nextStart);
    }
    const startTime = json["startTime"];
    if (startTime != null) {
      this.setStartTime(startTime);
    }
    const endTime = json["endTime"];
    if (endTime != null) {
      this.setEndTime(endTime);
    }
    const startFetchId = json["startFetchId"];
    if (startFetchId != null) {
      this.setStartFetchId(startFetchId);
    }
    const endFetchId = json["endFetchId"];
    if (endFetchId != null) {
      this.setEndFetchId(endFetchId);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const nextStart = this.getNextStart();
    if (nextStart != null) {
      json["nextStart"] = nextStart;
    }
    const startTime = this.getStartTime();
    if (startTime != null) {
      json["startTime"] = String(startTime);
    }
    const endTime = this.getEndTime();
    if (endTime != null) {
      json["endTime"] = String(endTime);
    }
    const startFetchId = this.getStartFetchId();
    if (startFetchId != null) {
      json["startFetchId"] = String(startFetchId);
    }
    const endFetchId = this.getEndFetchId();
    if (endFetchId != null) {
      json["endFetchId"] = String(endFetchId);
    }

    if (includeTypeTag) {
      json["__type"] = "MessageRange";
    }
  }

  public getTypeId(): string {
    return "MessageRange";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.paging.MessageRange";
  }
}
