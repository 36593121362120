/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EnterpriseServiceType} from '../../business/service/EnterpriseServiceType'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {PlatformOfferType} from './PlatformOfferType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class PlatformOffer extends AbstractSerializable {
    offerId: (number | string);
    offerType: PlatformOfferType;
    serviceType: EnterpriseServiceType;
    serviceOrderId: (number | string);
    offerTitle: string;

    constructor() {
        super();
    }

    public getOfferId(): (number | string) {
        return this.offerId;
    }

    public setOfferId(offerId: (number | string)) {
        this.offerId = offerId;
    }

    public getOfferType(): PlatformOfferType {
        return this.offerType;
    }

    public setOfferType(offerType: PlatformOfferType) {
        this.offerType = offerType;
    }

    public getServiceType(): EnterpriseServiceType {
        return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
        this.serviceType = serviceType;
    }

    public getServiceOrderId(): (number | string) {
        return this.serviceOrderId;
    }

    public setServiceOrderId(serviceOrderId: (number | string)) {
        this.serviceOrderId = serviceOrderId;
    }

    public getOfferTitle(): string {
        return this.offerTitle;
    }

    public setOfferTitle(offerTitle: string) {
        this.offerTitle = offerTitle;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let offerId = json["offerId"];
        if (offerId != null) {
            this.setOfferId(offerId);
        }
        let offerType = json["offerType"];
        if (offerType != null) {
            let convertedOfferType0 = PlatformOfferType[<string>offerType];
            this.setOfferType(convertedOfferType0);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let serviceOrderId = json["serviceOrderId"];
        if (serviceOrderId != null) {
            this.setServiceOrderId(serviceOrderId);
        }
        let offerTitle = json["offerTitle"];
        if (offerTitle != null) {
            this.setOfferTitle(offerTitle);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let offerId = this.getOfferId();
        if (offerId != null) {
            json["offerId"] = String(offerId);
        }
        let offerType = this.getOfferType();
        if (offerType != null) {
            let convertedOfferType0 = PlatformOfferType[offerType];
            json["offerType"] = convertedOfferType0;
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let serviceOrderId = this.getServiceOrderId();
        if (serviceOrderId != null) {
            json["serviceOrderId"] = String(serviceOrderId);
        }
        let offerTitle = this.getOfferTitle();
        if (offerTitle != null) {
            json["offerTitle"] = offerTitle;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.platform.offer.PlatformOffer";
    }
}
