/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../serialize/TypeFactory'


export class BusinessContactInfo extends AbstractSerializable  {
    contactName: string;
    phoneNumber: string;
    address: string;

    constructor() {
    	super();
    }

    public getContactName(): string {
    	return this.contactName;
    }

    public setContactName(contactName: string) {
    	this.contactName = contactName;
    }

    public getPhoneNumber(): string {
    	return this.phoneNumber;
    }

    public setPhoneNumber(phoneNumber: string) {
    	this.phoneNumber = phoneNumber;
    }

    public getAddress(): string {
    	return this.address;
    }

    public setAddress(address: string) {
    	this.address = address;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let contactName = json["contactName"];
        if (contactName != null) {
            this.setContactName(contactName);
        }
        let phoneNumber = json["phoneNumber"];
        if (phoneNumber != null) {
            this.setPhoneNumber(phoneNumber);
        }
        let address = json["address"];
        if (address != null) {
            this.setAddress(address);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let contactName = this.getContactName();
        if (contactName != null) {
            json["contactName"] = contactName;
        }
        let phoneNumber = this.getPhoneNumber();
        if (phoneNumber != null) {
            json["phoneNumber"] = phoneNumber;
        }
        let address = this.getAddress();
        if (address != null) {
            json["address"] = address;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.BusinessContactInfo";
    }
}
