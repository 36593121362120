/**
 * Created by Zhonghua on 14/11/2016.
 */

import { IKeyedCollection } from "./IKeyedCollection";

export class KeyedCollection<T> implements IKeyedCollection<T> {
  private kitems: { [index: string]: T };

  private kcount = 0;

  public constructor(items: { [index: string]: T } = {}) {
    this.kitems = items;
  }

  public containsKey(key: string): boolean {
    return this.kitems.hasOwnProperty(key);
  }

  public count(): number {
    return this.kcount;
  }

  public add(key: string, value: T) {
    if (!this.containsKey(key)) {
      this.kcount += 1;
    }
    this.kitems[key] = value;

    return this;
  }

  public remove(key: string): T | undefined | null {
    if (this.containsKey(key)) {
      delete this.kitems[key];
      this.kcount -= 1;
      return this.kitems[key];
    } else {
      return undefined;
    }
  }

  public item(key: string): T {
    return this.kitems[key];
  }

  public keys(): string[] {
    const keySet: string[] = [];

    for (const prop in this.kitems) {
      if (this.kitems.hasOwnProperty(prop)) {
        keySet.push(prop);
      }
    }

    return keySet;
  }

  public values(): T[] {
    const values: T[] = [];

    for (const prop in this.kitems) {
      if (this.kitems.hasOwnProperty(prop)) {
        values.push(this.kitems[prop]);
      }
    }

    return values;
  }

  public items(): { [index: string]: T } {
    return this.kitems;
  }

  public clear() {
    this.kitems = {};
    this.kcount = 0;
  }

  public getTypeId(): string {
    return "Map";
  }
}
