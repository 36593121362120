/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {EnterpriseType} from '../entity/enterprise/EnterpriseType'
import {EntityType} from '../entity/EntityType'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {Location} from '../geolocation/Location'
import {TopicType} from '../entity/topic/TopicType'
import {TypeFactory} from '../../serialize/TypeFactory'


export class KeywordEntity extends AbstractSerializable  {
    keyword: string;
    entityType: EntityType;
    enterpriseType: EnterpriseType;
    topicType: TopicType;
    entityId: (number | string);
    location: Location;
    regionId: (number | string);

    constructor() {
    	super();
    }

    public getKeyword(): string {
    	return this.keyword;
    }

    public setKeyword(keyword: string) {
    	this.keyword = keyword;
    }

    public getEntityType(): EntityType {
    	return this.entityType;
    }

    public setEntityType(entityType: EntityType) {
    	this.entityType = entityType;
    }

    public getEnterpriseType(): EnterpriseType {
    	return this.enterpriseType;
    }

    public setEnterpriseType(enterpriseType: EnterpriseType) {
    	this.enterpriseType = enterpriseType;
    }

    public getTopicType(): TopicType {
    	return this.topicType;
    }

    public setTopicType(topicType: TopicType) {
    	this.topicType = topicType;
    }

    public getEntityId(): (number | string) {
    	return this.entityId;
    }

    public setEntityId(entityId: (number | string)) {
    	this.entityId = entityId;
    }

    public getLocation(): Location {
    	return this.location;
    }

    public setLocation(location: Location) {
    	this.location = location;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let keyword = json["keyword"];
        if (keyword != null) {
            this.setKeyword(keyword);
        }
        let entityType = json["entityType"];
        if (entityType != null) {
            let convertedEntityType0 = EntityType[<string>entityType];
            this.setEntityType(convertedEntityType0);
        }
        let enterpriseType = json["enterpriseType"];
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[<string>enterpriseType];
            this.setEnterpriseType(convertedEnterpriseType0);
        }
        let topicType = json["topicType"];
        if (topicType != null) {
            let convertedTopicType0 = TopicType[<string>topicType];
            this.setTopicType(convertedTopicType0);
        }
        let entityId = json["entityId"];
        if (entityId != null) {
            this.setEntityId(entityId);
        }
        let location = json["location"];
        if (location != null) {
            let convertedLocation0 = new Location();
            convertedLocation0.fillFromJson(location, factory);
            this.setLocation(convertedLocation0);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let keyword = this.getKeyword();
        if (keyword != null) {
            json["keyword"] = keyword;
        }
        let entityType = this.getEntityType();
        if (entityType != null) {
            let convertedEntityType0 = EntityType[entityType];
            json["entityType"] = convertedEntityType0;
        }
        let enterpriseType = this.getEnterpriseType();
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[enterpriseType];
            json["enterpriseType"] = convertedEnterpriseType0;
        }
        let topicType = this.getTopicType();
        if (topicType != null) {
            let convertedTopicType0 = TopicType[topicType];
            json["topicType"] = convertedTopicType0;
        }
        let entityId = this.getEntityId();
        if (entityId != null) {
            json["entityId"] = String(entityId);
        }
        let location = this.getLocation();
        if (location != null) {
            let convertedLocation0 = location.getJson(JsonTypeTagType.NONE);
            json["location"] = convertedLocation0;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.keyword.KeywordEntity";
    }
}
