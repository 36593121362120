/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { AnnouncementStatus } from "../../userad/AnnouncementStatus";
import { AnnounceType } from "../../userad/AnnounceType";
import { Filter } from "../Filter";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { Location } from "../../geolocation/Location";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class AnnouncementFilter extends AbstractSerializable implements Filter {
  location: Location;
  distance: number;
  targetEntityId: number | string;
  announcementType: AnnounceType;
  keyword: string;
  announcementStatus: AnnouncementStatus;

  constructor() {
    super();
  }

  public getLocation(): Location {
    return this.location;
  }

  public setLocation(location: Location) {
    this.location = location;
  }

  public getDistance(): number {
    return this.distance;
  }

  public setDistance(distance: number) {
    this.distance = distance;
  }

  public getTargetEntityId(): number | string {
    return this.targetEntityId;
  }

  public setTargetEntityId(targetEntityId: number | string) {
    this.targetEntityId = targetEntityId;
  }

  public getAnnouncementType(): AnnounceType {
    return this.announcementType;
  }

  public setAnnouncementType(announcementType: AnnounceType) {
    this.announcementType = announcementType;
  }

  public getKeyword(): string {
    return this.keyword;
  }

  public setKeyword(keyword: string) {
    this.keyword = keyword;
  }

  public getAnnouncementStatus(): AnnouncementStatus {
    return this.announcementStatus;
  }

  public setAnnouncementStatus(announcementStatus: AnnouncementStatus) {
    this.announcementStatus = announcementStatus;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const location = json["location"];
    if (location != null) {
      const convertedLocation0 = new Location();
      convertedLocation0.fillFromJson(location, factory);
      this.setLocation(convertedLocation0);
    }
    const distance = json["distance"];
    if (distance != null) {
      this.setDistance(distance);
    }
    const targetEntityId = json["targetEntityId"];
    if (targetEntityId != null) {
      this.setTargetEntityId(targetEntityId);
    }
    const announcementType = json["announcementType"];
    if (announcementType != null) {
      const convertedAnnouncementType0 = AnnounceType[<string>announcementType];
      this.setAnnouncementType(convertedAnnouncementType0);
    }
    const keyword = json["keyword"];
    if (keyword != null) {
      this.setKeyword(keyword);
    }
    const announcementStatus = json["announcementStatus"];
    if (announcementStatus != null) {
      const convertedAnnouncementStatus0 =
        AnnouncementStatus[<string>announcementStatus];
      this.setAnnouncementStatus(convertedAnnouncementStatus0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const location = this.getLocation();
    if (location != null) {
      const convertedLocation0 = location.getJson(JsonTypeTagType.NONE);
      json["location"] = convertedLocation0;
    }
    const distance = this.getDistance();
    if (distance != null) {
      json["distance"] = distance;
    }
    const targetEntityId = this.getTargetEntityId();
    if (targetEntityId != null) {
      json["targetEntityId"] = String(targetEntityId);
    }
    const announcementType = this.getAnnouncementType();
    if (announcementType != null) {
      const convertedAnnouncementType0 = AnnounceType[announcementType];
      json["announcementType"] = convertedAnnouncementType0;
    }
    const keyword = this.getKeyword();
    if (keyword != null) {
      json["keyword"] = keyword;
    }
    const announcementStatus = this.getAnnouncementStatus();
    if (announcementStatus != null) {
      const convertedAnnouncementStatus0 =
        AnnouncementStatus[announcementStatus];
      json["announcementStatus"] = convertedAnnouncementStatus0;
    }

    if (includeTypeTag) {
      json["__type"] = "AnnouncementFilter";
    }
  }

  public getTypeId(): string {
    return "AnnouncementFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.userad.AnnouncementFilter";
  }
}
