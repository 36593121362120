/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {AdditionalEntityAttributes} from '../AdditionalEntityAttributes'
import {EntityType} from '../EntityType'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {LocationType} from '../../geolocation/LocationType'
import {PlatformMemberType} from '../../business/service/PlatformMemberType'
import {Profession} from './Profession'
import {TypeFactory} from '../../../serialize/TypeFactory'
import {UserEntityAdminInfo} from './UserEntityAdminInfo'
import {WorkerInfo} from './WorkerInfo'


export abstract class AbstractUserDetails extends AbstractSerializable implements AdditionalEntityAttributes  {
    profession: Profession;
    inProfession: boolean;
    serviceProvider: boolean;
    serviceMobile: string;
    noPassword: boolean;
    privateChatDisabled: boolean;
    noActivePush: boolean;
    inviteCode: string;
    mobile: string;
    email: string;
    weChatId: string;
    entWeChatId: string;
    publicAccounted: boolean;
    serviceAccounted: boolean;
    userEntityAdminInfo: UserEntityAdminInfo;
    locationType: LocationType;
    workerInfo: WorkerInfo;
    favorites: number;
    moneyAndPointsOn: boolean;
    anonymousOn: boolean;
    globalBusinessElite: boolean;
    regionBusinessElite: boolean;
    eliteInReview: boolean;
    memberType: PlatformMemberType;
    memberTypeName: string;
    memberTypeDisplayName: string;
    memberTypeShortName: string;
    highestMember: boolean;
    qllMemberStartDay: number;
    qllMemberEndDay: number;
    toShowUpgrade: boolean;
    intlEntitled: boolean;

    constructor() {
        super();
    }

    public getProfession(): Profession {
        return this.profession;
    }

    public setProfession(profession: Profession) {
        this.profession = profession;
    }

    public isInProfession(): boolean {
        return this.inProfession;
    }

    public setInProfession(inProfession: boolean) {
        this.inProfession = inProfession;
    }

    public isServiceProvider(): boolean {
        return this.serviceProvider;
    }

    public setServiceProvider(serviceProvider: boolean) {
        this.serviceProvider = serviceProvider;
    }

    public getServiceMobile(): string {
        return this.serviceMobile;
    }

    public setServiceMobile(serviceMobile: string) {
        this.serviceMobile = serviceMobile;
    }

    public isNoPassword(): boolean {
        return this.noPassword;
    }

    public setNoPassword(noPassword: boolean) {
        this.noPassword = noPassword;
    }

    public isPrivateChatDisabled(): boolean {
        return this.privateChatDisabled;
    }

    public setPrivateChatDisabled(privateChatDisabled: boolean) {
        this.privateChatDisabled = privateChatDisabled;
    }

    public isNoActivePush(): boolean {
        return this.noActivePush;
    }

    public setNoActivePush(noActivePush: boolean) {
        this.noActivePush = noActivePush;
    }

    public getInviteCode(): string {
        return this.inviteCode;
    }

    public setInviteCode(inviteCode: string) {
        this.inviteCode = inviteCode;
    }

    public getMobile(): string {
        return this.mobile;
    }

    public setMobile(mobile: string) {
        this.mobile = mobile;
    }

    public getEmail(): string {
        return this.email;
    }

    public setEmail(email: string) {
        this.email = email;
    }

    public getWeChatId(): string {
        return this.weChatId;
    }

    public setWeChatId(weChatId: string) {
        this.weChatId = weChatId;
    }

    public getEntWeChatId(): string {
        return this.entWeChatId;
    }

    public setEntWeChatId(entWeChatId: string) {
        this.entWeChatId = entWeChatId;
    }

    public isPublicAccounted(): boolean {
        return this.publicAccounted;
    }

    public setPublicAccounted(publicAccounted: boolean) {
        this.publicAccounted = publicAccounted;
    }

    public isServiceAccounted(): boolean {
        return this.serviceAccounted;
    }

    public setServiceAccounted(serviceAccounted: boolean) {
        this.serviceAccounted = serviceAccounted;
    }

    public getUserEntityAdminInfo(): UserEntityAdminInfo {
        return this.userEntityAdminInfo;
    }

    public setUserEntityAdminInfo(userEntityAdminInfo: UserEntityAdminInfo) {
        this.userEntityAdminInfo = userEntityAdminInfo;
    }

    public getLocationType(): LocationType {
        return this.locationType;
    }

    public setLocationType(locationType: LocationType) {
        this.locationType = locationType;
    }

    public getWorkerInfo(): WorkerInfo {
        return this.workerInfo;
    }

    public setWorkerInfo(workerInfo: WorkerInfo) {
        this.workerInfo = workerInfo;
    }

    public getFavorites(): number {
        return this.favorites;
    }

    public setFavorites(favorites: number) {
        this.favorites = favorites;
    }

    public isMoneyAndPointsOn(): boolean {
        return this.moneyAndPointsOn;
    }

    public setMoneyAndPointsOn(moneyAndPointsOn: boolean) {
        this.moneyAndPointsOn = moneyAndPointsOn;
    }

    public isAnonymousOn(): boolean {
        return this.anonymousOn;
    }

    public setAnonymousOn(anonymousOn: boolean) {
        this.anonymousOn = anonymousOn;
    }

    public isGlobalBusinessElite(): boolean {
        return this.globalBusinessElite;
    }

    public setGlobalBusinessElite(globalBusinessElite: boolean) {
        this.globalBusinessElite = globalBusinessElite;
    }

    public isRegionBusinessElite(): boolean {
        return this.regionBusinessElite;
    }

    public setRegionBusinessElite(regionBusinessElite: boolean) {
        this.regionBusinessElite = regionBusinessElite;
    }

    public isEliteInReview(): boolean {
        return this.eliteInReview;
    }

    public setEliteInReview(eliteInReview: boolean) {
        this.eliteInReview = eliteInReview;
    }

    public getMemberType(): PlatformMemberType {
        return this.memberType;
    }

    public setMemberType(memberType: PlatformMemberType) {
        this.memberType = memberType;
    }

    public getMemberTypeName(): string {
        return this.memberTypeName;
    }

    public setMemberTypeName(memberTypeName: string) {
        this.memberTypeName = memberTypeName;
    }

    public getMemberTypeDisplayName(): string {
        return this.memberTypeDisplayName;
    }

    public setMemberTypeDisplayName(memberTypeDisplayName: string) {
        this.memberTypeDisplayName = memberTypeDisplayName;
    }

    public getMemberTypeShortName(): string {
        return this.memberTypeShortName;
    }

    public setMemberTypeShortName(memberTypeShortName: string) {
        this.memberTypeShortName = memberTypeShortName;
    }

    public isHighestMember(): boolean {
        return this.highestMember;
    }

    public setHighestMember(highestMember: boolean) {
        this.highestMember = highestMember;
    }

    public getQllMemberStartDay(): number {
        return this.qllMemberStartDay;
    }

    public setQllMemberStartDay(qllMemberStartDay: number) {
        this.qllMemberStartDay = qllMemberStartDay;
    }

    public getQllMemberEndDay(): number {
        return this.qllMemberEndDay;
    }

    public setQllMemberEndDay(qllMemberEndDay: number) {
        this.qllMemberEndDay = qllMemberEndDay;
    }

    public isToShowUpgrade(): boolean {
        return this.toShowUpgrade;
    }

    public setToShowUpgrade(toShowUpgrade: boolean) {
        this.toShowUpgrade = toShowUpgrade;
    }

    public isIntlEntitled(): boolean {
        return this.intlEntitled;
    }

    public setIntlEntitled(intlEntitled: boolean) {
        this.intlEntitled = intlEntitled;
    }


    abstract copy(): AdditionalEntityAttributes;

    abstract getEntityType(): EntityType;

    abstract addAdditionalEntityAttributes(arg0: (AdditionalEntityAttributes | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let profession = json["profession"];
        if (profession != null) {
            let convertedProfession0 = Profession[<string>profession];
            this.setProfession(convertedProfession0);
        }
        let inProfession = json["inProfession"];
        if (inProfession != null) {
            this.setInProfession(inProfession);
        }
        let serviceProvider = json["serviceProvider"];
        if (serviceProvider != null) {
            this.setServiceProvider(serviceProvider);
        }
        let serviceMobile = json["serviceMobile"];
        if (serviceMobile != null) {
            this.setServiceMobile(serviceMobile);
        }
        let noPassword = json["noPassword"];
        if (noPassword != null) {
            this.setNoPassword(noPassword);
        }
        let privateChatDisabled = json["privateChatDisabled"];
        if (privateChatDisabled != null) {
            this.setPrivateChatDisabled(privateChatDisabled);
        }
        let noActivePush = json["noActivePush"];
        if (noActivePush != null) {
            this.setNoActivePush(noActivePush);
        }
        let inviteCode = json["inviteCode"];
        if (inviteCode != null) {
            this.setInviteCode(inviteCode);
        }
        let mobile = json["mobile"];
        if (mobile != null) {
            this.setMobile(mobile);
        }
        let email = json["email"];
        if (email != null) {
            this.setEmail(email);
        }
        let weChatId = json["weChatId"];
        if (weChatId != null) {
            this.setWeChatId(weChatId);
        }
        let entWeChatId = json["entWeChatId"];
        if (entWeChatId != null) {
            this.setEntWeChatId(entWeChatId);
        }
        let publicAccounted = json["publicAccounted"];
        if (publicAccounted != null) {
            this.setPublicAccounted(publicAccounted);
        }
        let serviceAccounted = json["serviceAccounted"];
        if (serviceAccounted != null) {
            this.setServiceAccounted(serviceAccounted);
        }
        let userEntityAdminInfo = json["userEntityAdminInfo"];
        if (userEntityAdminInfo != null) {
            let convertedUserEntityAdminInfo0 = new UserEntityAdminInfo();
            convertedUserEntityAdminInfo0.fillFromJson(userEntityAdminInfo, factory);
            this.setUserEntityAdminInfo(convertedUserEntityAdminInfo0);
        }
        let locationType = json["locationType"];
        if (locationType != null) {
            let convertedLocationType0 = LocationType[<string>locationType];
            this.setLocationType(convertedLocationType0);
        }
        let workerInfo = json["workerInfo"];
        if (workerInfo != null) {
            let convertedWorkerInfo0 = new WorkerInfo();
            convertedWorkerInfo0.fillFromJson(workerInfo, factory);
            this.setWorkerInfo(convertedWorkerInfo0);
        }
        let favorites = json["favorites"];
        if (favorites != null) {
            this.setFavorites(favorites);
        }
        let moneyAndPointsOn = json["moneyAndPointsOn"];
        if (moneyAndPointsOn != null) {
            this.setMoneyAndPointsOn(moneyAndPointsOn);
        }
        let anonymousOn = json["anonymousOn"];
        if (anonymousOn != null) {
            this.setAnonymousOn(anonymousOn);
        }
        let globalBusinessElite = json["globalBusinessElite"];
        if (globalBusinessElite != null) {
            this.setGlobalBusinessElite(globalBusinessElite);
        }
        let regionBusinessElite = json["regionBusinessElite"];
        if (regionBusinessElite != null) {
            this.setRegionBusinessElite(regionBusinessElite);
        }
        let eliteInReview = json["eliteInReview"];
        if (eliteInReview != null) {
            this.setEliteInReview(eliteInReview);
        }
        let memberType = json["memberType"];
        if (memberType != null) {
            let convertedMemberType0 = PlatformMemberType[<string>memberType];
            this.setMemberType(convertedMemberType0);
        }
        let memberTypeName = json["memberTypeName"];
        if (memberTypeName != null) {
            this.setMemberTypeName(memberTypeName);
        }
        let memberTypeDisplayName = json["memberTypeDisplayName"];
        if (memberTypeDisplayName != null) {
            this.setMemberTypeDisplayName(memberTypeDisplayName);
        }
        let memberTypeShortName = json["memberTypeShortName"];
        if (memberTypeShortName != null) {
            this.setMemberTypeShortName(memberTypeShortName);
        }
        let highestMember = json["highestMember"];
        if (highestMember != null) {
            this.setHighestMember(highestMember);
        }
        let qllMemberStartDay = json["qllMemberStartDay"];
        if (qllMemberStartDay != null) {
            this.setQllMemberStartDay(qllMemberStartDay);
        }
        let qllMemberEndDay = json["qllMemberEndDay"];
        if (qllMemberEndDay != null) {
            this.setQllMemberEndDay(qllMemberEndDay);
        }
        let toShowUpgrade = json["toShowUpgrade"];
        if (toShowUpgrade != null) {
            this.setToShowUpgrade(toShowUpgrade);
        }
        let intlEntitled = json["intlEntitled"];
        if (intlEntitled != null) {
            this.setIntlEntitled(intlEntitled);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let profession = this.getProfession();
        if (profession != null) {
            let convertedProfession0 = Profession[profession];
            json["profession"] = convertedProfession0;
        }
        let inProfession = this.isInProfession();
        if (inProfession != null) {
            json["inProfession"] = inProfession;
        }
        let serviceProvider = this.isServiceProvider();
        if (serviceProvider != null) {
            json["serviceProvider"] = serviceProvider;
        }
        let serviceMobile = this.getServiceMobile();
        if (serviceMobile != null) {
            json["serviceMobile"] = serviceMobile;
        }
        let noPassword = this.isNoPassword();
        if (noPassword != null) {
            json["noPassword"] = noPassword;
        }
        let privateChatDisabled = this.isPrivateChatDisabled();
        if (privateChatDisabled != null) {
            json["privateChatDisabled"] = privateChatDisabled;
        }
        let noActivePush = this.isNoActivePush();
        if (noActivePush != null) {
            json["noActivePush"] = noActivePush;
        }
        let inviteCode = this.getInviteCode();
        if (inviteCode != null) {
            json["inviteCode"] = inviteCode;
        }
        let mobile = this.getMobile();
        if (mobile != null) {
            json["mobile"] = mobile;
        }
        let email = this.getEmail();
        if (email != null) {
            json["email"] = email;
        }
        let weChatId = this.getWeChatId();
        if (weChatId != null) {
            json["weChatId"] = weChatId;
        }
        let entWeChatId = this.getEntWeChatId();
        if (entWeChatId != null) {
            json["entWeChatId"] = entWeChatId;
        }
        let publicAccounted = this.isPublicAccounted();
        if (publicAccounted != null) {
            json["publicAccounted"] = publicAccounted;
        }
        let serviceAccounted = this.isServiceAccounted();
        if (serviceAccounted != null) {
            json["serviceAccounted"] = serviceAccounted;
        }
        let userEntityAdminInfo = this.getUserEntityAdminInfo();
        if (userEntityAdminInfo != null) {
            let convertedUserEntityAdminInfo0 = userEntityAdminInfo.getJson(JsonTypeTagType.NONE);
            json["userEntityAdminInfo"] = convertedUserEntityAdminInfo0;
        }
        let locationType = this.getLocationType();
        if (locationType != null) {
            let convertedLocationType0 = LocationType[locationType];
            json["locationType"] = convertedLocationType0;
        }
        let workerInfo = this.getWorkerInfo();
        if (workerInfo != null) {
            let convertedWorkerInfo0 = workerInfo.getJson(JsonTypeTagType.NONE);
            json["workerInfo"] = convertedWorkerInfo0;
        }
        let favorites = this.getFavorites();
        if (favorites != null) {
            json["favorites"] = favorites;
        }
        let moneyAndPointsOn = this.isMoneyAndPointsOn();
        if (moneyAndPointsOn != null) {
            json["moneyAndPointsOn"] = moneyAndPointsOn;
        }
        let anonymousOn = this.isAnonymousOn();
        if (anonymousOn != null) {
            json["anonymousOn"] = anonymousOn;
        }
        let globalBusinessElite = this.isGlobalBusinessElite();
        if (globalBusinessElite != null) {
            json["globalBusinessElite"] = globalBusinessElite;
        }
        let regionBusinessElite = this.isRegionBusinessElite();
        if (regionBusinessElite != null) {
            json["regionBusinessElite"] = regionBusinessElite;
        }
        let eliteInReview = this.isEliteInReview();
        if (eliteInReview != null) {
            json["eliteInReview"] = eliteInReview;
        }
        let memberType = this.getMemberType();
        if (memberType != null) {
            let convertedMemberType0 = PlatformMemberType[memberType];
            json["memberType"] = convertedMemberType0;
        }
        let memberTypeName = this.getMemberTypeName();
        if (memberTypeName != null) {
            json["memberTypeName"] = memberTypeName;
        }
        let memberTypeDisplayName = this.getMemberTypeDisplayName();
        if (memberTypeDisplayName != null) {
            json["memberTypeDisplayName"] = memberTypeDisplayName;
        }
        let memberTypeShortName = this.getMemberTypeShortName();
        if (memberTypeShortName != null) {
            json["memberTypeShortName"] = memberTypeShortName;
        }
        let highestMember = this.isHighestMember();
        if (highestMember != null) {
            json["highestMember"] = highestMember;
        }
        let qllMemberStartDay = this.getQllMemberStartDay();
        if (qllMemberStartDay != null) {
            json["qllMemberStartDay"] = qllMemberStartDay;
        }
        let qllMemberEndDay = this.getQllMemberEndDay();
        if (qllMemberEndDay != null) {
            json["qllMemberEndDay"] = qllMemberEndDay;
        }
        let toShowUpgrade = this.isToShowUpgrade();
        if (toShowUpgrade != null) {
            json["toShowUpgrade"] = toShowUpgrade;
        }
        let intlEntitled = this.isIntlEntitled();
        if (intlEntitled != null) {
            json["intlEntitled"] = intlEntitled;
        }

        if (includeTypeTag) {
            json["__type"] = "UserDetails";
        }
    }


    public getTypeId(): string {
        return "UserDetails";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.user.UserDetails";
    }
}
