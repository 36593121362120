/**
 * 自动生成， 请不要手工修改！
 */

import {CompositeCoworkView} from '../cowork/CompositeCoworkView'
import {DistanceInfo} from '../geolocation/DistanceInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {ProductInfo} from './ProductInfo'
import {TypeFactory} from '../../serialize/TypeFactory'


export class ProductView extends CompositeCoworkView<ProductInfo, DistanceInfo> {

    constructor() {
        super();
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.product.ProductView";
    }
}
