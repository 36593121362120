/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCompositeCoworkInfo} from '../cowork/AbstractCompositeCoworkInfo'
import {EnterpriseServiceTradableStats} from '../business/service/tradable/EnterpriseServiceTradableStats'
import {EnterpriseServiceTradableUserInfo} from '../business/service/tradable/EnterpriseServiceTradableUserInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {Product} from './Product'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractCompositeProductInfo extends AbstractCompositeCoworkInfo<Product, EnterpriseServiceTradableStats, EnterpriseServiceTradableUserInfo, AbstractCompositeProductInfo> {

    constructor() {
        super();
    }


    abstract getVisits(): number;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);

        if (includeTypeTag) {
            json["__type"] = "CompositeProductInfo";
        }
    }


    public getTypeId(): string {
        return "CompositeProductInfo";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.product.CompositeProductInfo";
    }
}
