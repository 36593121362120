/**
 * 自动生成， 请不要手工修改！
 */

import { RequestStatusClassifier } from "./RequestStatusClassifier";

export class RequestStatusClassifierName {
  public static getDisplayName(value: RequestStatusClassifier): string {
    switch (value) {
      case RequestStatusClassifier.TO_PROCESS:
        return "待处理";
      case RequestStatusClassifier.IN_PROCESS:
        return "处理中";
      case RequestStatusClassifier.IN_ERROR:
        return "出错中";
      case RequestStatusClassifier.COMPLETED:
        return "已处理";
      case RequestStatusClassifier.OBSOLETE:
        return "已作废";
    }
    return null;
  }
}
