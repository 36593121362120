/**
 * 自动生成， 请不要手工修改！
 */


export enum GroupInfoTabType {
    SYNTHESIZED,
    COOP,
    DEMAND,
    LABOUR,
    SERVICE_INFO,
    SERVICE,
    COOP_COMMERCIAL,
    COMMERCIAL_NEWS,
    SERVICE_PROVIDERS,
    COMMERCIAL_DYNAMIC,
    SHARED_WISDOM,
    WISDOM_AND_DYNAMIC,
    SHARED_INFO,
    GENERAL_REVIEW,
    GROUP_DYNAMIC,
    NC_GROUP_DYNAMIC,
    NC_GROUP_SHARED_WISDOM,
    WISDOM_AND_USER_DYNAMIC,
    MY_LABOUR,
    PRODUCT,
    APPEAL,
    INTERNAL
}
