/**
 * 自动生成， 请不要手工修改！
 */

import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { MineFilter } from "./MineFilter";
import { QuerySpec } from "../QuerySpec";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class MineQuerySpec extends QuerySpec<MineFilter> {
  constructor() {
    super();
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.mine.MineQuerySpec";
  }
}
