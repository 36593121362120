// @ts-nocheck
import {AbstractSimpleEntityDesc} from "./AbstractSimpleEntityDesc";
import {MessageType} from "../../message/MessageContentType";

export class SimpleEntityDesc extends AbstractSimpleEntityDesc {
  constructor() {
    super();
  }

    getMessageType(): MessageType {
        // Manual Code Here
    }
}
