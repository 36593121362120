/**
 * 自动生成， 请不要手工修改！
 */

import { CoworkUserInfo } from "../../../cowork/CoworkUserInfo";
import { GeneralCoworkUserInfo } from "../../../cowork/GeneralCoworkUserInfo";
import { JsonTypeTagType } from "../../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../../serialize/TypeFactory";

export abstract class AbstractEnterpriseServiceTradableUserInfo extends GeneralCoworkUserInfo {
  purchased: boolean;

  constructor() {
    super();
  }

  public isPurchased(): boolean {
    return this.purchased;
  }

  public setPurchased(purchased: boolean) {
    this.purchased = purchased;
  }

  abstract isActive(): boolean;

  abstract isPulled(): boolean;

  abstract getPrizable(): boolean;

  abstract getApproverPrize(): number;

  abstract getCreatorPrize(): number;

  abstract getRelApproverPrize(): number;

  abstract getRelCreatorPrize(): number;

  abstract addApproverPrize(arg0: number | null);

  abstract addCreatorPrize(arg0: number | null);

  abstract setActive(active: boolean | null);

  abstract setApproverPrize(approverPrize: number | null);

  abstract setCreatorPrize(creatorPrize: number | null);

  abstract setPrizable(prizable: boolean | null);

  abstract setPulled();

  abstract setRelCreatorPrize(relCreatorPrize: number | null);

  abstract update(coworkUserInfo: CoworkUserInfo | null);

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const purchased = json["purchased"];
    if (purchased != null) {
      this.setPurchased(purchased);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const purchased = this.isPurchased();
    if (purchased != null) {
      json["purchased"] = purchased;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.tradable.EnterpriseServiceTradableUserInfo";
  }
}
