/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCompositeCoworkInfo} from '../cowork/AbstractCompositeCoworkInfo'
import {ActivityStats} from './ActivityStats'
import {ActivityUserInfo} from './ActivityUserInfo'
import {Activity} from './Activity'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractCompositeActivityInfo extends AbstractCompositeCoworkInfo<Activity, ActivityStats, ActivityUserInfo, AbstractCompositeActivityInfo> {
    quitable: boolean;

    constructor() {
        super();
    }

    public getQuitable(): boolean {
        return this.quitable;
    }

    public setQuitable(quitable: boolean) {
        this.quitable = quitable;
    }


    abstract getVisits(): number;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let quitable = json["quitable"];
        if (quitable != null) {
            this.setQuitable(quitable);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let quitable = this.getQuitable();
        if (quitable != null) {
            json["quitable"] = quitable;
        }

        if (includeTypeTag) {
            json["__type"] = "CompositeActivityInfo";
        }
    }


    public getTypeId(): string {
        return "CompositeActivityInfo";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.activity.CompositeActivityInfo";
    }
}
