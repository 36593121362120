/**
 * 自动生成， 请不要手工修改！
 */

export enum ReleaseStatus {
  IN_REVIEW,
  RELEASED,
  DUE_TO_PAY,
  FAILED,
}
