/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {RangeType} from './RangeType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class TopicInfo extends AbstractSerializable {
    special: boolean;
    brand: boolean;
    totalPrize: number;
    currentPrize: number;
    rangeType: RangeType;

    constructor() {
        super();
    }

    public getSpecial(): boolean {
        return this.special;
    }

    public setSpecial(special: boolean) {
        this.special = special;
    }

    public getBrand(): boolean {
        return this.brand;
    }

    public setBrand(brand: boolean) {
        this.brand = brand;
    }

    public getTotalPrize(): number {
        return this.totalPrize;
    }

    public setTotalPrize(totalPrize: number) {
        this.totalPrize = totalPrize;
    }

    public getCurrentPrize(): number {
        return this.currentPrize;
    }

    public setCurrentPrize(currentPrize: number) {
        this.currentPrize = currentPrize;
    }

    public getRangeType(): RangeType {
        return this.rangeType;
    }

    public setRangeType(rangeType: RangeType) {
        this.rangeType = rangeType;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let special = json["special"];
        if (special != null) {
            this.setSpecial(special);
        }
        let brand = json["brand"];
        if (brand != null) {
            this.setBrand(brand);
        }
        let totalPrize = json["totalPrize"];
        if (totalPrize != null) {
            this.setTotalPrize(totalPrize);
        }
        let currentPrize = json["currentPrize"];
        if (currentPrize != null) {
            this.setCurrentPrize(currentPrize);
        }
        let rangeType = json["rangeType"];
        if (rangeType != null) {
            let convertedRangeType0 = RangeType[<string>rangeType];
            this.setRangeType(convertedRangeType0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let special = this.getSpecial();
        if (special != null) {
            json["special"] = special;
        }
        let brand = this.getBrand();
        if (brand != null) {
            json["brand"] = brand;
        }
        let totalPrize = this.getTotalPrize();
        if (totalPrize != null) {
            json["totalPrize"] = totalPrize;
        }
        let currentPrize = this.getCurrentPrize();
        if (currentPrize != null) {
            json["currentPrize"] = currentPrize;
        }
        let rangeType = this.getRangeType();
        if (rangeType != null) {
            let convertedRangeType0 = RangeType[rangeType];
            json["rangeType"] = convertedRangeType0;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.topic.TopicInfo";
    }
}
