/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCoworkInfo} from '../../cowork/AbstractCoworkInfo'
import {CoworkInfo} from '../../cowork/CoworkInfo'
import {CoworkSimpleView} from '../../cowork/CoworkSimpleView'
import {CoworkStats} from '../../cowork/CoworkStats'
import {CoworkType} from '../../cowork/CoworkType'
import {EntityInfo} from '../../entity/EntityInfo'
import {GeoHash} from '../../geolocation/GeoHash'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../keyword/KeywordEntity'
import {QuestionStatus} from './QuestionStatus'
import {SelectTimes} from '../../cowork/SelectTimes'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractQuestionInfo extends AbstractCoworkInfo  {
    keywordEntities: Array<KeywordEntity>;
    description: string;
    subscribers: number;
    answers: number;
    status: QuestionStatus;

    constructor() {
    	super();
    }

    public getKeywordEntities(): Array<KeywordEntity> {
    	return this.keywordEntities;
    }

    public setKeywordEntities(keywordEntities: Array<KeywordEntity>) {
    	this.keywordEntities = keywordEntities;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getSubscribers(): number {
    	return this.subscribers;
    }

    public setSubscribers(subscribers: number) {
    	this.subscribers = subscribers;
    }

    public getAnswers(): number {
    	return this.answers;
    }

    public setAnswers(answers: number) {
    	this.answers = answers;
    }

    public getStatus(): QuestionStatus {
    	return this.status;
    }

    public setStatus(status: QuestionStatus) {
    	this.status = status;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let keywordEntities = json["keywordEntities"];
        if (keywordEntities != null) {
            let convertedKeywordEntities0 = [];
            keywordEntities = keywordEntities[1];
            for (let i in keywordEntities) {
                let convertedKeywordEntities10 = keywordEntities[i];
                let convertedKeywordEntities1 = new KeywordEntity();
                convertedKeywordEntities1.fillFromJson(convertedKeywordEntities10, factory);
                convertedKeywordEntities0.push(convertedKeywordEntities1);
            }
            this.setKeywordEntities(convertedKeywordEntities0);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let subscribers = json["subscribers"];
        if (subscribers != null) {
            this.setSubscribers(subscribers);
        }
        let answers = json["answers"];
        if (answers != null) {
            this.setAnswers(answers);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = QuestionStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let keywordEntities = this.getKeywordEntities();
        if (keywordEntities != null) {
            let convertedKeywordEntities0 = [];
            for (let i in keywordEntities) {
                let convertedKeywordEntities10 = keywordEntities[i];
                let convertedKeywordEntities1 = convertedKeywordEntities10.getJson(JsonTypeTagType.NONE);
                convertedKeywordEntities0.push(convertedKeywordEntities1);
            }
            let convertedKeywordEntitiesWithType1 = ["java.util.ArrayList", convertedKeywordEntities0];
            json["keywordEntities"] = convertedKeywordEntitiesWithType1;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let subscribers = this.getSubscribers();
        if (subscribers != null) {
            json["subscribers"] = subscribers;
        }
        let answers = this.getAnswers();
        if (answers != null) {
            json["answers"] = answers;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = QuestionStatus[status];
            json["status"] = convertedStatus0;
        }
    
        if (includeTypeTag) {
            json["__type"] = "QuestionInfo";
        }
    }


    public getTypeId(): string {
    	return "QuestionInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.ipsphere.qa.QuestionInfo";
    }
}
