/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractEnterpriseTradableInfo} from '../business/tradable/AbstractEnterpriseTradableInfo'
import {CoworkInfo} from '../cowork/CoworkInfo'
import {CoworkSimpleView} from '../cowork/CoworkSimpleView'
import {CoworkStats} from '../cowork/CoworkStats'
import {CoworkType} from '../cowork/CoworkType'
import {EntityInfo} from '../entity/EntityInfo'
import {GeoHash} from '../geolocation/GeoHash'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {ProductStatus} from './ProductStatus'
import {SelectTimes} from '../cowork/SelectTimes'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractProductInfo extends AbstractEnterpriseTradableInfo  {
    contactInfo: EntityInfo;
    producer: boolean;
    productImgUrl: string;
    topicInfo: EntityInfo;
    prodSpec: string;
    declare price: number;
    status: ProductStatus;

    constructor() {
    	super();
    }

    public getContactInfo(): EntityInfo {
    	return this.contactInfo;
    }

    public setContactInfo(contactInfo: EntityInfo) {
    	this.contactInfo = contactInfo;
    }

    public isProducer(): boolean {
    	return this.producer;
    }

    public setProducer(producer: boolean) {
    	this.producer = producer;
    }

    public getProductImgUrl(): string {
    	return this.productImgUrl;
    }

    public setProductImgUrl(productImgUrl: string) {
    	this.productImgUrl = productImgUrl;
    }

    public getTopicInfo(): EntityInfo {
    	return this.topicInfo;
    }

    public setTopicInfo(topicInfo: EntityInfo) {
    	this.topicInfo = topicInfo;
    }

    public getProdSpec(): string {
    	return this.prodSpec;
    }

    public setProdSpec(prodSpec: string) {
    	this.prodSpec = prodSpec;
    }

    public getPrice(): number {
    	return this.price;
    }

    public setPrice(price: number) {
    	this.price = price;
    }

    public getStatus(): ProductStatus {
    	return this.status;
    }

    public setStatus(status: ProductStatus) {
    	this.status = status;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let contactInfo = json["contactInfo"];
        if (contactInfo != null) {
            let convertedContactInfo0 = Serializer.fillFromJsonObjectWithTypeTag(contactInfo, "__type", factory);
            this.setContactInfo(convertedContactInfo0);
        }
        let producer = json["producer"];
        if (producer != null) {
            this.setProducer(producer);
        }
        let productImgUrl = json["productImgUrl"];
        if (productImgUrl != null) {
            this.setProductImgUrl(productImgUrl);
        }
        let topicInfo = json["topicInfo"];
        if (topicInfo != null) {
            let convertedTopicInfo0 = Serializer.fillFromJsonObjectWithTypeTag(topicInfo, "__type", factory);
            this.setTopicInfo(convertedTopicInfo0);
        }
        let prodSpec = json["prodSpec"];
        if (prodSpec != null) {
            this.setProdSpec(prodSpec);
        }
        let price = json["price"];
        if (price != null) {
            this.setPrice(price);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = ProductStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let contactInfo = this.getContactInfo();
        if (contactInfo != null) {
            let convertedContactInfo0 = contactInfo.getJson(JsonTypeTagType.TYPE);
            json["contactInfo"] = convertedContactInfo0;
        }
        let producer = this.isProducer();
        if (producer != null) {
            json["producer"] = producer;
        }
        let productImgUrl = this.getProductImgUrl();
        if (productImgUrl != null) {
            json["productImgUrl"] = productImgUrl;
        }
        let topicInfo = this.getTopicInfo();
        if (topicInfo != null) {
            let convertedTopicInfo0 = topicInfo.getJson(JsonTypeTagType.TYPE);
            json["topicInfo"] = convertedTopicInfo0;
        }
        let prodSpec = this.getProdSpec();
        if (prodSpec != null) {
            json["prodSpec"] = prodSpec;
        }
        let price = this.getPrice();
        if (price != null) {
            json["price"] = price;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = ProductStatus[status];
            json["status"] = convertedStatus0;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.product.ProductInfo";
    }
}
