/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../../serialize/AbstractJsonSerializable";
import { Filter } from "../../../query/Filter";
import { JsonTypeTagType } from "../../../../serialize/JsonTypeTagType";
import { TradeStatus } from "./TradeStatus";
import { TypeFactory } from "../../../../serialize/TypeFactory";

export class TradeFilter extends AbstractSerializable implements Filter {
  startTime: number | string;
  endTime: number | string;
  status: TradeStatus;

  constructor() {
    super();
  }

  public getStartTime(): number | string {
    return this.startTime;
  }

  public setStartTime(startTime: number | string) {
    this.startTime = startTime;
  }

  public getEndTime(): number | string {
    return this.endTime;
  }

  public setEndTime(endTime: number | string) {
    this.endTime = endTime;
  }

  public getStatus(): TradeStatus {
    return this.status;
  }

  public setStatus(status: TradeStatus) {
    this.status = status;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const startTime = json["startTime"];
    if (startTime != null) {
      this.setStartTime(startTime);
    }
    const endTime = json["endTime"];
    if (endTime != null) {
      this.setEndTime(endTime);
    }
    const status = json["status"];
    if (status != null) {
      const convertedStatus0 = TradeStatus[<string>status];
      this.setStatus(convertedStatus0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const startTime = this.getStartTime();
    if (startTime != null) {
      json["startTime"] = String(startTime);
    }
    const endTime = this.getEndTime();
    if (endTime != null) {
      json["endTime"] = String(endTime);
    }
    const status = this.getStatus();
    if (status != null) {
      const convertedStatus0 = TradeStatus[status];
      json["status"] = convertedStatus0;
    }
  }

  public getTypeId(): string {
    return "TradeFilter";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.general.trade.TradeFilter";
  }
}
