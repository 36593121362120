/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../../serialize/AbstractJsonSerializable";
import { EntityInfo } from "../../../entity/EntityInfo";
import { JsonTypeTagType } from "../../../../serialize/JsonTypeTagType";
import { Serializer } from "../../../../serialize/Serializer";
import { ServiceStatsItem } from "./ServiceStatsItem";
import { TypeFactory } from "../../../../serialize/TypeFactory";
import { RequestStatusClassifier } from "@/services/datatype/request/RequestStatusClassifier";

export class EntityServiceStats extends AbstractSerializable {
  servingEntityInfo: EntityInfo;
  totalMembers = 10000; // 平台会员总数
  totalRequests: number;
  totalUnassignedRequests: number;
  totalAssignedRequests: number; // 本季度承接请求数
  totalAcceptedRequests: number;
  totalCompletedRequests: number;
  totalRequestsToSettle: number; // 本季度待结算请求数
  totalAmount: number; // 总收益
  totalAmountToSettle: number; // 本季度待结算总收益
  statsItems: Array<ServiceStatsItem>;
  // statsItemsToProcess: Array<ServiceStatsItem>;
  // statsItemsInProcess: Array<ServiceStatsItem>;
  // statsItemsCompleted: Array<ServiceStatsItem>;

  constructor() {
    super();
  }

  public getServingEntityInfo(): EntityInfo {
    return this.servingEntityInfo;
  }

  public setServingEntityInfo(servingEntityInfo: EntityInfo) {
    this.servingEntityInfo = servingEntityInfo;
  }

  public getTotalMembers(): number {
    return this.totalMembers;
  }

  public setTotalMembers(totalMembers: number) {
    this.totalMembers = totalMembers;
  }

  public getTotalRequests(): number {
    return this.totalRequests;
  }

  public setTotalRequests(totalRequests: number) {
    this.totalRequests = totalRequests;
  }

  public getTotalUnassignedRequests(): number {
    return this.totalUnassignedRequests;
  }

  public setTotalUnassignedRequests(totalUnassignedRequests: number) {
    this.totalUnassignedRequests = totalUnassignedRequests;
  }

  public getTotalAssignedRequests(): number {
    return this.totalAssignedRequests;
  }

  public setTotalAssignedRequests(totalAssignedRequests: number) {
    this.totalAssignedRequests = totalAssignedRequests;
  }

  public getTotalAcceptedRequests(): number {
    return this.totalAcceptedRequests;
  }

  public setTotalAcceptedRequests(totalAcceptedRequests: number) {
    this.totalAcceptedRequests = totalAcceptedRequests;
  }

  public getTotalCompletedRequests(): number {
    return this.totalCompletedRequests;
  }

  public setTotalCompletedRequests(totalCompletedRequests: number) {
    this.totalCompletedRequests = totalCompletedRequests;
  }

  public getTotalRequestsToSettle(): number {
    return this.totalRequestsToSettle;
  }

  public setTotalRequestsToSettle(totalRequestsToSettle: number) {
    this.totalRequestsToSettle = totalRequestsToSettle;
  }

  public getTotalAmount(): number {
    return this.totalAmount;
  }

  public setTotalAmount(totalAmount: number) {
    this.totalAmount = totalAmount;
  }

  public getTotalAmountToSettle(): number {
    return this.totalAmountToSettle;
  }

  public setTotalAmountToSettle(totalAmountToSettle: number) {
    this.totalAmountToSettle = totalAmountToSettle;
  }

  public getStatsItems(): Array<ServiceStatsItem> {
    return this.statsItems;
  }

  public setStatsItems(statsItems: Array<ServiceStatsItem>) {
    this.statsItems = statsItems;
  }

  public getStatsItemsToProcess(): Array<ServiceStatsItem> | null {
    if (this.statsItems && this.statsItems.length > 0) {
      const statsItemsToProcess = new Array<ServiceStatsItem>();
      let item: ServiceStatsItem;
      for (let i = 0; i < this.statsItems.length; i++) {
        item = this.statsItems[i];
        if (item.getTotalAssignedRequests() > 0) {
          statsItemsToProcess.push(item);
        }
      }
      return statsItemsToProcess;
    } else {
      return null;
    }
  }

  public getStatsItemsInProcess(): Array<ServiceStatsItem> | null {
    if (this.statsItems && this.statsItems.length > 0) {
      const statsItemsToProcess = new Array<ServiceStatsItem>();
      let item: ServiceStatsItem;
      for (let i = 0; i < this.statsItems.length; i++) {
        item = this.statsItems[i];
        if (item.getTotalAcceptedRequests() > 0) {
          statsItemsToProcess.push(item);
        }
      }
      return statsItemsToProcess;
    } else {
      return null;
    }
  }

  public getStatsItemsCompleted(): Array<ServiceStatsItem> | null {
    if (this.statsItems && this.statsItems.length > 0) {
      const statsItemsToProcess = new Array<ServiceStatsItem>();
      let item: ServiceStatsItem;
      for (let i = 0; i < this.statsItems.length; i++) {
        item = this.statsItems[i];
        if (item.getTotalCompletedRequests() > 0) {
          statsItemsToProcess.push(item);
        }
      }
      return statsItemsToProcess;
    } else {
      return null;
    }
  }

  public getStatsItemsByStatusClassifier(
    category: RequestStatusClassifier
  ): Array<ServiceStatsItem> | null {
    switch (category) {
      case RequestStatusClassifier.TO_PROCESS:
        return this.getStatsItemsToProcess();
      case RequestStatusClassifier.IN_PROCESS:
        return this.getStatsItemsInProcess();
      case RequestStatusClassifier.COMPLETED:
        return this.getStatsItemsCompleted();
      default:
        return null;
    }
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const servingEntityInfo = json["servingEntityInfo"];
    if (servingEntityInfo != null) {
      const convertedServingEntityInfo0 =
        Serializer.fillFromJsonObjectWithTypeTag(
          servingEntityInfo,
          "__type",
          factory
        );
      this.setServingEntityInfo(convertedServingEntityInfo0);
    }
    const totalMembers = json["totalMembers"];
    if (totalMembers != null) {
      this.setTotalMembers(totalMembers);
    }
    const totalRequests = json["totalRequests"];
    if (totalRequests != null) {
      this.setTotalRequests(totalRequests);
    }
    const totalUnassignedRequests = json["totalUnassignedRequests"];
    if (totalUnassignedRequests != null) {
      this.setTotalUnassignedRequests(totalUnassignedRequests);
    }
    const totalAssignedRequests = json["totalAssignedRequests"];
    if (totalAssignedRequests != null) {
      this.setTotalAssignedRequests(totalAssignedRequests);
    }
    const totalAcceptedRequests = json["totalAcceptedRequests"];
    if (totalAcceptedRequests != null) {
      this.setTotalAcceptedRequests(totalAcceptedRequests);
    }
    const totalCompletedRequests = json["totalCompletedRequests"];
    if (totalCompletedRequests != null) {
      this.setTotalCompletedRequests(totalCompletedRequests);
    }
    const totalRequestsToSettle = json["totalRequestsToSettle"];
    if (totalRequestsToSettle != null) {
      this.setTotalRequestsToSettle(totalRequestsToSettle);
    }
    const totalAmount = json["totalAmount"];
    if (totalAmount != null) {
      this.setTotalAmount(totalAmount);
    }
    const totalAmountToSettle = json["totalAmountToSettle"];
    if (totalAmountToSettle != null) {
      this.setTotalAmountToSettle(totalAmountToSettle);
    }
    let statsItems = json["statsItems"];
    if (statsItems != null) {
      const convertedStatsItems0 = [];
      statsItems = statsItems[1];
      for (const i in statsItems) {
        const convertedStatsItems10 = statsItems[i];
        const convertedStatsItems1 = new ServiceStatsItem();
        convertedStatsItems1.fillFromJson(convertedStatsItems10, factory);
        convertedStatsItems0.push(convertedStatsItems1);
      }
      this.setStatsItems(convertedStatsItems0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const servingEntityInfo = this.getServingEntityInfo();
    if (servingEntityInfo != null) {
      const convertedServingEntityInfo0 = servingEntityInfo.getJson(
        JsonTypeTagType.TYPE
      );
      json["servingEntityInfo"] = convertedServingEntityInfo0;
    }
    const totalMembers = this.getTotalMembers();
    if (totalMembers != null) {
      json["totalMembers"] = totalMembers;
    }
    const totalRequests = this.getTotalRequests();
    if (totalRequests != null) {
      json["totalRequests"] = totalRequests;
    }
    const totalUnassignedRequests = this.getTotalUnassignedRequests();
    if (totalUnassignedRequests != null) {
      json["totalUnassignedRequests"] = totalUnassignedRequests;
    }
    const totalAssignedRequests = this.getTotalAssignedRequests();
    if (totalAssignedRequests != null) {
      json["totalAssignedRequests"] = totalAssignedRequests;
    }
    const totalAcceptedRequests = this.getTotalAcceptedRequests();
    if (totalAcceptedRequests != null) {
      json["totalAcceptedRequests"] = totalAcceptedRequests;
    }
    const totalCompletedRequests = this.getTotalCompletedRequests();
    if (totalCompletedRequests != null) {
      json["totalCompletedRequests"] = totalCompletedRequests;
    }
    const totalRequestsToSettle = this.getTotalRequestsToSettle();
    if (totalRequestsToSettle != null) {
      json["totalRequestsToSettle"] = totalRequestsToSettle;
    }
    const totalAmount = this.getTotalAmount();
    if (totalAmount != null) {
      json["totalAmount"] = totalAmount;
    }
    const totalAmountToSettle = this.getTotalAmountToSettle();
    if (totalAmountToSettle != null) {
      json["totalAmountToSettle"] = totalAmountToSettle;
    }
    const statsItems = this.getStatsItems();
    if (statsItems != null) {
      const convertedStatsItems0 = [];
      for (const i in statsItems) {
        const convertedStatsItems10 = statsItems[i];
        const convertedStatsItems1 = convertedStatsItems10.getJson(
          JsonTypeTagType.NONE
        );
        convertedStatsItems0.push(convertedStatsItems1);
      }
      const convertedStatsItemsWithType1 = [
        "java.util.ArrayList",
        convertedStatsItems0,
      ];
      json["statsItems"] = convertedStatsItemsWithType1;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.stats.EntityServiceStats";
  }
}
