/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {CoworkIdentifier} from '../cowork/CoworkIdentifier'
import {EntityInfo} from '../entity/EntityInfo'
import {FeedBackType} from './FeedBackType'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export class FeedBackDetails extends AbstractSerializable {
    id: (number | string);
    initiatorEntityInfo: EntityInfo;
    feedBackType: FeedBackType;
    coworkId: CoworkIdentifier;
    description: string;
    imageUrl: string;
    contactInfo: string;

    constructor() {
        super();
    }

    public getId(): (number | string) {
        return this.id;
    }

    public setId(id: (number | string)) {
        this.id = id;
    }

    public getInitiatorEntityInfo(): EntityInfo {
        return this.initiatorEntityInfo;
    }

    public setInitiatorEntityInfo(initiatorEntityInfo: EntityInfo) {
        this.initiatorEntityInfo = initiatorEntityInfo;
    }

    public getFeedBackType(): FeedBackType {
        return this.feedBackType;
    }

    public setFeedBackType(feedBackType: FeedBackType) {
        this.feedBackType = feedBackType;
    }

    public getCoworkId(): CoworkIdentifier {
        return this.coworkId;
    }

    public setCoworkId(coworkId: CoworkIdentifier) {
        this.coworkId = coworkId;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string) {
        this.description = description;
    }

    public getImageUrl(): string {
        return this.imageUrl;
    }

    public setImageUrl(imageUrl: string) {
        this.imageUrl = imageUrl;
    }

    public getContactInfo(): string {
        return this.contactInfo;
    }

    public setContactInfo(contactInfo: string) {
        this.contactInfo = contactInfo;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let id = json["id"];
        if (id != null) {
            this.setId(id);
        }
        let initiatorEntityInfo = json["initiatorEntityInfo"];
        if (initiatorEntityInfo != null) {
            let convertedInitiatorEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(initiatorEntityInfo, "__type", factory);
            this.setInitiatorEntityInfo(convertedInitiatorEntityInfo0);
        }
        let feedBackType = json["feedBackType"];
        if (feedBackType != null) {
            let convertedFeedBackType0 = FeedBackType[<string>feedBackType];
            this.setFeedBackType(convertedFeedBackType0);
        }
        let coworkId = json["coworkId"];
        if (coworkId != null) {
            let convertedCoworkId0 = new CoworkIdentifier();
            convertedCoworkId0.fillFromJson(coworkId, factory);
            this.setCoworkId(convertedCoworkId0);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let imageUrl = json["imageUrl"];
        if (imageUrl != null) {
            this.setImageUrl(imageUrl);
        }
        let contactInfo = json["contactInfo"];
        if (contactInfo != null) {
            this.setContactInfo(contactInfo);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let id = this.getId();
        if (id != null) {
            json["id"] = String(id);
        }
        let initiatorEntityInfo = this.getInitiatorEntityInfo();
        if (initiatorEntityInfo != null) {
            let convertedInitiatorEntityInfo0 = initiatorEntityInfo.getJson(JsonTypeTagType.TYPE);
            json["initiatorEntityInfo"] = convertedInitiatorEntityInfo0;
        }
        let feedBackType = this.getFeedBackType();
        if (feedBackType != null) {
            let convertedFeedBackType0 = FeedBackType[feedBackType];
            json["feedBackType"] = convertedFeedBackType0;
        }
        let coworkId = this.getCoworkId();
        if (coworkId != null) {
            let convertedCoworkId0 = coworkId.getJson(JsonTypeTagType.NONE);
            json["coworkId"] = convertedCoworkId0;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let imageUrl = this.getImageUrl();
        if (imageUrl != null) {
            json["imageUrl"] = imageUrl;
        }
        let contactInfo = this.getContactInfo();
        if (contactInfo != null) {
            json["contactInfo"] = contactInfo;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.setting.FeedBackDetails";
    }
}
