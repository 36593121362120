/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { ServiceHighlightId } from "./ServiceHighlightId";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class ServiceHighlight extends AbstractSerializable {
  position: number;
  highlightId: ServiceHighlightId;
  title: string;
  description: string;
  imgUrl: string;
  infoUrl: string;

  constructor() {
    super();
  }

  public getPosition(): number {
    return this.position;
  }

  public setPosition(position: number) {
    this.position = position;
  }

  public getHighlightId(): ServiceHighlightId {
    return this.highlightId;
  }

  public setHighlightId(highlightId: ServiceHighlightId) {
    this.highlightId = highlightId;
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string) {
    this.title = title;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getImgUrl(): string {
    return this.imgUrl;
  }

  public setImgUrl(imgUrl: string) {
    this.imgUrl = imgUrl;
  }

  public getInfoUrl(): string {
    return this.infoUrl;
  }

  public setInfoUrl(infoUrl: string) {
    this.infoUrl = infoUrl;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const position = json["position"];
    if (position != null) {
      this.setPosition(position);
    }
    const highlightId = json["highlightId"];
    if (highlightId != null) {
      const convertedHighlightId0 = ServiceHighlightId[<string>highlightId];
      this.setHighlightId(convertedHighlightId0);
    }
    const title = json["title"];
    if (title != null) {
      this.setTitle(title);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    const imgUrl = json["imgUrl"];
    if (imgUrl != null) {
      this.setImgUrl(imgUrl);
    }
    const infoUrl = json["infoUrl"];
    if (infoUrl != null) {
      this.setInfoUrl(infoUrl);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const position = this.getPosition();
    if (position != null) {
      json["position"] = position;
    }
    const highlightId = this.getHighlightId();
    if (highlightId != null) {
      const convertedHighlightId0 = ServiceHighlightId[highlightId];
      json["highlightId"] = convertedHighlightId0;
    }
    const title = this.getTitle();
    if (title != null) {
      json["title"] = title;
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const imgUrl = this.getImgUrl();
    if (imgUrl != null) {
      json["imgUrl"] = imgUrl;
    }
    const infoUrl = this.getInfoUrl();
    if (infoUrl != null) {
      json["infoUrl"] = infoUrl;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.ServiceHighlight";
  }
}
