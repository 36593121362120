/**
 * 自动生成， 请不要手工修改！
 */

export enum ReviewStatus {
  IN_REVIEW,
  ACTIVE,
  CANCELLED,
  HIDDEN,
  DISLIKED,
}
