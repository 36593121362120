/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {EnterpriseServiceCategory} from '../EnterpriseServiceCategory'
import {EnterpriseServiceType} from '../EnterpriseServiceType'
import {EntityInfo} from '../../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../../keyword/KeywordEntity'
import {RoleType} from '../../../entity/RoleType'
import {Serializer} from '../../../../serialize/Serializer'
import {ServiceDeliveryType} from '../ServiceDeliveryType'
import {TradeStatus} from '../../general/trade/TradeStatus'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class EnterpriseServiceTradeInfo extends AbstractSerializable  {
    tradeId: (number | string);
    tradableId: (number | string);
    orderId: (number | string);
    regionId: (number | string);
    servicePackage: boolean;
    global: boolean;
    serviceType: EnterpriseServiceType;
    serviceTag: EnterpriseServiceCategory;
    tradingEnterpriseInfo: EntityInfo;
    tradingUserInfo: EntityInfo;
    imageUrls: Array<string>;
    keywordEntities: Array<KeywordEntity>;
    title: string;
    description: string;
    activityId: (number | string);
    price: number;
    discount: number;
    tradeUnits: number;
    tradeAmount: number;
    serviceQuota: number;
    remainingServiceQuota: number;
    serviceDeliveryType: ServiceDeliveryType;
    offered: boolean;
    startDay: number;
    endDay: number;
    status: TradeStatus;
    createTime: (number | string);
    renewed: boolean;
    remindingReview: boolean;
    entitledToUse: boolean;
    entitledRole: RoleType;

    constructor() {
    	super();
    }

    public getTradeId(): (number | string) {
    	return this.tradeId;
    }

    public setTradeId(tradeId: (number | string)) {
    	this.tradeId = tradeId;
    }

    public getTradableId(): (number | string) {
    	return this.tradableId;
    }

    public setTradableId(tradableId: (number | string)) {
    	this.tradableId = tradableId;
    }

    public getOrderId(): (number | string) {
    	return this.orderId;
    }

    public setOrderId(orderId: (number | string)) {
    	this.orderId = orderId;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public isServicePackage(): boolean {
    	return this.servicePackage;
    }

    public setServicePackage(servicePackage: boolean) {
    	this.servicePackage = servicePackage;
    }

    public isGlobal(): boolean {
    	return this.global;
    }

    public setGlobal(global: boolean) {
    	this.global = global;
    }

    public getServiceType(): EnterpriseServiceType {
    	return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
    	this.serviceType = serviceType;
    }

    public getServiceTag(): EnterpriseServiceCategory {
    	return this.serviceTag;
    }

    public setServiceTag(serviceTag: EnterpriseServiceCategory) {
    	this.serviceTag = serviceTag;
    }

    public getTradingEnterpriseInfo(): EntityInfo {
    	return this.tradingEnterpriseInfo;
    }

    public setTradingEnterpriseInfo(tradingEnterpriseInfo: EntityInfo) {
    	this.tradingEnterpriseInfo = tradingEnterpriseInfo;
    }

    public getTradingUserInfo(): EntityInfo {
    	return this.tradingUserInfo;
    }

    public setTradingUserInfo(tradingUserInfo: EntityInfo) {
    	this.tradingUserInfo = tradingUserInfo;
    }

    public getImageUrls(): Array<string> {
    	return this.imageUrls;
    }

    public setImageUrls(imageUrls: Array<string>) {
    	this.imageUrls = imageUrls;
    }

    public getKeywordEntities(): Array<KeywordEntity> {
    	return this.keywordEntities;
    }

    public setKeywordEntities(keywordEntities: Array<KeywordEntity>) {
    	this.keywordEntities = keywordEntities;
    }

    public getTitle(): string {
    	return this.title;
    }

    public setTitle(title: string) {
    	this.title = title;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getActivityId(): (number | string) {
    	return this.activityId;
    }

    public setActivityId(activityId: (number | string)) {
    	this.activityId = activityId;
    }

    public getPrice(): number {
    	return this.price;
    }

    public setPrice(price: number) {
    	this.price = price;
    }

    public getDiscount(): number {
    	return this.discount;
    }

    public setDiscount(discount: number) {
    	this.discount = discount;
    }

    public getTradeUnits(): number {
    	return this.tradeUnits;
    }

    public setTradeUnits(tradeUnits: number) {
    	this.tradeUnits = tradeUnits;
    }

    public getTradeAmount(): number {
    	return this.tradeAmount;
    }

    public setTradeAmount(tradeAmount: number) {
    	this.tradeAmount = tradeAmount;
    }

    public getServiceQuota(): number {
    	return this.serviceQuota;
    }

    public setServiceQuota(serviceQuota: number) {
    	this.serviceQuota = serviceQuota;
    }

    public getRemainingServiceQuota(): number {
    	return this.remainingServiceQuota;
    }

    public setRemainingServiceQuota(remainingServiceQuota: number) {
    	this.remainingServiceQuota = remainingServiceQuota;
    }

    public getServiceDeliveryType(): ServiceDeliveryType {
    	return this.serviceDeliveryType;
    }

    public setServiceDeliveryType(serviceDeliveryType: ServiceDeliveryType) {
    	this.serviceDeliveryType = serviceDeliveryType;
    }

    public isOffered(): boolean {
    	return this.offered;
    }

    public setOffered(offered: boolean) {
    	this.offered = offered;
    }

    public getStartDay(): number {
    	return this.startDay;
    }

    public setStartDay(startDay: number) {
    	this.startDay = startDay;
    }

    public getEndDay(): number {
    	return this.endDay;
    }

    public setEndDay(endDay: number) {
    	this.endDay = endDay;
    }

    public getStatus(): TradeStatus {
    	return this.status;
    }

    public setStatus(status: TradeStatus) {
    	this.status = status;
    }

    public getCreateTime(): (number | string) {
    	return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
    	this.createTime = createTime;
    }

    public isRenewed(): boolean {
    	return this.renewed;
    }

    public setRenewed(renewed: boolean) {
    	this.renewed = renewed;
    }

    public isRemindingReview(): boolean {
    	return this.remindingReview;
    }

    public setRemindingReview(remindingReview: boolean) {
    	this.remindingReview = remindingReview;
    }

    public isEntitledToUse(): boolean {
    	return this.entitledToUse;
    }

    public setEntitledToUse(entitledToUse: boolean) {
    	this.entitledToUse = entitledToUse;
    }

    public getEntitledRole(): RoleType {
    	return this.entitledRole;
    }

    public setEntitledRole(entitledRole: RoleType) {
    	this.entitledRole = entitledRole;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let tradeId = json["tradeId"];
        if (tradeId != null) {
            this.setTradeId(tradeId);
        }
        let tradableId = json["tradableId"];
        if (tradableId != null) {
            this.setTradableId(tradableId);
        }
        let orderId = json["orderId"];
        if (orderId != null) {
            this.setOrderId(orderId);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let servicePackage = json["servicePackage"];
        if (servicePackage != null) {
            this.setServicePackage(servicePackage);
        }
        let global = json["global"];
        if (global != null) {
            this.setGlobal(global);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let serviceTag = json["serviceTag"];
        if (serviceTag != null) {
            let convertedServiceTag0 = EnterpriseServiceCategory[<string>serviceTag];
            this.setServiceTag(convertedServiceTag0);
        }
        let tradingEnterpriseInfo = json["tradingEnterpriseInfo"];
        if (tradingEnterpriseInfo != null) {
            let convertedTradingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(tradingEnterpriseInfo, "__type", factory);
            this.setTradingEnterpriseInfo(convertedTradingEnterpriseInfo0);
        }
        let tradingUserInfo = json["tradingUserInfo"];
        if (tradingUserInfo != null) {
            let convertedTradingUserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(tradingUserInfo, "__type", factory);
            this.setTradingUserInfo(convertedTradingUserInfo0);
        }
        let imageUrls = json["imageUrls"];
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            imageUrls = imageUrls[1];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            this.setImageUrls(convertedImageUrls0);
        }
        let keywordEntities = json["keywordEntities"];
        if (keywordEntities != null) {
            let convertedKeywordEntities0 = [];
            keywordEntities = keywordEntities[1];
            for (let i in keywordEntities) {
                let convertedKeywordEntities10 = keywordEntities[i];
                let convertedKeywordEntities1 = new KeywordEntity();
                convertedKeywordEntities1.fillFromJson(convertedKeywordEntities10, factory);
                convertedKeywordEntities0.push(convertedKeywordEntities1);
            }
            this.setKeywordEntities(convertedKeywordEntities0);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let activityId = json["activityId"];
        if (activityId != null) {
            this.setActivityId(activityId);
        }
        let price = json["price"];
        if (price != null) {
            this.setPrice(price);
        }
        let discount = json["discount"];
        if (discount != null) {
            this.setDiscount(discount);
        }
        let tradeUnits = json["tradeUnits"];
        if (tradeUnits != null) {
            this.setTradeUnits(tradeUnits);
        }
        let tradeAmount = json["tradeAmount"];
        if (tradeAmount != null) {
            this.setTradeAmount(tradeAmount);
        }
        let serviceQuota = json["serviceQuota"];
        if (serviceQuota != null) {
            this.setServiceQuota(serviceQuota);
        }
        let remainingServiceQuota = json["remainingServiceQuota"];
        if (remainingServiceQuota != null) {
            this.setRemainingServiceQuota(remainingServiceQuota);
        }
        let serviceDeliveryType = json["serviceDeliveryType"];
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[<string>serviceDeliveryType];
            this.setServiceDeliveryType(convertedServiceDeliveryType0);
        }
        let offered = json["offered"];
        if (offered != null) {
            this.setOffered(offered);
        }
        let startDay = json["startDay"];
        if (startDay != null) {
            this.setStartDay(startDay);
        }
        let endDay = json["endDay"];
        if (endDay != null) {
            this.setEndDay(endDay);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = TradeStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let renewed = json["renewed"];
        if (renewed != null) {
            this.setRenewed(renewed);
        }
        let remindingReview = json["remindingReview"];
        if (remindingReview != null) {
            this.setRemindingReview(remindingReview);
        }
        let entitledToUse = json["entitledToUse"];
        if (entitledToUse != null) {
            this.setEntitledToUse(entitledToUse);
        }
        let entitledRole = json["entitledRole"];
        if (entitledRole != null) {
            let convertedEntitledRole0 = RoleType[<string>entitledRole];
            this.setEntitledRole(convertedEntitledRole0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let tradeId = this.getTradeId();
        if (tradeId != null) {
            json["tradeId"] = String(tradeId);
        }
        let tradableId = this.getTradableId();
        if (tradableId != null) {
            json["tradableId"] = String(tradableId);
        }
        let orderId = this.getOrderId();
        if (orderId != null) {
            json["orderId"] = String(orderId);
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let servicePackage = this.isServicePackage();
        if (servicePackage != null) {
            json["servicePackage"] = servicePackage;
        }
        let global = this.isGlobal();
        if (global != null) {
            json["global"] = global;
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let serviceTag = this.getServiceTag();
        if (serviceTag != null) {
            let convertedServiceTag0 = EnterpriseServiceCategory[serviceTag];
            json["serviceTag"] = convertedServiceTag0;
        }
        let tradingEnterpriseInfo = this.getTradingEnterpriseInfo();
        if (tradingEnterpriseInfo != null) {
            let convertedTradingEnterpriseInfo0 = tradingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["tradingEnterpriseInfo"] = convertedTradingEnterpriseInfo0;
        }
        let tradingUserInfo = this.getTradingUserInfo();
        if (tradingUserInfo != null) {
            let convertedTradingUserInfo0 = tradingUserInfo.getJson(JsonTypeTagType.TYPE);
            json["tradingUserInfo"] = convertedTradingUserInfo0;
        }
        let imageUrls = this.getImageUrls();
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            let convertedImageUrlsWithType1 = ["java.util.ArrayList", convertedImageUrls0];
            json["imageUrls"] = convertedImageUrlsWithType1;
        }
        let keywordEntities = this.getKeywordEntities();
        if (keywordEntities != null) {
            let convertedKeywordEntities0 = [];
            for (let i in keywordEntities) {
                let convertedKeywordEntities10 = keywordEntities[i];
                let convertedKeywordEntities1 = convertedKeywordEntities10.getJson(JsonTypeTagType.NONE);
                convertedKeywordEntities0.push(convertedKeywordEntities1);
            }
            let convertedKeywordEntitiesWithType1 = ["java.util.ArrayList", convertedKeywordEntities0];
            json["keywordEntities"] = convertedKeywordEntitiesWithType1;
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let activityId = this.getActivityId();
        if (activityId != null) {
            json["activityId"] = String(activityId);
        }
        let price = this.getPrice();
        if (price != null) {
            json["price"] = price;
        }
        let discount = this.getDiscount();
        if (discount != null) {
            json["discount"] = discount;
        }
        let tradeUnits = this.getTradeUnits();
        if (tradeUnits != null) {
            json["tradeUnits"] = tradeUnits;
        }
        let tradeAmount = this.getTradeAmount();
        if (tradeAmount != null) {
            json["tradeAmount"] = tradeAmount;
        }
        let serviceQuota = this.getServiceQuota();
        if (serviceQuota != null) {
            json["serviceQuota"] = serviceQuota;
        }
        let remainingServiceQuota = this.getRemainingServiceQuota();
        if (remainingServiceQuota != null) {
            json["remainingServiceQuota"] = remainingServiceQuota;
        }
        let serviceDeliveryType = this.getServiceDeliveryType();
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[serviceDeliveryType];
            json["serviceDeliveryType"] = convertedServiceDeliveryType0;
        }
        let offered = this.isOffered();
        if (offered != null) {
            json["offered"] = offered;
        }
        let startDay = this.getStartDay();
        if (startDay != null) {
            json["startDay"] = startDay;
        }
        let endDay = this.getEndDay();
        if (endDay != null) {
            json["endDay"] = endDay;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = TradeStatus[status];
            json["status"] = convertedStatus0;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let renewed = this.isRenewed();
        if (renewed != null) {
            json["renewed"] = renewed;
        }
        let remindingReview = this.isRemindingReview();
        if (remindingReview != null) {
            json["remindingReview"] = remindingReview;
        }
        let entitledToUse = this.isEntitledToUse();
        if (entitledToUse != null) {
            json["entitledToUse"] = entitledToUse;
        }
        let entitledRole = this.getEntitledRole();
        if (entitledRole != null) {
            let convertedEntitledRole0 = RoleType[entitledRole];
            json["entitledRole"] = convertedEntitledRole0;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.trade.EnterpriseServiceTradeInfo";
    }
}
