/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {CoworkReplyInfo} from '../../cowork/CoworkReplyInfo'
import {CoworkSimpleView} from '../../cowork/CoworkSimpleView'
import {CoworkStats} from '../../cowork/CoworkStats'
import {CoworkType} from '../../cowork/CoworkType'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {Location} from '../../geolocation/Location'
import {QllCountrySpec} from '../../international/QllCountrySpec'
import {QllLanguageSpec} from '../../international/QllLanguageSpec'
import {SelectTimes} from '../../cowork/SelectTimes'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractAdContent extends AbstractSerializable implements CoworkSimpleView  {
    adSubjectEntityId: (number | string);
    adTitle: string;
    adImgUrls: Array<string>;
    keywords: Array<string>;
    adLink: string;
    description: string;
    adId: (number | string);
    totalPaidAmount: number;
    currentBalance: number;

    constructor() {
    	super();
    }

    public getAdSubjectEntityId(): (number | string) {
    	return this.adSubjectEntityId;
    }

    public setAdSubjectEntityId(adSubjectEntityId: (number | string)) {
    	this.adSubjectEntityId = adSubjectEntityId;
    }

    public getAdTitle(): string {
    	return this.adTitle;
    }

    public setAdTitle(adTitle: string) {
    	this.adTitle = adTitle;
    }

    public getAdImgUrls(): Array<string> {
    	return this.adImgUrls;
    }

    public setAdImgUrls(adImgUrls: Array<string>) {
    	this.adImgUrls = adImgUrls;
    }

    public getKeywords(): Array<string> {
    	return this.keywords;
    }

    public setKeywords(keywords: Array<string>) {
    	this.keywords = keywords;
    }

    public getAdLink(): string {
    	return this.adLink;
    }

    public setAdLink(adLink: string) {
    	this.adLink = adLink;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getAdId(): (number | string) {
    	return this.adId;
    }

    public setAdId(adId: (number | string)) {
    	this.adId = adId;
    }

    public getTotalPaidAmount(): number {
    	return this.totalPaidAmount;
    }

    public setTotalPaidAmount(totalPaidAmount: number) {
    	this.totalPaidAmount = totalPaidAmount;
    }

    public getCurrentBalance(): number {
    	return this.currentBalance;
    }

    public setCurrentBalance(currentBalance: number) {
    	this.currentBalance = currentBalance;
    }


    abstract isActive(): boolean;

    abstract isAnonymous(): boolean;

    abstract getLatestReply(): CoworkReplyInfo;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getLocation(): Location;

    abstract getCountrySpec(): QllCountrySpec;

    abstract getLanguageSpec(): QllLanguageSpec;

    abstract getApprovals(): number;

    abstract getCountryCode(): number;

    abstract getDisapprovals(): number;

    abstract getLanguageCode(): number;

    abstract getVisits(): number;

    abstract getPrize(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract getMainImageUrl(): string;

    abstract getRid(): (number | string);

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCountrySpec(arg0: (QllCountrySpec | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setLanguageSpec(arg0: (QllLanguageSpec | null));

    abstract setLatestReply(arg0: (CoworkReplyInfo | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let adSubjectEntityId = json["adSubjectEntityId"];
        if (adSubjectEntityId != null) {
            this.setAdSubjectEntityId(adSubjectEntityId);
        }
        let adTitle = json["adTitle"];
        if (adTitle != null) {
            this.setAdTitle(adTitle);
        }
        let adImgUrls = json["adImgUrls"];
        if (adImgUrls != null) {
            let convertedAdImgUrls0 = [];
            adImgUrls = adImgUrls[1];
            for (let i in adImgUrls) {
                let convertedAdImgUrls10 = adImgUrls[i];
                convertedAdImgUrls0.push(convertedAdImgUrls10);
            }
            this.setAdImgUrls(convertedAdImgUrls0);
        }
        let keywords = json["keywords"];
        if (keywords != null) {
            let convertedKeywords0 = [];
            keywords = keywords[1];
            for (let i in keywords) {
                let convertedKeywords10 = keywords[i];
                convertedKeywords0.push(convertedKeywords10);
            }
            this.setKeywords(convertedKeywords0);
        }
        let adLink = json["adLink"];
        if (adLink != null) {
            this.setAdLink(adLink);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let adId = json["adId"];
        if (adId != null) {
            this.setAdId(adId);
        }
        let totalPaidAmount = json["totalPaidAmount"];
        if (totalPaidAmount != null) {
            this.setTotalPaidAmount(totalPaidAmount);
        }
        let currentBalance = json["currentBalance"];
        if (currentBalance != null) {
            this.setCurrentBalance(currentBalance);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let adSubjectEntityId = this.getAdSubjectEntityId();
        if (adSubjectEntityId != null) {
            json["adSubjectEntityId"] = String(adSubjectEntityId);
        }
        let adTitle = this.getAdTitle();
        if (adTitle != null) {
            json["adTitle"] = adTitle;
        }
        let adImgUrls = this.getAdImgUrls();
        if (adImgUrls != null) {
            let convertedAdImgUrls0 = [];
            for (let i in adImgUrls) {
                let convertedAdImgUrls10 = adImgUrls[i];
                convertedAdImgUrls0.push(convertedAdImgUrls10);
            }
            let convertedAdImgUrlsWithType1 = ["java.util.ArrayList", convertedAdImgUrls0];
            json["adImgUrls"] = convertedAdImgUrlsWithType1;
        }
        let keywords = this.getKeywords();
        if (keywords != null) {
            let convertedKeywords0 = [];
            for (let i in keywords) {
                let convertedKeywords10 = keywords[i];
                convertedKeywords0.push(convertedKeywords10);
            }
            let convertedKeywordsWithType1 = ["java.util.ArrayList", convertedKeywords0];
            json["keywords"] = convertedKeywordsWithType1;
        }
        let adLink = this.getAdLink();
        if (adLink != null) {
            json["adLink"] = adLink;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let adId = this.getAdId();
        if (adId != null) {
            json["adId"] = String(adId);
        }
        let totalPaidAmount = this.getTotalPaidAmount();
        if (totalPaidAmount != null) {
            json["totalPaidAmount"] = totalPaidAmount;
        }
        let currentBalance = this.getCurrentBalance();
        if (currentBalance != null) {
            json["currentBalance"] = currentBalance;
        }
    
        if (includeTypeTag) {
            json["__type"] = "AdContent";
        }
    }


    public getTypeId(): string {
    	return "AdContent";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.ad.AdContent";
    }
}
