/**
 * 自动生成， 请不要手工修改！
 */

export enum NotificationType {
  PLATFORM_MSG,
  GRID_MSG,
  MONEY,
  REPLY,
  COMMENT,
  QUESTION_ANSWER,
  SUBSCRIBE,
  SERVICE_REQUEST,
}
