// @ts-nocheck
import {AbstractSimpleChannelEntitlements} from "./AbstractSimpleChannelEntitlements";
import {MessageType} from "../../../message/MessageContentType";
import {OperationType} from "../../security/OperationType";

export class SimpleChannelEntitlements extends AbstractSimpleChannelEntitlements {
  constructor() {
    super();
  }

  getContentTypes(): Array<MessageType> {
    // Manual Code Here
  }
  getEntitledOperations(arg0: MessageContentType | null): Array<OperationType> {
    // Manual Code Here
  }
}
