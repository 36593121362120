/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {EntityMessage} from '../../message/entity/api/EntityMessage'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {LocalChat} from '../geolocation/LocalChat'
import {PageableData} from '../paging/PageableData'
import {Serializer} from '../../serialize/Serializer'
import {SyncResponse} from '../platform/SyncResponse'
import {TypeFactory} from '../../serialize/TypeFactory'


export class MultiMessageResponse extends AbstractSerializable  {
    message: SyncResponse;
    chatList: Array<LocalChat>;
    initialMessages: PageableData<EntityMessage>;

    constructor() {
    	super();
    }

    public getMessage(): SyncResponse {
    	return this.message;
    }

    public setMessage(message: SyncResponse) {
    	this.message = message;
    }

    public getChatList(): Array<LocalChat> {
    	return this.chatList;
    }

    public setChatList(chatList: Array<LocalChat>) {
    	this.chatList = chatList;
    }

    public getInitialMessages(): PageableData<EntityMessage> {
    	return this.initialMessages;
    }

    public setInitialMessages(initialMessages: PageableData<EntityMessage>) {
    	this.initialMessages = initialMessages;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let message = json["message"];
        if (message != null) {
            let convertedMessage0 = new SyncResponse();
            convertedMessage0.fillFromJson(message, factory);
            this.setMessage(convertedMessage0);
        }
        let chatList = json["chatList"];
        if (chatList != null) {
            let convertedChatList0 = [];
            chatList = chatList[1];
            for (let i in chatList) {
                let convertedChatList10 = chatList[i];
                let convertedChatList1 = Serializer.fillFromJsonObjectWithTypeTag(convertedChatList10, "__type", factory);
                convertedChatList0.push(convertedChatList1);
            }
            this.setChatList(convertedChatList0);
        }
        let initialMessages = json["initialMessages"];
        if (initialMessages != null) {
            let convertedInitialMessages0 = Serializer.fillFromJsonObjectWithTypeTag(initialMessages, "__type", factory);
            this.setInitialMessages(convertedInitialMessages0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let message = this.getMessage();
        if (message != null) {
            let convertedMessage0 = message.getJson(JsonTypeTagType.NONE);
            json["message"] = convertedMessage0;
        }
        let chatList = this.getChatList();
        if (chatList != null) {
            let convertedChatList0 = [];
            for (let i in chatList) {
                let convertedChatList10 = chatList[i];
                let convertedChatList1 = convertedChatList10.getJson(JsonTypeTagType.TYPE);
                convertedChatList0.push(convertedChatList1);
            }
            let convertedChatListWithType1 = ["java.util.ArrayList", convertedChatList0];
            json["chatList"] = convertedChatListWithType1;
        }
        let initialMessages = this.getInitialMessages();
        if (initialMessages != null) {
            let convertedInitialMessages0 = initialMessages.getJson(JsonTypeTagType.TYPE);
            json["initialMessages"] = convertedInitialMessages0;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.message.MultiMessageResponse";
    }
}
