import { nanoid } from "nanoid";
// 发布工具
export default class IssueUtils {
  static getResult(result) {
    if (result) {
      return result.url
        .split("/")
        .splice(3, result.url.split("/").length)
        .join("/");
    }
  }
  static newFileName(token, files) {
    if (files) {
      const fullFileName: string =
        token.pkgName +
        "/" +
        nanoid(20) +
        "." +
        files.name.split(".")[files.name.split(".").length - 1];
      return fullFileName;
    }
  }
}
