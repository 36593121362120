/**
 * Created by Zhonghua on 14/11/2016.
 */

import { Map } from "../util/Map";
import { GeneratedClassFactory } from "./GeneratedClassFactory";
import { GeneratedTypeFactory } from "./GeneratedTypeFactory";
import { JsonSerializable } from "./JsonSerializable";
import { ManualClassFactory } from "./ManualClassFactory";
import { ManualTypeFactory } from "./ManualTypeFactory";
import { TypeFactory } from "./TypeFactory";
import { GeneratedEnumFactory } from "@/services/serialize/GeneratedEnumFactory";

const HASH_MAP = "java.util.HashMap";
const ARRAY_LIST = "java.util.ArrayList";

export class TypeMapper implements TypeFactory {
  private static _instance = new TypeMapper();

  private typeMap: Map<string, () => any>;
  private classMap: Map<string, () => any>;
  private manualTypeMap: Map<string, () => any>;
  private manualClassMap: Map<string, () => any>;
  private enumMap: Map<string, (string) => number | null | undefined>;

  public static getTypeFactory(): TypeFactory {
    return TypeMapper._instance;
  }

  public createInstanceByTypeId(id: string): JsonSerializable {
    //
    const typeMap = this.getOrCreateTypeMap();
    if (typeMap.has(id)) {
      const creator = typeMap.get(id);
      return creator();
    } else {
      const manualTypeMap = this.getOrCreateManualTypeMap();
      if (manualTypeMap.has(id)) {
        const manualCreator = manualTypeMap.get(id);
        return manualCreator();
      } else {
        return null;
      }
    }
  }

  public createInstanceByClassId(id: string): any {
    if (id === ARRAY_LIST) {
      return [];
    } else if (id === HASH_MAP) {
      return new Map<any, any>();
    }
    const classMap = this.getOrCreateClassMap();
    if (classMap.has(id)) {
      const creator = classMap.get(id);
      return creator();
    } else {
      const manualClassMap = this.getOrCreateManualClassMap();
      if (manualClassMap.has(id)) {
        const manualCreator = manualClassMap.get(id);
        return manualCreator();
      } else {
        return this.createInstanceByTypeId(id);
      }
    }
  }

  private getOrCreateTypeMap(): Map<string, () => any> {
    if (this.typeMap == null) {
      this.typeMap = GeneratedTypeFactory.createTypeMap();
    }
    return this.typeMap;
  }

  private getOrCreateClassMap(): Map<string, () => any> {
    if (this.classMap == null) {
      this.classMap = GeneratedClassFactory.createClassMap();
    }
    return this.classMap;
  }

  private getOrCreateManualTypeMap(): Map<string, () => any> {
    if (this.manualTypeMap == null) {
      this.manualTypeMap = ManualTypeFactory.createTypeMap();
    }
    return this.manualTypeMap;
  }

  private getOrCreateManualClassMap(): Map<string, () => any> {
    if (this.manualClassMap == null) {
      this.manualClassMap = ManualClassFactory.createClassMap();
    }
    return this.manualClassMap;
  }

  private getOrCreateEnumMap(): Map<
    string,
    (string) => number | null | undefined
  > {
    if (!this.enumMap) {
      this.enumMap = GeneratedEnumFactory.createEnumMap();
    }
    return this.enumMap;
  }

  public isEnum(className: string): boolean {
    const enumMap = this.getOrCreateEnumMap();
    return this.enumMap.has(className);
  }

  public getEnumValue(
    className: string,
    enumName: string
  ): number | null | undefined {
    const enumMap = this.getOrCreateEnumMap();
    if (this.enumMap.has(className)) {
      return this.enumMap.get(className)(enumName);
    } else {
      return null;
    }
  }
}
