/**
 * 自动生成， 请不要手工修改！
 */

export enum AcademicDegree {
  TECH_SCHOOL,
  JUNIOR_COLLEGE,
  BACHELOR,
  MASTER,
  DOCTOR,
}
