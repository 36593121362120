/**
 * 自动生成， 请不要手工修改！
 */


export enum ActivityStatus {
    IN_REVIEW,
    CONFIRMED,
    CANCELLED,
    HIDDEN,
    FINISHED,
    ACTIVE,
    DISLIKED
}
