/**
 * 自动生成， 请不要手工修改！
 */


export enum OrderStatus {
    ACCEPT_NEW,
    PENDING_TO_PAY,
    CANCELLED,
    COMPLETED,
    PENDING_REVIEW
}
