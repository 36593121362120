import { PageDataSpec } from "@/services/datatype/paging/PageDataSpec";
import { PageBlockAccessSpec } from "@/services/datatype/paging/PageBlockAccessSpec";

export default class PageUtils {
  public static merge(
    currentSpec: PageDataSpec | null,
    newSpec: PageDataSpec | null,
    atStart: boolean
  ): PageDataSpec | null {
    if (!currentSpec) {
      return newSpec;
    } else if (newSpec && atStart && newSpec.isRecordsRemaining()) {
      return newSpec;
    } else {
      return currentSpec.addPageDataSpec(newSpec, atStart);
    }
  }

  public static getNewPageBlockAccessSpec(
    pageSize: number,
    pageNum: number
  ): PageBlockAccessSpec {
    const newPageSpec: PageBlockAccessSpec = new PageBlockAccessSpec();
    newPageSpec.pageSize = pageSize;
    newPageSpec.startPosition = 0;
    newPageSpec.maxPagesPerBatch = pageNum;
    return newPageSpec;
  }

  /**
   * Temporally backward
   */
  public static getNextPageBlockAccessSpec(
    pageDataSpec: PageDataSpec | null | undefined,
    pageSize: number,
    pageNum: number
  ): PageBlockAccessSpec {
    let newPageSpec: PageBlockAccessSpec;
    if (pageDataSpec == null) {
      newPageSpec = new PageBlockAccessSpec();
      newPageSpec.pageSize = pageSize;
      newPageSpec.startPosition = 0;
      newPageSpec.maxPagesPerBatch = pageNum;
      return newPageSpec;
    } else {
      return pageDataSpec.getNextBackwardPageSpec(pageSize, pageNum);
    }
  }

  /**
   * Temporally forwardward
   */
  public static getPrevPageBlockAccessSpec(
    pageDataSpec: PageDataSpec | null | undefined,
    pageSize: number,
    pageNum: number
  ): PageBlockAccessSpec {
    let newPageSpec: PageBlockAccessSpec;
    if (pageDataSpec == null) {
      newPageSpec = new PageBlockAccessSpec();
      newPageSpec.pageSize = pageSize;
      newPageSpec.startPosition = 0;
      newPageSpec.maxPagesPerBatch = pageNum;
      return newPageSpec;
    } else {
      return pageDataSpec.getForwardPageSpec(pageSize, pageNum);
    }
  }
}
