// 此处为跳转订单详情面包屑传递
export default {
  namespaced: true, // 为每个模块添加一个前缀名，保证模块命明不冲突
  state: () => {
    return {
      bread: {
        orderType: "",
        orderState: "",
        orderId: "",
      },
      detailData: {},
    };
  },
  mutations: {
    updateBread(state, payload) {
      state.bread = payload;
    },
    updateOrderId(state, payload) {
      state.bread.orderId = payload;
    },
    updateDetailData(state, payload) {
      state.detailData = payload;
    },
  },
  actions: {},
};
