/**
 * Created by admin on 2017/3/23.
 */
const MAX_DATE = 8;
export enum FORMAT_TYPE {
  DEFAULT, // html的默认格式 yyyy-MM-ddThh:mm
  CHINA, // yyyy年MM月dd日
  FULL_CHINA, // yyyy年MM月dd日 hh:mm:ss
  NORMAL, // yyyy-MM-dd hh:mm:ss
  NORMAL2, // yyyy/MM/dd hh:mm:ss
  NORMAL3, //yyyy-MM-dd hh:mm
  NORMAL4, // yyyy/MM/dd hh:mm
}
export class DateFormat {
  public static dateFormatString(cTime: number, format?: FORMAT_TYPE): string {
    let result: string;
    const date = new Date(cTime);
    const y = date.getFullYear();
    const m = this.toZero(date.getMonth() + 1);
    const d = this.toZero(date.getDate());
    const h = this.toZero(date.getHours());
    const ms = this.toZero(date.getMinutes());
    const s = this.toZero(date.getSeconds());
    if (format == null) {
      format = FORMAT_TYPE.NORMAL;
    }
    switch (format) {
      case FORMAT_TYPE.DEFAULT:
        result = y + "-" + m + "-" + d + "T" + h + ":" + ms;
        break;
      case FORMAT_TYPE.CHINA:
        result = y + "年" + m + "月" + d + "日";
        break;
      case FORMAT_TYPE.FULL_CHINA:
        result = y + "年" + m + "月" + d + "日" + " " + h + ":" + ms + ":" + s;
        break;
      case FORMAT_TYPE.NORMAL:
        result = y + "-" + m + "-" + d + " " + h + ":" + ms + ":" + s;
        break;
      case FORMAT_TYPE.NORMAL2:
        result = y + "/" + m + "/" + d + " " + h + ":" + ms + ":" + s;
        break;
      case FORMAT_TYPE.NORMAL3:
        result = y + "-" + m + "-" + d + " " + h + ":" + ms;
        break;
      case FORMAT_TYPE.NORMAL4:
        result = y + "/" + m + "/" + d + " " + h + ":" + ms;
        break;
    }
    return result;
  }

  public static dateCompareString(date: Date, format?: FORMAT_TYPE): string {
    let result: string;
    const now = Date.now();
    const cha = (now - date.getTime()) / 1000;
    if (cha < 0) {
      result = "输入的时间不合法";
    } else if (cha < 60) {
      result = this.toInteger(cha) + " 秒前";
    } else if (cha < 3600) {
      result = this.toInteger(cha / 60) + " 分钟前";
    } else if (cha < 86400) {
      result = this.toInteger(cha / 3600) + " 小时前";
    } else if (cha < 86400 * MAX_DATE) {
      result = this.toInteger(cha / 86400) + " 天前";
    } else {
      result = this.dateFormatString(date.getTime(), format);
    }
    return result;
  }

  public static toZero(num: number): string {
    return num < 10 ? "0" + num : num + "";
  }

  private static toInteger(num: number): number {
    return parseInt(num + "");
  }
}
