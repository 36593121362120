/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { EntityMessage } from "../../message/entity/api/EntityMessage";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class SyncResponse extends AbstractSerializable {
  unreadCount: number;
  nextSyncPosition: number;
  actualStartPosition: number;
  messages: Array<EntityMessage>;

  constructor() {
    super();
  }

  public getUnreadCount(): number {
    return this.unreadCount;
  }

  public setUnreadCount(unreadCount: number) {
    this.unreadCount = unreadCount;
  }

  public getNextSyncPosition(): number {
    return this.nextSyncPosition;
  }

  public setNextSyncPosition(nextSyncPosition: number) {
    this.nextSyncPosition = nextSyncPosition;
  }

  public getActualStartPosition(): number {
    return this.actualStartPosition;
  }

  public setActualStartPosition(actualStartPosition: number) {
    this.actualStartPosition = actualStartPosition;
  }

  public getMessages(): Array<EntityMessage> {
    return this.messages;
  }

  public setMessages(messages: Array<EntityMessage>) {
    this.messages = messages;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const unreadCount = json["unreadCount"];
    if (unreadCount != null) {
      this.setUnreadCount(unreadCount);
    }
    const nextSyncPosition = json["nextSyncPosition"];
    if (nextSyncPosition != null) {
      this.setNextSyncPosition(nextSyncPosition);
    }
    const actualStartPosition = json["actualStartPosition"];
    if (actualStartPosition != null) {
      this.setActualStartPosition(actualStartPosition);
    }
    let messages = json["messages"];
    if (messages != null) {
      const convertedMessages0 = [];
      messages = messages[1];
      for (const i in messages) {
        const convertedMessages10 = messages[i];
        const convertedMessages1 = Serializer.fillFromJsonObjectWithTypeTag(
          convertedMessages10,
          "__type",
          factory
        );
        convertedMessages0.push(convertedMessages1);
      }
      this.setMessages(convertedMessages0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const unreadCount = this.getUnreadCount();
    if (unreadCount != null) {
      json["unreadCount"] = unreadCount;
    }
    const nextSyncPosition = this.getNextSyncPosition();
    if (nextSyncPosition != null) {
      json["nextSyncPosition"] = nextSyncPosition;
    }
    const actualStartPosition = this.getActualStartPosition();
    if (actualStartPosition != null) {
      json["actualStartPosition"] = actualStartPosition;
    }
    const messages = this.getMessages();
    if (messages != null) {
      const convertedMessages0 = [];
      for (const i in messages) {
        const convertedMessages10 = messages[i];
        const convertedMessages1 = convertedMessages10.getJson(
          JsonTypeTagType.TYPE
        );
        convertedMessages0.push(convertedMessages1);
      }
      const convertedMessagesWithType1 = [
        "java.util.ArrayList",
        convertedMessages0,
      ];
      json["messages"] = convertedMessagesWithType1;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.platform.SyncResponse";
  }
}
