/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {PageableData} from './PageableData'
import {PageBlockAccessSpec} from './PageBlockAccessSpec'
import {PageDataSpec} from './PageDataSpec'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractPageableData<T> extends AbstractSerializable implements PageableData<T> {
    pageData: Array<T>;
    pageDataSpec: PageDataSpec;

    constructor() {
        super();
    }

    public getPageData(): Array<T> {
        return this.pageData;
    }

    public setPageData(pageData: Array<T>) {
        this.pageData = pageData;
    }

    public getPageDataSpec(): PageDataSpec {
        return this.pageDataSpec;
    }

    public setPageDataSpec(pageDataSpec: PageDataSpec) {
        this.pageDataSpec = pageDataSpec;
    }


    abstract isRecordsRemaining(): boolean;

    abstract getForwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getForwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract getNextBackwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getNextBackwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract addPageDataSpec(arg0: (PageDataSpec | null), arg1: (boolean | null)): PageDataSpec;

    abstract getContinuousNoDataCount(): number;

    abstract getEstimatedTotalRecords(): number;

    abstract getFetchedSize(): number;

    abstract getNextStart(): number;

    abstract getStartPosition(): number;

    abstract setEstimatedTotalRecords(arg0: (number | null));

    abstract setRecordsRemaining(arg0: (boolean | null));

    abstract size(): number;

    abstract getPageDataPart(startPosition: (number | null), count: (number | null)): Array<T>;

    abstract getTypeId(): string;

    public fillFromJson(json: Object, factory?: TypeFactory) {
        let pageData = json["pageData"];
        if (pageData != null) {
            let convertedPageData0 = [];
            pageData = pageData[1];
            for (let i in pageData) {
                let convertedPageData10 = pageData[i];
                let convertedPageData1 = Serializer.fromJson(convertedPageData10, factory);
                convertedPageData0.push(convertedPageData1);
            }
            this.setPageData(convertedPageData0);
        }
        let pageDataSpec = json["pageDataSpec"];
        if (pageDataSpec != null) {
            let convertedPageDataSpec0 = Serializer.fillFromJsonObjectWithTypeTag(pageDataSpec, "__type", factory);
            this.setPageDataSpec(convertedPageDataSpec0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let pageData = this.getPageData();
        if (pageData != null) {
            let convertedPageData0 = [];
            for (let i in pageData) {
                let convertedPageData10 = pageData[i];
                let convertedPageData1 = Serializer.getJson(convertedPageData10, JsonTypeTagType.CLASS);
                convertedPageData0.push(convertedPageData1);
            }
            let convertedPageDataWithType1 = ["java.util.ArrayList", convertedPageData0];
            json["pageData"] = convertedPageDataWithType1;
        }
        let pageDataSpec = this.getPageDataSpec();
        if (pageDataSpec != null) {
            let convertedPageDataSpec0 = pageDataSpec.getJson(JsonTypeTagType.TYPE);
            json["pageDataSpec"] = convertedPageDataSpec0;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.paging.AbstractPageableData";
    }
}
