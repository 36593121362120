import { RequestStatusType } from "@/services/datatype/request/RequestStatusType";
import { Request } from "@/services/datatype/request/Request";
import { EnterpriseServiceRequest } from "@/services/datatype/business/service/request/EnterpriseServiceRequest";
import { CoworkType } from "@/services/datatype/cowork/CoworkType";

export class RequestStatusTypeUtils {
  /**
   * 请求待处理？
   */
  public static isPendingToProcess(request: Request): boolean {
    const status: RequestStatusType = request.getStatus();
    const coworkType = request.getTargetType();
    return RequestStatusTypeUtils.isStatusPendingToProcess(status, coworkType);
  }

  /**
   * 新状态：请求待处理？
   */
  public static isStatusPendingToProcess(
    newStatus: RequestStatusType,
    coworkType: CoworkType
  ): boolean {
    if (newStatus == null) {
      return true;
    }
    switch (newStatus) {
      case RequestStatusType.TO_ACCEPT:
      case RequestStatusType.MATCH_WORKER:
      case RequestStatusType.ACCEPTED_WORKER:
      case RequestStatusType.PEND_FOR_REVIEW:
      case RequestStatusType.PENDING_ORDER:
      case RequestStatusType.ACK_COMPLETE:
        return true;
      default:
        return false;
    }
  }

  /**
   * 请求处理中？
   */
  public static isInProcess(request: Request): boolean {
    const status: RequestStatusType = request.getStatus();
    const coworkType = request.getTargetType();
    return RequestStatusTypeUtils.isStatusInProcess(status, coworkType);
  }

  /**
   * 新状态：请求处理中？
   */
  public static isStatusInProcess(
    newStatus: RequestStatusType,
    coworkType: CoworkType
  ): boolean {
    if (newStatus == null) {
      return false;
    }
    switch (newStatus) {
      case RequestStatusType.IN_REVIEW:
        return true;
      case RequestStatusType.ACCEPTED:
        if (coworkType == CoworkType.ENT_SERVICE_REQUEST) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  /**
   * 请求已处理？
   */
  public static isProcessedOrCompleted(request: Request): boolean {
    const status: RequestStatusType = request.getStatus();
    const coworkType = request.getTargetType();
    return RequestStatusTypeUtils.isStatusProcessedOrCompleted(
      status,
      coworkType
    );
  }

  /**
   * 新状态：请求已处理？
   */
  public static isStatusProcessedOrCompleted(
    newStatus: RequestStatusType,
    coworkType: CoworkType
  ): boolean {
    if (newStatus == null) {
      return false;
    }
    switch (newStatus) {
      case RequestStatusType.REJECTED:
        return true;
      case RequestStatusType.ACCEPTED:
        if (coworkType == CoworkType.ENT_SERVICE_REQUEST) {
          return false;
        } else {
          return true;
        }
      case RequestStatusType.ACKED:
        return true;
      case RequestStatusType.CANCELLED:
      case RequestStatusType.PROCESS_FAILED:
        return true;
      case RequestStatusType.COMPLETED:
        return true;
      default:
        return false;
    }
  }
}
