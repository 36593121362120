/**
 * Created by Zhonghua on 16/11/2016.
 */
import { KeyedCollection } from "../util/KeyedCollection";
import { WebSocketConnection } from "./WebSocketConnection";
import { ConnectionConfig } from "./ConnectionConfig";
import WebSocketConnectionListener from "./WebSocketConnectionListener";
import { CompositeWebSocketConnectionListener } from "@/services/connection/CompositeWebSocketConnectionListener";
export type ConnectionCreateListener = (WebSocketConnection) => void;

const PING_INTERVAL_IN_MILLISECONDS = 20 * 1000;

export interface WebSocketConnectionManager {
  getDefaultConnection(): WebSocketConnection;

  getConnection(url: string): WebSocketConnection;

  getOrCreateConnection(
    url: string,
    connectionListener: WebSocketConnectionListener
  ): WebSocketConnection;

  getOrCreateDefaultConnection(
    connectionListener: WebSocketConnectionListener
  ): WebSocketConnection;

  closeAllConnections(): void;

  closeConnection(connection: WebSocketConnection): void;

  deregisterConnection(connection: WebSocketConnection): void;

  addConnectionCreateListener(listener: ConnectionCreateListener): void;
}

export class WebSocketConnectionImpl implements WebSocketConnection {
  private socket: WebSocket;

  private connectionListener: WebSocketConnectionListener;

  private connectionManager: WebSocketConnectionManager;

  private url: string;

  private timerId: any;

  constructor(
    url: string,
    connectionListener: WebSocketConnectionListener,
    connectionManager: WebSocketConnectionManager
  ) {
    this.connectionManager = connectionManager;
    this.connectionListener = connectionListener;
    this.url = url;

    const socket = new WebSocket(url);
    this.socket = socket;
    this.listen();
    this.keepAlive(this);
  }

  public reConnect(): void {
    this.socket = new WebSocket(this.url);
    this.listen();
    this.cancelKeepAlive();
    this.keepAlive(this);
  }

  private listen(): void {
    this.socket.onopen = () => {
      this.connectionListener.onOpen(this);
    };

    this.socket.onerror = (ev: Event) => {
      this.connectionListener.onError("websocket发生错误");
    };

    this.socket.onmessage = (ev: MessageEvent) => {
      this.connectionListener.onMessage(ev.data);
    };

    this.socket.onclose = () => {
      this.connectionListener.onClose();
    };
  }

  public sendMessage(text: string): void {
    return this.socket.send(text);
  }

  public doClose() {
    this.socket.close();
    this.cancelKeepAlive();
  }

  public close() {
    this.cancelKeepAlive();
    this.connectionManager.deregisterConnection(this);
  }

  public getState(): number {
    return this.socket.readyState;
  }

  public getId() {
    return this.socket.url;
  }

  private keepAlive(connection: WebSocketConnectionImpl): void {
    connection.timerId = setInterval(() => {
      if (connection.socket.readyState == connection.socket.OPEN) {
        connection.socket.send("");
      } else {
        connection.cancelKeepAlive();
      }
    }, PING_INTERVAL_IN_MILLISECONDS);
  }

  private cancelKeepAlive() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }
}

export class WebSocketConnectionManagerImpl
  implements WebSocketConnectionManager
{
  private defaultUrl: string;

  private connections = new KeyedCollection<WebSocketConnection>();
  private connectionCreateListeners: Array<ConnectionCreateListener> = [];

  constructor(url?: string | null) {
    if (url && !(typeof url === "undefined")) {
      this.defaultUrl = url;
    } else {
      this.defaultUrl = ConnectionConfig.DEFAULT_WS_URL;
    }
  }

  addConnectionCreateListener(listener: ConnectionCreateListener): void {
    this.connectionCreateListeners.push(listener);
  }

  notifyConnectionCreateListeners(connection: WebSocketConnection): void {
    if (this.connectionCreateListeners.length > 0) {
      for (const listener of this.connectionCreateListeners) {
        listener(connection);
      }
    }
  }

  public getDefaultConnection(): WebSocketConnection {
    return this.getConnection(this.defaultUrl) as WebSocketConnection;
  }

  public getConnection(url: string): WebSocketConnection {
    return this.connections.item(url);
  }

  public getOrCreateDefaultConnection(
    connectionListener: WebSocketConnectionListener
  ): WebSocketConnection {
    return this.getOrCreateConnection(this.defaultUrl, connectionListener);
  }

  public getOrCreateConnection(
    url: string,
    connectionListener: WebSocketConnectionListener
  ): WebSocketConnection {
    if (this.connections.containsKey(url)) {
      return this.connections.item(url);
    }
    return this.createConnection(url, connectionListener);
  }

  public closeAllConnections() {
    for (let index = 0; index < this.connections.values().length; index += 1) {
      this.connections.values()[index].doClose();
    }
    this.connections.clear();
  }

  public deregisterConnection(connection: WebSocketConnection) {
    if (connection) {
      const id = connection.getId();
      if (this.connections.containsKey(id)) {
        this.connections.remove(id);
      }
    }
  }

  public closeConnection(connection: WebSocketConnection) {
    if (connection) {
      connection.close();
    }
  }

  private createConnection(
    url: string,
    connectionListener: WebSocketConnectionListener
  ): WebSocketConnection {
    const connection: WebSocketConnection = new WebSocketConnectionImpl(
      url,
      new CompositeWebSocketConnectionListener(
        connectionListener,
        this.connectionCreateListeners
      ),
      this
    );
    this.connections.add(url, connection);
    return connection;
  }
}
