// @ts-nocheck
import { AbstractSimpleServiceResponseHandle } from "./AbstractSimpleServiceResponseHandle";
import { ServiceRequestStatusType } from "./ServiceRequestStatusType";

export class SimpleServiceResponseHandle<
  T
> extends AbstractSimpleServiceResponseHandle<T> {
  constructor() {
    super();
  }

  getStatusType(): ServiceRequestStatusType {
    if (this.result && !this.error) {
      return ServiceRequestStatusType.SUCCEEDED;
    } else if (this.error) {
      return ServiceRequestStatusType.FAILED;
    } else if (this.inWorkflow) {
      return ServiceRequestStatusType.IN_WORKFLOW;
    } else {
      return ServiceRequestStatusType.IN_WORKFLOW;
    }
  }
}
