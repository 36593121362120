import WebSocketConnectionListener from "@/services/connection/WebSocketConnectionListener";
import { ConnectionCreateListener } from "@/services/connection/WebSocketConnectionManager";
import { WebSocketConnection } from "@/services/connection/WebSocketConnection";

export class CompositeWebSocketConnectionListener
  implements WebSocketConnectionListener
{
  constructor(
    private listener: WebSocketConnectionListener,
    private createListeners: Array<ConnectionCreateListener>
  ) {}

  onClose(): void {
    if (this.listener) {
      this.listener.onClose();
    }
  }

  onError(error: any): void {
    if (this.listener) {
      this.listener.onError(error);
    }
  }

  onMessage(data: any): void {
    if (this.listener) {
      this.listener.onMessage(data);
    }
  }

  onOpen(connection: WebSocketConnection): void {
    if (this.listener) {
      this.listener.onOpen(connection);
    }
    if (this.createListeners && this.createListeners.length > 0) {
      for (const listener of this.createListeners) {
        listener(null);
      }
    }
  }
}
