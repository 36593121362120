/**
 * 自动生成， 请不要手工修改！
 */


export enum CoworkVisitType {
    BROWSE,
    SUBSCRIBE,
    UNSUBSCRIBE,
    APPROVAl,
    DISAPPROVAl,
    UNAPPROVAL,
    UNDISAPPROVAl,
    AWARD,
    COLLECT,
    UNCOLLECT,
    COMMENT,
    REPLY,
    ANSWER,
    SPONSOR,
    JOIN_ACTIVITY,
    QUIT_ACTIVITY,
    TRADE_ENT_SERVICE,
    ADD_ENTITY,
    ADD_REVIEW,
    ADD_QUESTION,
    SHARE_INFO,
    ADD_ACTIVITY,
    ADD_USER_AD,
    ANNOUNCE,
    ADD_PROD_REL,
    DEL_ENTITY,
    DEL_REVIEW,
    DEL_QUESTION,
    DEL_ANSWER,
    DEL_SHARED_INFO,
    DEL_ACTIVITY,
    DEL_USER_AD,
    DEL_ANNOUNCE,
    DEL_PROD_REL,
    DEL_REPLY,
    DEL_COMMENT,
    DEL_APPEAL,
    RES_TO_NEGATIVE,
    ACCEPT_RESPONSE,
    ADD_CONTENT,
    ADD_GRID_MEM,
    ADD_GRID_ENT,
    DEL_GRID_MEM,
    DEL_GRID_ENT,
    ADD_APPEAL,
    SOLVE_APPEAL,
    CALL,
    PRIVATE_MSG,
    PURCHASE
}
