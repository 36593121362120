import {RoleType} from "@/services/datatype/entity/RoleType";
import {EnterpriseType} from "@/services/datatype/entity/enterprise/EnterpriseType";

export default class RoleTypeUtils {
    static isLawyer(enterpriseType: EnterpriseType, roleType: RoleType) {
        if (enterpriseType == EnterpriseType.QLL_CUSTOMER_SERVICE) return true;
        if (enterpriseType != EnterpriseType.LAW_FIRM) {
            return false;
        }
        switch (roleType) {
            case RoleType.E_DIRECTOR:
            case RoleType.E_ASSOC_DIRECTOR:
            case RoleType.SENIOR_PARTNER:
            case RoleType.PARTNER:
            case RoleType.LAWYER:
            case RoleType.P_LAWYER:
            case RoleType.CEO:
                return true;
            default:
                return false;
        }
    }
}
