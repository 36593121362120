import {TopicType} from "@/services/datatype/entity/topic/TopicType";
import {SystemSuppliedEntityInfo} from "@/services/datatype/entity/SystemSuppliedEntityInfo";
import { UserSuppliedEntityInfo } from "@/services/datatype/entity/UserSuppliedEntityInfo";
import { UserSuppliedEntityDesc } from "@/services/datatype/entity/UserSuppliedEntityDesc";
import { SupplierDetails } from "@/services/datatype/entity/enterprise/SupplierDetails";
import { UserDetails } from "@/services/datatype/entity/user/UserDetails";
import { ExpertiseProfileAspect } from "@/services/datatype/ipsphere/expertise/ExpertiseProfileAspect";
import { ProfileAspect } from "@/services/datatype/entity/profile/ProfileAspect";
import { KeywordItem } from "@/services/datatype/ipsphere/expertise/KeywordItem";

export default class UserSuppliedEntityInfoUtils {
  static createUserSuppliedEntityInfo(
    systemSuppliedEntityInfo: SystemSuppliedEntityInfo
  ): UserSuppliedEntityInfo {
    if (!systemSuppliedEntityInfo) {
      return null;
    }
    const entityDesc = systemSuppliedEntityInfo.getEntityDesc();
    const userSuppliedEntityInfo: UserSuppliedEntityInfo =
      new UserSuppliedEntityInfo();
    const userSuppliedEntityDesc: UserSuppliedEntityDesc =
      new UserSuppliedEntityDesc();
    userSuppliedEntityInfo.setEntityDesc(userSuppliedEntityDesc);

    userSuppliedEntityDesc.setNid(entityDesc.getNid());
    userSuppliedEntityDesc.setLat(entityDesc.getLat());
    userSuppliedEntityDesc.setLng(entityDesc.getLng());
    userSuppliedEntityDesc.setLocationName(entityDesc.getLocationName());
    userSuppliedEntityDesc.setRegionId(entityDesc.getRegionId());
    userSuppliedEntityDesc.setImageUrl(entityDesc.getImageUrl());
    userSuppliedEntityDesc.setShowImageUrls(entityDesc.getShowImageUrls());
    userSuppliedEntityDesc.setName(entityDesc.getName());
    userSuppliedEntityDesc.setNickName(entityDesc.getNickName());
    userSuppliedEntityDesc.setDescription(entityDesc.getDescription());

    const additionalAttributes = entityDesc.getAdditionalAttributes();
    if (additionalAttributes instanceof SupplierDetails) {
      const supplierDetails: SupplierDetails =
        additionalAttributes as SupplierDetails;
      const newSupplierDetails = new SupplierDetails();
      newSupplierDetails.setEnterpriseType(supplierDetails.getEnterpriseType());
      newSupplierDetails.setAdImageUrl(supplierDetails.getAdImageUrl());
      newSupplierDetails.setTelPhone(supplierDetails.getTelPhone());
      newSupplierDetails.setBusiness(supplierDetails.isBusiness());
      newSupplierDetails.setOpenToVP(supplierDetails.isOpenToVP());
      userSuppliedEntityDesc.setAdditionalAttributes(newSupplierDetails);
    } else if (additionalAttributes instanceof UserDetails) {
      const userDetails = additionalAttributes as UserDetails;
      const newUserDetails = new UserDetails();
      newUserDetails.setMobile(userDetails.getMobile());
      newUserDetails.setServiceProvider(userDetails.isServiceProvider());
      newUserDetails.setWorkerInfo(userDetails.getWorkerInfo());
      userSuppliedEntityDesc.setAdditionalAttributes(newUserDetails);
    }

    const profileAspects = systemSuppliedEntityInfo.getProfileAspects();
    if (profileAspects && profileAspects.length > 0) {
      let expertiseProfileAspect: ExpertiseProfileAspect = null;
      for (const profileAspect of profileAspects) {
        if (profileAspect instanceof ExpertiseProfileAspect) {
          expertiseProfileAspect = profileAspect as ExpertiseProfileAspect;
          break;
        }
      }
      if (expertiseProfileAspect) {
        const newExpertiseProfileAspect = new ExpertiseProfileAspect();
        newExpertiseProfileAspect.setEntityId(
          expertiseProfileAspect.getEntityId()
        );
        newExpertiseProfileAspect.setExpertAreas(
          expertiseProfileAspect.getExpertAreas()
        );

        const newProfileAspects = new Array<ProfileAspect>();
        newProfileAspects.push(newExpertiseProfileAspect);

        userSuppliedEntityInfo.setProfileAspects(newProfileAspects);
      }
    }
    return userSuppliedEntityInfo;
  }

  static setKeywords(
    userSuppliedEntityInfo: UserSuppliedEntityInfo,
    keywords: Array<string>
  ): void {
    if (keywords && keywords.length > 0) {
      const expertAreas = new Array<KeywordItem>();
      for (const keyword of keywords) {
        if (keyword) {
          const keywordItem = new KeywordItem();
          keywordItem.setKeyword(keyword.toString());
          expertAreas.push(keywordItem);
        }
      }

      const currentProfileAspects = userSuppliedEntityInfo.getProfileAspects();
      if (!currentProfileAspects) {
        const currentProfileAspects = new Array<ProfileAspect>();
        userSuppliedEntityInfo.setProfileAspects(currentProfileAspects);
      }
      let expertiseProfileAspect: ExpertiseProfileAspect = null;
      if (currentProfileAspects.length > 0) {
        for (const profileAspect of currentProfileAspects) {
          if (profileAspect instanceof ExpertiseProfileAspect) {
            expertiseProfileAspect = profileAspect as ExpertiseProfileAspect;
            break;
          }
        }
      }
      if (expertiseProfileAspect) {
        expertiseProfileAspect.setExpertAreas(expertAreas);
      } else {
        const newExpertiseProfileAspect = new ExpertiseProfileAspect();
        newExpertiseProfileAspect.setEntityId(
          userSuppliedEntityInfo.getEntityDesc().getNid()
        );
        newExpertiseProfileAspect.setExpertAreas(expertAreas);
        userSuppliedEntityInfo
          .getProfileAspects()
          .push(newExpertiseProfileAspect);
      }
    }
  }
}
