/**
 * 自动生成， 请不要手工修改！
 */

import {RequestStatusType} from './RequestStatusType'


export class RequestStatusTypeName {
    public static getDisplayName(value: RequestStatusType): string {
       switch (value) {
          case RequestStatusType.TO_ACCEPT:
             return "待接受";
          case RequestStatusType.MATCH_WORKER:
             return "正在挑选专家";
          case RequestStatusType.ACCEPTED_WORKER:
             return "等待专家确认";
          case RequestStatusType.PEND_FOR_REVIEW:
             return "待审核";
          case RequestStatusType.IN_REVIEW:
             return "审核中";
          case RequestStatusType.REJECTED:
             return "已拒绝";
          case RequestStatusType.ACCEPTED:
             return "已接受";
          case RequestStatusType.PENDING_ORDER:
             return "洽购中";
          case RequestStatusType.ACKED:
             return "处理中";
          case RequestStatusType.ACTIVITY_JOINED:
             return "活动已报名";
          case RequestStatusType.CANCELLED:
             return "已取消";
          case RequestStatusType.PROCESS_FAILED:
             return "系统处理失败";
          case RequestStatusType.ACK_COMPLETE:
             return "已完成，待确认";
          case RequestStatusType.COMPLETED:
             return "已完成";
          case RequestStatusType.DELEGATED:
             return "已指派";
          case RequestStatusType.DELEGATE_REVOKED:
             return "已重新指派";
       }
       ;
       return null;
    }
}
