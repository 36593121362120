/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../serialize/AbstractJsonSerializable";
import { TypeFactory } from "../serialize/TypeFactory";
import { SessionContext } from "./SessionContext";

export class MockSessionContext
  extends AbstractSerializable
  implements SessionContext
{
  constructor() {
    super();
  }

  private sessionToken: string;
  private userId: number | string;
  public static createSessionContext(
    userId: number | string,
    sessionToken?: string
  ) {
    const context = new MockSessionContext();
    context.setUserId(userId);
    if (sessionToken) {
      context.setSessionToken(sessionToken);
    }
    return context;
  }

  public getSessionToken(): string {
    return this.sessionToken;
  }

  public setSessionToken(sessionToken: string) {
    this.sessionToken = sessionToken;
  }

  public getUserId(): number | string {
    return this.userId;
  }

  public setUserId(userId: number | string) {
    this.userId = userId;
  }

  public getEquipmentId(): string {
    return "";
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const sessionToken = json["sessionToken"];
    if (sessionToken != null) {
      this.setSessionToken(sessionToken);
    }
    const userId = json["userId"];
    if (userId != null) {
      this.setUserId(userId);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const sessionToken = this.getSessionToken();
    if (sessionToken != null) {
      json["sessionToken"] = sessionToken;
    }
    const userId = this.getUserId();
    if (userId != null) {
      json["userId"] = userId;
    }

    if (includeTypeTag) {
      json["__type"] = "MockSessionContext";
    }
  }

  public getTypeId(): string {
    return "MockSessionContext";
  }

  public getClassName(): string {
    return "com.gong_wei.common.session.MockSessionContext";
  }
}
