/**
 * 自动生成， 请不要手工修改！
 */


export enum CouponType {
    DISCOUNT,
    FULL_DISCOUNT,
    REBATE,
    VOUCHER
}
