/**
 * 自动生成， 请不要手工修改！
 */

import {CoworkInfo} from '../../../../cowork/CoworkInfo'
import {CoworkSimpleView} from '../../../../cowork/CoworkSimpleView'
import {CoworkStats} from '../../../../cowork/CoworkStats'
import {CoworkType} from '../../../../cowork/CoworkType'
import {EnterpriseServiceRequestInfo} from '../EnterpriseServiceRequestInfo'
import {EntityInfo} from '../../../../entity/EntityInfo'
import {FinancingType} from '../../finance/FinancingType'
import {GeoHash} from '../../../../geolocation/GeoHash'
import {JsonTypeTagType} from '../../../../../serialize/JsonTypeTagType'
import {SelectTimes} from '../../../../cowork/SelectTimes'
import {TypeFactory} from '../../../../../serialize/TypeFactory'


export abstract class AbstractFinancingServiceRequestInfo extends EnterpriseServiceRequestInfo  {
    financingAmount: number;
    financingType: FinancingType;

    constructor() {
    	super();
    }

    public getFinancingAmount(): number {
    	return this.financingAmount;
    }

    public setFinancingAmount(financingAmount: number) {
    	this.financingAmount = financingAmount;
    }

    public getFinancingType(): FinancingType {
    	return this.financingType;
    }

    public setFinancingType(financingType: FinancingType) {
    	this.financingType = financingType;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getKeywords(): Array<string>;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setKeywords(arg0: (Array<string> | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract getDescription(): string;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setDescription(arg0: (string | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let financingAmount = json["financingAmount"];
        if (financingAmount != null) {
            this.setFinancingAmount(financingAmount);
        }
        let financingType = json["financingType"];
        if (financingType != null) {
            let convertedFinancingType0 = FinancingType[<string>financingType];
            this.setFinancingType(convertedFinancingType0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let financingAmount = this.getFinancingAmount();
        if (financingAmount != null) {
            json["financingAmount"] = financingAmount;
        }
        let financingType = this.getFinancingType();
        if (financingType != null) {
            let convertedFinancingType0 = FinancingType[financingType];
            json["financingType"] = convertedFinancingType0;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.request.banking.FinancingServiceRequestInfo";
    }
}
