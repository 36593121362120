/**
 * 自动生成， 请不要手工修改！
 */

import {CustomRequest} from '../CustomRequest'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {MessageType} from '../../../message/MessageContentType'
import {OfficialEndorsementProfileAspect} from '../../entity/profile/OfficialEndorsementProfileAspect'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractOfficialEndorsementReviewRequest extends CustomRequest<OfficialEndorsementProfileAspect> {
    freeMembershipEnabled: boolean;

    constructor() {
        super();
    }

    public isFreeMembershipEnabled(): boolean {
        return this.freeMembershipEnabled;
    }

    public setFreeMembershipEnabled(freeMembershipEnabled: boolean) {
        this.freeMembershipEnabled = freeMembershipEnabled;
    }


    abstract getMessageType(): MessageType


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let freeMembershipEnabled = json["freeMembershipEnabled"];
        if (freeMembershipEnabled != null) {
            this.setFreeMembershipEnabled(freeMembershipEnabled);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let freeMembershipEnabled = this.isFreeMembershipEnabled();
        if (freeMembershipEnabled != null) {
            json["freeMembershipEnabled"] = freeMembershipEnabled;
        }

        if (includeTypeTag) {
            json["__type"] = "OfficialEndorsementReviewRequest";
        }
    }


    public getTypeId(): string {
        return "OfficialEndorsementReviewRequest";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.request.entity.OfficialEndorsementReviewRequest";
    }
}
