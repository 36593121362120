/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkIdentifier } from "../cowork/CoworkIdentifier";
import { EntityInfo } from "../entity/EntityInfo";
import { HelpType } from "./HelpType";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class HelpOffer extends AbstractSerializable {
  coworkId: CoworkIdentifier;
  replyId: number | string;
  helpType: HelpType;
  helperInfo: EntityInfo;
  amount: number;
  description: string;
  offerTime: number | string;

  constructor() {
    super();
  }

  public getCoworkId(): CoworkIdentifier {
    return this.coworkId;
  }

  public setCoworkId(coworkId: CoworkIdentifier) {
    this.coworkId = coworkId;
  }

  public getReplyId(): number | string {
    return this.replyId;
  }

  public setReplyId(replyId: number | string) {
    this.replyId = replyId;
  }

  public getHelpType(): HelpType {
    return this.helpType;
  }

  public setHelpType(helpType: HelpType) {
    this.helpType = helpType;
  }

  public getHelperInfo(): EntityInfo {
    return this.helperInfo;
  }

  public setHelperInfo(helperInfo: EntityInfo) {
    this.helperInfo = helperInfo;
  }

  public getAmount(): number {
    return this.amount;
  }

  public setAmount(amount: number) {
    this.amount = amount;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getOfferTime(): number | string {
    return this.offerTime;
  }

  public setOfferTime(offerTime: number | string) {
    this.offerTime = offerTime;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const coworkId = json["coworkId"];
    if (coworkId != null) {
      const convertedCoworkId0 = new CoworkIdentifier();
      convertedCoworkId0.fillFromJson(coworkId, factory);
      this.setCoworkId(convertedCoworkId0);
    }
    const replyId = json["replyId"];
    if (replyId != null) {
      this.setReplyId(replyId);
    }
    const helpType = json["helpType"];
    if (helpType != null) {
      const convertedHelpType0 = HelpType[<string>helpType];
      this.setHelpType(convertedHelpType0);
    }
    const helperInfo = json["helperInfo"];
    if (helperInfo != null) {
      const convertedHelperInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        helperInfo,
        "__type",
        factory
      );
      this.setHelperInfo(convertedHelperInfo0);
    }
    const amount = json["amount"];
    if (amount != null) {
      this.setAmount(amount);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    const offerTime = json["offerTime"];
    if (offerTime != null) {
      this.setOfferTime(offerTime);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const coworkId = this.getCoworkId();
    if (coworkId != null) {
      const convertedCoworkId0 = coworkId.getJson(JsonTypeTagType.NONE);
      json["coworkId"] = convertedCoworkId0;
    }
    const replyId = this.getReplyId();
    if (replyId != null) {
      json["replyId"] = String(replyId);
    }
    const helpType = this.getHelpType();
    if (helpType != null) {
      const convertedHelpType0 = HelpType[helpType];
      json["helpType"] = convertedHelpType0;
    }
    const helperInfo = this.getHelperInfo();
    if (helperInfo != null) {
      const convertedHelperInfo0 = helperInfo.getJson(JsonTypeTagType.TYPE);
      json["helperInfo"] = convertedHelperInfo0;
    }
    const amount = this.getAmount();
    if (amount != null) {
      json["amount"] = amount;
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const offerTime = this.getOfferTime();
    if (offerTime != null) {
      json["offerTime"] = String(offerTime);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.reply.HelpOffer";
  }
}
