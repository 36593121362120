/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class ServiceTradableDesc extends AbstractSerializable {
    pkgSeriesId: (number | string);
    servicePkgId: (number | string);
    serviceId: (number | string);
    serviceName: string;

    constructor() {
        super();
    }

    public getPkgSeriesId(): (number | string) {
        return this.pkgSeriesId;
    }

    public setPkgSeriesId(pkgSeriesId: (number | string)) {
        this.pkgSeriesId = pkgSeriesId;
    }

    public getServicePkgId(): (number | string) {
        return this.servicePkgId;
    }

    public setServicePkgId(servicePkgId: (number | string)) {
        this.servicePkgId = servicePkgId;
    }

    public getServiceId(): (number | string) {
        return this.serviceId;
    }

    public setServiceId(serviceId: (number | string)) {
        this.serviceId = serviceId;
    }

    public getServiceName(): string {
        return this.serviceName;
    }

    public setServiceName(serviceName: string) {
        this.serviceName = serviceName;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let pkgSeriesId = json["pkgSeriesId"];
        if (pkgSeriesId != null) {
            this.setPkgSeriesId(pkgSeriesId);
        }
        let servicePkgId = json["servicePkgId"];
        if (servicePkgId != null) {
            this.setServicePkgId(servicePkgId);
        }
        let serviceId = json["serviceId"];
        if (serviceId != null) {
            this.setServiceId(serviceId);
        }
        let serviceName = json["serviceName"];
        if (serviceName != null) {
            this.setServiceName(serviceName);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let pkgSeriesId = this.getPkgSeriesId();
        if (pkgSeriesId != null) {
            json["pkgSeriesId"] = String(pkgSeriesId);
        }
        let servicePkgId = this.getServicePkgId();
        if (servicePkgId != null) {
            json["servicePkgId"] = String(servicePkgId);
        }
        let serviceId = this.getServiceId();
        if (serviceId != null) {
            json["serviceId"] = String(serviceId);
        }
        let serviceName = this.getServiceName();
        if (serviceName != null) {
            json["serviceName"] = serviceName;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.service.tradable.ServiceTradableDesc";
    }
}
