/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class PagingSpec extends AbstractSerializable {
  pageSize: number;
  maxPagesPerBatch: number;

  constructor() {
    super();
  }

  public getPageSize(): number {
    return this.pageSize;
  }

  public setPageSize(pageSize: number) {
    this.pageSize = pageSize;
  }

  public getMaxPagesPerBatch(): number {
    return this.maxPagesPerBatch;
  }

  public setMaxPagesPerBatch(maxPagesPerBatch: number) {
    this.maxPagesPerBatch = maxPagesPerBatch;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const pageSize = json["pageSize"];
    if (pageSize != null) {
      this.setPageSize(pageSize);
    }
    const maxPagesPerBatch = json["maxPagesPerBatch"];
    if (maxPagesPerBatch != null) {
      this.setMaxPagesPerBatch(maxPagesPerBatch);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const pageSize = this.getPageSize();
    if (pageSize != null) {
      json["pageSize"] = pageSize;
    }
    const maxPagesPerBatch = this.getMaxPagesPerBatch();
    if (maxPagesPerBatch != null) {
      json["maxPagesPerBatch"] = maxPagesPerBatch;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.paging.PagingSpec";
  }
}
