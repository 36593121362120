/**
 * 自动生成， 请不要手工修改！
 */


export enum CouponUseType {
    ALL,
    SERVICE,
    SERVICE_TYPE,
    SERVICE_CATEGORY
}
