import { OSSTokenResponse } from "@/services/response/OSSTokenResponse";
import { OSSFederationToken } from "@/services/util/oss/OSSFederationToken";
import { DefaultWebServiceFactory } from "@/services/webapi/DefaultWebServiceFactory";

export type OssTokenConsumer = (OSSFederationToken, any) => void;

export class OSSCredentialProvider {
  private static _instance: OSSCredentialProvider = new OSSCredentialProvider();

  private fetchInProgress: boolean;
  private cachedOssToken: OSSFederationToken;
  private ossTokenConsumers: Array<OssTokenConsumer> = [];

  private static isTokenValid(token: OSSFederationToken): boolean {
    if (!token) {
      return false;
    }
    return token.isValid();
  }

  constructor() {
    if (OSSCredentialProvider._instance) {
      throw new Error(
        "Error: Instantiation failed: Use OSSCredentialProvider.getInstance() instead of new."
      );
    }
    OSSCredentialProvider._instance = this;
  }

  public static getInstance(): OSSCredentialProvider {
    return OSSCredentialProvider._instance;
  }

  private isCurrentTokenValid(): boolean {
    const currentToken = this.cachedOssToken;
    return OSSCredentialProvider.isTokenValid(currentToken);
  }

  private fetchFederationTokenAsync(tokenConsumer: OssTokenConsumer): void {
    if (this.fetchInProgress) {
      this.ossTokenConsumers.push(tokenConsumer);
    } else {
      this.fetchInProgress = true;

      const service =
        DefaultWebServiceFactory.getInstance().getWebSystemService();
      const ossTokenPromise: Promise<OSSTokenResponse> =
        service.requestOSSToken();
      ossTokenPromise.then((ossTokenResponse) => {
        if (ossTokenResponse) {
          const expiration =
            OSSFederationToken.getExpirationSecondsFromGMTFormat(
              ossTokenResponse.expiration
            );
          const newToken = new OSSFederationToken(
            ossTokenResponse.getAccessKeyId(),
            ossTokenResponse.getAccessKeySecret(),
            ossTokenResponse.getSecurityToken(),
            expiration,
            ossTokenResponse.packageName
          );
          this.cachedOssToken = newToken;
          tokenConsumer(newToken, null);
          let queuedConsumer: OssTokenConsumer;
          while (this.ossTokenConsumers.length > 0) {
            queuedConsumer = this.ossTokenConsumers.pop();
            if (queuedConsumer) {
              queuedConsumer(newToken, null);
            }
          }
        } else {
          tokenConsumer(null, null);
          let queuedConsumer: OssTokenConsumer;
          while (this.ossTokenConsumers.length > 0) {
            queuedConsumer = this.ossTokenConsumers.pop();
            if (queuedConsumer) {
              queuedConsumer(null, null);
            }
          }
        }
        this.fetchInProgress = false;
      });

      ossTokenPromise.catch((error) => {
        tokenConsumer(null, error);
        let queuedConsumer: OssTokenConsumer;
        while (this.ossTokenConsumers.length > 0) {
          queuedConsumer = this.ossTokenConsumers.pop();
          if (queuedConsumer) {
            queuedConsumer(null, error);
          }
        }
        this.fetchInProgress = false;
      });
    }
  }

  /**
   * Gets the valid STS token. The subclass needs to implement this function.
   *
   * @return The valid STS Token
   */
  public getFederationToken(tokenConsumer: OssTokenConsumer): void {
    const currentToken = this.cachedOssToken;
    if (OSSCredentialProvider.isTokenValid(currentToken)) {
      tokenConsumer(currentToken, null);
    } else {
      this.fetchFederationTokenAsync(tokenConsumer);
    }
  }
}
