/**
 * 自动生成， 请不要手工修改！
 */

export enum RequestSuperType {
  REPORT_OFFENCE,
  SERVICE_REQUEST_TO_PLATFORM,
  SERVICE_REQUEST_TO_ENTITY,
  ENTERPRISE_SERVICE_REQUEST,
}
