/**
 * 自动生成， 请不要手工修改！
 */


export enum HighlightType {
    GRID,
    DISCOVERY,
    ELITE,
    BUS_MEMBER,
    COOP_CASES,
    BUSINESS_TRAINING,
    ENTERPRISE,
    ASSOCIATION,
    QLL
}
