/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractEnterpriseServiceTrade} from './AbstractEnterpriseServiceTrade'
import {CompositeActivityInfo} from '../../../activity/CompositeActivityInfo'
import {InfoSectionDesc} from '../../../entity/topic/InfoSectionDesc'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {Serializer} from '../../../../serialize/Serializer'
import {ServicePriceDiscountType} from '../../price/ServicePriceDiscountType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class EnterpriseServiceTrade extends AbstractEnterpriseServiceTrade  {
    packageTradeId: (number | string);
    declare priceDiscountType: ServicePriceDiscountType;
    serviceQuota: number;
    remainingServiceQuota: number;
    remainingPackageAmount: number;
    customerServiceSpec: InfoSectionDesc;
    activityId: (number | string);
    activityInfo: CompositeActivityInfo;
    packageOnly: boolean;
    wechatId: string;
    wechatUrl: string;

    constructor() {
    	super();
    }

    public getPackageTradeId(): (number | string) {
    	return this.packageTradeId;
    }

    public setPackageTradeId(packageTradeId: (number | string)) {
    	this.packageTradeId = packageTradeId;
    }

    public getPriceDiscountType(): ServicePriceDiscountType {
    	return this.priceDiscountType;
    }

    public setPriceDiscountType(priceDiscountType: ServicePriceDiscountType) {
    	this.priceDiscountType = priceDiscountType;
    }

    public getServiceQuota(): number {
    	return this.serviceQuota;
    }

    public setServiceQuota(serviceQuota: number) {
    	this.serviceQuota = serviceQuota;
    }

    public getRemainingServiceQuota(): number {
    	return this.remainingServiceQuota;
    }

    public setRemainingServiceQuota(remainingServiceQuota: number) {
    	this.remainingServiceQuota = remainingServiceQuota;
    }

    public getRemainingPackageAmount(): number {
    	return this.remainingPackageAmount;
    }

    public setRemainingPackageAmount(remainingPackageAmount: number) {
    	this.remainingPackageAmount = remainingPackageAmount;
    }

    public getCustomerServiceSpec(): InfoSectionDesc {
    	return this.customerServiceSpec;
    }

    public setCustomerServiceSpec(customerServiceSpec: InfoSectionDesc) {
    	this.customerServiceSpec = customerServiceSpec;
    }

    public getActivityId(): (number | string) {
    	return this.activityId;
    }

    public setActivityId(activityId: (number | string)) {
    	this.activityId = activityId;
    }

    public getActivityInfo(): CompositeActivityInfo {
    	return this.activityInfo;
    }

    public setActivityInfo(activityInfo: CompositeActivityInfo) {
    	this.activityInfo = activityInfo;
    }

    public isPackageOnly(): boolean {
    	return this.packageOnly;
    }

    public setPackageOnly(packageOnly: boolean) {
    	this.packageOnly = packageOnly;
    }



    public getWechatId(): string {
    	return this.wechatId;
    }

    public setWechatId(wechatId: string) {
    	this.wechatId = wechatId;
    }

    public getWechatUrl(): string {
    	return this.wechatUrl;
    }

    public setWechatUrl(wechatUrl: string) {
    	this.wechatUrl = wechatUrl;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let packageTradeId = json["packageTradeId"];
        if (packageTradeId != null) {
            this.setPackageTradeId(packageTradeId);
        }
        let priceDiscountType = json["priceDiscountType"];
        if (priceDiscountType != null) {
            let convertedPriceDiscountType0 = ServicePriceDiscountType[<string>priceDiscountType];
            this.setPriceDiscountType(convertedPriceDiscountType0);
        }
        let serviceQuota = json["serviceQuota"];
        if (serviceQuota != null) {
            this.setServiceQuota(serviceQuota);
        }
        let remainingServiceQuota = json["remainingServiceQuota"];
        if (remainingServiceQuota != null) {
            this.setRemainingServiceQuota(remainingServiceQuota);
        }
        let remainingPackageAmount = json["remainingPackageAmount"];
        if (remainingPackageAmount != null) {
            this.setRemainingPackageAmount(remainingPackageAmount);
        }
        let customerServiceSpec = json["customerServiceSpec"];
        if (customerServiceSpec != null) {
            let convertedCustomerServiceSpec0 = new InfoSectionDesc();
            convertedCustomerServiceSpec0.fillFromJson(customerServiceSpec, factory);
            this.setCustomerServiceSpec(convertedCustomerServiceSpec0);
        }
        let activityId = json["activityId"];
        if (activityId != null) {
            this.setActivityId(activityId);
        }
        let activityInfo = json["activityInfo"];
        if (activityInfo != null) {
            let convertedActivityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(activityInfo, "__type", factory);
            this.setActivityInfo(convertedActivityInfo0);
        }
        let packageOnly = json["packageOnly"];
        if (packageOnly != null) {
            this.setPackageOnly(packageOnly);
        }
        let wechatId = json["wechatId"];
        if (wechatId != null) {
            this.setWechatId(wechatId);
        }
        let wechatUrl = json["wechatUrl"];
        if (wechatUrl != null) {
            this.setWechatUrl(wechatUrl);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let packageTradeId = this.getPackageTradeId();
        if (packageTradeId != null) {
            json["packageTradeId"] = String(packageTradeId);
        }
        let priceDiscountType = this.getPriceDiscountType();
        if (priceDiscountType != null) {
            let convertedPriceDiscountType0 = ServicePriceDiscountType[priceDiscountType];
            json["priceDiscountType"] = convertedPriceDiscountType0;
        }
        let serviceQuota = this.getServiceQuota();
        if (serviceQuota != null) {
            json["serviceQuota"] = serviceQuota;
        }
        let remainingServiceQuota = this.getRemainingServiceQuota();
        if (remainingServiceQuota != null) {
            json["remainingServiceQuota"] = remainingServiceQuota;
        }
        let remainingPackageAmount = this.getRemainingPackageAmount();
        if (remainingPackageAmount != null) {
            json["remainingPackageAmount"] = remainingPackageAmount;
        }
        let customerServiceSpec = this.getCustomerServiceSpec();
        if (customerServiceSpec != null) {
            let convertedCustomerServiceSpec0 = customerServiceSpec.getJson(JsonTypeTagType.NONE);
            json["customerServiceSpec"] = convertedCustomerServiceSpec0;
        }
        let activityId = this.getActivityId();
        if (activityId != null) {
            json["activityId"] = String(activityId);
        }
        let activityInfo = this.getActivityInfo();
        if (activityInfo != null) {
            let convertedActivityInfo0 = activityInfo.getJson(JsonTypeTagType.TYPE);
            json["activityInfo"] = convertedActivityInfo0;
        }
        let packageOnly = this.isPackageOnly();
        if (packageOnly != null) {
            json["packageOnly"] = packageOnly;
        }
        let wechatId = this.getWechatId();
        if (wechatId != null) {
            json["wechatId"] = wechatId;
        }
        let wechatUrl = this.getWechatUrl();
        if (wechatUrl != null) {
            json["wechatUrl"] = wechatUrl;
        }

        if (includeTypeTag) {
            json["__type"] = "EnterpriseServiceTrade";
        }
    }


    public getTypeId(): string {
    	return "EnterpriseServiceTrade";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.trade.EnterpriseServiceTrade";
    }
}
