/**
 * 自动生成， 请不要手工修改！
 */

import {EnterpriseServiceType} from './EnterpriseServiceType'


export class EnterpriseServiceTypeName {
    public static getDisplayName(value: EnterpriseServiceType): string {
        switch (value) {
           case EnterpriseServiceType.BANKING:
              return "金融";
           case EnterpriseServiceType.LEGAL:
              return "法律";
           case EnterpriseServiceType.COMMERCE:
              return "商务";
           case EnterpriseServiceType.LABOUR:
              return "劳务派遣";
           case EnterpriseServiceType.SOFTWARE:
              return "软件";
           case EnterpriseServiceType.ACCOUNTANT:
              return "会计";
           case EnterpriseServiceType.TAX:
              return "财税";
           case EnterpriseServiceType.IP:
              return "知识产权";
           case EnterpriseServiceType.CONSULT:
              return "管理咨询";
           case EnterpriseServiceType.TRAINING:
              return "企业培训";
           case EnterpriseServiceType.MANAGEMENT:
              return "企业管理";
           case EnterpriseServiceType.CB_EC:
              return "跨境电商";
           case EnterpriseServiceType.MARKETING:
              return "市场推广";
           case EnterpriseServiceType.LICENSE:
              return "证照";
           case EnterpriseServiceType.INSURANCE:
              return "保险";
           case EnterpriseServiceType.REF_CHECK:
              return "背调";
           case EnterpriseServiceType.CAPITAL:
              return "资本市场";
           case EnterpriseServiceType.FP:
              return "消防";
           case EnterpriseServiceType.CATERING:
              return "餐饮";
           case EnterpriseServiceType.HOTEL:
              return "酒店";
           case EnterpriseServiceType.TEA:
              return "茶叶";
           case EnterpriseServiceType.LEGAL_CONSULT:
              return "法律咨询";
           case EnterpriseServiceType.LEGAL_CONTRACT:
              return "合同起草/审核";
           case EnterpriseServiceType.LEGAL_LETTER:
              return "律师函";
           case EnterpriseServiceType.LEGAL_LAWSUIT:
              return "法律诉讼";
           case EnterpriseServiceType.EQUITY:
              return "融资对接";
           case EnterpriseServiceType.LOAN_SERVICE:
              return "贷款";
           case EnterpriseServiceType.EQUITY_LOAN_SERVICE:
              return "投贷联动";
           case EnterpriseServiceType.BOND_SERVICE:
              return "债券";
           case EnterpriseServiceType.FIN_ENT_COOP_SERVICE:
              return "企业间融资合作";
           case EnterpriseServiceType.FIN_NET_COOP_SERVICE:
              return "个人融资合作";
           case EnterpriseServiceType.CONSULT_FT:
              return "财税咨询";
           case EnterpriseServiceType.CONSULT_CAPITAL:
              return "资本市场咨询";
           case EnterpriseServiceType.CONSULT_MANAGEMENT:
              return "企业管理咨询";
           case EnterpriseServiceType.CONSULT_CB_EC:
              return "跨境电商咨询";
           case EnterpriseServiceType.CONSULT_MARKETING:
              return "市场推广咨询";
           case EnterpriseServiceType.CONSULT_FP:
              return "消防咨询";
           case EnterpriseServiceType.TRAINING_FT:
              return "财税讲座";
           case EnterpriseServiceType.TRAINING_LEGAL:
              return "企业合规讲座";
           case EnterpriseServiceType.TRAINING_CAPITAL:
              return "资本市场讲座";
           case EnterpriseServiceType.TRAINING_MANAGEMENT:
              return "企业总裁讲座";
           case EnterpriseServiceType.TRAINING_CB_EC:
              return "跨境电商讲座";
           case EnterpriseServiceType.TRAINING_MARKETING:
              return "市场培训";
           case EnterpriseServiceType.TRAINING_FP:
              return "消防培训";
           case EnterpriseServiceType.FT_PLANNING:
              return "财税筹划";
           case EnterpriseServiceType.PUBLICITY_BASE:
              return "企业宣传基础服务包";
           case EnterpriseServiceType.PUBLICITY_SUPER:
              return "企业宣传高级服务包";
           case EnterpriseServiceType.CONNECTION:
              return "人脉商圈";
           case EnterpriseServiceType.POLICY:
              return "政策推送";
           case EnterpriseServiceType.POLICY_QUERY:
              return "政策查询";
           case EnterpriseServiceType.COOP:
              return "合作机会推送";
           case EnterpriseServiceType.COOP_QUERY:
              return "合作机会查询";
           case EnterpriseServiceType.QLL_SERVICE_PKG:
              return "企联联综合服务套餐";
           case EnterpriseServiceType.QLL_MEMBER_COOP:
              return "企联联友情会员";
           case EnterpriseServiceType.QLL_MEMBER:
              return "企联联会员";
           case EnterpriseServiceType.QLL_MEMBER_VIP:
              return "企联联高级会员";
           case EnterpriseServiceType.QLL_MEMBER_PARTNER:
              return "企联联合作伙伴";
           case EnterpriseServiceType.LEGAL_SERVICE_PKG:
              return "法务套餐";
           case EnterpriseServiceType.LEGAL_LIGHT:
              return "法务套餐轻量版";
           case EnterpriseServiceType.LEGAL_STANDARD:
              return "法务套餐标准版";
           case EnterpriseServiceType.LEGAL_VIP:
              return "法务套餐尊享版";
           case EnterpriseServiceType.ASSOC_MEMBER:
              return "商协会会员服务";
           case EnterpriseServiceType.CHARITY:
              return "公益";
           case EnterpriseServiceType.GENERAL:
              return "综合";
           case EnterpriseServiceType.MINI_PROGRAM:
              return "小程序开发";
           case EnterpriseServiceType.WEIXIN_PUBLIC_ACCOUNT:
              return "微信公众号开发及维护";
           case EnterpriseServiceType.MANUFACTURE_DATA_SERVICE:
              return "制造企业数据服务";
           case EnterpriseServiceType.CLOUD:
              return "云";
           case EnterpriseServiceType.FORUM:
              return "论坛";
           case EnterpriseServiceType.COOP_BUS_CASE:
              return "合作案例";
           case EnterpriseServiceType.QLL_BASIC_MEMBER:
              return "企联联基础会员";
           case EnterpriseServiceType.QLL_TEST_MEMBER:
              return "企联联测试会员";
        };
        return null;
    }
}
