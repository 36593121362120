/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {EnterpriseServiceType} from '../EnterpriseServiceType'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class ServiceStatsItem extends AbstractSerializable  {
    imgUrl: string;
    serviceType: EnterpriseServiceType;
    serviceTypeName: string;
    serviceTypeDisplayName: string;
    serviceClassifier: string;
    serviceClassifierDisplayName: string;
    description: string;
    totalMembers: number;
    totalRequests: number;
    totalUnassignedRequests: number;
    totalAssignedRequests: number;
    totalAcceptedRequests: number;
    totalCompletedRequests: number;
    totalRequestsToSettle: number;
    totalAmount: number;
    totalAmountToSettle: number;
    latestRequestTime: (number | string);
    unreadRequestCountByWorker: number;

    constructor() {
    	super();
    }

    public getImgUrl(): string {
    	return this.imgUrl;
    }

    public setImgUrl(imgUrl: string) {
    	this.imgUrl = imgUrl;
    }

    public getServiceType(): EnterpriseServiceType {
    	return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
    	this.serviceType = serviceType;
    }

    public getServiceTypeName(): string {
    	return this.serviceTypeName;
    }

    public setServiceTypeName(serviceTypeName: string) {
    	this.serviceTypeName = serviceTypeName;
    }

    public getServiceTypeDisplayName(): string {
    	return this.serviceTypeDisplayName;
    }

    public setServiceTypeDisplayName(serviceTypeDisplayName: string) {
    	this.serviceTypeDisplayName = serviceTypeDisplayName;
    }

    public getServiceClassifier(): string {
    	return this.serviceClassifier;
    }

    public setServiceClassifier(serviceClassifier: string) {
    	this.serviceClassifier = serviceClassifier;
    }

    public getServiceClassifierDisplayName(): string {
    	return this.serviceClassifierDisplayName;
    }

    public setServiceClassifierDisplayName(serviceClassifierDisplayName: string) {
    	this.serviceClassifierDisplayName = serviceClassifierDisplayName;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getTotalMembers(): number {
    	return this.totalMembers;
    }

    public setTotalMembers(totalMembers: number) {
    	this.totalMembers = totalMembers;
    }

    public getTotalRequests(): number {
    	return this.totalRequests;
    }

    public setTotalRequests(totalRequests: number) {
    	this.totalRequests = totalRequests;
    }

    public getTotalUnassignedRequests(): number {
    	return this.totalUnassignedRequests;
    }

    public setTotalUnassignedRequests(totalUnassignedRequests: number) {
    	this.totalUnassignedRequests = totalUnassignedRequests;
    }

    public getTotalAssignedRequests(): number {
    	return this.totalAssignedRequests;
    }

    public setTotalAssignedRequests(totalAssignedRequests: number) {
    	this.totalAssignedRequests = totalAssignedRequests;
    }

    public getTotalAcceptedRequests(): number {
    	return this.totalAcceptedRequests;
    }

    public setTotalAcceptedRequests(totalAcceptedRequests: number) {
    	this.totalAcceptedRequests = totalAcceptedRequests;
    }

    public getTotalCompletedRequests(): number {
    	return this.totalCompletedRequests;
    }

    public setTotalCompletedRequests(totalCompletedRequests: number) {
    	this.totalCompletedRequests = totalCompletedRequests;
    }

    public getTotalRequestsToSettle(): number {
    	return this.totalRequestsToSettle;
    }

    public setTotalRequestsToSettle(totalRequestsToSettle: number) {
    	this.totalRequestsToSettle = totalRequestsToSettle;
    }

    public getTotalAmount(): number {
    	return this.totalAmount;
    }

    public setTotalAmount(totalAmount: number) {
    	this.totalAmount = totalAmount;
    }

    public getTotalAmountToSettle(): number {
    	return this.totalAmountToSettle;
    }

    public setTotalAmountToSettle(totalAmountToSettle: number) {
    	this.totalAmountToSettle = totalAmountToSettle;
    }

    public getLatestRequestTime(): (number | string) {
    	return this.latestRequestTime;
    }

    public setLatestRequestTime(latestRequestTime: (number | string)) {
    	this.latestRequestTime = latestRequestTime;
    }

    public getUnreadRequestCountByWorker(): number {
    	return this.unreadRequestCountByWorker;
    }

    public setUnreadRequestCountByWorker(unreadRequestCountByWorker: number) {
    	this.unreadRequestCountByWorker = unreadRequestCountByWorker;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let imgUrl = json["imgUrl"];
        if (imgUrl != null) {
            this.setImgUrl(imgUrl);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let serviceTypeName = json["serviceTypeName"];
        if (serviceTypeName != null) {
            this.setServiceTypeName(serviceTypeName);
        }
        let serviceTypeDisplayName = json["serviceTypeDisplayName"];
        if (serviceTypeDisplayName != null) {
            this.setServiceTypeDisplayName(serviceTypeDisplayName);
        }
        let serviceClassifier = json["serviceClassifier"];
        if (serviceClassifier != null) {
            this.setServiceClassifier(serviceClassifier);
        }
        let serviceClassifierDisplayName = json["serviceClassifierDisplayName"];
        if (serviceClassifierDisplayName != null) {
            this.setServiceClassifierDisplayName(serviceClassifierDisplayName);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let totalMembers = json["totalMembers"];
        if (totalMembers != null) {
            this.setTotalMembers(totalMembers);
        }
        let totalRequests = json["totalRequests"];
        if (totalRequests != null) {
            this.setTotalRequests(totalRequests);
        }
        let totalUnassignedRequests = json["totalUnassignedRequests"];
        if (totalUnassignedRequests != null) {
            this.setTotalUnassignedRequests(totalUnassignedRequests);
        }
        let totalAssignedRequests = json["totalAssignedRequests"];
        if (totalAssignedRequests != null) {
            this.setTotalAssignedRequests(totalAssignedRequests);
        }
        let totalAcceptedRequests = json["totalAcceptedRequests"];
        if (totalAcceptedRequests != null) {
            this.setTotalAcceptedRequests(totalAcceptedRequests);
        }
        let totalCompletedRequests = json["totalCompletedRequests"];
        if (totalCompletedRequests != null) {
            this.setTotalCompletedRequests(totalCompletedRequests);
        }
        let totalRequestsToSettle = json["totalRequestsToSettle"];
        if (totalRequestsToSettle != null) {
            this.setTotalRequestsToSettle(totalRequestsToSettle);
        }
        let totalAmount = json["totalAmount"];
        if (totalAmount != null) {
            this.setTotalAmount(totalAmount);
        }
        let totalAmountToSettle = json["totalAmountToSettle"];
        if (totalAmountToSettle != null) {
            this.setTotalAmountToSettle(totalAmountToSettle);
        }
        let latestRequestTime = json["latestRequestTime"];
        if (latestRequestTime != null) {
            this.setLatestRequestTime(latestRequestTime);
        }
        let unreadRequestCountByWorker = json["unreadRequestCountByWorker"];
        if (unreadRequestCountByWorker != null) {
            this.setUnreadRequestCountByWorker(unreadRequestCountByWorker);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let imgUrl = this.getImgUrl();
        if (imgUrl != null) {
            json["imgUrl"] = imgUrl;
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let serviceTypeName = this.getServiceTypeName();
        if (serviceTypeName != null) {
            json["serviceTypeName"] = serviceTypeName;
        }
        let serviceTypeDisplayName = this.getServiceTypeDisplayName();
        if (serviceTypeDisplayName != null) {
            json["serviceTypeDisplayName"] = serviceTypeDisplayName;
        }
        let serviceClassifier = this.getServiceClassifier();
        if (serviceClassifier != null) {
            json["serviceClassifier"] = serviceClassifier;
        }
        let serviceClassifierDisplayName = this.getServiceClassifierDisplayName();
        if (serviceClassifierDisplayName != null) {
            json["serviceClassifierDisplayName"] = serviceClassifierDisplayName;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let totalMembers = this.getTotalMembers();
        if (totalMembers != null) {
            json["totalMembers"] = totalMembers;
        }
        let totalRequests = this.getTotalRequests();
        if (totalRequests != null) {
            json["totalRequests"] = totalRequests;
        }
        let totalUnassignedRequests = this.getTotalUnassignedRequests();
        if (totalUnassignedRequests != null) {
            json["totalUnassignedRequests"] = totalUnassignedRequests;
        }
        let totalAssignedRequests = this.getTotalAssignedRequests();
        if (totalAssignedRequests != null) {
            json["totalAssignedRequests"] = totalAssignedRequests;
        }
        let totalAcceptedRequests = this.getTotalAcceptedRequests();
        if (totalAcceptedRequests != null) {
            json["totalAcceptedRequests"] = totalAcceptedRequests;
        }
        let totalCompletedRequests = this.getTotalCompletedRequests();
        if (totalCompletedRequests != null) {
            json["totalCompletedRequests"] = totalCompletedRequests;
        }
        let totalRequestsToSettle = this.getTotalRequestsToSettle();
        if (totalRequestsToSettle != null) {
            json["totalRequestsToSettle"] = totalRequestsToSettle;
        }
        let totalAmount = this.getTotalAmount();
        if (totalAmount != null) {
            json["totalAmount"] = totalAmount;
        }
        let totalAmountToSettle = this.getTotalAmountToSettle();
        if (totalAmountToSettle != null) {
            json["totalAmountToSettle"] = totalAmountToSettle;
        }
        let latestRequestTime = this.getLatestRequestTime();
        if (latestRequestTime != null) {
            json["latestRequestTime"] = String(latestRequestTime);
        }
        let unreadRequestCountByWorker = this.getUnreadRequestCountByWorker();
        if (unreadRequestCountByWorker != null) {
            json["unreadRequestCountByWorker"] = unreadRequestCountByWorker;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.stats.ServiceStatsItem";
    }
}
