/**
 * 自动生成， 请不要手工修改！
 */


export enum TopicIconTag {
    POLLS,
    TEMP_JOB,
    TIPS,
    STUDY,
    TRAVEL,
    EDUCATION,
    HELP,
    L_SPECIAL,
    LIFE_SERVICE,
    PRICE,
    HEALTH,
    JOKE,
    GOOD_MEN,
    GOOD_FOOD,
    GOOD_THING,
    GOOD_LIFE,
    GOOD_IDEAS,
    PRAISE,
    AVOID_BAD,
    APPEAL,
    RESIDENCE_LIST,
    VILLAGE_LIST,
    GRID_LIST,
    RESIDENCE,
    VILLAGE,
    GRID,
    BUS_CIRCLE,
    CHARITY,
    LIFE,
    POINT,
    TOPIC_LIST,
    GW,
    ALUMNI,
    SOCIETY,
    PRODUCT,
    SERVICE,
    REGION
}
