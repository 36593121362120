


export enum EnterpriseServiceCategory {
    QLL_MEMBER,
    LEGAL,
    LABOUR,
    BANKING,
    COMMERCE,
    SOFTWARE,
    FISCAL,
    SAFETY,
    CONSUMER,
    ASSOC_MEMBER,
    CHARITY,
    LOAN,
    EQUITY,
    FIN_GENERAL,
    MISC,
    MARKETING,
    IC_FISCAL,
    IC,
    PRE_SALE_SERVICE,
    POST_SALE_SERVICE
}
