/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {MessageRange} from './MessageRange'
import {PageBlockAccessSpec} from './PageBlockAccessSpec'
import {PageDataSpec} from './PageDataSpec'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractGeoSelectPageDataSpec extends AbstractSerializable implements PageDataSpec {
    fetchedSize: number;
    estimatedTotalRecords: number;
    localRecordsRemaining: boolean;
    mediumRecordsRemaining: boolean;
    globalRecordsRemaining: boolean;
    attentionRecordsRemaining: boolean;
    recordsRemaining: boolean;
    localRadiusFiltered: boolean;
    localRange: MessageRange;
    mediumRange: MessageRange;
    globalRange: MessageRange;
    attentionRange: MessageRange;
    startPosition: number;

    constructor() {
        super();
    }

    public getFetchedSize(): number {
        return this.fetchedSize;
    }

    public setFetchedSize(fetchedSize: number) {
        this.fetchedSize = fetchedSize;
    }

    public getEstimatedTotalRecords(): number {
        return this.estimatedTotalRecords;
    }

    public setEstimatedTotalRecords(estimatedTotalRecords: number) {
        this.estimatedTotalRecords = estimatedTotalRecords;
    }

    public isLocalRecordsRemaining(): boolean {
        return this.localRecordsRemaining;
    }

    public setLocalRecordsRemaining(localRecordsRemaining: boolean) {
        this.localRecordsRemaining = localRecordsRemaining;
    }

    public isMediumRecordsRemaining(): boolean {
        return this.mediumRecordsRemaining;
    }

    public setMediumRecordsRemaining(mediumRecordsRemaining: boolean) {
        this.mediumRecordsRemaining = mediumRecordsRemaining;
    }

    public isGlobalRecordsRemaining(): boolean {
        return this.globalRecordsRemaining;
    }

    public setGlobalRecordsRemaining(globalRecordsRemaining: boolean) {
        this.globalRecordsRemaining = globalRecordsRemaining;
    }

    public isAttentionRecordsRemaining(): boolean {
        return this.attentionRecordsRemaining;
    }

    public setAttentionRecordsRemaining(attentionRecordsRemaining: boolean) {
        this.attentionRecordsRemaining = attentionRecordsRemaining;
    }

    public isRecordsRemaining(): boolean {
        return this.recordsRemaining;
    }

    public setRecordsRemaining(recordsRemaining: boolean) {
        this.recordsRemaining = recordsRemaining;
    }

    public isLocalRadiusFiltered(): boolean {
        return this.localRadiusFiltered;
    }

    public setLocalRadiusFiltered(localRadiusFiltered: boolean) {
        this.localRadiusFiltered = localRadiusFiltered;
    }

    public getLocalRange(): MessageRange {
        return this.localRange;
    }

    public setLocalRange(localRange: MessageRange) {
        this.localRange = localRange;
    }

    public getMediumRange(): MessageRange {
        return this.mediumRange;
    }

    public setMediumRange(mediumRange: MessageRange) {
        this.mediumRange = mediumRange;
    }

    public getGlobalRange(): MessageRange {
        return this.globalRange;
    }

    public setGlobalRange(globalRange: MessageRange) {
        this.globalRange = globalRange;
    }

    public getAttentionRange(): MessageRange {
        return this.attentionRange;
    }

    public setAttentionRange(attentionRange: MessageRange) {
        this.attentionRange = attentionRange;
    }

    public getStartPosition(): number {
        return this.startPosition;
    }

    public setStartPosition(startPosition: number) {
        this.startPosition = startPosition;
    }


    abstract getForwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getForwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract getNextBackwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getNextBackwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract addPageDataSpec(arg0: (PageDataSpec | null), arg1: (boolean | null)): PageDataSpec;

    abstract getContinuousNoDataCount(): number;

    abstract getNextStart(): number;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let fetchedSize = json["fetchedSize"];
        if (fetchedSize != null) {
            this.setFetchedSize(fetchedSize);
        }
        let estimatedTotalRecords = json["estimatedTotalRecords"];
        if (estimatedTotalRecords != null) {
            this.setEstimatedTotalRecords(estimatedTotalRecords);
        }
        let localRecordsRemaining = json["localRecordsRemaining"];
        if (localRecordsRemaining != null) {
            this.setLocalRecordsRemaining(localRecordsRemaining);
        }
        let mediumRecordsRemaining = json["mediumRecordsRemaining"];
        if (mediumRecordsRemaining != null) {
            this.setMediumRecordsRemaining(mediumRecordsRemaining);
        }
        let globalRecordsRemaining = json["globalRecordsRemaining"];
        if (globalRecordsRemaining != null) {
            this.setGlobalRecordsRemaining(globalRecordsRemaining);
        }
        let attentionRecordsRemaining = json["attentionRecordsRemaining"];
        if (attentionRecordsRemaining != null) {
            this.setAttentionRecordsRemaining(attentionRecordsRemaining);
        }
        let recordsRemaining = json["recordsRemaining"];
        if (recordsRemaining != null) {
            this.setRecordsRemaining(recordsRemaining);
        }
        let localRadiusFiltered = json["localRadiusFiltered"];
        if (localRadiusFiltered != null) {
            this.setLocalRadiusFiltered(localRadiusFiltered);
        }
        let localRange = json["localRange"];
        if (localRange != null) {
            let convertedLocalRange0 = Serializer.fillFromJsonObjectWithTypeTag(localRange, "__type", factory);
            this.setLocalRange(convertedLocalRange0);
        }
        let mediumRange = json["mediumRange"];
        if (mediumRange != null) {
            let convertedMediumRange0 = Serializer.fillFromJsonObjectWithTypeTag(mediumRange, "__type", factory);
            this.setMediumRange(convertedMediumRange0);
        }
        let globalRange = json["globalRange"];
        if (globalRange != null) {
            let convertedGlobalRange0 = Serializer.fillFromJsonObjectWithTypeTag(globalRange, "__type", factory);
            this.setGlobalRange(convertedGlobalRange0);
        }
        let attentionRange = json["attentionRange"];
        if (attentionRange != null) {
            let convertedAttentionRange0 = Serializer.fillFromJsonObjectWithTypeTag(attentionRange, "__type", factory);
            this.setAttentionRange(convertedAttentionRange0);
        }
        let startPosition = json["startPosition"];
        if (startPosition != null) {
            this.setStartPosition(startPosition);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let fetchedSize = this.getFetchedSize();
        if (fetchedSize != null) {
            json["fetchedSize"] = fetchedSize;
        }
        let estimatedTotalRecords = this.getEstimatedTotalRecords();
        if (estimatedTotalRecords != null) {
            json["estimatedTotalRecords"] = estimatedTotalRecords;
        }
        let localRecordsRemaining = this.isLocalRecordsRemaining();
        if (localRecordsRemaining != null) {
            json["localRecordsRemaining"] = localRecordsRemaining;
        }
        let mediumRecordsRemaining = this.isMediumRecordsRemaining();
        if (mediumRecordsRemaining != null) {
            json["mediumRecordsRemaining"] = mediumRecordsRemaining;
        }
        let globalRecordsRemaining = this.isGlobalRecordsRemaining();
        if (globalRecordsRemaining != null) {
            json["globalRecordsRemaining"] = globalRecordsRemaining;
        }
        let attentionRecordsRemaining = this.isAttentionRecordsRemaining();
        if (attentionRecordsRemaining != null) {
            json["attentionRecordsRemaining"] = attentionRecordsRemaining;
        }
        let recordsRemaining = this.isRecordsRemaining();
        if (recordsRemaining != null) {
            json["recordsRemaining"] = recordsRemaining;
        }
        let localRadiusFiltered = this.isLocalRadiusFiltered();
        if (localRadiusFiltered != null) {
            json["localRadiusFiltered"] = localRadiusFiltered;
        }
        let localRange = this.getLocalRange();
        if (localRange != null) {
            let convertedLocalRange0 = localRange.getJson(JsonTypeTagType.TYPE);
            json["localRange"] = convertedLocalRange0;
        }
        let mediumRange = this.getMediumRange();
        if (mediumRange != null) {
            let convertedMediumRange0 = mediumRange.getJson(JsonTypeTagType.TYPE);
            json["mediumRange"] = convertedMediumRange0;
        }
        let globalRange = this.getGlobalRange();
        if (globalRange != null) {
            let convertedGlobalRange0 = globalRange.getJson(JsonTypeTagType.TYPE);
            json["globalRange"] = convertedGlobalRange0;
        }
        let attentionRange = this.getAttentionRange();
        if (attentionRange != null) {
            let convertedAttentionRange0 = attentionRange.getJson(JsonTypeTagType.TYPE);
            json["attentionRange"] = convertedAttentionRange0;
        }
        let startPosition = this.getStartPosition();
        if (startPosition != null) {
            json["startPosition"] = startPosition;
        }

        if (includeTypeTag) {
            json["__type"] = "GeoSelectPageDataSpec";
        }
    }


    public getTypeId(): string {
        return "GeoSelectPageDataSpec";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.paging.GeoSelectPageDataSpec";
    }
}
