// @ts-nocheck
import {AbstractSecurityProfileAspect} from './AbstractSecurityProfileAspect'
import {ProfileAspectType} from './ProfileAspectType'


export class SecurityProfileAspect extends AbstractSecurityProfileAspect  {
    constructor() {
    	super();
    }

    getProfileAspectType(): ProfileAspectType {
      // Manual Code Here
    }
    getEntityId(): (number | string) {
      // Manual Code Here
    }
    setEntityId(arg0: (number | string | null)) {
      // Manual Code Here
    }

}
