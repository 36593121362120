/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {AbstractEnterpriseServiceTrade} from '../datatype/business/service/trade/AbstractEnterpriseServiceTrade'
import {CompositeEnterpriseServiceTradableInfo} from '../datatype/business/service/tradable/CompositeEnterpriseServiceTradableInfo'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EnterpriseServiceCategorySpec} from '../datatype/business/service/EnterpriseServiceCategorySpec'
import {EnterpriseServiceCategory} from '../datatype/business/service/EnterpriseServiceCategory'
import {EnterpriseServicePackageService} from '../api/business/service/EnterpriseServicePackageService'
import {EnterpriseServiceTradable} from '../datatype/business/service/tradable/EnterpriseServiceTradable'
import {EnterpriseServiceType} from '../datatype/business/service/EnterpriseServiceType'
import {EnterpriseServiceView} from '../datatype/business/service/tradable/EnterpriseServiceView'
import {GeoEntityDesc} from '../datatype/geolocation/GeoEntityDesc'
import {Location} from '../datatype/geolocation/Location'
import {PageableData} from '../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {PlatformHighlightedServiceInfos} from '../datatype/business/service/tradable/PlatformHighlightedServiceInfos'
import {PlatformMemberType} from '../datatype/business/service/PlatformMemberType'
import {ReviewOverview} from '../datatype/ipsphere/review/ReviewOverview'
import {SectorServiceSpec} from '../datatype/entity/sector/SectorServiceSpec'
import {ServiceHighlight} from '../datatype/business/service/ServiceHighlight'
import {ServiceTradableSeriesDisplaySpec} from '../datatype/business/service/tradable/ServiceTradableSeriesDisplaySpec'
import {UserServiceTradableInfo} from '../datatype/business/service/tradable/UserServiceTradableInfo'
import {WebEnterpriseServicePackageService} from '../webapi/WebEnterpriseServicePackageService'
import {WebSessionManager} from '../common/WebSessionManager'


export class WebEnterpriseServicePackageServiceImpl implements WebEnterpriseServicePackageService {

    private enterpriseServicePackageService: EnterpriseServicePackageService;

    public constructor() {
        this.enterpriseServicePackageService = DefaultServiceFactory.getInstance().getEnterpriseServicePackageService();
    }

    public addEnterpriseService(enterpriseService: (EnterpriseServiceTradable | null)): Promise<(number | string)> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.addEnterpriseService(WebSessionManager.getSessionContext(),
        	enterpriseService, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public cancelEnterpriseService(enterpriseServiceId: (number | string | null), reason: (string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.cancelEnterpriseService(WebSessionManager.getSessionContext(),
        	enterpriseServiceId, reason, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceById(enterpriseServiceId: (number | string | null)): Promise<CompositeEnterpriseServiceTradableInfo> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceById(WebSessionManager.getSessionContext(),
        	enterpriseServiceId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceByIdAndPkgId(tradableId: (number | string | null), pkgTradableId: (number | string | null)): Promise<CompositeEnterpriseServiceTradableInfo> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceByIdAndPkgId(WebSessionManager.getSessionContext(),
        	tradableId, pkgTradableId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceByMemberType(memberType: (PlatformMemberType | null)): Promise<CompositeEnterpriseServiceTradableInfo> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceByMemberType(WebSessionManager.getSessionContext(),
        	memberType, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceProfessionals(enterpriseServiceId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceProfessionals(WebSessionManager.getSessionContext(),
        	enterpriseServiceId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceProfessionalsByRegionAndCategory(regionId: (number | string | null), category: (EnterpriseServiceCategory | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceProfessionalsByRegionAndCategory(WebSessionManager.getSessionContext(),
        	regionId, category, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceProfessionalsByRegionAndServiceType(regionId: (number | string | null), serviceType: (EnterpriseServiceType | null)): Promise<Array<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceProfessionalsByRegionAndServiceType(WebSessionManager.getSessionContext(),
        	regionId, serviceType, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceSuppliers(enterpriseServiceId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceSuppliers(WebSessionManager.getSessionContext(),
        	enterpriseServiceId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceSuppliersByRegionAndCategory(regionId: (number | string | null), category: (EnterpriseServiceCategory | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceSuppliersByRegionAndCategory(WebSessionManager.getSessionContext(),
        	regionId, category, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceSuppliersByRegionAndServiceType(regionId: (number | string | null), serviceType: (EnterpriseServiceType | null)): Promise<Array<GeoEntityDesc>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceSuppliersByRegionAndServiceType(WebSessionManager.getSessionContext(),
        	regionId, serviceType, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceTradeByTradableId(enterpriseServiceId: (number | string | null)): Promise<AbstractEnterpriseServiceTrade> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceTradeByTradableId(WebSessionManager.getSessionContext(),
        	enterpriseServiceId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServiceTradeByTradableIdInPkg(tradableId: (number | string | null), pkgTradableId: (number | string | null)): Promise<AbstractEnterpriseServiceTrade> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServiceTradeByTradableIdInPkg(WebSessionManager.getSessionContext(),
        	tradableId, pkgTradableId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServicesByAssociationId(associationId: (number | string | null), serviceCategory: (EnterpriseServiceCategory | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceView>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServicesByAssociationId(WebSessionManager.getSessionContext(),
        	associationId, serviceCategory, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServicesByEnterpriseId(enterpriseId: (number | string | null), serviceCategory: (EnterpriseServiceCategory | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceView>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServicesByEnterpriseId(WebSessionManager.getSessionContext(),
        	enterpriseId, serviceCategory, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServicesByRegion(regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceView>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServicesByRegion(WebSessionManager.getSessionContext(),
        	regionId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServicesByServiceTypeAndRegion(serviceType: (EnterpriseServiceType | null), regionId: (number | string | null)): Promise<Array<EnterpriseServiceView>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServicesByServiceTypeAndRegion(WebSessionManager.getSessionContext(),
        	serviceType, regionId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServicesInPackage(enterpriseServicePackageId: (number | string | null)): Promise<Array<EnterpriseServiceView>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServicesInPackage(WebSessionManager.getSessionContext(),
        	enterpriseServicePackageId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findEnterpriseServicesInSeries(seriesId: (number | string | null)): Promise<Array<CompositeEnterpriseServiceTradableInfo>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findEnterpriseServicesInSeries(WebSessionManager.getSessionContext(),
        	seriesId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findRegionalServicesByAssociationId(associationId: (number | string | null), regionId: (number | string | null), serviceCategory: (EnterpriseServiceCategory | null), isPackage: (boolean | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceView>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findRegionalServicesByAssociationId(WebSessionManager.getSessionContext(),
        	associationId, regionId, serviceCategory, isPackage, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findReviewsByServiceId(serviceId: (number | string | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<ReviewOverview>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findReviewsByServiceId(WebSessionManager.getSessionContext(),
        	serviceId, regionId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findServiceIdByEnterpriseOnRegionAndCategory(enterpriseId: (number | string | null), regionId: (number | string | null), category: (EnterpriseServiceCategory | null)): Promise<(number | string)> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findServiceIdByEnterpriseOnRegionAndCategory(WebSessionManager.getSessionContext(),
        	enterpriseId, regionId, category, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findServiceIdByProfessionalOnRegionAndCategory(professionalId: (number | string | null), regionId: (number | string | null), category: (EnterpriseServiceCategory | null)): Promise<(number | string)> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findServiceIdByProfessionalOnRegionAndCategory(WebSessionManager.getSessionContext(),
        	professionalId, regionId, category, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findUserServiceTradableInfoByTradableId(tradableId: (number | string | null)): Promise<UserServiceTradableInfo> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findUserServiceTradableInfoByTradableId(WebSessionManager.getSessionContext(),
        	tradableId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findUserServiceTradableInfoByTradableIdAndPkgTradeId(tradableId: (number | string | null), pkgTradeId: (number | string | null)): Promise<UserServiceTradableInfo> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findUserServiceTradableInfoByTradableIdAndPkgTradeId(WebSessionManager.getSessionContext(),
        	tradableId, pkgTradeId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public findUserServiceTradableInfoByTradableIdInPkg(tradableId: (number | string | null), pkgTradableId: (number | string | null)): Promise<UserServiceTradableInfo> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.findUserServiceTradableInfoByTradableIdInPkg(WebSessionManager.getSessionContext(),
        	tradableId, pkgTradableId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getAllDisplayableServiceCategories(): Promise<Array<EnterpriseServiceCategory>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.getAllDisplayableServiceCategories(WebSessionManager.getSessionContext(),
        	
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getAllDisplayableServiceCategorySpecs(regionId: (number | string | null)): Promise<Array<EnterpriseServiceCategorySpec>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.getAllDisplayableServiceCategorySpecs(WebSessionManager.getSessionContext(),
        	regionId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getGeneralServicesByRegionAndCategory(regionId: (number | string | null), serviceCategory: (EnterpriseServiceCategory | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<SectorServiceSpec>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.getGeneralServicesByRegionAndCategory(WebSessionManager.getSessionContext(),
        	regionId, serviceCategory, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getPlatformHighlightedServiceInfosByCategoryForLocation(category: (EnterpriseServiceCategory | null), location: (Location | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PlatformHighlightedServiceInfos> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.getPlatformHighlightedServiceInfosByCategoryForLocation(WebSessionManager.getSessionContext(),
        	category, location, regionId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getPlatformHighlightedServiceInfosByMemberTypeForLocation(memberType: (PlatformMemberType | null), location: (Location | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PlatformHighlightedServiceInfos> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.getPlatformHighlightedServiceInfosByMemberTypeForLocation(WebSessionManager.getSessionContext(),
        	memberType, location, regionId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getPlatformHighlightedServiceInfosToUpgradeForLocation(currentMemberType: (PlatformMemberType | null), location: (Location | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PlatformHighlightedServiceInfos> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.getPlatformHighlightedServiceInfosToUpgradeForLocation(WebSessionManager.getSessionContext(),
        	currentMemberType, location, regionId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getPlatformServiceHighlightsByCategoryForLocation(category: (EnterpriseServiceCategory | null), location: (Location | null), regionId: (number | string | null)): Promise<Array<ServiceHighlight>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.getPlatformServiceHighlightsByCategoryForLocation(WebSessionManager.getSessionContext(),
        	category, location, regionId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getPlatformServicesByCategoryForLocation(category: (EnterpriseServiceCategory | null), location: (Location | null), regionId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceView>> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.getPlatformServicesByCategoryForLocation(WebSessionManager.getSessionContext(),
        	category, location, regionId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getServiceTradableSeriesDisplaySpec(seriesId: (number | string | null), currentPkgId: (number | string | null)): Promise<ServiceTradableSeriesDisplaySpec> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.getServiceTradableSeriesDisplaySpec(WebSessionManager.getSessionContext(),
        	seriesId, currentPkgId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getServiceTradableSeriesDisplaySpecByMemberType(memberType: (PlatformMemberType | null)): Promise<ServiceTradableSeriesDisplaySpec> {
        return new Promise((resolve, reject) => {
        	this.enterpriseServicePackageService.getServiceTradableSeriesDisplaySpecByMemberType(WebSessionManager.getSessionContext(),
        	memberType, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

}
