/**
 * 自动生成， 请不要手工修改！
 */


export enum EnterpriseInfoTabType {
    MEMBER,
    SHARED_INFO,
    HONOUR,
    BUS_CASE,
    DYNAMIC_WITH_CASE,
    DYNAMIC_WITHOUT_CASE,
    CULTURE,
    AD,
    APPEAL,
    EXCHANGE
}
