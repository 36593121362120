/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EducationInfo} from '../expert/EducationInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {ProfileAspectType} from '../../entity/profile/ProfileAspectType'
import {ProfileAspect} from '../../entity/profile/ProfileAspect'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractEducationProfileAspect extends AbstractSerializable implements ProfileAspect  {
    userId: (number | string);
    educationInfos: Array<EducationInfo>;

    constructor() {
    	super();
    }

    public getUserId(): (number | string) {
    	return this.userId;
    }

    public setUserId(userId: (number | string)) {
    	this.userId = userId;
    }

    public getEducationInfos(): Array<EducationInfo> {
    	return this.educationInfos;
    }

    public setEducationInfos(educationInfos: Array<EducationInfo>) {
    	this.educationInfos = educationInfos;
    }


    abstract getProfileAspectType(): ProfileAspectType;

    abstract getEntityId(): (number | string);

    abstract setEntityId(arg0: (number | string | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let userId = json["userId"];
        if (userId != null) {
            this.setUserId(userId);
        }
        let educationInfos = json["educationInfos"];
        if (educationInfos != null) {
            let convertedEducationInfos0 = [];
            educationInfos = educationInfos[1];
            for (let i in educationInfos) {
                let convertedEducationInfos10 = educationInfos[i];
                let convertedEducationInfos1 = new EducationInfo();
                convertedEducationInfos1.fillFromJson(convertedEducationInfos10, factory);
                convertedEducationInfos0.push(convertedEducationInfos1);
            }
            this.setEducationInfos(convertedEducationInfos0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let userId = this.getUserId();
        if (userId != null) {
            json["userId"] = String(userId);
        }
        let educationInfos = this.getEducationInfos();
        if (educationInfos != null) {
            let convertedEducationInfos0 = [];
            for (let i in educationInfos) {
                let convertedEducationInfos10 = educationInfos[i];
                let convertedEducationInfos1 = convertedEducationInfos10.getJson(JsonTypeTagType.NONE);
                convertedEducationInfos0.push(convertedEducationInfos1);
            }
            let convertedEducationInfosWithType1 = ["java.util.ArrayList", convertedEducationInfos0];
            json["educationInfos"] = convertedEducationInfosWithType1;
        }
    
        if (includeTypeTag) {
            json["__type"] = "EducationProfileAspect";
        }
    }


    public getTypeId(): string {
    	return "EducationProfileAspect";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.ipsphere.expertise.EducationProfileAspect";
    }
}
