/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { PositionDescriptor } from "./PositionDescriptor";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class CompositePositionDescriptor
  extends AbstractSerializable
  implements PositionDescriptor
{
  leftPosition: PositionDescriptor;
  rightPosition: PositionDescriptor;

  constructor() {
    super();
  }

  public getLeftPosition(): PositionDescriptor {
    return this.leftPosition;
  }

  public setLeftPosition(leftPosition: PositionDescriptor) {
    this.leftPosition = leftPosition;
  }

  public getRightPosition(): PositionDescriptor {
    return this.rightPosition;
  }

  public setRightPosition(rightPosition: PositionDescriptor) {
    this.rightPosition = rightPosition;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const leftPosition = json["leftPosition"];
    if (leftPosition != null) {
      const convertedLeftPosition0 = Serializer.fillFromJsonObjectWithTypeTag(
        leftPosition,
        "__type",
        factory
      );
      this.setLeftPosition(convertedLeftPosition0);
    }
    const rightPosition = json["rightPosition"];
    if (rightPosition != null) {
      const convertedRightPosition0 = Serializer.fillFromJsonObjectWithTypeTag(
        rightPosition,
        "__type",
        factory
      );
      this.setRightPosition(convertedRightPosition0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const leftPosition = this.getLeftPosition();
    if (leftPosition != null) {
      const convertedLeftPosition0 = leftPosition.getJson(JsonTypeTagType.TYPE);
      json["leftPosition"] = convertedLeftPosition0;
    }
    const rightPosition = this.getRightPosition();
    if (rightPosition != null) {
      const convertedRightPosition0 = rightPosition.getJson(
        JsonTypeTagType.TYPE
      );
      json["rightPosition"] = convertedRightPosition0;
    }

    if (includeTypeTag) {
      json["__type"] = "CompositePosition";
    }
  }

  public getTypeId(): string {
    return "CompositePosition";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.paging.CompositePositionDescriptor";
  }
}
