/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class OffsetItem extends AbstractSerializable {
  startPosition: number;
  fetchSize: number;
  recordsRemaining: boolean;

  constructor() {
    super();
  }

  public getStartPosition(): number {
    return this.startPosition;
  }

  public setStartPosition(startPosition: number) {
    this.startPosition = startPosition;
  }

  public getFetchSize(): number {
    return this.fetchSize;
  }

  public setFetchSize(fetchSize: number) {
    this.fetchSize = fetchSize;
  }

  public isRecordsRemaining(): boolean {
    return this.recordsRemaining;
  }

  public setRecordsRemaining(recordsRemaining: boolean) {
    this.recordsRemaining = recordsRemaining;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const startPosition = json["startPosition"];
    if (startPosition != null) {
      this.setStartPosition(startPosition);
    }
    const fetchSize = json["fetchSize"];
    if (fetchSize != null) {
      this.setFetchSize(fetchSize);
    }
    const recordsRemaining = json["recordsRemaining"];
    if (recordsRemaining != null) {
      this.setRecordsRemaining(recordsRemaining);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const startPosition = this.getStartPosition();
    if (startPosition != null) {
      json["startPosition"] = startPosition;
    }
    const fetchSize = this.getFetchSize();
    if (fetchSize != null) {
      json["fetchSize"] = fetchSize;
    }
    const recordsRemaining = this.isRecordsRemaining();
    if (recordsRemaining != null) {
      json["recordsRemaining"] = recordsRemaining;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.paging.OffsetItem";
  }
}
