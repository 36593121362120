/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class PrizableInfo extends AbstractSerializable {
  creatorPrize: number;
  approverPrize: number;
  prizable: boolean;
  relCreatorPrize: number;
  active: boolean;

  constructor() {
    super();
  }

  public getCreatorPrize(): number {
    return this.creatorPrize;
  }

  public setCreatorPrize(creatorPrize: number) {
    this.creatorPrize = creatorPrize;
  }

  public getApproverPrize(): number {
    return this.approverPrize;
  }

  public setApproverPrize(approverPrize: number) {
    this.approverPrize = approverPrize;
  }

  public getPrizable(): boolean {
    return this.prizable;
  }

  public setPrizable(prizable: boolean) {
    this.prizable = prizable;
  }

  public getRelCreatorPrize(): number {
    return this.relCreatorPrize;
  }

  public setRelCreatorPrize(relCreatorPrize: number) {
    this.relCreatorPrize = relCreatorPrize;
  }

  public isActive(): boolean {
    return this.active;
  }

  public setActive(active: boolean) {
    this.active = active;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const creatorPrize = json["creatorPrize"];
    if (creatorPrize != null) {
      this.setCreatorPrize(creatorPrize);
    }
    const approverPrize = json["approverPrize"];
    if (approverPrize != null) {
      this.setApproverPrize(approverPrize);
    }
    const prizable = json["prizable"];
    if (prizable != null) {
      this.setPrizable(prizable);
    }
    const relCreatorPrize = json["relCreatorPrize"];
    if (relCreatorPrize != null) {
      this.setRelCreatorPrize(relCreatorPrize);
    }
    const active = json["active"];
    if (active != null) {
      this.setActive(active);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const creatorPrize = this.getCreatorPrize();
    if (creatorPrize != null) {
      json["creatorPrize"] = creatorPrize;
    }
    const approverPrize = this.getApproverPrize();
    if (approverPrize != null) {
      json["approverPrize"] = approverPrize;
    }
    const prizable = this.getPrizable();
    if (prizable != null) {
      json["prizable"] = prizable;
    }
    const relCreatorPrize = this.getRelCreatorPrize();
    if (relCreatorPrize != null) {
      json["relCreatorPrize"] = relCreatorPrize;
    }
    const active = this.isActive();
    if (active != null) {
      json["active"] = active;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.asset.PrizableInfo";
  }
}
