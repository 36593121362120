/**
 * 自动生成， 请不要手工修改！
 */

export enum MessageResponseType {
  ZERO,
  AT_MOST_ONE,
  ONE,
  MULTIPLE,
}
