/**
 * 自动生成， 请不要手工修改！
 */

import { CustomRequest } from "../../../request/CustomRequest";
import { EnterpriseServiceRequest } from "./EnterpriseServiceRequest";
import {JsonTypeTagType} from "../../../../serialize/JsonTypeTagType";
import {MessageType} from "../../../../message/MessageContentType";
import {TypeFactory} from "../../../../serialize/TypeFactory";

export abstract class AbstractAssignableEnterpriseServiceRequest extends CustomRequest<EnterpriseServiceRequest> {
  constructor() {
    super();
  }

    abstract getMessageType(): MessageType;

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);

    if (includeTypeTag) {
      json["__type"] = "AssignableEnterpriseServiceRequest";
    }
  }

  public getTypeId(): string {
    return "AssignableEnterpriseServiceRequest";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.service.request.AssignableEnterpriseServiceRequest";
  }
}
