/**
 * 自动生成， 请不要手工修改！
 */


export enum AppealStatus {
    TO_REVIEW,
    ACTIVE,
    ASSIGNED,
    ACCEPTED,
    REJECTED,
    REPLIED,
    TO_REPORT,
    REPORTED,
    COMPLETED,
    CANCELLED,
    HIDDEN,
    DISLIKED
}
