/**
 * 自动生成， 请不要手工修改！
 */

export enum AssociationType {
  U_REGION,
  U_SECTOR,
  L_SERVICE,
  G_SERVICE,
  INDUSTRY,
  INTEREST,
  CHARITY,
}
