/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../../../common/AbstractClientServiceAdapter'
import {AssociatedEntityInfo} from '../../../datatype/entity/AssociatedEntityInfo'
import {BiConsumer} from '../../../util/BiConsumer'
import {CoopUserAdView} from '../../../datatype/userad/coop/CoopUserAdView'
import {EnterpriseServiceOrder} from '../../../datatype/business/order/EnterpriseServiceOrder'
import {EnterpriseServiceRequestInfo} from '../../../datatype/business/service/request/EnterpriseServiceRequestInfo'
import {EnterpriseServiceRequestService} from '../../../api/business/service/EnterpriseServiceRequestService'
import {EnterpriseServiceRequest} from '../../../datatype/business/service/request/EnterpriseServiceRequest'
import {EnterpriseServiceType} from '../../../datatype/business/service/EnterpriseServiceType'
import {EntityMessageResponse} from '../../../message/entity/api/EntityMessageResponse'
import {EntityMessage} from '../../../message/entity/api/EntityMessage'
import {EntityServiceStats} from '../../../datatype/business/service/stats/EntityServiceStats'
import {FinancingServiceRequestClassifier} from '../../../datatype/business/service/finance/FinancingServiceRequestClassifier'
import {FinancingServiceRequest} from '../../../datatype/business/service/request/banking/FinancingServiceRequest'
import {GeoEntityDesc} from '../../../datatype/geolocation/GeoEntityDesc'
import {PageableData} from '../../../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../../../datatype/paging/PageBlockAccessSpec'
import {PaymentSpec} from '../../../datatype/business/payment/PaymentSpec'
import {RequestStatusClassifier} from '../../../datatype/request/RequestStatusClassifier'
import {RequestStatusType} from '../../../datatype/request/RequestStatusType'
import {ServiceConsumers} from '../../../util/ServiceConsumers'
import {ServiceRequestClassifier} from '../../../datatype/business/service/request/ServiceRequestClassifier'
import {ServiceRequest} from '../../../common/ServiceRequest'
import {ServingEntityInfos} from '../../../datatype/business/service/request/ServingEntityInfos'
import {SessionContext} from '../../../session/SessionContext'


export class EnterpriseServiceRequestServiceProxy extends AbstractClientServiceAdapter implements EnterpriseServiceRequestService {

    public constructor() {
        super("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService");
    }

    public acceptByServiceProvider(sessionContext: (SessionContext | null), requestId: (number | string | null), resultHandler: BiConsumer<RequestStatusType, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "acceptByServiceProvider", [sessionContext, requestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public acceptByServiceProviderWithMiniCode(sessionContext: (SessionContext | null), miniCode: (string | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "acceptByServiceProviderWithMiniCode", [sessionContext, miniCode]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public acceptDelegateByServiceWorker(sessionContext: (SessionContext | null), requestId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "acceptDelegateByServiceWorker", [sessionContext, requestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public acceptDelegateByServiceWorkerWithMiniCode(sessionContext: (SessionContext | null), miniCode: (string | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "acceptDelegateByServiceWorkerWithMiniCode", [sessionContext, miniCode]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public acceptServiceProvider(sessionContext: (SessionContext | null), requestId: (number | string | null), servingProfessionalId: (number | string | null), resultHandler: BiConsumer<RequestStatusType, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "acceptServiceProvider", [sessionContext, requestId, servingProfessionalId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public addEnterpriseServiceRequest(sessionContext: (SessionContext | null), serviceRequest: (EnterpriseServiceRequest | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "addEnterpriseServiceRequest", [sessionContext, serviceRequest]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public addFinancingServiceRequestToMatchCoops(sessionContext: (SessionContext | null), financingServiceRequest: (FinancingServiceRequest | null), pageAccessSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<CoopUserAdView>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "addFinancingServiceRequestToMatchCoops", [sessionContext, financingServiceRequest, pageAccessSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public assignToWorker(sessionContext: (SessionContext | null), requestId: (number | string | null), enterpriseId: (number | string | null), workerId: (number | string | null), resultHandler: BiConsumer<ServingEntityInfos, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "assignToWorker", [sessionContext, requestId, enterpriseId, workerId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public cancelEnterpriseServiceRequestById(sessionContext: (SessionContext | null), requestId: (number | string | null), resultHandler: BiConsumer<RequestStatusType, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "cancelEnterpriseServiceRequestById", [sessionContext, requestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public completeServiceRequest(sessionContext: (SessionContext | null), requestId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "completeServiceRequest", [sessionContext, requestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public confirmServiceRequestCompleted(sessionContext: (SessionContext | null), requestId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "confirmServiceRequestCompleted", [sessionContext, requestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public delegateToAssistant(sessionContext: (SessionContext | null), requestId: (number | string | null), assistantId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "delegateToAssistant", [sessionContext, requestId, assistantId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public delegateToCustomerServiceAssistant(sessionContext: (SessionContext | null), requestId: (number | string | null), assistEnterpriseId: (number | string | null), assistantId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "delegateToCustomerServiceAssistant", [sessionContext, requestId, assistEnterpriseId, assistantId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public enterMessageSessionOnServiceRequest(sessionContext: (SessionContext | null), serviceRequestId: (number | string | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "enterMessageSessionOnServiceRequest", [sessionContext, serviceRequestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findActiveCustomerServiceRequestByUserIdAndServiceType(sessionContext: (SessionContext | null), userId: (number | string | null), serviceType: (EnterpriseServiceType | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findActiveCustomerServiceRequestByUserIdAndServiceType", [sessionContext, userId, serviceType]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findActiveCustomerServiceRequestByUserIdAndServiceTypeName(sessionContext: (SessionContext | null), userId: (number | string | null), serviceTypeName: (string | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findActiveCustomerServiceRequestByUserIdAndServiceTypeName", [sessionContext, userId, serviceTypeName]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findAllCustomerServiceRequests(sessionContext: (SessionContext | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findAllCustomerServiceRequests", [sessionContext, serviceType, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findAllCustomerServiceRequestsByClassifier(sessionContext: (SessionContext | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findAllCustomerServiceRequestsByClassifier", [sessionContext, serviceClassifier, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findAllCustomerServiceRequestsByServiceTypeName(sessionContext: (SessionContext | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findAllCustomerServiceRequestsByServiceTypeName", [sessionContext, serviceTypeName, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findClassifiedCustomerServiceRequestsByCustomerServiceWorker(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findClassifiedCustomerServiceRequestsByCustomerServiceWorker", [sessionContext, entityId, serviceClassifier, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findClassifiedEnterpriseServiceRequestsByCustomerService(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findClassifiedEnterpriseServiceRequestsByCustomerService", [sessionContext, entityId, serviceClassifier, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findClassifiedEnterpriseServiceRequestsByServingEntity(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findClassifiedEnterpriseServiceRequestsByServingEntity", [sessionContext, entityId, serviceClassifier, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findClassifiedEnterpriseServiceRequestsByServingManager(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findClassifiedEnterpriseServiceRequestsByServingManager", [sessionContext, entityId, serviceClassifier, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findClassifiedEnterpriseServiceRequestsByServingWorker(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findClassifiedEnterpriseServiceRequestsByServingWorker", [sessionContext, entityId, serviceClassifier, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findClassifiedEnterpriseServiceRequestsForRegion(sessionContext: (SessionContext | null), regionId: (number | string | null), serviceClassifier: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findClassifiedEnterpriseServiceRequestsForRegion", [sessionContext, regionId, serviceClassifier, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findCustomerServiceAssistants(sessionContext: (SessionContext | null), serviceRequestId: (number | string | null), resultHandler: BiConsumer<Array<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findCustomerServiceAssistants", [sessionContext, serviceRequestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findCustomerServiceRequestsByClientEntity(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findCustomerServiceRequestsByClientEntity", [sessionContext, entityId, serviceType, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findCustomerServiceRequestsByClientEntityAndServiceTypeName(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findCustomerServiceRequestsByClientEntityAndServiceTypeName", [sessionContext, entityId, serviceTypeName, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findCustomerServiceRequestsByCustomerServiceWorker(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findCustomerServiceRequestsByCustomerServiceWorker", [sessionContext, entityId, serviceType, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findCustomerServiceRequestsByCustomerServiceWorkerAndServiceTypeName(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findCustomerServiceRequestsByCustomerServiceWorkerAndServiceTypeName", [sessionContext, entityId, serviceTypeName, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceProfessionalsToReassign(sessionContext: (SessionContext | null), serviceRequestId: (number | string | null), resultHandler: BiConsumer<Array<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceProfessionalsToReassign", [sessionContext, serviceRequestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByClientEntity(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByClientEntity", [sessionContext, entityId, serviceType, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByClientEntityAndServiceTypeName(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByClientEntityAndServiceTypeName", [sessionContext, entityId, serviceTypeName, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByCustomerService(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByCustomerService", [sessionContext, entityId, serviceType, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByCustomerServiceAndServiceTypeName(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByCustomerServiceAndServiceTypeName", [sessionContext, entityId, serviceTypeName, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByServiceTypeNameForRegion(sessionContext: (SessionContext | null), regionId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByServiceTypeNameForRegion", [sessionContext, regionId, serviceTypeName, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByServingEntity(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByServingEntity", [sessionContext, entityId, serviceType, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByServingEntityAndServiceTypeName(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByServingEntityAndServiceTypeName", [sessionContext, entityId, serviceTypeName, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByServingManager(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByServingManager", [sessionContext, entityId, serviceType, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByServingManagerAndServiceTypeName(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByServingManagerAndServiceTypeName", [sessionContext, entityId, serviceTypeName, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByServingWorker(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByServingWorker", [sessionContext, entityId, serviceType, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsByServingWorkerAndServiceTypeName(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceTypeName: (string | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsByServingWorkerAndServiceTypeName", [sessionContext, entityId, serviceTypeName, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceRequestsForRegion(sessionContext: (SessionContext | null), regionId: (number | string | null), serviceType: (EnterpriseServiceType | null), statusClass: (RequestStatusClassifier | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EnterpriseServiceRequestInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findEnterpriseServiceRequestsForRegion", [sessionContext, regionId, serviceType, statusClass, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findServiceEnterprisesOnServiceRequest(sessionContext: (SessionContext | null), serviceRequestId: (number | string | null), tradableId: (number | string | null), tradeId: (number | string | null), pageBlockAccessSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findServiceEnterprisesOnServiceRequest", [sessionContext, serviceRequestId, tradableId, tradeId, pageBlockAccessSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findServiceProfessionalsOnServiceRequest(sessionContext: (SessionContext | null), serviceRequestId: (number | string | null), tradableId: (number | string | null), tradeId: (number | string | null), enterpriseId: (number | string | null), pageBlockAccessSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<GeoEntityDesc>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "findServiceProfessionalsOnServiceRequest", [sessionContext, serviceRequestId, tradableId, tradeId, enterpriseId, pageBlockAccessSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getAndRequestToAssignEnterpriseServiceRequestById(sessionContext: (SessionContext | null), requestId: (number | string | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getAndRequestToAssignEnterpriseServiceRequestById", [sessionContext, requestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getCustomerServiceMonitorStats(sessionContext: (SessionContext | null), entityId: (number | string | null), resultHandler: BiConsumer<EntityServiceStats, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getCustomerServiceMonitorStats", [sessionContext, entityId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getCustomerServiceStats(sessionContext: (SessionContext | null), entityId: (number | string | null), resultHandler: BiConsumer<EntityServiceStats, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getCustomerServiceStats", [sessionContext, entityId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEnterpriseServiceRequestById(sessionContext: (SessionContext | null), requestId: (number | string | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getEnterpriseServiceRequestById", [sessionContext, requestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getFinancingServiceRequestClassifier(sessionContext: (SessionContext | null), resultHandler: BiConsumer<FinancingServiceRequestClassifier, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getFinancingServiceRequestClassifier", [sessionContext]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getMessagesOnServiceRequest(sessionContext: (SessionContext | null), serviceRequestId: (number | string | null), pageBlockAccessSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<EntityMessage>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getMessagesOnServiceRequest", [sessionContext, serviceRequestId, pageBlockAccessSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getOrCreateExclusiveCustomerServiceSessionByOrderId(sessionContext: (SessionContext | null), orderId: (number | string | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getOrCreateExclusiveCustomerServiceSessionByOrderId", [sessionContext, orderId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getOrCreateExclusiveCustomerServiceSessionByTradableId(sessionContext: (SessionContext | null), tradableId: (number | string | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getOrCreateExclusiveCustomerServiceSessionByTradableId", [sessionContext, tradableId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getOrCreateExclusiveCustomerServiceSessionByTradeId(sessionContext: (SessionContext | null), tradeId: (number | string | null), resultHandler: BiConsumer<EnterpriseServiceRequest, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getOrCreateExclusiveCustomerServiceSessionByTradeId", [sessionContext, tradeId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getServiceProfessionalsByEnterpriseId(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceType: (EnterpriseServiceType | null), resultHandler: BiConsumer<Array<AssociatedEntityInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getServiceProfessionalsByEnterpriseId", [sessionContext, entityId, serviceType]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getServiceProfessionalsByEnterpriseIdAndServiceTypeName(sessionContext: (SessionContext | null), entityId: (number | string | null), serviceTypeName: (string | null), resultHandler: BiConsumer<Array<AssociatedEntityInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getServiceProfessionalsByEnterpriseIdAndServiceTypeName", [sessionContext, entityId, serviceTypeName]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getServiceRequestClassifierByServiceType(sessionContext: (SessionContext | null), serviceType: (EnterpriseServiceType | null), forEnterprise: (boolean | null), resultHandler: BiConsumer<ServiceRequestClassifier, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getServiceRequestClassifierByServiceType", [sessionContext, serviceType, forEnterprise]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getServiceRequestClassifierByServiceTypeName(sessionContext: (SessionContext | null), serviceTypeName: (string | null), forEnterprise: (boolean | null), resultHandler: BiConsumer<ServiceRequestClassifier, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getServiceRequestClassifierByServiceTypeName", [sessionContext, serviceTypeName, forEnterprise]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getServingEntityServiceStats(sessionContext: (SessionContext | null), entityId: (number | string | null), resultHandler: BiConsumer<EntityServiceStats, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "getServingEntityServiceStats", [sessionContext, entityId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public joinActivityOnServiceRequest(sessionContext: (SessionContext | null), serviceRequest: (EnterpriseServiceRequest | null), resultHandler: BiConsumer<EntityMessageResponse, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "joinActivityOnServiceRequest", [sessionContext, serviceRequest]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public openMessageSessionOnServiceRequest(sessionContext: (SessionContext | null), serviceRequestId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "openMessageSessionOnServiceRequest", [sessionContext, serviceRequestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public payEnterpriseServiceRequest(sessionContext: (SessionContext | null), requestId: (number | string | null), paymentSpec: (PaymentSpec | null), resultHandler: BiConsumer<EnterpriseServiceOrder, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "payEnterpriseServiceRequest", [sessionContext, requestId, paymentSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public reassignToWorker(sessionContext: (SessionContext | null), requestId: (number | string | null), enterpriseId: (number | string | null), workerId: (number | string | null), resultHandler: BiConsumer<ServingEntityInfos, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "reassignToWorker", [sessionContext, requestId, enterpriseId, workerId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public rejectByServiceProvider(sessionContext: (SessionContext | null), requestId: (number | string | null), resultHandler: BiConsumer<RequestStatusType, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "rejectByServiceProvider", [sessionContext, requestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public rejectDelegateByServiceWorker(sessionContext: (SessionContext | null), requestId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "rejectDelegateByServiceWorker", [sessionContext, requestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public requestToReassignServiceProvider(sessionContext: (SessionContext | null), requestId: (number | string | null), resultHandler: BiConsumer<ServingEntityInfos, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "requestToReassignServiceProvider", [sessionContext, requestId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public selectServiceRequestWorker(sessionContext: (SessionContext | null), requestId: (number | string | null), enterpriseId: (number | string | null), workerId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceRequestService", "selectServiceRequestWorker", [sessionContext, requestId, enterpriseId, workerId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

}
