// @ts-nocheck
import { AbstractServiceOpMessage } from "./AbstractServiceOpMessage";
import { NotificationType } from "@/services/datatype/ipsphere/NotificationType";

export class ServiceOpMessage extends AbstractServiceOpMessage {
  constructor() {
    super();
  }

  public isForServiceRequest(): boolean {
    if (!this.data) {
      return fasle;
    }
    return this.data.getNotificationType() == NotificationType.SERVICE_REQUEST;
  }
}
