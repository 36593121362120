/**
 * 自动生成， 请不要手工修改！
 */

export enum AwardOrder {
  FIRST_PRIZE,
  SECOND_PRIZE,
  THIRD_PRIZE,
  GENERAL_PRIZE,
}
