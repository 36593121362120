/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {SettlementAccountInfo} from './SettlementAccountInfo'
import {SettlementRuleInfo} from './SettlementRuleInfo'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class SettlementInfo extends AbstractSerializable {
    settlementRuleInfo: SettlementRuleInfo;
    settlementAccountInfo: SettlementAccountInfo;

    constructor() {
        super();
    }

    public getSettlementRuleInfo(): SettlementRuleInfo {
        return this.settlementRuleInfo;
    }

    public setSettlementRuleInfo(settlementRuleInfo: SettlementRuleInfo) {
        this.settlementRuleInfo = settlementRuleInfo;
    }

    public getSettlementAccountInfo(): SettlementAccountInfo {
        return this.settlementAccountInfo;
    }

    public setSettlementAccountInfo(settlementAccountInfo: SettlementAccountInfo) {
        this.settlementAccountInfo = settlementAccountInfo;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let settlementRuleInfo = json["settlementRuleInfo"];
        if (settlementRuleInfo != null) {
            let convertedSettlementRuleInfo0 = new SettlementRuleInfo();
            convertedSettlementRuleInfo0.fillFromJson(settlementRuleInfo, factory);
            this.setSettlementRuleInfo(convertedSettlementRuleInfo0);
        }
        let settlementAccountInfo = json["settlementAccountInfo"];
        if (settlementAccountInfo != null) {
            let convertedSettlementAccountInfo0 = new SettlementAccountInfo();
            convertedSettlementAccountInfo0.fillFromJson(settlementAccountInfo, factory);
            this.setSettlementAccountInfo(convertedSettlementAccountInfo0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let settlementRuleInfo = this.getSettlementRuleInfo();
        if (settlementRuleInfo != null) {
            let convertedSettlementRuleInfo0 = settlementRuleInfo.getJson(JsonTypeTagType.NONE);
            json["settlementRuleInfo"] = convertedSettlementRuleInfo0;
        }
        let settlementAccountInfo = this.getSettlementAccountInfo();
        if (settlementAccountInfo != null) {
            let convertedSettlementAccountInfo0 = settlementAccountInfo.getJson(JsonTypeTagType.NONE);
            json["settlementAccountInfo"] = convertedSettlementAccountInfo0;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.business.service.settlement.SettlementInfo";
    }
}
