/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class SimpleOperationResult extends AbstractSerializable {
  succeeded: boolean;
  objectId: number | string;
  failureReason: string;

  constructor() {
    super();
  }

  public isSucceeded(): boolean {
    return this.succeeded;
  }

  public setSucceeded(succeeded: boolean) {
    this.succeeded = succeeded;
  }

  public getObjectId(): number | string {
    return this.objectId;
  }

  public setObjectId(objectId: number | string) {
    this.objectId = objectId;
  }

  public getFailureReason(): string {
    return this.failureReason;
  }

  public setFailureReason(failureReason: string) {
    this.failureReason = failureReason;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const succeeded = json["succeeded"];
    if (succeeded != null) {
      this.setSucceeded(succeeded);
    }
    const objectId = json["objectId"];
    if (objectId != null) {
      this.setObjectId(objectId);
    }
    const failureReason = json["failureReason"];
    if (failureReason != null) {
      this.setFailureReason(failureReason);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const succeeded = this.isSucceeded();
    if (succeeded != null) {
      json["succeeded"] = succeeded;
    }
    const objectId = this.getObjectId();
    if (objectId != null) {
      json["objectId"] = String(objectId);
    }
    const failureReason = this.getFailureReason();
    if (failureReason != null) {
      json["failureReason"] = failureReason;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.service.api.datatype.SimpleOperationResult";
  }
}
