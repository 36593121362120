/**
 * Created by Zhonghua on 27/11/2016.
 */
import { DefaultSessionContext } from "../session/DefaultSessionContext";
import { SessionContext } from "../session/SessionContext";
import { qllStorageSession } from "@/services/util/StorageUtils";
import UserTools from "@/services/session/UserTools";
import { SESSION_TOKEN } from "@/constants/SessionDataKeys";
import { AppType } from "@/services/common/config/AppType";

export class WebSessionManager {
  private static sessionToken: string | null;

  public static getSessionToken(): string | null | undefined {
    if (!WebSessionManager.sessionToken) {
      WebSessionManager.sessionToken = qllStorageSession.getItem(SESSION_TOKEN);
    }
    return WebSessionManager.sessionToken;
  }

  public static setSessionToken(sessionToken: string) {
    WebSessionManager.sessionToken = sessionToken;
    if (sessionToken) {
      qllStorageSession.setItem(SESSION_TOKEN, sessionToken);
    } else {
      qllStorageSession.removeItem(SESSION_TOKEN);
    }
  }

  public static getSessionContext(): SessionContext {
    const session = new DefaultSessionContext();
    session.appId = AppType[AppType.QLL_WORK_WEB];
    session.setSessionToken(WebSessionManager.getSessionToken());
    return session;
  }
}
