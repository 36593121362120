/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EndorsementType} from './EndorsementType'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {ProfileAspectType} from './ProfileAspectType'
import {ProfileAspect} from './ProfileAspect'
import {RequestStatusType} from '../../request/RequestStatusType'
import {TextSource} from '../../../message/entity/api/TextSource'
import {TextType} from '../../TextType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractOfficialEndorsementProfileAspect extends AbstractSerializable implements ProfileAspect, TextSource {
    entityId: (number | string);
    endorsementType: EndorsementType;
    endorsementId: string;
    regionId: (number | string);
    endorsementUrl: string;
    reviewStatus: RequestStatusType;

    constructor() {
        super();
    }

    public getEntityId(): (number | string) {
        return this.entityId;
    }

    public setEntityId(entityId: (number | string)) {
        this.entityId = entityId;
    }

    public getEndorsementType(): EndorsementType {
        return this.endorsementType;
    }

    public setEndorsementType(endorsementType: EndorsementType) {
        this.endorsementType = endorsementType;
    }

    public getEndorsementId(): string {
        return this.endorsementId;
    }

    public setEndorsementId(endorsementId: string) {
        this.endorsementId = endorsementId;
    }

    public getRegionId(): (number | string) {
        return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
        this.regionId = regionId;
    }

    public getEndorsementUrl(): string {
        return this.endorsementUrl;
    }

    public setEndorsementUrl(endorsementUrl: string) {
        this.endorsementUrl = endorsementUrl;
    }

    public getReviewStatus(): RequestStatusType {
        return this.reviewStatus;
    }

    public setReviewStatus(reviewStatus: RequestStatusType) {
        this.reviewStatus = reviewStatus;
    }


    abstract getProfileAspectType(): ProfileAspectType;

    abstract getTextType(): TextType;

    abstract getText(): string;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let entityId = json["entityId"];
        if (entityId != null) {
            this.setEntityId(entityId);
        }
        let endorsementType = json["endorsementType"];
        if (endorsementType != null) {
            let convertedEndorsementType0 = EndorsementType[<string>endorsementType];
            this.setEndorsementType(convertedEndorsementType0);
        }
        let endorsementId = json["endorsementId"];
        if (endorsementId != null) {
            this.setEndorsementId(endorsementId);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let endorsementUrl = json["endorsementUrl"];
        if (endorsementUrl != null) {
            this.setEndorsementUrl(endorsementUrl);
        }
        let reviewStatus = json["reviewStatus"];
        if (reviewStatus != null) {
            let convertedReviewStatus0 = RequestStatusType[<string>reviewStatus];
            this.setReviewStatus(convertedReviewStatus0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let entityId = this.getEntityId();
        if (entityId != null) {
            json["entityId"] = String(entityId);
        }
        let endorsementType = this.getEndorsementType();
        if (endorsementType != null) {
            let convertedEndorsementType0 = EndorsementType[endorsementType];
            json["endorsementType"] = convertedEndorsementType0;
        }
        let endorsementId = this.getEndorsementId();
        if (endorsementId != null) {
            json["endorsementId"] = endorsementId;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let endorsementUrl = this.getEndorsementUrl();
        if (endorsementUrl != null) {
            json["endorsementUrl"] = endorsementUrl;
        }
        let reviewStatus = this.getReviewStatus();
        if (reviewStatus != null) {
            let convertedReviewStatus0 = RequestStatusType[reviewStatus];
            json["reviewStatus"] = convertedReviewStatus0;
        }

        if (includeTypeTag) {
            json["__type"] = "OfficialEndorsementProfileAspect";
        }
    }


    public getTypeId(): string {
        return "OfficialEndorsementProfileAspect";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.profile.OfficialEndorsementProfileAspect";
    }
}
