/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class InvoiceRequest extends AbstractSerializable {
  requestId: number | string;
  orderId: number | string;
  taxCode: string;
  address: string;
  email: string;
  mobile: string;
  createTime: number | string;
  invoicedTime: number | string;

  constructor() {
    super();
  }

  public getRequestId(): number | string {
    return this.requestId;
  }

  public setRequestId(requestId: number | string) {
    this.requestId = requestId;
  }

  public getOrderId(): number | string {
    return this.orderId;
  }

  public setOrderId(orderId: number | string) {
    this.orderId = orderId;
  }

  public getTaxCode(): string {
    return this.taxCode;
  }

  public setTaxCode(taxCode: string) {
    this.taxCode = taxCode;
  }

  public getAddress(): string {
    return this.address;
  }

  public setAddress(address: string) {
    this.address = address;
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(email: string) {
    this.email = email;
  }

  public getMobile(): string {
    return this.mobile;
  }

  public setMobile(mobile: string) {
    this.mobile = mobile;
  }

  public getCreateTime(): number | string {
    return this.createTime;
  }

  public setCreateTime(createTime: number | string) {
    this.createTime = createTime;
  }

  public getInvoicedTime(): number | string {
    return this.invoicedTime;
  }

  public setInvoicedTime(invoicedTime: number | string) {
    this.invoicedTime = invoicedTime;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const requestId = json["requestId"];
    if (requestId != null) {
      this.setRequestId(requestId);
    }
    const orderId = json["orderId"];
    if (orderId != null) {
      this.setOrderId(orderId);
    }
    const taxCode = json["taxCode"];
    if (taxCode != null) {
      this.setTaxCode(taxCode);
    }
    const address = json["address"];
    if (address != null) {
      this.setAddress(address);
    }
    const email = json["email"];
    if (email != null) {
      this.setEmail(email);
    }
    const mobile = json["mobile"];
    if (mobile != null) {
      this.setMobile(mobile);
    }
    const createTime = json["createTime"];
    if (createTime != null) {
      this.setCreateTime(createTime);
    }
    const invoicedTime = json["invoicedTime"];
    if (invoicedTime != null) {
      this.setInvoicedTime(invoicedTime);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const requestId = this.getRequestId();
    if (requestId != null) {
      json["requestId"] = String(requestId);
    }
    const orderId = this.getOrderId();
    if (orderId != null) {
      json["orderId"] = String(orderId);
    }
    const taxCode = this.getTaxCode();
    if (taxCode != null) {
      json["taxCode"] = taxCode;
    }
    const address = this.getAddress();
    if (address != null) {
      json["address"] = address;
    }
    const email = this.getEmail();
    if (email != null) {
      json["email"] = email;
    }
    const mobile = this.getMobile();
    if (mobile != null) {
      json["mobile"] = mobile;
    }
    const createTime = this.getCreateTime();
    if (createTime != null) {
      json["createTime"] = String(createTime);
    }
    const invoicedTime = this.getInvoicedTime();
    if (invoicedTime != null) {
      json["invoicedTime"] = String(invoicedTime);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.order.InvoiceRequest";
  }
}
