/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EnterpriseServiceRequestInfo} from '../datatype/business/service/request/EnterpriseServiceRequestInfo'
import {EnterpriseServiceSettlementService} from '../api/business/service/EnterpriseServiceSettlementService'
import {PageableData} from '../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {SettlementAccountInfo} from '../datatype/business/service/settlement/SettlementAccountInfo'
import {SettlementBillAdjustRequest} from '../datatype/business/service/settlement/SettlementBillAdjustRequest'
import {SettlementBillInfo} from '../datatype/business/service/settlement/SettlementBillInfo'
import {SettlementBillSyncStatus} from '../datatype/business/service/settlement/SettlementBillSyncStatus'
import {SettlementInfo} from '../datatype/business/service/settlement/SettlementInfo'
import {WebEnterpriseServiceSettlementService} from '../webapi/WebEnterpriseServiceSettlementService'
import {WebSessionManager} from '../common/WebSessionManager'


export class WebEnterpriseServiceSettlementServiceImpl implements WebEnterpriseServiceSettlementService {

    private enterpriseServiceSettlementService: EnterpriseServiceSettlementService;

    public constructor() {
        this.enterpriseServiceSettlementService = DefaultServiceFactory.getInstance().getEnterpriseServiceSettlementService();
    }

    public addSettlementAccountInfo(settlementAccountInfo: SettlementAccountInfo): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.addSettlementAccountInfo(WebSessionManager.getSessionContext(),
                settlementAccountInfo,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public addSettlementBillInfo(settlementBillInfo: SettlementBillInfo): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.addSettlementBillInfo(WebSessionManager.getSessionContext(),
                settlementBillInfo,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public confirmBillById(enterpriseId: (number | string | null), workerId: (number | string | null), seasonId: (number | string | null), billId: (number | string | null)): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.confirmBillById(WebSessionManager.getSessionContext(),
                enterpriseId, workerId, seasonId, billId,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public findEnterpriseServiceRequestsToSettleByPeriod(period: (number | null), enterpriseId: (number | string | null), workerId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.findEnterpriseServiceRequestsToSettleByPeriod(WebSessionManager.getSessionContext(),
                period, enterpriseId, workerId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public findSettledEnterpriseServiceRequestsByPeriod(period: (number | null), enterpriseId: (number | string | null), workerId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<EnterpriseServiceRequestInfo>> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.findSettledEnterpriseServiceRequestsByPeriod(WebSessionManager.getSessionContext(),
                period, enterpriseId, workerId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getEnterpriseServiceSettlementInfo(enterpriseId: (number | string | null), workerId: (number | string | null)): Promise<SettlementInfo> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.getEnterpriseServiceSettlementInfo(WebSessionManager.getSessionContext(),
                enterpriseId, workerId,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getSettlementBillInfoById(billId: (number | string | null)): Promise<SettlementBillInfo> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.getSettlementBillInfoById(WebSessionManager.getSessionContext(),
                billId,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getSettlementBillInfoBySeasonId(enterpriseId: (number | string | null), workerId: (number | string | null), seasonId: (number | string | null)): Promise<SettlementBillInfo> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.getSettlementBillInfoBySeasonId(WebSessionManager.getSessionContext(),
                enterpriseId, workerId, seasonId,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getSettlementBillInfos(enterpriseId: (number | string), workerId: (number | string), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<SettlementBillInfo>> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.getSettlementBillInfos(WebSessionManager.getSessionContext(),
                enterpriseId, workerId, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public getSettlementBillInfosBySyncStatus(enterpriseId: (number | string | null), workerId: (number | string | null), syncStatus: (SettlementBillSyncStatus | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<SettlementBillInfo>> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.getSettlementBillInfosBySyncStatus(WebSessionManager.getSessionContext(),
                enterpriseId, workerId, syncStatus, pageBlockSpec,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public requestToAdjustBill(billAdjustRequest: SettlementBillAdjustRequest): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.requestToAdjustBill(WebSessionManager.getSessionContext(),
                billAdjustRequest,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

    public updateSettlementAccountInfo(settlementAccountInfo: SettlementAccountInfo): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.enterpriseServiceSettlementService.updateSettlementAccountInfo(WebSessionManager.getSessionContext(),
                settlementAccountInfo,
                (t, u) => {
                    if (u) {
                        reject(u);
                    } else {
                        resolve(t);
                    }
                });
        });
    }

}
