/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {EntityInfo} from '../EntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class WorkerInfo extends AbstractSerializable  {
    netizenId: (number | string);
    serviceTime: string;
    enterpriseInfo: EntityInfo;
    employeeId: string;
    position: string;
    entWeChatUrl: string;
    certified: boolean;
    createTime: (number | string);
    recipientName: string;
    recipientMobile: string;
    recipientAddress: string;

    constructor() {
    	super();
    }

    public getNetizenId(): (number | string) {
    	return this.netizenId;
    }

    public setNetizenId(netizenId: (number | string)) {
    	this.netizenId = netizenId;
    }

    public getServiceTime(): string {
    	return this.serviceTime;
    }

    public setServiceTime(serviceTime: string) {
    	this.serviceTime = serviceTime;
    }

    public getEnterpriseInfo(): EntityInfo {
    	return this.enterpriseInfo;
    }

    public setEnterpriseInfo(enterpriseInfo: EntityInfo) {
    	this.enterpriseInfo = enterpriseInfo;
    }

    public getEmployeeId(): string {
    	return this.employeeId;
    }

    public setEmployeeId(employeeId: string) {
    	this.employeeId = employeeId;
    }

    public getPosition(): string {
    	return this.position;
    }

    public setPosition(position: string) {
    	this.position = position;
    }

    public getEntWeChatUrl(): string {
    	return this.entWeChatUrl;
    }

    public setEntWeChatUrl(entWeChatUrl: string) {
    	this.entWeChatUrl = entWeChatUrl;
    }

    public isCertified(): boolean {
    	return this.certified;
    }

    public setCertified(certified: boolean) {
    	this.certified = certified;
    }

    public getCreateTime(): (number | string) {
    	return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
    	this.createTime = createTime;
    }

    public getRecipientName(): string {
    	return this.recipientName;
    }

    public setRecipientName(recipientName: string) {
    	this.recipientName = recipientName;
    }

    public getRecipientMobile(): string {
    	return this.recipientMobile;
    }

    public setRecipientMobile(recipientMobile: string) {
    	this.recipientMobile = recipientMobile;
    }

    public getRecipientAddress(): string {
    	return this.recipientAddress;
    }

    public setRecipientAddress(recipientAddress: string) {
    	this.recipientAddress = recipientAddress;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let netizenId = json["netizenId"];
        if (netizenId != null) {
            this.setNetizenId(netizenId);
        }
        let serviceTime = json["serviceTime"];
        if (serviceTime != null) {
            this.setServiceTime(serviceTime);
        }
        let enterpriseInfo = json["enterpriseInfo"];
        if (enterpriseInfo != null) {
            let convertedEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(enterpriseInfo, "__type", factory);
            this.setEnterpriseInfo(convertedEnterpriseInfo0);
        }
        let employeeId = json["employeeId"];
        if (employeeId != null) {
            this.setEmployeeId(employeeId);
        }
        let position = json["position"];
        if (position != null) {
            this.setPosition(position);
        }
        let entWeChatUrl = json["entWeChatUrl"];
        if (entWeChatUrl != null) {
            this.setEntWeChatUrl(entWeChatUrl);
        }
        let certified = json["certified"];
        if (certified != null) {
            this.setCertified(certified);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let recipientName = json["recipientName"];
        if (recipientName != null) {
            this.setRecipientName(recipientName);
        }
        let recipientMobile = json["recipientMobile"];
        if (recipientMobile != null) {
            this.setRecipientMobile(recipientMobile);
        }
        let recipientAddress = json["recipientAddress"];
        if (recipientAddress != null) {
            this.setRecipientAddress(recipientAddress);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let netizenId = this.getNetizenId();
        if (netizenId != null) {
            json["netizenId"] = String(netizenId);
        }
        let serviceTime = this.getServiceTime();
        if (serviceTime != null) {
            json["serviceTime"] = serviceTime;
        }
        let enterpriseInfo = this.getEnterpriseInfo();
        if (enterpriseInfo != null) {
            let convertedEnterpriseInfo0 = enterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["enterpriseInfo"] = convertedEnterpriseInfo0;
        }
        let employeeId = this.getEmployeeId();
        if (employeeId != null) {
            json["employeeId"] = employeeId;
        }
        let position = this.getPosition();
        if (position != null) {
            json["position"] = position;
        }
        let entWeChatUrl = this.getEntWeChatUrl();
        if (entWeChatUrl != null) {
            json["entWeChatUrl"] = entWeChatUrl;
        }
        let certified = this.isCertified();
        if (certified != null) {
            json["certified"] = certified;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let recipientName = this.getRecipientName();
        if (recipientName != null) {
            json["recipientName"] = recipientName;
        }
        let recipientMobile = this.getRecipientMobile();
        if (recipientMobile != null) {
            json["recipientMobile"] = recipientMobile;
        }
        let recipientAddress = this.getRecipientAddress();
        if (recipientAddress != null) {
            json["recipientAddress"] = recipientAddress;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.user.WorkerInfo";
    }
}
