/**
 * Created by Zhonghua on 27/11/2016.
 */
import { EventRequest } from "../common/EventRequest";
import { ServiceCancelRequest } from "../common/ServiceCancelRequest";
import { ServiceRequest } from "../common/ServiceRequest";
import { ServiceResponse } from "../common/ServiceResponse";
import { DefaultSessionContext } from "../session/DefaultSessionContext";
import { MockSessionContext } from "../session/MockSessionContext";
import { Map } from "../util/Map";

export class ManualTypeFactory {
  public static createTypeMap(): Map<string, () => any> {
    const typeMap = new Map<string, () => any>();

    // 会话
    typeMap.set("MockSessionContext", () => new MockSessionContext());
    typeMap.set("DefaultSessionContext", () => new DefaultSessionContext());

    // 请求和反应
    typeMap.set("Service", () => new ServiceRequest());
    typeMap.set("ServiceResponse", () => new ServiceResponse());
    typeMap.set("Event", () => new EventRequest());
    typeMap.set("ServiceCancel", () => new ServiceCancelRequest());

    return typeMap;
  }
}
