import { DefaultWebServiceFactory } from "@/services/webapi/DefaultWebServiceFactory";
import order from "./order";
import UserTools from "@/services/session/UserTools";
// 设置已读
const setRead = (orderId: number | string) =>
  DefaultWebServiceFactory.getInstance()
    .getWebMessageService()
    .setReadMessage(orderId);
// 通知转聊天生成路径
const MessageToTalk = async (
  requestId: number | string,
  orderId: number | string | null
) => {
  if (!orderId) {
    return {
      path: "/layout/home",
      query: {
        index: 0,
      },
    };
  } else {
    console.log (requestId)
    const getOrder = await order.getOrder(requestId);
    await order.acceptOrder(requestId as number);
    return {
      path: "/layout/talk",
      query: {
        rid: requestId,
        code:
          getOrder.servingProfessionalInfo.entityId == UserTools.getUserId()
            ? "true"
            : "false",
        breadType: "0",
      },
    };
  }
};
export default { setRead, MessageToTalk };
