/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { CoworkUserInfo } from "./CoworkUserInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { REPrizeStatus } from "../redenvelope/REPrizeStatus";
import { SectorTopicConfig } from "../entity/sector/SectorTopicConfig";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractEntityUserInfo
  extends AbstractSerializable
  implements CoworkUserInfo
{
  subscribed: boolean;
  approve: boolean;
  totalPoints: number;
  currentPoints: number;
  totalPrize: number;
  projectedPrize: number;
  sectorTopicConfig: SectorTopicConfig;

  constructor() {
    super();
  }

  public isSubscribed(): boolean {
    return this.subscribed;
  }

  public setSubscribed(subscribed: boolean) {
    this.subscribed = subscribed;
  }

  public isApprove(): boolean {
    return this.approve;
  }

  public setApprove(approve: boolean) {
    this.approve = approve;
  }

  public getTotalPoints(): number {
    return this.totalPoints;
  }

  public setTotalPoints(totalPoints: number) {
    this.totalPoints = totalPoints;
  }

  public getCurrentPoints(): number {
    return this.currentPoints;
  }

  public setCurrentPoints(currentPoints: number) {
    this.currentPoints = currentPoints;
  }

  public getTotalPrize(): number {
    return this.totalPrize;
  }

  public setTotalPrize(totalPrize: number) {
    this.totalPrize = totalPrize;
  }

  public getProjectedPrize(): number {
    return this.projectedPrize;
  }

  public setProjectedPrize(projectedPrize: number) {
    this.projectedPrize = projectedPrize;
  }

  public getSectorTopicConfig(): SectorTopicConfig {
    return this.sectorTopicConfig;
  }

  public setSectorTopicConfig(sectorTopicConfig: SectorTopicConfig) {
    this.sectorTopicConfig = sectorTopicConfig;
  }

  abstract getApprove(): boolean;

  abstract isActive(): boolean;

  abstract isManager(): boolean;

  abstract isPulled(): boolean;

  abstract getPrizeStatus(): REPrizeStatus;

  abstract getPrizable(): boolean;

  abstract getApproverPrize(): number;

  abstract getCreatorPrize(): number;

  abstract getRelApproverPrize(): number;

  abstract getRelCreatorPrize(): number;

  abstract addApproverPrize(arg0: number | null);

  abstract addCreatorPrize(arg0: number | null);

  abstract setActive(active: boolean | null);

  abstract setApproverPrize(approverPrize: number | null);

  abstract setCreatorPrize(creatorPrize: number | null);

  abstract setManager(arg0: boolean | null);

  abstract setPrizable(prizable: boolean | null);

  abstract setPrizeStatus(arg0: REPrizeStatus | null);

  abstract setPulled();

  abstract setRelCreatorPrize(relCreatorPrize: number | null);

  abstract update(coworkUserInfo: CoworkUserInfo | null);

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const subscribed = json["subscribed"];
    if (subscribed != null) {
      this.setSubscribed(subscribed);
    }
    const approve = json["approve"];
    if (approve != null) {
      this.setApprove(approve);
    }
    const totalPoints = json["totalPoints"];
    if (totalPoints != null) {
      this.setTotalPoints(totalPoints);
    }
    const currentPoints = json["currentPoints"];
    if (currentPoints != null) {
      this.setCurrentPoints(currentPoints);
    }
    const totalPrize = json["totalPrize"];
    if (totalPrize != null) {
      this.setTotalPrize(totalPrize);
    }
    const projectedPrize = json["projectedPrize"];
    if (projectedPrize != null) {
      this.setProjectedPrize(projectedPrize);
    }
    const sectorTopicConfig = json["sectorTopicConfig"];
    if (sectorTopicConfig != null) {
      const convertedSectorTopicConfig0 = new SectorTopicConfig();
      convertedSectorTopicConfig0.fillFromJson(sectorTopicConfig, factory);
      this.setSectorTopicConfig(convertedSectorTopicConfig0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const subscribed = this.isSubscribed();
    if (subscribed != null) {
      json["subscribed"] = subscribed;
    }
    const approve = this.isApprove();
    if (approve != null) {
      json["approve"] = approve;
    }
    const totalPoints = this.getTotalPoints();
    if (totalPoints != null) {
      json["totalPoints"] = totalPoints;
    }
    const currentPoints = this.getCurrentPoints();
    if (currentPoints != null) {
      json["currentPoints"] = currentPoints;
    }
    const totalPrize = this.getTotalPrize();
    if (totalPrize != null) {
      json["totalPrize"] = totalPrize;
    }
    const projectedPrize = this.getProjectedPrize();
    if (projectedPrize != null) {
      json["projectedPrize"] = projectedPrize;
    }
    const sectorTopicConfig = this.getSectorTopicConfig();
    if (sectorTopicConfig != null) {
      const convertedSectorTopicConfig0 = sectorTopicConfig.getJson(
        JsonTypeTagType.NONE
      );
      json["sectorTopicConfig"] = convertedSectorTopicConfig0;
    }

    if (includeTypeTag) {
      json["__type"] = "EntityUserInfo";
    }
  }

  public getTypeId(): string {
    return "EntityUserInfo";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.cowork.EntityUserInfo";
  }
}
