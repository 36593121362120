// @ts-nocheck
import {AbstractCompositeProductInfo} from './AbstractCompositeProductInfo'


export class CompositeProductInfo extends AbstractCompositeProductInfo {
    constructor() {
        super();
    }

    getVisits(): number {
        // Manual Code Here
    }

}
