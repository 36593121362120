/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {PageableData} from '../../paging/PageableData'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'
import {UserFavorite} from './UserFavorite'


export class UserLocalCircle extends AbstractSerializable {
    localPointUserFavorites: PageableData<UserFavorite>;
    regionUserFavorites: PageableData<UserFavorite>;
    associationFavorites: PageableData<UserFavorite>;
    enterpriseUserFavorites: PageableData<UserFavorite>;
    institutionUserFavorites: PageableData<UserFavorite>;
    productUserFavorites: PageableData<UserFavorite>;
    netizenUserFavorites: PageableData<UserFavorite>;
    gridUserFavorites: PageableData<UserFavorite>;

    constructor() {
        super();
    }

    public getLocalPointUserFavorites(): PageableData<UserFavorite> {
        return this.localPointUserFavorites;
    }

    public setLocalPointUserFavorites(localPointUserFavorites: PageableData<UserFavorite>) {
        this.localPointUserFavorites = localPointUserFavorites;
    }

    public getRegionUserFavorites(): PageableData<UserFavorite> {
        return this.regionUserFavorites;
    }

    public setRegionUserFavorites(regionUserFavorites: PageableData<UserFavorite>) {
        this.regionUserFavorites = regionUserFavorites;
    }

    public getAssociationFavorites(): PageableData<UserFavorite> {
        return this.associationFavorites;
    }

    public setAssociationFavorites(associationFavorites: PageableData<UserFavorite>) {
        this.associationFavorites = associationFavorites;
    }

    public getEnterpriseUserFavorites(): PageableData<UserFavorite> {
        return this.enterpriseUserFavorites;
    }

    public setEnterpriseUserFavorites(enterpriseUserFavorites: PageableData<UserFavorite>) {
        this.enterpriseUserFavorites = enterpriseUserFavorites;
    }

    public getInstitutionUserFavorites(): PageableData<UserFavorite> {
        return this.institutionUserFavorites;
    }

    public setInstitutionUserFavorites(institutionUserFavorites: PageableData<UserFavorite>) {
        this.institutionUserFavorites = institutionUserFavorites;
    }

    public getProductUserFavorites(): PageableData<UserFavorite> {
        return this.productUserFavorites;
    }

    public setProductUserFavorites(productUserFavorites: PageableData<UserFavorite>) {
        this.productUserFavorites = productUserFavorites;
    }

    public getNetizenUserFavorites(): PageableData<UserFavorite> {
        return this.netizenUserFavorites;
    }

    public setNetizenUserFavorites(netizenUserFavorites: PageableData<UserFavorite>) {
        this.netizenUserFavorites = netizenUserFavorites;
    }

    public getGridUserFavorites(): PageableData<UserFavorite> {
        return this.gridUserFavorites;
    }

    public setGridUserFavorites(gridUserFavorites: PageableData<UserFavorite>) {
        this.gridUserFavorites = gridUserFavorites;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let localPointUserFavorites = json["localPointUserFavorites"];
        if (localPointUserFavorites != null) {
            let convertedLocalPointUserFavorites0 = Serializer.fillFromJsonObjectWithTypeTag(localPointUserFavorites, "__type", factory);
            this.setLocalPointUserFavorites(convertedLocalPointUserFavorites0);
        }
        let regionUserFavorites = json["regionUserFavorites"];
        if (regionUserFavorites != null) {
            let convertedRegionUserFavorites0 = Serializer.fillFromJsonObjectWithTypeTag(regionUserFavorites, "__type", factory);
            this.setRegionUserFavorites(convertedRegionUserFavorites0);
        }
        let associationFavorites = json["associationFavorites"];
        if (associationFavorites != null) {
            let convertedAssociationFavorites0 = Serializer.fillFromJsonObjectWithTypeTag(associationFavorites, "__type", factory);
            this.setAssociationFavorites(convertedAssociationFavorites0);
        }
        let enterpriseUserFavorites = json["enterpriseUserFavorites"];
        if (enterpriseUserFavorites != null) {
            let convertedEnterpriseUserFavorites0 = Serializer.fillFromJsonObjectWithTypeTag(enterpriseUserFavorites, "__type", factory);
            this.setEnterpriseUserFavorites(convertedEnterpriseUserFavorites0);
        }
        let institutionUserFavorites = json["institutionUserFavorites"];
        if (institutionUserFavorites != null) {
            let convertedInstitutionUserFavorites0 = Serializer.fillFromJsonObjectWithTypeTag(institutionUserFavorites, "__type", factory);
            this.setInstitutionUserFavorites(convertedInstitutionUserFavorites0);
        }
        let productUserFavorites = json["productUserFavorites"];
        if (productUserFavorites != null) {
            let convertedProductUserFavorites0 = Serializer.fillFromJsonObjectWithTypeTag(productUserFavorites, "__type", factory);
            this.setProductUserFavorites(convertedProductUserFavorites0);
        }
        let netizenUserFavorites = json["netizenUserFavorites"];
        if (netizenUserFavorites != null) {
            let convertedNetizenUserFavorites0 = Serializer.fillFromJsonObjectWithTypeTag(netizenUserFavorites, "__type", factory);
            this.setNetizenUserFavorites(convertedNetizenUserFavorites0);
        }
        let gridUserFavorites = json["gridUserFavorites"];
        if (gridUserFavorites != null) {
            let convertedGridUserFavorites0 = Serializer.fillFromJsonObjectWithTypeTag(gridUserFavorites, "__type", factory);
            this.setGridUserFavorites(convertedGridUserFavorites0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let localPointUserFavorites = this.getLocalPointUserFavorites();
        if (localPointUserFavorites != null) {
            let convertedLocalPointUserFavorites0 = localPointUserFavorites.getJson(JsonTypeTagType.TYPE);
            json["localPointUserFavorites"] = convertedLocalPointUserFavorites0;
        }
        let regionUserFavorites = this.getRegionUserFavorites();
        if (regionUserFavorites != null) {
            let convertedRegionUserFavorites0 = regionUserFavorites.getJson(JsonTypeTagType.TYPE);
            json["regionUserFavorites"] = convertedRegionUserFavorites0;
        }
        let associationFavorites = this.getAssociationFavorites();
        if (associationFavorites != null) {
            let convertedAssociationFavorites0 = associationFavorites.getJson(JsonTypeTagType.TYPE);
            json["associationFavorites"] = convertedAssociationFavorites0;
        }
        let enterpriseUserFavorites = this.getEnterpriseUserFavorites();
        if (enterpriseUserFavorites != null) {
            let convertedEnterpriseUserFavorites0 = enterpriseUserFavorites.getJson(JsonTypeTagType.TYPE);
            json["enterpriseUserFavorites"] = convertedEnterpriseUserFavorites0;
        }
        let institutionUserFavorites = this.getInstitutionUserFavorites();
        if (institutionUserFavorites != null) {
            let convertedInstitutionUserFavorites0 = institutionUserFavorites.getJson(JsonTypeTagType.TYPE);
            json["institutionUserFavorites"] = convertedInstitutionUserFavorites0;
        }
        let productUserFavorites = this.getProductUserFavorites();
        if (productUserFavorites != null) {
            let convertedProductUserFavorites0 = productUserFavorites.getJson(JsonTypeTagType.TYPE);
            json["productUserFavorites"] = convertedProductUserFavorites0;
        }
        let netizenUserFavorites = this.getNetizenUserFavorites();
        if (netizenUserFavorites != null) {
            let convertedNetizenUserFavorites0 = netizenUserFavorites.getJson(JsonTypeTagType.TYPE);
            json["netizenUserFavorites"] = convertedNetizenUserFavorites0;
        }
        let gridUserFavorites = this.getGridUserFavorites();
        if (gridUserFavorites != null) {
            let convertedGridUserFavorites0 = gridUserFavorites.getJson(JsonTypeTagType.TYPE);
            json["gridUserFavorites"] = convertedGridUserFavorites0;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.user.UserLocalCircle";
    }
}
