/**
 * 自动生成， 请不要手工修改！
 */

import {Activity} from './Activity'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../keyword/KeywordEntity'
import {MessageType} from '../../message/MessageContentType'
import {QllCountry} from '../international/QllCountry'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractGeneralActivity extends Activity  {

    constructor() {
    	super();
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);

        if (includeTypeTag) {
            json["__type"] = "GeneralActivity";
        }
    }


    public getTypeId(): string {
    	return "GeneralActivity";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.activity.GeneralActivity";
    }
}
