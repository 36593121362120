/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {MessageRange} from './MessageRange'
import {PageBlockAccessSpec} from './PageBlockAccessSpec'
import {PageDataSpec} from './PageDataSpec'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractDefaultPageDataSpec extends AbstractSerializable implements PageDataSpec {
    startPosition: number;
    estimatedTotalRecords: number;
    fetchedSize: number;
    recordsRemaining: boolean;
    nextStart: number;
    messageRange: MessageRange;

    constructor() {
        super();
    }

    public getStartPosition(): number {
        return this.startPosition;
    }

    public setStartPosition(startPosition: number) {
        this.startPosition = startPosition;
    }

    public getEstimatedTotalRecords(): number {
        return this.estimatedTotalRecords;
    }

    public setEstimatedTotalRecords(estimatedTotalRecords: number) {
        this.estimatedTotalRecords = estimatedTotalRecords;
    }

    public getFetchedSize(): number {
        return this.fetchedSize;
    }

    public setFetchedSize(fetchedSize: number) {
        this.fetchedSize = fetchedSize;
    }

    public isRecordsRemaining(): boolean {
        return this.recordsRemaining;
    }

    public setRecordsRemaining(recordsRemaining: boolean) {
        this.recordsRemaining = recordsRemaining;
    }

    public getNextStart(): number {
        return this.nextStart;
    }

    public setNextStart(nextStart: number) {
        this.nextStart = nextStart;
    }

    public getMessageRange(): MessageRange {
        return this.messageRange;
    }

    public setMessageRange(messageRange: MessageRange) {
        this.messageRange = messageRange;
    }


    abstract getForwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getForwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract getNextBackwardPageSpec(arg0: (number | null), arg1: (number | null)): PageBlockAccessSpec;

    abstract getNextBackwardPageSpecWithDefaultSize(): PageBlockAccessSpec;

    abstract addPageDataSpec(arg0: (PageDataSpec | null), arg1: (boolean | null)): PageDataSpec;

    abstract getContinuousNoDataCount(): number;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let startPosition = json["startPosition"];
        if (startPosition != null) {
            this.setStartPosition(startPosition);
        }
        let estimatedTotalRecords = json["estimatedTotalRecords"];
        if (estimatedTotalRecords != null) {
            this.setEstimatedTotalRecords(estimatedTotalRecords);
        }
        let fetchedSize = json["fetchedSize"];
        if (fetchedSize != null) {
            this.setFetchedSize(fetchedSize);
        }
        let recordsRemaining = json["recordsRemaining"];
        if (recordsRemaining != null) {
            this.setRecordsRemaining(recordsRemaining);
        }
        let nextStart = json["nextStart"];
        if (nextStart != null) {
            this.setNextStart(nextStart);
        }
        let messageRange = json["messageRange"];
        if (messageRange != null) {
            let convertedMessageRange0 = Serializer.fillFromJsonObjectWithTypeTag(messageRange, "__type", factory);
            this.setMessageRange(convertedMessageRange0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let startPosition = this.getStartPosition();
        if (startPosition != null) {
            json["startPosition"] = startPosition;
        }
        let estimatedTotalRecords = this.getEstimatedTotalRecords();
        if (estimatedTotalRecords != null) {
            json["estimatedTotalRecords"] = estimatedTotalRecords;
        }
        let fetchedSize = this.getFetchedSize();
        if (fetchedSize != null) {
            json["fetchedSize"] = fetchedSize;
        }
        let recordsRemaining = this.isRecordsRemaining();
        if (recordsRemaining != null) {
            json["recordsRemaining"] = recordsRemaining;
        }
        let nextStart = this.getNextStart();
        if (nextStart != null) {
            json["nextStart"] = nextStart;
        }
        let messageRange = this.getMessageRange();
        if (messageRange != null) {
            let convertedMessageRange0 = messageRange.getJson(JsonTypeTagType.TYPE);
            json["messageRange"] = convertedMessageRange0;
        }

        if (includeTypeTag) {
            json["__type"] = "DefaultPageDataSpec";
        }
    }


    public getTypeId(): string {
        return "DefaultPageDataSpec";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.paging.DefaultPageDataSpec";
    }
}
