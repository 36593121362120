/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { EntityInfo } from "../entity/EntityInfo";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class LocalChat extends AbstractSerializable {
  id: number | string;
  senderInfo: EntityInfo;
  lat: number;
  lng: number;
  locName: string;
  geohash: number | string;
  detailGeohash: number | string;
  createTime: number | string;
  description: string;
  imageUrl: string;
  anonymous: boolean;
  timeVisible: boolean;

  constructor() {
    super();
  }

  public getId(): number | string {
    return this.id;
  }

  public setId(id: number | string) {
    this.id = id;
  }

  public getSenderInfo(): EntityInfo {
    return this.senderInfo;
  }

  public setSenderInfo(senderInfo: EntityInfo) {
    this.senderInfo = senderInfo;
  }

  public getLat(): number {
    return this.lat;
  }

  public setLat(lat: number) {
    this.lat = lat;
  }

  public getLng(): number {
    return this.lng;
  }

  public setLng(lng: number) {
    this.lng = lng;
  }

  public getLocName(): string {
    return this.locName;
  }

  public setLocName(locName: string) {
    this.locName = locName;
  }

  public getGeohash(): number | string {
    return this.geohash;
  }

  public setGeohash(geohash: number | string) {
    this.geohash = geohash;
  }

  public getDetailGeohash(): number | string {
    return this.detailGeohash;
  }

  public setDetailGeohash(detailGeohash: number | string) {
    this.detailGeohash = detailGeohash;
  }

  public getCreateTime(): number | string {
    return this.createTime;
  }

  public setCreateTime(createTime: number | string) {
    this.createTime = createTime;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;
  }

  public getImageUrl(): string {
    return this.imageUrl;
  }

  public setImageUrl(imageUrl: string) {
    this.imageUrl = imageUrl;
  }

  public isAnonymous(): boolean {
    return this.anonymous;
  }

  public setAnonymous(anonymous: boolean) {
    this.anonymous = anonymous;
  }

  public isTimeVisible(): boolean {
    return this.timeVisible;
  }

  public setTimeVisible(timeVisible: boolean) {
    this.timeVisible = timeVisible;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const id = json["id"];
    if (id != null) {
      this.setId(id);
    }
    const senderInfo = json["senderInfo"];
    if (senderInfo != null) {
      const convertedSenderInfo0 = Serializer.fillFromJsonObjectWithTypeTag(
        senderInfo,
        "__type",
        factory
      );
      this.setSenderInfo(convertedSenderInfo0);
    }
    const lat = json["lat"];
    if (lat != null) {
      this.setLat(lat);
    }
    const lng = json["lng"];
    if (lng != null) {
      this.setLng(lng);
    }
    const locName = json["locName"];
    if (locName != null) {
      this.setLocName(locName);
    }
    const geohash = json["geohash"];
    if (geohash != null) {
      this.setGeohash(geohash);
    }
    const detailGeohash = json["detailGeohash"];
    if (detailGeohash != null) {
      this.setDetailGeohash(detailGeohash);
    }
    const createTime = json["createTime"];
    if (createTime != null) {
      this.setCreateTime(createTime);
    }
    const description = json["description"];
    if (description != null) {
      this.setDescription(description);
    }
    const imageUrl = json["imageUrl"];
    if (imageUrl != null) {
      this.setImageUrl(imageUrl);
    }
    const anonymous = json["anonymous"];
    if (anonymous != null) {
      this.setAnonymous(anonymous);
    }
    const timeVisible = json["timeVisible"];
    if (timeVisible != null) {
      this.setTimeVisible(timeVisible);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const id = this.getId();
    if (id != null) {
      json["id"] = String(id);
    }
    const senderInfo = this.getSenderInfo();
    if (senderInfo != null) {
      const convertedSenderInfo0 = senderInfo.getJson(JsonTypeTagType.TYPE);
      json["senderInfo"] = convertedSenderInfo0;
    }
    const lat = this.getLat();
    if (lat != null) {
      json["lat"] = lat;
    }
    const lng = this.getLng();
    if (lng != null) {
      json["lng"] = lng;
    }
    const locName = this.getLocName();
    if (locName != null) {
      json["locName"] = locName;
    }
    const geohash = this.getGeohash();
    if (geohash != null) {
      json["geohash"] = String(geohash);
    }
    const detailGeohash = this.getDetailGeohash();
    if (detailGeohash != null) {
      json["detailGeohash"] = String(detailGeohash);
    }
    const createTime = this.getCreateTime();
    if (createTime != null) {
      json["createTime"] = String(createTime);
    }
    const description = this.getDescription();
    if (description != null) {
      json["description"] = description;
    }
    const imageUrl = this.getImageUrl();
    if (imageUrl != null) {
      json["imageUrl"] = imageUrl;
    }
    const anonymous = this.isAnonymous();
    if (anonymous != null) {
      json["anonymous"] = anonymous;
    }
    const timeVisible = this.isTimeVisible();
    if (timeVisible != null) {
      json["timeVisible"] = timeVisible;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.geolocation.LocalChat";
  }
}
