/**
 * 自动生成， 请不要手工修改！
 */

export enum AppType {
  GONG_WEI_ZAI_ZHE,
  GONG_WEI_ZAI_ZHE_DEBUG,
  GONG_WEI_ZAI_ZHE_MINI,
  GRID_HOME,
  GRID_HOME_DEBUG,
  MANAGER_WEB,
  GRID_IOS,
  ALUMNI_MINI,
  THINK_IOS,
  THINK_WEB,
  ASSOCIATIONS,
  ASSOCIATIONS_IOS,
  ASSOCIATIONS_DEBUG,
  ASSOCIATIONS_IOS_DEBUG,
  ASSOCIATIONS_UNITED,
  ASSOCIATIONS_UNITED_DEBUG,
  QLL_WEB,
  QLL_INTL_WEB,
  QLL_WORK_WEB,
  QLL_OP_WEB,
  QLL_MINI_SERVICE
}

