/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {Filter} from '../../Filter'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class TopicFilter extends AbstractSerializable implements Filter {
    categoryIds: Array<(number | string)>;
    keyWords: string;

    constructor() {
        super();
    }

    public getCategoryIds(): Array<(number | string)> {
        return this.categoryIds;
    }

    public setCategoryIds(categoryIds: Array<(number | string)>) {
        this.categoryIds = categoryIds;
    }

    public getKeyWords(): string {
        return this.keyWords;
    }

    public setKeyWords(keyWords: string) {
        this.keyWords = keyWords;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let categoryIds = json["categoryIds"];
        if (categoryIds != null) {
            let convertedCategoryIds0 = [];
            categoryIds = categoryIds[1];
            for (let i in categoryIds) {
                let convertedCategoryIds10 = categoryIds[i];
                convertedCategoryIds0.push(convertedCategoryIds10);
            }
            this.setCategoryIds(convertedCategoryIds0);
        }
        let keyWords = json["keyWords"];
        if (keyWords != null) {
            this.setKeyWords(keyWords);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let categoryIds = this.getCategoryIds();
        if (categoryIds != null) {
            let convertedCategoryIds0 = [];
            for (let i in categoryIds) {
                let convertedCategoryIds10 = categoryIds[i];
                convertedCategoryIds0.push(String(convertedCategoryIds10));
            }
            let convertedCategoryIdsWithType1 = ["java.util.ArrayList", convertedCategoryIds0];
            json["categoryIds"] = convertedCategoryIdsWithType1;
        }
        let keyWords = this.getKeyWords();
        if (keyWords != null) {
            json["keyWords"] = keyWords;
        }

        if (includeTypeTag) {
            json["__type"] = "TopicFilter";
        }
    }


    public getTypeId(): string {
        return "TopicFilter";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.query.entity.topic.TopicFilter";
    }
}
