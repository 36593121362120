/**
 * 自动生成， 请不要手工修改！
 */


export enum EntityType {
    SYSTEM,
    NETIZEN,
    REGION,
    ENTERPRISE,
    TOPIC,
    PRODUCT,
    SERVICE,
    PLF_ACTV,
    GROUP,
    GEOHASH,
    GUEST
}
