/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {AdContent} from '../../business/ad/AdContent'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {Serializer} from '../../../serialize/Serializer'
import {SystemSuppliedEntityInfo} from '../SystemSuppliedEntityInfo'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class UserHomePageInfo extends AbstractSerializable {
    userInfo: SystemSuppliedEntityInfo;
    adContents: Array<AdContent>;

    constructor() {
        super();
    }

    public getUserInfo(): SystemSuppliedEntityInfo {
        return this.userInfo;
    }

    public setUserInfo(userInfo: SystemSuppliedEntityInfo) {
        this.userInfo = userInfo;
    }

    public getAdContents(): Array<AdContent> {
        return this.adContents;
    }

    public setAdContents(adContents: Array<AdContent>) {
        this.adContents = adContents;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let userInfo = json["userInfo"];
        if (userInfo != null) {
            let convertedUserInfo0 = new SystemSuppliedEntityInfo();
            convertedUserInfo0.fillFromJson(userInfo, factory);
            this.setUserInfo(convertedUserInfo0);
        }
        let adContents = json["adContents"];
        if (adContents != null) {
            let convertedAdContents0 = [];
            adContents = adContents[1];
            for (let i in adContents) {
                let convertedAdContents10 = adContents[i];
                let convertedAdContents1 = Serializer.fillFromJsonObjectWithTypeTag(convertedAdContents10, "__type", factory);
                convertedAdContents0.push(convertedAdContents1);
            }
            this.setAdContents(convertedAdContents0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let userInfo = this.getUserInfo();
        if (userInfo != null) {
            let convertedUserInfo0 = userInfo.getJson(JsonTypeTagType.NONE);
            json["userInfo"] = convertedUserInfo0;
        }
        let adContents = this.getAdContents();
        if (adContents != null) {
            let convertedAdContents0 = [];
            for (let i in adContents) {
                let convertedAdContents10 = adContents[i];
                let convertedAdContents1 = convertedAdContents10.getJson(JsonTypeTagType.TYPE);
                convertedAdContents0.push(convertedAdContents1);
            }
            let convertedAdContentsWithType1 = ["java.util.ArrayList", convertedAdContents0];
            json["adContents"] = convertedAdContentsWithType1;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.entity.user.UserHomePageInfo";
    }
}
