// 此处为跳转订单详情面包屑传递
export default {
  namespaced: true, // 为每个模块添加一个前缀名，保证模块命明不冲突
  state: () => {
    return {
      // 发送与收取id
      wsState: "",
    };
  },
  mutations: {
    updateWsState(state, payload) {
      state.wsState = payload;
    },
  },
  actions: {},
};
