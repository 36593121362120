/**
 * Created by Zhonghua on 16/11/2016.
 */

import { AbstractServiceRequest } from "../common/AbstractServiceRequest";
import { ServiceResponse } from "@/services/common";
import { ServiceResponseHandler } from "@/services/common";
import { BiConsumer } from "./BiConsumer";
import { Consumer } from "./Consumer";
import EventHandle from "@/services/common/EventHandle";
import { ServiceResponseHandle } from "@/services/api/datatype/ServiceResponseHandle";
import { MultiMessageResponse } from "@/services/datatype/message/MultiMessageResponse";
import { EventRequest, ServiceError } from "@/services/common";
import { EventResponseStatus } from "@/services/common/EventResponseStatus";
import { SimpleServiceResponseHandle } from "@/services/api/datatype/SimpleServiceResponseHandle";
import {
  Canceller,
  SimpleEventHandle,
} from "@/services/common/SimpleEventHandle";

class ServiceResponseHandlerOnConsumer<T> implements ServiceResponseHandler {
  constructor(private consumer: Consumer<T>) {}

  public handleResponse(
    request: AbstractServiceRequest,
    response: ServiceResponse
  ) {
    if (response.getServiceError()) {
      console.log(
        "Failed to execute service request: " +
          request +
          " with error: " +
          response.getServiceError()
      );
      this.consumer(null);
    } else {
      this.consumer(response.getReturnValue() as T);
    }
  }

  public handleError(request: AbstractServiceRequest, error: any) {
    console.log(
      "Failed to execute service request: " + request + " with error: " + error
    );
    this.consumer(null);
  }
}

class ServiceResponseHandlerOnConsumerForEvent
  implements ServiceResponseHandler
{
  constructor(
    private eventHandler: BiConsumer<EventHandle, any>,
    private resultHandler: Consumer<
      ServiceResponseHandle<MultiMessageResponse>
    >,
    private canceller: Canceller
  ) {}

  public handleResponse(
    request: AbstractServiceRequest,
    response: ServiceResponse
  ) {
    if (response.getServiceError()) {
      this.handleError(request, response.getServiceError());
    } else {
      const eventRequest = request as EventRequest;
      if (EventRequest.isDataResponse(response)) {
        if (
          eventRequest.eventResponseStatus == EventResponseStatus.FIRST_RESPONSE
        ) {
        }
        const responseHandle =
          new SimpleServiceResponseHandle<MultiMessageResponse>();
        if (response) {
          responseHandle.result = response.getReturnValue();
          responseHandle.error = response.getServiceError();
        }
        this.resultHandler(responseHandle);
      } else {
        const eventHandle = new SimpleEventHandle(eventRequest, this.canceller);
        this.eventHandler(eventHandle, null);
      }
    }
  }

  public handleError(request: AbstractServiceRequest, error: any) {
    console.log(
      "Failed to execute service request: " + request + " with error: " + error
    );

    let notifyEventHandler = false;
    if (ServiceError.checkDisconnectedErrorForAny(error)) {
      notifyEventHandler = true;
    } else {
      const eventRequest: EventRequest = request as EventRequest;
      if (
        eventRequest &&
        eventRequest.eventResponseStatus == EventResponseStatus.FIRST_RESPONSE
      ) {
        notifyEventHandler = true;
      }
    }

    if (notifyEventHandler) {
      this.eventHandler(null, error);
    } else {
      const responseHandle =
        new SimpleServiceResponseHandle<MultiMessageResponse>();
      let serviceError: ServiceError;
      if (error instanceof ServiceError) {
        serviceError = error as ServiceError;
      } else {
        serviceError = new ServiceError();
        serviceError.setErrorTitle(error + "");
      }
      responseHandle.error = serviceError;
      this.resultHandler(responseHandle);
    }
  }
}

class ServiceResponseHandlerOnBiConsumer<T> implements ServiceResponseHandler {
  constructor(private consumer: BiConsumer<T, any>) {}

  public handleResponse(
    request: AbstractServiceRequest,
    response: ServiceResponse
  ) {
    if (response.getServiceError()) {
      console.log(
        "Failed to execute service request: " +
          request +
          " with error: " +
          response.getServiceError()
      );
    }
    this.consumer(response.getReturnValue() as T, response.getServiceError());
  }

  public handleError(request: AbstractServiceRequest, error: any) {
    console.log(
      "Failed to execute service request: " + request + " with error: " + error
    );
    this.consumer(null, error);
  }
}

export class ServiceConsumers {
  public static toServiceConsumer<T>(
    consumer: Consumer<T>
  ): ServiceResponseHandler {
    return new ServiceResponseHandlerOnConsumer<T>(consumer);
  }

  public static toBiServiceConsumer<T>(
    consumer: BiConsumer<T, any>
  ): ServiceResponseHandler {
    return new ServiceResponseHandlerOnBiConsumer<T>(consumer);
  }

  public static toBiServiceConsumerForEvent(
    eventHandler: BiConsumer<EventHandle, any>,
    resultHandler: Consumer<ServiceResponseHandle<MultiMessageResponse>>,
    canceller: Canceller
  ): ServiceResponseHandler {
    return new ServiceResponseHandlerOnConsumerForEvent(
      eventHandler,
      resultHandler,
      canceller
    );
  }
}
