// @ts-nocheck
import {AbstractPersonalProfileAspect} from './AbstractPersonalProfileAspect'
import {ProfileAspectType} from './ProfileAspectType'


export class PersonalProfileAspect extends AbstractPersonalProfileAspect  {
    constructor() {
    	super();
    }

    getProfileAspectType(): ProfileAspectType {
      // Manual Code Here
    }
    getEntityId(): (number | string) {
      // Manual Code Here
    }
    setEntityId(arg0: (number | string | null)) {
      // Manual Code Here
    }

}
