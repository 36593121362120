/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";
import { UserSponsorInfo } from "./UserSponsorInfo";

export class SponsorInfo extends AbstractSerializable {
  userSponsors: Array<UserSponsorInfo>;
  totalSponsorAmount: number;
  realizedSponsorAmount: number;

  constructor() {
    super();
  }

  public getUserSponsors(): Array<UserSponsorInfo> {
    return this.userSponsors;
  }

  public setUserSponsors(userSponsors: Array<UserSponsorInfo>) {
    this.userSponsors = userSponsors;
  }

  public getTotalSponsorAmount(): number {
    return this.totalSponsorAmount;
  }

  public setTotalSponsorAmount(totalSponsorAmount: number) {
    this.totalSponsorAmount = totalSponsorAmount;
  }

  public getRealizedSponsorAmount(): number {
    return this.realizedSponsorAmount;
  }

  public setRealizedSponsorAmount(realizedSponsorAmount: number) {
    this.realizedSponsorAmount = realizedSponsorAmount;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    let userSponsors = json["userSponsors"];
    if (userSponsors != null) {
      const convertedUserSponsors0 = [];
      userSponsors = userSponsors[1];
      for (const i in userSponsors) {
        const convertedUserSponsors10 = userSponsors[i];
        const convertedUserSponsors1 = new UserSponsorInfo();
        convertedUserSponsors1.fillFromJson(convertedUserSponsors10, factory);
        convertedUserSponsors0.push(convertedUserSponsors1);
      }
      this.setUserSponsors(convertedUserSponsors0);
    }
    const totalSponsorAmount = json["totalSponsorAmount"];
    if (totalSponsorAmount != null) {
      this.setTotalSponsorAmount(totalSponsorAmount);
    }
    const realizedSponsorAmount = json["realizedSponsorAmount"];
    if (realizedSponsorAmount != null) {
      this.setRealizedSponsorAmount(realizedSponsorAmount);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const userSponsors = this.getUserSponsors();
    if (userSponsors != null) {
      const convertedUserSponsors0 = [];
      for (const i in userSponsors) {
        const convertedUserSponsors10 = userSponsors[i];
        const convertedUserSponsors1 = convertedUserSponsors10.getJson(
          JsonTypeTagType.NONE
        );
        convertedUserSponsors0.push(convertedUserSponsors1);
      }
      const convertedUserSponsorsWithType1 = [
        "java.util.ArrayList",
        convertedUserSponsors0,
      ];
      json["userSponsors"] = convertedUserSponsorsWithType1;
    }
    const totalSponsorAmount = this.getTotalSponsorAmount();
    if (totalSponsorAmount != null) {
      json["totalSponsorAmount"] = totalSponsorAmount;
    }
    const realizedSponsorAmount = this.getRealizedSponsorAmount();
    if (realizedSponsorAmount != null) {
      json["realizedSponsorAmount"] = realizedSponsorAmount;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.qa.SponsorInfo";
  }
}
