// @ts-nocheck
import {AbstractAdContent} from './AbstractAdContent'
import {CoworkReplyInfo} from '../../cowork/CoworkReplyInfo'
import {CoworkSimpleView} from '../../cowork/CoworkSimpleView'
import {CoworkStats} from '../../cowork/CoworkStats'
import {CoworkType} from '../../cowork/CoworkType'
import {Location} from '../../geolocation/Location'
import {QllCountrySpec} from '../../international/QllCountrySpec'
import {QllLanguageSpec} from '../../international/QllLanguageSpec'
import {SelectTimes} from '../../cowork/SelectTimes'


export class AdContent extends AbstractAdContent  {
    constructor() {
    	super();
    }

    isActive(): boolean {
      // Manual Code Here
    }
    isAnonymous(): boolean {
      // Manual Code Here
    }
    getLatestReply(): CoworkReplyInfo {
      // Manual Code Here
    }
    copy(arg0: (boolean | null)): CoworkSimpleView {
      // Manual Code Here
    }
    getCoworkType(): CoworkType {
      // Manual Code Here
    }
    getSelectTimes(): SelectTimes {
      // Manual Code Here
    }
    getLocation(): Location {
      // Manual Code Here
    }
    getCountrySpec(): QllCountrySpec {
      // Manual Code Here
    }
    getLanguageSpec(): QllLanguageSpec {
      // Manual Code Here
    }
    getApprovals(): number {
      // Manual Code Here
    }
    getCountryCode(): number {
      // Manual Code Here
    }
    getDisapprovals(): number {
      // Manual Code Here
    }
    getLanguageCode(): number {
      // Manual Code Here
    }
    getVisits(): number {
      // Manual Code Here
    }
    getPrize(): number {
      // Manual Code Here
    }
    getInitiatorId(): (number | string) {
      // Manual Code Here
    }
    getCoworkStatus(): any {
      // Manual Code Here
    }
    getSubType(): any {
      // Manual Code Here
    }
    getMainImageUrl(): string {
      // Manual Code Here
    }
    getRid(): (number | string) {
      // Manual Code Here
    }
    accept(arg0: (CoworkStats | null)) {
      // Manual Code Here
    }
    addSelectTimes(arg0: (SelectTimes | null)) {
      // Manual Code Here
    }
    setCountrySpec(arg0: (QllCountrySpec | null)) {
      // Manual Code Here
    }
    setCoworkStatus(arg0: (any | null)) {
      // Manual Code Here
    }
    setLanguageSpec(arg0: (QllLanguageSpec | null)) {
      // Manual Code Here
    }
    setLatestReply(arg0: (CoworkReplyInfo | null)) {
      // Manual Code Here
    }
    setSubType(arg0: (any | null)) {
      // Manual Code Here
    }
    update(arg0: (CoworkSimpleView | null)) {
      // Manual Code Here
    }

}
