/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCowork} from '../../cowork/AbstractCowork'
import {AnswerContent} from './AnswerContent'
import {EntityInfo} from '../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../keyword/KeywordEntity'
import {MessageType} from '../../../message/MessageContentType'
import {PageableData} from '../../paging/PageableData'
import {QllCountry} from '../../international/QllCountry'
import {QuestionStatus} from './QuestionStatus'
import {Serializer} from '../../../serialize/Serializer'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractQuestion extends AbstractCowork  {
    status: QuestionStatus;
    senderSponsorAmount: number;
    answers: PageableData<AnswerContent>;
    sponsorsList: Array<EntityInfo>;

    constructor() {
    	super();
    }

    public getStatus(): QuestionStatus {
    	return this.status;
    }

    public setStatus(status: QuestionStatus) {
    	this.status = status;
    }

    public getSenderSponsorAmount(): number {
    	return this.senderSponsorAmount;
    }

    public setSenderSponsorAmount(senderSponsorAmount: number) {
    	this.senderSponsorAmount = senderSponsorAmount;
    }

    public getAnswers(): PageableData<AnswerContent> {
    	return this.answers;
    }

    public setAnswers(answers: PageableData<AnswerContent>) {
    	this.answers = answers;
    }

    public getSponsorsList(): Array<EntityInfo> {
    	return this.sponsorsList;
    }

    public setSponsorsList(sponsorsList: Array<EntityInfo>) {
    	this.sponsorsList = sponsorsList;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = QuestionStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let senderSponsorAmount = json["senderSponsorAmount"];
        if (senderSponsorAmount != null) {
            this.setSenderSponsorAmount(senderSponsorAmount);
        }
        let answers = json["answers"];
        if (answers != null) {
            let convertedAnswers0 = Serializer.fillFromJsonObjectWithTypeTag(answers, "__type", factory);
            this.setAnswers(convertedAnswers0);
        }
        let sponsorsList = json["sponsorsList"];
        if (sponsorsList != null) {
            let convertedSponsorsList0 = [];
            sponsorsList = sponsorsList[1];
            for (let i in sponsorsList) {
                let convertedSponsorsList10 = sponsorsList[i];
                let convertedSponsorsList1 = Serializer.fillFromJsonObjectWithTypeTag(convertedSponsorsList10, "__type", factory);
                convertedSponsorsList0.push(convertedSponsorsList1);
            }
            this.setSponsorsList(convertedSponsorsList0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = QuestionStatus[status];
            json["status"] = convertedStatus0;
        }
        let senderSponsorAmount = this.getSenderSponsorAmount();
        if (senderSponsorAmount != null) {
            json["senderSponsorAmount"] = senderSponsorAmount;
        }
        let answers = this.getAnswers();
        if (answers != null) {
            let convertedAnswers0 = answers.getJson(JsonTypeTagType.TYPE);
            json["answers"] = convertedAnswers0;
        }
        let sponsorsList = this.getSponsorsList();
        if (sponsorsList != null) {
            let convertedSponsorsList0 = [];
            for (let i in sponsorsList) {
                let convertedSponsorsList10 = sponsorsList[i];
                let convertedSponsorsList1 = convertedSponsorsList10.getJson(JsonTypeTagType.TYPE);
                convertedSponsorsList0.push(convertedSponsorsList1);
            }
            let convertedSponsorsListWithType1 = ["java.util.ArrayList", convertedSponsorsList0];
            json["sponsorsList"] = convertedSponsorsListWithType1;
        }

        if (includeTypeTag) {
            json["__type"] = "Question";
        }
    }


    public getTypeId(): string {
    	return "Question";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.ipsphere.qa.Question";
    }
}
