// @ts-nocheck
import { AbstractEntityRoleSpec } from "./AbstractEntityRoleSpec";
import { TextType } from "../../TextType";

export class EntityRoleSpec extends AbstractEntityRoleSpec {
  constructor() {
    super();
  }

  getTextType(): TextType {
    return TextType.PLAIN;
  }

  getText(): string | null {
    return null;
  }
}
