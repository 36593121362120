/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../common/AbstractClientServiceAdapter'
import {CompositeSharedInfo} from '../datatype/share/CompositeSharedInfo'
import {DefaultServiceFactory} from '../api/DefaultServiceFactory'
import {DefaultWebServiceFactory} from '../webapi/DefaultWebServiceFactory'
import {EntityMessageResponse} from '../message/entity/api/EntityMessageResponse'
import {PageableData} from '../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../datatype/paging/PageBlockAccessSpec'
import {PlatformDataContainer} from '../datatype/platform/PlatformDataContainer'
import {RequestType} from '../datatype/request/RequestType'
import {SharedInfoService} from '../api/share/SharedInfoService'
import {SharedInfoView} from '../datatype/share/SharedInfoView'
import {SharedInfo} from '../datatype/share/SharedInfo'
import {WebSessionManager} from '../common/WebSessionManager'
import {WebSharedInfoService} from '../webapi/WebSharedInfoService'


export class WebSharedInfoServiceImpl implements WebSharedInfoService {

    private sharedInfoService: SharedInfoService;

    public constructor() {
        this.sharedInfoService = DefaultServiceFactory.getInstance().getSharedInfoService();
    }

    public addOrUpdateSharedInfo(sharedInfo: SharedInfo): Promise<EntityMessageResponse> {
        return new Promise((resolve, reject) => {
        	this.sharedInfoService.addOrUpdateSharedInfo(WebSessionManager.getSessionContext(),
        	sharedInfo, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public cancelSharedInfo(targetEntityId: (number | string | null), infoId: (number | string | null), regionId: (number | string | null), requestType: (RequestType | null), reason: (string | null)): Promise<EntityMessageResponse> {
        return new Promise((resolve, reject) => {
        	this.sharedInfoService.cancelSharedInfo(WebSessionManager.getSessionContext(),
        	targetEntityId, infoId, regionId, requestType, reason, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getNotesByTargetId(targetId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<SharedInfoView>> {
        return new Promise((resolve, reject) => {
        	this.sharedInfoService.getNotesByTargetId(WebSessionManager.getSessionContext(),
        	targetId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getNotesByUserId(targetId: (number | string | null), userId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<SharedInfoView>> {
        return new Promise((resolve, reject) => {
        	this.sharedInfoService.getNotesByUserId(WebSessionManager.getSessionContext(),
        	targetId, userId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getNotesBydCitzenId(citzenId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null)): Promise<PageableData<SharedInfoView>> {
        return new Promise((resolve, reject) => {
        	this.sharedInfoService.getNotesBydCitzenId(WebSessionManager.getSessionContext(),
        	citzenId, pageBlockSpec, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

    public getSharedInfo(sharedInfoId: (number | string | null)): Promise<PlatformDataContainer<CompositeSharedInfo>> {
        return new Promise((resolve, reject) => {
        	this.sharedInfoService.getSharedInfo(WebSessionManager.getSessionContext(),
        	sharedInfoId, 
        	(t, u) => {
        		if (u) { reject(u); }
        		else { resolve(t); }
        	});
        });
    }

}
