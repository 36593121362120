/**
 * Created by Zhonghua on 15/11/2016.
 */

import {MessageType} from "../message/MessageContentType";
import {MessageResponseType} from "../message/MessageResponseType";
import { JsonTypeTagType } from "../serialize/JsonTypeTagType";
import { TypeFactory } from "../serialize/TypeFactory";
import { AbstractServiceRequest } from "./AbstractServiceRequest";

export class ServiceCancelRequest extends AbstractServiceRequest {
    public static createServiceCancelRequest(originalRequestId: string) {
        const request = new ServiceCancelRequest();
        request.setCorrelationId(originalRequestId);
        return request;
    }

    constructor() {
        super();
    }

    public getContentType(): MessageType {
        return MessageType.SERVICE_REQUEST;
    }

    public getResponseType(): MessageResponseType {
        return MessageResponseType.ONE;
    }

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
      super.fillFromJson(json);
    }
  }

  public fillToJson(json: Object, includeTypeTag: boolean) {
    super.fillToJson(json, includeTypeTag);

    json["__type"] = "ServiceCancel";
  }

  public getTypeId(): string {
    return "ServiceCancel";
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.service.spi.ServiceCancelRequest";
  }
}
