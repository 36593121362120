/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { AssociationType } from "./AssociationType";
import { EnterpriseServiceType } from "../../business/service/EnterpriseServiceType";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class SectorAssocInfo extends AbstractSerializable {
  assocType: AssociationType;
  customSectorType: string;
  serviceType: EnterpriseServiceType;
  version: string;
  chartered: boolean;
  charter: string;
  tradeClassifiers: Array<string>;
  updateTime: number | string;

  constructor() {
    super();
  }

  public getAssocType(): AssociationType {
    return this.assocType;
  }

  public setAssocType(assocType: AssociationType) {
    this.assocType = assocType;
  }

  public getCustomSectorType(): string {
    return this.customSectorType;
  }

  public setCustomSectorType(customSectorType: string) {
    this.customSectorType = customSectorType;
  }

  public getServiceType(): EnterpriseServiceType {
    return this.serviceType;
  }

  public setServiceType(serviceType: EnterpriseServiceType) {
    this.serviceType = serviceType;
  }

  public getVersion(): string {
    return this.version;
  }

  public setVersion(version: string) {
    this.version = version;
  }

  public isChartered(): boolean {
    return this.chartered;
  }

  public setChartered(chartered: boolean) {
    this.chartered = chartered;
  }

  public getCharter(): string {
    return this.charter;
  }

  public setCharter(charter: string) {
    this.charter = charter;
  }

  public getTradeClassifiers(): Array<string> {
    return this.tradeClassifiers;
  }

  public setTradeClassifiers(tradeClassifiers: Array<string>) {
    this.tradeClassifiers = tradeClassifiers;
  }

  public getUpdateTime(): number | string {
    return this.updateTime;
  }

  public setUpdateTime(updateTime: number | string) {
    this.updateTime = updateTime;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const assocType = json["assocType"];
    if (assocType != null) {
      const convertedAssocType0 = AssociationType[<string>assocType];
      this.setAssocType(convertedAssocType0);
    }
    const customSectorType = json["customSectorType"];
    if (customSectorType != null) {
      this.setCustomSectorType(customSectorType);
    }
    const serviceType = json["serviceType"];
    if (serviceType != null) {
      const convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
      this.setServiceType(convertedServiceType0);
    }
    const version = json["version"];
    if (version != null) {
      this.setVersion(version);
    }
    const chartered = json["chartered"];
    if (chartered != null) {
      this.setChartered(chartered);
    }
    const charter = json["charter"];
    if (charter != null) {
      this.setCharter(charter);
    }
    let tradeClassifiers = json["tradeClassifiers"];
    if (tradeClassifiers != null) {
      const convertedTradeClassifiers0 = [];
      tradeClassifiers = tradeClassifiers[1];
      for (const i in tradeClassifiers) {
        const convertedTradeClassifiers10 = tradeClassifiers[i];
        convertedTradeClassifiers0.push(convertedTradeClassifiers10);
      }
      this.setTradeClassifiers(convertedTradeClassifiers0);
    }
    const updateTime = json["updateTime"];
    if (updateTime != null) {
      this.setUpdateTime(updateTime);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const assocType = this.getAssocType();
    if (assocType != null) {
      const convertedAssocType0 = AssociationType[assocType];
      json["assocType"] = convertedAssocType0;
    }
    const customSectorType = this.getCustomSectorType();
    if (customSectorType != null) {
      json["customSectorType"] = customSectorType;
    }
    const serviceType = this.getServiceType();
    if (serviceType != null) {
      const convertedServiceType0 = EnterpriseServiceType[serviceType];
      json["serviceType"] = convertedServiceType0;
    }
    const version = this.getVersion();
    if (version != null) {
      json["version"] = version;
    }
    const chartered = this.isChartered();
    if (chartered != null) {
      json["chartered"] = chartered;
    }
    const charter = this.getCharter();
    if (charter != null) {
      json["charter"] = charter;
    }
    const tradeClassifiers = this.getTradeClassifiers();
    if (tradeClassifiers != null) {
      const convertedTradeClassifiers0 = [];
      for (const i in tradeClassifiers) {
        const convertedTradeClassifiers10 = tradeClassifiers[i];
        convertedTradeClassifiers0.push(convertedTradeClassifiers10);
      }
      const convertedTradeClassifiersWithType1 = [
        "java.util.ArrayList",
        convertedTradeClassifiers0,
      ];
      json["tradeClassifiers"] = convertedTradeClassifiersWithType1;
    }
    const updateTime = this.getUpdateTime();
    if (updateTime != null) {
      json["updateTime"] = String(updateTime);
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.sector.SectorAssocInfo";
  }
}
