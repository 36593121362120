/**
 * 自动生成， 请不要手工修改！
 */

export enum EnterpriseServiceDisplayType {
  SERVICE_PKG,
  ASSOCIATION,
  GENERAL_LIST,
  SERVICE,
  COWORK_AS_BUS_CASE,
  TOOL,
  ENTERPRISE,
  FORUM,
}
