/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class LabelCount extends AbstractSerializable {
  label: string;
  count: number;

  constructor() {
    super();
  }

  public getLabel(): string {
    return this.label;
  }

  public setLabel(label: string) {
    this.label = label;
  }

  public getCount(): number {
    return this.count;
  }

  public setCount(count: number) {
    this.count = count;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const label = json["label"];
    if (label != null) {
      this.setLabel(label);
    }
    const count = json["count"];
    if (count != null) {
      this.setCount(count);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const label = this.getLabel();
    if (label != null) {
      json["label"] = label;
    }
    const count = this.getCount();
    if (count != null) {
      json["count"] = count;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.appeal.LabelCount";
  }
}
