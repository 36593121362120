/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCowork} from '../cowork/AbstractCowork'
import {AnnouncementStatus} from './AnnouncementStatus'
import {AnnounceType} from './AnnounceType'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../keyword/KeywordEntity'
import {MessageType} from '../../message/MessageContentType'
import {PricedTag} from './PricedTag'
import {QllCountry} from '../international/QllCountry'
import {ReadStats} from './ReadStats'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractAnnouncement extends AbstractCowork  {
    initiatorMobile: string;
    announceType: AnnounceType;
    status: AnnouncementStatus;
    endTime: number;
    activity: boolean;
    price: number;
    pricedTag: PricedTag;
    customPriceName: string;
    readStats: ReadStats;
    reRid: (number | string);

    constructor() {
    	super();
    }

    public getInitiatorMobile(): string {
    	return this.initiatorMobile;
    }

    public setInitiatorMobile(initiatorMobile: string) {
    	this.initiatorMobile = initiatorMobile;
    }

    public getAnnounceType(): AnnounceType {
    	return this.announceType;
    }

    public setAnnounceType(announceType: AnnounceType) {
    	this.announceType = announceType;
    }

    public getStatus(): AnnouncementStatus {
    	return this.status;
    }

    public setStatus(status: AnnouncementStatus) {
    	this.status = status;
    }

    public getEndTime(): number {
    	return this.endTime;
    }

    public setEndTime(endTime: number) {
    	this.endTime = endTime;
    }

    public getActivity(): boolean {
    	return this.activity;
    }

    public setActivity(activity: boolean) {
    	this.activity = activity;
    }

    public getPrice(): number {
    	return this.price;
    }

    public setPrice(price: number) {
    	this.price = price;
    }

    public getPricedTag(): PricedTag {
    	return this.pricedTag;
    }

    public setPricedTag(pricedTag: PricedTag) {
    	this.pricedTag = pricedTag;
    }

    public getCustomPriceName(): string {
    	return this.customPriceName;
    }

    public setCustomPriceName(customPriceName: string) {
    	this.customPriceName = customPriceName;
    }

    public getReadStats(): ReadStats {
    	return this.readStats;
    }

    public setReadStats(readStats: ReadStats) {
    	this.readStats = readStats;
    }

    public getReRid(): (number | string) {
    	return this.reRid;
    }

    public setReRid(reRid: (number | string)) {
    	this.reRid = reRid;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let initiatorMobile = json["initiatorMobile"];
        if (initiatorMobile != null) {
            this.setInitiatorMobile(initiatorMobile);
        }
        let announceType = json["announceType"];
        if (announceType != null) {
            let convertedAnnounceType0 = AnnounceType[<string>announceType];
            this.setAnnounceType(convertedAnnounceType0);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = AnnouncementStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let endTime = json["endTime"];
        if (endTime != null) {
            this.setEndTime(endTime);
        }
        let activity = json["activity"];
        if (activity != null) {
            this.setActivity(activity);
        }
        let price = json["price"];
        if (price != null) {
            this.setPrice(price);
        }
        let pricedTag = json["pricedTag"];
        if (pricedTag != null) {
            let convertedPricedTag0 = PricedTag[<string>pricedTag];
            this.setPricedTag(convertedPricedTag0);
        }
        let customPriceName = json["customPriceName"];
        if (customPriceName != null) {
            this.setCustomPriceName(customPriceName);
        }
        let readStats = json["readStats"];
        if (readStats != null) {
            let convertedReadStats0 = new ReadStats();
            convertedReadStats0.fillFromJson(readStats, factory);
            this.setReadStats(convertedReadStats0);
        }
        let reRid = json["reRid"];
        if (reRid != null) {
            this.setReRid(reRid);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let initiatorMobile = this.getInitiatorMobile();
        if (initiatorMobile != null) {
            json["initiatorMobile"] = initiatorMobile;
        }
        let announceType = this.getAnnounceType();
        if (announceType != null) {
            let convertedAnnounceType0 = AnnounceType[announceType];
            json["announceType"] = convertedAnnounceType0;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = AnnouncementStatus[status];
            json["status"] = convertedStatus0;
        }
        let endTime = this.getEndTime();
        if (endTime != null) {
            json["endTime"] = endTime;
        }
        let activity = this.getActivity();
        if (activity != null) {
            json["activity"] = activity;
        }
        let price = this.getPrice();
        if (price != null) {
            json["price"] = price;
        }
        let pricedTag = this.getPricedTag();
        if (pricedTag != null) {
            let convertedPricedTag0 = PricedTag[pricedTag];
            json["pricedTag"] = convertedPricedTag0;
        }
        let customPriceName = this.getCustomPriceName();
        if (customPriceName != null) {
            json["customPriceName"] = customPriceName;
        }
        let readStats = this.getReadStats();
        if (readStats != null) {
            let convertedReadStats0 = readStats.getJson(JsonTypeTagType.NONE);
            json["readStats"] = convertedReadStats0;
        }
        let reRid = this.getReRid();
        if (reRid != null) {
            json["reRid"] = String(reRid);
        }

        if (includeTypeTag) {
            json["__type"] = "Announcement";
        }
    }


    public getTypeId(): string {
    	return "Announcement";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.userad.Announcement";
    }
}
