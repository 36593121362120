/**
 * 自动生成， 请不要手工修改！
 */

export enum PricedTag {
  PORK1,
  PORK3,
  PIG_RIBS,
  PORK_BELLY,
  PORK_TROTTERS,
  PORK_TRIPE,
  PORK_CHITLING,
  MUTTON,
  LAMB_LEG,
  BEEF,
  BEEF_OFFAL,
  SIRLOIN,
  BEEF_TENDON,
  COOKED_BEEF,
  CHICKEN,
  SAN_HUANG,
  CARP,
  GRASS_CARP,
  SNAKEHEAD,
  SILVER_CARP,
  POMFRET,
  PRAWN,
  APPLE,
  ORANGE,
  BANANA,
  PEAR,
  ORANGE1,
  DURIAN,
  KIWI,
  TOFU,
  TOFU_SKIN,
  EGG,
  MUSHROOM,
  FUNGUS,
  CARROT,
  POTATO,
  TOMATO,
  PEPPER,
  GINGER,
}
