/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractCoworkInfo} from '../cowork/AbstractCoworkInfo'
import {ActivityStatus} from './ActivityStatus'
import {ActivityType} from './ActivityType'
import {CoworkInfo} from '../cowork/CoworkInfo'
import {CoworkSimpleView} from '../cowork/CoworkSimpleView'
import {CoworkStats} from '../cowork/CoworkStats'
import {CoworkType} from '../cowork/CoworkType'
import {EntityInfo} from '../entity/EntityInfo'
import {GeoHash} from '../geolocation/GeoHash'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {SelectTimes} from '../cowork/SelectTimes'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractActivityInfo extends AbstractCoworkInfo  {
    activityType: ActivityType;
    price: number;
    startTime: (number | string);
    endTime: (number | string);
    address: string;
    awardValue: number;
    status: ActivityStatus;
    participants: number;
    publisherName: string;
    byPlatform: boolean;

    constructor() {
    	super();
    }

    public getActivityType(): ActivityType {
    	return this.activityType;
    }

    public setActivityType(activityType: ActivityType) {
    	this.activityType = activityType;
    }

    public getPrice(): number {
    	return this.price;
    }

    public setPrice(price: number) {
    	this.price = price;
    }

    public getStartTime(): (number | string) {
    	return this.startTime;
    }

    public setStartTime(startTime: (number | string)) {
    	this.startTime = startTime;
    }

    public getEndTime(): (number | string) {
    	return this.endTime;
    }

    public setEndTime(endTime: (number | string)) {
    	this.endTime = endTime;
    }

    public getAddress(): string {
    	return this.address;
    }

    public setAddress(address: string) {
    	this.address = address;
    }

    public getAwardValue(): number {
    	return this.awardValue;
    }

    public setAwardValue(awardValue: number) {
    	this.awardValue = awardValue;
    }

    public getStatus(): ActivityStatus {
    	return this.status;
    }

    public setStatus(status: ActivityStatus) {
    	this.status = status;
    }

    public getParticipants(): number {
    	return this.participants;
    }

    public setParticipants(participants: number) {
    	this.participants = participants;
    }

    public getPublisherName(): string {
    	return this.publisherName;
    }

    public setPublisherName(publisherName: string) {
    	this.publisherName = publisherName;
    }

    public getByPlatform(): boolean {
    	return this.byPlatform;
    }

    public setByPlatform(byPlatform: boolean) {
    	this.byPlatform = byPlatform;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getKeywords(): Array<string>;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setKeywords(arg0: (Array<string> | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract getDescription(): string;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setDescription(arg0: (string | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let activityType = json["activityType"];
        if (activityType != null) {
            let convertedActivityType0 = ActivityType[<string>activityType];
            this.setActivityType(convertedActivityType0);
        }
        let price = json["price"];
        if (price != null) {
            this.setPrice(price);
        }
        let startTime = json["startTime"];
        if (startTime != null) {
            this.setStartTime(startTime);
        }
        let endTime = json["endTime"];
        if (endTime != null) {
            this.setEndTime(endTime);
        }
        let address = json["address"];
        if (address != null) {
            this.setAddress(address);
        }
        let awardValue = json["awardValue"];
        if (awardValue != null) {
            this.setAwardValue(awardValue);
        }
        let status = json["status"];
        if (status != null) {
            let convertedStatus0 = ActivityStatus[<string>status];
            this.setStatus(convertedStatus0);
        }
        let participants = json["participants"];
        if (participants != null) {
            this.setParticipants(participants);
        }
        let publisherName = json["publisherName"];
        if (publisherName != null) {
            this.setPublisherName(publisherName);
        }
        let byPlatform = json["byPlatform"];
        if (byPlatform != null) {
            this.setByPlatform(byPlatform);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let activityType = this.getActivityType();
        if (activityType != null) {
            let convertedActivityType0 = ActivityType[activityType];
            json["activityType"] = convertedActivityType0;
        }
        let price = this.getPrice();
        if (price != null) {
            json["price"] = price;
        }
        let startTime = this.getStartTime();
        if (startTime != null) {
            json["startTime"] = String(startTime);
        }
        let endTime = this.getEndTime();
        if (endTime != null) {
            json["endTime"] = String(endTime);
        }
        let address = this.getAddress();
        if (address != null) {
            json["address"] = address;
        }
        let awardValue = this.getAwardValue();
        if (awardValue != null) {
            json["awardValue"] = awardValue;
        }
        let status = this.getStatus();
        if (status != null) {
            let convertedStatus0 = ActivityStatus[status];
            json["status"] = convertedStatus0;
        }
        let participants = this.getParticipants();
        if (participants != null) {
            json["participants"] = participants;
        }
        let publisherName = this.getPublisherName();
        if (publisherName != null) {
            json["publisherName"] = publisherName;
        }
        let byPlatform = this.isByPlatform();
        if (byPlatform != null) {
            json["byPlatform"] = byPlatform;
        }

        if (includeTypeTag) {
            json["__type"] = "ActivityInfo";
        }
    }


    public getTypeId(): string {
    	return "ActivityInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.activity.ActivityInfo";
    }
}
