/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {CreditGrade} from '../credit/CreditGrade'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {RoleType} from '../entity/RoleType'
import {Serializer} from '../../serialize/Serializer'
import {TopicInfo} from '../entity/topic/TopicInfo'
import {TypeFactory} from '../../serialize/TypeFactory'


export class CoreGeoEntityDesc extends AbstractSerializable {
    entityInfo: EntityInfo;
    description: string;
    createTime: (number | string);
    relationGrade: CreditGrade;
    relationScore: number;
    netOfficialApprovals: number;
    productRelatedInfo: TopicInfo;
    roleType: RoleType;
    position: string;
    employerInfo: EntityInfo;
    endorserInfo: EntityInfo;

    constructor() {
        super();
    }

    public getEntityInfo(): EntityInfo {
        return this.entityInfo;
    }

    public setEntityInfo(entityInfo: EntityInfo) {
        this.entityInfo = entityInfo;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string) {
        this.description = description;
    }

    public getCreateTime(): (number | string) {
        return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
        this.createTime = createTime;
    }

    public getRelationGrade(): CreditGrade {
        return this.relationGrade;
    }

    public setRelationGrade(relationGrade: CreditGrade) {
        this.relationGrade = relationGrade;
    }

    public getRelationScore(): number {
        return this.relationScore;
    }

    public setRelationScore(relationScore: number) {
        this.relationScore = relationScore;
    }

    public getNetOfficialApprovals(): number {
        return this.netOfficialApprovals;
    }

    public setNetOfficialApprovals(netOfficialApprovals: number) {
        this.netOfficialApprovals = netOfficialApprovals;
    }

    public getProductRelatedInfo(): TopicInfo {
        return this.productRelatedInfo;
    }

    public setProductRelatedInfo(productRelatedInfo: TopicInfo) {
        this.productRelatedInfo = productRelatedInfo;
    }

    public getRoleType(): RoleType {
        return this.roleType;
    }

    public setRoleType(roleType: RoleType) {
        this.roleType = roleType;
    }

    public getPosition(): string {
        return this.position;
    }

    public setPosition(position: string) {
        this.position = position;
    }

    public getEmployerInfo(): EntityInfo {
        return this.employerInfo;
    }

    public setEmployerInfo(employerInfo: EntityInfo) {
        this.employerInfo = employerInfo;
    }

    public getEndorserInfo(): EntityInfo {
        return this.endorserInfo;
    }

    public setEndorserInfo(endorserInfo: EntityInfo) {
        this.endorserInfo = endorserInfo;
    }


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let entityInfo = json["entityInfo"];
        if (entityInfo != null) {
            let convertedEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(entityInfo, "__type", factory);
            this.setEntityInfo(convertedEntityInfo0);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let relationGrade = json["relationGrade"];
        if (relationGrade != null) {
            let convertedRelationGrade0 = CreditGrade[<string>relationGrade];
            this.setRelationGrade(convertedRelationGrade0);
        }
        let relationScore = json["relationScore"];
        if (relationScore != null) {
            this.setRelationScore(relationScore);
        }
        let netOfficialApprovals = json["netOfficialApprovals"];
        if (netOfficialApprovals != null) {
            this.setNetOfficialApprovals(netOfficialApprovals);
        }
        let productRelatedInfo = json["productRelatedInfo"];
        if (productRelatedInfo != null) {
            let convertedProductRelatedInfo0 = new TopicInfo();
            convertedProductRelatedInfo0.fillFromJson(productRelatedInfo, factory);
            this.setProductRelatedInfo(convertedProductRelatedInfo0);
        }
        let roleType = json["roleType"];
        if (roleType != null) {
            let convertedRoleType0 = RoleType[<string>roleType];
            this.setRoleType(convertedRoleType0);
        }
        let position = json["position"];
        if (position != null) {
            this.setPosition(position);
        }
        let employerInfo = json["employerInfo"];
        if (employerInfo != null) {
            let convertedEmployerInfo0 = Serializer.fillFromJsonObjectWithTypeTag(employerInfo, "__type", factory);
            this.setEmployerInfo(convertedEmployerInfo0);
        }
        let endorserInfo = json["endorserInfo"];
        if (endorserInfo != null) {
            let convertedEndorserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(endorserInfo, "__type", factory);
            this.setEndorserInfo(convertedEndorserInfo0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let entityInfo = this.getEntityInfo();
        if (entityInfo != null) {
            let convertedEntityInfo0 = entityInfo.getJson(JsonTypeTagType.TYPE);
            json["entityInfo"] = convertedEntityInfo0;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let relationGrade = this.getRelationGrade();
        if (relationGrade != null) {
            let convertedRelationGrade0 = CreditGrade[relationGrade];
            json["relationGrade"] = convertedRelationGrade0;
        }
        let relationScore = this.getRelationScore();
        if (relationScore != null) {
            json["relationScore"] = relationScore;
        }
        let netOfficialApprovals = this.getNetOfficialApprovals();
        if (netOfficialApprovals != null) {
            json["netOfficialApprovals"] = netOfficialApprovals;
        }
        let productRelatedInfo = this.getProductRelatedInfo();
        if (productRelatedInfo != null) {
            let convertedProductRelatedInfo0 = productRelatedInfo.getJson(JsonTypeTagType.NONE);
            json["productRelatedInfo"] = convertedProductRelatedInfo0;
        }
        let roleType = this.getRoleType();
        if (roleType != null) {
            let convertedRoleType0 = RoleType[roleType];
            json["roleType"] = convertedRoleType0;
        }
        let position = this.getPosition();
        if (position != null) {
            json["position"] = position;
        }
        let employerInfo = this.getEmployerInfo();
        if (employerInfo != null) {
            let convertedEmployerInfo0 = employerInfo.getJson(JsonTypeTagType.TYPE);
            json["employerInfo"] = convertedEmployerInfo0;
        }
        let endorserInfo = this.getEndorserInfo();
        if (endorserInfo != null) {
            let convertedEndorserInfo0 = endorserInfo.getJson(JsonTypeTagType.TYPE);
            json["endorserInfo"] = convertedEndorserInfo0;
        }
    }


    public getClassName(): string {
        return "com.gong_wei.common.datatype.geolocation.CoreGeoEntityDesc";
    }
}
