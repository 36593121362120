// @ts-nocheck
import { AbstractSimpleChannelSpec } from "./AbstractSimpleChannelSpec";

export class SimpleChannelSpec extends AbstractSimpleChannelSpec {
  constructor() {
    super();
  }

  getRid(): number {
    // Manual Code Here
  }
}
