/**
 * Created by Zhonghua on 13/11/2016.
 */

import { AbstractMessage } from "../message/AbstractMessage";
import { JsonTypeTagType } from "../serialize/JsonTypeTagType";
import { Serializer } from "../serialize/Serializer";
import { TypeFactory } from "../serialize/TypeFactory";
import { Guid } from "./Guid";

export abstract class AbstractServiceRequest extends AbstractMessage {
  private serviceName: string;
  private entityUrl: string;
  private entityId: number | string;
  private methodName: string;
  private withCallback: boolean;
  private parameterValues: any[];

  constructor() {
    super();
  }

  public fillId() {
    if (!this.getId()) {
      this.setId(Guid.MakeNew().ToString());
    }
  }

  public getId(): string {
    return super.getId() as string;
  }

  public setId(id: string) {
    super.setId(id);
  }

  public getServiceName(): string {
    return this.serviceName;
  }

  public setServiceName(serviceName: string) {
    this.serviceName = serviceName;
  }

  public getMethodName(): string {
    return this.methodName;
  }

  public setMethodName(methodName: string) {
    this.methodName = methodName;
  }

  public getParameterValues(): any[] {
    return this.parameterValues;
  }

  public setParameterValues(parameterValues: any[]) {
    this.parameterValues = parameterValues;
  }

  public getEntityUrl(): string {
    return this.entityUrl;
  }

  public setEntityUrl(entityUrl: string) {
    this.entityUrl = entityUrl;
  }

  public getEntityId(): number | string {
    return this.entityId;
  }

  public setEntityId(entityId: number | string) {
    this.entityId = entityId;
  }

  public isWithCallback(): boolean {
    return this.withCallback;
  }

  public setWithCallback(value: boolean) {
    this.withCallback = value;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const serviceName = json["serviceName"];
    if (serviceName != null) {
      this.setServiceName(serviceName);
    }
    const entityUrl = json["entityUrl"];
    if (entityUrl != null) {
      this.setEntityUrl(entityUrl);
    }
    const entityId = json["entityId"];
    if (entityId != null) {
      this.setEntityId(entityId);
    }
    const methodName = json["methodName"];
    if (methodName != null) {
      this.setMethodName(methodName);
    }
    const withCallback = json["withCallback"];
    if (withCallback != null) {
      this.setMethodName(withCallback);
    }
    const parameterValues = json["parameterValues"];
    if (parameterValues != null) {
      const convertedParameterValues = [];
      Serializer.fillFromJson(
        convertedParameterValues,
        parameterValues,
        factory
      );
      this.setParameterValues(convertedParameterValues);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const id = this.getId();
    if (id != null) {
      json["id"] = id;
    }
    const serviceName = this.getServiceName();
    if (serviceName != null) {
      json["serviceName"] = serviceName;
    }
    const entityUrl = this.getEntityUrl();
    if (entityUrl != null) {
      json["entityUrl"] = entityUrl;
    }
    const entityId = this.getEntityId();
    if (entityId != null) {
      json["entityId"] = String(entityId);
    }
    const methodName = this.getMethodName();
    if (methodName != null) {
      json["methodName"] = methodName;
    }
    const withCallback = this.isWithCallback();
    if (withCallback != null) {
      json["withCallback"] = withCallback;
    }
    const parameterValues = this.getParameterValues();
    if (parameterValues != null) {
      const convertedParameterValues0 = [];
      for (const i in parameterValues) {
        const convertedParameterValues10 = parameterValues[i];
        const convertedParameterValues1 = Serializer.getJson(
          convertedParameterValues10,
          JsonTypeTagType.CLASS
        );
        convertedParameterValues0.push(convertedParameterValues1);
      }
      json["parameterValues"] = convertedParameterValues0;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.message.service.spi.AbstractServiceRequest";
  }
}
