/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {EnterpriseServiceCategory} from '../EnterpriseServiceCategory'
import {EnterpriseServiceType} from '../EnterpriseServiceType'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {PeriodType} from '../../../PeriodType'
import {RoleType} from '../../../entity/RoleType'
import {ServiceDeliveryType} from '../ServiceDeliveryType'
import {ServiceProviderType} from '../ServiceProviderType'
import {ServiceUseType} from '../ServiceUseType'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export class EnterpriseServiceTradeKeyInfo extends AbstractSerializable  {
    position: number;
    serviceType: EnterpriseServiceType;
    serviceTag: EnterpriseServiceCategory;
    serviceTypeName: string;
    serviceTypeDisplayName: string;
    serviceTagName: string;
    serviceDeliveryType: ServiceDeliveryType;
    serviceUseType: ServiceUseType;
    serviceProviderType: ServiceProviderType;
    enterpriseServiceTradeId: (number | string);
    serviceTitle: string;
    brief: string;
    description: string;
    imgUrls: Array<string>;
    activityId: (number | string);
    discount: number;
    unitPrice: number;
    originalUnitPrice: number;
    serviceQuota: number;
    remainingServiceQuota: number;
    otcPriced: boolean;
    packageOnly: boolean;
    offered: boolean;
    couponId: (number | string);
    couponValue: number;
    startDay: number;
    endDay: number;
    periodType: PeriodType;
    servicePeriod: number;
    entitledToUse: boolean;
    entitledRole: RoleType;
    exclusiveCustomServiceSessionSupported: boolean;

    constructor() {
    	super();
    }

    public getPosition(): number {
    	return this.position;
    }

    public setPosition(position: number) {
    	this.position = position;
    }

    public getServiceType(): EnterpriseServiceType {
    	return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
    	this.serviceType = serviceType;
    }

    public getServiceTag(): EnterpriseServiceCategory {
    	return this.serviceTag;
    }

    public setServiceTag(serviceTag: EnterpriseServiceCategory) {
    	this.serviceTag = serviceTag;
    }

    public getServiceTypeName(): string {
    	return this.serviceTypeName;
    }

    public setServiceTypeName(serviceTypeName: string) {
    	this.serviceTypeName = serviceTypeName;
    }

    public getServiceTypeDisplayName(): string {
    	return this.serviceTypeDisplayName;
    }

    public setServiceTypeDisplayName(serviceTypeDisplayName: string) {
    	this.serviceTypeDisplayName = serviceTypeDisplayName;
    }

    public getServiceTagName(): string {
    	return this.serviceTagName;
    }

    public setServiceTagName(serviceTagName: string) {
    	this.serviceTagName = serviceTagName;
    }

    public getServiceDeliveryType(): ServiceDeliveryType {
    	return this.serviceDeliveryType;
    }

    public setServiceDeliveryType(serviceDeliveryType: ServiceDeliveryType) {
    	this.serviceDeliveryType = serviceDeliveryType;
    }

    public getServiceUseType(): ServiceUseType {
    	return this.serviceUseType;
    }

    public setServiceUseType(serviceUseType: ServiceUseType) {
    	this.serviceUseType = serviceUseType;
    }

    public getServiceProviderType(): ServiceProviderType {
    	return this.serviceProviderType;
    }

    public setServiceProviderType(serviceProviderType: ServiceProviderType) {
    	this.serviceProviderType = serviceProviderType;
    }

    public getEnterpriseServiceTradeId(): (number | string) {
    	return this.enterpriseServiceTradeId;
    }

    public setEnterpriseServiceTradeId(enterpriseServiceTradeId: (number | string)) {
    	this.enterpriseServiceTradeId = enterpriseServiceTradeId;
    }

    public getServiceTitle(): string {
    	return this.serviceTitle;
    }

    public setServiceTitle(serviceTitle: string) {
    	this.serviceTitle = serviceTitle;
    }

    public getBrief(): string {
    	return this.brief;
    }

    public setBrief(brief: string) {
    	this.brief = brief;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getImgUrls(): Array<string> {
    	return this.imgUrls;
    }

    public setImgUrls(imgUrls: Array<string>) {
    	this.imgUrls = imgUrls;
    }

    public getActivityId(): (number | string) {
    	return this.activityId;
    }

    public setActivityId(activityId: (number | string)) {
    	this.activityId = activityId;
    }

    public getDiscount(): number {
    	return this.discount;
    }

    public setDiscount(discount: number) {
    	this.discount = discount;
    }

    public getUnitPrice(): number {
    	return this.unitPrice;
    }

    public setUnitPrice(unitPrice: number) {
    	this.unitPrice = unitPrice;
    }

    public getOriginalUnitPrice(): number {
    	return this.originalUnitPrice;
    }

    public setOriginalUnitPrice(originalUnitPrice: number) {
    	this.originalUnitPrice = originalUnitPrice;
    }

    public getServiceQuota(): number {
    	return this.serviceQuota;
    }

    public setServiceQuota(serviceQuota: number) {
    	this.serviceQuota = serviceQuota;
    }

    public getRemainingServiceQuota(): number {
    	return this.remainingServiceQuota;
    }

    public setRemainingServiceQuota(remainingServiceQuota: number) {
    	this.remainingServiceQuota = remainingServiceQuota;
    }

    public isOtcPriced(): boolean {
    	return this.otcPriced;
    }

    public setOtcPriced(otcPriced: boolean) {
    	this.otcPriced = otcPriced;
    }

    public isPackageOnly(): boolean {
    	return this.packageOnly;
    }

    public setPackageOnly(packageOnly: boolean) {
    	this.packageOnly = packageOnly;
    }

    public isOffered(): boolean {
    	return this.offered;
    }

    public setOffered(offered: boolean) {
    	this.offered = offered;
    }

    public getCouponId(): (number | string) {
    	return this.couponId;
    }

    public setCouponId(couponId: (number | string)) {
    	this.couponId = couponId;
    }

    public getCouponValue(): number {
    	return this.couponValue;
    }

    public setCouponValue(couponValue: number) {
    	this.couponValue = couponValue;
    }

    public getStartDay(): number {
    	return this.startDay;
    }

    public setStartDay(startDay: number) {
    	this.startDay = startDay;
    }

    public getEndDay(): number {
    	return this.endDay;
    }

    public setEndDay(endDay: number) {
    	this.endDay = endDay;
    }

    public getPeriodType(): PeriodType {
    	return this.periodType;
    }

    public setPeriodType(periodType: PeriodType) {
    	this.periodType = periodType;
    }

    public getServicePeriod(): number {
    	return this.servicePeriod;
    }

    public setServicePeriod(servicePeriod: number) {
    	this.servicePeriod = servicePeriod;
    }

    public isEntitledToUse(): boolean {
    	return this.entitledToUse;
    }

    public setEntitledToUse(entitledToUse: boolean) {
    	this.entitledToUse = entitledToUse;
    }

    public getEntitledRole(): RoleType {
    	return this.entitledRole;
    }

    public setEntitledRole(entitledRole: RoleType) {
    	this.entitledRole = entitledRole;
    }

    public getExclusiveCustomServiceSessionSupported(): boolean {
    	return this.exclusiveCustomServiceSessionSupported;
    }

    public setExclusiveCustomServiceSessionSupported(exclusiveCustomServiceSessionSupported: boolean) {
    	this.exclusiveCustomServiceSessionSupported = exclusiveCustomServiceSessionSupported;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let position = json["position"];
        if (position != null) {
            this.setPosition(position);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let serviceTag = json["serviceTag"];
        if (serviceTag != null) {
            let convertedServiceTag0 = EnterpriseServiceCategory[<string>serviceTag];
            this.setServiceTag(convertedServiceTag0);
        }
        let serviceTypeName = json["serviceTypeName"];
        if (serviceTypeName != null) {
            this.setServiceTypeName(serviceTypeName);
        }
        let serviceTypeDisplayName = json["serviceTypeDisplayName"];
        if (serviceTypeDisplayName != null) {
            this.setServiceTypeDisplayName(serviceTypeDisplayName);
        }
        let serviceTagName = json["serviceTagName"];
        if (serviceTagName != null) {
            this.setServiceTagName(serviceTagName);
        }
        let serviceDeliveryType = json["serviceDeliveryType"];
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[<string>serviceDeliveryType];
            this.setServiceDeliveryType(convertedServiceDeliveryType0);
        }
        let serviceUseType = json["serviceUseType"];
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[<string>serviceUseType];
            this.setServiceUseType(convertedServiceUseType0);
        }
        let serviceProviderType = json["serviceProviderType"];
        if (serviceProviderType != null) {
            let convertedServiceProviderType0 = ServiceProviderType[<string>serviceProviderType];
            this.setServiceProviderType(convertedServiceProviderType0);
        }
        let enterpriseServiceTradeId = json["enterpriseServiceTradeId"];
        if (enterpriseServiceTradeId != null) {
            this.setEnterpriseServiceTradeId(enterpriseServiceTradeId);
        }
        let serviceTitle = json["serviceTitle"];
        if (serviceTitle != null) {
            this.setServiceTitle(serviceTitle);
        }
        let brief = json["brief"];
        if (brief != null) {
            this.setBrief(brief);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let imgUrls = json["imgUrls"];
        if (imgUrls != null) {
            let convertedImgUrls0 = [];
            imgUrls = imgUrls[1];
            for (let i in imgUrls) {
                let convertedImgUrls10 = imgUrls[i];
                convertedImgUrls0.push(convertedImgUrls10);
            }
            this.setImgUrls(convertedImgUrls0);
        }
        let activityId = json["activityId"];
        if (activityId != null) {
            this.setActivityId(activityId);
        }
        let discount = json["discount"];
        if (discount != null) {
            this.setDiscount(discount);
        }
        let unitPrice = json["unitPrice"];
        if (unitPrice != null) {
            this.setUnitPrice(unitPrice);
        }
        let originalUnitPrice = json["originalUnitPrice"];
        if (originalUnitPrice != null) {
            this.setOriginalUnitPrice(originalUnitPrice);
        }
        let serviceQuota = json["serviceQuota"];
        if (serviceQuota != null) {
            this.setServiceQuota(serviceQuota);
        }
        let remainingServiceQuota = json["remainingServiceQuota"];
        if (remainingServiceQuota != null) {
            this.setRemainingServiceQuota(remainingServiceQuota);
        }
        let otcPriced = json["otcPriced"];
        if (otcPriced != null) {
            this.setOtcPriced(otcPriced);
        }
        let packageOnly = json["packageOnly"];
        if (packageOnly != null) {
            this.setPackageOnly(packageOnly);
        }
        let offered = json["offered"];
        if (offered != null) {
            this.setOffered(offered);
        }
        let couponId = json["couponId"];
        if (couponId != null) {
            this.setCouponId(couponId);
        }
        let couponValue = json["couponValue"];
        if (couponValue != null) {
            this.setCouponValue(couponValue);
        }
        let startDay = json["startDay"];
        if (startDay != null) {
            this.setStartDay(startDay);
        }
        let endDay = json["endDay"];
        if (endDay != null) {
            this.setEndDay(endDay);
        }
        let periodType = json["periodType"];
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[<string>periodType];
            this.setPeriodType(convertedPeriodType0);
        }
        let servicePeriod = json["servicePeriod"];
        if (servicePeriod != null) {
            this.setServicePeriod(servicePeriod);
        }
        let entitledToUse = json["entitledToUse"];
        if (entitledToUse != null) {
            this.setEntitledToUse(entitledToUse);
        }
        let entitledRole = json["entitledRole"];
        if (entitledRole != null) {
            let convertedEntitledRole0 = RoleType[<string>entitledRole];
            this.setEntitledRole(convertedEntitledRole0);
        }
        let exclusiveCustomServiceSessionSupported = json["exclusiveCustomServiceSessionSupported"];
        if (exclusiveCustomServiceSessionSupported != null) {
            this.setExclusiveCustomServiceSessionSupported(exclusiveCustomServiceSessionSupported);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let position = this.getPosition();
        if (position != null) {
            json["position"] = position;
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let serviceTag = this.getServiceTag();
        if (serviceTag != null) {
            let convertedServiceTag0 = EnterpriseServiceCategory[serviceTag];
            json["serviceTag"] = convertedServiceTag0;
        }
        let serviceTypeName = this.getServiceTypeName();
        if (serviceTypeName != null) {
            json["serviceTypeName"] = serviceTypeName;
        }
        let serviceTypeDisplayName = this.getServiceTypeDisplayName();
        if (serviceTypeDisplayName != null) {
            json["serviceTypeDisplayName"] = serviceTypeDisplayName;
        }
        let serviceTagName = this.getServiceTagName();
        if (serviceTagName != null) {
            json["serviceTagName"] = serviceTagName;
        }
        let serviceDeliveryType = this.getServiceDeliveryType();
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[serviceDeliveryType];
            json["serviceDeliveryType"] = convertedServiceDeliveryType0;
        }
        let serviceUseType = this.getServiceUseType();
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[serviceUseType];
            json["serviceUseType"] = convertedServiceUseType0;
        }
        let serviceProviderType = this.getServiceProviderType();
        if (serviceProviderType != null) {
            let convertedServiceProviderType0 = ServiceProviderType[serviceProviderType];
            json["serviceProviderType"] = convertedServiceProviderType0;
        }
        let enterpriseServiceTradeId = this.getEnterpriseServiceTradeId();
        if (enterpriseServiceTradeId != null) {
            json["enterpriseServiceTradeId"] = String(enterpriseServiceTradeId);
        }
        let serviceTitle = this.getServiceTitle();
        if (serviceTitle != null) {
            json["serviceTitle"] = serviceTitle;
        }
        let brief = this.getBrief();
        if (brief != null) {
            json["brief"] = brief;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let imgUrls = this.getImgUrls();
        if (imgUrls != null) {
            let convertedImgUrls0 = [];
            for (let i in imgUrls) {
                let convertedImgUrls10 = imgUrls[i];
                convertedImgUrls0.push(convertedImgUrls10);
            }
            let convertedImgUrlsWithType1 = ["java.util.ArrayList", convertedImgUrls0];
            json["imgUrls"] = convertedImgUrlsWithType1;
        }
        let activityId = this.getActivityId();
        if (activityId != null) {
            json["activityId"] = String(activityId);
        }
        let discount = this.getDiscount();
        if (discount != null) {
            json["discount"] = discount;
        }
        let unitPrice = this.getUnitPrice();
        if (unitPrice != null) {
            json["unitPrice"] = unitPrice;
        }
        let originalUnitPrice = this.getOriginalUnitPrice();
        if (originalUnitPrice != null) {
            json["originalUnitPrice"] = originalUnitPrice;
        }
        let serviceQuota = this.getServiceQuota();
        if (serviceQuota != null) {
            json["serviceQuota"] = serviceQuota;
        }
        let remainingServiceQuota = this.getRemainingServiceQuota();
        if (remainingServiceQuota != null) {
            json["remainingServiceQuota"] = remainingServiceQuota;
        }
        let otcPriced = this.isOtcPriced();
        if (otcPriced != null) {
            json["otcPriced"] = otcPriced;
        }
        let packageOnly = this.isPackageOnly();
        if (packageOnly != null) {
            json["packageOnly"] = packageOnly;
        }
        let offered = this.isOffered();
        if (offered != null) {
            json["offered"] = offered;
        }
        let couponId = this.getCouponId();
        if (couponId != null) {
            json["couponId"] = String(couponId);
        }
        let couponValue = this.getCouponValue();
        if (couponValue != null) {
            json["couponValue"] = couponValue;
        }
        let startDay = this.getStartDay();
        if (startDay != null) {
            json["startDay"] = startDay;
        }
        let endDay = this.getEndDay();
        if (endDay != null) {
            json["endDay"] = endDay;
        }
        let periodType = this.getPeriodType();
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[periodType];
            json["periodType"] = convertedPeriodType0;
        }
        let servicePeriod = this.getServicePeriod();
        if (servicePeriod != null) {
            json["servicePeriod"] = servicePeriod;
        }
        let entitledToUse = this.isEntitledToUse();
        if (entitledToUse != null) {
            json["entitledToUse"] = entitledToUse;
        }
        let entitledRole = this.getEntitledRole();
        if (entitledRole != null) {
            let convertedEntitledRole0 = RoleType[entitledRole];
            json["entitledRole"] = convertedEntitledRole0;
        }
        let exclusiveCustomServiceSessionSupported = this.getExclusiveCustomServiceSessionSupported();
        if (exclusiveCustomServiceSessionSupported != null) {
            json["exclusiveCustomServiceSessionSupported"] = exclusiveCustomServiceSessionSupported;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.trade.EnterpriseServiceTradeKeyInfo";
    }
}
