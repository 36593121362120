/**
 * 自动生成， 请不要手工修改！
 */

export enum ActivityType {
  PLATFORM,
  SPORT,
  ENTERTAINMENT,
  EDUCATION,
  CHARITY,
  GROUP_PURCHASE,
  PRODUCT_TRIAL,
  DO_TOGETHER,
  BUY_TOGETHER,
  COMMERCIAL,
  SOCIAL,
  COOP,
  TRAINING,
  ASSOCIATION,
  GENERAL,
}
