/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {ActionType} from './ActionType'
import {CoworkType} from '../cowork/CoworkType'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {MessageType} from '../../message/MessageContentType'
import {MessageContent} from '../../message/MessageContent'
import {RequestReplyData} from './RequestReplyData'
import {RequestType} from './RequestType'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractRequestAction extends AbstractSerializable implements MessageContent {
    requestId: (number | string);
    requestType: RequestType;
    targetType: CoworkType;
    targetEntityId: (number | string);
    targetId: (number | string);
    actorId: (number | string);
    actionType: ActionType;
    actionNotes: string;
    replyData: RequestReplyData;
    offerFreeMembership: boolean;

    constructor() {
        super();
    }

    public getRequestId(): (number | string) {
        return this.requestId;
    }

    /**
     * 必选数据： 请求ID
     */
    public setRequestId(requestId: (number | string)) {
        this.requestId = requestId;
    }

    public getRequestType(): RequestType {
        return this.requestType;
    }

    /**
     * 必选数据： 请求类型
     */
    public setRequestType(requestType: RequestType) {
        this.requestType = requestType;
    }

    public getTargetType(): CoworkType {
        return this.targetType;
    }

    /**
     * 必选数据： 请求对象类型
     */
    public setTargetType(targetType: CoworkType) {
        this.targetType = targetType;
    }

    public getTargetEntityId(): (number | string) {
        return this.targetEntityId;
    }

    /**
     * 必选数据： 请求对象所属社区ID
     */
    public setTargetEntityId(targetEntityId: (number | string)) {
        this.targetEntityId = targetEntityId;
    }

    public getTargetId(): (number | string) {
        return this.targetId;
    }

    /**
     * 必选数据： 请求对象ID
     */
    public setTargetId(targetId: (number | string)) {
        this.targetId = targetId;
    }

    public getActorId(): (number | string) {
        return this.actorId;
    }

    /**
     * 必选数据： 请求响应者ID
     */
    public setActorId(actorId: (number | string)) {
        this.actorId = actorId;
    }

    public getActionType(): ActionType {
        return this.actionType;
    }

    /**
     * 必选数据： 活动类型
     */
    public setActionType(actionType: ActionType) {
        this.actionType = actionType;
    }

    public getActionNotes(): string {
        return this.actionNotes;
    }

    /**
     * 可选数据： 活动注记
     */
    public setActionNotes(actionNotes: string) {
        this.actionNotes = actionNotes;
    }

    public getReplyData(): RequestReplyData {
        return this.replyData;
    }

    /**
     * 可选数据： 请求反馈数据
     */
    public setReplyData(replyData: RequestReplyData) {
        this.replyData = replyData;
    }

    public isOfferFreeMembership(): boolean {
        return this.offerFreeMembership;
    }

    public setOfferFreeMembership(offerFreeMembership: boolean) {
        this.offerFreeMembership = offerFreeMembership;
    }


    abstract getMessageType(): MessageType


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let requestId = json["requestId"];
        if (requestId != null) {
            this.setRequestId(requestId);
        }
        let requestType = json["requestType"];
        if (requestType != null) {
            let convertedRequestType0 = RequestType[<string>requestType];
            this.setRequestType(convertedRequestType0);
        }
        let targetType = json["targetType"];
        if (targetType != null) {
            let convertedTargetType0 = CoworkType[<string>targetType];
            this.setTargetType(convertedTargetType0);
        }
        let targetEntityId = json["targetEntityId"];
        if (targetEntityId != null) {
            this.setTargetEntityId(targetEntityId);
        }
        let targetId = json["targetId"];
        if (targetId != null) {
            this.setTargetId(targetId);
        }
        let actorId = json["actorId"];
        if (actorId != null) {
            this.setActorId(actorId);
        }
        let actionType = json["actionType"];
        if (actionType != null) {
            let convertedActionType0 = ActionType[<string>actionType];
            this.setActionType(convertedActionType0);
        }
        let actionNotes = json["actionNotes"];
        if (actionNotes != null) {
            this.setActionNotes(actionNotes);
        }
        let replyData = json["replyData"];
        if (replyData != null) {
            let convertedReplyData0 = Serializer.fillFromJsonObjectWithTypeTag(replyData, "__type", factory);
            this.setReplyData(convertedReplyData0);
        }
        let offerFreeMembership = json["offerFreeMembership"];
        if (offerFreeMembership != null) {
            this.setOfferFreeMembership(offerFreeMembership);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let requestId = this.getRequestId();
        if (requestId != null) {
            json["requestId"] = String(requestId);
        }
        let requestType = this.getRequestType();
        if (requestType != null) {
            let convertedRequestType0 = RequestType[requestType];
            json["requestType"] = convertedRequestType0;
        }
        let targetType = this.getTargetType();
        if (targetType != null) {
            let convertedTargetType0 = CoworkType[targetType];
            json["targetType"] = convertedTargetType0;
        }
        let targetEntityId = this.getTargetEntityId();
        if (targetEntityId != null) {
            json["targetEntityId"] = String(targetEntityId);
        }
        let targetId = this.getTargetId();
        if (targetId != null) {
            json["targetId"] = String(targetId);
        }
        let actorId = this.getActorId();
        if (actorId != null) {
            json["actorId"] = String(actorId);
        }
        let actionType = this.getActionType();
        if (actionType != null) {
            let convertedActionType0 = ActionType[actionType];
            json["actionType"] = convertedActionType0;
        }
        let actionNotes = this.getActionNotes();
        if (actionNotes != null) {
            json["actionNotes"] = actionNotes;
        }
        let replyData = this.getReplyData();
        if (replyData != null) {
            let convertedReplyData0 = replyData.getJson(JsonTypeTagType.TYPE);
            json["replyData"] = convertedReplyData0;
        }
        let offerFreeMembership = this.isOfferFreeMembership();
        if (offerFreeMembership != null) {
            json["offerFreeMembership"] = offerFreeMembership;
        }

        if (includeTypeTag) {
            json["__type"] = "RequestAction";
        }
    }


    public getTypeId(): string {
        return "RequestAction";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.request.RequestAction";
    }
}
