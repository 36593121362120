/**
 * 自动生成， 请不要手工修改！
 */

import { CoworkSimpleView } from "../../cowork/CoworkSimpleView";
import { CoworkStats } from "../../cowork/CoworkStats";
import { GeneralCoworkStats } from "../../cowork/GeneralCoworkStats";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export abstract class AbstractQuestionStats extends GeneralCoworkStats {
  totalSubscribers: number;
  totalAnswers: number;
  totalSponsorAmount: number;

  constructor() {
    super();
  }

  public getTotalSubscribers(): number {
    return this.totalSubscribers;
  }

  public setTotalSubscribers(totalSubscribers: number) {
    this.totalSubscribers = totalSubscribers;
  }

  public getTotalAnswers(): number {
    return this.totalAnswers;
  }

  public setTotalAnswers(totalAnswers: number) {
    this.totalAnswers = totalAnswers;
  }

  public getTotalSponsorAmount(): number {
    return this.totalSponsorAmount;
  }

  public setTotalSponsorAmount(totalSponsorAmount: number) {
    this.totalSponsorAmount = totalSponsorAmount;
  }

  abstract addInfoToView(arg0: CoworkSimpleView | null);

  abstract updateCoworkStats(arg0: CoworkStats | null);

  public fillFromJson(json: Object, factory?: TypeFactory) {
    if (factory) {
      super.fillFromJson(json, factory);
    } else {
      super.fillFromJson(json);
    }
    const totalSubscribers = json["totalSubscribers"];
    if (totalSubscribers != null) {
      this.setTotalSubscribers(totalSubscribers);
    }
    const totalAnswers = json["totalAnswers"];
    if (totalAnswers != null) {
      this.setTotalAnswers(totalAnswers);
    }
    const totalSponsorAmount = json["totalSponsorAmount"];
    if (totalSponsorAmount != null) {
      this.setTotalSponsorAmount(totalSponsorAmount);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const totalSubscribers = this.getTotalSubscribers();
    if (totalSubscribers != null) {
      json["totalSubscribers"] = totalSubscribers;
    }
    const totalAnswers = this.getTotalAnswers();
    if (totalAnswers != null) {
      json["totalAnswers"] = totalAnswers;
    }
    const totalSponsorAmount = this.getTotalSponsorAmount();
    if (totalSponsorAmount != null) {
      json["totalSponsorAmount"] = totalSponsorAmount;
    }

    if (includeTypeTag) {
      json["__type"] = "QuestionStats";
    }
  }

  public getTypeId(): string {
    return "QuestionStats";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.ipsphere.qa.QuestionStats";
  }
}
