/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { GWServiceType } from "../general/service/GWServiceType";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { PriceType } from "./PriceType";
import { ServiceQuotaType } from "../general/service/ServiceQuotaType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class PriceQuote extends AbstractSerializable {
  priceId: number;
  packageId: number | string;
  serviceId: number | string;
  serviceType: GWServiceType;
  priceType: PriceType;
  quotaType: ServiceQuotaType;
  quota: number;
  price: number;
  actualPrice: number;

  constructor() {
    super();
  }

  public getPriceId(): number {
    return this.priceId;
  }

  public setPriceId(priceId: number) {
    this.priceId = priceId;
  }

  public getPackageId(): number | string {
    return this.packageId;
  }

  public setPackageId(packageId: number | string) {
    this.packageId = packageId;
  }

  public getServiceId(): number | string {
    return this.serviceId;
  }

  public setServiceId(serviceId: number | string) {
    this.serviceId = serviceId;
  }

  public getServiceType(): GWServiceType {
    return this.serviceType;
  }

  public setServiceType(serviceType: GWServiceType) {
    this.serviceType = serviceType;
  }

  public getPriceType(): PriceType {
    return this.priceType;
  }

  public setPriceType(priceType: PriceType) {
    this.priceType = priceType;
  }

  public getQuotaType(): ServiceQuotaType {
    return this.quotaType;
  }

  public setQuotaType(quotaType: ServiceQuotaType) {
    this.quotaType = quotaType;
  }

  public getQuota(): number {
    return this.quota;
  }

  public setQuota(quota: number) {
    this.quota = quota;
  }

  public getPrice(): number {
    return this.price;
  }

  public setPrice(price: number) {
    this.price = price;
  }

  public getActualPrice(): number {
    return this.actualPrice;
  }

  public setActualPrice(actualPrice: number) {
    this.actualPrice = actualPrice;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const priceId = json["priceId"];
    if (priceId != null) {
      this.setPriceId(priceId);
    }
    const packageId = json["packageId"];
    if (packageId != null) {
      this.setPackageId(packageId);
    }
    const serviceId = json["serviceId"];
    if (serviceId != null) {
      this.setServiceId(serviceId);
    }
    const serviceType = json["serviceType"];
    if (serviceType != null) {
      const convertedServiceType0 = GWServiceType[<string>serviceType];
      this.setServiceType(convertedServiceType0);
    }
    const priceType = json["priceType"];
    if (priceType != null) {
      const convertedPriceType0 = PriceType[<string>priceType];
      this.setPriceType(convertedPriceType0);
    }
    const quotaType = json["quotaType"];
    if (quotaType != null) {
      const convertedQuotaType0 = ServiceQuotaType[<string>quotaType];
      this.setQuotaType(convertedQuotaType0);
    }
    const quota = json["quota"];
    if (quota != null) {
      this.setQuota(quota);
    }
    const price = json["price"];
    if (price != null) {
      this.setPrice(price);
    }
    const actualPrice = json["actualPrice"];
    if (actualPrice != null) {
      this.setActualPrice(actualPrice);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const priceId = this.getPriceId();
    if (priceId != null) {
      json["priceId"] = priceId;
    }
    const packageId = this.getPackageId();
    if (packageId != null) {
      json["packageId"] = String(packageId);
    }
    const serviceId = this.getServiceId();
    if (serviceId != null) {
      json["serviceId"] = String(serviceId);
    }
    const serviceType = this.getServiceType();
    if (serviceType != null) {
      const convertedServiceType0 = GWServiceType[serviceType];
      json["serviceType"] = convertedServiceType0;
    }
    const priceType = this.getPriceType();
    if (priceType != null) {
      const convertedPriceType0 = PriceType[priceType];
      json["priceType"] = convertedPriceType0;
    }
    const quotaType = this.getQuotaType();
    if (quotaType != null) {
      const convertedQuotaType0 = ServiceQuotaType[quotaType];
      json["quotaType"] = convertedQuotaType0;
    }
    const quota = this.getQuota();
    if (quota != null) {
      json["quota"] = quota;
    }
    const price = this.getPrice();
    if (price != null) {
      json["price"] = price;
    }
    const actualPrice = this.getActualPrice();
    if (actualPrice != null) {
      json["actualPrice"] = actualPrice;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.business.price.PriceQuote";
  }
}
