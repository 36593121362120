/**
 * 自动生成， 请不要手工修改！
 */

import {Activity} from './Activity'
import {EntityInfo} from '../entity/EntityInfo'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../keyword/KeywordEntity'
import {MessageType} from '../../message/MessageContentType'
import {QllCountry} from '../international/QllCountry'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractCommercialActivity extends Activity  {
    topicInfo: EntityInfo;
    enterpriseInfo: EntityInfo;

    constructor() {
    	super();
    }

    public getTopicInfo(): EntityInfo {
    	return this.topicInfo;
    }

    public setTopicInfo(topicInfo: EntityInfo) {
    	this.topicInfo = topicInfo;
    }

    public getEnterpriseInfo(): EntityInfo {
    	return this.enterpriseInfo;
    }

    public setEnterpriseInfo(enterpriseInfo: EntityInfo) {
    	this.enterpriseInfo = enterpriseInfo;
    }


    abstract getTargetEntityInfo(): EntityInfo;

    abstract getCountry(): QllCountry;

    abstract getEntity(arg0: (string | null)): KeywordEntity;

    abstract getInitiatorId(): (number | string);

    abstract getReferencedEntityId(): (number | string);

    abstract getInitiatorImageUrl(): string;

    abstract getObjectMainImageUrl(): string;

    abstract getMessageType(): MessageType;


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let topicInfo = json["topicInfo"];
        if (topicInfo != null) {
            let convertedTopicInfo0 = Serializer.fillFromJsonObjectWithTypeTag(topicInfo, "__type", factory);
            this.setTopicInfo(convertedTopicInfo0);
        }
        let enterpriseInfo = json["enterpriseInfo"];
        if (enterpriseInfo != null) {
            let convertedEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(enterpriseInfo, "__type", factory);
            this.setEnterpriseInfo(convertedEnterpriseInfo0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let topicInfo = this.getTopicInfo();
        if (topicInfo != null) {
            let convertedTopicInfo0 = topicInfo.getJson(JsonTypeTagType.TYPE);
            json["topicInfo"] = convertedTopicInfo0;
        }
        let enterpriseInfo = this.getEnterpriseInfo();
        if (enterpriseInfo != null) {
            let convertedEnterpriseInfo0 = enterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["enterpriseInfo"] = convertedEnterpriseInfo0;
        }

        if (includeTypeTag) {
            json["__type"] = "CommercialActivity";
        }
    }


    public getTypeId(): string {
    	return "CommercialActivity";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.activity.CommercialActivity";
    }
}
