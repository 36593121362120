/**
 * 自动生成， 请不要手工修改！
 */


export enum PaymentMethod {
    ALI_PAY,
    WEI_PAY,
    BANK_TRANSFER,
    COVERED_BY_PKG,
    COVERED_BY_QUOTA,
    QLL_OFFER,
    POINT,
    CASH
}
