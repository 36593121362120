/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractClientServiceAdapter} from '../../../common/AbstractClientServiceAdapter'
import {AbstractEnterpriseServiceTrade} from '../../../datatype/business/service/trade/AbstractEnterpriseServiceTrade'
import {BiConsumer} from '../../../util/BiConsumer'
import {BusinessContactInfo} from '../../../datatype/business/BusinessContactInfo'
import {EnterpriseServiceOrderService} from '../../../api/business/service/EnterpriseServiceOrderService'
import {EnterpriseServiceOrder} from '../../../datatype/business/order/EnterpriseServiceOrder'
import {EnterpriseServicePackageTrade} from '../../../datatype/business/service/trade/EnterpriseServicePackageTrade'
import {EnterpriseServiceTradeInfo} from '../../../datatype/business/service/trade/EnterpriseServiceTradeInfo'
import {InvoiceRequest} from '../../../datatype/business/order/InvoiceRequest'
import {OrderQuerySpec} from '../../../datatype/business/order/OrderQuerySpec'
import {OrderStatus} from '../../../datatype/business/order/OrderStatus'
import {PageableData} from '../../../datatype/paging/PageableData'
import {PageBlockAccessSpec} from '../../../datatype/paging/PageBlockAccessSpec'
import {PaymentSpec} from '../../../datatype/business/payment/PaymentSpec'
import {ServiceConsumers} from '../../../util/ServiceConsumers'
import {ServiceRequest} from '../../../common/ServiceRequest'
import {SessionContext} from '../../../session/SessionContext'
import {ThirdPartyPayClientInfo} from '../../../datatype/business/payment/ThirdPartyPayClientInfo'


export class EnterpriseServiceOrderServiceProxy extends AbstractClientServiceAdapter implements EnterpriseServiceOrderService {

    public constructor() {
        super("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService");
    }

    public addContactInfo(sessionContext: (SessionContext | null), orderId: (number | string | null), enterpriseId: (number | string | null), contactInfo: (BusinessContactInfo | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "addContactInfo", [sessionContext, orderId, enterpriseId, contactInfo]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public addEnterpriseServiceOrder(sessionContext: (SessionContext | null), enterpriseServiceOrder: (EnterpriseServiceOrder | null), resultHandler: BiConsumer<AbstractEnterpriseServiceTrade, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "addEnterpriseServiceOrder", [sessionContext, enterpriseServiceOrder]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public addInvoiceTemplate(sessionContext: (SessionContext | null), invoiceTemplate: (any | null), resultHandler: BiConsumer<(number | string), any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "addInvoiceTemplate", [sessionContext, invoiceTemplate]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public attachEnterpirseToTrade(sessionContext: (SessionContext | null), tradeId: (number | string | null), enterpriseId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "attachEnterpirseToTrade", [sessionContext, tradeId, enterpriseId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public cancelEnterpriseServiceOrder(sessionContext: (SessionContext | null), enterpriseServiceOrderId: (number | string | null), reason: (string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "cancelEnterpriseServiceOrder", [sessionContext, enterpriseServiceOrderId, reason]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public createEnterpriseServiceOrderWithRefTradeId(sessionContext: (SessionContext | null), refTradeId: (number | string | null), enterpriseId: (number | string | null), resultHandler: BiConsumer<EnterpriseServiceOrder, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "createEnterpriseServiceOrderWithRefTradeId", [sessionContext, refTradeId, enterpriseId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public createEnterpriseServiceOrderWithTradableId(sessionContext: (SessionContext | null), tradableId: (number | string | null), enterpriseId: (number | string | null), resultHandler: BiConsumer<EnterpriseServiceOrder, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "createEnterpriseServiceOrderWithTradableId", [sessionContext, tradableId, enterpriseId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public deleteInvoiceTemplateByTempId(sessionContext: (SessionContext | null), tempId: (number | string | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "deleteInvoiceTemplateByTempId", [sessionContext, tempId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findContactInfosByUser(sessionContext: (SessionContext | null), userId: (number | string | null), resultHandler: BiConsumer<Array<BusinessContactInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "findContactInfosByUser", [sessionContext, userId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findDefaultInvoiceTemplateByUserId(sessionContext: (SessionContext | null), resultHandler: BiConsumer<any, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "findDefaultInvoiceTemplateByUserId", [sessionContext]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceOrdersByRegionId(sessionContext: (SessionContext | null), regionId: (number | string | null), orderQuerySpec: (OrderQuerySpec | null), resulter: BiConsumer<PageableData<EnterpriseServiceOrder>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "findEnterpriseServiceOrdersByRegionId", [sessionContext, regionId, orderQuerySpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resulter);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServicePackageTradeByOrderId(sessionContext: (SessionContext | null), enterpriseServiceOrderId: (number | string | null), resultHandler: BiConsumer<EnterpriseServicePackageTrade, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "findEnterpriseServicePackageTradeByOrderId", [sessionContext, enterpriseServiceOrderId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceTradeById(sessionContext: (SessionContext | null), tradeId: (number | string | null), resultHandler: BiConsumer<AbstractEnterpriseServiceTrade, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "findEnterpriseServiceTradeById", [sessionContext, tradeId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findEnterpriseServiceTradesByOrderId(sessionContext: (SessionContext | null), enterpriseServiceOrderId: (number | string | null), resultHandler: BiConsumer<Array<EnterpriseServiceTradeInfo>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "findEnterpriseServiceTradesByOrderId", [sessionContext, enterpriseServiceOrderId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findInvoiceRequestsByOrderId(sessionContext: (SessionContext | null), orderId: (number | string | null), pageBlockSpec: (PageBlockAccessSpec | null), resultHandler: BiConsumer<PageableData<InvoiceRequest>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "findInvoiceRequestsByOrderId", [sessionContext, orderId, pageBlockSpec]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findInvoiceTemplateByTempId(sessionContext: (SessionContext | null), tempId: (number | string | null), resultHandler: BiConsumer<any, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "findInvoiceTemplateByTempId", [sessionContext, tempId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findInvoiceTemplateByUserId(sessionContext: (SessionContext | null), resultHandler: BiConsumer<Array<any>, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "findInvoiceTemplateByUserId", [sessionContext]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public findMainEnterpriseServiceTradeByOrderId(sessionContext: (SessionContext | null), enterpriseServiceOrderId: (number | string | null), resultHandler: BiConsumer<AbstractEnterpriseServiceTrade, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "findMainEnterpriseServiceTradeByOrderId", [sessionContext, enterpriseServiceOrderId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEnterpriseServiceOrderByOrderId(sessionContext: (SessionContext | null), enterpriseServiceOrderId: (number | string | null), resultHandler: BiConsumer<EnterpriseServiceOrder, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "getEnterpriseServiceOrderByOrderId", [sessionContext, enterpriseServiceOrderId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEnterpriseServiceOrderByOrderIdAfterPaySuccess(sessionContext: (SessionContext | null), enterpriseServiceOrderId: (number | string | null), resultHandler: BiConsumer<EnterpriseServiceOrder, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "getEnterpriseServiceOrderByOrderIdAfterPaySuccess", [sessionContext, enterpriseServiceOrderId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getEnterpriseServiceOrderStatusByOrderId(sessionContext: (SessionContext | null), enterpriseServiceOrderId: (number | string | null), resultHandler: BiConsumer<OrderStatus, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "getEnterpriseServiceOrderStatusByOrderId", [sessionContext, enterpriseServiceOrderId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public getPayClientInfoForServiceOrder(sessionContext: (SessionContext | null), payment: (PaymentSpec | null), resultHandler: BiConsumer<ThirdPartyPayClientInfo, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "getPayClientInfoForServiceOrder", [sessionContext, payment]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public renewServiceOrder(sessionContext: (SessionContext | null), currentOrderId: (number | string | null), resultHandler: BiConsumer<AbstractEnterpriseServiceTrade, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "renewServiceOrder", [sessionContext, currentOrderId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public renewServiceOrderByCurrentTradeId(sessionContext: (SessionContext | null), currentTradeId: (number | string | null), resultHandler: BiConsumer<AbstractEnterpriseServiceTrade, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "renewServiceOrderByCurrentTradeId", [sessionContext, currentTradeId]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public submitInvoiceRequest(sessionContext: (SessionContext | null), invoiceRequest: (InvoiceRequest | null), resultHandler: BiConsumer<(number | string), any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "submitInvoiceRequest", [sessionContext, invoiceRequest]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

    public updateInvoiceTemplateByTempId(sessionContext: (SessionContext | null), invoiceTemplate: (any | null), resultHandler: BiConsumer<boolean, any>) {
        let request = ServiceRequest.createServiceRequest("com.gong_wei.service.api.business.service.EnterpriseServiceOrderService", "updateInvoiceTemplateByTempId", [sessionContext, invoiceTemplate]);
        let handler = ServiceConsumers.toBiServiceConsumer(resultHandler);
        super.processMessageAsync(request, handler);
    }

}
