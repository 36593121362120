/**
 * Created by Zhonghua on 14/11/2016.
 */

import { AbstractSerializable } from "../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../serialize/JsonTypeTagType";
import { TypeFactory } from "../serialize/TypeFactory";

export class ServiceError extends AbstractSerializable {
  constructor() {
    super();
  }

  private errorCode: number;
  private errorTitle: string;
  private errorMessage: string;
  private errorDetails: string;
  public static createServiceError(
    errorTitle: string,
    errorMessage: string,
    errorDetails: string
  ): ServiceError {
    const error = new ServiceError();
    error.setErrorTitle(errorTitle);
    error.setErrorMessage(errorMessage);
    error.setErrorDetails(errorDetails);
    return error;
  }

  public getErrorCode(): number {
    return this.errorCode;
  }

  public setErrorCode(errorCode: number) {
    this.errorCode = errorCode;
  }

  public getErrorTitle(): string {
    return this.errorTitle;
  }

  public setErrorTitle(errorTitle: string) {
    this.errorTitle = errorTitle;
  }

  public getErrorMessage(): string {
    return this.errorMessage;
  }

  public setErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  public getErrorDetails(): string {
    return this.errorDetails;
  }

  public setErrorDetails(errorDetails: string) {
    this.errorDetails = errorDetails;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const errorCode = json["errorCode"];
    if (errorCode != null) {
      this.setErrorCode(errorCode);
    }
    const errorTitle = json["errorTitle"];
    if (errorTitle != null) {
      this.setErrorTitle(errorTitle);
    }
    const errorMessage = json["errorMessage"];
    if (errorMessage != null) {
      this.setErrorMessage(errorMessage);
    }
    const errorDetails = json["errorDetails"];
    if (errorDetails != null) {
      this.setErrorDetails(errorDetails);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const errorCode = this.getErrorCode();
    if (errorCode != null) {
      json["errorCode"] = errorCode;
    }
    const errorTitle = this.getErrorTitle();
    if (errorTitle != null) {
      json["errorTitle"] = errorTitle;
    }
    const errorMessage = this.getErrorMessage();
    if (errorMessage != null) {
      json["errorMessage"] = errorMessage;
    }
    const errorDetails = this.getErrorDetails();
    if (errorDetails != null) {
      json["errorDetails"] = errorDetails;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.ServiceError";
  }

  public isDisconnectedError(): boolean {
    return true;
  }

  public static checkDisconnectedErrorForAny(error: any): boolean {
    if (error instanceof ServiceError) {
      const serviceError = error as ServiceError;
      return serviceError.isDisconnectedError();
    } else {
      return false;
    }
  }
}
