/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../../serialize/TypeFactory";

export class SimpleCitizenInfo extends AbstractSerializable {
  id: number | string;
  name: string;
  male: boolean;
  nickName: string;
  logoImgUrl: string;
  keywords: Array<string>;
  readStatus: boolean;

  constructor() {
    super();
  }

  public getId(): number | string {
    return this.id;
  }

  public setId(id: number | string) {
    this.id = id;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string) {
    this.name = name;
  }

  public getMale(): boolean {
    return this.male;
  }

  public setMale(male: boolean) {
    this.male = male;
  }

  public getNickName(): string {
    return this.nickName;
  }

  public setNickName(nickName: string) {
    this.nickName = nickName;
  }

  public getLogoImgUrl(): string {
    return this.logoImgUrl;
  }

  public setLogoImgUrl(logoImgUrl: string) {
    this.logoImgUrl = logoImgUrl;
  }

  public getKeywords(): Array<string> {
    return this.keywords;
  }

  public setKeywords(keywords: Array<string>) {
    this.keywords = keywords;
  }

  public isReadStatus(): boolean {
    return this.readStatus;
  }

  public setReadStatus(readStatus: boolean) {
    this.readStatus = readStatus;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const id = json["id"];
    if (id != null) {
      this.setId(id);
    }
    const name = json["name"];
    if (name != null) {
      this.setName(name);
    }
    const male = json["male"];
    if (male != null) {
      this.setMale(male);
    }
    const nickName = json["nickName"];
    if (nickName != null) {
      this.setNickName(nickName);
    }
    const logoImgUrl = json["logoImgUrl"];
    if (logoImgUrl != null) {
      this.setLogoImgUrl(logoImgUrl);
    }
    let keywords = json["keywords"];
    if (keywords != null) {
      const convertedKeywords0 = [];
      keywords = keywords[1];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      this.setKeywords(convertedKeywords0);
    }
    const readStatus = json["readStatus"];
    if (readStatus != null) {
      this.setReadStatus(readStatus);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const id = this.getId();
    if (id != null) {
      json["id"] = String(id);
    }
    const name = this.getName();
    if (name != null) {
      json["name"] = name;
    }
    const male = this.getMale();
    if (male != null) {
      json["male"] = male;
    }
    const nickName = this.getNickName();
    if (nickName != null) {
      json["nickName"] = nickName;
    }
    const logoImgUrl = this.getLogoImgUrl();
    if (logoImgUrl != null) {
      json["logoImgUrl"] = logoImgUrl;
    }
    const keywords = this.getKeywords();
    if (keywords != null) {
      const convertedKeywords0 = [];
      for (const i in keywords) {
        const convertedKeywords10 = keywords[i];
        convertedKeywords0.push(convertedKeywords10);
      }
      const convertedKeywordsWithType1 = [
        "java.util.ArrayList",
        convertedKeywords0,
      ];
      json["keywords"] = convertedKeywordsWithType1;
    }
    const readStatus = this.isReadStatus();
    if (readStatus != null) {
      json["readStatus"] = readStatus;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.entity.enterprise.SimpleCitizenInfo";
  }
}
