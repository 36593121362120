/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { TypeFactory } from "../../serialize/TypeFactory";

export class ReadStats extends AbstractSerializable {
  readCount: number;
  total: number;

  constructor() {
    super();
  }

  public getReadCount(): number {
    return this.readCount;
  }

  public setReadCount(readCount: number) {
    this.readCount = readCount;
  }

  public getTotal(): number {
    return this.total;
  }

  public setTotal(total: number) {
    this.total = total;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const readCount = json["readCount"];
    if (readCount != null) {
      this.setReadCount(readCount);
    }
    const total = json["total"];
    if (total != null) {
      this.setTotal(total);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const readCount = this.getReadCount();
    if (readCount != null) {
      json["readCount"] = readCount;
    }
    const total = this.getTotal();
    if (total != null) {
      json["total"] = total;
    }
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.userad.ReadStats";
  }
}
