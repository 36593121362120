/**
 * 自动生成， 请不要手工修改！
 */

import {CompositeCoworkView} from '../../cowork/CompositeCoworkView'
import {CoopUserAdInfo} from './CoopUserAdInfo'
import {CoopUserInfo} from './CoopUserInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class CoopUserAdView extends CompositeCoworkView<CoopUserAdInfo, CoopUserInfo>  {

    constructor() {
    	super();
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
    
        if (includeTypeTag) {
            json["__type"] = "CoopUserAdView";
        }
    }


    public getTypeId(): string {
    	return "CoopUserAdView";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.userad.coop.CoopUserAdView";
    }
}
