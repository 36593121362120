// @ts-nocheck
import {AbstractUserSuppliedEntityDesc} from "./AbstractUserSuppliedEntityDesc";
import {MessageType} from "../../message/MessageContentType";

export class UserSuppliedEntityDesc extends AbstractUserSuppliedEntityDesc {
  constructor() {
    super();
  }

  getMessageType(): MessageType {
    // Manual Code Here
  }
}
