/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../serialize/AbstractJsonSerializable'
import {AssociatedEntityInfo} from '../AssociatedEntityInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {RoledRegionSpec} from '../region/RoledRegionSpec'
import {TypeFactory} from '../../../serialize/TypeFactory'


export class UserEntityAdminInfo extends AbstractSerializable  {
    roledEntityInfos: Array<AssociatedEntityInfo>;
    roledRegionSpecs: Array<RoledRegionSpec>;
    gwAdmin: boolean;
    inCustomerService: boolean;

    constructor() {
    	super();
    }

    public getRoledEntityInfos(): Array<AssociatedEntityInfo> {
    	return this.roledEntityInfos;
    }

    public setRoledEntityInfos(roledEntityInfos: Array<AssociatedEntityInfo>) {
    	this.roledEntityInfos = roledEntityInfos;
    }

    public getRoledRegionSpecs(): Array<RoledRegionSpec> {
    	return this.roledRegionSpecs;
    }

    public setRoledRegionSpecs(roledRegionSpecs: Array<RoledRegionSpec>) {
    	this.roledRegionSpecs = roledRegionSpecs;
    }

    public isGwAdmin(): boolean {
    	return this.gwAdmin;
    }

    public setGwAdmin(gwAdmin: boolean) {
    	this.gwAdmin = gwAdmin;
    }

    public getInCustomerService(): boolean {
    	return this.inCustomerService;
    }

    public setInCustomerService(inCustomerService: boolean) {
    	this.inCustomerService = inCustomerService;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let roledEntityInfos = json["roledEntityInfos"];
        if (roledEntityInfos != null) {
            let convertedRoledEntityInfos0 = [];
            roledEntityInfos = roledEntityInfos[1];
            for (let i in roledEntityInfos) {
                let convertedRoledEntityInfos10 = roledEntityInfos[i];
                let convertedRoledEntityInfos1 = new AssociatedEntityInfo();
                convertedRoledEntityInfos1.fillFromJson(convertedRoledEntityInfos10, factory);
                convertedRoledEntityInfos0.push(convertedRoledEntityInfos1);
            }
            this.setRoledEntityInfos(convertedRoledEntityInfos0);
        }
        let roledRegionSpecs = json["roledRegionSpecs"];
        if (roledRegionSpecs != null) {
            let convertedRoledRegionSpecs0 = [];
            roledRegionSpecs = roledRegionSpecs[1];
            for (let i in roledRegionSpecs) {
                let convertedRoledRegionSpecs10 = roledRegionSpecs[i];
                let convertedRoledRegionSpecs1 = new RoledRegionSpec();
                convertedRoledRegionSpecs1.fillFromJson(convertedRoledRegionSpecs10, factory);
                convertedRoledRegionSpecs0.push(convertedRoledRegionSpecs1);
            }
            this.setRoledRegionSpecs(convertedRoledRegionSpecs0);
        }
        let gwAdmin = json["gwAdmin"];
        if (gwAdmin != null) {
            this.setGwAdmin(gwAdmin);
        }
        let inCustomerService = json["inCustomerService"];
        if (inCustomerService != null) {
            this.setInCustomerService(inCustomerService);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let roledEntityInfos = this.getRoledEntityInfos();
        if (roledEntityInfos != null) {
            let convertedRoledEntityInfos0 = [];
            for (let i in roledEntityInfos) {
                let convertedRoledEntityInfos10 = roledEntityInfos[i];
                let convertedRoledEntityInfos1 = convertedRoledEntityInfos10.getJson(JsonTypeTagType.NONE);
                convertedRoledEntityInfos0.push(convertedRoledEntityInfos1);
            }
            let convertedRoledEntityInfosWithType1 = ["java.util.ArrayList", convertedRoledEntityInfos0];
            json["roledEntityInfos"] = convertedRoledEntityInfosWithType1;
        }
        let roledRegionSpecs = this.getRoledRegionSpecs();
        if (roledRegionSpecs != null) {
            let convertedRoledRegionSpecs0 = [];
            for (let i in roledRegionSpecs) {
                let convertedRoledRegionSpecs10 = roledRegionSpecs[i];
                let convertedRoledRegionSpecs1 = convertedRoledRegionSpecs10.getJson(JsonTypeTagType.NONE);
                convertedRoledRegionSpecs0.push(convertedRoledRegionSpecs1);
            }
            let convertedRoledRegionSpecsWithType1 = ["java.util.ArrayList", convertedRoledRegionSpecs0];
            json["roledRegionSpecs"] = convertedRoledRegionSpecsWithType1;
        }
        let gwAdmin = this.isGwAdmin();
        if (gwAdmin != null) {
            json["gwAdmin"] = gwAdmin;
        }
        let inCustomerService = this.getInCustomerService();
        if (inCustomerService != null) {
            json["inCustomerService"] = inCustomerService;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.user.UserEntityAdminInfo";
    }
}
