// @ts-nocheck
import {AbstractQuestion} from './AbstractQuestion'
import {EntityInfo} from '../../entity/EntityInfo'
import {KeywordEntity} from '../../keyword/KeywordEntity'
import {MessageContentType} from '../../../message/MessageContentType'
import {QllCountry} from '../../international/QllCountry'


export class Question extends AbstractQuestion  {
    constructor() {
    	super();
    }

    getTargetEntityInfo(): EntityInfo {
      // Manual Code Here
    }
    getCountry(): QllCountry {
      // Manual Code Here
    }
    getEntity(arg0: (string | null)): KeywordEntity {
      // Manual Code Here
    }
    getInitiatorId(): (number | string) {
      // Manual Code Here
    }
    getReferencedEntityId(): (number | string) {
      // Manual Code Here
    }
    getInitiatorImageUrl(): string {
      // Manual Code Here
    }
    getObjectMainImageUrl(): string {
      // Manual Code Here
    }
    getMessageType(): MessageContentType {
      // Manual Code Here
    }

}
