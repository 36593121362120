/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../../../serialize/AbstractJsonSerializable'
import {EnterpriseServiceCategory} from '../EnterpriseServiceCategory'
import {EnterpriseServiceType} from '../EnterpriseServiceType'
import {EntityInfo} from '../../../entity/EntityInfo'
import {JsonTypeTagType} from '../../../../serialize/JsonTypeTagType'
import {KeywordEntity} from '../../../keyword/KeywordEntity'
import {PeriodType} from '../../../PeriodType'
import {RoleType} from '../../../entity/RoleType'
import {Serializer} from '../../../../serialize/Serializer'
import {ServiceDeliveryType} from '../ServiceDeliveryType'
import {ServicePriceDiscountType} from '../../price/ServicePriceDiscountType'
import {ServiceProviderType} from '../ServiceProviderType'
import {ServicePurchaseAward} from '../tradable/ServicePurchaseAward'
import {ServiceUseType} from '../ServiceUseType'
import {TradeStatus} from '../../general/trade/TradeStatus'
import {TypeFactory} from '../../../../serialize/TypeFactory'


export abstract class AbstractEnterpriseServiceTrade extends AbstractSerializable  {
    tradeId: (number | string);
    tradableId: (number | string);
    pkgTradableId: (number | string);
    pkgSeriesId: (number | string);
    orderId: (number | string);
    regionId: (number | string);
    tradingEnterpriseInfo: EntityInfo;
    tradingUserInfo: EntityInfo;
    serviceType: EnterpriseServiceType;
    serviceTag: EnterpriseServiceCategory;
    serviceTypeName: string;
    serviceTypeDisplayName: string;
    serviceTagName: string;
    serviceDeliveryType: ServiceDeliveryType;
    serviceUseType: ServiceUseType;
    serviceProviderType: ServiceProviderType;
    chooseServiceSubType: boolean;
    chooseServiceProvider: boolean;
    servingEnterpriseInfo: EntityInfo;
    imageUrls: Array<string>;
    keywordEntities: Array<KeywordEntity>;
    title: string;
    brief: string;
    description: string;
    tradeUnits: number;
    periodType: PeriodType;
    servicePeriod: number;
    startDay: number;
    endDay: number;
    price: number;
    originalPrice: number;
    settlePrice: number;
    tradeAmount: number;
    priceDiscountType: ServicePriceDiscountType;
    discount: number;
    enterpriseAward: ServicePurchaseAward;
    traderAward: ServicePurchaseAward;
    tradeStatus: TradeStatus;
    createTime: (number | string);
    serviceInfoLink: string;
    userProtocolLink: string;
    serviceStandardLink: string;
    otcPriced: boolean;
    offered: boolean;
    entitledToUse: boolean;
    entitledRole: RoleType;
    exclusiveCustomServiceSessionSupported: boolean;

    constructor() {
    	super();
    }

    public getTradeId(): (number | string) {
    	return this.tradeId;
    }

    public setTradeId(tradeId: (number | string)) {
    	this.tradeId = tradeId;
    }

    public getTradableId(): (number | string) {
    	return this.tradableId;
    }

    public setTradableId(tradableId: (number | string)) {
    	this.tradableId = tradableId;
    }

    public getPkgTradableId(): (number | string) {
    	return this.pkgTradableId;
    }

    public setPkgTradableId(pkgTradableId: (number | string)) {
    	this.pkgTradableId = pkgTradableId;
    }

    public getPkgSeriesId(): (number | string) {
    	return this.pkgSeriesId;
    }

    public setPkgSeriesId(pkgSeriesId: (number | string)) {
    	this.pkgSeriesId = pkgSeriesId;
    }

    public getOrderId(): (number | string) {
    	return this.orderId;
    }

    public setOrderId(orderId: (number | string)) {
    	this.orderId = orderId;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getTradingEnterpriseInfo(): EntityInfo {
    	return this.tradingEnterpriseInfo;
    }

    public setTradingEnterpriseInfo(tradingEnterpriseInfo: EntityInfo) {
    	this.tradingEnterpriseInfo = tradingEnterpriseInfo;
    }

    public getTradingUserInfo(): EntityInfo {
    	return this.tradingUserInfo;
    }

    public setTradingUserInfo(tradingUserInfo: EntityInfo) {
    	this.tradingUserInfo = tradingUserInfo;
    }

    public getServiceType(): EnterpriseServiceType {
    	return this.serviceType;
    }

    public setServiceType(serviceType: EnterpriseServiceType) {
    	this.serviceType = serviceType;
    }

    public getServiceTag(): EnterpriseServiceCategory {
    	return this.serviceTag;
    }

    public setServiceTag(serviceTag: EnterpriseServiceCategory) {
    	this.serviceTag = serviceTag;
    }

    public getServiceTypeName(): string {
    	return this.serviceTypeName;
    }

    public setServiceTypeName(serviceTypeName: string) {
    	this.serviceTypeName = serviceTypeName;
    }

    public getServiceTypeDisplayName(): string {
    	return this.serviceTypeDisplayName;
    }

    public setServiceTypeDisplayName(serviceTypeDisplayName: string) {
    	this.serviceTypeDisplayName = serviceTypeDisplayName;
    }

    public getServiceTagName(): string {
    	return this.serviceTagName;
    }

    public setServiceTagName(serviceTagName: string) {
    	this.serviceTagName = serviceTagName;
    }

    public getServiceDeliveryType(): ServiceDeliveryType {
    	return this.serviceDeliveryType;
    }

    public setServiceDeliveryType(serviceDeliveryType: ServiceDeliveryType) {
    	this.serviceDeliveryType = serviceDeliveryType;
    }

    public getServiceUseType(): ServiceUseType {
    	return this.serviceUseType;
    }

    public setServiceUseType(serviceUseType: ServiceUseType) {
    	this.serviceUseType = serviceUseType;
    }

    public getServiceProviderType(): ServiceProviderType {
    	return this.serviceProviderType;
    }

    public setServiceProviderType(serviceProviderType: ServiceProviderType) {
    	this.serviceProviderType = serviceProviderType;
    }

    public isChooseServiceSubType(): boolean {
    	return this.chooseServiceSubType;
    }

    public setChooseServiceSubType(chooseServiceSubType: boolean) {
    	this.chooseServiceSubType = chooseServiceSubType;
    }

    public isChooseServiceProvider(): boolean {
    	return this.chooseServiceProvider;
    }

    public setChooseServiceProvider(chooseServiceProvider: boolean) {
    	this.chooseServiceProvider = chooseServiceProvider;
    }

    public getServingEnterpriseInfo(): EntityInfo {
    	return this.servingEnterpriseInfo;
    }

    public setServingEnterpriseInfo(servingEnterpriseInfo: EntityInfo) {
    	this.servingEnterpriseInfo = servingEnterpriseInfo;
    }

    public getImageUrls(): Array<string> {
    	return this.imageUrls;
    }

    public setImageUrls(imageUrls: Array<string>) {
    	this.imageUrls = imageUrls;
    }

    public getKeywordEntities(): Array<KeywordEntity> {
    	return this.keywordEntities;
    }

    public setKeywordEntities(keywordEntities: Array<KeywordEntity>) {
    	this.keywordEntities = keywordEntities;
    }

    public getTitle(): string {
    	return this.title;
    }

    public setTitle(title: string) {
    	this.title = title;
    }

    public getBrief(): string {
    	return this.brief;
    }

    public setBrief(brief: string) {
    	this.brief = brief;
    }

    public getDescription(): string {
    	return this.description;
    }

    public setDescription(description: string) {
    	this.description = description;
    }

    public getTradeUnits(): number {
    	return this.tradeUnits;
    }

    public setTradeUnits(tradeUnits: number) {
    	this.tradeUnits = tradeUnits;
    }

    public getPeriodType(): PeriodType {
    	return this.periodType;
    }

    public setPeriodType(periodType: PeriodType) {
    	this.periodType = periodType;
    }

    public getServicePeriod(): number {
    	return this.servicePeriod;
    }

    public setServicePeriod(servicePeriod: number) {
    	this.servicePeriod = servicePeriod;
    }

    public getStartDay(): number {
    	return this.startDay;
    }

    public setStartDay(startDay: number) {
    	this.startDay = startDay;
    }

    public getEndDay(): number {
    	return this.endDay;
    }

    public setEndDay(endDay: number) {
    	this.endDay = endDay;
    }

    public getPrice(): number {
    	return this.price;
    }

    public setPrice(price: number) {
    	this.price = price;
    }

    public getOriginalPrice(): number {
    	return this.originalPrice;
    }

    public setOriginalPrice(originalPrice: number) {
    	this.originalPrice = originalPrice;
    }

    public getSettlePrice(): number {
    	return this.settlePrice;
    }

    public setSettlePrice(settlePrice: number) {
    	this.settlePrice = settlePrice;
    }

    public getTradeAmount(): number {
    	return this.tradeAmount;
    }

    public setTradeAmount(tradeAmount: number) {
    	this.tradeAmount = tradeAmount;
    }

    public getPriceDiscountType(): ServicePriceDiscountType {
    	return this.priceDiscountType;
    }

    public setPriceDiscountType(priceDiscountType: ServicePriceDiscountType) {
    	this.priceDiscountType = priceDiscountType;
    }

    public getDiscount(): number {
    	return this.discount;
    }

    public setDiscount(discount: number) {
    	this.discount = discount;
    }

    public getEnterpriseAward(): ServicePurchaseAward {
    	return this.enterpriseAward;
    }

    public setEnterpriseAward(enterpriseAward: ServicePurchaseAward) {
    	this.enterpriseAward = enterpriseAward;
    }

    public getTraderAward(): ServicePurchaseAward {
    	return this.traderAward;
    }

    public setTraderAward(traderAward: ServicePurchaseAward) {
    	this.traderAward = traderAward;
    }

    public getTradeStatus(): TradeStatus {
    	return this.tradeStatus;
    }

    public setTradeStatus(tradeStatus: TradeStatus) {
    	this.tradeStatus = tradeStatus;
    }

    public getCreateTime(): (number | string) {
    	return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
    	this.createTime = createTime;
    }

    public getServiceInfoLink(): string {
    	return this.serviceInfoLink;
    }

    public setServiceInfoLink(serviceInfoLink: string) {
    	this.serviceInfoLink = serviceInfoLink;
    }

    public getUserProtocolLink(): string {
    	return this.userProtocolLink;
    }

    public setUserProtocolLink(userProtocolLink: string) {
    	this.userProtocolLink = userProtocolLink;
    }

    public getServiceStandardLink(): string {
    	return this.serviceStandardLink;
    }

    public setServiceStandardLink(serviceStandardLink: string) {
    	this.serviceStandardLink = serviceStandardLink;
    }

    public isOtcPriced(): boolean {
    	return this.otcPriced;
    }

    public setOtcPriced(otcPriced: boolean) {
    	this.otcPriced = otcPriced;
    }

    public isOffered(): boolean {
    	return this.offered;
    }

    public setOffered(offered: boolean) {
    	this.offered = offered;
    }

    public isEntitledToUse(): boolean {
    	return this.entitledToUse;
    }

    public setEntitledToUse(entitledToUse: boolean) {
    	this.entitledToUse = entitledToUse;
    }

    public getEntitledRole(): RoleType {
    	return this.entitledRole;
    }

    public setEntitledRole(entitledRole: RoleType) {
    	this.entitledRole = entitledRole;
    }

    public getExclusiveCustomServiceSessionSupported(): boolean {
    	return this.exclusiveCustomServiceSessionSupported;
    }

    public setExclusiveCustomServiceSessionSupported(exclusiveCustomServiceSessionSupported: boolean) {
    	this.exclusiveCustomServiceSessionSupported = exclusiveCustomServiceSessionSupported;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let tradeId = json["tradeId"];
        if (tradeId != null) {
            this.setTradeId(tradeId);
        }
        let tradableId = json["tradableId"];
        if (tradableId != null) {
            this.setTradableId(tradableId);
        }
        let pkgTradableId = json["pkgTradableId"];
        if (pkgTradableId != null) {
            this.setPkgTradableId(pkgTradableId);
        }
        let pkgSeriesId = json["pkgSeriesId"];
        if (pkgSeriesId != null) {
            this.setPkgSeriesId(pkgSeriesId);
        }
        let orderId = json["orderId"];
        if (orderId != null) {
            this.setOrderId(orderId);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let tradingEnterpriseInfo = json["tradingEnterpriseInfo"];
        if (tradingEnterpriseInfo != null) {
            let convertedTradingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(tradingEnterpriseInfo, "__type", factory);
            this.setTradingEnterpriseInfo(convertedTradingEnterpriseInfo0);
        }
        let tradingUserInfo = json["tradingUserInfo"];
        if (tradingUserInfo != null) {
            let convertedTradingUserInfo0 = Serializer.fillFromJsonObjectWithTypeTag(tradingUserInfo, "__type", factory);
            this.setTradingUserInfo(convertedTradingUserInfo0);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let serviceTag = json["serviceTag"];
        if (serviceTag != null) {
            let convertedServiceTag0 = EnterpriseServiceCategory[<string>serviceTag];
            this.setServiceTag(convertedServiceTag0);
        }
        let serviceTypeName = json["serviceTypeName"];
        if (serviceTypeName != null) {
            this.setServiceTypeName(serviceTypeName);
        }
        let serviceTypeDisplayName = json["serviceTypeDisplayName"];
        if (serviceTypeDisplayName != null) {
            this.setServiceTypeDisplayName(serviceTypeDisplayName);
        }
        let serviceTagName = json["serviceTagName"];
        if (serviceTagName != null) {
            this.setServiceTagName(serviceTagName);
        }
        let serviceDeliveryType = json["serviceDeliveryType"];
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[<string>serviceDeliveryType];
            this.setServiceDeliveryType(convertedServiceDeliveryType0);
        }
        let serviceUseType = json["serviceUseType"];
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[<string>serviceUseType];
            this.setServiceUseType(convertedServiceUseType0);
        }
        let serviceProviderType = json["serviceProviderType"];
        if (serviceProviderType != null) {
            let convertedServiceProviderType0 = ServiceProviderType[<string>serviceProviderType];
            this.setServiceProviderType(convertedServiceProviderType0);
        }
        let chooseServiceSubType = json["chooseServiceSubType"];
        if (chooseServiceSubType != null) {
            this.setChooseServiceSubType(chooseServiceSubType);
        }
        let chooseServiceProvider = json["chooseServiceProvider"];
        if (chooseServiceProvider != null) {
            this.setChooseServiceProvider(chooseServiceProvider);
        }
        let servingEnterpriseInfo = json["servingEnterpriseInfo"];
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = Serializer.fillFromJsonObjectWithTypeTag(servingEnterpriseInfo, "__type", factory);
            this.setServingEnterpriseInfo(convertedServingEnterpriseInfo0);
        }
        let imageUrls = json["imageUrls"];
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            imageUrls = imageUrls[1];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            this.setImageUrls(convertedImageUrls0);
        }
        let keywordEntities = json["keywordEntities"];
        if (keywordEntities != null) {
            let convertedKeywordEntities0 = [];
            keywordEntities = keywordEntities[1];
            for (let i in keywordEntities) {
                let convertedKeywordEntities10 = keywordEntities[i];
                let convertedKeywordEntities1 = new KeywordEntity();
                convertedKeywordEntities1.fillFromJson(convertedKeywordEntities10, factory);
                convertedKeywordEntities0.push(convertedKeywordEntities1);
            }
            this.setKeywordEntities(convertedKeywordEntities0);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let brief = json["brief"];
        if (brief != null) {
            this.setBrief(brief);
        }
        let description = json["description"];
        if (description != null) {
            this.setDescription(description);
        }
        let tradeUnits = json["tradeUnits"];
        if (tradeUnits != null) {
            this.setTradeUnits(tradeUnits);
        }
        let periodType = json["periodType"];
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[<string>periodType];
            this.setPeriodType(convertedPeriodType0);
        }
        let servicePeriod = json["servicePeriod"];
        if (servicePeriod != null) {
            this.setServicePeriod(servicePeriod);
        }
        let startDay = json["startDay"];
        if (startDay != null) {
            this.setStartDay(startDay);
        }
        let endDay = json["endDay"];
        if (endDay != null) {
            this.setEndDay(endDay);
        }
        let price = json["price"];
        if (price != null) {
            this.setPrice(price);
        }
        let originalPrice = json["originalPrice"];
        if (originalPrice != null) {
            this.setOriginalPrice(originalPrice);
        }
        let settlePrice = json["settlePrice"];
        if (settlePrice != null) {
            this.setSettlePrice(settlePrice);
        }
        let tradeAmount = json["tradeAmount"];
        if (tradeAmount != null) {
            this.setTradeAmount(tradeAmount);
        }
        let priceDiscountType = json["priceDiscountType"];
        if (priceDiscountType != null) {
            let convertedPriceDiscountType0 = ServicePriceDiscountType[<string>priceDiscountType];
            this.setPriceDiscountType(convertedPriceDiscountType0);
        }
        let discount = json["discount"];
        if (discount != null) {
            this.setDiscount(discount);
        }
        let enterpriseAward = json["enterpriseAward"];
        if (enterpriseAward != null) {
            let convertedEnterpriseAward0 = new ServicePurchaseAward();
            convertedEnterpriseAward0.fillFromJson(enterpriseAward, factory);
            this.setEnterpriseAward(convertedEnterpriseAward0);
        }
        let traderAward = json["traderAward"];
        if (traderAward != null) {
            let convertedTraderAward0 = new ServicePurchaseAward();
            convertedTraderAward0.fillFromJson(traderAward, factory);
            this.setTraderAward(convertedTraderAward0);
        }
        let tradeStatus = json["tradeStatus"];
        if (tradeStatus != null) {
            let convertedTradeStatus0 = TradeStatus[<string>tradeStatus];
            this.setTradeStatus(convertedTradeStatus0);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let serviceInfoLink = json["serviceInfoLink"];
        if (serviceInfoLink != null) {
            this.setServiceInfoLink(serviceInfoLink);
        }
        let userProtocolLink = json["userProtocolLink"];
        if (userProtocolLink != null) {
            this.setUserProtocolLink(userProtocolLink);
        }
        let serviceStandardLink = json["serviceStandardLink"];
        if (serviceStandardLink != null) {
            this.setServiceStandardLink(serviceStandardLink);
        }
        let otcPriced = json["otcPriced"];
        if (otcPriced != null) {
            this.setOtcPriced(otcPriced);
        }
        let offered = json["offered"];
        if (offered != null) {
            this.setOffered(offered);
        }
        let entitledToUse = json["entitledToUse"];
        if (entitledToUse != null) {
            this.setEntitledToUse(entitledToUse);
        }
        let entitledRole = json["entitledRole"];
        if (entitledRole != null) {
            let convertedEntitledRole0 = RoleType[<string>entitledRole];
            this.setEntitledRole(convertedEntitledRole0);
        }
        let exclusiveCustomServiceSessionSupported = json["exclusiveCustomServiceSessionSupported"];
        if (exclusiveCustomServiceSessionSupported != null) {
            this.setExclusiveCustomServiceSessionSupported(exclusiveCustomServiceSessionSupported);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let tradeId = this.getTradeId();
        if (tradeId != null) {
            json["tradeId"] = String(tradeId);
        }
        let tradableId = this.getTradableId();
        if (tradableId != null) {
            json["tradableId"] = String(tradableId);
        }
        let pkgTradableId = this.getPkgTradableId();
        if (pkgTradableId != null) {
            json["pkgTradableId"] = String(pkgTradableId);
        }
        let pkgSeriesId = this.getPkgSeriesId();
        if (pkgSeriesId != null) {
            json["pkgSeriesId"] = String(pkgSeriesId);
        }
        let orderId = this.getOrderId();
        if (orderId != null) {
            json["orderId"] = String(orderId);
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let tradingEnterpriseInfo = this.getTradingEnterpriseInfo();
        if (tradingEnterpriseInfo != null) {
            let convertedTradingEnterpriseInfo0 = tradingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["tradingEnterpriseInfo"] = convertedTradingEnterpriseInfo0;
        }
        let tradingUserInfo = this.getTradingUserInfo();
        if (tradingUserInfo != null) {
            let convertedTradingUserInfo0 = tradingUserInfo.getJson(JsonTypeTagType.TYPE);
            json["tradingUserInfo"] = convertedTradingUserInfo0;
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = EnterpriseServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let serviceTag = this.getServiceTag();
        if (serviceTag != null) {
            let convertedServiceTag0 = EnterpriseServiceCategory[serviceTag];
            json["serviceTag"] = convertedServiceTag0;
        }
        let serviceTypeName = this.getServiceTypeName();
        if (serviceTypeName != null) {
            json["serviceTypeName"] = serviceTypeName;
        }
        let serviceTypeDisplayName = this.getServiceTypeDisplayName();
        if (serviceTypeDisplayName != null) {
            json["serviceTypeDisplayName"] = serviceTypeDisplayName;
        }
        let serviceTagName = this.getServiceTagName();
        if (serviceTagName != null) {
            json["serviceTagName"] = serviceTagName;
        }
        let serviceDeliveryType = this.getServiceDeliveryType();
        if (serviceDeliveryType != null) {
            let convertedServiceDeliveryType0 = ServiceDeliveryType[serviceDeliveryType];
            json["serviceDeliveryType"] = convertedServiceDeliveryType0;
        }
        let serviceUseType = this.getServiceUseType();
        if (serviceUseType != null) {
            let convertedServiceUseType0 = ServiceUseType[serviceUseType];
            json["serviceUseType"] = convertedServiceUseType0;
        }
        let serviceProviderType = this.getServiceProviderType();
        if (serviceProviderType != null) {
            let convertedServiceProviderType0 = ServiceProviderType[serviceProviderType];
            json["serviceProviderType"] = convertedServiceProviderType0;
        }
        let chooseServiceSubType = this.isChooseServiceSubType();
        if (chooseServiceSubType != null) {
            json["chooseServiceSubType"] = chooseServiceSubType;
        }
        let chooseServiceProvider = this.isChooseServiceProvider();
        if (chooseServiceProvider != null) {
            json["chooseServiceProvider"] = chooseServiceProvider;
        }
        let servingEnterpriseInfo = this.getServingEnterpriseInfo();
        if (servingEnterpriseInfo != null) {
            let convertedServingEnterpriseInfo0 = servingEnterpriseInfo.getJson(JsonTypeTagType.TYPE);
            json["servingEnterpriseInfo"] = convertedServingEnterpriseInfo0;
        }
        let imageUrls = this.getImageUrls();
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            let convertedImageUrlsWithType1 = ["java.util.ArrayList", convertedImageUrls0];
            json["imageUrls"] = convertedImageUrlsWithType1;
        }
        let keywordEntities = this.getKeywordEntities();
        if (keywordEntities != null) {
            let convertedKeywordEntities0 = [];
            for (let i in keywordEntities) {
                let convertedKeywordEntities10 = keywordEntities[i];
                let convertedKeywordEntities1 = convertedKeywordEntities10.getJson(JsonTypeTagType.NONE);
                convertedKeywordEntities0.push(convertedKeywordEntities1);
            }
            let convertedKeywordEntitiesWithType1 = ["java.util.ArrayList", convertedKeywordEntities0];
            json["keywordEntities"] = convertedKeywordEntitiesWithType1;
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let brief = this.getBrief();
        if (brief != null) {
            json["brief"] = brief;
        }
        let description = this.getDescription();
        if (description != null) {
            json["description"] = description;
        }
        let tradeUnits = this.getTradeUnits();
        if (tradeUnits != null) {
            json["tradeUnits"] = tradeUnits;
        }
        let periodType = this.getPeriodType();
        if (periodType != null) {
            let convertedPeriodType0 = PeriodType[periodType];
            json["periodType"] = convertedPeriodType0;
        }
        let servicePeriod = this.getServicePeriod();
        if (servicePeriod != null) {
            json["servicePeriod"] = servicePeriod;
        }
        let startDay = this.getStartDay();
        if (startDay != null) {
            json["startDay"] = startDay;
        }
        let endDay = this.getEndDay();
        if (endDay != null) {
            json["endDay"] = endDay;
        }
        let price = this.getPrice();
        if (price != null) {
            json["price"] = price;
        }
        let originalPrice = this.getOriginalPrice();
        if (originalPrice != null) {
            json["originalPrice"] = originalPrice;
        }
        let settlePrice = this.getSettlePrice();
        if (settlePrice != null) {
            json["settlePrice"] = settlePrice;
        }
        let tradeAmount = this.getTradeAmount();
        if (tradeAmount != null) {
            json["tradeAmount"] = tradeAmount;
        }
        let priceDiscountType = this.getPriceDiscountType();
        if (priceDiscountType != null) {
            let convertedPriceDiscountType0 = ServicePriceDiscountType[priceDiscountType];
            json["priceDiscountType"] = convertedPriceDiscountType0;
        }
        let discount = this.getDiscount();
        if (discount != null) {
            json["discount"] = discount;
        }
        let enterpriseAward = this.getEnterpriseAward();
        if (enterpriseAward != null) {
            let convertedEnterpriseAward0 = enterpriseAward.getJson(JsonTypeTagType.NONE);
            json["enterpriseAward"] = convertedEnterpriseAward0;
        }
        let traderAward = this.getTraderAward();
        if (traderAward != null) {
            let convertedTraderAward0 = traderAward.getJson(JsonTypeTagType.NONE);
            json["traderAward"] = convertedTraderAward0;
        }
        let tradeStatus = this.getTradeStatus();
        if (tradeStatus != null) {
            let convertedTradeStatus0 = TradeStatus[tradeStatus];
            json["tradeStatus"] = convertedTradeStatus0;
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let serviceInfoLink = this.getServiceInfoLink();
        if (serviceInfoLink != null) {
            json["serviceInfoLink"] = serviceInfoLink;
        }
        let userProtocolLink = this.getUserProtocolLink();
        if (userProtocolLink != null) {
            json["userProtocolLink"] = userProtocolLink;
        }
        let serviceStandardLink = this.getServiceStandardLink();
        if (serviceStandardLink != null) {
            json["serviceStandardLink"] = serviceStandardLink;
        }
        let otcPriced = this.isOtcPriced();
        if (otcPriced != null) {
            json["otcPriced"] = otcPriced;
        }
        let offered = this.isOffered();
        if (offered != null) {
            json["offered"] = offered;
        }
        let entitledToUse = this.isEntitledToUse();
        if (entitledToUse != null) {
            json["entitledToUse"] = entitledToUse;
        }
        let entitledRole = this.getEntitledRole();
        if (entitledRole != null) {
            let convertedEntitledRole0 = RoleType[entitledRole];
            json["entitledRole"] = convertedEntitledRole0;
        }
        let exclusiveCustomServiceSessionSupported = this.getExclusiveCustomServiceSessionSupported();
        if (exclusiveCustomServiceSessionSupported != null) {
            json["exclusiveCustomServiceSessionSupported"] = exclusiveCustomServiceSessionSupported;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.business.service.trade.AbstractEnterpriseServiceTrade";
    }
}
