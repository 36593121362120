/**
 * 自动生成， 请不要手工修改！
 */

import { AbstractSerializable } from "../../serialize/AbstractJsonSerializable";
import { Filter } from "./Filter";
import { JsonTypeTagType } from "../../serialize/JsonTypeTagType";
import { Ordering } from "./Ordering";
import { PageBlockAccessSpec } from "../paging/PageBlockAccessSpec";
import { Serializer } from "../../serialize/Serializer";
import { TypeFactory } from "../../serialize/TypeFactory";

export class QuerySpec<F extends Filter> extends AbstractSerializable {
  filter: F;
  pageBlockSpec: PageBlockAccessSpec;
  ordering: Ordering;

  constructor() {
    super();
  }

  public getFilter(): F {
    return this.filter;
  }

  public setFilter(filter: F) {
    this.filter = filter;
  }

  public getPageBlockSpec(): PageBlockAccessSpec {
    return this.pageBlockSpec;
  }

  public setPageBlockSpec(pageBlockSpec: PageBlockAccessSpec) {
    this.pageBlockSpec = pageBlockSpec;
  }

  public getOrdering(): Ordering {
    return this.ordering;
  }

  public setOrdering(ordering: Ordering) {
    this.ordering = ordering;
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const filter = json["filter"];
    if (filter != null) {
      const convertedFilter0 = Serializer.fillFromJsonObjectWithTypeTag(
        filter,
        "__type",
        factory
      );
      this.setFilter(convertedFilter0);
    }
    const pageBlockSpec = json["pageBlockSpec"];
    if (pageBlockSpec != null) {
      const convertedPageBlockSpec0 = new PageBlockAccessSpec();
      convertedPageBlockSpec0.fillFromJson(pageBlockSpec, factory);
      this.setPageBlockSpec(convertedPageBlockSpec0);
    }
    const ordering = json["ordering"];
    if (ordering != null) {
      const convertedOrdering0 = Ordering[<string>ordering];
      this.setOrdering(convertedOrdering0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    const filter = this.getFilter();
    if (filter != null) {
      const convertedFilter0 = filter.getJson(JsonTypeTagType.TYPE);
      json["filter"] = convertedFilter0;
    }
    const pageBlockSpec = this.getPageBlockSpec();
    if (pageBlockSpec != null) {
      const convertedPageBlockSpec0 = pageBlockSpec.getJson(
        JsonTypeTagType.NONE
      );
      json["pageBlockSpec"] = convertedPageBlockSpec0;
    }
    const ordering = this.getOrdering();
    if (ordering != null) {
      const convertedOrdering0 = Ordering[ordering];
      json["ordering"] = convertedOrdering0;
    }

    if (includeTypeTag) {
      json["__type"] = "QuerySpec";
    }
  }

  public getTypeId(): string {
    return "QuerySpec";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.query.QuerySpec";
  }
}
