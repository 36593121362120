// @ts-nocheck
import { AbstractEntityRoleRequest } from "./AbstractEntityRoleRequest";
import { CoworkType } from "../../cowork/CoworkType";
import {EntityInfo} from "../../entity/EntityInfo";
import {MessageType} from "../../../message/MessageContentType";
import {RequestType} from "../RequestType";

export class EntityRoleRequest extends AbstractEntityRoleRequest {
  constructor() {
    super();
  }

    getMessageType(): MessageType {
        return MessageType.REQUEST;
    }

  /**
   * 必选数据： 请求对象类型
   */
  public withTargetType(targetType: CoworkType): EntityRoleRequest {
    this.targetType = targetType;
    return this;
  }

  /**
   * 必选数据： 请求对象ID
   */
  public withTargetId(targetId: number | string): EntityRoleRequest {
    this.targetId = targetId;
    return this;
  }

  /**
   * 必选数据： 请求对象所属社区D
   */
  public withTargetEntityInfo(targetEntityInfo: EntityInfo): EntityRoleRequest {
    this.targetEntityInfo = targetEntityInfo;
    return this;
  }

  /**
   * 必选数据： 请求者信息
   */
  public withRequestorInfo(requestorInfo: EntityInfo): EntityRoleRequest {
    this.requestorInfo = requestorInfo;
    return this;
  }

  /**
   * 必选数据： 请求类型
   */
  public withRequestType(requestType: RequestType): EntityRoleRequest {
    this.requestType = requestType;
    return this;
  }
}
