/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {CoreGeoEntityDesc} from './CoreGeoEntityDesc'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {MessageType} from '../../message/MessageContentType'
import {MessageContent} from '../../message/MessageContent'
import {ServiceType} from '../entity/topic/ServiceType'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractGeoEntityDesc extends AbstractSerializable implements MessageContent {
    coreGeoEntityDesc: CoreGeoEntityDesc;
    parentName: string;
    serviceType: ServiceType;
    distance: number;
    manager: boolean;

    constructor() {
        super();
    }

    public getCoreGeoEntityDesc(): CoreGeoEntityDesc {
        return this.coreGeoEntityDesc;
    }

    public setCoreGeoEntityDesc(coreGeoEntityDesc: CoreGeoEntityDesc) {
        this.coreGeoEntityDesc = coreGeoEntityDesc;
    }

    public getParentName(): string {
        return this.parentName;
    }

    public setParentName(parentName: string) {
        this.parentName = parentName;
    }

    public getServiceType(): ServiceType {
        return this.serviceType;
    }

    public setServiceType(serviceType: ServiceType) {
        this.serviceType = serviceType;
    }

    public getDistance(): number {
        return this.distance;
    }

    public setDistance(distance: number) {
        this.distance = distance;
    }

    public isManager(): boolean {
        return this.manager;
    }

    public setManager(manager: boolean) {
        this.manager = manager;
    }


    abstract getMessageType(): MessageType


    public fillFromJson(json: Object, factory?: TypeFactory) {
        let coreGeoEntityDesc = json["coreGeoEntityDesc"];
        if (coreGeoEntityDesc != null) {
            let convertedCoreGeoEntityDesc0 = new CoreGeoEntityDesc();
            convertedCoreGeoEntityDesc0.fillFromJson(coreGeoEntityDesc, factory);
            this.setCoreGeoEntityDesc(convertedCoreGeoEntityDesc0);
        }
        let parentName = json["parentName"];
        if (parentName != null) {
            this.setParentName(parentName);
        }
        let serviceType = json["serviceType"];
        if (serviceType != null) {
            let convertedServiceType0 = ServiceType[<string>serviceType];
            this.setServiceType(convertedServiceType0);
        }
        let distance = json["distance"];
        if (distance != null) {
            this.setDistance(distance);
        }
        let manager = json["manager"];
        if (manager != null) {
            this.setManager(manager);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let coreGeoEntityDesc = this.getCoreGeoEntityDesc();
        if (coreGeoEntityDesc != null) {
            let convertedCoreGeoEntityDesc0 = coreGeoEntityDesc.getJson(JsonTypeTagType.NONE);
            json["coreGeoEntityDesc"] = convertedCoreGeoEntityDesc0;
        }
        let parentName = this.getParentName();
        if (parentName != null) {
            json["parentName"] = parentName;
        }
        let serviceType = this.getServiceType();
        if (serviceType != null) {
            let convertedServiceType0 = ServiceType[serviceType];
            json["serviceType"] = convertedServiceType0;
        }
        let distance = this.getDistance();
        if (distance != null) {
            json["distance"] = distance;
        }
        let manager = this.isManager();
        if (manager != null) {
            json["manager"] = manager;
        }

        if (includeTypeTag) {
            json["__type"] = "GeoEntityDesc";
        }
    }


    public getTypeId(): string {
        return "GeoEntityDesc";
    }

    public getClassName(): string {
        return "com.gong_wei.common.datatype.geolocation.GeoEntityDesc";
    }
}
