/**
 * 自动生成， 请不要手工修改！
 */

import {JsonTypeTagType} from "../../serialize/JsonTypeTagType";
import {MessageType} from "../../message/MessageContentType";
import {Request} from "@/services/datatype/request/Request";
import { Serializer } from "../../serialize/Serializer";
import { TextSource } from "../../message/entity/api/TextSource";
import { TypeFactory } from "../../serialize/TypeFactory";

export abstract class AbstractCustomRequest<
  T extends TextSource
> extends Request {
  customData: T;

  constructor() {
    super();
  }

    public getCustomData(): T {
        return this.customData;
    }

    public setCustomData(customData: T) {
        this.customData = customData;
    }

    getMessageType(): MessageType {
        return MessageType.REQUEST;
    }

    public fillFromJson(json: Object, factory?: TypeFactory) {
        if (factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        const customData = json["customData"];
        if (customData != null) {
      const convertedCustomData0 = Serializer.fillFromJsonObjectWithTypeTag(
        customData,
        "__type",
        factory
      );
      this.setCustomData(convertedCustomData0);
    }
  }

  public fillToJson(json: Object, includeTypeTag = true) {
    super.fillToJson(json, includeTypeTag);
    const customData = this.getCustomData();
    if (customData != null) {
      const convertedCustomData0 = customData.getJson(JsonTypeTagType.TYPE);
      json["customData"] = convertedCustomData0;
    }

    if (includeTypeTag) {
      json["__type"] = "CustomRequest";
    }
  }

  public getTypeId(): string {
    return "CustomRequest";
  }

  public getClassName(): string {
    return "com.gong_wei.common.datatype.request.CustomRequest";
  }
}
