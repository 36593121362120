/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {AwardOrder} from '../reply/AwardOrder'
import {CoworkInfo} from './CoworkInfo'
import {CoworkReplyInfo} from './CoworkReplyInfo'
import {CoworkSimpleView} from './CoworkSimpleView'
import {CoworkStats} from './CoworkStats'
import {CoworkType} from './CoworkType'
import {EntityInfo} from '../entity/EntityInfo'
import {GeoHash} from '../geolocation/GeoHash'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {Location} from '../geolocation/Location'
import {QllCountrySpec} from '../international/QllCountrySpec'
import {QllLanguageSpec} from '../international/QllLanguageSpec'
import {SelectTimes} from './SelectTimes'
import {Serializer} from '../../serialize/Serializer'
import {TypeFactory} from '../../serialize/TypeFactory'


export abstract class AbstractCoworkInfo extends AbstractSerializable implements CoworkInfo  {
    languageSpec: QllLanguageSpec;
    countrySpec: QllCountrySpec;
    rid: (number | string);
    title: string;
    imageUrls: Array<string>;
    initiatorInfo: EntityInfo;
    anonymous: boolean;
    targetEntityInfo: EntityInfo;
    refEntityInfo: EntityInfo;
    location: Location;
    regionId: (number | string);
    createTime: (number | string);
    visits: number;
    approvals: number;
    disapprovals: number;
    prize: number;
    activityId: (number | string);
    activityAwardType: AwardOrder;
    latestReply: CoworkReplyInfo;
    official: boolean;
    internal: boolean;

    constructor() {
    	super();
    }

    public getLanguageSpec(): QllLanguageSpec {
    	return this.languageSpec;
    }

    public setLanguageSpec(languageSpec: QllLanguageSpec) {
    	this.languageSpec = languageSpec;
    }

    public getCountrySpec(): QllCountrySpec {
    	return this.countrySpec;
    }

    public setCountrySpec(countrySpec: QllCountrySpec) {
    	this.countrySpec = countrySpec;
    }

    public getRid(): (number | string) {
    	return this.rid;
    }

    public setRid(rid: (number | string)) {
    	this.rid = rid;
    }

    public getTitle(): string {
    	return this.title;
    }

    public setTitle(title: string) {
    	this.title = title;
    }

    public getImageUrls(): Array<string> {
    	return this.imageUrls;
    }

    public setImageUrls(imageUrls: Array<string>) {
    	this.imageUrls = imageUrls;
    }

    public getInitiatorInfo(): EntityInfo {
    	return this.initiatorInfo;
    }

    public setInitiatorInfo(initiatorInfo: EntityInfo) {
    	this.initiatorInfo = initiatorInfo;
    }

    public isAnonymous(): boolean {
    	return this.anonymous;
    }

    public setAnonymous(anonymous: boolean) {
    	this.anonymous = anonymous;
    }

    public getTargetEntityInfo(): EntityInfo {
    	return this.targetEntityInfo;
    }

    public setTargetEntityInfo(targetEntityInfo: EntityInfo) {
    	this.targetEntityInfo = targetEntityInfo;
    }

    public getRefEntityInfo(): EntityInfo {
    	return this.refEntityInfo;
    }

    public setRefEntityInfo(refEntityInfo: EntityInfo) {
    	this.refEntityInfo = refEntityInfo;
    }

    public getLocation(): Location {
    	return this.location;
    }

    public setLocation(location: Location) {
    	this.location = location;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getCreateTime(): (number | string) {
    	return this.createTime;
    }

    public setCreateTime(createTime: (number | string)) {
    	this.createTime = createTime;
    }

    public getVisits(): number {
    	return this.visits;
    }

    public setVisits(visits: number) {
    	this.visits = visits;
    }

    public getApprovals(): number {
    	return this.approvals;
    }

    public setApprovals(approvals: number) {
    	this.approvals = approvals;
    }

    public getDisapprovals(): number {
    	return this.disapprovals;
    }

    public setDisapprovals(disapprovals: number) {
    	this.disapprovals = disapprovals;
    }

    public getPrize(): number {
    	return this.prize;
    }

    public setPrize(prize: number) {
    	this.prize = prize;
    }

    public getActivityId(): (number | string) {
    	return this.activityId;
    }

    public setActivityId(activityId: (number | string)) {
    	this.activityId = activityId;
    }

    public getActivityAwardType(): AwardOrder {
    	return this.activityAwardType;
    }

    public setActivityAwardType(activityAwardType: AwardOrder) {
    	this.activityAwardType = activityAwardType;
    }

    public getLatestReply(): CoworkReplyInfo {
    	return this.latestReply;
    }

    public setLatestReply(latestReply: CoworkReplyInfo) {
    	this.latestReply = latestReply;
    }

    public isOfficial(): boolean {
    	return this.official;
    }

    public setOfficial(official: boolean) {
    	this.official = official;
    }

    public isInternal(): boolean {
    	return this.internal;
    }

    public setInternal(internal: boolean) {
    	this.internal = internal;
    }


    abstract hasImageUrls(): boolean;

    abstract isByPlatform(): boolean;

    abstract isObsolete(): boolean;

    abstract isObsoleteInDays(arg0: (number | null)): boolean;

    abstract isPlatformActivity(): boolean;

    abstract getActualInitiatorInfo(): EntityInfo;

    abstract getGeohash(): GeoHash;

    abstract getCVisits(): number;

    abstract getProperVisits(): number;

    abstract getScore(): number;

    abstract getWeighted(): number;

    abstract getVisitOffset(): number;

    abstract getRefEntityId(): (number | string);

    abstract getTargetEntityId(): (number | string);

    abstract getCityName(): string;

    abstract getCommonDescription(): string;

    abstract getDisplayTitle(arg0: (boolean | null)): string;

    abstract getLocationName(): string;

    abstract getMainImageUrl(): string;

    abstract getSuggestionPkLabel(): string;

    abstract getTypeDisplayName(): string;

    abstract getKeywords(): Array<string>;

    abstract getEntityDynamicTime(): (number | string);

    abstract getEntitySelectTime(): (number | string);

    abstract getGlobalDynamicSelectTime(): (number | string);

    abstract getGlobalSelectTime(): (number | string);

    abstract getLastUpdateTime(): (number | string);

    abstract getMediumDynamicSelectTime(): (number | string);

    abstract getMediumSelectTime(): (number | string);

    abstract getRecentTime(): (number | string);

    abstract getServiceSelectTime(): (number | string);

    abstract addApprovals(arg0: (number | null));

    abstract addDisapprovals(arg0: (number | null));

    abstract addPrize(arg0: (number | null));

    abstract addVisits(arg0: (number | null));

    abstract addWeighted(arg0: (number | null));

    abstract copyFrom(arg0: (CoworkInfo | null));

    abstract mergeFrom(arg0: (CoworkInfo | null));

    abstract setByPlatform(arg0: (boolean | null));

    abstract setCityName(arg0: (string | null));

    abstract setEntityDynamicTime(arg0: (number | string | null));

    abstract setEntitySelectTime(arg0: (number | string | null));

    abstract setGeohash(arg0: (GeoHash | null));

    abstract setGlobalDynamicSelectTime(arg0: (number | string | null));

    abstract setGlobalSelectTime(arg0: (number | string | null));

    abstract setInitiatorId(arg0: (number | string | null));

    abstract setKeywords(arg0: (Array<string> | null));

    abstract setLastUpdateTime(arg0: (number | string | null));

    abstract setLat(arg0: (number | null));

    abstract setLng(arg0: (number | null));

    abstract setLocationName(arg0: (string | null));

    abstract setMediumDynamicSelectTime(arg0: (number | string | null));

    abstract setMediumSelectTime(arg0: (number | string | null));

    abstract setObsolete(arg0: (boolean | null));

    abstract setRecentTime(arg0: (number | string | null));

    abstract setRefEntityId(arg0: (number | string | null));

    abstract setScore(arg0: (number | null));

    abstract setServiceSelectTime(arg0: (number | string | null));

    abstract setSuggestionPkLabel(arg0: (string | null));

    abstract setTargetEntityId(arg0: (number | string | null));

    abstract setWeighted(arg0: (number | null));

    abstract isActive(): boolean;

    abstract copy(arg0: (boolean | null)): CoworkSimpleView;

    abstract getCoworkType(): CoworkType;

    abstract getSelectTimes(): SelectTimes;

    abstract getCountryCode(): number;

    abstract getLanguageCode(): number;

    abstract getInitiatorId(): (number | string);

    abstract getCoworkStatus(): any;

    abstract getSubType(): any;

    abstract getDescription(): string;

    abstract accept(arg0: (CoworkStats | null));

    abstract addSelectTimes(arg0: (SelectTimes | null));

    abstract setCoworkStatus(arg0: (any | null));

    abstract setDescription(arg0: (string | null));

    abstract setSubType(arg0: (any | null));

    abstract update(arg0: (CoworkSimpleView | null));

    abstract getTypeId(): string;

    public fillFromJson(json: Object, factory?: TypeFactory) {
        let languageSpec = json["languageSpec"];
        if (languageSpec != null) {
            let convertedLanguageSpec0 = new QllLanguageSpec();
            convertedLanguageSpec0.fillFromJson(languageSpec, factory);
            this.setLanguageSpec(convertedLanguageSpec0);
        }
        let countrySpec = json["countrySpec"];
        if (countrySpec != null) {
            let convertedCountrySpec0 = new QllCountrySpec();
            convertedCountrySpec0.fillFromJson(countrySpec, factory);
            this.setCountrySpec(convertedCountrySpec0);
        }
        let rid = json["rid"];
        if (rid != null) {
            this.setRid(rid);
        }
        let title = json["title"];
        if (title != null) {
            this.setTitle(title);
        }
        let imageUrls = json["imageUrls"];
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            imageUrls = imageUrls[1];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            this.setImageUrls(convertedImageUrls0);
        }
        let initiatorInfo = json["initiatorInfo"];
        if (initiatorInfo != null) {
            let convertedInitiatorInfo0 = Serializer.fillFromJsonObjectWithTypeTag(initiatorInfo, "__type", factory);
            this.setInitiatorInfo(convertedInitiatorInfo0);
        }
        let anonymous = json["anonymous"];
        if (anonymous != null) {
            this.setAnonymous(anonymous);
        }
        let targetEntityInfo = json["targetEntityInfo"];
        if (targetEntityInfo != null) {
            let convertedTargetEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(targetEntityInfo, "__type", factory);
            this.setTargetEntityInfo(convertedTargetEntityInfo0);
        }
        let refEntityInfo = json["refEntityInfo"];
        if (refEntityInfo != null) {
            let convertedRefEntityInfo0 = Serializer.fillFromJsonObjectWithTypeTag(refEntityInfo, "__type", factory);
            this.setRefEntityInfo(convertedRefEntityInfo0);
        }
        let location = json["location"];
        if (location != null) {
            let convertedLocation0 = new Location();
            convertedLocation0.fillFromJson(location, factory);
            this.setLocation(convertedLocation0);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let createTime = json["createTime"];
        if (createTime != null) {
            this.setCreateTime(createTime);
        }
        let visits = json["visits"];
        if (visits != null) {
            this.setVisits(visits);
        }
        let approvals = json["approvals"];
        if (approvals != null) {
            this.setApprovals(approvals);
        }
        let disapprovals = json["disapprovals"];
        if (disapprovals != null) {
            this.setDisapprovals(disapprovals);
        }
        let prize = json["prize"];
        if (prize != null) {
            this.setPrize(prize);
        }
        let activityId = json["activityId"];
        if (activityId != null) {
            this.setActivityId(activityId);
        }
        let activityAwardType = json["activityAwardType"];
        if (activityAwardType != null) {
            let convertedActivityAwardType0 = AwardOrder[<string>activityAwardType];
            this.setActivityAwardType(convertedActivityAwardType0);
        }
        let latestReply = json["latestReply"];
        if (latestReply != null) {
            let convertedLatestReply0 = new CoworkReplyInfo();
            convertedLatestReply0.fillFromJson(latestReply, factory);
            this.setLatestReply(convertedLatestReply0);
        }
        let official = json["official"];
        if (official != null) {
            this.setOfficial(official);
        }
        let internal = json["internal"];
        if (internal != null) {
            this.setInternal(internal);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let languageSpec = this.getLanguageSpec();
        if (languageSpec != null) {
            let convertedLanguageSpec0 = languageSpec.getJson(JsonTypeTagType.NONE);
            json["languageSpec"] = convertedLanguageSpec0;
        }
        let countrySpec = this.getCountrySpec();
        if (countrySpec != null) {
            let convertedCountrySpec0 = countrySpec.getJson(JsonTypeTagType.NONE);
            json["countrySpec"] = convertedCountrySpec0;
        }
        let rid = this.getRid();
        if (rid != null) {
            json["rid"] = String(rid);
        }
        let title = this.getTitle();
        if (title != null) {
            json["title"] = title;
        }
        let imageUrls = this.getImageUrls();
        if (imageUrls != null) {
            let convertedImageUrls0 = [];
            for (let i in imageUrls) {
                let convertedImageUrls10 = imageUrls[i];
                convertedImageUrls0.push(convertedImageUrls10);
            }
            let convertedImageUrlsWithType1 = ["java.util.ArrayList", convertedImageUrls0];
            json["imageUrls"] = convertedImageUrlsWithType1;
        }
        let initiatorInfo = this.getInitiatorInfo();
        if (initiatorInfo != null) {
            let convertedInitiatorInfo0 = initiatorInfo.getJson(JsonTypeTagType.TYPE);
            json["initiatorInfo"] = convertedInitiatorInfo0;
        }
        let anonymous = this.isAnonymous();
        if (anonymous != null) {
            json["anonymous"] = anonymous;
        }
        let targetEntityInfo = this.getTargetEntityInfo();
        if (targetEntityInfo != null) {
            let convertedTargetEntityInfo0 = targetEntityInfo.getJson(JsonTypeTagType.TYPE);
            json["targetEntityInfo"] = convertedTargetEntityInfo0;
        }
        let refEntityInfo = this.getRefEntityInfo();
        if (refEntityInfo != null) {
            let convertedRefEntityInfo0 = refEntityInfo.getJson(JsonTypeTagType.TYPE);
            json["refEntityInfo"] = convertedRefEntityInfo0;
        }
        let location = this.getLocation();
        if (location != null) {
            let convertedLocation0 = location.getJson(JsonTypeTagType.NONE);
            json["location"] = convertedLocation0;
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let createTime = this.getCreateTime();
        if (createTime != null) {
            json["createTime"] = String(createTime);
        }
        let visits = this.getVisits();
        if (visits != null) {
            json["visits"] = visits;
        }
        let approvals = this.getApprovals();
        if (approvals != null) {
            json["approvals"] = approvals;
        }
        let disapprovals = this.getDisapprovals();
        if (disapprovals != null) {
            json["disapprovals"] = disapprovals;
        }
        let prize = this.getPrize();
        if (prize != null) {
            json["prize"] = prize;
        }
        let activityId = this.getActivityId();
        if (activityId != null) {
            json["activityId"] = String(activityId);
        }
        let activityAwardType = this.getActivityAwardType();
        if (activityAwardType != null) {
            let convertedActivityAwardType0 = AwardOrder[activityAwardType];
            json["activityAwardType"] = convertedActivityAwardType0;
        }
        let latestReply = this.getLatestReply();
        if (latestReply != null) {
            let convertedLatestReply0 = latestReply.getJson(JsonTypeTagType.NONE);
            json["latestReply"] = convertedLatestReply0;
        }
        let official = this.isOfficial();
        if (official != null) {
            json["official"] = official;
        }
        let internal = this.isInternal();
        if (internal != null) {
            json["internal"] = internal;
        }
    }



    public getClassName(): string {
    	return "com.gong_wei.common.datatype.cowork.AbstractCoworkInfo";
    }
}
