// @ts-nocheck
import { AbstractExpertiseProfileAspect } from "./AbstractExpertiseProfileAspect";
import { ProfileAspectType } from "../../entity/profile/ProfileAspectType";

export class ExpertiseProfileAspect extends AbstractExpertiseProfileAspect {
  constructor() {
    super();
  }

  getProfileAspectType(): ProfileAspectType {
    // Manual Code Here
  }
}
