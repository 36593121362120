/**
 * 自动生成， 请不要手工修改！
 */

export enum TransactionType {
  C_TRANSFER_IN,
  C_IN_GENERAL,
  C_IN_PACKAGE_FEE,
  C_IN_SERVICE_FEE,
  C_IN_TOP_UP_FEE,
  C_IN_AD_FEE,
  C_IN_DIVIDEND,
  C_IN_GW_AWARD,
  C_INVITE_AWARD,
  C_IN_USER_AWARD,
  C_DIAN_ZAN_AWARD,
  C_BE_DIAN_ZAN_AWARD,
  C_RED_ENVELOPE,
  C_OUT_GENERAL,
  C_OUT_AD_FEE,
  C_OUT_MEMBER_FEE,
  C_TRANSFER_OUT,
  C_FUND_RESERVE,
  C_RED_ENVELOPE_RESV,
  C_INFO_AWARD_RESV,
  C_CANCEL_RESERVE,
  C_DONE_RESERVE,
  C_PLATFORM_TOPUP,
  C_TRADE,
  P_CONTRIBUTE,
  P_PARTICIPATE,
  P_PERMANENT,
  P_INHERIT,
  P_TRADE,
  S_PERCENTAGE,
  S_PERM_POINTS,
  A_COOP_POINTS,
  A_TRADE_POINTS,
  A_MEMBER_POINTS,
  A_PURCHASED_POINTS,
  Q_SERVICE_PKG,
  Q_SERVICE,
}
