/**
 * 自动生成， 请不要手工修改！
 */

import {CoworkUserInfo} from '../../cowork/CoworkUserInfo'
import {GeneralCoworkUserInfo} from '../../cowork/GeneralCoworkUserInfo'
import {JsonTypeTagType} from '../../../serialize/JsonTypeTagType'
import {TypeFactory} from '../../../serialize/TypeFactory'


export abstract class AbstractCoopUserInfo extends GeneralCoworkUserInfo  {
    replied: boolean;
    mutuallyReplied: boolean;

    constructor() {
    	super();
    }

    public isReplied(): boolean {
    	return this.replied;
    }

    public setReplied(replied: boolean) {
    	this.replied = replied;
    }

    public isMutuallyReplied(): boolean {
    	return this.mutuallyReplied;
    }

    public setMutuallyReplied(mutuallyReplied: boolean) {
    	this.mutuallyReplied = mutuallyReplied;
    }


    abstract isActive(): boolean;

    abstract isPulled(): boolean;

    abstract getPrizable(): boolean;

    abstract getApproverPrize(): number;

    abstract getCreatorPrize(): number;

    abstract getRelApproverPrize(): number;

    abstract getRelCreatorPrize(): number;

    abstract addApproverPrize(arg0: (number | null));

    abstract addCreatorPrize(arg0: (number | null));

    abstract setActive(active: (boolean | null));

    abstract setApproverPrize(approverPrize: (number | null));

    abstract setCreatorPrize(creatorPrize: (number | null));

    abstract setPrizable(prizable: (boolean | null));

    abstract setPulled();

    abstract setRelCreatorPrize(relCreatorPrize: (number | null));

    abstract update(coworkUserInfo: (CoworkUserInfo | null));


    public fillFromJson(json: Object, factory?: TypeFactory) {
        if(factory) {
            super.fillFromJson(json, factory);
        } else {
            super.fillFromJson(json);
        }
        let replied = json["replied"];
        if (replied != null) {
            this.setReplied(replied);
        }
        let mutuallyReplied = json["mutuallyReplied"];
        if (mutuallyReplied != null) {
            this.setMutuallyReplied(mutuallyReplied);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        super.fillToJson(json, includeTypeTag);
        let replied = this.isReplied();
        if (replied != null) {
            json["replied"] = replied;
        }
        let mutuallyReplied = this.isMutuallyReplied();
        if (mutuallyReplied != null) {
            json["mutuallyReplied"] = mutuallyReplied;
        }
    
        if (includeTypeTag) {
            json["__type"] = "CoopUserInfo";
        }
    }


    public getTypeId(): string {
    	return "CoopUserInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.userad.coop.CoopUserInfo";
    }
}
