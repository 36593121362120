/**
 * Created by Zhonghua on 13/11/2016.
 */

import { AbstractSerializable } from "../serialize/AbstractJsonSerializable";
import { Serializer } from "../serialize/Serializer";
import { TypeFactory } from "../serialize/TypeFactory";
import { Message } from "./Message";
import {MessageResponseType} from "./MessageResponseType";
import {MessageType} from "./MessageContentType";

export abstract class AbstractMessage
  extends AbstractSerializable
  implements Message
{
  public id: string | number;
  public correlationId: string | number;
  public createTime: string | number;

  constructor() {
    super();
  }

  public getId(): string | number {
    return this.id;
  }

  public setId(id: string | number) {
    this.id = id;
  }

  public getCorrelationId(): string | number {
    return this.correlationId;
  }

  public setCorrelationId(correlationId: string | number) {
    this.correlationId = correlationId;
  }

  public getCreateTime(): string | number {
    return this.createTime;
  }

  public setCreateTime(createTime: string | number) {
    this.createTime = createTime;
  }

  isResponseRequired(): boolean {
    const responseType = this.getResponseType();
    return responseType != null && responseType !== MessageResponseType.ZERO;
  }

  isMultipleResponse(): boolean {
    const responseType = this.getResponseType();
    return (
      responseType != null && responseType === MessageResponseType.MULTIPLE
    );
  }

    abstract getContentType(): MessageType;

  public getResponseType(): MessageResponseType {
    const contentType = this.getContentType();
    if (contentType) {
      switch (contentType) {
        // 有关服务的消息类型
        // case MessageType.SERVICE_REQUEST:
        //     return MessageResponseType.ONE;
          case MessageType.SERVICE_RESP:
              return MessageResponseType.ZERO;
          case MessageType.EVENT_SUBSCRIBE:
              return MessageResponseType.MULTIPLE;
          case MessageType.EVENT_NOTIFY:
              return MessageResponseType.ZERO;

        // 用于消息操作的消息类型
          case MessageType.MESSAGE_REVOKE:
              return MessageResponseType.ZERO;

        // 一般性的消息类型
          case MessageType.TEXT:
              return MessageResponseType.AT_MOST_ONE;
          case MessageType.URL:
              return MessageResponseType.ZERO;
        // case MessageType.VOTE_CALL:
          case MessageType.COMMENT:
              return MessageResponseType.ZERO;

          // 知识产权天地：一般

          // 通知新生成的待完成知识领域工作
          case MessageType.TASK:
              return MessageResponseType.ZERO;

          // 知识产权天地：问答
          case MessageType.QA_REQUEST:
          case MessageType.QA_ANSWER:
              return MessageResponseType.ZERO;

          // 订单

          default:
              return MessageResponseType.ZERO;
      }
    } else {
      return MessageResponseType.ZERO;
    }
  }

  public fillFromJson(json: Object, factory?: TypeFactory) {
    const id = json["id"];
    if (id != null) {
      this.setId(Serializer.fromJson(id, factory));
    }
    const correlationId = json["correlationId"];
    if (correlationId != null) {
      this.setCorrelationId(Serializer.fromJson(correlationId, factory));
    }
    const createTime = json["createTime"];
    if (createTime != null) {
      this.setCreateTime(createTime);
    }
  }

  public fillToJson(json: Object, includeTypeTag: boolean) {
    const id = this.getId();
    if (id != null) {
      json["id"] = id;
    }
    const correlationId = this.correlationId;
    if (correlationId != null) {
      json["correlationId"] = correlationId;
    }
    const createTime = this.getCreateTime();
    if (createTime != null) {
      json["createTime"] = createTime;
    }
  }
}
