/**
 * 自动生成， 请不要手工修改！
 */

import {AbstractSerializable} from '../../serialize/AbstractJsonSerializable'
import {AlumniDetails} from './topic/AlumniDetails'
import {AssociationType} from './sector/AssociationType'
import {EnterpriseType} from './enterprise/EnterpriseType'
import {EntityType} from './EntityType'
import {JsonTypeTagType} from '../../serialize/JsonTypeTagType'
import {Location} from '../geolocation/Location'
import {Profession} from './user/Profession'
import {QllCountrySpec} from '../international/QllCountrySpec'
import {Serializer} from '../../serialize/Serializer'
import {TopicType} from './topic/TopicType'
import {TypeFactory} from '../../serialize/TypeFactory'


export class EntityInfo extends AbstractSerializable  {
    languageCode: number;
    countrySpec: QllCountrySpec;
    entityId: (number | string);
    parentId: (number | string);
    regionId: (number | string);
    entityName: string;
    entityType: EntityType;
    logoImgUrl: string;
    location: Location;
    topicType: TopicType;
    enterpriseType: EnterpriseType;
    associationType: AssociationType;
    profession: Profession;
    serviceProvider: boolean;
    noChat: boolean;
    forSector: boolean;
    byPlatform: boolean;
    joined: boolean;
    alumniDetails: AlumniDetails;
    keywords: Array<string>;

    constructor() {
    	super();
    }

    public getLanguageCode(): number {
    	return this.languageCode;
    }

    public setLanguageCode(languageCode: number) {
    	this.languageCode = languageCode;
    }

    public getCountrySpec(): QllCountrySpec {
    	return this.countrySpec;
    }

    public setCountrySpec(countrySpec: QllCountrySpec) {
    	this.countrySpec = countrySpec;
    }

    public getEntityId(): (number | string) {
    	return this.entityId;
    }

    public setEntityId(entityId: (number | string)) {
    	this.entityId = entityId;
    }

    public getParentId(): (number | string) {
    	return this.parentId;
    }

    public setParentId(parentId: (number | string)) {
    	this.parentId = parentId;
    }

    public getRegionId(): (number | string) {
    	return this.regionId;
    }

    public setRegionId(regionId: (number | string)) {
    	this.regionId = regionId;
    }

    public getEntityName(): string {
    	return this.entityName;
    }

    public setEntityName(entityName: string) {
    	this.entityName = entityName;
    }

    public getEntityType(): EntityType {
    	return this.entityType;
    }

    public setEntityType(entityType: EntityType) {
    	this.entityType = entityType;
    }

    public getLogoImgUrl(): string {
    	return this.logoImgUrl;
    }

    public setLogoImgUrl(logoImgUrl: string) {
    	this.logoImgUrl = logoImgUrl;
    }

    public getLocation(): Location {
    	return this.location;
    }

    public setLocation(location: Location) {
    	this.location = location;
    }

    public getTopicType(): TopicType {
    	return this.topicType;
    }

    public setTopicType(topicType: TopicType) {
    	this.topicType = topicType;
    }

    public getEnterpriseType(): EnterpriseType {
    	return this.enterpriseType;
    }

    public setEnterpriseType(enterpriseType: EnterpriseType) {
    	this.enterpriseType = enterpriseType;
    }

    public getAssociationType(): AssociationType {
    	return this.associationType;
    }

    public setAssociationType(associationType: AssociationType) {
    	this.associationType = associationType;
    }

    public getProfession(): Profession {
    	return this.profession;
    }

    public setProfession(profession: Profession) {
    	this.profession = profession;
    }

    public getServiceProvider(): boolean {
    	return this.serviceProvider;
    }

    public setServiceProvider(serviceProvider: boolean) {
    	this.serviceProvider = serviceProvider;
    }

    public isNoChat(): boolean {
    	return this.noChat;
    }

    public setNoChat(noChat: boolean) {
    	this.noChat = noChat;
    }

    public isForSector(): boolean {
    	return this.forSector;
    }

    public setForSector(forSector: boolean) {
    	this.forSector = forSector;
    }

    public isByPlatform(): boolean {
    	return this.byPlatform;
    }

    public setByPlatform(byPlatform: boolean) {
    	this.byPlatform = byPlatform;
    }

    public isJoined(): boolean {
    	return this.joined;
    }

    public setJoined(joined: boolean) {
    	this.joined = joined;
    }

    public getAlumniDetails(): AlumniDetails {
    	return this.alumniDetails;
    }

    public setAlumniDetails(alumniDetails: AlumniDetails) {
    	this.alumniDetails = alumniDetails;
    }

    public getKeywords(): Array<string> {
    	return this.keywords;
    }

    public setKeywords(keywords: Array<string>) {
    	this.keywords = keywords;
    }



    public fillFromJson(json: Object, factory?: TypeFactory) {
        let languageCode = json["languageCode"];
        if (languageCode != null) {
            this.setLanguageCode(languageCode);
        }
        let countrySpec = json["countrySpec"];
        if (countrySpec != null) {
            let convertedCountrySpec0 = new QllCountrySpec();
            convertedCountrySpec0.fillFromJson(countrySpec, factory);
            this.setCountrySpec(convertedCountrySpec0);
        }
        let entityId = json["entityId"];
        if (entityId != null) {
            this.setEntityId(entityId);
        }
        let parentId = json["parentId"];
        if (parentId != null) {
            this.setParentId(parentId);
        }
        let regionId = json["regionId"];
        if (regionId != null) {
            this.setRegionId(regionId);
        }
        let entityName = json["entityName"];
        if (entityName != null) {
            this.setEntityName(entityName);
        }
        let entityType = json["entityType"];
        if (entityType != null) {
            let convertedEntityType0 = EntityType[<string>entityType];
            this.setEntityType(convertedEntityType0);
        }
        let logoImgUrl = json["logoImgUrl"];
        if (logoImgUrl != null) {
            this.setLogoImgUrl(logoImgUrl);
        }
        let location = json["location"];
        if (location != null) {
            let convertedLocation0 = new Location();
            convertedLocation0.fillFromJson(location, factory);
            this.setLocation(convertedLocation0);
        }
        let topicType = json["topicType"];
        if (topicType != null) {
            let convertedTopicType0 = TopicType[<string>topicType];
            this.setTopicType(convertedTopicType0);
        }
        let enterpriseType = json["enterpriseType"];
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[<string>enterpriseType];
            this.setEnterpriseType(convertedEnterpriseType0);
        }
        let associationType = json["associationType"];
        if (associationType != null) {
            let convertedAssociationType0 = AssociationType[<string>associationType];
            this.setAssociationType(convertedAssociationType0);
        }
        let profession = json["profession"];
        if (profession != null) {
            let convertedProfession0 = Profession[<string>profession];
            this.setProfession(convertedProfession0);
        }
        let serviceProvider = json["serviceProvider"];
        if (serviceProvider != null) {
            this.setServiceProvider(serviceProvider);
        }
        let noChat = json["noChat"];
        if (noChat != null) {
            this.setNoChat(noChat);
        }
        let forSector = json["forSector"];
        if (forSector != null) {
            this.setForSector(forSector);
        }
        let byPlatform = json["byPlatform"];
        if (byPlatform != null) {
            this.setByPlatform(byPlatform);
        }
        let joined = json["joined"];
        if (joined != null) {
            this.setJoined(joined);
        }
        let alumniDetails = json["alumniDetails"];
        if (alumniDetails != null) {
            let convertedAlumniDetails0 = Serializer.fillFromJsonObjectWithTypeTag(alumniDetails, "__type", factory);
            this.setAlumniDetails(convertedAlumniDetails0);
        }
        let keywords = json["keywords"];
        if (keywords != null) {
            let convertedKeywords0 = [];
            keywords = keywords[1];
            for (let i in keywords) {
                let convertedKeywords10 = keywords[i];
                convertedKeywords0.push(convertedKeywords10);
            }
            this.setKeywords(convertedKeywords0);
        }
    }

    public fillToJson(json: Object, includeTypeTag: boolean = true) {
        let languageCode = this.getLanguageCode();
        if (languageCode != null) {
            json["languageCode"] = languageCode;
        }
        let countrySpec = this.getCountrySpec();
        if (countrySpec != null) {
            let convertedCountrySpec0 = countrySpec.getJson(JsonTypeTagType.NONE);
            json["countrySpec"] = convertedCountrySpec0;
        }
        let entityId = this.getEntityId();
        if (entityId != null) {
            json["entityId"] = String(entityId);
        }
        let parentId = this.getParentId();
        if (parentId != null) {
            json["parentId"] = String(parentId);
        }
        let regionId = this.getRegionId();
        if (regionId != null) {
            json["regionId"] = String(regionId);
        }
        let entityName = this.getEntityName();
        if (entityName != null) {
            json["entityName"] = entityName;
        }
        let entityType = this.getEntityType();
        if (entityType != null) {
            let convertedEntityType0 = EntityType[entityType];
            json["entityType"] = convertedEntityType0;
        }
        let logoImgUrl = this.getLogoImgUrl();
        if (logoImgUrl != null) {
            json["logoImgUrl"] = logoImgUrl;
        }
        let location = this.getLocation();
        if (location != null) {
            let convertedLocation0 = location.getJson(JsonTypeTagType.NONE);
            json["location"] = convertedLocation0;
        }
        let topicType = this.getTopicType();
        if (topicType != null) {
            let convertedTopicType0 = TopicType[topicType];
            json["topicType"] = convertedTopicType0;
        }
        let enterpriseType = this.getEnterpriseType();
        if (enterpriseType != null) {
            let convertedEnterpriseType0 = EnterpriseType[enterpriseType];
            json["enterpriseType"] = convertedEnterpriseType0;
        }
        let associationType = this.getAssociationType();
        if (associationType != null) {
            let convertedAssociationType0 = AssociationType[associationType];
            json["associationType"] = convertedAssociationType0;
        }
        let profession = this.getProfession();
        if (profession != null) {
            let convertedProfession0 = Profession[profession];
            json["profession"] = convertedProfession0;
        }
        let serviceProvider = this.getServiceProvider();
        if (serviceProvider != null) {
            json["serviceProvider"] = serviceProvider;
        }
        let noChat = this.isNoChat();
        if (noChat != null) {
            json["noChat"] = noChat;
        }
        let forSector = this.isForSector();
        if (forSector != null) {
            json["forSector"] = forSector;
        }
        let byPlatform = this.isByPlatform();
        if (byPlatform != null) {
            json["byPlatform"] = byPlatform;
        }
        let joined = this.isJoined();
        if (joined != null) {
            json["joined"] = joined;
        }
        let alumniDetails = this.getAlumniDetails();
        if (alumniDetails != null) {
            let convertedAlumniDetails0 = alumniDetails.getJson(JsonTypeTagType.TYPE);
            json["alumniDetails"] = convertedAlumniDetails0;
        }
        let keywords = this.getKeywords();
        if (keywords != null) {
            let convertedKeywords0 = [];
            for (let i in keywords) {
                let convertedKeywords10 = keywords[i];
                convertedKeywords0.push(convertedKeywords10);
            }
            let convertedKeywordsWithType1 = ["java.util.ArrayList", convertedKeywords0];
            json["keywords"] = convertedKeywordsWithType1;
        }
    
        if (includeTypeTag) {
            json["__type"] = "EntityInfo";
        }
    }


    public getTypeId(): string {
    	return "EntityInfo";
    }

    public getClassName(): string {
    	return "com.gong_wei.common.datatype.entity.EntityInfo";
    }
}
