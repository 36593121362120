/**
 * 自动生成， 请不要手工修改！
 */


export enum DestinationType {
    COWORK,
    SELF_CASH_TRANS_LIST,
    ENTITY_ASSET_HOME,
    REQUEST_DETAILS,
    GW_CHANCE,
    NO_DESTINATION,
    POINT_SHOP,
    GRID_HOME,
    SERVICE_REQUEST_CHAT
}
